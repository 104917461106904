import React from 'react'
import { Loading } from '~/shared/components/loading'
import { TDeliveryBannerQuickServiceBase } from '.'

const DeliveryBannerQuickServiceContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryBannerQuickService),
  }))
)

interface TDeliveryBannerQuickServicePack
  extends TDeliveryBannerQuickServiceBase {}
export function DeliveryBannerQuickServicePack(
  props: TDeliveryBannerQuickServicePack
) {
  return (
    <React.Suspense fallback={<Loading />}>
      <DeliveryBannerQuickServiceContainer {...props} />
    </React.Suspense>
  )
}
