/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { 상수_결제수단 } from '~/entities/@x'

/**
 * patch expired-time
 */
export const patchResExpiredTimeDto = z.any()
export const patchPayloadExpiredTimeDto = z.object({
  paymentMethodCd: z.nativeEnum(상수_결제수단),
  expireTime: z.string(),
})
