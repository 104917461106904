import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TDeliveryDelayReasonBase, useDeliveryDelayReasonAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const DeliveryDelayReasonContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryDelayReason),
  }))
)

interface TDeliveryDelayReasonPack extends TDeliveryDelayReasonBase {}
export function DeliveryDelayReasonPack(props: TDeliveryDelayReasonPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useDeliveryDelayReasonAtom.state({
    atomKey: props.atomKey,
    sectionCode: props.sectionCode,
    sectionItemCode: props.sectionItemCode,
    orderCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
        <AlertDialog.Content
          className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <DeliveryDelayReasonContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
