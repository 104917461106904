import React from 'react'
import {
  ProductSearchItemBase,
  TProductSearchItemBase,
} from '../product-search-item-base'
import { ProductSearchItemProps } from '../product-search-item.type'
import { ChevronDown } from '@imwebme/clay-icons'
import { cn } from '~/shared/utils'

export type TProductSearchItemProduct = React.PropsWithChildren<
  Omit<
    ProductSearchItemProps,
    'price' | 'option' | 'currency' | 'optionCount' | 'type'
  > &
    TProductSearchItemBase
>
/**
 * ProductSearchItemAtype은 프로덕션 UI이면서 품목을 나타내는 컴포넌트이다.
 */
const ProductSearchItemProduct = ({
  children,
  ...props
}: TProductSearchItemProduct) => {
  const childrenArray = React.Children.toArray(
    children
  ) as unknown as React.ReactElement[]

  function findChild(childName: string): React.ReactElement
  function findChild(
    childName: string,
    optional?: boolean
  ): React.ReactElement | undefined
  function findChild(childName: string, optional?: boolean) {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined && !optional) {
      throw new Error(`${childName} is undefined`)
    }
    return result
  }

  const Label = findChild('label')
  const Body = findChild('body')
  return (
    <ProductSearchItemBase {...props}>
      <ProductSearchItemBase.Slot name="RightArea">
        <div className="flex items-center gap-x-[12px]">
          <div {...Label?.props} />
          <div
            className={cn('h-[16px] transition-all', {
              'rotate-180': props.open,
            })}
          >
            <ChevronDown size="medium" color="#717680" />
          </div>
        </div>
      </ProductSearchItemBase.Slot>
      <ProductSearchItemBase.Slot name="BodyArea">
        <div {...Body?.props} />
      </ProductSearchItemBase.Slot>
    </ProductSearchItemBase>
  )
}
interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'label' | 'body'
}
const Slot: React.FC<SlotProps> = () => null
ProductSearchItemProduct.Slot = Slot

export { ProductSearchItemProduct }
