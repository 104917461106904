/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN } from '../@x'

const addressDto = z.object({
  country: z.string().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().describe('배송지 국가명'),
  receiverName: z.string().describe('수령자명'),
  receiverCall: z.string().describe('수령자전화번호'),
  zipcode: z.string().describe('배송지 우편번호'),
  whoInput: z
    .union([z.literal('orderer'), z.literal('receiver')])
    .describe('배송지 입력 대상 (선물하기 주문에서 주문자, 수령자)'),
  building: z.string().optional().describe('배송지 건물명'),
  street: z.string().optional().describe('배송지 거리명'),
  city: z.string().optional().describe('배송지 도시'),
  state: z.string().optional().describe('배송지 주'),
  addr1: z.string().nullish().describe('배송지 주소1'),
  addr2: z.string().nullish().describe('배송지 주소2'),
  memo: z.string().nullish().describe('메모 (배송 요청사항)'),
})

const resDto = addressDto.extend({
  siteCode: z.string().describe('사이트코드'),
  orderDeliveryCode: z.string().describe('주문배송정보코드'),
  orderCode: z.string().describe('주문코드'),
  memo: z.string().max(200).nullish().describe('메모 (배송 요청사항)'),
  isInput: z.nativeEnum(YN).describe('선물배송지입력여부'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  wtime: z.string().datetime().nullish().describe('등록일시'),
  mtime: z.string().datetime().describe('변경일시'),
})
/**
 * get order-delivery
 */
export const getResOrderDeliveryDto = resDto

/**
 * post order-delivery
 */
export const postResOrderDeliveryDto = resDto
export const postPayloadOrderDeliveryDto = addressDto

/**
 * patch order-delivery
 */
export const patchResOrderDeliveryDto = resDto
export const patchPayloadOrderDeliveryDto = addressDto

/**
 * delete order-delivery
 */
export const deleteResOrderDeliveryDto = z.any()
export const deletePayloadOrderDeliveryDto = z.any()
