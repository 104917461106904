/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post bulk-action-section-delivery-shipping-apply
 */
export const postResBulkActionSectionDeliveryShippingApplySchema = z.any()
export const postPayloadBulkActionSectionDeliveryShippingApplySchema = z.object(
  {
    targetOrderCodes: z.array(z.string()),
  }
)
