import React from 'react'
import OrderReturnInfo from './views'
import { useQuery } from '@tanstack/react-query'
import { pipe, flow } from 'fp-ts/function'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useOrderSectionAtom } from '~t'
import { COrder } from '../class-order'
import { type COrderSection } from '../class-order-section'
import { SingleOrderCheck } from '../order-api'
import { type TTargetSectionInformation } from './order-retrun-info.type'
import { __ } from '~/shared/i18n'
import { orderDetailOptions } from '~/entities/order-detail'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const OrderCancelInfoPage = () => {
  const orderCode = useOrderCode()
  if (orderCode === undefined) {
    throw new Error(
      '🚀 ~ file: order-cancel-info.tsx:17 ~ OrderCancelInfoPage ~ OrderCancelInfoPage:'
    )
  }
  // server status
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
    queryFn: () => SingleOrderCheck(orderCode),
    select: flow((d) => d.data),
  })
  const updateItems = useOrderSectionAtom.valueUpdateAll()

  if (isSuccess && data && updateItems) {
    // ==========================================
    // 스펙
    // 스펙문서
    // https://www.notion.so/imweb/C3-BO-71c4387fa3f84c75879e061d962dbd15
    // ==========================================

    const sections = new COrder(data).orderSectionList

    // OSS03(배송중) 섹션인지 확인하는 스펙
    const isStatusCdOSS03: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS03'
    )

    // OSS04(배송완료) 섹션인지 확인하는 스펙
    const isStatusCdOSS04: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS04'
    )

    // OSS05(구매확정) 섹션인지 확인하는 스펙
    const isStatusCdOSS05: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS05'
    )

    const result = pipe(
      sections,
      A.filter((section) =>
        isStatusCdOSS03
          .or(isStatusCdOSS04)
          .or(isStatusCdOSS05)
          .isSatisfiedBy(section)
      )
    )

    const c3payloadItems: TTargetSectionInformation = pipe(
      result,
      // 주문서에 존재했던 섹션데이터에서 UI상 업데이트된 섹션테이터를 찾아서 맵핑한다.
      A.map(
        flow(
          (section) => section.meta,
          (s) => ({
            orderSectionCode: s.orderSectionCode,
            targetItemInformation: pipe(
              s.orderSectionItemList.map((item) =>
                pipe(
                  updateItems,
                  A.findFirst((e) => e.id === s.orderSectionCode),
                  // 첫번째 value를 가져오기
                  O.map((e) => e.value),
                  // 첫번째 value에서 itemId를 가진 아이템을 찾기
                  O.map((e) => e.find((v) => v.id === item.orderItemCode)),
                  // TOrderAddConfirm.items의 타입에 맞게 변환
                  // updateItems에서 qtyChange, itemPrice, baseItemPrice만 맵핑하고 나머지는 섹션 아이템값으로 넣어준다.
                  O.fold(
                    () => undefined,
                    flow(
                      // undefined를 필터링한다.
                      O.fromNullable,
                      O.fold(
                        () => undefined,
                        (e) => ({
                          orderSectionItemCode: item.orderSectionItemCode,
                          qty: Number(e?.qtyChange || item.qty),
                        })
                      )
                    )
                  )
                )
              ),
              A.filter((e) => !!e?.orderSectionItemCode)
            ),
          })
        )
      ),
      A.filter((e) => !!e.targetItemInformation.length),
      (e) => e as TTargetSectionInformation
    )

    return (
      <>
        <PageTopBar />
        <OrderReturnInfo c3payloadItems={c3payloadItems} />
      </>
    )
  }
  if (isLoading) {
    return <div>{__('로딩중')}</div>
  }
  return <div>{__('로딩중')}</div>
}

export default OrderCancelInfoPage
