import { z } from 'zod'
import { orderCreatePayloadDto } from '~/entities/@dto/주문생성페이로드'

export const postResAmountDueDto = z.object({
  basePrice: z
    .number()
    .describe(
      '아무런 할인이 적용되지 않은 상품들의 원 금액들의 합 (원 금액 * 수량)'
    ),
  salePrice: z.number().describe('할인금액 (basePrice - itemPrice)'),
  deliveryPrice: z.number().describe('배송비'),
  deliveryIslandPrice: z.number().describe('도서산간 배송비'),
  cashOnDeliveryPrice: z.number().describe('착불 배송비'),
  cashOnDeliveryIslandPrice: z.number().describe('착불 도서산간 배송비'),
  couponDiscount: z.number().describe('총 쿠폰 할인 금액'),
  gradeDiscount: z.number().describe('등급할인 금액'),
  deliveryCouponDiscount: z.number().describe('배송비 쿠폰 할인 금액'),
  pointAmount: z.number().describe('포인트 사용 금액'),
  deliveryPointAmount: z.number().describe('배송비 포인트 사용 금액'),
  amountDue: z.number().describe('총 결제 예정 금액'),
  totalDownloadCouponDiscount: z
    .number()
    .describe('다운로드 쿠폰들의 할인금액 합'),
  totalInputCouponDiscount: z.number().describe('입력 쿠폰 할인 금액'),
  givePoint: z.number().describe('적립 예정 포인트'),
})

export const postPayloadAmountDueDto = orderCreatePayloadDto
