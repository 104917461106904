import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { Lens } from 'monocle-ts'
import {
  TGetResOrderSearchTabSchema,
  TPatchPayloadOrderSearchTabSchema,
  TVisibleColumn,
} from './order-search-tab.type'

const visibleColumnLens =
  Lens.fromProp<TPatchPayloadOrderSearchTabSchema>()('visibleColumns')
export function updateVisibleColumns(visibleColumns: TVisibleColumn[]) {
  return (
    result: TPatchPayloadOrderSearchTabSchema
  ): TPatchPayloadOrderSearchTabSchema =>
    visibleColumnLens.set(visibleColumns)(result)
}

export function findCurrentTabData(tabCode?: string) {
  return (d: ApiResponse<TGetResOrderSearchTabSchema>) => {
    const allTab = pipe(
      d,
      (e) => e.data.list,
      A.findFirst((e) => e.name === '전체'),
      O.fold(
        () => {
          // 1번의 전체를 의미하는 탭은 없을 수 없다.
          throw new Error('allTab is undefined')
        },
        (e) => e
      )
    )
    const result = pipe(
      d,
      (e) => e.data.list,
      A.findFirst((e) => e.adminOrderSearchTabCode === tabCode),
      O.fold(
        () => allTab,
        (e) => e
      )
    )

    return result
  }
}
