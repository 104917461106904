import { type ISpecification, Spec } from 'spec-pattern-ts'
import { TBulkBannerBase } from './bulk-banner.type'

export class 모델_벌크검증 {
  #fulfilled: number | undefined | null
  #rejected: number | undefined | null
  constructor({ fulfilled, rejected }: TBulkBannerBase) {
    this.#fulfilled = fulfilled
    this.#rejected = rejected
  }

  get fulfilled() {
    return this.#fulfilled
  }
  get rejected() {
    return this.#rejected
  }
}

const 스펙_실패: ISpecification<모델_벌크검증> = new Spec(
  (candidate) => candidate.fulfilled === undefined || candidate.fulfilled === 0
)

export const 스펙_벌크검증 = {
  실패: 스펙_실패,
}
