import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { ApiClient, ApiResponse } from '~/shared/api'
import { omsChannelStatus, type TGetResExternalOrderDto } from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/MiwFis
 * @description 외부채널 주문 조회
 * 외부채널 주문 조회만 특수 케이스로 api응답값을 변환해서 사용한다.
 */
export function getExternalOrder({ orderCode }: { orderCode: string }) {
  return async function () {
    const result = await axios
      .get<ApiResponse<TGetResExternalOrderDto>>(`/external-order/${orderCode}`)
      .then((res) => res.data)

    result.data.saleChannelItemList = pipe(
      result.data.saleChannelItemList,
      A.map((e) => ({
        ...e,
        channelStatus: omsChannelStatus(e.channelStatus as string),
      }))
    )

    return result
  }
}
