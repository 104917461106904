import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  orderCode: string
  sectionCode: string
}
type AtomType = {
  key: string
  value?: boolean
}

const RetreiveRequestEditState = atomFamily(
  ({ key, value = false }: AtomType) =>
    atomWithReset<AtomType>({
      key,
      value,
    }),
  (a, b) => a.key === b.key
)

export const useRetreiveRequestEditAtom = {
  key: (p: AtomKey) => `retreive-request-edit-${p.orderCode}-${p.sectionCode}`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          RetreiveRequestEditState({
            key: useRetreiveRequestEditAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          RetreiveRequestEditState({ key: useRetreiveRequestEditAtom.key(p) }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          RetreiveRequestEditState({ key: useRetreiveRequestEditAtom.key(p) }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          RetreiveRequestEditState({ key: useRetreiveRequestEditAtom.key(p) }),
        [p]
      )
    ),
}
