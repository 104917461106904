/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post bulk-action-invoice-delete-apply
 */
export const postResBulkActionInvoiceDeleteApplySchema = z.any()
export const postPayloadBulkActionInvoiceDeleteApplySchema = z.object({
  targetOrderCodes: z.array(z.string()),
})
