export const retrieveTypeCd = {
  /**
   * @description 자동 수거 신청
   */
  RRT01: 'RRT01',
  /**
   * @description 구매자 발송
   */
  RRT02: 'RRT02',
  /**
   * @description 기타
   */
  RRT03: 'RRT03',
  /**
   * @description 수동 수거 신청 (판매자 직접 송장번호 입력)
   */
  RRT04: 'RRT04',
} as const

export const retrievePayTypeCd = {
  /**
   * @description 신용
   */
  RPT01: 'RPT01',
  /**
   * @description 선불
   */
  RPT02: 'RPT02',
  /**
   * @description 착불
   */
  RPT03: 'RPT03',
  /**
   * @description 기타
   */
  RPT04: 'RPT04',
} as const
