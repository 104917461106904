import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { orderSearchTabQueryOptions } from '~/entities/order-search-tab'

/**
 * 현재 URL의 동적 매개변수에서 현재 탭 데이터를 가져오는 tanstak/query
 * @returns tabData
 * @throws tabData가 undefined인 경우 에러 던짐
 */
export function useCurrentTabData() {
  const params = useParams()
  const tabCode = params.tabCode
  return useQuery({
    ...orderSearchTabQueryOptions(),
    select: flow(
      (data) => data.data.list,
      // 리팩토링 가능영역 (아직잘몰라서 이렇게함)
      (list) =>
        pipe(
          list,
          A.findFirst((v) => v.adminOrderSearchTabCode === tabCode),
          O.fold(
            () =>
              pipe(
                list,
                // 첫번째 전체라는 이름을 찾는것이라 중복되어도 상관없다.
                A.findFirst((v) => v.name === '전체'),
                O.fold(
                  () => {
                    throw new Error('tabCode가 존재하지 않습니다.')
                  },
                  (v) => v
                )
              ),
            (e) => e
          )
        )
    ),
  })
}
