import { useQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { useOrderCode } from '../use-order-code'
import { externalOrderQueryOptions } from '~/entities/external-order'

/**
 * @description
 * 현재 주문서의 currency를 가져온다.
 */
function useCurrency(params?: { isExternal?: boolean }): string {
  try {
    const orderCode = useOrderCode()
    if (params?.isExternal) {
      const { data, isSuccess } = useQuery({
        ...externalOrderQueryOptions({ orderCode }),
      })
      if (isSuccess) {
        return data?.currency
      }
      return 'KRW'
    } else {
      const { data, isSuccess } = useQuery({
        ...orderDetailOptions({ orderCode }),
      })
      if (isSuccess) {
        return data?.currency
      }
      return 'KRW'
    }
  } catch (error) {
    return 'KRW'
  }
}

export { useCurrency }
