import { __ } from '~/shared/i18n'
import Icon from './master-circle-warning.svg?react'
import { Trans } from 'react-i18next'
import { Button } from '@imwebme/clay-components'
import { cn, findChild } from '~/shared/utils'
import { match, P } from 'ts-pattern'
import React from 'react'
import { Link } from '../link'

interface TError extends React.PropsWithChildren {
  isFull?: boolean
  isDebug?: boolean
  showButtons?: boolean
  error?: Error
  componentStack?: string
  eventId?: string
  cleanup?: () => void
  resetError?(): void
}
export function ErrorCard({
  isFull = false,
  isDebug = true,
  showButtons = true,
  error: _error,
  componentStack: _componentStack,
  cleanup,
  resetError: _resetError,
  children,
}: TError) {
  const MessageSlot = findChild(children, 'message', true)
  return (
    <>
      <div
        className={cn(
          'grid place-content-center p-[16px]',
          isFull && 'h-[100dvh]'
        )}
      >
        <div className="text-center">
          <div className="flex justify-center">
            <Icon />
          </div>
          {MessageSlot ? (
            <div {...MessageSlot.props} />
          ) : (
            <>
              <div className="mt-[20px]">{__('화면을 불러오지 못했어요')}</div>
              <div className="mt-[8px]">
                <Trans>
                  서비스가 원활하지 않아 일시적인 오류가 발생했어요.
                  <br />
                  잠시 후 다시 시도해 주세요.
                </Trans>
              </div>
            </>
          )}
          {showButtons && (
            <div className="mt-clay-5">
              <div className="flex gap-x-clay-2 justify-center">
                <Link to="/orders" onClick={() => cleanup?.()}>
                  <Button as="div" variant="secondary" text={__('홈으로')} />
                </Link>
                <Button
                  native={{ type: 'button' }}
                  variant="primary"
                  onClick={() => {
                    _resetError?.()
                    cleanup?.()
                  }}
                  text={__('새로고침')}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {match<boolean>(true)
        .with(import.meta.env.VITE_NODE_ENV === 'local' && isDebug, () => (
          <div className="container bg-dark-core-slate-700 p-[10px] lg:p-[40px] rounded-2xl mb-[200px] translate-y-[-110px] _drop-shadow">
            <div className=" prose prose-invert max-w-none">
              <h1 className="text-clay-semantic-textWarning">
                로컬, dev모드 활성화시에만 보이는 영역입니다
              </h1>
              <h2>error message</h2>
              <pre>{_error?.toString()}</pre>
              <h2>componentStack</h2>
              <pre>{_componentStack}</pre>
            </div>
          </div>
        ))
        .otherwise(() => null)}
    </>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'message'
}
const Slot: React.FC<SlotProps> = () => null
ErrorCard.Slot = Slot
