import { Checkbox, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TGetResProducttListItemDto } from '~/entities/product/product-list'
import { __ } from '~/shared/i18n'
import { FormattedPrice } from '../../formatted-price'
import { genUniqueId } from '~/widgets/product-add-modal/product-add-modal.fn'
import {
  useProductAddModalAtom,
  useSelectedProdAtom,
} from '~/widgets/product-add-modal/product-add-modal.store'

interface Props {
  prod: TGetResProducttListItemDto
}

export function NoRequiredOptionProd({ prod }: Props) {
  const [selectedProd, setSelectedProd] = useSelectedProdAtom(prod.prodCode)
  const modalData = useProductAddModalAtom.get()
  const id = genUniqueId({ prodCode: prod.prodCode })

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      padding={`${vars.spacing[15]} 0`}
      columnGap={vars.spacing[4]}
    >
      <Checkbox.Label
        checked={
          !!selectedProd?.체크박스옵션?.includes(
            genUniqueId({ prodCode: prod.prodCode })
          )
        }
        onChangeChecked={(v: boolean) => {
          if (v) {
            setSelectedProd({
              action: 'add',
              type: '체크박스옵션',
              payload: {
                prod,
              },
            })
          } else {
            setSelectedProd({
              action: 'remove',
              type: '체크박스옵션',
              payload: id,
            })
          }
        }}
        label={prod.prodName}
        disabled={modalData.idList?.includes(id)}
      />
      <Typography variant="body-medium" whiteSpace="nowrap">
        <FormattedPrice price={prod.price} />
      </Typography>
    </Flex>
  )
}
