import { z } from 'zod'
import {
  YN,
  상수_배송결제타입,
  상수_배송타입,
  상수_상품옵션타입,
} from '~/entities/@x'

export const orderCreatePayloadItemDto = z.object({
  prodOptionDetailCode: z
    .string()
    .optional()
    .describe('상품 옵션 상세 코드, 조합형 옵션 상품인 경우에만 입력'),
  isIndividualOption: z.nativeEnum(YN).optional(),
  isRequireOption: z.nativeEnum(YN),
  optionDataList: z
    .object({
      optionType: z.nativeEnum(상수_상품옵션타입),
      optionCode: z.string(),
      valueCode: z.string(),
    })
    .array()
    .optional(),
  prodCode: z.string(),
  qty: z.number(),
  deliveryTypeCd: z.nativeEnum(상수_배송타입),
  deliveryPayTypeCd: z.nativeEnum(상수_배송결제타입),
  itemPrice: z.number().optional(),
})
export type TOrderCreatePayloadItemDto = z.infer<
  typeof orderCreatePayloadItemDto
>

export const orderCreatePayloadDeliveryDto = z.object({
  shippingAddressCode: z.string().optional().describe('회원 배송지 코드'),
  receiverName: z.string().optional().describe('수령자명'),
  receiverCall: z.string().optional().describe('수령자전화번호'),
  addr2: z.string().optional().describe('배송지 주소2'),
  building: z.string().optional().describe('배송지 건물명'),
  street: z.string().optional().describe('배송지 거리명'),
  city: z.string().optional().describe('배송지 도시'),
  state: z.string().optional().describe('배송지 주'),
  country: z.string().optional().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().optional().describe('배송지 국가명'),
  memo: z.string().optional().describe('메모 (배송 요청사항)'),
  zipcode: z.string().optional().describe('배송지 우편번호'),
  addressFormat: z.string().optional().describe('배송지 주소 format'),
  isAddShippingAddress: z
    .nativeEnum(YN)
    .optional()
    .describe('배송지 목록에 추가(배송지 신규 입력 일 때만 넘겨주세요)'),
  isDefaultShippingAddress: z
    .nativeEnum(YN)
    .optional()
    .describe('기본 배송지로 설정(배송지 신규 입력 일 때만 넘겨주세요)'),
  pickupMemo: z.string().optional().describe('방문 픽업 메모'),
  addr1: z.string().optional().describe('배송지 주소1'),
})
export type TOrderCreatePayloadDeliveryDto = z.infer<
  typeof orderCreatePayloadDeliveryDto
>

export const orderCreatePayloadDto = z.object({
  unitCode: z.string().describe('사이트 유닛 코드'),
  memberCode: z.string().optional().describe('회원코드 (비회원이면 생략)'),
  ordererName: z.string().optional().describe('주문자명'),
  ordererEmail: z.string().optional().describe('주문자 이메일'),
  ordererCall: z.string().optional().describe('주문자 연락처'),
  items: orderCreatePayloadItemDto.array(),
  country: z.string(),
  unipassNumber: z.string().optional().describe('개인 통관 고유 부호'),
  delivery: orderCreatePayloadDeliveryDto.optional(),
  isGradeDiscount: z.boolean(),
  inputCouponCode: z.string().optional().describe('입력 쿠폰 코드'),
  couponIssueCodes: z.string().array().optional().describe('쿠폰 코드'),
  inputPointAmount: z.number().optional().describe('사용 포인트'),
})
export type TOrderCreatePayloadDto = z.infer<typeof orderCreatePayloadDto>
