import { ISpecification } from 'spec-pattern-ts'
import { 모델_쿠폰 } from '.'
import { TCouponInfoSchema } from '~/entities/order-section/cancel-settlements'

export function couponSortBySpecs(specs: ISpecification<모델_쿠폰>[]) {
  return function (array: TCouponInfoSchema[]): TCouponInfoSchema[] {
    return array.sort((a, b) => {
      const aModel = new 모델_쿠폰(a)
      const bModel = new 모델_쿠폰(b)
      for (const spec of specs) {
        if (spec.isSatisfiedBy(aModel) && !spec.isSatisfiedBy(bModel)) {
          return -1
        }
        if (!spec.isSatisfiedBy(aModel) && spec.isSatisfiedBy(bModel)) {
          return 1
        }
      }
      return 0
    })
  }
}
