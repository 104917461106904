import { Spinner } from '@imwebme/clay-components'
import React from 'react'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

export function Loading({
  className,
  text,
  ...props
}: React.PropsWithChildren<{
  className?: string | undefined | null
  text?: string
}>) {
  return (
    <div
      {...props}
      className={cn('h-screen flex justify-center items-center', className)}
    >
      <div className="grid">
        <div className="justify-self-center mb-3">
          <Spinner size="medium" />
        </div>
        <p className="semantic-p14">{text ?? __('페이지를 불러오고 있어요')}</p>
      </div>
    </div>
  )
}
