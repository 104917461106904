import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionInvoiceDeleteApplyAllSchema,
  type TPostPayloadBulkActionInvoiceDeleteApplyAllSchema,
  postResBulkActionInvoiceDeleteApplyAllSchema,
} from '.'

const axios = ApiClient()

export async function postBulkActionInvoiceDeleteApplyAll({
  body,
}: {
  body: TPostPayloadBulkActionInvoiceDeleteApplyAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionInvoiceDeleteApplyAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/invoice/delete/all', body)
    .then((res) => res.data)

  postResBulkActionInvoiceDeleteApplyAllSchema.parse(result.data)

  return result
}
