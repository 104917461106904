import { IconHelpCircle } from '@imwebme/icon'
import React from 'react'
import { Trans, __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'
import {
  Clay,
  Flex,
  Label,
  LocalColorSchemeArea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '@imwebme/clay-components'
import { HelpCircle } from '@imwebme/clay-icons'

export function OrderEditCalcCard({ children }: React.PropsWithChildren) {
  const childrenArray = React.Children.toArray(
    children
  ) as unknown as React.ReactElement[]

  function findChild(childName: string): React.ReactElement
  function findChild(
    childName: string,
    optional?: boolean
  ): React.ReactElement | undefined
  function findChild(childName: string, optional?: boolean) {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined && !optional) {
      throw new Error(`${childName} is undefined`)
    }
    return result
  }

  const TitleComp = findChild('title')
  const DescriptionComp = findChild('description')
  const PriceComp = findChild('price')
  const HeaderComp = findChild('header', true)
  const FooterComp = findChild('footer', true)
  const BannerComp = findChild('banner', true)

  return (
    <div className="rounded-[12px] bg-white grid pt-[20px] pb-[24px]">
      <div {...HeaderComp?.props} />
      <div className="mx-[24px]">
        <div className="text-clay-semantic-text semantic-h6-bold">
          <div {...TitleComp.props} />
        </div>
      </div>
      {BannerComp && (
        <div
          {...BannerComp?.props}
          className={cn('mt-[20px] mx-[24px]', BannerComp.props.className)}
        />
      )}
      <div className="mt-[20px] mx-[24px]">
        <div className="grid grid-cols-[auto,1fr]">
          <div>
            <Label text={__('금액')} type="primary">
              <Tooltip>
                <TooltipTrigger action="click">
                  <HelpCircle />
                </TooltipTrigger>
                <TooltipContent className="clay-dark-theme-area">
                  <div className="w-[187px] typo-clay-label-small text-white">
                    <div {...DescriptionComp.props} />
                  </div>
                </TooltipContent>
              </Tooltip>
            </Label>
          </div>
          <div className="justify-self-end">
            <div {...PriceComp.props} />
          </div>
        </div>
      </div>
      <div {...FooterComp?.props} />
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name:
    | 'description'
    | 'price'
    | 'title'
    | 'banner'
    // layout
    | 'header'
    | 'footer'
}
const Slot: React.FC<SlotProps> = () => null
OrderEditCalcCard.Slot = Slot
