import { z } from 'zod'
import { YN, 상수_주소형식 } from '~/entities/@x'

export const addressData = z.object({
  delivName: z.string().describe('수취인 이름'),
  delivCall: z.string().describe('수취인 연락처 번호'),
  delivPostcode: z.string().describe('우편번호'),
  delivAddress: z.string().describe('주소'),
  delivAddressDetail: z.string().describe('상세주소'),
  delivAddressStreet: z.string().describe('국제 5형식 주소 (거리이름)'),
  delivAddressBuilding: z.string().describe('국제 5형식 주소 (건물이름)'),
  delivAddressCity: z.string().describe('국제 5형식 주소 (도시이름)'),
  delivAddressState: z.string().describe('국제 5형식 주소 (주 이름)'),
  delivAddressZipcode: z.string().describe('국제 5형식 주소 (우편번호)'),
  delivSplitAddressDetail1: z.string().describe('추가 상세 주소'),
  delivSplitAddressDetail2: z.string().describe('추가 상세 주소'),
})

export const getResMemberDeliveryDto = z
  .object({
    idx: z.number(),
    code: z.string(),
    siteCode: z.string(),
    unitCode: z.string(),
    memberCode: z.string(),
    siteLang: z.string(),
    siteCountry: z.string(),
    shippingCountry: z.string(),
    addressType: z.nativeEnum(상수_주소형식).describe('배송지 주소 format'),
    isDefault: z.nativeEnum(YN).describe('특정 회원의 기본 배송지 인지'),
    isTemp: z.nativeEnum(YN).describe('임시저장 인지'),
    lastUpdateTime: z.string().describe('마지막 사용 시점'),
    addressData,
  })
  .array()
