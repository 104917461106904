import React from 'react'
import { ExternalProductPreparation, TExternalProductPreparationBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

interface TExternalProductPreparationPack
  extends TExternalProductPreparationBase {}
export function ExternalProductPreparationPack(
  props: TExternalProductPreparationPack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalProductPreparation {...props} />
    </React.Suspense>
  )
}
