import React from 'react'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { ProductSearchItemProduct } from '../product-search-item/product-search-item-product'
import type { TItem, T_VIEW_Item } from './product-search.type'
import {
  NonRequiredOptionMemo,
  RequiredOptionMemo,
  RncoOptionMemo,
} from './product-search-item-options'
import { type TProductSearchItemBase } from '../product-search-item/product-search-item-base'
import { Product } from './product-search-item-class'
import { SingleProductContainer } from './product-search-item-options/single-product/single-product.container'
import { RcoOptionMemo } from './product-search-item-options/rco-option/rco-option'
import { SiOptionMemo } from './product-search-item-options/si-option/si-option'

export class ProductItem extends Product {
  #id
  #name
  #thumbnail
  #options
  #price
  #optionCount
  #optionDetails
  #data
  #searchId

  constructor(data: TItem, searchId: string) {
    super(data, searchId)
    this.#id = data.prodCode
    this.#name = data.prodName
    this.#thumbnail = data.imageUrls
    this.#price = data.price
    this.#options = data.options
    this.#optionDetails = data.optionDetails
    this.#optionCount = data.optionDetailCount || 0
    this.#data = data
    this.#searchId = searchId
  }

  // 선택상품은 필수조합, 필수비조합, 선택상품만있는경우 모두 개별로 렌더링해야하기 때문에 children에서 분리되어서 만들었다.
  get opitonalProductRender() {
    if (this.#options === undefined) {
      return null
    }
    const options = pipe(
      this.#options,
      A.filter((e) => e.isRequiredOption === false)
    )
    return (
      <NonRequiredOptionMemo
        searchId={this.#searchId}
        options={options}
        productData={this.#data}
      />
    )
  }

  get children() {
    switch (this.childComponentType) {
      case '필수조합옵션':
        if (this.#optionDetails === undefined) {
          if (import.meta.env.VITE_NODE_ENV === 'local') {
            throw new Error('옵션디테일이 존재하지 않습니다.')
          }
          return null
        }
        return (
          <>
            <RequiredOptionMemo
              optionDetails={this.#optionDetails}
              productData={this.#data}
              searchId={this.#searchId}
            />
            {this.opitonalProductRender}
          </>
        )
      case '필수조합옵션_입력형옵션':
        if (this.#optionDetails === undefined) {
          if (import.meta.env.VITE_NODE_ENV === 'local') {
            throw new Error('옵션이 존재하지 않습니다.')
          }
          return null
        }
        return (
          <>
            <RcoOptionMemo
              productData={this.#data}
              searchId={this.#searchId}
              optionKey={pipe(
                this.#optionDetails,
                A.map((e) => e.optionDetailCode),
                (e) => e.join('-')
              )}
            />
            <div className="mt-[12px]">{this.opitonalProductRender}</div>
          </>
        )
      case '필수비조합옵션':
        if (this.#options === undefined) {
          if (import.meta.env.VITE_NODE_ENV === 'local') {
            throw new Error('옵션이 존재하지 않습니다.')
          }
          return null
        }
        return (
          <>
            <RncoOptionMemo
              productData={this.#data}
              searchId={this.#searchId}
              optionKey={pipe(
                this.#options,
                A.filter((e) => e.isRequiredOption === true),
                A.map((e) => e.optionCode),
                (e) => e.join('-')
              )}
            />
            <div className="mt-[12px]">{this.opitonalProductRender}</div>
          </>
        )
      case '필수비조합옵션_입력형옵션':
        if (this.#options === undefined) {
          if (import.meta.env.VITE_NODE_ENV === 'local') {
            throw new Error('옵션이 존재하지 않습니다.')
          }
          return null
        }
        return (
          <>
            <RncoOptionMemo
              productData={this.#data}
              searchId={this.#searchId}
              optionKey={pipe(
                this.#options,
                A.filter((e) => e.isRequiredOption === true),
                A.map((e) => e.optionCode),
                (e) => e.join('-')
              )}
            />
            <div className="mt-[12px]">{this.opitonalProductRender}</div>
          </>
        )
      case '단일상품':
        return (
          <div className="space-y-[12px]">
            <SingleProductContainer
              id={this.#id}
              name={this.name}
              price={this.#price}
              searchId={this.#searchId}
            />
            {this.opitonalProductRender}
          </div>
        )
      case '단일상품_입력형옵션':
        if (this.#options === undefined) {
          if (import.meta.env.VITE_NODE_ENV === 'local') {
            throw new Error('옵션이 존재하지 않습니다.')
          }
          return null
        }
        return (
          <>
            <SiOptionMemo
              productData={this.#data}
              searchId={this.#searchId}
              optionKey={pipe(
                this.#options,
                A.filter((e) => e.isRequiredOption === true),
                A.map((e) => e.optionCode),
                (e) => e.join('-')
              )}
            />
            <div className="mt-[12px]">{this.opitonalProductRender}</div>
          </>
        )
      default:
        return (
          <div className="prose max-w-none">
            <pre>
              <code>{this.childComponentType}</code>
            </pre>
          </div>
        )
    }
  }

  render({
    label = null,
    ...props
  }: TProductSearchItemBase & {
    label:
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | undefined
      | null
  }) {
    return (
      <ProductSearchItemProduct
        {...{
          id: this.#id,
          name: this.name,
          thumbnail: this.#thumbnail[0],
        }}
        {...props}
      >
        <ProductSearchItemProduct.Slot name="label">
          <div {...label?.props} />
        </ProductSearchItemProduct.Slot>
        <ProductSearchItemProduct.Slot name="body">
          {this.children}
        </ProductSearchItemProduct.Slot>
      </ProductSearchItemProduct>
    )
  }
}

export interface T_ProductSearchItem extends TItem, T_VIEW_Item {
  searchId: string
}
const ProductSearchItem = ({
  setOpen,
  children,
  searchId,
  ...props
}: React.PropsWithChildren<T_ProductSearchItem>) => {
  const childrenArray = React.Children.toArray(
    children
  ) as unknown as React.ReactElement[]

  function findChild(childName: string): React.ReactElement
  function findChild(
    childName: string,
    optional?: boolean
  ): React.ReactElement | undefined
  function findChild(childName: string, optional?: boolean) {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined && !optional) {
      throw new Error(`${childName} is undefined`)
    }
    return result
  }
  const Label = findChild('label', true)
  // render
  const product = new ProductItem(props, searchId)
  return product.render({ open: props.open, setOpen, label: Label })
}
export interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'label'
}
const Slot: React.FC<SlotProps> = () => null
ProductSearchItem.Slot = Slot

export { ProductSearchItem }
