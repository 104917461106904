import { z } from 'zod'

export const 상수_언어코드 = {
  KR: 'KR',
  JP: 'JA',
  TW: 'zh-TW',
  CN: 'CN',
  EN: 'EN',
  ES: 'ES',
  VI: 'VI',
} as const

const type = z.nativeEnum(상수_언어코드)
export type T상수_언어코드 = z.infer<typeof type>
