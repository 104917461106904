import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPatchResExchangedItemsDto,
  type TPatchPayloadExchangedItemsDto,
  patchResExchangedItemsDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description exchanged-items
 */
export function patchExchangedItems({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadExchangedItemsDto) {
    type T = TPatchResExchangedItemsDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/sections/${sectionCode}/exchanged-items`, body)
      .then((res) => res.data)

    patchResExchangedItemsDto.parse(result.data)
    return result
  }
}
