import { match, P } from 'ts-pattern'
import { Tag } from '@imwebme/clay-components'
import { AlertTriangle } from '@imwebme/clay-icons'
import {
  모델_외부채널_주문섹션_품목_클레임정보,
  스펙_외부채널_주문섹션_품목_클레임정보,
} from '~/entities/external-order-claim-info/model/external-order-item-claim-info'

export function ExternalItemTag({
  $claimInfo,
}: {
  $claimInfo: 모델_외부채널_주문섹션_품목_클레임정보
}) {
  return match<boolean>(true)
    .with(
      스펙_외부채널_주문섹션_품목_클레임정보.보류중
        .and(
          스펙_외부채널_주문섹션_품목_클레임정보.배송비동봉.or(
            스펙_외부채널_주문섹션_품목_클레임정보.배송비직접송금
          )
        )
        .isSatisfiedBy($claimInfo),
      () => (
        <Tag
          variant="critical"
          text="보류·동봉/송금"
          leadingIcon={<AlertTriangle />}
          size="small"
        />
      )
    )
    .with(
      스펙_외부채널_주문섹션_품목_클레임정보.보류중
        .and(스펙_외부채널_주문섹션_품목_클레임정보.배송비환불금차감)
        .isSatisfiedBy($claimInfo),
      () => (
        <Tag
          variant="critical"
          text="보류·환불금차감"
          leadingIcon={<AlertTriangle />}
          size="small"
        />
      )
    )
    .otherwise(() => null)
}
