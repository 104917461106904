import React from 'react'
import { Plus, X } from '@imwebme/clay-icons'
import { match } from 'ts-pattern'
import {
  useFloating,
  useClick,
  useDismiss,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  hide,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import { vars } from '@imwebme/clay-token'

interface Props {
  label: string
  isActive: boolean
  resetField: () => void
  dropdownOpen?: boolean
  setDropdownOpen?: React.Dispatch<React.SetStateAction<boolean>>
  dropdownContent?: React.ReactNode
}

export function Selector({
  label,
  isActive,
  resetField,
  dropdownOpen,
  setDropdownOpen,
  dropdownContent,
}: Props) {
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement: 'bottom-start',
    open: dropdownOpen,
    onOpenChange: setDropdownOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip({ padding: 10 }),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
        padding: 20,
      }),
      hide(),
    ],
  })

  const click = useClick(context, { event: 'click' })

  const dismiss = useDismiss(context, { bubbles: false })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ])

  return (
    <>
      {match(isActive)
        .with(true, () => (
          <div
            ref={refs.setReference}
            {...getReferenceProps()}
            className="group border border-solid border-clay-semantic-border typo-clay-label-medium px-clay-2 py-clay-1 rounded-full h-clay-8 text-clay-semantic-text flex items-center hover:bg-clay-semantic-actionSecondaryTonal cursor-pointer"
          >
            <span className="px-clay-1">{label}</span>
            <X
              className="text-clay-semantic-iconSecondary group-hover:text-clay-semantic-iconCriticalHover hover:!text-clay-semantic-iconCritical "
              onClick={(e) => {
                e.stopPropagation()
                resetField()
              }}
              aria-label="초기화"
            />
          </div>
        ))
        .with(false, () => (
          <div
            ref={refs.setReference}
            {...getReferenceProps()}
            className="typo-clay-label-medium-bold px-clay-2 py-clay-1 rounded-full h-clay-8 text-clay-semantic-textSecondary flex items-center bg-clay-semantic-actionSecondaryTonal border border-solid border-transparent hover:border-clay-semantic-border cursor-pointer"
          >
            <span className="px-clay-1">{label}</span>
            <Plus color="inherit" />
          </div>
        ))
        .exhaustive()}
      {dropdownOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                overflowY: 'auto',
                borderRadius: vars.rounded.medium,
                zIndex: 11,
                background: vars.semantic.color.surface,
                boxShadow: vars.dropShadow.layer,
                padding: vars.spacing[2],
                display: 'flex',
                flexDirection: 'column',
                gap: vars.spacing[1],
                visibility: middlewareData.hide?.referenceHidden
                  ? 'hidden'
                  : 'visible',
              }}
              {...getFloatingProps()}
            >
              {dropdownContent}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
