import { IconX } from '@imwebme/icon'
import { Trans } from 'react-i18next'
import { OrderSectionItemDto } from '~/entities/order-detail'
import { 모델_주문섹션_품목_UI } from '~/entities/order-detail/model/order-section-item'
import { useCurrency } from '~/shared/hooks/use-currency'
import { __ } from '~/shared/i18n'
import { ProductCard002 } from '~t'

export function OrderSectionCancelSectionItem({
  item,
}: {
  item: OrderSectionItemDto
}) {
  const currency = useCurrency()
  const $prod = new 모델_주문섹션_품목_UI(item)
  return (
    <div className="flex justify-between gap-x-[8px]">
      <ProductCard002>
        <ProductCard002.Slot name="image">
          {item.imageUrl && <img src={item.imageUrl} alt="" />}
        </ProductCard002.Slot>
        <ProductCard002.Slot name="name" className="break-keep">
          {item.prodName}
        </ProductCard002.Slot>
        <ProductCard002.Slot name="option">{$prod.옵션}</ProductCard002.Slot>
        <ProductCard002.Slot name="price">
          <div className="flex">
            <div className="grid grid-cols-[auto,auto,auto] grid-rows-2 gap-x-[2px]">
              <div>{item.qty}</div>
              <div className="self-center">
                <IconX className="stroke-[2] text-clay-semantic-icon" />
              </div>
              <div className="self-center">
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: item.itemPrice,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              {item.baseItemPrice !== item.itemPrice && (
                <>
                  <div></div>
                  <div></div>
                  <div className="text-clay-semantic-textDisabled line-through">
                    {__('{{price, 3comma}} {{currency, currency}}', {
                      price: item.baseItemPrice,
                      formatParams: {
                        currency: {
                          currency,
                        },
                      },
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </ProductCard002.Slot>
      </ProductCard002>
      <div>
        <div className="whitespace-nowrap typo-clay-heading-medium">
          <Trans
            i18nKey="<b>{{price, 3comma}}</b> <span>{{currency, currency}}</span>"
            values={{
              price: item.itemPrice,
              formatParams: {
                currency: {
                  currency,
                },
              },
            }}
            components={{ b: <b />, span: <span className="semantic-p14" /> }}
          />
        </div>
      </div>
    </div>
  )
}
