import { queryOptions } from '@tanstack/react-query'
import { getOrderDetailData } from './order-detail.api'

/**
 * 주문 단건 상세 조회
 */
export function orderDetailOptions({ orderCode }: { orderCode: string }) {
  return queryOptions({
    queryKey: ['orderDetail', orderCode, 'imweb'],
    queryFn: getOrderDetailData(orderCode),
    staleTime: 1000 * 10, // 10초, 조건부로 렌더되는 중첩된 자식 컴포넌트에서 동일한 쿼리를 요청할 때 이중 요청되는 현상을 방지
    select: (d) => d.data,
  })
}
