import React from 'react'
import { TOrderDetailBase } from '.'
import { Loading } from '~/shared/components/loading'

const OrderDetailContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.OrderDetail),
  }))
)

interface TOrderDetailPack extends TOrderDetailBase {}
export function OrderDetailPack(props: TOrderDetailPack) {
  return (
    <React.Suspense>
      <OrderDetailContainer {...props} />
    </React.Suspense>
  )
}
