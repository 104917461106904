import { useFormContext } from 'react-hook-form'
import { Clay, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TOrderSearchTabLabelRdo } from '../order-search-tab.rdo'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

const FIELD_KEY = 'name' as const

export function NameField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<TOrderSearchTabLabelRdo>()

  return (
    <Clay marginBottom={vars.spacing[3]}>
      <input
        {...register(FIELD_KEY)}
        placeholder={__('새로운 탭')}
        className={cn(
          'w-full p-clay-1 rounded-clay-medium typo-clay-heading-xlarge-bold text-clay-semantic-text caret-clay-semantic-surfaceInverse',
          'selection:text-clay-semantic-textInverse selection:bg-clay-semantic-surfaceInverse',
          'placeholder:text-clay-semantic-textMinimal',
          'hover:bg-clay-semantic-actionPrimaryTonalHover',
          'focus:outline-2 focus:outline-clay-semantic-focus',
          errors[FIELD_KEY] && 'bg-clay-semantic-actionCriticalTonal'
        )}
        autoComplete="off"
      />
      <Typography
        variant="body-small"
        colorToken="textCritical"
        sx={{ marginTop: vars.spacing[15] }}
      >
        {errors[FIELD_KEY]?.message}
      </Typography>
    </Clay>
  )
}
