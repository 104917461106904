export const ORDER_DETAIL = 'order_detail'

export const OrderDetailKey = {
  getOrderDetailData: [ORDER_DETAIL, 'getOrderDetailData'] as const,
  getSectionData: [ORDER_DETAIL, 'getSectionData'] as const,
  getHeader: [ORDER_DETAIL, 'getHeader'] as const,
  getPayment: [ORDER_DETAIL, 'getPayment'] as const,
  getPurchaser: [ORDER_DETAIL, 'getPurchaser'] as const,
  getReceiver: [ORDER_DETAIL, 'getReceiver'] as const,
  getMemo: [ORDER_DETAIL, 'getMemo'] as const,
  getOrderDetailContextState: ['getOrderDetailContextState'] as const,
  getOrderBadge: ['getOrderBadge'] as const,
} as const
