import { z } from 'zod'
import {
  YN,
  상수_배송결제타입,
  상수_배송타입,
  상수_섹션상태,
  상수_송장배송상태,
  상수_송장입력타입,
  상수_수거타입,
  상수_수거결제타입,
  IMWEB_BANK_CODE,
} from '~/entities/@x'
import { orderSectionItemDto } from '../주문섹션아이템'

const invoiceData = z.object({
  siteCode: z.string(),
  invoiceCode: z.string().nullish(),
  invoiceNo: z.string().nullish(),
  parcelCompanyIdx: z.number().nullish(),
  deliveryStatusCd: z.nativeEnum(상수_송장배송상태).nullish(),
  inputTypeCd: z.nativeEnum(상수_송장입력타입).nullish(),
  isTracking: z.nativeEnum(YN).nullish(),
  isReturn: z.nativeEnum(YN).nullish(),
  wtime: z.string().nullish(),
  mtime: z.string(),
})

const alternativeRefundDataData = z.object({
  bankCode: z.nativeEnum(IMWEB_BANK_CODE),
  bankName: z.string(),
  bankAccount: z.string(),
  accountHolderName: z.string(),
})

const selectSectionReturnInfoData = z.object({
  isCustomerRequest: z.boolean(),
  isExchange: z.nativeEnum(YN),
  reason: z.string(),
  reasonDetail: z.string().nullish(),
  isRetrieved: z.nativeEnum(YN),
  retrieveTypeCd: z.nativeEnum(상수_수거타입),
  retrievePayTypeCd: z.nativeEnum(상수_수거결제타입),
  orderDeliveryCode: z.string().nullish(),
  invoice: invoiceData.nullish(),
  centerCode: z.string().nullish(),
  refundAmount: z.number(),
  refundPoint: z.number(),
  deliveryExtraPrice: z.number(),
  isRefund: z.nativeEnum(YN),
  isAlternativeRefund: z.nativeEnum(YN),
  alternativeRefundData: alternativeRefundDataData.nullish(),
  retrieveMemo: z.string().nullish().describe('회수메모'),
})

const selectSectionCancelInfoData = z.object({
  isCustomerRequest: z.boolean(),
  reason: z.string().nullish(),
  reasonDetail: z.string().nullish(),
  deliveryExtraPrice: z.number(),
  refundAmount: z.number(),
  refundPoint: z.number(),
  isRefund: z.nativeEnum(YN),
  isAlternativeRefund: z.nativeEnum(YN),
  alternativeRefundData: alternativeRefundDataData.nullish(),
})

export const orderSectionDto = z.object({
  siteCode: z.string(),
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderCode: z.string(),
  memberCode: z.string().nullish(),
  statusCd: z.nativeEnum(상수_섹션상태),
  isDeliveryHold: z.nativeEnum(YN),
  deliveryPrice: z.number(),
  deliveryIslandPrice: z.number(),
  deliveryCouponDiscount: z.number().nullish(),
  deliveryPointAmount: z.number(),
  isDeliveryTaxFree: z.nativeEnum(YN).nullish(),
  deliveryExtraPrice: z.number(),
  deliveryTypeCd: z.nativeEnum(상수_배송타입),
  deliveryPayTypeCd: z.nativeEnum(상수_배송결제타입),
  orderDeliveryCode: z.string().nullish(),
  invoiceCode: z.string().nullish(),
  deliverySendTime: z.string().nullish(),
  deliveryCompleteTime: z.string().nullish(),
  isDel: z.nativeEnum(YN).nullish(),
  pickupMemo: z.string().nullish(),
  wtime: z.string().datetime(),
  mtime: z.string().datetime(),
  orderSectionItemList: orderSectionItemDto.array().nullish(),
  invoice: invoiceData.nullish(),
  returnInfo: selectSectionReturnInfoData.nullish(),
  cancelInfo: selectSectionCancelInfoData.nullish(),
})

export type TOrderSectionDto = z.infer<typeof orderSectionDto>
