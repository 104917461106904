import { format, subMilliseconds } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import { pipe } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import { ISO8601Format } from '~/shared/utils'
import {
  OrderSearchFilterFormValues,
  FilterColumnConditionDataDto,
  orderSearchFilterFormDto,
} from '~/entities/order-search-filter'
import {
  defaultFilterFormValues,
  DEFAULT_FILTER_FORM_KEY,
} from './order-search-filter.constants'

/**
 * UTC 타임존 및 ISO8601 포맷으로 변환
 */
export function formatDate(date: Date | number) {
  const timezoneOffsetMs = getTimezoneOffset(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  return pipe(
    date,
    (d) => subMilliseconds(d, timezoneOffsetMs),
    (utcDate) => format(utcDate, ISO8601Format)
  )
}

/**
 * 필터 폼값 중에서 값이 null 혹은 undefined인 필드를 제거, 배열 정렬
 */
export const clearFormValues = (values: OrderSearchFilterFormValues) =>
  pipe(
    values,
    R.filter((value) => value !== undefined && value !== null),
    R.map((item) => (Array.isArray(item) ? item.sort() : item)),
    (result) => result as OrderSearchFilterFormValues
  )

/**
 * 현재 필터 폼값이 주문목록 조회를 위한 컬럼컨디션 DTO로 유효한지 확인
 */
export const validateFilterColumnCondition = (
  formValues: FilterColumnConditionDataDto
) => {
  const {
    searchDateType,
    dateRange,
    deliveryDelayDay,
    startDate,
    endDate,
    minPaymentPrice,
    maxPaymentPrice,
  } = formValues

  // 기간 필터 유효성 검사
  if (
    searchDateType ||
    dateRange ||
    typeof deliveryDelayDay === 'number' ||
    startDate ||
    endDate
  ) {
    const dateFieldValidators = [
      () =>
        searchDateType === 'deliveryDelayDate' &&
        dateRange === 'CUSTOM' &&
        typeof deliveryDelayDay === 'number',
      () =>
        searchDateType &&
        searchDateType !== 'deliveryDelayDate' &&
        dateRange &&
        dateRange !== 'CUSTOM',
      () =>
        searchDateType &&
        searchDateType !== 'deliveryDelayDate' &&
        dateRange === 'CUSTOM' &&
        typeof startDate === 'string' &&
        typeof endDate === 'string',
    ]

    if (dateFieldValidators.every((validator) => !validator())) {
      return false
    }
  }

  // 구매금액 필터 유효성 검사
  if (
    typeof minPaymentPrice === 'number' ||
    typeof maxPaymentPrice === 'number'
  ) {
    const paymentPriceFieldValidators = [
      () =>
        typeof minPaymentPrice === 'number' &&
        typeof maxPaymentPrice === 'number',
    ]

    if (paymentPriceFieldValidators.every((validator) => !validator())) {
      return false
    }
  }

  return true
}

/**
 * 세션스토리지에 저장된 기본 필터 폼값을 가져옴
 */
export function getStoredDefaultFilterForm(): OrderSearchFilterFormValues {
  const storedValue = sessionStorage.getItem(DEFAULT_FILTER_FORM_KEY)
  try {
    return orderSearchFilterFormDto.parse(JSON.parse(storedValue ?? ''))
  } catch {
    return defaultFilterFormValues
  }
}

/**
 * 세션스토리지에 기본 필터 폼값을 저장
 */
export function setStoredDefaultFilterForm(
  formValues: OrderSearchFilterFormValues
) {
  const parsed = orderSearchFilterFormDto.safeParse(formValues)

  if (parsed.success) {
    sessionStorage.setItem(DEFAULT_FILTER_FORM_KEY, JSON.stringify(parsed.data))
  }
}
