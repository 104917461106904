import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBankTransferConfirmDto,
  type TPostPayloadBankTransferConfirmDto,
  postResVirtualConfirmDto,
  postResBankTransferConfirmDto,
  TPostPayloadVirtualConfirmDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/mC7rQ1
 * @description 주문 무통장 결제 수동 입금확인 처리
 * @param orderCode - 주문코드
 */
export function postBankTransferConfirm({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadBankTransferConfirmDto) {
    type R = typeof body
    type T = TPostResBankTransferConfirmDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/payment/bank-transfer/confirm`, body)
      .then((res) => res.data)

    postResBankTransferConfirmDto.parse(result.data)
    return result
  }
}

export function postVirtualConfirm({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadVirtualConfirmDto) {
    type R = typeof body
    type T = TPostPayloadVirtualConfirmDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/payment/virtual/confirm`, body)
      .then((res) => res.data)

    postResVirtualConfirmDto.parse(result.data)
    return result
  }
}
