import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { match, P } from 'ts-pattern'
import { useSuspenseQuery } from '@tanstack/react-query'
import { ExternalProductPreparationPack } from './외부채널_상품준비'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { externalOrderQueryOptions } from '~/entities/external-order'
import React from 'react'
import {
  계산_외부채널_주문섹션,
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '~/entities/external-order/model/external-order-section'
import {
  TypeTabCds,
  useExternalOrderDetailAtom,
} from '../../external-order-detail.store'
import { ExternalDeliveryHold2NullInovicePack } from './외부채널_배송대기_송장등록전'
import { ExternalDeliveryHold2InovicePack } from './외부채널_배송대기_송장등록후'
import { ExternalShippingPack } from './외부채널_배송중'
import { ExternalDeliveryDonePack } from './외부채널_배송완료'
import { ExternalCancelDonePack } from './외부채널_취소완료'
import { ExternalReturnRequestPack } from './외부채널_반품접수'
import { ExternalReturnDonePack } from './외부채널_반품완료'
import { ExternalCancelRequestPack } from './외부채널_취소접수'
import { ExternalDeliveryHoldPack } from './외부채널_배송보류'
import { ExternalPurchaseConfirmationPack } from './외부채널_구매확정'
import { OrderSectionProvider } from '~/shared/hooks/use-section-code'
import { __ } from '~/shared/i18n'
import GrimIconGrayShopping from '~/shared/components/icon/grim-icon-gray-shopping.svg?react'
import { ExternalExchangeRequestPack } from './외부채널_반품접수_교환'
import { ExternalExchangeDonePack } from './외부채널_반품완료_교환'

/**
 * @description 탭코드를 가지고 섹션스펙섹션상태스펙을 반환하는 함수
 */
export function tabBySpecs(value?: TypeTabCds) {
  switch (value) {
    case '배송':
      return 스펙_외부채널_주문섹션.섹션상태카테고리.배송
    case '취소':
      return 스펙_외부채널_주문섹션.섹션상태카테고리.취소
    case '반품':
      return 스펙_외부채널_주문섹션.섹션상태카테고리.반품
    default:
      return []
  }
}

export function ExternalOrderSectionList() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))
  const tabCd = useExternalOrderDetailAtom.get({ orderCode })
  return pipe(
    data.orderSectionList,
    O.fromNullable,
    O.fold(
      () => null,
      flow(
        계산_외부채널_주문섹션.filterSomeBySpecs(tabBySpecs(tabCd)),
        계산_외부채널_주문섹션.sortBySpecs([
          스펙_외부채널_주문섹션.상품준비,
          스펙_외부채널_주문섹션.배송대기_송장등록전,
          스펙_외부채널_주문섹션.배송대기_송장등록후,
          스펙_외부채널_주문섹션.배송보류,
          스펙_외부채널_주문섹션.배송중,
          스펙_외부채널_주문섹션.배송완료,
          스펙_외부채널_주문섹션.구매확정,
        ]),
        (orderSectionList) => {
          if (orderSectionList.length === 0) {
            return O.none
          }
          return O.some(orderSectionList)
        },
        O.fold(
          () =>
            pipe(
              [null],
              A.map(() => (
                <div className="grid place-content-center h-[320px]">
                  <div className="space-y-clay-3">
                    <div className="flex justify-center">
                      <GrimIconGrayShopping />
                    </div>
                    <div>
                      {__('{{상태}} 품목이 없어요.', {
                        상태: tabCd,
                      })}
                    </div>
                  </div>
                </div>
              ))
            ),
          flow(
            A.map((section) => {
              const $section = new 모델_외부채널_주문섹션(section)
              return (
                <React.Fragment
                  key={`${orderCode}-${section.orderSectionCode}`}
                >
                  <OrderSectionProvider sectionCode={section.orderSectionCode}>
                    {match<boolean>(true)
                      .with(
                        스펙_외부채널_주문섹션.상품준비.isSatisfiedBy($section),
                        () => <ExternalProductPreparationPack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.배송대기_송장등록전.isSatisfiedBy(
                          $section
                        ),
                        () => <ExternalDeliveryHold2NullInovicePack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.배송대기_송장등록후.isSatisfiedBy(
                          $section
                        ),
                        () => <ExternalDeliveryHold2InovicePack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.배송중.isSatisfiedBy($section),
                        () => <ExternalShippingPack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.배송완료.isSatisfiedBy($section),
                        () => <ExternalDeliveryDonePack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.취소완료.isSatisfiedBy($section),
                        () => <ExternalCancelDonePack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.교환접수.isSatisfiedBy($section),
                        () => <ExternalExchangeRequestPack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.반품접수.isSatisfiedBy($section),
                        () => <ExternalReturnRequestPack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.교환완료.isSatisfiedBy($section),
                        () => <ExternalExchangeDonePack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.반품완료.isSatisfiedBy($section),
                        () => <ExternalReturnDonePack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.취소접수.isSatisfiedBy($section),
                        () => <ExternalCancelRequestPack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.배송보류.isSatisfiedBy($section),
                        () => <ExternalDeliveryHoldPack />
                      )
                      .with(
                        스펙_외부채널_주문섹션.구매확정.isSatisfiedBy($section),
                        () => <ExternalPurchaseConfirmationPack />
                      )
                      .otherwise(() => null)}
                  </OrderSectionProvider>
                </React.Fragment>
              )
            })
          )
        )
      )
    )
  )
}
