import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryShippingApplySchema,
  type TPostPayloadBulkActionSectionDeliveryShippingApplySchema,
  postResBulkActionSectionDeliveryShippingApplySchema,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/QchX5B
 * @description 주문 배송 중 대량 처리 등록
 */
export async function postBulkActionSectionDeliveryShippingApply({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryShippingApplySchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryShippingApplySchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/shipping', body)
    .then((res) => res.data)

  postResBulkActionSectionDeliveryShippingApplySchema.parse(result.data)
  return result
}
