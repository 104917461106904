import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Flex, Modal, Textfield } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { useDeliverySetupAtom } from '~/container/order-create/order-create.store'
import {
  koreanDelivDirectInputSchema,
  TKoreanDelivDirectInputSchema,
} from '~/container/order-create/order-create.schema'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { SearchSM } from '@imwebme/clay-icons'

export function DirectInputKorean() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const {
    register,
    formState: { errors },
  } = useForm<TKoreanDelivDirectInputSchema>({
    resolver: zodResolver(koreanDelivDirectInputSchema),
    mode: 'onTouched',
  })
  const [daumPostCodeModalOpen, setDaumPostCodeModalOpen] =
    React.useState(false)

  return (
    <>
      <Flex columnGap={vars.spacing[25]}>
        <Textfield.Set flex={1}>
          <Textfield.Legend text={__('받는 분')} />
          <Textfield.Box>
            <Textfield.Input
              {...register('receiverName')}
              value={deliverySetup.receiverName ?? ''}
              onChangeText={(v) => setDeliverySetup({ receiverName: v })}
              isValid={errors.receiverName ? false : undefined}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>
            {errors.receiverName?.message}
          </Textfield.HelperText>
        </Textfield.Set>
        <Textfield.Set flex={1}>
          <Textfield.Legend text={__('연락처')} />
          <Textfield.Box>
            <Textfield.Input
              {...register('receiverCall')}
              value={deliverySetup.receiverCall ?? ''}
              onChangeText={(v) =>
                setDeliverySetup({ receiverCall: v.replace(/[^0-9]/g, '') })
              }
              isValid={errors.receiverCall ? false : undefined}
              type="tel"
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>
            {errors.receiverCall?.message}
          </Textfield.HelperText>
        </Textfield.Set>
      </Flex>
      <Flex.Column rowGap={vars.spacing[3]}>
        <Flex columnGap={vars.spacing[25]}>
          <Textfield.Set flex={1}>
            <Textfield.Legend text={__('주소정보')} />
            <Textfield.Box variant="filled" size="medium">
              <Textfield.Addon>
                <SearchSM colorToken="icon-sub" />
              </Textfield.Addon>
              <Textfield.Input
                value={deliverySetup.addr1 ?? ''}
                placeholder={__('도로명 혹은 지번 주소')}
                onFocus={() => setDaumPostCodeModalOpen(true)}
              />
            </Textfield.Box>
          </Textfield.Set>
          <Button
            variant="outlined"
            text={__('주소 찾기')}
            onClick={() => setDaumPostCodeModalOpen(true)}
            sx={{ marginTop: '30px' }}
          />
        </Flex>
        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              {...register('addr2')}
              value={deliverySetup.addr2 ?? ''}
              onChangeText={(v) => setDeliverySetup({ addr2: v })}
              isValid={errors.addr2 ? false : undefined}
              placeholder={__('상세주소')}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>{errors.addr2?.message}</Textfield.HelperText>
        </Textfield.Set>
      </Flex.Column>

      <DaumPostcodeModal
        isOpen={daumPostCodeModalOpen}
        setClose={() => setDaumPostCodeModalOpen(false)}
        onComplete={(address) => {
          setDeliverySetup({ ...address, addr2: undefined })
        }}
      />
    </>
  )
}

function DaumPostcodeModal({
  isOpen,
  setClose,
  onComplete,
}: {
  isOpen: boolean
  setClose: () => void
  onComplete: (data: { addr1: string; zipcode: string }) => void
}) {
  return (
    <Modal width="small" isOpen={isOpen} setClose={setClose}>
      <Modal.Header title="주소 검색" closeButton />

      <DaumPostcodeEmbed
        onComplete={(data) => {
          let fullAddress = data.address
          let extraAddress = ''

          if (data.addressType === 'R') {
            if (data.bname !== '') {
              extraAddress += data.bname
            }
            if (data.buildingName !== '') {
              extraAddress +=
                extraAddress !== ''
                  ? `, ${data.buildingName}`
                  : data.buildingName
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
          }

          onComplete({
            addr1: fullAddress,
            zipcode: data.zonecode,
          })
          setClose()
        }}
      />
    </Modal>
  )
}
