import { z } from 'zod'
import { deliverCode, goodsflowStatus } from './goodsflow.constants'

const delivery = z.object({
  centerCode: z.string(),
  deliverCode: z.nativeEnum(deliverCode),
  centerZipCode: z.string(),
  centerAddress: z.string(),
  centerAddressDetail: z.string(),
  deliverName: z.string(),
  status: z.nativeEnum(goodsflowStatus),
})

export const goodsflowSchema = z.array(delivery)
