import React from 'react'
import { __ } from '~/shared/i18n'
import * as Tooltip from '@radix-ui/react-tooltip'
import { cn } from '~/shared/utils'

const Title = ({ children }: { children: React.ReactNode }) => {
  const textRef = React.useRef<HTMLDivElement>(null)
  const [textOverflow, setTextOverflow] = React.useState(false)

  React.useEffect(() => {
    if (textRef.current) {
      const text = textRef.current
      setTextOverflow(text.scrollWidth > text.clientWidth)
    }
  }, [textRef.current])

  const renderText = () => (
    <div
      ref={textRef}
      className={cn(
        'typo-clay-body-medium-bold mb-[4px] truncate',
        textOverflow && 'cursor-default'
      )}
    >
      {children}
    </div>
  )

  if (textOverflow) {
    /**
     * clay tooltip을 사용하지 못하는 이유 https://github.com/imwebme/clay/issues/558
     */
    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>{renderText()}</Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="bg-clay-semantic-surface text-clay-semantic-text border-none py-[6px] px-[8px] rounded-[8px] max-w-[256px] w-max typo-clay-label-small _drop-shadow">
              {children}
              <Tooltip.Arrow className="fill-clay-semantic-surface" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    )
  }
  return renderText()
}

const Description = ({ children }: { children: React.ReactNode }) => {
  const textRef = React.useRef<HTMLDivElement>(null)
  const [textOverflow, setTextOverflow] = React.useState(false)

  React.useEffect(() => {
    if (textRef.current) {
      const text = textRef.current
      setTextOverflow(text.scrollWidth > text.clientWidth)
    }
  }, [textRef.current])

  const renderText = () => (
    <div
      ref={textRef}
      className={cn(
        'typo-clay-body-small text-clay-semantic-textSub truncate whitespace-break-spaces',
        textOverflow && 'cursor-default'
      )}
    >
      {children}
    </div>
  )

  if (textOverflow) {
    /**
     * clay tooltip을 사용하지 못하는 이유 https://github.com/imwebme/clay/issues/558
     */
    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>{renderText()}</Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="bg-clay-semantic-surface text-clay-semantic-text border-none py-[6px] px-[8px] rounded-[8px] max-w-[256px] w-max  typo-clay-label-small">
              {children}
              <Tooltip.Arrow className="bg-clay-semantic-surface" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    )
  }
  return renderText()
}

const Content = ({
  children,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div className="mt-[8px]" {...props}>
    {children}
  </div>
)

export const Item = ({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
)

Item.Title = Title
Item.Description = Description
Item.Content = Content
