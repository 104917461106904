import { match } from 'ts-pattern'
import { 상수_배송지보기모드 } from './delivery.constants'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { useDeliveryViewMode } from './delivery.store'
import { DeliverySummary } from './components/delivery-summary'
import { DeliveryDetail } from './components/delivery-detail'
import { TDeliveryDto } from '~/entities/@dto'

interface Props {
  boundaryRef?: React.RefObject<HTMLElement>
  orderDeliveryList: TDeliveryDto[]
  unipassNumber: string | null
}

export function Delivery({
  orderDeliveryList,
  boundaryRef,
  unipassNumber,
}: Props) {
  const tabCode = useTabCode()
  const viewMode = useDeliveryViewMode(tabCode)

  return (
    <>
      {match(viewMode)
        .with(상수_배송지보기모드.요약보기, () => (
          <DeliverySummary
            {...{
              boundaryRef,
              orderDeliveryList,
              unipassNumber,
            }}
          />
        ))
        .with(상수_배송지보기모드.상세보기, () => (
          <DeliveryDetail {...{ delivery: orderDeliveryList, unipassNumber }} />
        ))
        .exhaustive()}
    </>
  )
}
