import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import GrimIconGrayUsers from '~/shared/components/icon/grim-icon-gray-users.svg?react'
import { __ } from '~/shared/i18n'

export function NonMemberView() {
  return (
    <Flex.Column
      rowGap={vars.spacing[2]}
      paddingTop={vars.spacing[6]}
      paddingBottom={vars.spacing[12]}
      alignItems="center"
    >
      <GrimIconGrayUsers />
      <Typography variant="body-medium">
        {__('비회원 주문은 할인 설정을 할 수 없어요.')}
      </Typography>
    </Flex.Column>
  )
}
