import React from 'react'
import { debounce } from 'lodash'

/**
 * 드랍다운이 뷰포트 안에 들어오도록 드랍다운 높이를 계산
 */
export function useDropdownHeight({ maxHeight }: { maxHeight: number }) {
  const [height, setHeight] = React.useState(maxHeight)
  const fieldLayoutRef = React.useRef<HTMLDivElement>(null)

  const updateHeight = React.useMemo(
    () =>
      debounce(() => {
        if (fieldLayoutRef.current) {
          const rect = fieldLayoutRef.current.getBoundingClientRect()
          const viewportHeight = window.innerHeight

          const availableHeight = Math.max(viewportHeight - rect.top - 116, 0)

          const newHeight = Math.min(availableHeight, maxHeight)

          setHeight(newHeight)
        }
      }, 100),
    [maxHeight]
  )

  React.useEffect(() => {
    updateHeight()

    window.addEventListener('resize', updateHeight)
    window.addEventListener('scroll', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
      window.removeEventListener('scroll', updateHeight)
    }
  }, [updateHeight])

  return { fieldLayoutRef, dropdownHeight: height }
}
