import React from 'react'
import { useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import { Selector } from './components'
import { FormControlProp } from './order-search-filter-form-fields.type'
import { Textfield } from '@imwebme/clay-components'

interface Props extends FormControlProp {}

export const PaymentPriceField = ({ control }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const {
    onChange: onMinPaymentPriceChange,
    value: minPaymentPriceValue,
    ...minPaymentPriceInputProps
  } = useController({
    name: 'minPaymentPrice',
    control,
  }).field

  const {
    onChange: onMaxPaymentPriceChange,
    value: maxPaymentPriceValue,
    ...maxPaymentPriceInputProps
  } = useController({
    name: 'maxPaymentPrice',
    control,
  }).field

  const handleChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const name = evt.target.name

      if (evt.target.value === '') {
        name === 'minPaymentPrice'
          ? onMinPaymentPriceChange(null)
          : onMaxPaymentPriceChange(null)
        return
      }

      const parsed = parseInt(evt.target.value)

      if (!isNaN(parsed) && parsed >= 0) {
        name === 'minPaymentPrice'
          ? onMinPaymentPriceChange(parsed)
          : onMaxPaymentPriceChange(parsed)
      }
    },
    []
  )

  const handleResetFields = React.useCallback(() => {
    onMinPaymentPriceChange(null)
    onMaxPaymentPriceChange(null)
  }, [])

  const fieldLabel = React.useMemo(() => {
    let baseLabel = __('구매금액')

    const formattedPrice = (price: number) =>
      __('{{price, 3comma}}', {
        price,
      })

    if (
      typeof minPaymentPriceValue === 'number' &&
      typeof maxPaymentPriceValue === 'number'
    ) {
      baseLabel += `: ${formattedPrice(minPaymentPriceValue)}~${formattedPrice(
        maxPaymentPriceValue
      )}`
    } else if (typeof minPaymentPriceValue === 'number') {
      baseLabel += `: ${formattedPrice(minPaymentPriceValue)}~`
    } else if (typeof maxPaymentPriceValue === 'number') {
      baseLabel += `: ~${formattedPrice(maxPaymentPriceValue)}`
    }

    return baseLabel
  }, [minPaymentPriceValue, maxPaymentPriceValue])

  React.useEffect(() => {
    const resetFieldsOnExit = () => {
      if (dropdownOpen) {
        return
      }

      if (
        typeof minPaymentPriceValue !== 'number' ||
        typeof maxPaymentPriceValue !== 'number'
      ) {
        return handleResetFields()
      }

      if (minPaymentPriceValue > maxPaymentPriceValue) {
        handleResetFields()
      }
    }

    resetFieldsOnExit()
  }, [dropdownOpen, minPaymentPriceValue, maxPaymentPriceValue])

  return (
    <Selector
      label={fieldLabel}
      isActive={
        typeof minPaymentPriceValue === 'number' ||
        typeof maxPaymentPriceValue === 'number'
      }
      resetField={handleResetFields}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="space-y-clay-2 px-clay-5 py-clay-4 w-[385px]">
          <div className="typo-clay-label-medium text-clay-semantic-text">
            {__('구매금액')}
          </div>
          <div className="flex items-center gap-x-clay-2">
            <Textfield
              type="tel"
              variant="secondary"
              value={minPaymentPriceValue ?? ''}
              onChange={handleChange}
              placeholder="0"
              autoComplete="off"
              {...minPaymentPriceInputProps}
            />
            ~
            <Textfield
              type="tel"
              variant="secondary"
              value={maxPaymentPriceValue ?? ''}
              onChange={handleChange}
              placeholder="0"
              autoComplete="off"
              {...maxPaymentPriceInputProps}
            />
          </div>
        </div>
      }
    />
  )
}
