import { z } from 'zod'
import { __ } from '~/shared/i18n'

export const patchResExchangedItemsDto = z.any()

export const targetItemInformationDto = z.object({
  orderSectionItemCode: z.string().describe('변경하려는 품목의 코드'),
  qty: z.number().describe('변경하려는 품목의 수량'),
})
export const patchPayloadExchangedItemsDto = z.object({
  targetItemInformation: z
    .array(targetItemInformationDto)
    .describe('변경하려는 품목의 코드와 수량'),
  deliveryExtraPriceType: z
    .union([z.literal('ADD'), z.literal('SUB')])
    .describe('추가 배송료 청구/차감 구분 ADD | SUB'),
  deliveryExtraPrice: z
    .number()
    .max(200000, __('최대 200,000원 가능'))
    .describe('추가배송비'),
})
