import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { __ } from '~/shared/i18n'
import { useOrderAtom } from '~/container/orders/orders-table/orders-table-body'
import { DropdownMenu } from '~/shared/components/dropdown-menu'

export function PrintPack() {
  return null
}

PrintPack.Button = function () {
  const tabCode = useTabCode()
  const selectedOrderCodes = useOrderAtom.codeSelection.get({ tabCode })
  const resetCodeSelection = useOrderAtom.codeSelection.reset({ tabCode })
  const resetRowSelection = useOrderAtom.rowSelection.reset({ tabCode })
  const handelPrint = () => {
    window.parent.postMessage(
      {
        source: 'print_order',
        message: {
          orderCode: selectedOrderCodes.value,
        },
      },
      '*'
    )
    resetCodeSelection()
    resetRowSelection()
  }
  return (
    <DropdownMenu.Item onSelect={handelPrint}>
      {__('주문서 출력')}
    </DropdownMenu.Item>
  )
}
