import { OrderFormData } from '~/entities/order-detail'
import { Item } from './components'

export function Select({ title, description, value }: OrderFormData) {
  if (value[0] === undefined) {
    return null
  }
  return (
    <Item>
      <Item.Title>{title}</Item.Title>
      <Item.Description>{description}</Item.Description>
      <Item.Content className="typo-clay-body-medium text-clay-semantic-text">
        {value[0]}
      </Item.Content>
    </Item>
  )
}
