import { __ } from '~/shared/i18n'
import { pipe } from 'fp-ts/function'

// 허용가능한 확장자 목록!
const ALLOW_FILE_EXTENSION = 'csv,xlsx'
const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024 // 5MB

/**
 * 해당 함수의 기능은 .을 제거한 순수 파일 확장자를 return해준다.
 * @param originalFileName 업로드할 파일명
 * @returns .을 제거한 순수 파일 확장자(png, jpg 등)
 */
export function removeFileName(originalFileName: string): string {
  // 마지막 .의 위치를 구한다
  // 마지막 .의 위치다음이 파일 확장자를 의미한다
  const lastIndex = originalFileName.lastIndexOf('.')

  // 파일 이름에서 .이 존재하지 않는 경우이다.
  // 이경우 파일 확장자가 존재하지 않는경우(?)를 의미한다.
  if (lastIndex < 0) {
    return ''
  }

  // substring을 함수를 이용해 확장자만 잘라준다
  // lastIndex의 값은 마지막 .의 위치이기 때문에 해당 위치 다음부터 끝까지 문자열을 잘라준다.
  // 문자열을 자른 후 소문자로 변경시켜 확장자 값을 반환 해준다.
  return originalFileName.substring(lastIndex + 1).toLowerCase()
}

/**
 * 파일 확장자를 검사해주는 함수이다.
 * @param param
 * @returns true: 가능 확장자, false : 불가능 확장자
 */
export function fileExtensionValid({ name }: { name: string }): boolean {
  // 파일 확장자
  const extension = removeFileName(name)

  /**
   * 허용가능한 확장자가 있는지 확인하는 부분은 indexOf를 사용해도 괜찮고,
   * 새롭게 나온 includes를 사용해도 괜찮고, 그밖의 다른 방법을 사용해도 좋다.
   * 성능과 취향의 따라 사용하면 될것같다.
   *
   * indexOf의 경우
   * 허용가능한 확장자가 있을경우
   * ALLOW_FILE_EXTENSION 상수의 해당 확장자 첫 index 위치값을 반환
   */
  if (!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === '') {
    // 해당 if문이 수행되는 조건은
    // 1. 허용하지 않은 확장자일경우
    // 2. 확장자가 없는경우이다.
    return false
  }
  return true
}

/**
 * 파일 validation
 *
 * @param file  업로드할 파일
 * @param cbMessage 메세지를 전달할 callback 함수
 * @returns true: validation 통과, false: validation 실패
 */
function fileValidation(file?: File, cbMessage?: (message: string) => void) {
  if (file === undefined) {
    pipe(__('파일을 선택해주세요.'), (msg) => cbMessage?.(msg))
    return false
  }
  // 파일 확장자 체크
  if (!fileExtensionValid(file)) {
    pipe(
      __(
        '업로드 가능한 확장자가 아닙니다. [가능한 확장자 : {{ALLOW_FILE_EXTENSION}}]',
        { ALLOW_FILE_EXTENSION }
      ),
      (msg) => cbMessage?.(msg)
    )
    return false
  }

  // 파일 용량 체크
  if (file.size > FILE_SIZE_MAX_LIMIT) {
    pipe(__('업로드 가능한 최대 용량은 5MB입니다. '), (msg) => cbMessage?.(msg))
    return false
  }

  return true
}
/**
 * 파일 선택 onChangeHandler
 * 해당 method에서는 업로드할 파일에대해서 validaion을 하고
 * file state에 값을 할당한다
 * @returns (e: React.ChangeEvent<HTMLInputElement>) => void
 */
export function fileUploadValidation({
  onChangeMessage,
  onChangeFile,
}: {
  onChangeMessage: (message: string) => void
  onChangeFile: (file: File) => void
}) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget
    const files = (target.files as FileList)[0]

    if (!fileValidation(files, onChangeMessage)) {
      return
    }

    onChangeFile(files)
  }
}

export function fileUploadDragValidation({
  onChangeMessage,
  onChangeFile,
}: {
  onChangeMessage: (message: string) => void
  onChangeFile: (file: File) => void
}) {
  return (e: DragEvent) => {
    if (e.dataTransfer) {
      const _file = e.dataTransfer.files[0]
      if (!fileValidation(_file, onChangeMessage)) {
        return
      }
      onChangeFile(_file)
    } else {
      pipe(__('드래그앤 드롭을 지원하지 않는 브라우저입니다.'), onChangeMessage)
      return
    }
  }
}
