import React from 'react'
import type { TRetrieveRequestBase } from './retrieve-request.type'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { useRetrieveRequestAtom } from './retrieve-request.store'

const RetrieveRequest = React.lazy(() =>
  import('./retrieve-request').then((module) => ({
    default: React.memo(module.RetrieveRequest),
  }))
)

interface TRetrieveRequestContainer extends TRetrieveRequestBase {}
export function RetrieveRequestContainer(props: TRetrieveRequestContainer) {
  const [open, setState] = useRetrieveRequestAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    // close될때
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <RetrieveRequest {...props} onSuccess={() => onOpenChange(false)} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
