import React from 'react'
import { OrderSectionDto } from '~/entities/order-detail'
import { ChevronDown, Plus } from '@imwebme/clay-icons'
import { SafeImageLoading } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { OrderQuantityPreviewPack } from '~/container/order-preview'
import ProductThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { TExternalOrderSectionDto } from '~/entities/external-order'

interface Props {
  orderSectionList: (OrderSectionDto | TExternalOrderSectionDto)[]
  totalQty: number
  boundaryRef?: React.RefObject<HTMLElement>
  orderCode: string
  isExternalOrder: boolean
}

export function OrderItemsSummary({
  orderSectionList,
  totalQty,
  boundaryRef,
  orderCode,
  isExternalOrder,
}: Props) {
  return (
    <OrderQuantityPreviewPack
      {...{ boundaryRef, orderCode, isOrderItemsColumn: true, isExternalOrder }}
    >
      <div className="flex flex-grow min-w-[49px]">
        {getTop3Imgs(orderSectionList).map((img, idx) => (
          <div
            key={idx}
            className="relative w-clay-6 h-clay-6 rounded-full overflow-hidden border border-clay-semantic-border -mr-clay-4"
          >
            <SafeImageLoading
              fallback={
                <ProductThumbFallback className="w-full h-full object-cover bg-white" />
              }
              src={img ?? undefined}
            />
            {idx === 2 && totalQty > 3 && (
              <>
                <div className="w-full h-full rounded-full bg-black/50 absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"></div>
                <div className="flex items-center absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-clay-semantic-iconOn">
                  <Plus color="inherit" size="small" />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <span className="w-[25px] text-center typo-clay-body-medium text-clay-semantic-text">
        {totalQty > 99 ? __('99+') : totalQty}
      </span>
      <ChevronDown />
    </OrderQuantityPreviewPack>
  )
}

function getTop3Imgs(
  orderSectionList: (OrderSectionDto | TExternalOrderSectionDto)[]
) {
  const images: (string | null | undefined)[] = []

  for (const section of orderSectionList) {
    for (const item of section.orderSectionItemList || []) {
      if (images.length === 3) {
        break
      }
      images.push(item.imageUrl)
    }
  }

  return images
}
