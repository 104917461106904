import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import { 상수_판매채널 } from '~/entities/@x'
import { useNavigate, useParams } from '~/router'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

/**
 * @README
 * URL 구조를 변경하면서 `/external-order/:orderCode`로 배포되었던 알림 메세지 대응
 * `/external-order/:orderCode`로 접근하는 경우 `/order/:saleChannelIdx/:orderCode`로 redirect
 */
export default function () {
  const { saleChannel } = useParams('/order/:saleChannel')
  const naviate = useNavigate()

  React.useEffect(() => {
    naviate('/order/:saleChannel/:orderCode', {
      replace: true,
      params: {
        saleChannel: pipe(상수_판매채널.아임웹, String),
        orderCode: saleChannel,
      },
    })
  }, [])

  return <Loading />
}
