import { useFieldArray, useFormContext } from 'react-hook-form'
import { OrderFormData } from '~/entities/order-detail'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import * as Field from './order-form-data-edit-field'
import {
  TOrderFormDataEditFormSchema,
  TFieldProps,
} from './order-form-data-edit.type'
import { __, __e } from '~/shared/i18n'
import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { DevTool } from '~/app/provider/react-hook-form'
import { 상수_추가정보타입 } from '~/entities/@x'
import { useHasScroll } from './order-form-data-edit.hook'

const FieldComponentMap: Record<
  OrderFormData['inputTypeCd'],
  (props: TFieldProps) => EmotionJSX.Element | null
> = {
  [상수_추가정보타입.한줄텍스트]: Field.TextField,
  [상수_추가정보타입.여러줄텍스트]: Field.TextareaField,
  [상수_추가정보타입.단일선택]: Field.RadioField,
  [상수_추가정보타입.목록선택]: Field.SelectField,
  [상수_추가정보타입.복수선택]: Field.CheckboxField,
  [상수_추가정보타입.날짜형식]: Field.DateField,
  [상수_추가정보타입.시간형식]: Field.TimeField,
  [상수_추가정보타입.파일]: Field.FileField,
}

export function OrderFormDataEdit({
  orderFormDataList,
  handleModalClose,
}: {
  orderFormDataList: OrderFormData[]
  handleModalClose: () => void
}) {
  const { ref, hasScroll } = useHasScroll<HTMLDivElement>()

  const formMethods = useFormContext<TOrderFormDataEditFormSchema>()

  const { fields } = useFieldArray({
    control: formMethods.control,
    name: 'form',
  })

  return (
    <>
      <Modal.Header title={__('주문 추가 정보')} />

      <Modal.Body ref={ref}>
        <Flex.Column gap={vars.spacing[5]}>
          {orderFormDataList.map((item, index) => {
            const FieldComponent = FieldComponentMap[item.inputTypeCd]
            if (!FieldComponent) {
              return null
            }

            // 파일 아이템은 BO에서 수정기능이 없다. 읽기전용 UI만 제공한다.
            if (
              item.inputTypeCd === 상수_추가정보타입.파일 &&
              !item.fileDownloadUrl
            ) {
              return null
            }

            return (
              <Clay key={item.idx}>
                <Typography
                  variant="body-medium-bold"
                  sx={{ marginBottom: vars.spacing[1] }}
                >
                  {item.title}{' '}
                  {item.isRequire === 'N' && (
                    <Typography as="span" colorToken="textSub">
                      ({__('선택 사항')})
                    </Typography>
                  )}
                </Typography>

                <Typography
                  variant="body-small"
                  colorToken="textSub"
                  sx={{ marginBottom: vars.spacing[3] }}
                >
                  {item.description}
                </Typography>

                <FieldComponent
                  key={fields[index].id}
                  {...{
                    index,
                    formConfigValue: item.formConfigValue,
                    fileDownloadUrl: item.fileDownloadUrl,
                    wtime: item.wtime,
                  }}
                />
              </Clay>
            )
          })}
        </Flex.Column>
      </Modal.Body>

      <Clay
        sx={{
          padding: hasScroll
            ? `${vars.spacing[3]} ${vars.spacing[6]} ${vars.spacing[4]}`
            : `0 ${vars.spacing[6]} ${vars.spacing[4]}`,
          boxShadow: hasScroll ? vars.dropShadow.layer : undefined,
        }}
      >
        <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
          <Button
            native={{ type: 'button' }}
            variant="secondary"
            text={__('취소')}
            onClick={handleModalClose}
          />
          <Button
            native={{ type: 'submit' }}
            variant="primary"
            isDisabled={formMethods.formState.isSubmitting}
            text={__('저장')}
          />
        </ButtonGroup>
      </Clay>

      <DevTool control={formMethods.control} />
    </>
  )
}
