import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TCancelReasonCustomerBase } from './cancel-reason-customer.type'
import { useCancelReasonCustomerAtom } from './cancel-reason-customer.store'
import { ContentsBanner } from '@imwebme/clay-components'

const CancelReasonCustomer = React.lazy(() =>
  import('./cancel-reason-customer').then((module) => ({
    default: React.memo(module.CancelReasonCustomer),
  }))
)

interface TCancelReasonCustomerContainer extends TCancelReasonCustomerBase {}
export function CancelReasonCustomerContainer(
  props: TCancelReasonCustomerContainer
) {
  const [open, setState] = useCancelReasonCustomerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <>
      <ContentsBanner
        type="secondary"
        variant="critical"
        text={__('구매자가 접수한 취소건이예요.')}
        button={{ text: __('사유보기'), onClick: () => onOpenChange(true) }}
      />
      <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay />
          <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
            <CancelReasonCustomer {...props} />
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </>
  )
}
