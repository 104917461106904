import { YN } from '~/entities/@x'
import { TProdAddResult } from '~/widgets/product-add-modal'
import { TProdSelect } from '../order-create.schema'

/** 상품선택모달에서 받은 데이터를 변환  */
export function converter(data: TProdAddResult): TProdSelect {
  return data.map((el) => ({
    prodOptionDetailCode: el.optionDetailCode,
    isIndividualOption: el.prod.isIndividualOption ? YN.Y : YN.N,
    isRequireOption: el.isRequireOption ? YN.Y : YN.N,
    optionDataList: el.options
      ?.filter((opt) => opt.optionCode && opt.optionType && opt.valueCode)
      .map((opt) => {
        const { label, ...rest } = opt
        return rest as Required<typeof rest>
      }),
    prodCode: el.prod.prodCode,
    qty: 1,
    itemPrice: el.price,
    _id: el.id,
    _prodName: el.prod.prodName,
    _prodImg: el.prod.imageUrls?.[0],
    _optionLabels: el.options?.map((opt) => opt.label),
    _baseItemPrice: el.price,
    _delivCountryList: el.prod.delivCountryList ?? [],
    _delivTypeList: el.prod.delivTypeList ?? [],
    _delivPayTypeList: el.prod.delivPayTypeList ?? [],
    _prodType: el.prod.type,
    _useUnipassNumber: el.prod.useUnipassNumber,
    _useCoupon: el.prod.useCoupon,
    _usePoint: el.prod.usePoint,
    _useShoppingGroup: el.prod.useShoppingGroup,
  }))
}
