import React from 'react'
import { OrderCancelInfo } from './view'
import { useQuery } from '@tanstack/react-query'
import { pipe, flow } from 'fp-ts/function'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { useOrderSectionAtom } from '~t'
import { COrder } from '../class-order'
import { type COrderSection } from '../class-order-section'
import { SingleOrderCheck } from '../order-api'
import type { TC3payloadItems } from './order-cancel-info.type'
import { orderDetailOptions } from '~/entities/order-detail'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __ } from '~/shared/i18n'
import { 상수_주문타입 } from '~/entities/@x'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'

function OrderCancelInfoPage() {
  const orderCode = useOrderCode()
  if (orderCode === undefined) {
    throw new Error(
      '🚀 ~ file: order-cancel-info.tsx:17 ~ OrderCancelInfoPage ~ OrderCancelInfoPage:'
    )
  }
  // server status
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: [orderDetailOptions({ orderCode }).queryKey[0], orderCode],
    queryFn: () => SingleOrderCheck(orderCode),
    select: flow((d) => d.data),
  })
  const updateItems = useOrderSectionAtom.valueUpdateAll()

  if (isSuccess && data && updateItems) {
    // 상품준비 섹션
    // OSS01 섹션인지 확인하는 스펙
    const sections = new COrder(data).orderSectionList
    const isStatusCdOSS01: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS01'
    )
    // OSS02 섹션이면서 인보이스가 없는 경우의 스펙
    // 인보이스가 없는 경우는 invoice가 없는 경우와 invocice가 있지만 invociceNo가 없는 경우가 있다.
    const isStatusCdOSS02: ISpecification<COrderSection> = new Spec(
      (candidate) =>
        candidate.statusCd === 'OSS02' &&
        (candidate.invoice === null || candidate.invoice.invoiceNo === '')
    )

    const result = pipe(
      data.orderTypeCd,
      (주문타입) =>
        주문타입 === 상수_주문타입.디지털 ||
        주문타입 === 상수_주문타입.그룹패스,
      E.fromPredicate(
        (취소가능주문) => 취소가능주문,
        () => sections
      ),
      E.fold(
        (_sections) =>
          pipe(
            _sections,
            A.filter((section) =>
              isStatusCdOSS01.or(isStatusCdOSS02).isSatisfiedBy(section)
            )
          ),
        () => sections
      )
    )

    const c3payloadItems: TC3payloadItems = pipe(
      result,
      // 주문서에 존재했던 섹션데이터에서 UI상 업데이트된 섹션테이터를 찾아서 맵핑한다.
      A.map(
        flow(
          (section) => section.meta,
          (s) => ({
            orderSectionCode: s.orderSectionCode,
            targetItemInformation: pipe(
              s.orderSectionItemList.map((item) =>
                pipe(
                  updateItems,
                  A.findFirst((e) => e.id === s.orderSectionCode),
                  // 첫번째 value를 가져오기
                  O.map((e) => e.value),
                  // 첫번째 value에서 itemId를 가진 아이템을 찾기
                  O.map((e) => e.find((v) => v.id === item.orderItemCode)),
                  // TOrderAddConfirm.items의 타입에 맞게 변환
                  // updateItems에서 qtyChange, itemPrice, baseItemPrice만 맵핑하고 나머지는 섹션 아이템값으로 넣어준다.
                  O.fold(
                    () => undefined,
                    flow(
                      // undefined를 필터링한다.
                      O.fromNullable,
                      O.fold(
                        () => undefined,
                        (e) => ({
                          orderSectionItemCode: item.orderSectionItemCode,
                          qty: Number(e?.qtyChange || item.qty),
                        })
                      )
                    )
                  )
                )
              ),
              A.filter((e) => !!e?.orderSectionItemCode)
            ),
          })
        )
      ),
      A.filter((e) => !!e.targetItemInformation.length),
      (e) => e as TC3payloadItems
    )

    return (
      <>
        <PageTopBar />
        <OrderCancelInfo
          c3payloadItems={c3payloadItems}
          orderNo={data.orderNo}
        />
      </>
    )
  }
  if (isLoading) {
    return <div>{__('로딩중')}</div>
  }
  return <div>{__('에러')}</div>
}

export default OrderCancelInfoPage
