import { useQuery } from '@tanstack/react-query'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import React from 'react'
import { OrderCancel } from './view'
import { useAtom } from 'jotai'
import { IconInfoCircle, IconX } from '@imwebme/icon'
import { COrderSection } from '../class-order-section'
import { COrder } from '../class-order'
import { SingleOrderCheck } from '../order-api'
import { OrderCancelBannerAtom } from './order-cancel.store'
import { __ } from '~/shared/i18n'
import { useParams } from 'react-router-dom'
import { orderDetailOptions } from '~/entities/order-detail'
import { 상수_주문타입 } from '~/entities/@x'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const OrderCancelPage = () => {
  const orderCode = useOrderCode()
  // server status
  const { data, isSuccess } = useQuery({
    queryKey: [orderDetailOptions({ orderCode }).queryKey[0], orderCode],
    queryFn: () => SingleOrderCheck(orderCode),
    select: flow((d) => d.data),
  })
  const [orderCancelBanner, setOrderCancelBanner] = useAtom(
    React.useMemo(() => OrderCancelBannerAtom({ id: orderCode }), [orderCode])
  )

  if (isSuccess && data) {
    // 상품준비 섹션
    // OSS01 섹션인지 확인하는 스펙
    const sections = new COrder(data).orderSectionList
    const isStatusCdOSS01: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS01'
    )
    // OSS02 섹션이면서 인보이스가 없는 경우의 스펙
    // 인보이스가 없는 경우는 invoice가 없는 경우와 invocice가 있지만 invociceNo가 없는 경우가 있다.
    const isStatusCdOSS02: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS02'
      // 배송대기_송장등록전 섹션에서 굿스플로 송장번호 등록하려다가 취소한경우 invoice는있고 invoiceNo가 없는 상태이다.
      // && (candidate.invoice === null || candidate.invoice.invoiceNo === '')
    )

    const result = pipe(
      data.orderTypeCd,
      (주문타입) =>
        주문타입 === 상수_주문타입.디지털 ||
        주문타입 === 상수_주문타입.그룹패스,
      E.fromPredicate(
        (취소가능주문) => 취소가능주문,
        () => sections
      ),
      E.fold(
        (_sections) =>
          pipe(
            _sections,
            A.filter((section) =>
              isStatusCdOSS01.or(isStatusCdOSS02).isSatisfiedBy(section)
            )
          ),
        () => sections
      )
    )

    // 주문서 섹션에서 인보이스가 있는 경우
    const isInvoiceNo: ISpecification<COrderSection> = new Spec(
      (candidate) =>
        candidate.invoice !== null && candidate.invoice.invoiceNo !== ''
    )

    const 환불안내배너 = pipe(
      result,
      A.filter((section) => isInvoiceNo.isSatisfiedBy(section)),
      (e) => e.length > 0
    )

    if (환불안내배너 && orderCancelBanner && !orderCancelBanner.component) {
      setOrderCancelBanner({
        id: orderCode,
        component: (
          <div className="border bg-clay-semantic-surfaceHighlight rounded-[12px] px-[24px] py-[16px] flex">
            <div className="flex-1 flex items-center gap-x-[16px]">
              <div className="text-[20px]">
                <IconInfoCircle className="stroke-[2]" />
              </div>
              <div className="semantic-p14">
                {__(
                  '송장입력상태에서는 취소가 불가능해요. 송장을 삭제하고 취소처리를 진행해주세요'
                )}
              </div>
            </div>
            <button
              type="button"
              className="text-[20px]"
              onClick={() => setOrderCancelBanner(null)}
            >
              <IconX />
            </button>
          </div>
        ),
      })
    }

    // init
    const readyItems = pipe(
      result,
      O.fromNullable,
      O.fold(
        () => [],
        flow(
          A.map((section) => ({
            id: section.id,
            label: section.orderSectionNo,
            statusCd: section.statusCd,
            value: section.templateItemList,
          }))
        )
      )
    )

    // render
    return (
      <>
        <PageTopBar />
        <OrderCancel readyItems={readyItems} orderNo={data.orderNo} />
      </>
    )
  }
  return <div>{__('로딩중')}</div>
}

export default OrderCancelPage
