import React from 'react'
import { useOrderActionModal } from '~/container/order-preview/order-quantity-preview/order-quantity-preview.store'
import { match } from 'ts-pattern'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Modal } from '@imwebme/clay-components'

const ReadyToShip = React.lazy(() =>
  import('./배송대기처리').then((module) => ({
    default: module.ReadyToShip,
  }))
)
const StartShipping = React.lazy(() =>
  import('./배송중처리').then((module) => ({
    default: module.StartShipping,
  }))
)
const InvoiceManualInput = React.lazy(() =>
  import('./송장번호수동입력').then((module) => ({
    default: module.InvoiceManualInput,
  }))
)
const GoodsflowInvoiceInput = React.lazy(() =>
  import('./굿스플로송장등록').then((module) => ({
    default: module.GoodsflowInvoiceInput,
  }))
)
const CompleteShipping = React.lazy(() =>
  import('./배송완료처리').then((module) => ({
    default: module.CompleteShipping,
  }))
)
const DeliveryHoldCancel = React.lazy(() =>
  import('./배송보류해제').then((module) => ({
    default: module.DeliveryHoldCancel,
  }))
)
const RejectCancel = React.lazy(() =>
  import('./취소거절').then((module) => ({
    default: module.RejectCancel,
  }))
)
const RejectReturn = React.lazy(() =>
  import('./반품거절').then((module) => ({
    default: module.RejectReturn,
  }))
)
const RetrieveComplete = React.lazy(() =>
  import('./수거완료').then((module) => ({
    default: module.RetrieveComplete,
  }))
)

interface Props {
  orderCode: string
}
export function OrderQuantityPreviewModals({ orderCode }: Props) {
  const [modal] = useOrderActionModal()

  if (modal.open === false || modal.orderCode !== orderCode) {
    return null
  }
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <React.Suspense fallback={<SuspenseFallback />}>
        {match(modal.action)
          .with('배송대기처리', () => (
            <ReadyToShip
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('배송중처리', () => (
            <StartShipping
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('송장번호수동입력', () => (
            <InvoiceManualInput
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('굿스플로송장등록', () => (
            <GoodsflowInvoiceInput
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('배송완료처리', () => (
            <CompleteShipping
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('배송보류해제', () => (
            <DeliveryHoldCancel
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('취소거절', () => (
            <RejectCancel
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('반품거절', () => (
            <RejectReturn
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .with('수거완료', () => (
            <RetrieveComplete
              orderCode={modal.orderCode}
              sectionCode={modal.sectionCode}
            />
          ))
          .otherwise(() => null)}
      </React.Suspense>
    </ErrorBoundary>
  )
}

function ErrorFallback() {
  const [modal, setModal] = useOrderActionModal()
  return (
    <Modal width="small" isOpen={modal.open} setClose={() => setModal(false)}>
      <ErrorCard />
    </Modal>
  )
}

function SuspenseFallback() {
  const [modal, setModal] = useOrderActionModal()
  return (
    <Modal
      width="small"
      isOpen={modal.open}
      setClose={() => setModal(false)}
    ></Modal>
  )
}
