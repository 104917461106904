import { z } from 'zod'

export const getResMembersItemDto = z.object({
  idx: z.number().describe('회원번호'),
  code: z.string().describe('회원코드'),
  uid: z.string().describe('아이디'),
  email: z.string().describe('이메일'),
  name: z.string().nullish().describe('이름'),
  callnum: z.string().nullish().describe('전화번호'),
  profileImgUrl: z.string().nullable().describe('프로필 이미지 URL'),
  account: z.string().describe('로그인 계정'),
})

export const getResMembersDto = z.object({
  cursorForNext: z.string(),
  cursorForPrev: z.string(),
  count: z.number(),
  list: getResMembersItemDto.array(),
})

export const getPayloadMembersDto = z.object({
  direction: z.union([z.literal('next'), z.literal('prev')]),
  keyword: z.string().optional(),
  cursor: z.string().optional(),
  count: z.number().optional(),
})
