import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostPayloadBulkActionCashReceiptValidateDto,
  TPostResBulkActionCashReceiptValidateDto,
} from './bulk-action-cash-receipt-validate.type'
import { postResBulkActionCashReceiptValidateDto } from './bulk-action-cash-receipt-validate.dto'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/6YrE12
 * @description 현금영수증 발급 대량 처리 검증
 */
export async function postBulkActionCashReceiptValidate<
  T = TPostResBulkActionCashReceiptValidateDto,
  R = TPostPayloadBulkActionCashReceiptValidateDto,
>({ body }: { body: R }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/cash-receipt/validate', body)
    .then((res) => res.data)

  postResBulkActionCashReceiptValidateDto.parse(result.data)

  return result.data
}
