import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'
import { Textfield } from '@imwebme/clay-components'
import { Trans } from 'react-i18next'

export function TimeField({ index }: TFieldProps) {
  const { control, register, watch } =
    useFormContext<TOrderFormDataEditFormSchema>()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: `form.${index}.value`,
    control,
  })
  const time = watch(`form.${index}.value`)

  const handleHourChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const hour = parseInt(evt.target.value, 10)

      if (!isNaN(hour) && hour >= 0 && hour <= 24) {
        const newHour = hour.toString().padStart(2, '0')
        const curMinute = time[0]?.split(':')?.[1] ?? '00'
        const newTime = newHour + ':' + curMinute
        onChange([newTime])
      }
    },
    [time]
  )

  const handleMinuteChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const minut = parseInt(evt.target.value, 10)

      if (!isNaN(minut) && minut >= 0 && minut <= 59) {
        const newMinut = minut.toString().padStart(2, '0')
        const curHour = time[0]?.split(':')?.[0] ?? '00'
        const newTime = curHour + ':' + newMinut
        onChange([newTime])
      }
    },
    [time]
  )

  return (
    <div>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />

      <Textfield.Set>
        <div className="flex gap-x-clay-2">
          <Trans>
            <Textfield.Box>
              <Textfield.Input
                value={value[0]?.split(':')?.[0] ?? ''}
                onChange={handleHourChange}
                isValid={error ? false : undefined}
              />
              <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
                시
              </Textfield.Addon>
            </Textfield.Box>
          </Trans>
          <Trans>
            <Textfield.Box>
              <Textfield.Input
                value={value[0]?.split(':')?.[1] ?? ''}
                onChange={handleMinuteChange}
                isValid={error ? false : undefined}
              />
              <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
                분
              </Textfield.Addon>
            </Textfield.Box>
          </Trans>
        </div>

        <Textfield.HelperText className="text-clay-semantic-textCritical">
          {error?.message}
        </Textfield.HelperText>
      </Textfield.Set>
    </div>
  )
}
