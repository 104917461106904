/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'

/**
 * get site-claims
 */
export const getResSiteClaimsSchema = z.object({
  claims: z.object({
    INTENT_CHANGED: z.string(),
    COLOR_AND_SIZE: z.string(),
    WRONG_ORDER: z.string(),
    PRODUCT_UNSATISFIED: z.string(),
    DELAYED_DELIVERY: z.string(),
    DROPPED_DELIVERY: z.string(),
    SOLD_OUT: z.string(),
    BROKEN: z.string(),
    INCORRECT_INFO: z.string(),
    WRONG_DELIVERY: z.string(),
    WRONG_OPTION: z.string(),
    AUTO_CANCEL: z.string(),
  }),
})
