/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { postPayloadDeliveryDelayDto } from '.'
import { z } from 'zod'

/**
 * TExternalItemMutableList 타입의 값을 받아오기때문에 해당 값을 제외한다.
 */
export const postPayloadDeliveryDelayRdo = postPayloadDeliveryDelayDto.omit({
  orderSectionCode: true,
  orderSectionItemCodes: true,
})
