import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { useDeliveryHoldApproveAtom } from './delivery-hold-approve.store'
import { TDeliveryHoldApproveBase } from './delivery-hold-approve.type'

const DeliveryHoldApprove = React.lazy(() =>
  import('./delivery-hold-approve').then((module) => ({
    default: React.memo(module.DeliveryHoldApprove),
  }))
)

interface TDeliveryHoldApproveContainer extends TDeliveryHoldApproveBase {}
export function DeliveryHoldApproveContainer(
  props: TDeliveryHoldApproveContainer
) {
  const [open, setState] = useDeliveryHoldApproveAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <DeliveryHoldApprove
            {...props}
            onSuccess={() => onOpenChange(false)}
          />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
