import * as React from 'react'
import { DateFormatter, DayPicker, WeekNumberFormatter } from 'react-day-picker'
import { ko, enUS } from 'date-fns/locale'
import { LocaleContext } from '~/app/provider/locale'
import { ChevronLeft, ChevronRight } from '@imwebme/clay-icons'
import { fDate } from '~/shared/utils'

const locales: Record<string, Locale> = { ko, en: enUS }

const formatDay: DateFormatter = (day) =>
  day.getDate().toLocaleString('ko-kr', { minimumIntegerDigits: 2 })

const formatWeekNumber: WeekNumberFormatter = (weekNumber) =>
  weekNumber.toLocaleString('ko-kr')

const formatCaption: DateFormatter = (date) => fDate(date, 'yyyy년 LLLL')

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const { locale } = React.useContext(LocaleContext)
  return (
    <div className="semantic-calendar">
      <DayPicker
        locale={locale ? locales[locale] : ko}
        showOutsideDays={showOutsideDays}
        components={{
          IconLeft: () => <ChevronLeft className="h-4 w-4" />,
          IconRight: () => <ChevronRight className="h-4 w-4" />,
        }}
        formatters={{
          formatDay,
          formatWeekNumber,
          formatCaption,
        }}
        {...props}
      />
    </div>
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
