import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { IconAccordionDown, IconX } from '@imwebme/icon'
import { OrderSectionItemDto } from '~/entities/order-detail'
import { __ } from '~/shared/i18n'
import { OrderSectionItem } from '~t/order-section/order-section-item'
import { 모델_주문섹션_품목_UI } from '~/entities/order-detail/model/order-section-item'
import { Badge } from '~/shared/components/ui/badge'
import { InputNumber } from '~/shared/components/ui/input-number'
import { Tooltip } from '~/shared/components/ui/tooltip'
import * as math from 'mathjs'
import { useCurrency } from '~/shared/hooks/use-currency'
import { Trans } from 'react-i18next'
import { Tag } from '@imwebme/clay-components'

interface TSectionItemImmutable {
  item: OrderSectionItemDto
}
export function SectionItemImmutable({ item }: TSectionItemImmutable) {
  const currency = useCurrency()
  const Product = new 모델_주문섹션_품목_UI(item)

  return (
    <tr className="odd:bg-clay-semantic-layerSub even:bg-white w-full text-left [&_td]:py-clay-4">
      <td />
      <td>
        <OrderSectionItem>
          <OrderSectionItem.Slot name="image">
            {item.imageUrl && (
              <img
                src={item.imageUrl}
                loading="lazy"
                draggable="false"
                alt=""
              />
            )}
          </OrderSectionItem.Slot>
          <OrderSectionItem.Slot
            name="name"
            className="text-clay-semantic-textPrimary"
          >
            {item.prodName}
          </OrderSectionItem.Slot>
          <OrderSectionItem.Slot name="option">
            <div className="flex gap-clay-1 flex-wrap">
              {pipe(
                item.optionInfo,
                O.fromNullable,
                O.fold(
                  () => null,
                  flow(
                    A.map((e) => (
                      <Tag
                        variant="other"
                        background
                        size="small"
                        text={`${e.key} ${e.value}`}
                        className="bg-[#15181E1A]"
                      />
                    ))
                  )
                )
              )}
            </div>
            <div className="grid gap-y-[4px] mt-[8px]">
              <div className="flex gap-x-[4px] items-center typo-clay-body-medium">
                <div>
                  {__('{{price, 3comma}} {{currency, currency}}', {
                    price: item.itemPrice,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </div>
                <IconX className="stroke-[2] text-clay-semantic-icon" />
                {item.qty}
              </div>
              <div className="typo-clay-body-medium text-clay-semantic-text">
                {__('총 {{price, 3comma}} {{currency, currency}}', {
                  price: item.itemPrice * item.qty,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
            </div>
          </OrderSectionItem.Slot>
        </OrderSectionItem>
      </td>
      <td className="relative px-clay-4">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div>
                <InputNumber
                  value={item.qty}
                  valueIncrease={() => 0}
                  valueDecrease={() => 0}
                  onChange={() => 0}
                  className="bg-white w-full text-right border-solid"
                  disabled
                >
                  <InputNumber.Slot name="increase">
                    <IconAccordionDown className="rotate-180 group-disabled:text-clay-semantic-iconDisabled" />
                  </InputNumber.Slot>
                  <InputNumber.Slot name="decrease">
                    <IconAccordionDown className="group-disabled:text-clay-semantic-iconDisabled" />
                  </InputNumber.Slot>
                </InputNumber>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content>
                <p>{__('입금확인 후 부분 취소를 진행해 주세요')}</p>
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </td>
      <td>
        <div className="grid gap-y-[8px]">
          <div className="text-right typo-clay-label-medium">
            <Trans
              i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
              values={{
                price:
                  math
                    .chain(item.itemPrice)
                    .multiply(item.qty)
                    .multiply(-1)
                    .done() || 0,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              }}
              components={{ b: <b className="typo-clay-body-medium-bold" /> }}
            />
          </div>
          <div className="justify-self-end">
            <Badge variant="critical" className="typo-clay-label-small">
              {__('품목 취소')}
            </Badge>
          </div>
        </div>
      </td>
      <td></td>
    </tr>
  )
}
