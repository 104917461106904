import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import type { TItem } from './product-search.type'

export type TChildComponentType =
  | '필수조합옵션'
  | '필수조합옵션_입력형옵션'
  | '필수비조합옵션'
  | '필수비조합옵션_입력형옵션'
  | '단일상품'
  | '단일상품_입력형옵션'
  | 'Unknown'
export class Product {
  #id
  #name
  #thumbnail
  #options
  #optionCount
  #price
  #optionDetails

  #data
  #searchId

  constructor(data: TItem, searchId: string) {
    this.#id = data.prodCode
    this.#name = data.prodName
    this.#thumbnail = data.imageUrls
    this.#price = data.price
    this.#options = data.options
    this.#optionDetails = data.optionDetails
    this.#optionCount = data.optionDetailCount || 0
    this.#data = data
    this.#searchId = searchId
  }

  get prodCode() {
    return this.#id
  }

  get options() {
    return this.#options
  }
  // 상품의 옵션들을 보고 어떤 상품인지 판단한다.
  get childComponentType(): TChildComponentType {
    /**
     * 옵션에 필수옵션 포함되었나?
     * 존재하지 않으면 false, 존재하면 true
     */
    const isOptionHasRequiredSpec: ISpecification<Product> = new Spec(
      flow(
        (candidate) => candidate.#options,
        O.fromNullable,
        O.fold(
          () => false,
          // https://gcanti.github.io/fp-ts/modules/Array.ts.html#every
          // every 제공된 조건자를 모두 만족하는지 확인합니다.
          flow(A.exists((e) => e.isRequiredOption === true))
        )
      )
    )

    /**
     * 옵션디테일이 존재하는지 확인
     */
    const isOptionDetailSpec: ISpecification<Product> = new Spec(
      flow((e) => e.#optionDetails, O.fromNullable, O.isSome)
    )

    /**
     * 입력형 옵션이 존재하는지 확인
     */
    const isInputOptionSpec: ISpecification<Product> = new Spec(
      flow(
        (candidate) => candidate.#options,
        O.fromNullable,
        O.fold(
          () => false,
          flow(
            A.findFirst((e) => e.type === 'input'),
            O.fold(
              () => false,
              () => true
            )
          )
        )
      )
    )
    /**
     * 필수옵션중에 입력형 옵션만 존재하는지 확인
     */
    const isInputOptionOnlySpec: ISpecification<Product> = new Spec(
      flow(
        (candidate) => candidate.#options,
        O.fromNullable,
        O.fold(
          () => false,
          flow(
            A.filter((e) => e.isRequiredOption === true),
            A.every((e) => e.type === 'input')
          )
        )
      )
    )

    // 필수비조합
    const isRnco: ISpecification<Product> = new Spec(
      flow(
        (candidate) => candidate.#options,
        O.fromNullable,
        O.fold(
          () => false,
          flow(
            A.findFirst((e) => e.isRequiredOption && e.type !== 'input'),
            O.fold(
              () => false,
              () => true
            )
          )
        )
      )
    )

    // 필수 조합
    // - 옵션 디테일이 존재한다.
    // - 옵션에 필수값이 없어야한다.
    // - 또는 필수지만 입력형이어야한다.
    if (
      // 필수옵션이없고 필수조합입력형이있는경우
      isOptionDetailSpec
        .and(isOptionHasRequiredSpec.not())
        .and(isInputOptionSpec.not())
        .isSatisfiedBy(this)
    ) {
      return '필수조합옵션'
    }

    // 필수 조합 옵션 + 입력형 옵션
    if (
      isOptionDetailSpec
        .and(isInputOptionSpec)
        .and(isRnco.not())
        .isSatisfiedBy(this)
    ) {
      return '필수조합옵션_입력형옵션'
    }

    // 필수 비조합
    // - 옵션이 존재하고 옵션의 필수값이 있다.
    // - 필수 비조합일경우 order-detail이 없다.
    if (
      isOptionHasRequiredSpec
        .and(isOptionDetailSpec.not())
        .and(isInputOptionSpec.not())
        .isSatisfiedBy(this)
    ) {
      return '필수비조합옵션'
    }
    // 필수 비조합
    // - 옵션이 존재하고 옵션의 필수값이 있다.
    // - 필수 비조합일경우 order-detail이 없다.
    if (
      isOptionHasRequiredSpec
        .and(isOptionDetailSpec.not())
        .and(isInputOptionSpec)
        .and(isInputOptionOnlySpec.not())
        .isSatisfiedBy(this)
    ) {
      return '필수비조합옵션_입력형옵션'
    }

    // 단일상품
    // - 선택옵션은 존재할 수 있음.
    // - 옵션이 존재하지 않는다.
    // - 옵션디테일이 존재하지 않는다.
    if (
      isOptionHasRequiredSpec
        .not()
        .and(isOptionDetailSpec.not())
        .isSatisfiedBy(this)
    ) {
      return '단일상품'
    }
    if (
      isInputOptionOnlySpec
        .and(isOptionDetailSpec.not())
        .and(isInputOptionOnlySpec)
        .isSatisfiedBy(this)
    ) {
      return '단일상품_입력형옵션'
    }
    return 'Unknown'
  }

  // 옵션의 갯수 가져오기
  get optionCount() {
    // 선택옵션의 갯수
    const optionalOptionCount = pipe(
      this.#options,
      O.fromNullable,
      O.fold(
        () => 0,
        flow(
          A.filter((e) => e.isRequiredOption === false),
          A.map(
            flow(
              (e) => e.values,
              O.fromNullable,
              O.fold(
                () => 0,
                (e) => e.length
              )
            )
          ),
          A.reduce(0, (acc, cur) => acc + cur)
        )
      )
    )
    switch (this.childComponentType) {
      case '필수비조합옵션': {
        if (this.#options === undefined) {
          throw new Error('해당로직에서는 필수비조합옵션은 항상 있어야합니다.')
        }
        // TODO: 일단 여기서 상태값을 사용할 수 없으니까 선택옵션값만 임시로 넘기고있다.
        return optionalOptionCount
      }
      case '필수조합옵션': {
        if (this.#optionDetails === undefined) {
          throw new Error('해당로직에서는 필수조합옵션은 항상 있어야합니다.')
        }
        return this.#optionDetails.length + optionalOptionCount
      }
      case '단일상품':
        return 1 + optionalOptionCount
      // 위 세가지에 부합되지 않으면 선택상품만 남은것으로
      // 선택상품의 갯수를 반환한다.
      default:
        return pipe(
          this.#options,
          O.fromNullable,
          O.fold(
            () => 0,
            (e) => e.length
          )
        )
    }
  }

  // 상품의 이름 가져오기
  get name() {
    if (import.meta.env.VITE_NODE_ENV === 'local') {
      return `(${this.#id}) ${this.#name}`
    }
    return this.#name
  }
}
