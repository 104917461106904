import React from 'react'
import { __ } from '~/shared/i18n'
import { ExternalOrderDetail } from '~/container/external-order/external-order-detail'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { useParams } from '~/router'
import { OrderDetailPack } from '~/container/order/order-detail'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

function External() {
  return (
    <>
      <PageTopBar />
      <ExternalOrderDetail />
    </>
  )
}

function OrderPage() {
  // 취소승인, 반품승인 시 필요했던 로컬스토리지에 저장된 데이터 삭제하기
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // Iterate over each key in localStorage
      for (let i = localStorage.length - 1; i >= 0; i--) {
        // Get the key at index i
        const key = localStorage.key(i)

        // If the key ends with the search string
        if (
          key?.endsWith('cancelApproveTargetItems') ||
          key?.endsWith('returnApproveTargetItems')
        ) {
          // Delete the item with the matching key
          localStorage.removeItem(key)
        }
      }
    }
  }, [])
  return (
    <>
      <PageTopBar />
      <OrderDetailPack />
    </>
  )
}

export default function Page() {
  const { saleChannel } = useParams('/order/:saleChannel/:orderCode')
  if (saleChannel !== '1') {
    return <External />
  }
  return <OrderPage />
}
