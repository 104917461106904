import React from 'react'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { __, __g } from '~/shared/i18n'
import { useDeliverySetupAtom, useOrderInfoAtom } from '../order-create.store'
import { Typography } from '@imwebme/clay-components'
import { deliverySetupToAddress } from './delivery-setup.fn'
import { useQuery } from '@tanstack/react-query'
import {
  deliveryDataToAddress,
  memberDeliveryQueryOptions,
  TGetResMemberDeliveryDto,
} from '~/entities/member/member-delivery'
import { match, P } from 'ts-pattern'

export function StaticView() {
  const [orderInfo] = useOrderInfoAtom()
  const [deliverySetup] = useDeliverySetupAtom()

  const { data: memberDelivList } = useQuery({
    ...memberDeliveryQueryOptions(orderInfo.memberCode as string),
    select: React.useCallback(
      (data: TGetResMemberDeliveryDto) =>
        data.filter((el) => el.shippingCountry === deliverySetup.country),
      [deliverySetup.country]
    ),
    enabled: !!orderInfo.memberCode,
  })

  const selectedDelivAddress = React.useMemo(() => {
    const found = memberDelivList?.find(
      (el) => el.code === deliverySetup.shippingAddressCode
    )
    return found
      ? deliveryDataToAddress(found.addressData, found.shippingCountry)
      : ''
  }, [memberDelivList, deliverySetup.shippingAddressCode])

  return (
    <>
      {deliverySetup._deliveryTypeCd && (
        <FieldLayout title={__('배송 방식')}>
          <Typography variant="body-medium">
            {__g(deliverySetup._deliveryTypeCd)}
          </Typography>
        </FieldLayout>
      )}

      {deliverySetup.country && deliverySetup.country !== 'KR' && (
        <FieldLayout title={__('배송 국가')}>
          <Typography variant="body-medium">
            {__g(deliverySetup.country)}
          </Typography>
        </FieldLayout>
      )}

      {match({
        zipcode: deliverySetup.zipcode,
        shippingAddressCode: deliverySetup.shippingAddressCode,
      })
        .with({ zipcode: P.nonNullable }, () => (
          <FieldLayout title={__('배송지')}>
            <Typography
              variant="body-medium"
              whiteSpace="pre-line"
              sx={{ wordBreak: 'break-all' }}
            >
              {__g(deliverySetupToAddress(deliverySetup))}
            </Typography>
          </FieldLayout>
        ))
        .with({ shippingAddressCode: P.nonNullable }, () => (
          <FieldLayout title={__('배송지')}>
            <Typography
              variant="body-medium"
              whiteSpace="pre-line"
              sx={{ wordBreak: 'break-all' }}
            >
              {selectedDelivAddress}
            </Typography>
          </FieldLayout>
        ))
        .otherwise(() => null)}

      {deliverySetup.memo && (
        <FieldLayout title={__('배송 메모')}>
          <Typography
            variant="body-medium"
            whiteSpace="pre-line"
            sx={{ wordBreak: 'break-all' }}
          >
            {deliverySetup.memo}
          </Typography>
        </FieldLayout>
      )}

      {deliverySetup.pickupMemo && (
        <FieldLayout title={__('방문수령 메모')}>
          <Typography
            variant="body-medium"
            whiteSpace="pre-line"
            sx={{ wordBreak: 'break-all' }}
          >
            {deliverySetup.pickupMemo}
          </Typography>
        </FieldLayout>
      )}

      {deliverySetup.unipassNumber && (
        <FieldLayout title={__('개인통관고유부호')}>
          <Typography variant="body-medium" sx={{ wordBreak: 'break-all' }}>
            {__g(deliverySetup.unipassNumber)}
          </Typography>
        </FieldLayout>
      )}

      {deliverySetup._deliveryPayTypeCd && (
        <FieldLayout title={__('배송비')}>
          <Typography variant="body-medium">
            {__g(deliverySetup._deliveryPayTypeCd)}
          </Typography>
        </FieldLayout>
      )}
    </>
  )
}
