import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import {
  postResReturnRejectRequestDto,
  type TPostResReturnRejectRequestDto,
  type TPostPayloadReturnRejectRequestDto,
} from '.'

const axios = ApiClient()

/**
 * @description
 */
export function postReturnRejectRequest({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadReturnRejectRequestDto) {
    type R = typeof body
    type T = TPostResReturnRejectRequestDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/return/reject-request`, body)
      .then((res) => res.data)

    try {
      postResReturnRejectRequestDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
