import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import {
  TStatusBackReturnRejectModalBase,
  useStatusBackReturnRejectModalAtom,
} from '.'

const StatusBackReturnRejectModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.StatusBackReturnRejectModal),
  }))
)

interface TStatusBackReturnRejectModalPack
  extends TStatusBackReturnRejectModalBase {}

/**
 * @name
 * 일반주문 섹션 - 반품거절 모달
 * @description
 * 주문상세가 아닌곳에서도 사용할 수 있어서 orderCode, sectionCode를 모두 받는다
 */
export function StatusBackReturnRejectModalPack(
  props: TStatusBackReturnRejectModalPack
) {
  const [open, setState] = useStatusBackReturnRejectModalAtom.state({
    atomKey: props.atomKey,
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <StatusBackReturnRejectModalContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
