import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as RA from 'fp-ts/ReadonlyArray'
import * as S from 'fp-ts/string'
import {
  TExternalOrderSectionDto,
  TGetResExternalOrderDto,
} from '~/entities/external-order'

export function params2Order(queryList: [string, string][]) {
  const selected = pipe(
    queryList,
    A.findFirst((e) => e[0] === 'data'),
    O.map(
      flow(
        (e) => e[1],
        (e) => atob(e),
        S.split('|'),
        RA.reduce({} as Record<string, string[]>, (acc, cur) => {
          const [key, value] = cur.split('=')
          return { ...acc, [key]: value.split(',') }
        })
      )
    ),
    O.getOrElseW(() => {
      throw new Error('data가 없습니다.')
    })
  )
  return function (
    data: TGetResExternalOrderDto
  ): TExternalOrderSectionDto[] | undefined {
    try {
      const result = pipe(
        data.orderSectionList,
        O.fromNullable,
        O.getOrElseW(() => {
          throw new Error('test1')
        }),
        A.map(
          flow(
            O.fromNullable,
            O.getOrElseW(() => {
              throw new Error('test2')
            }),
            (e) => {
              const orderSectionItemList = pipe(
                e.orderSectionItemList,
                O.fromNullable,
                O.getOrElseW(() => {
                  throw new Error('test3')
                }),
                A.filter(
                  flow(
                    (e2) => e2.orderSectionItemCode,
                    O.fromNullable,
                    O.getOrElseW(() => {
                      throw new Error('test4')
                    }),
                    // e2가 selected[e.orderCode]에 존재하는지 확인
                    (e2) => {
                      log.debug(e.orderCode)
                      log.debug(selected)
                      log.debug(selected[e.orderSectionCode])
                      const _result = pipe(
                        selected[e.orderSectionCode],
                        O.fromNullable,
                        O.getOrElseW(() => [] as string[]),
                        (e3) => e3.includes(e2)
                      )
                      return _result
                    }
                  )
                )
              )
              return {
                ...e,
                orderSectionItemList,
              }
            }
          )
        )
      )
      return result
    } catch (error) {
      log.debug(error)
      return undefined
    }
  }
}
