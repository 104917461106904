import { useFormContext, Controller } from 'react-hook-form'
import { Textarea } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'

export function TextareaField({ index }: TFieldProps) {
  const { control, register } = useFormContext<TOrderFormDataEditFormSchema>()
  return (
    <div>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />
      <Controller
        name={`form.${index}.value`}
        control={control}
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Textarea
            value={value[0] ?? ''}
            onChange={(v) => onChange([v])}
            errorText={error?.message}
            {...rest}
          />
        )}
      />
    </div>
  )
}
