import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { useCancelAllConfirmAtom } from './cancel-all-confirm.store'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const CancelAllConfirm = React.lazy(() =>
  import('./cancel-all-confirm').then((module) => ({
    default: React.memo(module.CancelAllConfirm),
  }))
)

export function CancelAllConfirmContainer() {
  const orderCode = useOrderCode()
  const [state, setState] = useCancelAllConfirmAtom.state({
    orderCode,
  })

  function onOpenChange(o: boolean) {
    setState((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        open: o,
      },
    }))
  }

  return (
    <AlertDialog.Root open={state.value.open} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <CancelAllConfirm orderCode={orderCode} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
