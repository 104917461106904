import { queryOptions } from '@tanstack/react-query'
import { getGoodsflowDeliveryServiceDetail } from './goodsflow.api'

export function goodsflowQueryOptions() {
  return queryOptions({
    queryKey: ['사이트의 굿스플로 파트너 리스트 조회'],
    queryFn: getGoodsflowDeliveryServiceDetail,
    select: (e) => e.data,
  })
}
