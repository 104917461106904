import React from 'react'
import { Selector } from './components'
import { __, __g } from '~/shared/i18n'
import { includes, pull, find } from 'lodash'
import { useController } from 'react-hook-form'
import { FormControlProp } from './order-search-filter-form-fields.type'
import { T상수_할인타입, 상수_할인타입 } from '~/entities/@x'
import { OptionItemCheckbox } from '@imwebme/clay-components'

interface Props extends FormControlProp {}

const options: ReadonlyArray<{ label: string; value: T상수_할인타입 }> = [
  { label: __('적립금'), value: 상수_할인타입.포인트 },
  {
    label: __('쿠폰'),
    value: 상수_할인타입.쿠폰,
  },
  { label: __('등급할인'), value: 상수_할인타입.등급 },
]

export const DiscountTypeField = ({ control }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const { onChange, value, ...inputProps } = useController({
    name: 'discountType',
    control,
  }).field

  const handleInputChange = React.useCallback(
    (item: string) => {
      const copiedValue = Array.isArray(value) ? [...value] : []

      if (includes(copiedValue, item)) {
        pull(copiedValue, item)
      } else {
        copiedValue.push(item)
      }

      onChange(copiedValue.length ? copiedValue : null)
    },
    [value, onChange]
  )

  const fieldLabel = React.useMemo(() => {
    let baseLabel = __('할인수단')

    if (Array.isArray(value) && value.length) {
      const firstItem = find(options, (o) => includes(value, o.value))
      baseLabel += ': '

      if (value.length > 1) {
        baseLabel += __('{{discountType}} 외 {{count}}건', {
          discountType: __g(firstItem?.label ?? ''),
          count: value.length - 1,
        })
      } else {
        baseLabel += __g(firstItem?.label ?? '')
      }
    }

    return baseLabel
  }, [value])

  return (
    <Selector
      label={fieldLabel}
      isActive={Array.isArray(value) && !!value.length}
      resetField={() => onChange(null)}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {options.map((option) => (
            <OptionItemCheckbox
              key={option.value}
              label={option.label}
              value={option.value}
              checked={
                Array.isArray(value) && value.some((v) => v === option.value)
              }
              onClick={(e) => handleInputChange(e as string)}
              {...inputProps}
            />
          ))}
        </div>
      }
    />
  )
}
