import { atom, SetStateAction, useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  TOrderInfo,
  TProdSelect,
  TDeliverySetup,
  TDiscountSetup,
  orderInfoSchema,
  prodSelectSchema,
  deliverySetupSchema,
  discountSetupSchema,
} from './order-create.schema'
import { atomWithStorage, createJSONStorage, RESET } from 'jotai/utils'
import {
  genStorageKey,
  transformDeliveryDto,
  transformItemsDto,
} from './order-create.fn'
import { intersection } from 'lodash'
import {
  TOrderCreatePayloadItemDto,
  TOrderCreatePayloadDeliveryDto,
  TOrderCreatePayloadDto,
} from '~/entities/@dto'
import { YN } from '~/entities/@x'

//================================= 스템0:주문정보입력 =================================//

const orderInfoAtom = atomWithStorage<Partial<TOrderInfo>>(
  genStorageKey('order-info'),
  { _isMember: true },
  createJSONStorage(() => sessionStorage)
)

const derived_orderInfoAtom = atom(
  (get) => get(orderInfoAtom),
  (get, set, args: Partial<TOrderInfo> | typeof RESET) => {
    const update = args === RESET ? RESET : { ...get(orderInfoAtom), ...args }
    set(orderInfoAtom, update)
  }
)

const orderInfoValidationAtom = atom((get) => {
  const orderInfo = get(orderInfoAtom)
  return orderInfoSchema.safeParse(orderInfo).success
})

export function useOrderInfoAtom() {
  return useAtom(derived_orderInfoAtom)
}
export function useOrderInfoIsValid() {
  return useAtomValue(orderInfoValidationAtom)
}

//================================= 스텝1:상품선택 =================================//

const prodSelectAtom = atomWithStorage<TProdSelect>(
  genStorageKey('prod-select'),
  [],
  createJSONStorage(() => sessionStorage)
)

const derived_prodSelectAtom = atom(
  (get) => get(prodSelectAtom),
  (
    get,
    set,
    args:
      | typeof RESET
      | { type: 'add'; payload: TProdSelect }
      | { type: 'remove'; id: string }
      | { type: 'update'; id: string; payload: Partial<TProdSelect[number]> }
  ) => {
    if (args === RESET) {
      set(prodSelectAtom, args)
      return
    }

    if (args.type === 'add') {
      set(prodSelectAtom, (prev) => {
        const existingIds = new Set(prev.map((el) => el._id))

        const uniqueNewOptions = args.payload.filter(
          (el) => !existingIds.has(el._id)
        )

        return [...prev, ...uniqueNewOptions]
      })
      return
    }

    if (args.type === 'remove') {
      const found = get(prodSelectAtom).find((el) => el._id === args.id)
      if (!found) {
        return
      }

      if (found.isRequireOption === 'Y') {
        // 필수옵션을 삭제시 해당 prodCode를 가진 모든 비필수옵션을 삭제한다
        const prodCode = found.prodCode
        set(prodSelectAtom, (prev) =>
          prev.filter((el) => {
            if (el._id === args.id) {
              return false
            }
            if (el.prodCode === prodCode && el.isRequireOption === 'N') {
              return false
            }
            return true
          })
        )
      } else {
        set(prodSelectAtom, (prev) => prev.filter((el) => el._id !== args.id))
      }

      return
    }

    if (args.type === 'update') {
      set(prodSelectAtom, (prev) =>
        prev.map((el) => (el._id === args.id ? { ...el, ...args.payload } : el))
      )
    }
  }
)

const prodSelectValidationAtom = atom((get) => {
  const prodSelect = get(prodSelectAtom)
  return prodSelectSchema.safeParse(prodSelect).success
})

export function useProdSelectAtom() {
  return useAtom(derived_prodSelectAtom)
}
export function useProdSelectIsValid() {
  return useAtomValue(prodSelectValidationAtom)
}

//================================= 스텝2:배송설정 =================================//

const deliverySetupAtom = atomWithStorage<Partial<TDeliverySetup>>(
  genStorageKey('delivery-setup'),
  {},
  createJSONStorage(() => sessionStorage)
)

const derived_deliverySetupAtom = atom(
  (get) => {
    const _useUnipassNumber = get(prodSelectAtom).some(
      (el) => el._useUnipassNumber === YN.Y
    )
    return { ...get(deliverySetupAtom), _useUnipassNumber }
  },
  (get, set, args: Partial<TDeliverySetup> | typeof RESET) => {
    const update =
      args === RESET ? RESET : { ...get(deliverySetupAtom), ...args }
    set(deliverySetupAtom, update)
  }
)

const deliverySetupValidationAtom = atom((get) => {
  const deliverySetup = get(derived_deliverySetupAtom)
  return deliverySetupSchema.safeParse(deliverySetup).success
})

export function useDeliverySetupAtom() {
  return useAtom(derived_deliverySetupAtom)
}
export function useDeliverySetupIsValid() {
  return useAtomValue(deliverySetupValidationAtom)
}

//================================= 스텝3:할인설정 =================================//

const discountSetupAtom = atomWithStorage<TDiscountSetup>(
  genStorageKey('discount-setup'),
  { isGradeDiscount: false, inputPointAmount: 0, _maxPoint: 0 },
  createJSONStorage(() => sessionStorage)
)

const discountSetupValidationAtom = atom((get) => {
  const discountSetup = get(discountSetupAtom)
  return discountSetupSchema.safeParse(discountSetup).success
})

const derived_discountSetupAtom = atom(
  (get) => get(discountSetupAtom),
  (get, set, args: Partial<TDiscountSetup> | typeof RESET) => {
    const update =
      args === RESET ? RESET : { ...get(discountSetupAtom), ...args }
    set(discountSetupAtom, update)
  }
)

export function useDiscountSetupAtom() {
  return useAtom(derived_discountSetupAtom)
}

export function useDiscountSetupIsValid() {
  return useAtomValue(discountSetupValidationAtom)
}

//================================= 현재 입력중인 스텝 인덱스 =================================//
const activeStepIdxAtom = atomWithStorage<number>(
  genStorageKey('step-idx'),
  0,
  createJSONStorage(() => sessionStorage)
)

const derived_activeStepIdxAtom = atom(
  (get) => get(activeStepIdxAtom),
  (get, set, stepIdx: number | SetStateAction<number>) => {
    const newStepIdx =
      typeof stepIdx === 'function' ? stepIdx(get(activeStepIdxAtom)) : stepIdx

    set(activeStepIdxAtom, newStepIdx)

    const ATOM_BY_STEP = [
      orderInfoAtom,
      prodSelectAtom,
      deliverySetupAtom,
      discountSetupAtom,
    ] as const

    // 활성화된 스텝 인덱스가 바뀌면 그 이후의 모든 스텝 값을 초기화
    for (let i = newStepIdx + 1; i < ATOM_BY_STEP.length; i++) {
      set(ATOM_BY_STEP[i] as ReturnType<typeof atomWithStorage<unknown>>, RESET)
    }
  }
)

export function useActiveStepIdxAtom() {
  return useAtom(derived_activeStepIdxAtom)
}

//================================= 유효성 검증 결과 =================================//

const validAllAtom = atom((get) => {
  const VALID_ATOMS = [
    orderInfoValidationAtom,
    prodSelectValidationAtom,
    deliverySetupValidationAtom,
    discountSetupValidationAtom,
  ] as const

  return VALID_ATOMS.every(get)
})

export function useValidAll() {
  return useAtomValue(validAllAtom)
}

//================================= 선택된 상품들의 상품타입, 배송관련 설정의 교집합 =================================//

const selectedProdCommons = atom((get) => {
  const prodSelect = get(prodSelectAtom)

  return {
    delivCountryList: intersection(
      ...prodSelect.map((el) => el._delivCountryList)
    ),
    delivTypeList: intersection(...prodSelect.map((el) => el._delivTypeList)),
    delivPayTypeList: intersection(
      ...prodSelect.map((el) => el._delivPayTypeList)
    ),
    idList: prodSelect.map((el) => el._id),
    prodType: prodSelect[0]?._prodType,
  }
})

export function useSelectedProdCommons() {
  return useAtomValue(selectedProdCommons)
}

//================================= 결제예정조회금액 배송비 무료 여부 =================================//
const isFreeShippingOrderAtom = atom<boolean>(false)

export function useFreeShippingOrder() {
  return useAtom(isFreeShippingOrderAtom)
}

//================================= 모든 아톰 초기화 =================================//

const resetAllAtom = atom(null, (_get, set) => {
  set(orderInfoAtom, RESET)
  set(prodSelectAtom, RESET)
  set(deliverySetupAtom, RESET)
  set(discountSetupAtom, RESET)
  set(activeStepIdxAtom, RESET)
})

export function useResetAllAtom() {
  return useSetAtom(resetAllAtom)
}

//================================= 주문생성/결제예정조회금액 API 페이로드 =================================//
const apiPayloadAtom = atom<TOrderCreatePayloadDto>((get) => {
  const orderInfo = get(orderInfoAtom)
  const prodSelect = get(prodSelectAtom)
  const deliverySetup = get(deliverySetupAtom)
  const discountSetup = get(discountSetupAtom)

  const itemsDto: TOrderCreatePayloadItemDto[] = transformItemsDto(
    prodSelect,
    deliverySetup
  )
  const deliveryDto: TOrderCreatePayloadDeliveryDto | undefined =
    transformDeliveryDto(deliverySetup)

  return {
    unitCode: orderInfo.unitSite?.code ?? '',
    memberCode: orderInfo.memberCode || undefined,
    ordererName: orderInfo.ordererName || undefined,
    ordererEmail: orderInfo.ordererEmail || undefined,
    ordererCall: orderInfo.ordererCall || undefined,
    items: itemsDto,
    country: deliverySetup.country!,
    unipassNumber: deliverySetup.unipassNumber || undefined,
    delivery: deliveryDto,
    isGradeDiscount: discountSetup.isGradeDiscount,
    couponIssueCodes: discountSetup._selectedCoupons?.map((el) => el.code),
    inputPointAmount: discountSetup.inputPointAmount || undefined,
  }
})
export function useApiPayload() {
  return useAtomValue(apiPayloadAtom)
}
