import React from 'react'
import { useParams } from 'react-router-dom'
import { useModalParams } from '../use-modal-params'

type TSectionCodeContext = [
  string | undefined,
  React.Dispatch<React.SetStateAction<string | undefined>>,
]
const SectionCodeContext = React.createContext<TSectionCodeContext | undefined>(
  undefined
)

export function OrderSectionProvider({
  sectionCode,
  children,
}: React.PropsWithChildren<{ sectionCode?: string }>) {
  const sectionCodeState = React.useState<string | undefined>(sectionCode)

  return (
    <SectionCodeContext.Provider value={sectionCodeState}>
      {children}
    </SectionCodeContext.Provider>
  )
}

// 타입 정의
type UseSectionCodeReturn<Required extends boolean> = Required extends true
  ? string
  : string | undefined

/**
 * 1순위 context api에서 가져온다
 * 2순위 현재 URL의 동적 매개변수에서 섹션코드(orderSectionCode)를 가져옴
 * @returns orderSectionCode
 */
export function useSectionCode<Required extends boolean>(
  required?: Required
): UseSectionCodeReturn<Required> {
  // 1순위 context api에서 가져온다
  // 2순위 url에서 가져온다
  // sectionCode는 orderCode가 있어야지만 받아올수있는 router구조라서 orderCode를 가져온다.
  const sectionCode = React.useContext(SectionCodeContext)

  if (sectionCode?.[0]) {
    return sectionCode[0]
  }

  const params = useParams()
  const modalParams = useModalParams()

  const orderSectionCode = params.sectionCode

  if (required) {
    if (orderSectionCode) {
      return orderSectionCode as UseSectionCodeReturn<Required>
    }
    if (modalParams?.sectionCode) {
      return modalParams.sectionCode as UseSectionCodeReturn<Required>
    }

    throw new Error('orderSectionCode is required')
  }
  return orderSectionCode as UseSectionCodeReturn<Required>
}
