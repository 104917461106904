import { z } from 'zod'

/**
 *
- PAYMENT_WAIT: 입금 대기
- PAYMENT_COMPLETE: 결제 완료
- SHIPPING_READY: 배송 준비
- SHIPPING: 배송 중
- SHIPPING_COMPLETE: 배송 완료
- PURCHASE_CONFIRMATION: 구매 확정
- CANCELD_BEFORE_DEPOSIT: 입금 전 취소
- CANCEL_REQUEST: 취소 요청
- CANCELING: 취소 처리 중
- CANCEL_COMPLETE: 취소 완료
- CANCEL_REJECT: 취소 철회
- RETURN_REQUEST: 반품 요청
- RETURN_COMPLETE: 반품 완료
- RETURN_REJECT: 반품 철회
- EXCHANGE_REQUEST: 교환 요청
- EXCHANGE_COMPLETE: 교환 완료
- EXCHANGE_REJECT: 교환 철회
- COLLECTING: 수거 처리 중
- COLLECT_COMPLETE: 수거 완료
- EXCHANGE_RESHIPPING_READY: 교환 재배송 준비
- EXCHANGE_RESHIPPING: 교환 재배송 중
- BEFORE_SHIPPING: 배송 전
- SHIPPED: 출고 완료
- PARTIAL_SHIPPED: 부분 출고
- PARTIAL_CANCEL: 부분 취소
- PARTIAL_RETURN: 부분 반품
- PARTIAL_CANCEL_RETURN: 부분 취소/반품
- ALL_CANCEL_RETURN: 전체 취소/반품
- UNKNOWN: 알 수 없음
 */
export const 상수_외부채널주문_주문상태 = {
  PAYMENT_WAIT: 'PAYMENT_WAIT',
  PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
  SHIPPING_READY: 'SHIPPING_READY',
  SHIPPING: 'SHIPPING',
  SHIPPING_COMPLETE: 'SHIPPING_COMPLETE',
  PURCHASE_CONFIRMATION: 'PURCHASE_CONFIRMATION',
  CANCELD_BEFORE_DEPOSIT: 'CANCELD_BEFORE_DEPOSIT',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CANCELING: 'CANCELING',
  CANCEL_COMPLETE: 'CANCEL_COMPLETE',
  CANCEL_REJECT: 'CANCEL_REJECT',
  RETURN_REQUEST: 'RETURN_REQUEST',
  RETURN_COMPLETE: 'RETURN_COMPLETE',
  RETURN_REJECT: 'RETURN_REJECT',
  EXCHANGE_REQUEST: 'EXCHANGE_REQUEST',
  EXCHANGE_COMPLETE: 'EXCHANGE_COMPLETE',
  EXCHANGE_REJECT: 'EXCHANGE_REJECT',
  COLLECTING: 'COLLECTING',
  COLLECT_COMPLETE: 'COLLECT_COMPLETE',
  EXCHANGE_RESHIPPING_READY: 'EXCHANGE_RESHIPPING_READY',
  EXCHANGE_RESHIPPING: 'EXCHANGE_RESHIPPING',
  BEFORE_SHIPPING: 'BEFORE_SHIPPING',
  SHIPPED: 'SHIPPED',
  PARTIAL_SHIPPED: 'PARTIAL_SHIPPED',
  PARTIAL_CANCEL: 'PARTIAL_CANCEL',
  PARTIAL_RETURN: 'PARTIAL_RETURN',
  PARTIAL_CANCEL_RETURN: 'PARTIAL_CANCEL_RETURN',
  ALL_CANCEL_RETURN: 'ALL_CANCEL_RETURN',
  UNKNOWN: 'UNKNOWN',
} as const

const type = z.nativeEnum(상수_외부채널주문_주문상태)
export type T상수_외부채널주문_주문상태 = z.infer<typeof type>
