import React from 'react'
import {
  useOrderItemsViewMode,
  setOrderItemsViewMode,
} from '../order-items.store/order-items.store'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { 상수_품목보기모드, T상수_품목보기모드 } from '../order-items.constants'
import { OptionList, OptionItem } from '@imwebme/clay-components'
import { ChevronSelectorVertical } from '@imwebme/clay-icons'
import * as Popover from '@radix-ui/react-popover'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'

export function OrderItemsTableHeader() {
  const tabCode = useTabCode()
  const viewMode = useOrderItemsViewMode(tabCode)
  const [open, setOpen] = React.useState(false)

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger
        aria-label="set-order-items-view-mode"
        className="w-max flex items-center gap-x-clay-1 hover:bg-clay-semantic-actionSecondaryHover p-clay-2 rounded-clay-medium"
      >
        {`${__('품목')}: ${getLabelBy(viewMode)}`}
        <ChevronSelectorVertical colorToken="icon-sub" />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={8}
          align="start"
          aria-label="menu"
          className="w-[148px]"
        >
          <OptionList popOver value={viewMode}>
            {Object.values(상수_품목보기모드).map((_viewMode) => (
              <OptionItem
                key={_viewMode}
                text={getLabelBy(_viewMode)}
                value={_viewMode}
                onClick={() => {
                  setOrderItemsViewMode(tabCode, _viewMode)
                  setOpen(false)
                }}
              />
            ))}
          </OptionList>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export function getLabelBy(viewMode: T상수_품목보기모드) {
  return match(viewMode)
    .with(상수_품목보기모드.요약보기, () => __('요약 보기'))
    .with(상수_품목보기모드.모두보기, () => __('상세 보기'))
    .exhaustive()
}
