import React from 'react'
import { useController } from 'react-hook-form'
import { find } from 'lodash'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as B from 'fp-ts/boolean'
import { __ } from '~/shared/i18n'
import { fDate } from '~/shared/utils'
import { dateTypeOptions, dateRangeOptions } from './date-field.constants'
import {
  DateType,
  DateRange,
  DatePicker,
  DeliveryDelayDay,
  type DropdownComponentProps,
  type DropdownContent,
} from './date-field-dropdown-contents'
import { Selector } from '../components'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { OrderSearchFilterFormValues } from '~/entities/order-search-filter'

interface Props extends FormControlProp {}

const initDropdownContent = ({
  searchDateType,
  dateRange,
}: Pick<
  OrderSearchFilterFormValues,
  'searchDateType' | 'dateRange'
>): DropdownContent =>
  pipe(
    O.fromNullable(searchDateType),
    O.fold(
      () => 'dateType',
      (sdt) =>
        pipe(
          sdt === 'deliveryDelayDate',
          B.fold(
            () => (dateRange === 'CUSTOM' ? 'datePicker' : 'dateRange'),
            () => 'deliveryDelayDay'
          )
        )
    )
  )

export const DateField = ({ control }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const { value: dateTypeValue, onChange: onDateTypeChange } = useController({
    name: 'searchDateType',
    control,
  }).field
  const { value: startDateValue, onChange: onStartDateChange } = useController({
    name: 'startDate',
    control,
  }).field
  const { value: endDateValue, onChange: onEndDateChange } = useController({
    name: 'endDate',
    control,
  }).field
  const { value: dateRangeValue, onChange: onDateRangeChange } = useController({
    name: 'dateRange',
    control,
  }).field
  const { value: deliveryDelayDayValue, onChange: onDeliveryDelayDayChange } =
    useController({
      name: 'deliveryDelayDay',
      control,
    }).field
  const [dropdownContent, setDropdownContent] = React.useState<DropdownContent>(
    () =>
      initDropdownContent({
        searchDateType: dateTypeValue,
        dateRange: dateRangeValue,
      })
  )

  const dropdownContentComponent = React.useMemo(() => {
    const props: DropdownComponentProps = {
      control,
      setDropdownContent,
      setDropdownOpen,
    }

    const componentsMapper: Record<DropdownContent, React.ReactNode> = {
      dateType: <DateType {...props} />,
      dateRange: <DateRange {...props} />,
      datePicker: <DatePicker {...props} />,
      deliveryDelayDay: <DeliveryDelayDay {...props} />,
    }

    return componentsMapper[dropdownContent]
  }, [dropdownContent])

  const fieldLabel = React.useMemo(() => {
    // 아무것도 선택되지 않았을 때
    if (!dateTypeValue) {
      return __('기간')
    }

    const dateTypeLabel =
      find(dateTypeOptions, (o) => o.value === dateTypeValue)?.label ?? ''

    // 배송지연일 타입일 때
    if (dateTypeValue === 'deliveryDelayDate') {
      if (deliveryDelayDayValue) {
        return (
          `${dateTypeLabel}: ` + __('{{day}}일', { day: deliveryDelayDayValue })
        )
      }
      return `${dateTypeLabel}: ${__('입력해 주세요')}`
    }

    // 타입(배송지연일 외)과 기간이 선택 완료
    if (dateRangeValue) {
      if (dateRangeValue !== 'CUSTOM') {
        const dateRangeLabel =
          find(dateRangeOptions, (o) => o.value === dateRangeValue)?.label ?? ''
        return `${dateTypeLabel}: ${dateRangeLabel}`
      }

      // 직접입력으로 date picker에서 선택하는 경우
      const startDate = startDateValue
        ? fDate(new Date(startDateValue), 'yyyy.M.d')
        : null
      const endDate = endDateValue
        ? fDate(new Date(endDateValue), 'yyyy.M.d')
        : null

      if (startDate && endDate) {
        return `${dateTypeLabel}: ${startDate} → ${endDate}`
      }
      if (startDate) {
        return `${dateTypeLabel}: ${startDate} → ${__('종료일 선택')}`
      }
      return `${dateTypeLabel}: ${__('시작일 선택')}`
    }

    // 타입(배송지연일 외) 선택 후 날짜 선택 중
    return `${dateTypeLabel}: ${__('선택하세요')}`
  }, [
    dateTypeValue,
    dateRangeValue,
    startDateValue,
    endDateValue,
    deliveryDelayDayValue,
  ])

  const resetFields = React.useCallback(() => {
    setDropdownContent('dateType')
    onDateTypeChange(null)
    onDateRangeChange(null)
    onStartDateChange(null)
    onEndDateChange(null)
    onDeliveryDelayDayChange(null)
  }, [])

  React.useEffect(() => {
    const resetFieldsOnExit = () => {
      if (dropdownOpen) {
        return
      }

      // 배송지연일
      if (dateTypeValue === 'deliveryDelayDate') {
        if (typeof deliveryDelayDayValue !== 'number') {
          resetFields()
        }
        return
      }

      // (배송지연일 이외) 직접입력
      if (dateRangeValue === 'CUSTOM') {
        if (!startDateValue || !endDateValue) {
          resetFields()
        }
        return
      }

      // (배송지연일 이외) 기간값 선택
      if (!dateRangeValue) {
        resetFields()
      }
    }
    resetFieldsOnExit()
  }, [
    dropdownOpen,
    dateTypeValue,
    startDateValue,
    endDateValue,
    deliveryDelayDayValue,
    dateRangeValue,
  ])

  return (
    <Selector
      label={fieldLabel}
      isActive={!!dateTypeValue}
      resetField={resetFields}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={dropdownContentComponent}
    />
  )
}
