import React, { Children, DOMAttributes, PropsWithChildren } from 'react'
import { IconX } from '@imwebme/icon'
import { type TProductCard002 } from './product-card-002.ns'
import { __ } from '~/shared/i18n'

/**
 * 상품정보를 표시하기위한 primitive 컴포넌트입니다.
 */
function ProductCard002({ children }: PropsWithChildren) {
  const childrenArray = Children.toArray(children) as React.ReactElement[]

  const findChild = (childName: string) => {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined) {
      throw new Error(`${childName} is undefined`)
    }
    return result
  }

  const ImageSlot = findChild('image')
  const NameSlot = findChild('name')
  const OptionSlot = findChild('option')
  const PriceSlot = findChild('price')

  return (
    <div className="grid grid-cols-[48px,1fr] gap-x-[12px]">
      <div
        className="rounded-[8px] w-[48px] h-[48px] relative overflow-hidden [&>*]:aspect-1 [&>*]:absolute [&>*]:w-full [&>*]:h-full [&>*]:object-cover [&>*]:top-1/2 [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2"
        {...ImageSlot?.props}
      />
      <div className="semantic-p14">
        <div className="semantic-l14" {...NameSlot?.props} />
        <div
          className="text-clay-semantic-textSecondary mt-[4px]"
          {...OptionSlot?.props}
        />
        <div
          className="text-clay-semantic-text grid gap-y-[4px] mt-[8px]"
          {...PriceSlot?.props}
        />
      </div>
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'image' | 'name' | 'option' | 'price'
}
const Slot: React.FC<SlotProps> = () => null
/**
 * Slot은 ProductCard002의 자식으로 사용되며, ProductCard002의 특정 영역에 컨텐츠를 표시해줍니다.
 */
ProductCard002.Slot = Slot

// ==============================
// ProductCard002Template
// ==============================

class CProductCard002Template {
  props: TProductCard002.props
  constructor(props: TProductCard002.props) {
    this.props = props
  }

  // render
  Qty = () => {
    const { qty } = this.props
    if (qty && qty > 0) {
      return (
        <>
          <span style={{ gridArea: 'qty' }}>{qty}</span>
          <IconX
            className="stroke-[2]"
            style={{
              gridArea: 'qtyIcon',
            }}
          />
        </>
      )
    }
    return null
  }

  Thumbnail = () => {
    if (!this.props.thumbnail) {
      return <div className="bg-clay-semantic-textDisabled"></div>
    }
    if (typeof this.props.thumbnail === 'string') {
      return (
        <img
          loading="lazy"
          draggable="false"
          src={this.props.thumbnail as string}
          alt=""
        />
      )
    }
    return this.props.thumbnail
  }

  OriginalPrice = ({
    children,
    ...props
  }: Omit<DOMAttributes<HTMLElement>, 'children'> & {
    children: (price: number) => React.ReactNode | string
  }) => {
    const { originalPrice, price } = this.props
    if (originalPrice && price && originalPrice !== price) {
      return (
        <s
          className="text-clay-semantic-textDisabled"
          style={{
            gridArea: 'originalPrice',
          }}
          {...props}
        >
          {children(originalPrice)}
        </s>
      )
    }
    return null
  }
  Price = ({
    children,
    ...props
  }: Omit<DOMAttributes<HTMLElement>, 'children'> & {
    children: (price: number) => React.ReactNode | string
  }) => {
    const { price, originalPrice } = this.props
    if (originalPrice !== undefined) {
      return <div {...props}>{children(price || originalPrice)}</div>
    }
    return null
  }
}
/**
 * 상품 아이템을 표시해줍니다.
 *
 * @param thumbnail 아이템의 이미지를 표시해줍니다.
 * @param name 아이템의 이름을 표시해줍니다.
 * @param option 아이템의 옵션을 표시해줍니다.
 * @param count 아이템의 수량을 표시해줍니다.
 * @param originalPrice 아이템의 원가를 표시해줍니다. 원가는 항상 포함되어야합니다.
 * @param price 아이템의 결제해야하는 금액을 표시해줍니다.
 * @param currency 아이템이 어느 통화인가를 표시해줍니다.
 */
function ProductCard002Template({
  id,
  thumbnail,
  name,
  option,
  qty,
  originalPrice,
  price,
  currency = 'KRW',
}: TProductCard002.props) {
  const CPCT = new CProductCard002Template({
    id,
    thumbnail,
    name,
    option,
    qty,
    originalPrice,
    price,
    currency,
  })
  const Thumbnail = React.useMemo(() => <CPCT.Thumbnail />, [thumbnail])

  return (
    <ProductCard002 data-id={id}>
      <ProductCard002.Slot name="image">{Thumbnail}</ProductCard002.Slot>
      <ProductCard002.Slot name="name">{name}</ProductCard002.Slot>
      <ProductCard002.Slot name="option">{option}</ProductCard002.Slot>
      <ProductCard002.Slot name="price">
        <div
          className="grid items-center"
          style={{
            gridTemplateAreas: `
              'qty qtyIcon price'
              'blank blank originalPrice'`,
            gridTemplateColumns: 'auto auto 1fr',
          }}
        >
          <CPCT.Qty />
          <CPCT.Price>
            {(p) =>
              __('{{price, 3comma}}{{currency, currency}}', {
                price: p,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })
            }
          </CPCT.Price>
          <div className="flex items-center " />
          <CPCT.OriginalPrice>
            {(p) =>
              __('{{price, 3comma}}{{currency, currency}}', {
                price: p,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })
            }
          </CPCT.OriginalPrice>
        </div>
      </ProductCard002.Slot>
    </ProductCard002>
  )
}
export { ProductCard002, ProductCard002Template }
