import { Tag } from '@imwebme/clay-components'
import {
  상수_외부채널주문_채널상태,
  T상수_외부채널주문_채널상태,
  T상수_외부채널주문_주문상태,
  T상수_섹션상태통합,
  상수_섹션상태통합,
  T상수_결제상태,
  T상수_결제상태대표,
} from '~/entities/@x'
import { __, __g } from '~/shared/i18n'
import { StatusTagBaseProps } from './status-tag.type'
import { ExternalChannelStatusTag } from './external-channel-status-tag'
import { SectionStatusTag } from './section-status-tag'

interface ExternalChannelOrderStatusTagProps
  extends StatusTagBaseProps<T상수_외부채널주문_주문상태> {
  paymentStatusCd: T상수_결제상태 | T상수_결제상태대표
}

/**
 * 외부채널 주문상태 태그
 * @param toggleDimmed - plain variant일 때 배경색을 변경할지 여부
 * @param paymentStatusCd - 주문 결제상태코드, 환불여부에 따라 배경색을 분기함
 */
export function ExternalChannelOrderStatusTag({
  statusCd,
  size,
  toggleDimmed,
  paymentStatusCd,
}: ExternalChannelOrderStatusTagProps) {
  if (!statusCd) {
    return null
  }

  const _statusCd = statusMapping[statusCd] || null

  if (is외부채널주문채널상태(_statusCd)) {
    return (
      <ExternalChannelStatusTag
        statusCd={_statusCd}
        size={size}
        toggleDimmed={toggleDimmed}
      />
    )
  }

  if (is섹션상태통합(_statusCd)) {
    return (
      <SectionStatusTag
        statusCd={_statusCd}
        size={size}
        toggleDimmed={toggleDimmed}
        paymentStatusCd={paymentStatusCd}
      />
    )
  }

  return <Tag variant="warning" text={__('알수 없음')} size={size} />
}

type TransformedStatusCd =
  | T상수_외부채널주문_채널상태
  | T상수_섹션상태통합
  | null

function is외부채널주문채널상태(
  status: TransformedStatusCd
): status is T상수_외부채널주문_채널상태 {
  return status !== null && status in 상수_외부채널주문_채널상태
}

function is섹션상태통합(
  status: TransformedStatusCd
): status is T상수_섹션상태통합 {
  return status !== null && status in 상수_섹션상태통합
}

const statusMapping: Record<T상수_외부채널주문_주문상태, TransformedStatusCd> =
  {
    PAYMENT_WAIT: 상수_외부채널주문_채널상태.입금대기,
    PAYMENT_COMPLETE: 상수_외부채널주문_채널상태.결제완료,
    SHIPPING_READY: 상수_외부채널주문_채널상태.배송준비중,
    SHIPPING: 상수_외부채널주문_채널상태.배송중,
    SHIPPING_COMPLETE: 상수_외부채널주문_채널상태.배송완료,
    PURCHASE_CONFIRMATION: 상수_외부채널주문_채널상태.구매확정,
    CANCELD_BEFORE_DEPOSIT: 상수_외부채널주문_채널상태.미입금취소,
    CANCEL_REQUEST: 상수_외부채널주문_채널상태.취소요청,
    CANCELING: 상수_외부채널주문_채널상태.취소처리중,
    CANCEL_COMPLETE: 상수_외부채널주문_채널상태.취소처리완료,
    CANCEL_REJECT: 상수_외부채널주문_채널상태.취소철회,
    RETURN_REQUEST: 상수_외부채널주문_채널상태.반품요청,
    RETURN_COMPLETE: 상수_외부채널주문_채널상태.반품완료,
    RETURN_REJECT: 상수_외부채널주문_채널상태.반품철회,
    EXCHANGE_REQUEST: 상수_외부채널주문_채널상태.교환요청,
    EXCHANGE_COMPLETE: 상수_외부채널주문_채널상태.교환완료,
    EXCHANGE_REJECT: 상수_외부채널주문_채널상태.교환철회,
    COLLECTING: 상수_외부채널주문_채널상태.수거처리중,
    COLLECT_COMPLETE: 상수_외부채널주문_채널상태.수거완료,
    EXCHANGE_RESHIPPING_READY: 상수_외부채널주문_채널상태.교환재배송대기,
    EXCHANGE_RESHIPPING: 상수_외부채널주문_채널상태.교환재배송중,
    BEFORE_SHIPPING: 상수_섹션상태통합.배송전,
    SHIPPED: 상수_섹션상태통합.출고완료,
    PARTIAL_SHIPPED: 상수_섹션상태통합.부분출고,
    PARTIAL_CANCEL: 상수_섹션상태통합.부분취소,
    PARTIAL_RETURN: 상수_섹션상태통합.부분반품,
    PARTIAL_CANCEL_RETURN: 상수_섹션상태통합.부분취소반품,
    ALL_CANCEL_RETURN: 상수_섹션상태통합.전체취소반품,
    UNKNOWN: null,
  }
