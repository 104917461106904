import { Checkbox, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TGetResProducttListItemDto } from '~/entities/product/product-list'
import { __ } from '~/shared/i18n'
import { FormattedPrice } from '../../formatted-price'
import { genUniqueId } from '~/widgets/product-add-modal/product-add-modal.fn'
import {
  useProductAddModalAtom,
  useSelectedProdAtom,
} from '~/widgets/product-add-modal/product-add-modal.store'

interface Props {
  prod: TGetResProducttListItemDto
}

export function CombinationProdNoInput({ prod }: Props) {
  const [selectedProd, setSelectedProd] = useSelectedProdAtom(prod.prodCode)
  const modalData = useProductAddModalAtom.get()

  return (
    <>
      {(prod.optionDetails ?? []).map((optionDetail) => {
        if (!optionDetail.isRequiredOption) {
          return null
        }
        const id = genUniqueId({
          prodCode: prod.prodCode,
          optionDetailCode: optionDetail.optionDetailCode,
        })
        return (
          <Flex
            key={id}
            alignItems="center"
            justifyContent="space-between"
            padding={`${vars.spacing[15]} 0`}
            columnGap={vars.spacing[4]}
          >
            <Checkbox.Label
              onChangeChecked={(v: boolean) => {
                if (v) {
                  setSelectedProd({
                    action: 'add',
                    type: '체크박스옵션',
                    payload: {
                      prod,
                      optionDetailCode: optionDetail.optionDetailCode,
                      options: optionDetail.options.map((o, idx) => ({
                        label: `${o.optionName} ${o.valueName}`,
                        price: idx === 0 ? optionDetail.optionDetailPrice : 0,
                      })),
                    },
                  })
                } else {
                  setSelectedProd({
                    action: 'remove',
                    type: '체크박스옵션',
                    payload: genUniqueId({
                      prodCode: prod.prodCode,
                      optionDetailCode: optionDetail.optionDetailCode,
                    }),
                  })
                }
              }}
              label={getLabel(optionDetail.options)}
              checked={!!selectedProd?.체크박스옵션?.includes(id)}
              disabled={modalData.idList?.includes(id)}
            />
            <Typography variant="body-medium" whiteSpace="nowrap">
              <FormattedPrice price={optionDetail.optionDetailPrice} />
            </Typography>
          </Flex>
        )
      })}
    </>
  )
}

function getLabel(
  options: { optionName: string; valueName: string }[]
): string {
  return options.map((o) => `${o.optionName} ${o.valueName}`).join(', ')
}
