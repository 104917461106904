import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import resources from './i18n-resource'
import { extendsFormatter } from './i18n-format'
import { pipe } from 'fp-ts/function'
import SessionStorageManager from '~/shared/managers/session-storage-manger'

const languageDetector = new LanguageDetector()
languageDetector.addDetector({
  name: 'customLocalStorageDetector',

  lookup() {
    return SessionStorageManager.getItem('i18nextLng')
  },
})

i18n
  .use(languageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    defaultNS: 'admin',
    ns: ['admin', 'common', 'error', 'glossary'],
    supportedLngs: ['ko', 'en', 'zh-Hant'],
    fallbackLng: 'ko',
    debug: false,
    lng: 'ko',
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    resources: pipe(resources),
    detection: {
      order: ['customLocalStorageDetector', 'navigator'],
      caches: [],
    },
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
  })

extendsFormatter(i18n)

export const overrideI18n = i18n
