import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  tabCode: string
}
type AtomType = {
  key: string
  value?: boolean
}
const DeliveryShippingAllState = atomFamily(
  ({ key, value = false }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)

export const useDeliveryShippingAllAtom = {
  key: (p: AtomKey) => `tabCode(${p.tabCode}):bulk-delivery-shipping-all`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          DeliveryShippingAllState({
            key: useDeliveryShippingAllAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          DeliveryShippingAllState({
            key: useDeliveryShippingAllAtom.key(p),
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          DeliveryShippingAllState({
            key: useDeliveryShippingAllAtom.key(p),
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          DeliveryShippingAllState({
            key: useDeliveryShippingAllAtom.key(p),
          }),
        [p]
      )
    ),
}
