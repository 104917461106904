import { z } from 'zod'
import {
  YN,
  상수_구매혜택,
  상수_배송비할인,
  상수_쿠폰할인타입,
  상수_할인적립방법,
} from '../@x'

const benefitDataTypePropertiesData = z.object({
  minimum: z.number().describe('최소 만족 금액'),
  maximum: z.number().describe('할인 최대금액'),
  amount: z.number().describe('할인금액 또는 할인율'),
  type: z.nativeEnum(상수_할인적립방법),
})

const benefitData = z.object({
  benefitType: z.nativeEnum(상수_구매혜택),
  dc: benefitDataTypePropertiesData.describe(
    '구매 금액 할인 또는 동시 사용 시'
  ),
  point:
    benefitDataTypePropertiesData.describe('적립금 지급 또는 동시 사용 시'),
  shippingFreeType: z.nativeEnum(상수_배송비할인),
})

export const couponDto = z.object({
  name: z.string().describe('쿠폰 이름'),
  code: z.string().describe('발급된 쿠폰 코드'),
  applySalePrice: z.number().describe('쿠폰 정액 할인금액'),
  applySalePercent: z.number().describe('쿠폰 정율 할인금액'),
  applySaleType: z.nativeEnum(상수_쿠폰할인타입),
  applySaleTypeMinimumPrice: z.number().describe('할인받기 위한 최소 금액'),
  applySaleTypeMaxPrice: z.number().describe('쿠폰 할인 최대 금액'),
  isOverlap: z.nativeEnum(YN).describe('다른 쿠폰 중복 사용'),
  isUnlimitedDate: z.nativeEnum(YN).describe('기간 무제한'),
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  isAvailable: z.nativeEnum(YN).describe('쿠폰 유효 여부'),
})

export const getResMemberDiscountInfoDto = z.object({
  point: z.number().describe('적립금'),
  memberShipGroup: benefitData.describe('쇼핑 구매등급 정보'),
  couponList: couponDto.array(),
})

export const getPayloadMemberDiscountInfoDto = z.object({
  currency: z.string(),
  itemPrice: z.number(),
  prodCode: z.string().array(),
})
