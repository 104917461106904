import { match, P } from 'ts-pattern'
import { 상수_상품옵션타입 } from '~/entities/@x'
import { TGetResProducttListItemDto } from '~/entities/product/product-list'

/**
 * 선택된 상품의 커스텀 id 생성
 */
export function genUniqueId({
  prodCode,
  optionDetailCode,
  options,
}: {
  prodCode: string
  optionDetailCode?: string | null
  options?: { optionCode?: string; valueCode?: string }[] | null
}): string {
  let id = prodCode

  if (optionDetailCode) {
    id += `-${optionDetailCode}`
  }

  const validOptions = options?.filter((opt) => opt.optionCode && opt.valueCode)
  if (validOptions && validOptions.length > 0) {
    const sortedOptions = validOptions
      .sort((a, b) =>
        `${a.optionCode}:${a.valueCode}`.localeCompare(
          `${b.optionCode}:${b.valueCode}`
        )
      )
      .map((opt) => `${opt.optionCode}:${opt.valueCode}`)
      .join('|')

    id += `-${sortedOptions}`
  }

  return id
}

export function getProdOptionType(prod: TGetResProducttListItemDto) {
  // 1. 필수옵션X (본상품으로 존재)
  if (
    (!prod.options ||
      prod.options.every((opt) => opt.isRequiredOption === false)) &&
    (!prod.optionDetails ||
      prod.optionDetails.every((opt) => opt.isRequiredOption === false))
  ) {
    return '필수옵션X'
  }

  if (prod.optionDetails) {
    // 2. 조합형_입력형X
    if (
      !prod.options ||
      prod.options.every((el) => el.isRequiredOption === false)
    ) {
      return '조합형_입력형X'
    }

    // 3. 조합형_입력형O
    if (prod.options?.some((el) => el.type === 상수_상품옵션타입.입력)) {
      return '조합형_입력형O'
    }
  }

  // 4. 비조합형
  return '비조합형'
}

export function calcNumTotalProd(
  prod: TGetResProducttListItemDto
): number | undefined {
  const optionType = getProdOptionType(prod)
  const numNonRequiredOption =
    prod.options
      ?.filter((el) => el.isRequiredOption === false)
      .reduce((acc, cur) => acc + (cur.values?.length ?? 0), 0) ?? 0

  return match(optionType)
    .with(P.union('비조합형', '조합형_입력형O'), () => undefined)
    .with('필수옵션X', () => 1 + numNonRequiredOption)
    .with('조합형_입력형X', () => prod.optionDetailCount + numNonRequiredOption)
    .exhaustive()
}

export function calcTotalPrice({
  prod,
  selectedOptions,
}: {
  prod: TGetResProducttListItemDto
  selectedOptions?: { label: string; price: number }[] | null
}): number {
  const 선택된옵션이없음 = !selectedOptions || selectedOptions.length === 0

  const 필수는only입력옵션 =
    !prod.optionDetails &&
    prod.options &&
    prod.options.some((el) => el.isRequiredOption) &&
    prod.options.every(
      (el) => !el.isRequiredOption || el.type === 상수_상품옵션타입.입력
    )

  if (선택된옵션이없음 || 필수는only입력옵션) {
    return prod.price
  }

  return (selectedOptions ?? []).reduce((acc, opt) => acc + opt.price, 0)
}
