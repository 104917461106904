import React from 'react'
import { useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import { Selector } from '../components'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'
import { getOrderInfoFieldLabel } from './order-info.helper'
import { Textfield } from '@imwebme/clay-components'

interface Props extends FormControlProp {
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
  fieldKey: OrderSearchFilterOrderInfoFields
  placeholder?: string
}

export const OrderInfoTextInput = ({
  control,
  handleRemoveOrderInfoField,
  fieldKey,
  placeholder = __('입력해 주세요'),
}: Props) => {
  const { onChange, value, ...inputProps } = useController({
    name: fieldKey,
    control,
  }).field
  const [dropdownOpen, setDropdownOpen] = React.useState(
    () => value === undefined || value === null
  )

  const fieldLabel = React.useMemo(() => {
    let baseLabel = getOrderInfoFieldLabel(fieldKey) + ': '

    value ? (baseLabel += value) : (baseLabel += __('입력해 주세요'))

    return baseLabel
  }, [value, fieldKey])

  const handleChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (evt.target.value === '') {
        onChange(null)
        return
      }
      onChange(evt.target.value)
    },
    []
  )

  const handleResetField = React.useCallback(() => {
    onChange(null)
    handleRemoveOrderInfoField(fieldKey)
  }, [fieldKey])

  React.useEffect(() => {
    ;(function removeFieldOnExit() {
      if (dropdownOpen === false && !value) {
        handleRemoveOrderInfoField(fieldKey)
      }
    })()
  }, [value, dropdownOpen])

  return (
    <Selector
      label={fieldLabel}
      isActive={true}
      resetField={handleResetField}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="px-clay-5 py-clay-4 w-[260px]">
          <Textfield.Set>
            <Textfield.Legend text={getOrderInfoFieldLabel(fieldKey)} />
            <Textfield.Box variant="secondary">
              <Textfield.Input
                value={value ?? ''}
                onChange={handleChange}
                placeholder={placeholder}
                autoComplete="off"
                {...inputProps}
              />
            </Textfield.Box>
          </Textfield.Set>
        </div>
      }
    />
  )
}
