import { __ } from '~/shared/i18n'
import type { TBatchAlertBase } from './batch-alert.type'
import { Button } from '@imwebme/clay-components'
import _ from 'lodash'
import { ContentsBanner } from '@imwebme/clay-components'

interface TBatchAlert extends TBatchAlertBase {
  onClose: () => void
  onClickCheckFails: () => void
  totalCount: number
  successCount: number
}
export function BatchAlert({
  onClose,
  onClickCheckFails,
  totalCount,
  successCount,
}: TBatchAlert) {
  return (
    <div className="mb-[20px] mt-[32px]">
      <div className="mx-[var(--sx)] px-[24px]">
        <div className="semantic-h4-bold">
          {__('{{number}}건이 등록됐어요', {
            number: successCount,
          })}
        </div>
      </div>
      <div className="mx-[var(--sx)] px-[24px] mt-[20px]">
        <ContentsBanner
          type="secondary"
          variant={'warning'}
          text={__('{{전체등록건}}건 중 {{성공등록건}}건을 등록했어요', {
            전체등록건: totalCount,
            성공등록건: successCount,
          })}
        />
      </div>
      <div className="mx-[var(--sx)] px-[24px] mt-[var(--space)] flex justify-end gap-x-[8px]">
        <Button
          native={{ type: 'button' }}
          variant="secondary"
          onClick={onClose}
          text={__('닫기')}
        />
        <Button
          native={{ type: 'button' }}
          variant="primary"
          onClick={onClickCheckFails}
          text={__('실패건 확인')}
        />
      </div>
    </div>
  )
}
