import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import {
  TExternalOrderSectionItemDto,
  TExternalSaleChannelItemDto,
} from '../..'

export class 모델_외부채널_주문섹션_품목 {
  item: TExternalOrderSectionItemDto
  saleChannelItem?: TExternalSaleChannelItemDto

  constructor(
    item: TExternalOrderSectionItemDto,
    saleChannelItem?: TExternalSaleChannelItemDto
  ) {
    this.item = item
    this.saleChannelItem = saleChannelItem
  }
}

export class 모델_외부채널_주문섹션_품목_UI extends 모델_외부채널_주문섹션_품목 {
  get 상품이름() {
    return this.item.prodName
  }
  get 상품가격() {
    return this.item.itemPrice
  }
  get 옵션() {
    return pipe(
      this.item.optionInfo,
      O.fromNullable,
      O.fold(
        () => '',
        flow(
          A.map((e) => `${e.key} ${e.value}`),
          (e) => e.join(', ')
        )
      )
    )
  }
  get 배송메모() {
    const result = pipe(this.saleChannelItem?.apiProductInfo.memo)
    return result
  }
}
