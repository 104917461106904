import { useSearchParams } from 'react-router-dom'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { useNavigate } from 'react-router-dom'

/** 주문목록 검색어 */
export function useOrderSearchQuery() {
  const [searchParams] = useSearchParams()
  const tabCode = useTabCode()
  const navigate = useNavigate()

  const setOrderSearchQuery = (query: string | null) => {
    if (query) {
      searchParams.set('q', query)
    } else {
      searchParams.delete('q')
    }
    navigate(`/orders/tab/${tabCode}/1?${searchParams.toString()}`)
  }

  return [searchParams.get('q') ?? '', setOrderSearchQuery] as const
}
