import React from 'react'
import { Suspense } from 'react'
import { TDeliveryRetrieveBase } from '.'

const DeliveryRetrieveContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: module.DeliveryRetrieve,
  }))
)

interface TDeliveryRetrievePack extends TDeliveryRetrieveBase {}
export function DeliveryRetrievePack(props: TDeliveryRetrievePack) {
  return (
    <Suspense>
      <DeliveryRetrieveContainer {...props} />
    </Suspense>
  )
}
