import { range } from 'lodash'

/**
 * Atlassian 형식으로 페이지를 생성한다.
 */
export function createPages({
  page,
  pageSize,
  total,
}: {
  page: number
  pageSize: number
  total: number
}) {
  const 페이지슬랏_중앙값 = Math.floor(pageSize / 2)
  const 가운데_페이지슬랏_개수 = Math.max(pageSize - 4, 0) // 맨 앞 2개, 맨 뒤 2개를 제외하고 가운데 위치하는 슬랏 수

  if (total <= pageSize) {
    return range(1, total + 1)
  }
  if (page - 1 <= 페이지슬랏_중앙값) {
    return [1, 2, ...range(3, 3 + 가운데_페이지슬랏_개수), '...', total]
  }
  if (total - page <= 페이지슬랏_중앙값) {
    return [
      1,
      '...',
      ...range(total - 1 - 가운데_페이지슬랏_개수, total - 1),
      total - 1,
      total,
    ]
  }
  return [
    1,
    '...',
    ...range(
      page - Math.floor(가운데_페이지슬랏_개수 / 2),
      page + Math.ceil(가운데_페이지슬랏_개수 / 2)
    ),
    '...',
    total,
  ]
}
