import { BasicModal } from '~/shared/components/basic-modal'
import { Button } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  handleSubmit: () => void
  isSubmitting: boolean
}

export function OrderEditCalcSubmitModal({
  open,
  onOpenChange,
  handleSubmit,
  isSubmitting,
}: Props) {
  return (
    <AlertDialog.Root {...{ open, onOpenChange }}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <BasicModal>
            <BasicModal.Slot name="title">
              {__('주문서에 반영 할까요?')}
            </BasicModal.Slot>
            <BasicModal.Slot name="footer">
              <AlertDialog.Cancel asChild>
                <Button
                  native={{ type: 'button' }}
                  variant="secondary"
                  text={__('닫기')}
                />
              </AlertDialog.Cancel>
              <Button
                native={{ type: 'button' }}
                variant="primary"
                text={__('주문서 반영')}
                onClick={handleSubmit}
                isLoading={isSubmitting}
              />
            </BasicModal.Slot>
          </BasicModal>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
