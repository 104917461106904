import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TRequestPaymentBase, useRequestPaymentAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ErrorCard } from '~/shared/components/error'

const RequestPaymentContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.RequestPayment),
  }))
)

interface TRequestPaymentPack extends TRequestPaymentBase {}
export function RequestPaymentPack(props: TRequestPaymentPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useRequestPaymentAtom.state({
    orderCode: orderCode + (props.atomKey || ''),
  })
  return (
    <AlertDialog.Root defaultOpen={false} open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<>{'...loading'}</>}>
              <RequestPaymentContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
