import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Flex, Textfield } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { useDeliverySetupAtom } from '~/container/order-create/order-create.store'
import {
  internationalDelivDirectInputSchema,
  TInternationalDelivDirectInputSchema,
} from '~/container/order-create/order-create.schema'

export function DirectInputInternational() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const {
    register,
    formState: { errors },
  } = useForm<TInternationalDelivDirectInputSchema>({
    resolver: zodResolver(internationalDelivDirectInputSchema),
    mode: 'onTouched',
  })

  return (
    <>
      <Flex columnGap={vars.spacing[25]}>
        <Textfield.Set flex={1}>
          <Textfield.Legend text={__('받는 분')} />
          <Textfield.Box>
            <Textfield.Input
              {...register('receiverName')}
              value={deliverySetup.receiverName ?? ''}
              onChangeText={(v) => setDeliverySetup({ receiverName: v })}
              isValid={errors.receiverName ? false : undefined}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>
            {errors.receiverName?.message}
          </Textfield.HelperText>
        </Textfield.Set>
        <Textfield.Set flex={1}>
          <Textfield.Legend text={__('연락처')} />
          <Textfield.Box>
            <Textfield.Input
              {...register('receiverCall')}
              value={deliverySetup.receiverCall ?? ''}
              onChangeText={(v) =>
                setDeliverySetup({ receiverCall: v.replace(/[^0-9]/g, '') })
              }
              isValid={errors.receiverCall ? false : undefined}
              type="tel"
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>
            {errors.receiverCall?.message}
          </Textfield.HelperText>
        </Textfield.Set>
      </Flex>
      <Flex.Column rowGap={vars.spacing[3]}>
        <Textfield.Set>
          <Textfield.Legend text={__('주소정보')} />
          <Textfield.Box>
            <Textfield.Input value={deliverySetup.countryName ?? ''} readOnly />
          </Textfield.Box>
        </Textfield.Set>
        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              {...register('zipcode')}
              value={deliverySetup.zipcode ?? ''}
              onChangeText={(v) => setDeliverySetup({ zipcode: v })}
              isValid={errors.zipcode ? false : undefined}
              placeholder={__('우편번호 (Zipcode)')}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>{errors.zipcode?.message}</Textfield.HelperText>
        </Textfield.Set>
        <Flex columnGap={vars.spacing[3]}>
          <Textfield.Set>
            <Textfield.Box>
              <Textfield.Input
                {...register('state')}
                value={deliverySetup.state ?? ''}
                onChangeText={(v) => setDeliverySetup({ state: v })}
                isValid={errors.state ? false : undefined}
                placeholder={__('시/도 (State)')}
                autoComplete="off"
              />
            </Textfield.Box>
            <Textfield.HelperText>{errors.state?.message}</Textfield.HelperText>
          </Textfield.Set>
          <Textfield.Set>
            <Textfield.Box>
              <Textfield.Input
                {...register('city')}
                value={deliverySetup.city ?? ''}
                onChangeText={(v) => setDeliverySetup({ city: v })}
                isValid={errors.city ? false : undefined}
                placeholder={__('구/군/시 (City)')}
                autoComplete="off"
              />
            </Textfield.Box>
            <Textfield.HelperText>{errors.city?.message}</Textfield.HelperText>
          </Textfield.Set>
        </Flex>

        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              {...register('street')}
              value={deliverySetup.street ?? ''}
              onChangeText={(v) => setDeliverySetup({ street: v })}
              isValid={errors.street ? false : undefined}
              placeholder={__('도로명주소 (Street)')}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>{errors.street?.message}</Textfield.HelperText>
        </Textfield.Set>

        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              {...register('building')}
              value={deliverySetup.building ?? ''}
              onChangeText={(v) => setDeliverySetup({ building: v })}
              isValid={errors.building ? false : undefined}
              placeholder={__('건물 이름 (Building)')}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>
            {errors.building?.message}
          </Textfield.HelperText>
        </Textfield.Set>
      </Flex.Column>
    </>
  )
}
