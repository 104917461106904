import React from 'react'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { useTabCode } from '~/shared/hooks/use-tab-code'
import { useNavigate } from '~/router'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

/**
 * @see https://cocoastudio.slack.com/archives/C069PU8TBNW/p1726032106956209
 */
export default function () {
  const naviate = useNavigate()
  const defaultTabCode = useTabCode()

  React.useEffect(() => {
    naviate('/orders/tab/:tabCode', {
      replace: true,
      params: {
        tabCode: defaultTabCode,
      },
    })
  }, [])

  return <Loading />
}
