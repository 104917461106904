import React from 'react'
import { match, P } from 'ts-pattern'
import { DelivCountryField } from './deliv-country-field'
import { DelivTypeField } from './deliv-type-field'
import { DelivAddressField } from './deliv-address-field'
import {
  useDeliverySetupAtom,
  useOrderInfoAtom,
} from '~/container/order-create/order-create.store'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { __ } from '~/shared/i18n'
import { DirectInputKorean } from './direct-input-korean'
import { DirectInputInternational } from './direct-input-international'
import { UnipassNumberField } from './unipass-number-field'
import { DelivPayTypeField } from './deliv-pay-type-field'
import { 상수_배송타입 } from '~/entities/@x'
import { Flex } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { MemoField } from './memo-field'
import { PickupMemoField } from './pickup-memo-field'

export function EditableView() {
  const [orderInfo] = useOrderInfoAtom()
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()

  React.useEffect(() => {
    setDeliverySetup({
      receiverName: orderInfo.ordererName || '',
      receiverCall: orderInfo.ordererCall || '',
    })
  }, [])

  return (
    <>
      {/* 배송 국가 */}
      <DelivCountryField />

      {/* 배송 방식 */}
      <DelivTypeField />

      {/* 배송지 */}
      {match({
        deliveryTypeCd: deliverySetup._deliveryTypeCd,
        memberCode: orderInfo.memberCode,
        country: deliverySetup.country,
      })
        .with(
          {
            deliveryTypeCd: P.union(
              상수_배송타입.다운로드,
              상수_배송타입.방문수령,
              상수_배송타입.배송없음
            ),
          },
          () => null
        )
        .with({ memberCode: P.string }, (p) => (
          <DelivAddressField memberCode={p.memberCode} />
        ))
        .with({ memberCode: P.nullish, country: 'KR' }, () => (
          <FieldLayout title={__('배송지')}>
            <Flex.Column rowGap={vars.spacing[5]}>
              <DirectInputKorean />
            </Flex.Column>
          </FieldLayout>
        ))
        .with({ memberCode: P.nullish, country: P.not('KR') }, () => (
          <FieldLayout title={__('배송지')}>
            <Flex.Column rowGap={vars.spacing[5]}>
              <DirectInputInternational />
            </Flex.Column>
          </FieldLayout>
        ))
        .otherwise(() => null)}

      {/* 메모 or 방문수령메모  */}
      {match(deliverySetup._deliveryTypeCd)
        .with(
          P.not(
            P.union(
              상수_배송타입.다운로드,
              상수_배송타입.방문수령,
              상수_배송타입.배송없음
            )
          ),
          () => <MemoField />
        )
        .with(상수_배송타입.방문수령, () => <PickupMemoField />)
        .otherwise(() => null)}

      {/* 개인통관고유부호 */}
      <UnipassNumberField />

      {/* 배송비 */}
      <DelivPayTypeField />
    </>
  )
}
