import { Clay, Flex } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

export function StepSkeleton() {
  return (
    <Clay
      paddingTop={vars.spacing[6]}
      borderRadius={vars.rounded.large}
      background={vars.semantic.color.surface}
    >
      {Array.from({ length: 3 }).map((_, idx) => (
        <Flex
          key={idx}
          paddingInline={vars.spacing[6]}
          paddingBottom={vars.spacing[6]}
          columnGap={vars.spacing[6]}
        >
          <Clay width="119px">
            <Clay
              width="80px"
              height="20px"
              borderRadius={vars.rounded.medium}
              background={vars.semantic.color.surfaceSub}
            />
          </Clay>
          <Clay flex={1} height="28px">
            <Clay
              height="20px"
              borderRadius={vars.rounded.medium}
              background={vars.semantic.color.surfaceSub}
            />
          </Clay>
        </Flex>
      ))}
    </Clay>
  )
}
