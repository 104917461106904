import React from 'react'
import { Separator } from '~/shared/components/ui/separator'
import { Card } from '~/shared/components/ui/card'
import { TReceiverInfoCardBase } from '.'

const ReceiverInfoCardContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ReceiverInfoCard),
  }))
)

interface TReceiverInfoCardPack extends TReceiverInfoCardBase {}
export function ReceiverInfoCardPack(props: TReceiverInfoCardPack) {
  return (
    <React.Suspense fallback={<ReceiverInfoCardSkeleton />}>
      <ReceiverInfoCardContainer {...props} />
    </React.Suspense>
  )
}

function ReceiverInfoCardSkeleton() {
  return (
    <Card.Root className="h-auto w-[auto] rounded-[10px] items-center bg-clay-semantic-surface py-[24px]">
      <Card.Header className="p-0 px-[24px] flex flex-col gap-[28px]">
        <div className="skeleton rounded-[4px] w-[120px]" />
        <div className="skeleton rounded-[4px] w-[80px]" />
      </Card.Header>
      <Separator
        orientation="horizontal"
        className="mx-[24px] my-[14px] w-auto"
      />
      <Card.Footer className="p-0 px-[24px] flex justify-between">
        <div className="skeleton rounded-[4px] w-[120px]" />
        <div className="skeleton rounded-[4px] w-[80px]" />
      </Card.Footer>
    </Card.Root>
  )
}
