import React from 'react'
import { useMessageSendAtom } from './message-send.store'
import { Skeleton } from './partials/skeleton'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __, __e } from '~/shared/i18n'
import * as Tooltip from '@radix-ui/react-tooltip'
import { formatPhoneNumber } from '~/shared/utils/format-phone-number'

const MessageSendContainer = React.lazy(() =>
  import('./message-send.container').then((module) => ({
    default: module.MessageSendContainer,
  }))
)

type Nullish<T> = { [P in keyof T]?: T[P] | undefined | null }

export interface TProps {
  receiverCall: string
  receiverName: string
  unitCode: string
  atomKey?: string
}

export function MessageSendPack({
  receiverCall,
  receiverName,
  atomKey,
  unitCode,
}: Nullish<TProps>) {
  const orderCode = useOrderCode()
  const [open, setOpen] = useMessageSendAtom.state({
    orderCode: orderCode + (atomKey || ''),
  })

  const onOpenChange = React.useCallback((o: boolean) => {
    setOpen({
      ...open,
      value: o,
    })
  }, [])

  if (!receiverCall) {
    return null
  }
  if (!receiverName) {
    return (
      <div className="text-clay-semantic-textPrimary typo-clay-label-medium">
        {receiverCall}
      </div>
    )
  }
  if (!unitCode) {
    return null
  }
  return (
    <>
      <Tooltip.Provider delayDuration={100}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <button
              onClick={() => onOpenChange(!open.value)}
              className="text-clay-semantic-textPrimary hover:text-clay-semantic-textPrimaryHover typo-clay-label-medium w-max"
            >
              {formatPhoneNumber(receiverCall)}
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="rounded-[8px] bg-clay-semantic-surface typo-clay-label-small text-clay-semantic-text px-[8px] py-[6px] _drop-shadow animate-fade-right duration-300 animate-ease-out"
              sideOffset={8}
              side="right"
            >
              {__('메시지 보내기')}
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay />
          <AlertDialog.Content className="rounded-[16px] p-0 max-w-[380px]">
            {open.value && (
              <React.Suspense fallback={<Skeleton />}>
                <MessageSendContainer
                  {...{
                    receiverCall,
                    receiverName,
                    onOpenChange,
                    unitCode,
                  }}
                />
              </React.Suspense>
            )}
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </>
  )
}
