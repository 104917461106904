import { match, P } from 'ts-pattern'
import { Tag, TagProps } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  상수_결제상태,
  상수_결제상태대표,
  T상수_결제상태,
  T상수_결제상태대표,
} from '~/entities/@x'
import { __g } from '~/shared/i18n'
import { StatusTagBaseProps } from './status-tag.type'

/**
 * 결제상태 태그
 * @param toggleDimmed - plain variant일 때 배경색을 변경할지 여부
 */
export function PaymentStatusTag({
  statusCd,
  size,
  toggleDimmed,
}: StatusTagBaseProps<T상수_결제상태 | T상수_결제상태대표>) {
  if (!statusCd) {
    return null
  }

  const variant = match(statusCd)
    .returnType<TagProps['variant']>()
    .with(
      P.union(
        상수_결제상태.결제대기,
        상수_결제상태대표.부분결제,
        상수_결제상태대표.부분환불
      ),
      () => 'warning'
    )
    .with(상수_결제상태.결제기한초과, () => 'critical')
    .otherwise(() => 'plain')

  const shouldToggleColor = !!toggleDimmed && variant === 'plain'

  return (
    <Tag
      variant={shouldToggleColor ? 'other' : variant}
      text={__g(statusCd)}
      size={size}
      sx={
        shouldToggleColor
          ? {
              '--clay-tag-background-color': vars.semantic.color.surface,
              '--clay-tag-color': vars.semantic.color.textSub,
            }
          : undefined
      }
    />
  )
}
