import React, { useDeferredValue } from 'react'
import { Button, Modal } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { SearchBar } from './search-bar'
import { ModalFooter } from './modal-footer'
import { NonOrderItemView } from './non-order-item-view'
import { useCreatedModalAtom } from './created-orders-modal.store'

const CreatedOrdersModalContent = React.lazy(() =>
  import('./created-orders-modal-content').then((mod) => ({
    default: mod.CreatedOrdersModalContent,
  }))
)

export function CreatedOrdersModal() {
  const [isOverflowing, setIsOverflowing] = React.useState(false)
  const [keyword, setKeyword] = React.useState<string>('')
  const deferredKeyword = useDeferredValue(keyword)
  const [open, setOpen] = useCreatedModalAtom()
  const [searchDisabled, setSearchDisabled] = React.useState(false)

  const handleClose = React.useCallback(() => {
    setOpen(false)
    setKeyword('')
  }, [])

  return (
    <>
      <Button
        text={__('관리자 생성 주문')}
        variant="primary"
        tonal
        onClick={() => setOpen(true)}
      />

      <Modal isOpen={open} setClose={handleClose} width="large">
        <Modal.Header
          text={__('관리자 생성 주문')}
          subtle={__('생성된 주문은 결제가 완료돼야 주문 페이지에 노출돼요.')}
        />

        {/* 검색바 */}
        <SearchBar
          setKeyword={setKeyword}
          initialKeyword={keyword}
          disabled={searchDisabled}
        />

        {/* 주문 목록 */}
        <React.Suspense fallback={<NonOrderItemView type="loading" />}>
          <CreatedOrdersModalContent
            {...{
              keyword: deferredKeyword,
              setIsOverflowing,
              setSearchDisabled,
            }}
          />
        </React.Suspense>

        <ModalFooter isOverflowing={isOverflowing} setClose={handleClose} />
      </Modal>
    </>
  )
}
