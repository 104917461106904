/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

export const patchResReturnCompleteDto = z.object({
  updateResult: z.boolean().describe('주문 섹션 상태변경 결과'),
})

export const targetItemInformationDto = z.object({
  orderSectionItemCode: z.string().describe('변경하려는 품목의 코드'),
  qty: z.number().describe('변경하려는 품목의 수량'),
})
export const patchPayloadReturnCompleteDto = z.object({
  orderSectionCode: z.string().describe('요청 섹션 코드'),
  targetItemInformation: z
    .array(targetItemInformationDto)
    .describe('변경하려는 품목의 코드와 수량'),
})
