import React, { ComponentPropsWithoutRef } from 'react'
import {
  ButtonGroup,
  Clay,
  ContentsBanner,
  Flex,
  Modal,
  TextButton,
  Typography,
  Button,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { match } from 'ts-pattern'
import { Check } from '@imwebme/clay-icons'
import { STEPS } from '../order-create.constants'
import { __ } from '~/shared/i18n'
import { useActiveStepIdxAtom } from '../order-create.store'

interface Props {
  status: 'complete' | 'incomplete' | 'current'
  stepIdx: number
  children?: React.ReactNode
}

/**
 * 카드와 다음 버튼을 제외한 스텝 레이아웃
 * @example 스텝: 주문정보입력, 상품선택, 배송설정, 할인설정
 */
export const StepLayout = React.forwardRef<HTMLDivElement, Props>(
  ({ status, stepIdx, children }: Props, ref) => (
    <Flex columnGap={vars.spacing[3]} ref={ref}>
      <Flex.Column
        rowGap={vars.spacing[1]}
        alignItems="center"
        paddingBottom={vars.spacing[1]}
      >
        {match(status)
          .with('complete', () => (
            <Flex.Center
              width={vars.spacing[7]}
              height={vars.spacing[7]}
              borderRadius="100%"
              background={vars.semantic.color.surfaceSuccessSecondary}
            >
              <Check colorToken="icon-success" />
            </Flex.Center>
          ))
          .with('incomplete', () => (
            <Flex.Center
              borderRadius="100%"
              border={`2px solid ${vars.semantic.color.border}`}
              width={vars.spacing[7]}
              height={vars.spacing[7]}
            >
              <Typography variant="body-medium-bold" colorToken="textSub">
                {stepIdx + 1}
              </Typography>
            </Flex.Center>
          ))
          .with('current', () => (
            <Flex.Center
              borderRadius="100%"
              background="#24282F"
              width={vars.spacing[7]}
              height={vars.spacing[7]}
            >
              <Typography variant="body-medium-bold" colorToken="textOn">
                {stepIdx + 1}
              </Typography>
            </Flex.Center>
          ))
          .exhaustive()}
        <Clay flex={1} width="1px" background={vars.semantic.color.border} />
      </Flex.Column>

      <Flex.Column
        rowGap={vars.spacing[5]}
        paddingBottom={stepIdx === STEPS.length - 1 ? 0 : vars.spacing[8]}
        flex={1}
      >
        <Flex justifyContent="space-between" alignItems="center">
          {/* 제목, 수정 버튼 */}
          <Typography variant="heading-xlarge-bold">
            {STEPS[stepIdx] ?? ''}
          </Typography>
          {status === 'complete' && <EditAction stepIdx={stepIdx} />}
        </Flex>

        {/* 카드, 다음 버튼 */}
        {children}
      </Flex.Column>
    </Flex>
  )
)

// 수정 버튼 동작
function EditAction({ stepIdx }: { stepIdx: number }) {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [_, setActiveStepIdx] = useActiveStepIdxAtom()

  return (
    <>
      <TextButton
        text={__('수정')}
        variant="secondary"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={modalOpen}
        setClose={() => setModalOpen(false)}
        width="small"
      >
        <Modal.Header text={__('아래 입력한 내용이 초기화 돼요')} />
        <Modal.Body>
          <ContentsBanner
            text={__(
              '{{해당멀티스텝}} 수정 시 아래 작성한 정보들을 다시 입력해야 해요.',
              {
                해당멀티스텝: match(stepIdx)
                  .with(0, () => __('주문 정보'))
                  .with(1, () => __('상품 정보'))
                  .with(2, () => __('배송 정보'))
                  .otherwise(() => ''),
              }
            )}
            variant="critical"
            type="secondary"
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
            <Button
              text={__('취소')}
              variant="outlined"
              onClick={() => setModalOpen(false)}
            />
            <Button
              text={__('변경')}
              variant="primary"
              onClick={() => setActiveStepIdx(stepIdx)}
            />
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  )
}
