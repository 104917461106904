import React, { Component, HTMLAttributes, RefObject } from 'react'

export interface OutsideClickHandlerProps
  extends HTMLAttributes<HTMLDivElement> {
  onOutsideClick: (e: MouseEvent) => void
}

export class OutsideClickComponenet extends Component<
  React.PropsWithChildren<OutsideClickHandlerProps>
> {
  private wrapperRef: RefObject<HTMLDivElement>

  constructor(props: OutsideClickHandlerProps) {
    super(props)
    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event: MouseEvent) {
    // 클릭된 요소가 컴포넌트 외부인지 확인
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target as Node)
    ) {
      this.props.onOutsideClick(event)
    }
  }

  render() {
    const { onOutsideClick, ...restProps } = this.props
    return (
      <div ref={this.wrapperRef} {...restProps}>
        {this.props.children}
      </div>
    )
  }
}
