import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_외부채널_페이먼트_정보 } from './external-order-payment-info.model'
import { 상수_결제상태 } from '~/entities/@x'
import { 외부채널주문섹션스펙some } from '../external-order/external-order.specification'
import { 스펙_외부채널_주문섹션 } from '../external-order-section'

export const 최초결제: ISpecification<모델_외부채널_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.paymentList,
    O.fromNullable,
    O.fold(
      () => false,
      flow((e) => e.length === 1)
    )
  )
)

export const 첫결제입금전취소: ISpecification<모델_외부채널_페이먼트_정보> =
  new Spec(
    flow(
      (candidate) => candidate.data.paymentInfo.paymentList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter((e) => e.statusCd === 상수_결제상태.입금전취소),
          (e) => e.length === 1
        )
      )
    )
  )

export const 첫결제결제기한초과: ISpecification<모델_외부채널_페이먼트_정보> =
  new Spec(
    flow(
      (candidate) => candidate.data.paymentInfo.paymentList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter((e) => e.statusCd === 상수_결제상태.결제기한초과),
          (e) => e.length === 1
        )
      )
    )
  )

export const 착불택배: ISpecification<모델_외부채널_페이먼트_정보> =
  외부채널주문섹션스펙some(스펙_외부채널_주문섹션.착불택배)
