import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Skeleton, DropdownTriggerBtn } from '../partials'
import { ErrorCard } from '~/shared/components/error'
import { match } from 'ts-pattern'
import { OrderQuantityPreviewModals } from './일반주문모달'
import { ExternalOrderQuantityPreviewModals } from './외부채널주문모달'

const OrderQuantityPreviewContainer = React.lazy(() =>
  import('./order-quantity-preview.container').then((module) => ({
    default: module.OrderQuantityPreviewContainer,
  }))
)

const ExternalOrderQuantityPreviewContainer = React.lazy(() =>
  import('./external-order-quantity-preview.container').then((module) => ({
    default: module.ExternalOrderQuantityPreviewContainer,
  }))
)

interface TProps {
  children: React.ReactNode
  orderCode: string
  isOrderItemsColumn?: boolean
  isExternalOrder: boolean
}

export function OrderQuantityPreviewPack({
  children,
  orderCode,
  isOrderItemsColumn,
  isExternalOrder,
}: TProps) {
  const [open, setOpen] = React.useState(false)

  if (!children) {
    return null
  }
  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownTriggerBtn
          className="justify-end"
          {...{ setOpen, isOrderItemsColumn }}
        >
          {children}
        </DropdownTriggerBtn>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            sideOffset={8}
            align="start"
            onCloseAutoFocus={(evt) => evt.preventDefault()}
            className="w-[320px] bg-clay-semantic-surface py-clay-4 rounded-clay-medium shadow-clay-layer h-fit max-h-[min(var(--radix-popper-available-height),400px)] overflow-y-auto"
            hideWhenDetached
            collisionPadding={12}
          >
            <ErrorBoundary fallback={ErrorCard}>
              <React.Suspense fallback={<Skeleton />}>
                {match(isExternalOrder)
                  .with(true, () => (
                    <ExternalOrderQuantityPreviewContainer
                      {...{
                        orderCode,
                      }}
                    />
                  ))
                  .with(false, () => (
                    <OrderQuantityPreviewContainer
                      {...{
                        orderCode,
                      }}
                    />
                  ))
                  .exhaustive()}
              </React.Suspense>
            </ErrorBoundary>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {/* 일반주문 모달 모음 */}
      <OrderQuantityPreviewModals {...{ orderCode }} />
      {/* 외부채널주문 모달 모음 */}
      <ExternalOrderQuantityPreviewModals {...{ orderCode }} />
    </>
  )
}
