import { match } from 'ts-pattern'
import { Tag, TagProps } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { 상수_주문상태, T주문상태 } from '~/entities/@x'
import { __g } from '~/shared/i18n'
import { StatusTagBaseProps } from './status-tag.type'

/**
 * 주문상태 태그
 * @param toggleDimmed - plain variant일 때 배경색을 변경할지 여부
 */
export function OrderStatusTag({
  statusCd,
  size,
  toggleDimmed,
}: StatusTagBaseProps<T주문상태>) {
  if (!statusCd) {
    return null
  }

  const variant = match(statusCd)
    .returnType<TagProps['variant']>()
    .with(상수_주문상태.거래개시, () => 'warning')
    .otherwise(() => 'plain')

  const shouldToggleColor = !!toggleDimmed && variant === 'plain'

  return (
    <Tag
      variant={shouldToggleColor ? 'other' : variant}
      text={__g(statusCd)}
      size={size}
      sx={
        shouldToggleColor
          ? {
              '--clay-tag-background-color': vars.semantic.color.surface,
              '--clay-tag-color': vars.semantic.color.textSub,
            }
          : undefined
      }
    />
  )
}
