import {
  CUSTOM_FILTER_IDX,
  OrderSearchFilterFormValues,
} from '~/entities/order-search-filter'
import { TFilter } from '~/entities/order-search-tab'
import { __ } from '~/shared/i18n'

export const defaultFilterFormValues: OrderSearchFilterFormValues = {
  name: __('기본 필터'),
  searchDateType: null,
  dateRange: null,
  startDate: null,
  endDate: null,
  pgMethod: null,
  discountType: null,
  minPaymentPrice: null,
  maxPaymentPrice: null,
  orderItemCount: null,
  isMember: null,
  isFirstOrderUser: null,
  deliveryDelayDay: null,
} as const

type FilterItem = TFilter | { name: string; idx: number }
export const defaultFilter: FilterItem = {
  name: __('기본 필터'),
  idx: CUSTOM_FILTER_IDX.DEFAULT,
} as const

export const DEFAULT_FILTER_FORM_KEY = 'oms-order-search-filter-form' as const
