/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post bank-transfer-confirm-revert
 */
export const postResBankTransferConfirmRevertDto = z.any()
export const postPayloadBankTransferConfirmRevertDto = z.object({
  paymentCode: z.string(),
})
