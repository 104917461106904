import { Dialog } from '~/shared/components/ui/dialog'
import { __ } from '~/shared/i18n'
import {
  DialogBulkStatusBanner,
  DialogBulkStatusButtons,
  DialogBulkStatusHeader,
} from '../dialog-bulk-status'

export type TDialogDeliveryProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onSubmit: () => void
  fulfilled?: number
  rejected?: number
}
export const DialogDelivery = ({
  open,
  onOpenChange,
  onSubmit,
  fulfilled = 0,
  rejected = 0,
}: TDialogDeliveryProps) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Portal>
      <Dialog.Overlay className="backdrop-blur-none bg-clay-semantic-backdrop bg-opacity-75" />
      <Dialog.Content className="sm:max-w-[380px] bg-clay-semantic-surface px-0 dark:bg-clay-semantic-surface">
        <Dialog.Header className="px-[32px]">
          <Dialog.Title className="semantic-h4-bold dark:text-clay-semantic-text">
            <DialogBulkStatusHeader
              nextStatus={__('배송중')}
              prevStatus={__('배송대기')}
              fulfilled={fulfilled}
              rejected={rejected}
            />
          </Dialog.Title>
        </Dialog.Header>
        <div className="px-[32px]">
          <DialogBulkStatusBanner
            nextStatus={__('배송중')}
            prevStatus={__('배송대기')}
            fulfilled={fulfilled}
            rejected={rejected}
          />
        </div>
        <Dialog.Footer className="px-[32px]">
          <DialogBulkStatusButtons
            nextStatus={__('배송중')}
            prevStatus={__('배송대기')}
            fulfilled={fulfilled}
            rejected={rejected}
            onSubmit={onSubmit}
          />
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
)
