import { pipe } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import {
  OrderSearchFilterFormValues,
  FilterReqDto,
} from '~/entities/order-search-filter'

export class OrderSearchFilterFormClass {
  constructor(private formValues: OrderSearchFilterFormValues) {}

  get convertToApiDto(): FilterReqDto {
    const { name, ...conditions } = this.formValues

    const columnCondition = pipe(
      conditions,
      R.filter((value) => value !== undefined && value !== null)
    )

    return { name, columnCondition }
  }
}
