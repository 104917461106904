import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { type SetStateAction, atom, useAtom, useAtomValue } from 'jotai'
import React from 'react'
import type { TExportTemplateTab } from './export-template-tab.type'
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils'

const orderExportFormTabOriginalInitialState: TExportTemplateTab = {
  forms: [],
  onFormAdd: () => log.debug('orderExportFormTabState'),
}
export const orderExportFormTabOriginalState =
  atomWithReset<TExportTemplateTab>(orderExportFormTabOriginalInitialState)
orderExportFormTabOriginalState.debugLabel = 'orderExportFormTabOriginalState'

export const orderExportFormTabState = atom(
  (get) => {
    const tabItem = get(orderExportFormTabOriginalState)
    return tabItem
  },
  (_, set, update: SetStateAction<TExportTemplateTab> | typeof RESET) => {
    if (update === RESET) {
      set(
        orderExportFormTabOriginalState,
        orderExportFormTabOriginalInitialState
      )
      return
    }
    set(orderExportFormTabOriginalState, update)
  }
)

const activeExportTemplateTabState = atom((get) => {
  const tab = get(orderExportFormTabOriginalState)
  const active = pipe(
    tab.forms,
    O.fromNullable,
    O.fold(
      () => null,
      flow(
        A.findFirst((form) => !!form.active),
        O.fold(
          () => null,
          (form) => form
        )
      )
    )
  )
  return active
})

export const useExportTemplateTabAtom = {
  tab: () => useAtom(React.useMemo(() => orderExportFormTabState, [])),
  reset: () => useResetAtom(orderExportFormTabState),
  active: () => useAtomValue(activeExportTemplateTabState),
}
