import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { DevTool } from '~/app/provider/react-hook-form'
import { RefreshCW05 } from '@imwebme/clay-icons'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import {
  OrderSearchFilterFormValues,
  OrderSearchFilterOrderInfoFields,
  orderSearchProdInfoDto,
  orderSearchFilterFormResolver,
  patchOrderSearchFilter,
  deleteOrderSearchFilter,
  CUSTOM_FILTER_IDX,
} from '~/entities/order-search-filter'
import {
  clearFormValues,
  validateFilterColumnCondition,
  setStoredDefaultFilterForm,
} from '~/container/order-search-filter/order-search-filter.fn'
import { Button } from '@imwebme/clay-components'
import { TextButton } from '@imwebme/clay-components'
import { toast } from 'react-toastify'
import { __, __e } from '~/shared/i18n'
import { OrderSearchFilterFormClass } from './order-search-filter-form-class'
import * as F from '../order-search-filter-form-fields'
import { orderSearchTabQueryOptions } from '~/entities/order-search-tab'
import {
  useSetOrderSearchFilterQueryString,
  useOrderSearchFilterFormHasChanged,
  useCurOrderSearchFilter,
} from '~/container/order-search-filter/order-search-filter.store'
import { omit, debounce } from 'lodash'
import { getOrderInfoFieldLabel } from '../order-search-filter-form-fields/order-info/order-info.helper'
import { FilterDeleteModal } from '~/container/order-search-filter/modals'
import { TYN, T상수_결제수단 } from '~/entities/@x'

export type SitePgList = {
  label: string
  value: T상수_결제수단
  disabled: boolean
}[]
export type BankNameList = { label: string; value: string }[]

interface Props {
  tabCode: string
  initialValues: OrderSearchFilterFormValues
  filterType: 'default' | 'created'
  sitePgList: SitePgList
  bankNameList: BankNameList
  handleChangeFilter: (idx: number) => void
  tIsMember?: TYN
  handleCloseFilter: () => void
  goToFirstPage: () => void
}

export interface OrderInfoFieldItem {
  label: string
  value: OrderSearchFilterOrderInfoFields
}

const getInitialOrderInfoFieldList = (
  formValues: OrderSearchFilterFormValues
) => {
  const orderInfoFieldKeyList = Object.keys(
    orderSearchProdInfoDto.shape
  ) as OrderSearchFilterOrderInfoFields[]

  const isOrderInfoFieldKey = (
    fieldKey: string
  ): fieldKey is OrderSearchFilterOrderInfoFields =>
    orderInfoFieldKeyList.includes(fieldKey as OrderSearchFilterOrderInfoFields)

  return pipe(
    formValues,
    Object.keys,
    A.filter(isOrderInfoFieldKey),
    A.reduce(
      [],
      (
        acc: OrderInfoFieldItem[],
        fieldKey: OrderSearchFilterOrderInfoFields
      ) => [
        ...acc,
        {
          label: getOrderInfoFieldLabel(fieldKey),
          value: fieldKey,
        },
      ]
    )
  )
}

export const OrderSearchFilterForm = ({
  tabCode,
  initialValues,
  filterType,
  sitePgList,
  bankNameList,
  handleChangeFilter,
  tIsMember,
  handleCloseFilter,
  goToFirstPage,
}: Props) => {
  const queryClient = useQueryClient()
  const [orderInfoFieldList, setOrderInfoFieldList] = React.useState<
    OrderInfoFieldItem[]
  >([])
  const [, startTransition] = React.useTransition()
  const [formHasChanged, setFormHasChanged] =
    useOrderSearchFilterFormHasChanged.state()
  const setOrderSearchFilterQueryString = useSetOrderSearchFilterQueryString()
  const [curFilter] = useCurOrderSearchFilter()

  const {
    watch,
    handleSubmit,
    control,
    reset,
    register,
    unregister,
    formState: { isSubmitting },
  } = useForm({
    resolver: orderSearchFilterFormResolver,
    values: initialValues,
  })

  const formValues = watch()

  const isValidColumnCondition = React.useMemo(
    () => validateFilterColumnCondition(formValues),
    [formValues]
  )

  const { mutateAsync: mutateUpdateFilter, isPending: isPendingUpdateFilter } =
    useMutation({
      mutationFn: patchOrderSearchFilter,
      onError: (error) => {
        if (!error.response) {
          log.error(error)
          return
        }
        toast.error(
          __e(error.response.data.code || error.response.data.message)
        )
      },
    })

  const { mutateAsync: mutateDeleteFilter, isPending: isPendingDeleteFilter } =
    useMutation({
      mutationFn: deleteOrderSearchFilter,
      onError: (error) => {
        if (!error.response) {
          log.error(error)
          return
        }
        toast.error(
          __e(error.response.data.code || error.response.data.message)
        )
      },
    })

  const handleDeletefilter = React.useCallback(async () => {
    handleChangeFilter(CUSTOM_FILTER_IDX.DEFAULT)

    await mutateDeleteFilter({ tabCode, filterIdx: curFilter.idx })

    await queryClient.invalidateQueries({
      queryKey: orderSearchTabQueryOptions().queryKey,
    })

    toast.success(__('필터 그룹을 삭제했어요'))
  }, [tabCode, curFilter.idx])

  const onSubmit = React.useCallback(
    async (data: OrderSearchFilterFormValues) => {
      const filterDto = new OrderSearchFilterFormClass(data).convertToApiDto

      await mutateUpdateFilter({
        tabCode,
        filterIdx: curFilter.idx,
        params: filterDto,
      })

      await queryClient.fetchQuery({
        queryKey: orderSearchTabQueryOptions().queryKey,
      })

      toast.success(__('필터 설정을 저장했어요'))

      setFormHasChanged(false)
    },
    [tabCode, curFilter.idx]
  )

  const handleRemoveOrderInfoField = React.useCallback(
    (fieldKey: OrderSearchFilterOrderInfoFields) => {
      setOrderInfoFieldList((prev) => prev.filter((f) => f.value !== fieldKey))
      unregister(fieldKey)
    },
    []
  )

  const addOrderInfoField = React.useCallback(
    (fieldItem: OrderInfoFieldItem) => {
      setOrderInfoFieldList((prev) => [fieldItem, ...prev])
      register(fieldItem.value)
    },
    []
  )

  const handleClickInitForm = React.useCallback(() => {
    reset(initialValues)
    setOrderInfoFieldList(getInitialOrderInfoFieldList(initialValues))
    setFormHasChanged(false)
  }, [initialValues])

  const orderInfoFieldComponentMap: {
    [key in OrderSearchFilterOrderInfoFields]: ({
      fieldKey,
    }: {
      fieldKey: OrderSearchFilterOrderInfoFields
    }) => EmotionJSX.Element
  } = React.useMemo(() => {
    const commonProps = { control, handleRemoveOrderInfoField }

    return {
      orderNo: () => <F.OrderNoField {...commonProps} />,
      invoiceNo: (params) => (
        <F.OrderInfoTextInput {...commonProps} {...params} />
      ),
      ordererName: (params) => (
        <F.OrderInfoTextInput {...commonProps} {...params} />
      ),
      ordererEmail: (params) => (
        <F.OrderInfoTextInput {...commonProps} {...params} />
      ),
      ordererCall: (params) => (
        <F.OrderInfoTextInput
          {...commonProps}
          {...params}
          placeholder={__("'-'를 제외하고 입력해 주세요")}
        />
      ),
      depositorName: (params) => (
        <F.OrderInfoTextInput {...commonProps} {...params} />
      ),
      receiverName: (params) => (
        <F.OrderInfoTextInput {...commonProps} {...params} />
      ),
      receiverCall: (params) => (
        <F.OrderInfoTextInput
          {...commonProps}
          {...params}
          placeholder={__("'-'를 제외하고 입력해 주세요")}
        />
      ),
      receiverAddress: () => <F.ReceiverAddressField {...commonProps} />,
      depositBankName: () => (
        <F.DepositBankNameField {...commonProps} {...{ bankNameList }} />
      ),
      cashReceiptStatus: () => <F.CashReceiptStatusField {...commonProps} />,
      orderCategory: () => <F.OrderCategoryField {...commonProps} />,
      deliveryIsInput: () => <F.DeliveryIsInputField {...commonProps} />,
      shopCouponCode: () => <F.OrderCouponField {...commonProps} />,
      invoiceIsInput: () => <F.InvoiceIsInputField {...commonProps} />,
      isDeliveryHold: () => <F.IsDeliveryHoldField {...commonProps} />,
      isRequestPayment: () => <F.IsRequestPaymentField {...commonProps} />,
      memoIsInput: () => <F.MemoIsInputField {...commonProps} />,
    }
  }, [handleRemoveOrderInfoField, control, bankNameList])

  React.useEffect(() => {
    reset(initialValues)

    setOrderInfoFieldList(getInitialOrderInfoFieldList(initialValues))

    pipe(
      clearFormValues(initialValues),
      (v) => omit(v, ['name']),
      setOrderSearchFilterQueryString
    )
  }, [initialValues])

  // 폼값 변경을 구독하여 필터 쿼리스트링, 기본보기 필터 폼값 업데이트
  React.useEffect(() => {
    const subscription = watch(
      debounce((value) => {
        startTransition(() => {
          pipe(
            clearFormValues(value as OrderSearchFilterFormValues),
            (v) => omit(v, ['name']),
            setOrderSearchFilterQueryString
          )

          // 필터가 바뀌었으니 1페이지로 이동
          goToFirstPage()

          if (filterType === 'default') {
            setStoredDefaultFilterForm(value as OrderSearchFilterFormValues)
          }
        })
      }, 800)
    )
    return () => subscription.unsubscribe()
  }, [watch, filterType])

  // 폼값 변경을 구독하여 폼변경 여부 저장
  React.useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change' && filterType === 'created') {
        setFormHasChanged(
          !isEqual(
            pipe(value as OrderSearchFilterFormValues, clearFormValues),
            pipe(initialValues, clearFormValues)
          )
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, initialValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-[12px] pb-[16px] rounded-[12px] text-clay-semantic-border border space-y-[12px]">
        <div className="pl-[12px] pr-[18px] flex justify-between">
          <F.NameField {...{ control, disabled: filterType === 'default' }} />
          {filterType === 'created' && (
            <FilterDeleteModal
              isDisabled={isPendingDeleteFilter}
              onConfirm={handleDeletefilter}
            />
          )}
        </div>

        <div className="px-[24px] flex gap-[8px] flex-wrap items-center">
          <F.DateField {...{ control }} />
          <F.PaymentMethodField {...{ control, sitePgList }} />
          <F.DiscountTypeField {...{ control }} />
          <F.PaymentPriceField {...{ control }} />
          <F.OrderItemCountField {...{ control }} />
          <F.MemberTypeField {...{ control, tIsMember }} />
          <F.OrderInfoFieldCreator
            {...{ orderInfoFieldList, addOrderInfoField }}
          />
          <F.DeliveryTypeCdField {...{ control }} />
          <F.ProductField {...{ control }} />
          {orderInfoFieldList.map((orderInfoFieldItem) => (
            <div key={orderInfoFieldItem.value}>
              {orderInfoFieldComponentMap[orderInfoFieldItem.value]({
                fieldKey: orderInfoFieldItem.value,
              })}
            </div>
          ))}
          {formHasChanged && (
            <TextButton
              onClick={handleClickInitForm}
              variant="secondary"
              text={__('초기화')}
              trailingIcon={<RefreshCW05 />}
              native={{ type: 'button' }}
            />
          )}
        </div>

        <div className="px-[16px] ml-auto w-max flex gap-x-[6px]">
          <Button
            native={{ type: 'button' }}
            variant="secondary"
            size="small"
            text={__('닫기')}
            onClick={handleCloseFilter}
          />
          {filterType === 'created' && (
            <Button
              native={{ type: 'button' }}
              variant="primary"
              size="small"
              isDisabled={!formHasChanged || !isValidColumnCondition}
              isLoading={isPendingUpdateFilter || isSubmitting}
              onClick={handleSubmit(onSubmit)}
              text={__('저장')}
            />
          )}
        </div>
      </div>
      <DevTool control={control} />
    </form>
  )
}
