import { ApiClient } from '~/shared/api'
import type { C3Product } from './product-search-type'

const axios = ApiClient()

export const getProduct = async ({
  query,
  cursor,
  unitCode,
  memberCode,
  orderType,
}: {
  query?: string
  cursor?: string
  unitCode?: string
  memberCode?: string
  orderType?: string
}): Promise<{
  list: C3Product[]
  cursorForNext: string
  cursorForPrev: string
}> => {
  const r = await axios
    .get('/product', {
      params: {
        direction: 'next',
        count: 50,
        searchingKeyword: query === '' ? undefined : query,
        cursor,
        unitCode,
        memberCode,
        orderType,
      },
    })
    .then((res) => res.data)
  if (r.statusCode === 200) {
    return r.data
  }
  throw new Error(r.message)
}
