import { TextButton } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { useModals } from '~/router'
import { useSuspenseQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { 스펙_주문서 } from '~/entities/order-detail/model/order'
import { 모델_주문서 } from '~/container/order/class-order'
import { useOrderCode } from '~/shared/hooks/use-order-code'

export function OrderFormDataEditButton() {
  const orderCode = useOrderCode()
  const modals = useModals()
  const { data } = useSuspenseQuery(orderDetailOptions({ orderCode }))
  const $order = new 모델_주문서(data)
  const disabled = 스펙_주문서.거래종료여부
    .or(스펙_주문서.입금기한_초과)
    .isSatisfiedBy($order)

  return (
    <TextButton
      text={__('수정')}
      variant="primary"
      onClick={() => {
        modals.open('/:orderCode/form-data/edit', {
          params: {
            orderCode,
          },
        })
      }}
      native={{ disabled }}
    />
  )
}
