import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { RefreshCCW01, Trash01 } from '@imwebme/clay-icons'
import { Button } from '@imwebme/clay-components'
import { useFormContextExternalOrderCancelSelect } from '../external-order-cancel-select'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { __ } from '~/shared/i18n'

export function ExternalCancelItemMutableReset({
  sectionCode,
  index,
}: {
  sectionCode: string
  index: number
}) {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(
        // sectionCode를 받았기 때문에 sectionList는 항상있다.
        e.data.orderSectionList!,
        A.findFirst((e2) => e2.orderSectionCode === sectionCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트가 없습니다.')
        }),
        (e2) => e2.orderSectionItemList,
        O.fromNullable,
        O.getOrElseW(() => {
          throw new Error('섹션 리스트에 아이템리스트가 없습니다.')
        })
      )
      return result
    },
  })
  const { setValue, watch } = useFormContextExternalOrderCancelSelect()

  const selectedItems = pipe(
    watch(`targetSectionInformation.${index}.targetItemInformation`),
    A.filter((e) => e._check === true)
  )

  return (
    <div className="flex gap-x-[8px]">
      {selectedItems.length > 0 && (
        <Button
          native={{ type: 'button' }}
          variant="secondary"
          text={__('초기화')}
          trailingIcon={<RefreshCCW01 color="inherit" />}
          onClick={() => {
            pipe(
              data,
              A.mapWithIndex((_index) => {
                setValue(
                  `targetSectionInformation.${index}.targetItemInformation.${_index}._check`,
                  false
                )
              })
            )
          }}
        />
      )}
      <Button
        native={{ type: 'button' }}
        variant="secondary"
        text={__('전체취소')}
        trailingIcon={<Trash01 color="inherit" />}
        isDisabled={data.length === selectedItems.length}
        onClick={() => {
          pipe(
            data,
            A.mapWithIndex((_index) => {
              setValue(
                `targetSectionInformation.${index}.targetItemInformation.${_index}._check`,
                true
              )
            })
          )
        }}
      />
    </div>
  )
}
