import React from 'react'
import { OrderSectionReturnCalc } from './order-section-return-calc'
import { Loading } from '~/shared/components/loading'

export function OrderSectionReturnCalcPack() {
  return (
    <React.Suspense fallback={<Loading />}>
      <OrderSectionReturnCalc />
    </React.Suspense>
  )
}
