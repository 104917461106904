import { fDate } from '~/shared/utils'
import { __ } from '~/shared/i18n'

export function getOrderNo() {
  return fDate(new Date(), 'yyyyMMdd') + '58121543'
}

export function formatPrice(price: number) {
  return __('{{price, 3comma}} {{currency, currency}}', {
    price,
  })
}
