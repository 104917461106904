import { Children, PropsWithChildren } from 'react'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { cn } from '~/shared/utils'

interface OrderSectionProps extends PropsWithChildren {
  name: string
  className?: string
}
export function OrderSection({
  children,
  name,
  className,
  ...props
}: OrderSectionProps) {
  const childrenArray = Children.toArray(children) as React.ReactElement[]

  // optional 값이 false라면 return 타입에 undefined는 올수없다.
  const findChild = (childName: string, optional = false) => {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )

    // 여기서 optional이 false이면 result는 undefined일 수 없다.
    // 리턴되는 타입은 완전히 바꿀수없다
    return pipe(
      result,
      O.fromNullable,
      O.fold(
        // undefined, null일때
        () => {
          // result가 undefined이지만 optional이 true일때는 undefined를 반환해도 무방하다
          if (optional) {
            return undefined
          }
          throw new Error(`${name} - ${childName} is undefined`)
        },
        (r) => r
      )
    )
  }

  // undefined가 아니다라고 타입을 확정하고싶다
  const TitleSlot = findChild('title', true)
  const MenuBtnSlot = findChild('menu button', true)
  const BodySlot = findChild('body')
  const FooterSlot = findChild('footer', true)

  return (
    <div
      data-find={name}
      className={cn(
        'rounded-[12px] py-[24px] bg-clay-semantic-surface group/order-section',
        className
      )}
      {...props}
    >
      <div className="mx-[24px] relative">
        <div {...TitleSlot?.props} />
        {MenuBtnSlot && (
          <div
            className="absolute right-0 top-1/2 -translate-y-1/2"
            {...MenuBtnSlot?.props}
          />
        )}
      </div>
      <div
        className="mt-[24px] [--mx:24px] space-y-[24px] [&>*]:mx-[var(--mx)]"
        {...BodySlot?.props}
      />
      {FooterSlot && (
        <div className="mt-[24px] [--mx:24px]" {...FooterSlot?.props} />
      )}
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'title' | 'menu button' | 'body' | 'footer'
}
const Slot: React.FC<SlotProps> = () => null
/**
 * Slot은 OrderSection의 자식으로 사용되며, OrderSection의 특정 영역에 컨텐츠를 표시해줍니다.
 */
OrderSection.Slot = Slot
