import { z } from 'zod'

export const postResReturnRejectRequestDto = z.object({
  successedProducts: z.array(z.string()),
})
export const postPayloadReturnRejectRequestDto = z.object({
  orderSectionCode: z.string(),
  orderSectionItemCodes: z.array(z.string()),
  reason: z.string(),
})
