import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as math from 'mathjs'
import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import { Spec, ISpecification } from 'spec-pattern-ts'
import { 모델_페이먼트_정보 } from '.'
import { YN, 상수_결제상태, 상수_결제타입 } from '~/entities/@x'
import { 일반주문섹션스펙에부합하는섹션존재유무 } from '../order/order.specification'
import { 스펙_주문섹션 } from '../order-section'
import * as E from 'fp-ts/Either'

export const 최초결제: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.paymentList,
    O.fromNullable,
    O.fold(
      () => false,
      flow((e) => e.length === 1)
    )
  )
)

export const 추가결제: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.paymentList,
    O.fromNullable,
    O.fold(
      () => false,
      flow((e) => e.length > 1)
    )
  )
)

export const 결제완료: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.type === 상수_결제타입.결제완료
  )
)

export const 미결제: ISpecification<모델_페이먼트_정보> = new Spec(
  flow((candidate) => candidate.data.paymentInfo.type === 상수_결제타입.미결제)
)

export const 환불예정: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.type === 상수_결제타입.환불예정
  )
)

export const 입금대기: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.type === 상수_결제타입.입금대기
  )
)

const 미결금액존재: ISpecification<모델_페이먼트_정보> = new Spec(
  flow((candidate) => {
    const pgPaymentPrice = math
      .chain(candidate.data.pgAmount)
      .subtract(candidate.data.pgCancelPrice)
      .done()

    // (결제대상금액 - 환불예정금액)
    const dataPaymentPrice = math
      .chain(candidate.data.paymentPrice)
      .subtract(candidate.data.refundPendingPrice)
      .done()
    return pgPaymentPrice < dataPaymentPrice
  })
)

export const 결제완료1개이상: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.paymentList,
    O.fromNullable,
    O.fold(
      () => false,
      flow(
        A.filter((e) => e.statusCd === 상수_결제상태.결제완료),
        (e) => e.length >= 1
      )
    )
  )
)

export const 결제완료또는환불1개이상또는결제내역없음: ISpecification<모델_페이먼트_정보> =
  new Spec(
    flow(
      (candidate) => candidate.data.paymentInfo.paymentList,
      (e) => {
        if (e === null || e === undefined) {
          return E.left(false)
        }
        /**
         * @description
         * 관리자에서 주문생성하면 paymentList는 []이다.
         * 이때만 true를 결제 요청을 할 수 있게한다.
         */
        if (e.length === 0) {
          return E.left(true)
        }
        return E.right(e)
      },
      E.fold(
        (e) => e,
        flow(
          A.filter(
            (e) =>
              e.statusCd === 상수_결제상태.결제완료 ||
              e.statusCd === 상수_결제상태.환불완료 ||
              e.statusCd === 상수_결제상태.부분환불완료
          ),
          (e) => e.length >= 1
        )
      )
    )
  )

export const 첫결제입금전취소: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.paymentList,
    O.fromNullable,
    O.fold(
      () => false,
      flow(
        A.filter((e) => e.statusCd === 상수_결제상태.입금전취소),
        (e) => e.length === 1
      )
    )
  )
)

export const 첫결제결제기한초과: ISpecification<모델_페이먼트_정보> = new Spec(
  flow(
    (candidate) => candidate.data.paymentInfo.paymentList,
    O.fromNullable,
    O.fold(
      () => false,
      flow(
        A.filter((e) => e.statusCd === 상수_결제상태.결제기한초과),
        (e) => e.length === 1
      )
    )
  )
)

export const 입금대기자동취소: ISpecification<모델_페이먼트_정보> = new Spec(
  flow((candidate) => candidate.data.paymentInfo.isShopUseAutoCancel)
)

/**
 * @description
 * isRequestPayment값이 Y일때 FO에서 결제요청 배너가 보여진다
 * 어드민에서 결제요청을하려면 이 값이 N이어야한다.
 * @기획서 https://5k.gg/btOLyR
 */
export const 결제요청: ISpecification<모델_페이먼트_정보> =
  결제완료또는환불1개이상또는결제내역없음.and(미결금액존재).and(미결제)

export const 결제요청노출: ISpecification<모델_페이먼트_정보> = new Spec(
  flow((candidate) => candidate.data.isRequestPayment === YN.N)
)

export const 결제요청취소: ISpecification<모델_페이먼트_정보> = new Spec(
  flow((candidate) => candidate.data.isRequestPayment === YN.Y)
)

export const 착불택배: ISpecification<모델_페이먼트_정보> =
  일반주문섹션스펙에부합하는섹션존재유무(스펙_주문섹션.착불택배)
