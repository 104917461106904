import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Combobox } from '@headlessui/react'
import { IconSearchSm, IconWarningTriangle } from '@imwebme/icon'
import { Controller, useFormContext } from 'react-hook-form'
import type { TReturnRequest } from '~/container/order/order-return-info/order-retrun-info.type'
import { __ } from '~/shared/i18n'
import { matches } from 'kled'
import { parcelCompanyQueryOptions } from '~/entities/parcel-company'
import { cn } from '~/shared/utils'

export function SearchParcelCompany() {
  const { control } = useFormContext<TReturnRequest>()
  const [query, setQuery] = useState('')
  const { data: parcelCompanyList = [] } = useQuery({
    ...parcelCompanyQueryOptions(),
    select: (d) => d.data.list.filter((e) => e.isUse === 'Y'),
  })

  const filteredParcelCompanyList = React.useMemo(
    () =>
      parcelCompanyList.filter((e) =>
        query
          ? query.length <= e.name.length
            ? matches(query, e.name) > 0.1
            : false
          : true
      ),
    [parcelCompanyList, query]
  )

  return (
    <Controller
      name="retrieveRequestDto.parcelCompanyIdx"
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div>
          <Combobox
            as="div"
            value={String(value) ?? ''}
            onChange={(val) => onChange(Number(val))}
          >
            <div className="relative">
              <Combobox.Button as="div" className="relative">
                <Combobox.Input
                  className={cn(
                    '!pl-[36px] flex h-10 w-full rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900 border-solid',
                    error && 'bg-clay-semantic-surfaceCritical border-none'
                  )}
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(idx) =>
                    parcelCompanyList.find((e) => e.idx === Number(idx))
                      ?.name ?? ''
                  }
                  placeholder={__('택배사를 선택해주세요')}
                />
                <Combobox.Label className="absolute left-0 top-0 w-[var(--icon-space,36px)] h-full flex items-center justify-center cursor-pointer">
                  <IconSearchSm className="stroke-[2]" />
                </Combobox.Label>

                {error && (
                  <IconWarningTriangle
                    width={16}
                    height={16}
                    className="stroke-[2] text-clay-semantic-iconCritical absolute right-[10px] top-1/2 -translate-y-1/2"
                  />
                )}
              </Combobox.Button>
              {filteredParcelCompanyList.length > 0 && (
                <Combobox.Options className="absolute z-10 p-[8px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredParcelCompanyList.map((parcelCompany) => (
                    <Combobox.Option
                      key={String(parcelCompany.idx)}
                      value={String(parcelCompany.idx)}
                    >
                      {({ active, selected }) => (
                        <span
                          className={cn(
                            'block truncate relative rounded-[8px] cursor-default select-none py-2 pl-3 pr-9 text-gray-900 transition-all',
                            (active || selected) &&
                              'bg-clay-semantic-actionSecondaryHover'
                          )}
                        >
                          {parcelCompany.name}
                        </span>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
          {error && (
            <div className="typo-clay-body-small mt-[4px] text-clay-semantic-textCritical">
              {error.message}
            </div>
          )}
        </div>
      )}
    />
  )
}
