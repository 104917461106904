/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

export const patchResNextShippingCompleteDto = z.object({
  updateResult: z.boolean(),
})

export const targetItemInformationDto = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
})
export const patchPayloadNextShippingCompleteDto = z.object({
  orderSectionCode: z.string(),
  targetItemInformation: z.array(targetItemInformationDto),
})
