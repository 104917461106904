import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResClaimsSwitchDto,
  type TPatchPayloadClaimsSwitchDto,
  patchResClaimsSwitchDto,
  patchPayloadClaimsSwitchDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description claims-switch
 */
export function patchClaimsSwitch({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadClaimsSwitchDto) {
    type T = TPatchResClaimsSwitchDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/sections/${sectionCode}/claims/switch`, body)
      .then((res) => res.data)

    return result
  }
}
