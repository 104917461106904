import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { Checkbox } from '~/shared/components/ui/checkbox'
import { Tooltip } from '~/shared/components/ui/tooltip'
import { IconHelpCircle } from '@imwebme/icon'
import type { TItem, TOption } from '../../product-search.type'
import { ProductSearchItemB } from '../../../product-search-item/product-search-item-b'
import { ProductSearchItemBase } from '../../../product-search-item/product-search-item-base'
import { NonRequiredOptionContainer } from './non-required-option.container'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

const DisableTooltip = () => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <div>
          <IconHelpCircle className="stroke-[2]" />
          <span className="sr-only">help</span>
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <p>Add to library</p>
      </Tooltip.Content>
    </Tooltip.Root>
  </Tooltip.Provider>
)

/**
 * 선택상품옵션
 */
export const NonRequiredOption = ({
  searchId,
  options,
  productData,
}: {
  searchId: string
  options: TOption[]
  productData: TItem
}) => (
  <div data-type="non-required-option">
    {pipe(
      options,
      A.map((e) => (
        <div
          key={e.optionCode}
          data-option-code={e.optionCode}
          className="space-y-[12px]"
        >
          {pipe(
            e.values,
            O.fromNullable,
            O.fold(
              () => null,
              flow(
                A.map((r) => (
                  <div key={r.valueCode} data-value-code={r.valueCode}>
                    <NonRequiredOptionContainer
                      searchId={searchId}
                      prodCode={productData.prodCode}
                      optionCode={e.optionCode}
                      valueCode={r.valueCode}
                      productData={productData}
                    >
                      {({ checked, setChecked, disabled }) => {
                        let name = __('(선택) {{name}}', {
                          name: r.valueName,
                        })
                        if (import.meta.env.VITE_NODE_ENV === 'local') {
                          name = `(${r.valueCode}) (선택) ${r.valueName}`
                        }
                        return (
                          <ProductSearchItemB
                            id={r.valueCode}
                            name={name}
                            price={pipe(r.price, String)}
                            data-type="non-required-option"
                            labelId={r.valueCode}
                          >
                            <ProductSearchItemBase.Slot name="LeftArea">
                              <Checkbox
                                className="rounded-[4px] disabled:opacity-100 disabled:bg-clay-semantic-actionPrimaryDisabled disabled:border-transparent disabled:text-clay-semantic-actionPrimaryDisabled"
                                checked={checked}
                                onChangeChecked={setChecked}
                                disabled={disabled}
                                id={r.valueCode}
                              />
                            </ProductSearchItemBase.Slot>
                            <ProductSearchItemBase.Slot name="NameArea">
                              <div className="flex items-center gap-x-[4px]">
                                <div
                                  className={cn(
                                    disabled &&
                                      'text-clay-semantic-textDisabled'
                                  )}
                                >
                                  {name}
                                </div>{' '}
                                {disabled && <DisableTooltip />}
                              </div>
                            </ProductSearchItemBase.Slot>
                          </ProductSearchItemB>
                        )
                      }}
                    </NonRequiredOptionContainer>
                  </div>
                ))
              )
            )
          )}
        </div>
      ))
    )}
  </div>
)

export const NonRequiredOptionMemo = React.memo(NonRequiredOption)
