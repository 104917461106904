import { z } from 'zod'

export const 상수_엑셀내보내기상태 = {
  진행중: 'OEU01',
  완료: 'OEU02',
  실패: 'OEU03',
} as const

const type = z.nativeEnum(상수_엑셀내보내기상태)
export type T상수_엑셀내보내기상태 = z.infer<typeof type>
