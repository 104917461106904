import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResCancelRequestDto,
  type TPatchPayloadCancelRequestDto,
  patchResCancelRequestDto,
  patchPayloadCancelRequestDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/WNoUiM
 * @description [cancel] 외부 주문서 섹션 취소접수 변경
 */
export function patchCancelRequest({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadCancelRequestDto) {
    type T = TPatchResCancelRequestDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/cancel/request`, body)
      .then((res) => res.data)

    const r = patchResCancelRequestDto.safeParse(result.data)
    if (!r.success) {
      log.debug(r.error)
    }
    return result
  }
}
