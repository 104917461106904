import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { __ } from '~/shared/i18n'
import { externalOrderQueryOptions } from '~/entities/external-order'
import {
  TPatchPayloadNextShippingReadyRdo,
  patchPayloadNextShippingReadyRdo,
} from '~/entities/external-order-section/next-shipping-ready'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ExternalItemImmutable } from '../external-item-immutable'
import { z } from 'zod'

export function ExternalItemImmutableList({
  sectionCode,
  children,
}: {
  children: ({
    List,
  }: {
    List: () => React.ReactNode
  }) => React.ReactNode | null
  sectionCode: string
}) {
  const orderCode = useOrderCode()
  const { data: sectionItems } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(
        // sectionCode를 받았기 때문에 sectionList는 항상있다.
        e.data.orderSectionList!,
        A.findFirst((e2) => e2.orderSectionCode === sectionCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트가 없습니다.')
        }),
        (e2) => e2.orderSectionItemList,
        O.fromNullable,
        O.getOrElseW(() => {
          throw new Error('섹션 리스트에 아이템리스트가 없습니다.')
        })
      )
      return result
    },
  })

  const formDefaultValues = React.useMemo(
    () => ({
      orderSectionCode: sectionCode,
      targetItemInformation: pipe(
        sectionItems,
        A.map((e) => ({
          _check: true,
          orderSectionItemCode: e.orderSectionItemCode,
          qty: e.qty,
        }))
      ),
    }),
    [sectionItems]
  )

  const method = useForm<TPatchPayloadNextShippingReadyRdo>({
    resolver: zodResolver(patchPayloadNextShippingReadyRdo),
    defaultValues: formDefaultValues,
  })

  // 체크박스 체크 및 섹션액션 API 요청 → 액션 성공하여 sectionItems 변경됨 → 폼을 리셋하여 체크상태를 해제함
  React.useEffect(() => {
    method.reset(formDefaultValues)
  }, [sectionItems])

  return (
    <FormProvider {...method}>
      {children({
        List: () => (
          <>
            <input type="hidden" {...method.register('orderSectionCode')} />
            <table className="w-full">
              <colgroup>
                <col className="min-w-[var(--mx)]" />
                <col className="w-full" />
                <col className="min-w-[100px]" />
                <col className="min-w-[100px]" />
                <col className="min-w-[var(--mx)]" />
              </colgroup>
              <tbody>
                {pipe(
                  sectionItems,
                  A.mapWithIndex((index, e) => (
                    <React.Fragment key={e.orderSectionItemCode}>
                      <ExternalItemImmutable
                        index={index}
                        sectionCode={sectionCode}
                        sectionItemCode={e.orderSectionItemCode}
                      />
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          </>
        ),
      })}
    </FormProvider>
  )
}

const targetItemInformationRdo = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
  _check: z.boolean().optional().optional(),
})

const externalItemMutableRdo = z.object({
  orderSectionCode: z.string(),
  targetItemInformation: z.array(targetItemInformationRdo),
})

type TExternalItemImmuutableList = z.infer<typeof externalItemMutableRdo>

export function useFormContextExternalItemImmutableList() {
  const form = useFormContext<TExternalItemImmuutableList>()
  return form
}
