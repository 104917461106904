import { pipe, flow } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import { TConvertExternlClaimsDto, TGetResExternalClaimsDto } from '.'

/**
 * @description
 * 외부채널 클레임 목록값을 array로 변경해준다.
 *
 * _before_
 * ```json
 * {
 *    "RETURN": {
 *      "description": "반품 사유",
 *      "responsibility": "판매자"
 *    },
 * }
 * ```
 * _after_
 * ```json
 * [
 *  {
 *    "key": "RETURN",
 *    "description": "반품 사유",
 *    "responsibility": "판매자",
 *  }
 * ]
 * ```
 */
export function convertObjectClaims2Array(
  e: TGetResExternalClaimsDto
): TConvertExternlClaimsDto {
  const result = pipe(
    e.claims,
    R.toArray,
    A.map(([k, v]) => ({ ...v, key: k }))
  )
  return result
}
