import { z } from 'zod'
import { 상수_결제상태, 상수_결제수단 } from '~/entities/@x'

export const selectPaymentForOrderDto = z.object({
  siteCode: z.string(),
  orderCode: z.string(),
  paymentCode: z.string(),
  paymentNo: z.string(),
  isCancel: z.string(),
  statusCd: z.nativeEnum(상수_결제상태),
  paidPrice: z.number(),
  pgTrxTime: z.string().nullish().describe('PG처리일시'),
  pgIdx: z.number().nullish(),
  taxFreePrice: z.number(),
  pgTrxKey: z.string().nullish(),
  methodCd: z.nativeEnum(상수_결제수단),
  pgName: z.string(),
  pgMethodCd: z.nativeEnum(상수_결제수단),
  wtime: z.string(),
})
