import { atom, useAtom } from 'jotai'
import { atomWithStorage, RESET } from 'jotai/utils'
import { match } from 'ts-pattern'

const onboardingTooltipAtom = atomWithStorage(
  'onboarding-tooltip__order-create-and-tab-order',
  { close: false, step: 0 },
  undefined,
  { getOnInit: true }
)

const derivedOnboardingTooltipAtom = atom(
  (get) => get(onboardingTooltipAtom),
  (get, set, update: 'prev' | 'next' | 'close' | typeof RESET) => {
    const data = get(onboardingTooltipAtom)
    match(update)
      .with('prev', () => {
        set(onboardingTooltipAtom, {
          ...data,
          step: Math.max(0, data.step - 1),
        })
      })
      .with('next', () => {
        set(onboardingTooltipAtom, {
          ...data,
          step: Math.min(1, data.step + 1),
        })
      })
      .with('close', () => {
        set(onboardingTooltipAtom, { ...data, close: true })
      })
      .otherwise(() => set(onboardingTooltipAtom, RESET))
  }
)

export function useOnboardingTooltip() {
  return useAtom(derivedOnboardingTooltipAtom)
}
