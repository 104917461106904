/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN } from '~/entities/@x'

export const patchResRetrievedDto = z.object({
  updateResult: z.boolean().describe('요청 결과'),
})

export const patchPayloadRetrievedDto = z.object({
  orderSectionCode: z.string().describe('요청 섹션 코드'),
  isActive: z.nativeEnum(YN).describe('활성화 여부'),
})
