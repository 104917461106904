export const deliverCode = {
  EPOST: 'EPOST',
  CJGLS: 'CJGLS',
  KGB: 'KGB',
  HANJIN: 'HANJIN',
  HYUNDAI: 'HYUNDAI',
  ILYANG: 'ILYANG',
} as const

export const goodsflowStatus = {
  Y: 'Y',
  N: 'N',
  C: 'C',
} as const
