import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'

const orderCodeAtom = atomWithReset<string | null>(null)
orderCodeAtom.debugLabel = 'orderCode'

export const useOrderCodeAtom = {
  state: () => useAtom(orderCodeAtom),
  get: () => useAtomValue(orderCodeAtom),
  set: () => useSetAtom(orderCodeAtom),
}
