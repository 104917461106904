import {
  Flex,
  Button,
  IconButton,
  ButtonGroup,
  Typography,
  Clay,
  OptionList,
  OptionItem,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { DotsVertical } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { formatPrice } from '~/container/onboarding/onboarding.fn'
import { Trans } from 'react-i18next'
import { P, match } from 'ts-pattern'
import { SkyRing } from '~/container/onboarding/partials/sky-ring'
import { useOnboardingStep } from '~/container/onboarding/onboarding.store'

export function PaymentInfoCard() {
  const [step] = useOnboardingStep()
  return (
    <Clay
      backgroundColor={vars.semantic.color.surface}
      borderRadius={vars.rounded.large}
      padding={`${vars.spacing[6]} 0`}
      position="relative"
      zIndex={match(step)
        .with(10, () => 1)
        .otherwise(() => 'initial')}
    >
      <Flex
        justifyContent="space-between"
        paddingInline={vars.spacing[6]}
        paddingBottom={vars.spacing[6]}
      >
        <Typography variant="heading-xlarge-bold">{__('결제정보')}</Typography>

        {match(step)
          .with(12, () => (
            <Clay position="relative" zIndex={1}>
              <IconButton
                icon={<DotsVertical />}
                variant="secondary"
                size="tiny"
                sx={{
                  backgroundColor: vars.semantic.color.actionSecondaryPressed,
                }}
              />
              <OptionList
                popOver
                sx={{
                  position: 'absolute',
                  width: '168px',
                  right: 0,
                  top: 36,
                }}
              >
                <OptionItem text={__('상세정보')} />
                <OptionItem text={__('입금대기로 되돌리기')} />
              </OptionList>
            </Clay>
          ))
          .otherwise(() => (
            <IconButton
              icon={<DotsVertical />}
              variant="secondary"
              size="tiny"
            />
          ))}
      </Flex>

      <Typography variant="label-medium" as="div">
        <Clay
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={vars.spacing[1]}
          paddingInline={vars.spacing[6]}
        >
          <Clay>{__('주문 금액')}</Clay>
          <Clay textAlign="right">{formatPrice(640000)}</Clay>
          <Clay>{__('배송비')}</Clay>
          <Clay textAlign="right">{formatPrice(30000)}</Clay>
          <Clay>{__('할인')}</Clay>
          <Clay textAlign="right">{formatPrice(-340000)}</Clay>
          <Clay>{__('환불')}</Clay>
          <Clay textAlign="right">{formatPrice(-30000)}</Clay>
          <Clay
            as="hr"
            gridColumnStart={1}
            gridColumnEnd={-1}
            borderColor={vars.semantic.color.border}
            margin={`${vars.spacing[3]} 0`}
          />
          <Typography variant="body-large">
            {match(step)
              .with(10, () => __('미결 금액'))
              .otherwise(() => __('결제된 금액'))}
          </Typography>
          <Typography textAlign="right">
            <Trans
              i18nKey="<t>{{price, 3comma}}</t> {{currency, currency}}"
              values={{
                price: 300000,
              }}
              components={{
                t: <Typography variant="heading-large-bold" as="span" />,
              }}
            />
          </Typography>
        </Clay>
      </Typography>

      {step === 10 && (
        <Flex
          justifyContent="flex-end"
          paddingInline={vars.spacing[6]}
          paddingTop={vars.spacing[5]}
        >
          <ButtonGroup isInline>
            <Button text={__('수동 입금 확인')} variant="outlined" />
            <Clay position="relative">
              <SkyRing />
              <Button text={__('결제 요청')} variant="primary" />
            </Clay>
          </ButtonGroup>
        </Flex>
      )}
    </Clay>
  )
}

function PaymentListByStep() {
  const [step] = useOnboardingStep()
  return match(step)
    .with(8, () => (
      <>
        <Clay>{__('주문 금액')}</Clay>
        <Clay textAlign="right">{formatPrice(640000)}</Clay>
        <Clay>{__('배송비')}</Clay>
        <Clay textAlign="right">{formatPrice(30000)}</Clay>
        <Clay>{__('할인')}</Clay>
        <Clay textAlign="right">{formatPrice(-340000)}</Clay>
        <Clay>{__('환불')}</Clay>
        <Clay textAlign="right">{formatPrice(-30000)}</Clay>
      </>
    ))
    .with(P.union(9, 11, 12), () => (
      <>
        <Clay>{__('주문 금액')}</Clay>
        <Clay textAlign="right">{formatPrice(2804000)}</Clay>
        <Clay>{__('배송비')}</Clay>
        <Clay textAlign="right">{formatPrice(50000)}</Clay>
        <Clay>{__('할인')}</Clay>
        <Clay textAlign="right">{formatPrice(-1059000)}</Clay>
      </>
    ))
    .with(10, () => (
      <>
        <Clay>{__('주문 금액')}</Clay>
        <Clay textAlign="right">{formatPrice(3254000)}</Clay>
        <Clay>{__('배송비')}</Clay>
        <Clay textAlign="right">{formatPrice(50000)}</Clay>
        <Clay>{__('할인')}</Clay>
        <Clay textAlign="right">{formatPrice(-1229000)}</Clay>
      </>
    ))
    .otherwise(() => null)
}

function PaymentTotalByStep() {
  const [step] = useOnboardingStep()
  return match(step)
    .with(8, () => (
      <>
        <Typography variant="body-large">{__('결제된 금액')}</Typography>
        <Typography textAlign="right">
          <Trans
            i18nKey="<t>{{price, 3comma}}</t> {{currency, currency}}"
            values={{
              price: 300000,
            }}
            components={{
              t: <Typography variant="heading-large-bold" as="span" />,
            }}
          />
        </Typography>
      </>
    ))
    .with(P.union(9, 11, 12), () => (
      <>
        <Typography variant="body-large">{__('결제된 금액')}</Typography>
        <Typography textAlign="right">
          <Trans
            i18nKey="<t>{{price, 3comma}}</t> {{currency, currency}}"
            values={{
              price: 1845000,
            }}
            components={{
              t: <Typography variant="heading-large-bold" as="span" />,
            }}
          />
        </Typography>
      </>
    ))
    .with(10, () => (
      <>
        <Typography variant="body-large">{__('미결 금액')}</Typography>
        <Typography textAlign="right">
          <Trans
            i18nKey="<t>{{price, 3comma}}</t> {{currency, currency}}"
            values={{
              price: 280000,
            }}
            components={{
              t: <Typography variant="heading-large-bold" as="span" />,
            }}
          />
        </Typography>
      </>
    ))
    .otherwise(() => null)
}
