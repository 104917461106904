import { TDeliverySetup } from '~/container/order-create/order-create.schema'
import {
  deliveryDataToAddress,
  TGetResMemberDeliveryDto,
} from '~/entities/member/member-delivery'
import { __ } from '~/shared/i18n'

/**
 * 배송지 jotai 상태를 주소 문자열로 반환
 * @example 아임웹, 070-4246-0106(06168)\n서울 강남구 테헤란로 501 브이플렉스 14, 15F
 * @example 삭삭몬, +12512384531\n{$Building}, {$Street}, {$City}, {$State}, {$zipcode}, {$country}
 */
export function deliverySetupToAddress(data: Partial<TDeliverySetup>): string {
  return deliveryDataToAddress(
    {
      delivName: data.receiverName,
      delivCall: data.receiverCall,
      delivPostcode: data.zipcode,
      delivAddress: data.addr1,
      delivAddressDetail: data.addr2,
      delivAddressBuilding: data.building,
      delivAddressStreet: data.street,
      delivAddressCity: data.city,
      delivAddressState: data.state,
      delivAddressZipcode: data.zipcode,
    },
    data.country ?? 'KR'
  )
}

export function delivDataToJotai(
  data: TGetResMemberDeliveryDto[number]
): Partial<TDeliverySetup> {
  return {
    shippingAddressCode: data.code,
    country: data.shippingCountry,
    addressFormat: data.addressType,
    receiverName: data.addressData.delivName,
    receiverCall: data.addressData.delivCall,
    zipcode:
      data.addressData.delivPostcode || data.addressData.delivAddressZipcode,
    addr1: data.addressData.delivAddress,
    addr2: data.addressData.delivAddressDetail,
    building: data.addressData.delivAddressBuilding,
    street: data.addressData.delivAddressStreet,
    city: data.addressData.delivAddressCity,
    state: data.addressData.delivAddressState,
  }
}
