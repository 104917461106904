import React from 'react'
import { useStatusNextShippingModalAtom, type TStatusNextShippingBase } from '.'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'

const StatusNextShippingContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.StatusNextShipping),
  }))
)

interface TStatusNextShippingContainer extends TStatusNextShippingBase {}
export function StatusNextShippingPack({
  orderCode,
  sectionCode,
  ...props
}: TStatusNextShippingContainer) {
  const [open, setState] = useStatusNextShippingModalAtom.state({
    orderCode,
    sectionCode,
  })

  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <StatusNextShippingContainer
            orderCode={orderCode}
            sectionCode={sectionCode}
            {...props}
          />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
