/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'
import {
  retrievePayTypeCd,
  retrieveTypeCd,
} from './return-settlements.constants'
import { 상수_환불금액_타입 } from '~/entities/@x'

const returnInfoSchema = z.object({
  siteCode: z.string(),
  idx: z.number().nullish(),
  orderSectionCode: z.string(),
  isCustomerRequest: z.union([z.literal('Y'), z.literal('N')]),
  isExchange: z.union([z.literal('Y'), z.literal('N')]),
  returnReason: z.string(),
  returnReasonDetail: z.string().nullish(),
  refundPriceTypeCd: z.nativeEnum(상수_환불금액_타입),
  deliveryExtraPrice: z.number(),
  etcPrice: z.number().nullish(),
  etcPriceReason: z.string().nullish(),
  retrieveTypeCd: z.nativeEnum(retrieveTypeCd),
  retrievePayTypeCd: z.nativeEnum(retrievePayTypeCd),
  retrieveMemo: z.string().nullish(),
  orderDeliveryCode: z.string().nullish(),
  invoiceCode: z.string().nullish(),
  centerCode: z.string().nullish(),
  refundAmount: z.number(),
  refundTaxFreeAmount: z.number(),
  refundPoint: z.number(),
  isRefund: z.string(),
  isAlternativeRefund: z.union([z.literal('Y'), z.literal('N')]),
  alternativeRefundData: z.string().nullish(),
  isDel: z.string(),
  wtime: z.string().datetime(),
  mtime: z.string().datetime(),
})
export const couponInfoDto = z.object({
  couponIssueCode: z.string(),
  couponName: z.string(),
  endDate: z.string().nullable(),
  isUnlimitedDate: z.union([z.literal('Y'), z.literal('N')]),
})
export const returnSettlementsDto = z.object({
  orderSectionItemList: z.array(
    z.object({
      orderSectionItemCode: z.string(),
      qty: z.number(),
      prodName: z.string(),
      baseItemPrice: z.number(),
      itemPrice: z.number(),
      optionInfo: z
        .array(
          z.object({
            key: z.string(),
            value: z.any(),
          })
        )
        .nullish(),
      imageUrl: z.string().nullish(),
    })
  ),
  totalItemPrice: z.number(),
  totalActualPaymentPrice: z.number(),
  totalAverageActualPaymentPrice: z.number(),
  refundableRemainPaymentPrice: z.number(),
  refundableAmount: z.number(),
  recreditedPoint: z.number(),
  totalGradeDiscount: z.number(),
  totalItemCouponDiscount: z.number(),
  totalItemPointAmount: z.number(),
  returnInfo: returnInfoSchema,
  usedCouponInfo: z.array(couponInfoDto).nullish(),
})
export const postResReturnSettlementsDto = ApiResponseSchema.extend({
  data: returnSettlementsDto,
})
export const postPayloadReturnSettlementsDto = z.object({
  targetItemInformation: z.array(
    z.object({
      orderSectionItemCode: z.string(),
      qty: z.number(),
    })
  ),
})
