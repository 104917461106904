import { createStore, atom, useAtomValue } from 'jotai'
import { TGetResPermissionDto } from '~/entities/permission'

export const defaultValue: TGetResPermissionDto = {
  adminShoppingOms: {
    edit: 'N',
    view: 'N',
  },
  etcPermissionData: {
    isPersonalInformationAccessPermission: 'N',
  },
}

export const permissionAtom = atom<TGetResPermissionDto>(defaultValue)
permissionAtom.debugLabel = 'permission'

export const usePermissionAtom = () => useAtomValue(permissionAtom)

export const store = createStore()
