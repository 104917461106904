import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { __, __e } from '~/shared/i18n'
import { type TRegisterInvoiceExcelBase, useRegisterInvoiceExcelAtom } from '.'
import { Button } from '@imwebme/clay-components'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ExcelUploadTemplateContainer } from './excel-upload-template'
import { DragDropFile } from './drag-drop-file'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useMutation } from '@tanstack/react-query'
import { Spinner } from '@imwebme/clay-components'
import { InvoiceExcelHistoryContainer } from './invoice-excel-history/invoice-excel-history.container'
import {
  TInvoiceExcelBatchSchema,
  getInvoiceExcelBatch,
} from '~/entities/invoice-excel-batch'
import { postInvoiceExcelBulkUpload } from '~/entities/invoice-excel-bulk-upload'
import {
  모델_송장배치,
  스펙_송장배치,
} from '~/entities/invoice-excel-batch/model'
import { toast } from 'react-toastify'

const schema = z.object({
  file: z.custom<File>((v) => v instanceof File, {
    message: __('파일을 업로드해주세요.'),
  }),
  fileName: z.string(),
})

export type FormValue = z.infer<typeof schema>
const resolver = zodResolver(schema)

interface TRegisterInvoiceExcel extends TRegisterInvoiceExcelBase {
  onSuccessCheckBatch?: (arg: TInvoiceExcelBatchSchema) => void
}
export function RegisterInvoiceExcel({
  onSuccessCheckBatch,
}: TRegisterInvoiceExcel) {
  const [batchCode, setBatchCode] = React.useState<string | null>(null)
  const { mutate } = useMutation({
    mutationFn: postInvoiceExcelBulkUpload,
    onSuccess: (resData) => {
      setBatchCode(resData)
    },
    onError: (error) => {
      toast.error(
        __e(error.response?.data.code || error.response?.data.message || '')
      )
    },
  })
  const { mutate: checkBatchCodeMutate } = useMutation({
    mutationFn: getInvoiceExcelBatch,
    onError: (error) => {
      toast.error(
        __e(error.response?.data.code || error.response?.data.message || '')
      )
      setBatchCode(null)
    },
    onSuccess: (res) => {
      log.debug('succes')
      const result = pipe(
        res,
        O.fromNullable,
        O.fold(
          () => true,
          flow(
            (e) => new 모델_송장배치(e),
            (e) =>
              스펙_송장배치.모두성공
                .or(스펙_송장배치.부분실패)
                .or(스펙_송장배치.업로드실패.not())
                .or(스펙_송장배치.잘못된형식.not())
                .isSatisfiedBy(e)
          )
        )
      )
      setBatchCode(null)
      if (result) {
        onSuccessCheckBatch?.(res)
      }
      return res
    },
  })

  const enabled = !!batchCode
  // 폴링에 성공했을때 useQuery onSuccess
  React.useEffect(() => {
    let _interval: NodeJS.Timeout | null = null
    if (enabled) {
      _interval = setInterval(() => {
        checkBatchCodeMutate(batchCode)
      }, 1000)
    }
    return () => {
      if (_interval) {
        clearInterval(_interval)
      }
    }
  }, [enabled])

  const method = useForm<FormValue>({
    resolver,
  })
  const onSubmit: SubmitHandler<FormValue> = (e) => {
    const formData = new FormData()
    formData.append('file', e.file)
    formData.append('fileName', e.fileName)
    // 이미 가지고있던 배치 상태 데이터를 무효화
    // 어짜피 새로운 배치가 등록되면 기존 배치는 의미가 없으니 무효화하지 않는다.

    // 폼데이터 전송
    mutate(formData)
  }
  return (
    <FormProvider {...method}>
      <form onSubmit={method.handleSubmit(onSubmit)}>
        <div className="mx-[var(--sx)] px-[24px] mt-[32px]">
          <div className="semantic-h4-bold">
            {__('Excel로 송장번호 일괄 등록하기')}
          </div>
        </div>
        <div className="mx-[var(--sx)] px-[24px] mt-[var(--space)]">
          <div className="bg-clay-semantic-surfaceSub border-l-[4px] border-clay-semantic-border p-[16px] typo-clay-body-medium">
            {__(
              '다운로드한 엑셀 양식에 맞춰 송장정보를 써준 뒤 파일을 올리면 송장번호를 한 번에 등록할 수 있어요.'
            )}
          </div>
        </div>
        <div className="mx-[var(--sx)] px-[24px] mt-[var(--space)]">
          <ExcelUploadTemplateContainer />
        </div>
        <div className="mx-[var(--sx)] px-[24px] mt-[var(--space)]">
          <DragDropFile />
        </div>
        <div className="mx-[var(--sx)] px-[24px] mt-[var(--space)]">
          <InvoiceExcelHistoryContainer />
        </div>
        <div className="mx-[var(--sx)] mt-[calc(var(--space)+4px)] px-[24px] mb-[20px] flex justify-end">
          <div className="flex gap-x-[8px]">
            <AlertDialog.Cancel asChild>
              <Button
                native={{ type: 'button' }}
                variant="secondary"
                isDisabled={enabled}
                text={__('닫기')}
              />
            </AlertDialog.Cancel>
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              className="typo-clay-label-medium min-w-[68px] disabled:text-white disabled:bg-clay-semantic-actionPrimaryPressed"
              isDisabled={enabled}
              text={enabled ? __('등록중') : __('등록')}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
