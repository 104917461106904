import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import { type TGetResSiteUnitSchema, getResSiteUnitSchema } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description site-unit
 */
export async function getSiteUnit() {
  const result = await axios
    .get<ApiResponse<TGetResSiteUnitSchema>>('/site/units')
    .then((res) => res.data)

  return result.data
}
