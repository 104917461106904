/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

export const patchResReturnReasonDto = z.any()

export const patchPayloadReturnReasonDto = z.object({
  orderSectionCode: z.string().describe('요청 섹션 코드'),
  returnReasonCode: z.string()
    .describe(`반품 사유 코드 (외부채널 클레임 사유는 각 채널별로 정의된 코드를 사용합니다.)
  해당 사유 코드는 site관련 api 참고, 각 사유별로 귀책 대상이 존재합니다`),
  returnReasonDetail: z.string().nullish().describe('반품 사유 상세'),
})
