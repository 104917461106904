import {
  Flex,
  TextButton,
  IconButton,
  Typography,
  Clay,
  OptionList,
  OptionItem,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { DotsVertical } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { useOnboardingStep } from '~/container/onboarding/onboarding.store'

export function ShippingInfoCard() {
  const [step] = useOnboardingStep()
  return (
    <Clay
      backgroundColor={vars.semantic.color.surface}
      borderRadius={vars.rounded.large}
      padding={`${vars.spacing[5]} 0`}
    >
      <Flex
        justifyContent="space-between"
        paddingInline={vars.spacing[6]}
        paddingBottom={vars.spacing[5]}
      >
        <Typography variant="heading-medium-bold">
          {__('배송지 정보')}
        </Typography>
        <TextButton text={__('추가')} variant="primary" />
      </Flex>

      <Typography variant="label-medium" as="div">
        <Flex.Column gap={vars.spacing[1]} paddingInline={vars.spacing[6]}>
          <Flex justifyContent="space-between">
            <Typography>{__('구매자')}</Typography>

            {match(step)
              .with(12, () => (
                <Clay position="relative" zIndex={1}>
                  <IconButton
                    icon={<DotsVertical />}
                    variant="secondary"
                    size="tiny"
                    sx={{
                      backgroundColor:
                        vars.semantic.color.actionSecondaryPressed,
                    }}
                  />
                  <OptionList
                    popOver
                    sx={{
                      position: 'absolute',
                      width: '120px',
                      right: 0,
                      top: 36,
                    }}
                  >
                    <OptionItem text={__('수정')} />
                    <OptionItem
                      text={__('삭제')}
                      sx={{ color: vars.semantic.color.textCritical }}
                    />
                  </OptionList>
                </Clay>
              ))
              .otherwise(() => (
                <IconButton
                  icon={<DotsVertical />}
                  variant="secondary"
                  size="tiny"
                />
              ))}
          </Flex>
          <TextButton text="070-1234-5678" variant="primary" />
          <Typography>{__('서울 강남구')}</Typography>
          <Typography colorToken="textSub">{__('배송 메모 없음')}</Typography>
        </Flex.Column>
      </Typography>
    </Clay>
  )
}
