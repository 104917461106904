import React from 'react'
import { OrderSectionDto, OrderSectionItemDto } from '~/entities/order-detail'
import { __g, __ } from '~/shared/i18n'
import { X } from '@imwebme/clay-icons'
import { Tag, SafeImageLoading } from '@imwebme/clay-components'
import ProductThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import {
  TExternalOrderSectionDto,
  TExternalOrderSectionItemDto,
} from '~/entities/external-order'

interface Props {
  orderSectionList: (OrderSectionDto | TExternalOrderSectionDto)[]
}

export function OrderItemsAll({ orderSectionList }: Props) {
  return (
    <div
      data-testid="order-items-all"
      className="border-l border-r border-clay-semantic-divide h-full"
    >
      {orderSectionList.map((section) => (
        <React.Fragment key={section.orderSectionCode}>
          <div className="space-y-clay-3 p-clay-3">
            <div className="typo-clay-body-small-bold text-clay-semantic-text">
              {__g(section.statusCd)}
            </div>
            <ul className="space-y-clay-3">
              {section.orderSectionItemList?.map((item) => (
                <SectionItem item={item} key={item.orderItemCode} />
              ))}
            </ul>
          </div>
          <hr className="divide-clay-semantic-divide last:hidden" />
        </React.Fragment>
      ))}
    </div>
  )
}

function SectionItem({
  item,
}: {
  item: OrderSectionItemDto | TExternalOrderSectionItemDto
}) {
  return (
    <li className="flex items-start gap-x-clay-3">
      {/* 상품 이미지 */}
      <div className="w-clay-8 h-clay-8 rounded-clay-medium overflow-hidden">
        <SafeImageLoading
          fallback={
            <ProductThumbFallback className="w-full h-full object-cover bg-white" />
          }
          src={item.imageUrl ?? undefined}
        />
      </div>

      {/* 상품 정보 */}
      <div className="space-y-clay-2 w-[320px]">
        <div className="typo-clay-body-medium text-clay-semantic-text whitespace-pre-wrap">
          {item.prodName}
        </div>
        {item.optionInfo && (
          <ul className="flex gap-clay-1 flex-wrap">
            {item.optionInfo.map((option, index) => (
              <Tag
                key={index + option.key + option.value}
                as="li"
                variant="other"
                text={`${option.key} ${option.value}`}
                sx={{
                  '--clay-tag-background-color': 'rgba(21, 24, 30, 0.10)',
                  '--clay-tag-color': '#15181E',
                }}
              />
            ))}
          </ul>
        )}
      </div>

      {/* 수량 */}
      <div className="flex items-center gap-x-clay-1 shrink-0">
        <X />
        <span className="typo-clay-body-medium-bold text-clay-semantic-text">
          {item.qty}
        </span>
      </div>
    </li>
  )
}
