/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'

/**
 * post order-memo
 */
export const postResOrderMemoSchema = ApiResponseSchema
export const postPayloadOrderMemoSchema = z.object({
  memo: z.string(),
})

/**
 * patch order-memo
 */
export const patchResOrderMemoSchema = ApiResponseSchema
export const patchPayloadOrderMemoSchema = z.object({
  memo: z.string().nullish(),
  isDone: z.union([z.literal('Y'), z.literal('N')]).nullish(),
})

/**
 * delete order-memo
 */
export const deleteResOrderMemoSchema = ApiResponseSchema
export const deletePayloadOrderMemoSchema = z.undefined()
