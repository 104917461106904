import React from 'react'
import { useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import { Selector } from '../components'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { getOrderInfoFieldLabel } from './order-info.helper'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'
import { Textarea } from '@imwebme/clay-components'

interface Props extends FormControlProp {
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
  placeholder?: string
}

const fieldKey = 'receiverAddress'
const MAX_TEXT_LENGTH = 100

export const ReceiverAddressField = ({
  control,
  handleRemoveOrderInfoField,
}: Props) => {
  const { onChange, value, ...inputProps } = useController({
    name: fieldKey,
    control,
  }).field
  const [dropdownOpen, setDropdownOpen] = React.useState(
    () => value === undefined || value === null
  )

  const fieldLabel = React.useMemo(() => {
    let baseLabel = getOrderInfoFieldLabel(fieldKey) + ': '

    value ? (baseLabel += value) : (baseLabel += __('입력해 주세요'))

    return baseLabel.length > 20
      ? baseLabel.substring(0, 20) + '...'
      : baseLabel
  }, [value, fieldKey])

  const handleChange = React.useCallback((newValue: string) => {
    if (newValue === '') {
      onChange(null)
      return
    }
    if (newValue.length < MAX_TEXT_LENGTH) {
      onChange(newValue)
    }
  }, [])

  const handleResetField = React.useCallback(() => {
    onChange(null)
    handleRemoveOrderInfoField(fieldKey)
  }, [])

  React.useEffect(() => {
    ;(function removeFieldOnExit() {
      if (dropdownOpen === false && !value) {
        handleRemoveOrderInfoField(fieldKey)
      }
    })()
  }, [value, dropdownOpen])

  return (
    <Selector
      label={fieldLabel}
      isActive={true}
      resetField={handleResetField}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="px-clay-5 py-clay-4 w-[280px]">
          <Textarea
            value={value ?? ''}
            onChange={handleChange}
            label={getOrderInfoFieldLabel(fieldKey)}
            characterCount
            maxLength={MAX_TEXT_LENGTH}
            placeholder={__('입력해 주세요')}
            {...inputProps}
          />
        </div>
      }
    />
  )
}
