import { IconChevronDown } from '@imwebme/icon'
import { Select } from '~/shared/components/ui/select'
import { Trans } from 'react-i18next'
import React, { useEffect } from 'react'
import { withRRT01Component } from '~/container/order/order-return-info/views/order-return-info-from/collection-information/rtt01/rrt01.container'
import { useFormContext } from 'react-hook-form'
import type { TReturnRequest } from '~/container/order/order-return-info/order-retrun-info.type'
import { AddressField } from './address'
import type { TRRT01Component } from './rrt01component.type'
import Collapse from './collapse'
import { __ } from '~/shared/i18n'

/**
 * 자동수거일때 나오는 것
 */
export const RRT01Component = ({ deliveryList }: TRRT01Component) => {
  const [value, onValueChange] = React.useState(() =>
    deliveryList?.length ? deliveryList[0].orderDeliveryCode : 'direct-input'
  )
  const { setValue } = useFormContext<TReturnRequest>()

  useEffect(() => {
    // 필드의 값을 모두 빈값으로 넣어주고 폼데이터에서 필드값을 제거해야지 재대로 초기화된 것처럼 보인다.
    // 그렇지않으면 폼데이터에 필드값은 없지만 UI에서 값으로 보이게된다.

    // 빈값으로 모두 초기화
    setValue('retrieveRequestDto.orderDeliveryCode', '')
    setValue('retrieveRequestDto.receiverName', '')
    setValue('retrieveRequestDto.receiverCall', '')
    setValue('retrieveRequestDto.addr1', '')
    setValue('retrieveRequestDto.addr2', '')
    setValue('retrieveRequestDto.zipcode', '')

    // 그리고 선택한 값이 direct-input이 아니라면 값을 채워준다.
    if (value !== 'direct-input') {
      const selected = deliveryList?.find((e) => e.orderDeliveryCode === value)
      setValue(
        'retrieveRequestDto.orderDeliveryCode',
        selected?.orderDeliveryCode
      )
      setValue('retrieveRequestDto.receiverName', selected?.receiverName)
      setValue('retrieveRequestDto.receiverCall', selected?.receiverCall)
      setValue('retrieveRequestDto.addr1', selected?.addr1)
      setValue('retrieveRequestDto.addr2', selected?.addr2 || '')
      setValue('retrieveRequestDto.zipcode', selected?.zipcode)
    }
  }, [value])

  return (
    <div>
      <Select.Root onValueChange={onValueChange} defaultValue={value}>
        <Select.Trigger className="w-full text-left min-h-[2.5rem] h-auto border-solid">
          <Select.Value placeholder={__('주소를 입력해주세요')} />
          <IconChevronDown className="stroke-[2]" />
        </Select.Trigger>
        <Select.Content>
          <Select.Group>
            {deliveryList?.map((e, _) => (
              <Select.Item
                value={e.orderDeliveryCode}
                key={e.orderDeliveryCode}
              >
                {e.receiverName}, {e.receiverCall}
                <br />({e.zipcode}) {e.addr1}, {e.addr2}
              </Select.Item>
            ))}
            <Select.Item
              disabled={deliveryList?.length === 5}
              value="direct-input"
              key="direct-input"
            >
              {__('직업입력')}
            </Select.Item>
          </Select.Group>
        </Select.Content>
      </Select.Root>
      <Collapse in={value === 'direct-input'}>
        <AddressField />
      </Collapse>
    </div>
  )
}

export const WRRT01Component = withRRT01Component(RRT01Component)
