import { RadioGroup } from '~/shared/components/ui/radio-group'
import { Control, Controller } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

export interface OrderEditCalcDeliveryFeeMethodProps {
  onChangeDeliveryFeeMethod?: (value: string) => void
  control: Control<any>
  deliveryFeeMethodDisabled?: boolean
}

const OrderEditCalcDeliveryFeeMethod = ({
  onChangeDeliveryFeeMethod,
  control,
  deliveryFeeMethodDisabled,
}: OrderEditCalcDeliveryFeeMethodProps) => (
  <div className="py-[4px]">
    <Controller
      name="deliveryFeeMethod"
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <RadioGroup
          disabled={deliveryFeeMethodDisabled}
          defaultValue={deliveryFeeMethodDisabled ? undefined : 'ADD'}
          value={field.value}
          onValueChange={(value: string) => {
            field.onChange(value)
            onChangeDeliveryFeeMethod?.(value)
          }}
          className="flex gap-[24px] items-end self-stretch"
        >
          <div className="flex items-center space-x-2">
            <RadioGroup.Item
              value={deliveryFeeMethodDisabled ? '' : 'ADD'}
              id="methodOption1"
              disabled={deliveryFeeMethodDisabled}
            />
            <RadioGroup.Label
              htmlFor="methodOption1"
              className="text-[14px] leading-[20px]"
            >
              <span
                className={cn(
                  deliveryFeeMethodDisabled && 'text-clay-semantic-textDisabled'
                )}
              >
                {__('요청')}
              </span>
            </RadioGroup.Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroup.Item
              value={deliveryFeeMethodDisabled ? '' : 'SUB'}
              id="methodOption2"
              disabled={deliveryFeeMethodDisabled}
            />
            <RadioGroup.Label
              htmlFor="methodOption2"
              className="text-[14px] leading-[20px]"
            >
              <span
                className={cn(
                  deliveryFeeMethodDisabled && 'text-clay-semantic-textDisabled'
                )}
              >
                {__('환불')}
              </span>
            </RadioGroup.Label>
          </div>
        </RadioGroup>
      )}
    />
  </div>
)

export { OrderEditCalcDeliveryFeeMethod }
