import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResBackProductPreparationDto,
  type TPatchPayloadBackProductPreparationDto,
  patchResBackProductPreparationDto,
  patchPayloadBackProductPreparationDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/nwA6Wl
 * @description [back] 주문 섹션 상태 상품준비로 되돌리기
 */
export function patchBackProductPreparation({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPatchPayloadBackProductPreparationDto) {
    type T = TPatchResBackProductPreparationDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/back/product-preparation`, body)
      .then((res) => res.data)

    patchResBackProductPreparationDto.parse(result.data)
    return result
  }
}
