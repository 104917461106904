import { ISpecification } from 'spec-pattern-ts'
import { TExternalOrderSectionDto } from '~/entities/external-order'
import { 모델_외부채널_주문섹션 } from '~/entities/external-order/model/external-order-section'

// 스펙을 기반으로 배열 정렬 함수 생성
export function sortBySpecs(specs: ISpecification<모델_외부채널_주문섹션>[]) {
  return function (
    array: TExternalOrderSectionDto[]
  ): TExternalOrderSectionDto[] {
    return array.sort((a, b) => {
      const aModel = new 모델_외부채널_주문섹션(a)
      const bModel = new 모델_외부채널_주문섹션(b)
      for (const spec of specs) {
        if (spec.isSatisfiedBy(aModel) && !spec.isSatisfiedBy(bModel)) {
          return -1
        }
        if (!spec.isSatisfiedBy(aModel) && spec.isSatisfiedBy(bModel)) {
          return 1
        }
      }
      return 0
    })
  }
}

export function filterSomeBySpecs(
  specs: ISpecification<모델_외부채널_주문섹션>[]
) {
  return function (
    array: TExternalOrderSectionDto[]
  ): TExternalOrderSectionDto[] {
    if (specs.length === 0) {
      return array
    }
    return array.filter((item) => {
      const model = new 모델_외부채널_주문섹션(item)
      // 한개의 스펙이라도 만족하면 true
      return specs.some((spec) => spec.isSatisfiedBy(model))
    })
  }
}
