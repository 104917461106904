import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { OrderSection } from '~t'
import { TExternalPurchaseConfirmationBase } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { findExternalOrderSection } from '~/shared/calc/find-external-order-section'
import { fpDate } from '~/shared/utils'
import { __ } from '~/shared/i18n'
import {
  ExternalItemImmutableList,
  useFormContextExternalItemImmutableList,
} from '../partials/external-item-immutable-list'
import { 모델_외부채널_주문섹션 } from '~/entities/external-order/model/external-order-section'
import { Menubar } from '~/shared/components/menubar'
import { IconDotsVertical } from '@imwebme/icon'
import { InvoiceEditPack, useInvoiceEditAtom } from '~/features/모달_송장수정'
import {
  BackShippingCompleteModalPack,
  useBackShippingCompleteModalAtom,
} from '~/entities/external-order-section/back-shipping-complete/modal'
import { ExternalDeliveryPack } from '../partials/external-delivery/external-delivery.pack'
import { IconButton } from '@imwebme/clay-components'
import { useSectionCode } from '~/shared/hooks/use-section-code'

interface TExternalPurchaseConfirmation
  extends TExternalPurchaseConfirmationBase {}
export function ExternalPurchaseConfirmation(_: TExternalPurchaseConfirmation) {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode(true)
  const {
    data: { order, section },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      return {
        order: e.data,
        section: pipe(e.data, findExternalOrderSection(sectionCode)),
      }
    },
  })

  const $exSection = new 모델_외부채널_주문섹션(section)

  return (
    <ExternalItemImmutableList sectionCode={sectionCode}>
      {({ List }) => (
        <OrderSection name="외부채널_구매확정">
          <OrderSection.Slot name="title">
            <div className="grid grid-cols-[1fr,auto] gap-x-[8px]">
              <div>
                <div className="typo-clay-heading-xlarge-bold">
                  {__('구매확정')}{' '}
                  {pipe(
                    section.orderSectionItemList,
                    O.fromNullable,
                    O.fold(
                      () => null,
                      flow(
                        (e) => e.length,
                        (e) => (
                          <span className="text-clay-semantic-textPrimary">
                            {e}
                          </span>
                        )
                      )
                    )
                  )}
                </div>
                <div className="mt-[8px] typo-clay-body-medium text-clay-semantic-textSub">
                  {__('#{{섹션넘버}} · {{생성일}} 생성', {
                    생성일: pipe(
                      section.wtime,
                      O.fromNullable,
                      O.fold(
                        () => new Date(),
                        (e) => new Date(e)
                      ),
                      fpDate('yyyy년 MM월 dd일')
                    ),
                    섹션넘버: section.orderSectionNo,
                  })}
                </div>
              </div>
              <div data-find="구매확정:엑션메뉴">
                <ExternalPurchaseConfirmationMenu $exSection={$exSection} />
              </div>
            </div>
          </OrderSection.Slot>
          <OrderSection.Slot
            name="body"
            className="mt-[24px] [--mx:24px] space-y-[24px]"
          >
            <ExternalDeliveryPack
              sectionCode={sectionCode}
              deliveryCode={section.orderDeliveryCode}
            />
            <List />
          </OrderSection.Slot>
        </OrderSection>
      )}
    </ExternalItemImmutableList>
  )
}

function ExternalPurchaseConfirmationMenu({
  $exSection,
}: {
  $exSection: 모델_외부채널_주문섹션
}) {
  const orderCode = useOrderCode()
  const sectionCode = $exSection.data.orderSectionCode
  const onInvoiceEditModal = useInvoiceEditAtom.set({
    orderCode,
    sectionCode,
    invoiceCode: $exSection.data.invoiceCode!,
  })
  const onBackShippingCompleteModalModal = useBackShippingCompleteModalAtom.set(
    {
      orderCode,
      sectionCode,
    }
  )
  return (
    <>
      <Menubar>
        <Menubar.Menu>
          <Menubar.Trigger asChild>
            <IconButton
              native={{ type: 'button' }}
              variant="secondary"
              size="tiny"
              icon={
                <IconDotsVertical
                  className="stroke-[2]"
                  width={16}
                  height={16}
                />
              }
            />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content>
              <Menubar.Item onSelect={() => onInvoiceEditModal(true)}>
                {__('송장번호 수정')}
              </Menubar.Item>
              <Menubar.Item disabled>{__('배송완료로 되돌리기')}</Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
      </Menubar>
      <InvoiceEditPack
        sectionCode={sectionCode}
        invoiceCode={$exSection.data.invoiceCode!}
        invoiceNo={$exSection.data.invoice?.invoiceNo}
        parcelCompanyIdx={$exSection.data.invoice?.parcelCompanyIdx}
      />
      <BackShippingCompleteModalPackForm sectionCode={sectionCode} />
    </>
  )
}

function BackShippingCompleteModalPackForm({
  sectionCode,
}: {
  sectionCode: string
}) {
  const { handleSubmit } = useFormContextExternalItemImmutableList()
  return (
    <BackShippingCompleteModalPack
      sectionCode={sectionCode}
      onSubmit={(mutate) => {
        handleSubmit((body) => {
          mutate(body)
        })()
      }}
    />
  )
}
