/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */
import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'

/**
 * post bulk-action-section-invoice-excel
 */
export const postResBulkActionSectionInvoiceExcelSchema = z.any()
export const postPayloadBulkActionSectionInvoiceExcelSchema = z.object({
  targetOrderCodes: z.array(z.string()),
})
