import { z } from 'zod'
import { 상수_현금영수증_상태, 상수_결제상태 } from '~/entities/@x'

export const cashReceiptInfoData = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  cashReceiptStatusCd: z.nativeEnum(상수_현금영수증_상태),
  paymentStatusCd: z.nativeEnum(상수_결제상태),
})

export type TCashReceiptInfoData = z.infer<typeof cashReceiptInfoData>
