import * as React from 'react'
import * as MenubarPrimitive from '@radix-ui/react-menubar'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '~/shared/utils'
import { OptionList } from '@imwebme/clay-components'

const MenubarMenu = MenubarPrimitive.Menu

const MenubarGroup = MenubarPrimitive.Group

const MenubarPortal = MenubarPrimitive.Portal

const MenubarSub = MenubarPrimitive.Sub

const MenubarRadioGroup = MenubarPrimitive.RadioGroup

const Menubar = MenubarPrimitive.Root

const MenubarTrigger = MenubarPrimitive.Trigger

const MenubarSubTrigger = MenubarPrimitive.SubTrigger

const MenubarSubContent = MenubarPrimitive.SubContent

const MenubarContent = React.forwardRef<
  React.ElementRef<typeof MenubarPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Content>
>(({ sideOffset = 8, children, ...props }, ref) => (
  <MenubarPrimitive.Portal>
    <MenubarPrimitive.Content ref={ref} sideOffset={sideOffset} {...props}>
      <OptionList popOver as="div">
        {children}
      </OptionList>
    </MenubarPrimitive.Content>
  </MenubarPrimitive.Portal>
))
MenubarContent.displayName = MenubarPrimitive.Content.displayName

const itemBaseVariants = cva(
  `cursor-pointer hover:bg-clay-semantic-actionSecondaryHover data-[state=checked]:bg-[#1A6DFF1A] focus-visible:outline-none
  data-[disabled]:text-clay-semantic-textDisabled data-[disabled]:cursor-not-allowed data-[disabled]:bg-transparent data-[disabled]:cursor-not-allowed
  `,
  {
    variants: {
      size: {
        default: 'px-[12px] py-[8px] rounded-[8px] typo-clay-label-medium',
        large: 'px-[12px] py-[12px] rounded-[8px] typo-clay-label-medium',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
)

/**
 * 클레이의 Option Item을 따릅니다.
 * clay-components의 OptionItem이 radix-ui와 호환성이 좋지 않아(예를 들어 OptionItem에 forwardRef가 필요해 보입니다.) 스타일을 직접 작성했습니다.
 */
const MenubarItem = React.forwardRef<
  React.ElementRef<typeof MenubarPrimitive.Item>,
  Omit<
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Item>,
    'asChild' | 'onClick'
  > &
    VariantProps<typeof itemBaseVariants>
>(({ className, size, ...props }, ref) => (
  <MenubarPrimitive.Item
    ref={ref}
    className={cn(itemBaseVariants({ size }), className)}
    {...props}
  />
))
MenubarItem.displayName = MenubarPrimitive.Item.displayName

const MenubarCheckboxItem = MenubarPrimitive.CheckboxItem

const MenubarRadioItem = MenubarPrimitive.RadioItem

const MenubarLabel = MenubarPrimitive.Label

const MenubarSeparator = MenubarPrimitive.Separator

export {
  Menubar,
  MenubarMenu,
  MenubarTrigger,
  MenubarContent,
  MenubarItem,
  MenubarSeparator,
  MenubarLabel,
  MenubarCheckboxItem,
  MenubarRadioGroup,
  MenubarRadioItem,
  MenubarPortal,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarGroup,
  MenubarSub,
}
