import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import { __ } from '~/shared/i18n'
import { useFormContextExternalOrderReturnInfo } from '../external-order-return-info'
import { Radio, Textfield } from '@imwebme/clay-components'
import { 상수_수거결제타입 } from '~/entities/@x'
import { Controller } from 'react-hook-form'
import { SearchParcelCompany } from '~/shared/components/search-parcel-company'

export function RRT02() {
  return (
    <div className="grid gap-y-[20px]">
      <RetrievePayTypeCdRadio />
      <InvoiceInfo />
    </div>
  )
}

function RetrievePayTypeCdRadio() {
  const { control, setValue } = useFormContextExternalOrderReturnInfo()

  React.useEffect(() => {
    setValue('retrieveRequestDto.retrievePayTypeCd', 상수_수거결제타입.선불)
  }, [])

  return (
    <div>
      <div className="typo-clay-label-medium">{__('요금')}</div>
      <Controller
        name="retrieveRequestDto.retrievePayTypeCd"
        control={control}
        render={({
          field: { value: formValue, onChange, ...rest },
          fieldState: { error },
        }) => (
          <>
            <Radio.Group
              value={formValue ?? ''}
              onChangeValue={onChange}
              direction="row"
              {...rest}
            >
              <Radio.Label
                value={상수_수거결제타입.선불}
                id={상수_수거결제타입.선불}
                label={__('선불')}
              />
              <Radio.Label
                value={상수_수거결제타입.착불}
                id={상수_수거결제타입.착불}
                label={__('착불')}
              />
              <Radio.Label
                value={상수_수거결제타입.신용}
                id={상수_수거결제타입.신용}
                label={__('신용')}
              />
            </Radio.Group>
            {error && (
              <div className="mt-[6px] typo-clay-body-small text-clay-semantic-textCritical">
                {error.message}
              </div>
            )}
          </>
        )}
      />
    </div>
  )
}

function InvoiceInfo() {
  return (
    <div className="semantic-p14">
      <div>{__('송장정보')}</div>
      <div className="mt-[8px]">
        <SearchParcelCompanyComp />
      </div>
      <div className="mt-[8px]">
        <InvoiceNumber />
      </div>
    </div>
  )
}

function SearchParcelCompanyComp() {
  const { control, watch } = useFormContextExternalOrderReturnInfo()
  return (
    <Controller
      name="retrieveRequestDto.parcelCompanyIdx"
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <SearchParcelCompany
          value={value}
          onChange={onChange}
          error={pipe(error, (e) => !!e)}
        />
      )}
    />
  )
}

function InvoiceNumber() {
  const { control, setValue } = useFormContextExternalOrderReturnInfo()
  return (
    <Controller
      name="retrieveRequestDto.invoiceNo"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              {...field}
              placeholder={__('송장번호를 입력해주세요')}
              isValid={error ? false : undefined}
              value={field.value ?? ''}
            />
          </Textfield.Box>
          <Textfield.HelperText className="text-clay-semantic-textCritical">
            {error?.message}
          </Textfield.HelperText>
        </Textfield.Set>
      )}
    />
  )
}
