/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN } from '~/entities/@x'

export const patchResDeliveryHoldDto = z.any()

export const patchPayloadDeliveryHoldDto = z.object({
  orderSectionCode: z.string().describe('섹션 코드'),
  deliveryHoldStatus: z.nativeEnum(YN).describe('배송보류'),
  memo: z
    .string()
    .min(0)
    .max(1000)
    .nullish()
    .describe('관리자메모 1000자 제한'),
})
