import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TGetResExternalOrderClaimInfoDto,
  type TGetParameterExternalOrderClaimInfoDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description {__kebabCaseName__}
 */
export function getExternalOrderClaimInfo({
  orderCode,
}: {
  orderCode: string
}) {
  return function (params: TGetParameterExternalOrderClaimInfoDto) {
    return async function () {
      const result = await axios
        .get<ApiResponse<TGetResExternalOrderClaimInfoDto>>(
          `/external-order/${orderCode}/claim-info`,
          {
            params,
            paramsSerializer: {
              indexes: false,
            },
          }
        )
        .then((res) => res.data)

      return result.data
    }
  }
}
