import React, { PropsWithChildren, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Provider } from 'jotai'
import { store } from '../permission'

export const AuthProvider = (props: PropsWithChildren) => {
  useEffect(() => {
    // sentry 로깅에 사이트정보 추가
    Sentry.setTag('oms_app.site_code', ImwebOAuth2ClientAPI.getInfo().siteCode)
    Sentry.setTag('oms_app.hostname', ImwebOAuth2ClientAPI.getInfo().siteUrl)
  }, [])

  return (
    <Provider store={store}>
      <React.Fragment {...props} />
    </Provider>
  )
}
