import { ISpecification, Spec } from 'spec-pattern-ts'
import { TGoodsflowSchema } from './goodsflow.type'

export class 모델_굿스플로 {
  data: TGoodsflowSchema

  constructor(data: TGoodsflowSchema) {
    this.data = data
  }
}

const 스펙_계약상태: ISpecification<모델_굿스플로> = new Spec(
  (candidate) => candidate.data.length > 0
)

export const 스펙_굿스플로 = {
  계약상태: 스펙_계약상태,
}
