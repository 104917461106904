import { atom, useAtom } from 'jotai'
import type { TItem } from '~t'
import React from 'react'

export const productSearchState = atom<TItem[] | undefined>(undefined)
productSearchState.debugLabel = 'productSearchAtom'

export const useProductSearchAtom = {
  search: () => useAtom(React.useMemo(() => productSearchState, [])),
}
