import * as Tooltip from '@radix-ui/react-tooltip'
import { Clay, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

/**
 * radix-ui 툴팁 스타일 커스텀(with 화살표)
 * @note 클레이 툴팁이 overflow-auto/hidden 속성을 가진 요소에서 가려지는 문제로 커스텀 작성
 */
export function CustomTooltip({
  trigger,
  label,
  asChild,
  side,
}: {
  trigger: React.ReactNode
  label: string
  asChild?: boolean
  side?: 'top' | 'right' | 'bottom' | 'left'
}) {
  return (
    <Tooltip.Provider delayDuration={120}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={asChild}>{trigger}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content sideOffset={4} side={side}>
            <Clay
              padding={`${vars.spacing[15]} ${vars.spacing[2]}`}
              borderRadius={vars.rounded.medium}
              background={vars.semantic.color.surface}
              boxShadow={vars.dropShadow.layer}
            >
              <Typography variant="label-small">{label}</Typography>
            </Clay>
            <Tooltip.Arrow
              css={{
                fill: vars.semantic.color.surface,
                filter: 'drop-shadow(0 0 1px rgba(75, 81, 91, 0.2))',
                clipPath: 'inset(0 -10px -10px -10px)',
              }}
            />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
