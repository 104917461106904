import { atom, useAtom, useSetAtom } from 'jotai'
import { atomWithReset, RESET } from 'jotai/utils'
import { __ } from '~/shared/i18n'

// 타임라인 보기 모드

export type TimelineViewMode = '작게보기_펼침' | '작게보기_접힘' | '우측패널'

const OrderTimelineViewModeAtom =
  atomWithReset<TimelineViewMode>('작게보기_펼침')

export function useTimelineViewMode() {
  return useAtom(OrderTimelineViewModeAtom)
}

// 타임라인 보기 필터

export type TimelineFilter = 'ALL' | 'MEMO' | 'ORDER'

export const TimelineFilterOptions: {
  value: TimelineFilter
  label: string
}[] = [
  { value: 'ALL', label: __('전체') },
  { value: 'MEMO', label: __('메모') },
  { value: 'ORDER', label: __('주문') },
]

const OrderTimelineFilterAtom = atomWithReset<TimelineFilter>('MEMO')

export function useTimelineFilter() {
  return useAtom(OrderTimelineFilterAtom)
}

// 메모 보기 필터

export type MemoFilter = 'ALL' | 'UNRESOLVED' | 'RESOLVED'

export const memoFilterOptions: { value: MemoFilter; label: string }[] = [
  { value: 'ALL', label: __('모든 메모') },
  { value: 'UNRESOLVED', label: __('미해결') },
  { value: 'RESOLVED', label: __('해결됨') },
]

const OrderTimelineMemoAtom = atomWithReset<MemoFilter>('ALL')

export function useTimelineMemoFilter() {
  return useAtom(OrderTimelineMemoAtom)
}

// 모든 아톰 초기화

const resetAllAtom = atom(null, (_, set) => {
  set(OrderTimelineViewModeAtom, RESET)
  set(OrderTimelineFilterAtom, RESET)
  set(OrderTimelineMemoAtom, RESET)
})
export function useResetAllTimelineAtom() {
  return useSetAtom(resetAllAtom)
}
