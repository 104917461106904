import React from 'react'
import { Typography, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { useOnboardingComplete } from '~/container/onboarding/onboarding.store'
import { Trans } from 'react-i18next'

function handleButtonClick() {
  window.open(
    `${
      ImwebOAuth2ClientAPI.getInfo().siteUrl
    }/admin/shopping/order-v1?subPath=${btoa('/onboarding')}`
  )
}

/**
 * OMS 사용 튜토리얼 안내
 */
export function OmsTutorial() {
  const [complete] = useOnboardingComplete()
  const [tooltipOpen, setTooltipOpen] = React.useState(() => !complete)

  return (
    <Typography variant="body-medium" as="div" colorToken="textSub">
      <Trans>
        새로운 주문 관리{' '}
        <Clay
          as="button"
          native={{ onClick: handleButtonClick }}
          color={vars.semantic.color.textAccent}
        >
          튜토리얼
        </Clay>
        을 진행해 보세요
      </Trans>
    </Typography>
  )
}
