import React from 'react'
import { TextButton } from '@imwebme/clay-components'
import { Button } from '@imwebme/clay-components'
import { Dialog } from '~/shared/components/ui/dialog'
import { toast } from 'react-toastify'
import { Select } from '~/shared/components/ui/select'
import { IconChevronDown } from '@imwebme/icon'
import { Minus } from '@imwebme/clay-icons'
import * as z from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Textfield } from '@imwebme/clay-components'
import { ProductCard001Template } from '../../../product'
import { type CProduct } from './order-edit-add-section-row'
import { ProductDiscountDone } from '../../../product/product-discount-done'
import { __ } from '~/shared/i18n'

const DialogExample = ({
  item,
  onSubmit: _onSubmit,
}: {
  item: CProduct
  onSubmit: (e: number) => void
}) => {
  const [open, setOpen] = React.useState(false)
  const [purchasingPrice, setPurchasingPrice] = React.useState(0)
  const [type, setType] = React.useState('static')

  const schema = z.union([
    z.object({
      // type은 값이 있어야한다
      price: z
        .number({
          required_error: __('필수 입력 항목입니다.') || undefined,
          invalid_type_error: __('필수 입력 항목입니다.') || undefined,
        })
        .positive(__('할인 금액은 0보다 커야합니다.') || undefined)
        .lte(
          item.originalPrice || 1000000000000,
          __('할인 금액이 판매가보다 클 수 없습니다.') || undefined
        )
        .int('할인 금액은 정수로 입력해주세요.'),
    }),
    z.object({
      percentagePrice: z
        .number({
          required_error: __('필수 입력 항목입니다.') || undefined,
          invalid_type_error: __('필수 입력 항목입니다.') || undefined,
        })
        .positive(__('할인 금액은 0보다 커야합니다.') || undefined)
        .lte(99, __('할인 금액이 판매가보다 클 수 없습니다.') || undefined)
        .int('할인 금액은 정수로 입력해주세요.'),
    }),
  ])

  const resolver = zodResolver(schema)
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver,
  })

  const price = watch('price')
  const percentagePrice = watch('percentagePrice')

  // 계산기
  React.useEffect(() => {
    if (type !== '' && !price && !percentagePrice) {
      setPurchasingPrice(0)
      return
    }
    if (type === 'static') {
      setPurchasingPrice((item.originalPrice || 0) - price)
    }
    if (type === 'percentage') {
      setPurchasingPrice(
        (item.originalPrice || 0) * (1 - percentagePrice / 100)
      )
    }
  }, [item.originalPrice, type, price, percentagePrice])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <TextButton
          variant="secondary"
          onClick={() => setOpen(true)}
          text={__('판매가 할인')}
          native={{ type: 'button' }}
        />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="sm:max-w-[425px] p-0">
          <form
            onSubmit={handleSubmit(() => {
              // 할인된 가격만 submit으로 넘겨준다.
              _onSubmit((item.originalPrice || 0) - purchasingPrice)
              setOpen(false)
            })}
          >
            <div className="px-[32px] pt-[32px] pb-[20px]">
              <h4 className="semantic-h4-bold">{__('판매가 할인')}</h4>
              <div className="mt-[20px]">
                <ProductCard001Template {...item.discountItem()} />
              </div>
              <div className="mt-[20px]">
                <Select.Root defaultValue="static" onValueChange={setType}>
                  <Select.Trigger className="w-full border-solid">
                    <Select.Value placeholder={__('차감 금액 입력')} />
                    <IconChevronDown />
                  </Select.Trigger>
                  <Select.Content>
                    <Select.Item value="percentage">
                      {__('할인율 적용')}
                    </Select.Item>
                    <Select.Item value="static">
                      {__('차감 금액 입력')}
                    </Select.Item>
                  </Select.Content>
                </Select.Root>
              </div>
              <div className="mt-[8px]">
                <Textfield.Set>
                  {type === 'static' && (
                    <Controller
                      name="price"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Textfield.Box>
                          <Textfield.Addon>
                            <Minus />
                          </Textfield.Addon>
                          <Textfield.Input
                            {...field}
                            type="number"
                            placeholder="0"
                            onChange={(e) =>
                              field.onChange(
                                e.target.value ? Number(e.target.value) : ''
                              )
                            }
                            isValid={errors.price ? false : undefined}
                          />
                          <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
                            {__('{{ currency, currency }}', {
                              currency: item.currency || 'KRW',
                              formatParams: {
                                currency: {
                                  currency: item.currency || 'KRW',
                                },
                              },
                            })}
                          </Textfield.Addon>
                        </Textfield.Box>
                      )}
                    />
                  )}
                  {type === 'percentage' && (
                    <Controller
                      name="percentagePrice"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Textfield.Box>
                          <Textfield.Input
                            {...field}
                            type="number"
                            placeholder="0"
                            onChange={(e) =>
                              field.onChange(
                                e.target.value ? Number(e.target.value) : ''
                              )
                            }
                            isValid={errors.price ? false : undefined}
                          />
                          <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
                            %
                          </Textfield.Addon>
                        </Textfield.Box>
                      )}
                    />
                  )}
                </Textfield.Set>
                {type === 'static' && errors.price && (
                  <div className="mt-[4px] text-clay-semantic-actionCritical">
                    {errors.price.message as string}
                  </div>
                )}
                {type === 'percentage' && errors.percentagePrice && (
                  <div className="mt-[4px] text-clay-semantic-actionCritical">
                    {errors.percentagePrice.message as string}
                  </div>
                )}
              </div>
              <div className="mt-[16px] text-right">
                {!errors.price && purchasingPrice > 0 && (
                  <div>
                    <s className="text-clay-semantic-textDisabled semantic-p12">
                      {__('{{price, 3comma}} {{currency, currency}}', {
                        price: item.originalPrice,
                        formatParams: {
                          currency: {
                            currency: item.currency || 'KRW',
                          },
                        },
                      })}
                    </s>
                  </div>
                )}
                {!errors.percentagePrice && purchasingPrice > 0 && (
                  <div className="semantic-p16">
                    {__('{{price, 3comma}} {{currency, currency}}', {
                      price: purchasingPrice,
                      formatParams: {
                        currency: {
                          currency: item.currency || 'KRW',
                        },
                      },
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="border-t border-clay-semantic-divide px-[32px] py-[20px] grid grid-cols-2 gap-x-[8px]">
              <Dialog.Close asChild>
                <Button
                  native={{ type: 'button' }}
                  variant="outlined"
                  fullWidth
                  text={__('취소')}
                />
              </Dialog.Close>
              <Button
                native={{ type: 'submit' }}
                variant="primary"
                fullWidth
                text={__('반영')}
              />
            </div>
          </form>
          <Dialog.CloseButton />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const OrderEditAddSectionRowDiscount = ({
  item,
  onSubmit: _onSubmit,
}: {
  item: CProduct
  onSubmit: (item: number) => void
}) => {
  const onSubmit = (e: number) => {
    _onSubmit(e)
    toast.success(__('할인이 적용되었어요'))
  }
  return <DialogExample item={item} onSubmit={onSubmit} />
}

export default OrderEditAddSectionRowDiscount
