import {
  Clay,
  Flex,
  SafeImageLoading,
  Tag,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import ProdThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { ChevronDown } from '@imwebme/clay-icons'
import { match, P } from 'ts-pattern'

interface Props {
  imgUrl?: string
  prodName: string
  prodCode: string
  numTotalProd?: number
  numSelectedProd: number
  open: boolean
  toggleOpen: (prodCode: string) => void
  disabled: boolean
}

export function ProdItemHeader({
  imgUrl,
  prodName,
  prodCode,
  numTotalProd,
  numSelectedProd,
  open,
  toggleOpen,
  disabled,
}: Props) {
  return (
    <Flex
      as="button"
      columnGap={vars.spacing[3]}
      alignItems="center"
      native={{ onClick: () => toggleOpen(prodCode), disabled }}
      width="100%"
      padding={'10px 0'}
    >
      <Clay
        width={vars.spacing[12]}
        height={vars.spacing[12]}
        borderRadius={vars.rounded.medium}
        overflow="hidden"
        opacity={disabled ? 0.4 : undefined}
      >
        <SafeImageLoading src={imgUrl} fallback={<ProdThumbFallback />} />
      </Clay>
      <Typography
        variant="label-medium"
        sx={{ flex: 1 }}
        colorToken={disabled ? 'textSub' : 'text'}
      >
        {prodName}
      </Typography>
      {match({ numTotalProd, numSelectedProd })
        .with(
          {
            numSelectedProd: P.number.gt(0),
          },
          () => (
            <Tag
              size="xsmall"
              text={__('{{count}}개 선택됨', {
                count: numSelectedProd,
              })}
              variant="success"
            />
          )
        )
        .with(
          {
            numTotalProd: P.nonNullable,
          },
          (p) => (
            <Tag
              size="xsmall"
              text={__('{{count}}개 품목', {
                count: p.numTotalProd,
              })}
              variant="plain"
            />
          )
        )
        .otherwise(() => (
          <Tag size="xsmall" text={__('옵션 조합 상품')} variant="plain" />
        ))}
      <ChevronDown
        rotate={open ? 180 : undefined}
        colorToken={disabled ? 'icon-disabled' : 'icon-secondary'}
      />
    </Flex>
  )
}
