import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { PaymentListItemDto } from '../../order-detail.type'
import { 상수_결제상태, YN } from '~/entities/@x'

export function mainPayment(payment: PaymentListItemDto) {
  return pipe(payment, (ex) => {
    const result = pipe(
      ex.data,
      A.findFirst(
        ({ isCancel }) =>
          isCancel === YN.N || ex.statusCd === 상수_결제상태.입금전취소
      ),
      O.chain((e) => (e.methodCd !== null ? O.some(e) : O.none))
    )
    return result
  })
}
