export const 상수_구매혜택 = {
  NONE: 'none', // 혜택없음
  DC: 'dc', // 구매 금액 할인
  POINT: 'point', // 추가 적립금 지급
  ALL: 'all', // 할인/적립금 동시 사용
} as const

export type T상수_구매혜택 = (typeof 상수_구매혜택)[keyof typeof 상수_구매혜택]

export const 상수_할인적립방법 = {
  금액: 'price',
  퍼센트: 'percent',
} as const
export type T상수_할인적립방법 =
  (typeof 상수_할인적립방법)[keyof typeof 상수_할인적립방법]

export const 상수_배송비할인 = {
  EXTRA: 'extra', // 도서산간 배송비까지 무료
  BASIC: 'basic', // 기본배송비만 무료
  NONE: 'none', // 혜택 없음
} as const
export type T상수_배송비할인 =
  (typeof 상수_배송비할인)[keyof typeof 상수_배송비할인]
