import { ApiClient } from '~/shared/api'
import { type TGetResMemberDeliveryDto, getResMemberDeliveryDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 회원 배송지 목록 조회
 */
export async function getMemberDelivery(memberCode: string) {
  const result = await axios
    .get<
      ApiResponse<TGetResMemberDeliveryDto>
    >(`/member/${memberCode}/delivery`)
    .then((res) => res.data)

  getResMemberDeliveryDto.parse(result.data)
  return result.data
}
