/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { IMWEB_BANK_CODE } from '~/entities/@x'

/**
 * get pending-cancel-info
 */
const bankTransferAccountsDataSchema = z.object({
  bankCode: z.nativeEnum(IMWEB_BANK_CODE).describe('은행 코드'),
  bankName: z.string().nullish().describe('은행 이름'),
  bankAccount: z.string().nullish().describe('입금 은행 계좌 번호'),
  accountHolderName: z.string().nullish().describe('입금 은행 예금주'),
})

export const getResPendingCancelInfoSchema = z.object({
  bankCancelPrice: z.number().describe('무통장 환불 금액'),
  etcCancelPrice: z.number().describe('무통장 외 환불 금액'),
  bankList: bankTransferAccountsDataSchema
    .array()
    .describe('무통장 환불 계좌 목록'),
})
