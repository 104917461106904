import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { atomFamily } from 'jotai/utils'
import { atom, useAtom } from 'jotai'
import React from 'react'
import type { TItem, T_VIEW_Item } from '../../product-search.type'
import { productSearchItemState } from '../../product-search.store'
import { Product } from '../../product-search-item-class'
import type { payload } from '../../../product-search-item/product-search-item-si/product-search-item-si.type'

// 상품(id)옵션(id)의 상태를 관리하는 atom
export const SIOptionState = atomFamily(
  ({
    searchId,
    prodCode,
    optionKey,
  }: {
    searchId: string
    prodCode: string
    optionKey?: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      // ==================== get ====================
      (get) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        if (prodData === undefined) {
          return undefined
        }
        const prod = new Product(prodData, searchId)
        // 필수 비조합 상품일때는 options를 모두 넘겨줘야한다. 필수로되어있는값만
        if (prod.childComponentType === '단일상품_입력형옵션') {
          const options = pipe(prodData, (e) => e.options)
          // 선택상품은 항상 options가 있다.
          if (options === undefined) {
            return undefined
          }
          const result = pipe(
            options,
            A.filter((e) => e.isRequiredOption)
          )
          return result
        }
        return undefined
      },
      // ==================== set ====================
      // 주문서전체 데이터에서 prodCode, optionCode, valueCode를 찾아서 해당 value를 업데이트한다.
      (get, set, arg: payload[]) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        const prod = new Product(prodData, searchId)
        if (prod.childComponentType === '단일상품_입력형옵션') {
          set(productSearchItemAtom, {
            ...prodData,
            siOptions: [...(prodData.siOptions || []), ...arg],
          })
        }
      }
    )
    at.debugLabel = `search-product(${prodCode})-si(${optionKey})`
    return at
  },
  (a, b) =>
    a.searchId === b.searchId &&
    a.prodCode === b.prodCode &&
    a.optionKey === b.optionKey
)

export const SIOptionValuesState = atomFamily(
  ({
    searchId,
    prodCode,
    optionKey,
  }: {
    searchId: string
    prodCode: string
    optionKey?: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      // ==================== get ====================
      (get) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        if (prodData === undefined) {
          return undefined
        }
        return prodData.siOptions as payload[]
      },
      // ==================== set ====================
      (get, set, arg: payload[]) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        if (prodData === undefined) {
          return
        }
        set(productSearchItemAtom, { ...prodData, siOptions: arg })
      }
    )
    at.debugLabel = `search-product(${prodCode})-si-values(${optionKey})`
    return at
  },
  (a, b) =>
    a.searchId === b.searchId &&
    a.prodCode === b.prodCode &&
    a.optionKey === b.optionKey
)

export const useSIOptionAtom = {
  option: (
    searchId: string,
    prodCode: string,
    optionKey?: string
    // valueCode: string
  ) =>
    useAtom(
      React.useMemo(
        () =>
          SIOptionState({
            searchId,
            prodCode,
            optionKey,
          }),
        [prodCode, searchId, optionKey]
      )
    ),
  values: (
    searchId: string,
    prodCode: string,
    optionKey?: string
    // optionCode: string,
    // valueCode: string
  ) =>
    useAtom(
      React.useMemo(
        () =>
          SIOptionValuesState({
            searchId,
            prodCode,
            optionKey,
          }),
        [prodCode, searchId, optionKey]
      )
    ),
}
