import { queryOptions } from '@tanstack/react-query'
import {
  TPostPayloadBulkActionSectionInvoicePrintValidateAllSchema,
  postBulkActionSectionInvoicePrintValidateAll,
} from '.'

export function bulkActionSectionInvoicePrintValidateAllQueryOptions(
  p: TPostPayloadBulkActionSectionInvoicePrintValidateAllSchema
) {
  return queryOptions({
    queryKey: ['bulk-action', 'section-invoice-print-validate-all'],
    queryFn: () =>
      postBulkActionSectionInvoicePrintValidateAll({
        body: p,
      }),
  })
}
