import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as math from 'mathjs'
import * as R from 'fp-ts/Record'
import { IconTrash03 } from '@imwebme/icon'
import type { payload } from './product-search-item-rnco.type'
import { __ } from '~/shared/i18n'
import { useCurrency } from '~/shared/hooks/use-currency'

function ProductSearchOptionItemRNCOcombined({
  optionName,
  optionValueName,
  optionValue,
}: {
  // optionCode: string
  optionName: string
  // optionValueCode?: string
  optionValueName?: string
  optionValue?: string
}) {
  return (
    <>
      <span>{optionName}</span>{' '}
      {optionValue ? (
        <span>{optionValue}</span>
      ) : (
        <span>{optionValueName}</span>
      )}
    </>
  )
}

export function ProductSearchItemRNCOValues({
  productPrice,
  values,
  setValues,
}: {
  productPrice: number
  values?: payload[]
  setValues: (e: payload[]) => void
}) {
  const currency = useCurrency()
  if (values === undefined) {
    return null
  }
  return (
    <div className="mt-[20px] flex flex-col gap-y-[12px] typo-clay-body-medium">
      {pipe(
        values,
        A.mapWithIndex((i, r) => {
          const price = pipe(
            r,
            R.map((r2) => Number(r2.price || 0)),
            Object.values,
            A.reduce(0, (acc, cur) => acc + cur),
            (e) => math.chain(e).add(productPrice).done()
          )
          return (
            <div
              data-rnco-value
              className="flex justify-between gap-x-[16px] py-[6px]"
              key={`rnco-options-${i}`}
            >
              <div
                key={`select-item-${i}`}
                className="flex items-center gap-x-[8px]"
              >
                <button
                  type="button"
                  onClick={() => {
                    const assginValues = [...values]
                    assginValues.splice(i, 1)
                    setValues(assginValues)
                  }}
                  className="p-[6px]"
                >
                  <IconTrash03 />
                </button>
                <div className="line-clamp-1">
                  {pipe(
                    r,
                    Object.entries,
                    A.map((e) => ({ id: e[0], value: e[1] })),
                    A.mapWithIndex((ii, e) => (
                      <span key={e.id}>
                        <ProductSearchOptionItemRNCOcombined {...e.value} />{' '}
                        {ii < pipe(r, Object.entries, (t) => t.length) - 1
                          ? ' · '
                          : ''}
                      </span>
                    ))
                  )}
                </div>
              </div>
              <div className="typo-clay-body-medium self-center">
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
