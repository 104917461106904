import React from 'react'
import { IconMuReady, IconMuSpinner, IconSearchSm } from '@imwebme/icon'
import { Controller, useForm } from 'react-hook-form'
import { Virtuoso } from 'react-virtuoso'
import { TItem } from './product-search.type'
import { ProductSearchItemContainer } from './product-search-item.container'
import { __ } from '~/shared/i18n'
import { match, P } from 'ts-pattern'
import { Loading } from '~/shared/components/loading'
import { Textfield } from '@imwebme/clay-components'
import { SearchSM } from '@imwebme/clay-icons'

const LoadTriggerDom = () => (
  <div>
    <IconMuSpinner className="animate-spin text-[#717680]" />
  </div>
)

const Footer = ({ children }: React.PropsWithChildren) => (
  <div
    style={{
      padding: '2rem',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {children}
  </div>
)

export interface ProductSearchProps {
  searchId: string
  items: TItem[]
  onInViewCallback?: () => void
  onInViewDisabled?: boolean
  onSearchSubmit?: (searchValue: string) => void
  isLoading: boolean
}
export function ProductSearch({
  searchId,
  items,
  onInViewCallback,
  onInViewDisabled,
  onSearchSubmit,
  isLoading,
}: ProductSearchProps) {
  const { register, handleSubmit, control } = useForm()

  return (
    <div className="h-full grid grid-rows-[auto,1fr] gap-y-[16px]">
      <form
        onSubmit={handleSubmit((e) => onSearchSubmit?.(e.productSearch))}
        className="mx-[32px]"
      >
        <Controller
          control={control}
          name="productSearch"
          render={({ field }) => (
            <Textfield.Box>
              <Textfield.Addon>
                <SearchSM />
              </Textfield.Addon>
              <Textfield.Input {...field} placeholder={__('상품 검색')} />
            </Textfield.Box>
          )}
        />
      </form>
      <div className="h-full overflow-auto">
        {match({ isLoading, items })
          .with({ isLoading: true }, () => (
            <Loading
              data-id="add product modal"
              className="h-full"
              text={__('상품을 불러오고 있어요')}
            />
          ))
          .with({ items: P.when((_items) => _items.length === 0) }, () => (
            <div className="h-full flex justify-center items-center">
              <div className="text-center">
                <div className="flex justify-center items-center">
                  <IconMuReady />
                </div>
                <div className="mt-[14px] typo-clay-body-medium text-clay-semantic-text">
                  {__('검색어와 일치하는 상품이 없어요')}
                </div>
                <div className="mt-[8px] typo-clay-body-small text-clay-semantic-textSub">
                  {__('검색어를 확인해 주세요')}
                </div>
              </div>
            </div>
          ))
          .otherwise(() => (
            <Virtuoso
              className="h-full"
              data={items}
              endReached={() => {
                if (onInViewDisabled) {
                  return
                }
                onInViewCallback?.()
              }}
              overscan={100}
              itemContent={(_, item) => (
                <li
                  key={item.prodCode}
                  className="grid first:mt-0 mt-4"
                  data-id={item.prodCode}
                >
                  <div className="mx-[32px]">
                    {/* ProductSearchItemCheckBox 내부에서 분기해서 여러 컴포넌트를 렌더할 수 있기 때문에 메모제이션하지 않는다. */}
                    <ProductSearchItemContainer {...item} searchId={searchId} />
                  </div>
                </li>
              )}
              components={{
                Footer: () => (
                  <Footer>
                    {/* 첫 검색에는 무조건 있을것이기 때문에 item의 갯수가 있을때부터 로딩이 되도록한다. */}
                    {items.length > 0 && !onInViewDisabled && (
                      <div className="flex justify-center">
                        <LoadTriggerDom />
                      </div>
                    )}
                  </Footer>
                ),
              }}
            />
          ))}
      </div>
    </div>
  )
}
