import { useFormContext, Controller } from 'react-hook-form'
import { Radio, Typography } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'

export function RadioField({ index, formConfigValue }: TFieldProps) {
  const { control, register } = useFormContext<TOrderFormDataEditFormSchema>()
  return (
    <>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />
      <Controller
        name={`form.${index}.value`}
        control={control}
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <>
            <Radio.Group
              direction="column"
              value={value[0] ?? ''}
              onChangeValue={(v) => onChange([v])}
              {...rest}
            >
              {formConfigValue.map((config) => (
                <Radio.Label label={config} value={config} key={config} />
              ))}
            </Radio.Group>
            {error && (
              <Typography className="mt-clay-15 typo-clay-body-small text-clay-semantic-textCritical">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
    </>
  )
}
