import { ApiClient } from '~/shared/api'

const axiosInstance = ApiClient()

// 주문 상세 조회
/**
 * @deprecated 'src/features/order-detail'로 대체
 */
export const getOrderDetailData = async (orderCode: string) => {
  const ENDPOINT = `/order/${orderCode}`
  const { data } = await axiosInstance({
    url: ENDPOINT,
    method: 'get',
  })

  return data
}
