import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { IconTrash01 } from '@imwebme/icon'
import { Button } from '@imwebme/clay-components'
import { IconButton } from '@imwebme/clay-components'

type TIngState = 'ing' | 'error' | 'default'

export interface TDragDropFileItem {
  name: string
  size: string | null
  onDelete?: () => void
}
export function DragDropFileItem({ name, size, onDelete }: TDragDropFileItem) {
  const [isHover, setIsHover] = React.useState(false)
  const [deleteMap, setDeleteMap] = React.useState<TIngState>('default')
  function mouseEnter() {
    setIsHover(true)
  }
  function mouseLeave() {
    setIsHover(false)
  }

  function onClickDelete() {
    if (onDelete == null) {
      return
    }
    setDeleteMap('ing')
    onDelete()
  }

  const disabled = deleteMap === 'ing'
  return (
    <div className="p-clay-1 rounded-clay-medium bg-clay-semantic-surfaceSub ">
      <div
        className="py-clay-1 px-clay-3 relative"
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <div className="typo-clay-body-medium line-clamp-1 break-all">
          {name}
        </div>
        <div className="typo-clay-body-medium text-clay-semantic-textSub">
          {size}
        </div>

        <CSSTransition
          in={isHover}
          timeout={300}
          className="animate-fade [&.exit]:animate-reverse absolute right-clay-3 top-1/2 -translate-y-1/2"
          unmountOnExit
          appear
        >
          <IconButton
            native={{ type: 'button' }}
            variant="critical"
            size="small"
            isDisabled={disabled}
            onClick={onClickDelete}
            icon={<IconTrash01 />}
            isLoading={deleteMap === 'ing'}
          />
        </CSSTransition>
      </div>
    </div>
  )
}
