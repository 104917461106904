import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { TExportExcelTargetRadio } from './export-excel-target-radio.type'

const ExportExcelTargetRadioState = atom<TExportExcelTargetRadio | null>(null)
ExportExcelTargetRadioState.debugLabel = 'ExportExcelTargetRadioState'

export const useExportExcelTargetRadioAtom = {
  state: () => useAtom(ExportExcelTargetRadioState),
  get: () => useAtomValue(ExportExcelTargetRadioState),
  set: () => useSetAtom(ExportExcelTargetRadioState),
}
