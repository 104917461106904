import React from 'react'
import {
  Button,
  ContentsBanner,
  Flex,
  Radio,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SafeAvatar } from '~/shared/components/safe-avatar'
import { __ } from '~/shared/i18n'
import { FieldLayout } from '../partials/field-layout'
import { useOrderInfoAtom } from '../order-create.store'
import { useSuspenseQuery } from '@tanstack/react-query'
import { shopConfigQueryOptions } from '~/entities/site/shop-config'
import { YN } from '~/entities/@x'
import { useModals } from '~/router'
import { useMemberSelectModalAtom } from '~/widgets/member-select-modal/member-select-modal.store'
import { TGetResMembersItemDto } from '~/entities/site/members'
import { toast } from 'react-toastify'
import { SearchSM } from '@imwebme/clay-icons'

/** 회원 정보 필드 */
export function MemberInfoField() {
  const [orderInfo, setOrderInfoAtom] = useOrderInfoAtom()
  const isMember = React.useMemo(
    () => !!orderInfo._isMember,
    [orderInfo._isMember]
  )
  const { data: nomemberOrderNoCk } = useSuspenseQuery({
    ...shopConfigQueryOptions(),
    select(data) {
      return data.nomemberOrderNoCk === YN.Y
    },
  })

  return (
    <FieldLayout title={__('회원 정보')}>
      <Flex.Column rowGap={vars.spacing[3]}>
        <Flex columnGap={vars.spacing[6]}>
          <Radio.Label
            label={__('회원')}
            name="member"
            onChangeValue={() => {
              setOrderInfoAtom({
                _isMember: true,
                ordererName: undefined,
                ordererCall: undefined,
                ordererEmail: undefined,
              })
            }}
            checked={isMember}
          />
          <Radio.Label
            label={__('비회원')}
            name="member"
            onChangeValue={() => {
              setOrderInfoAtom({
                _isMember: false,
                memberCode: undefined,
                _account: undefined,
                _profileImgUrl: undefined,
                _memberName: undefined,
                _memberCall: undefined,
                _memberEmail: undefined,
                ordererName: undefined,
                ordererCall: undefined,
                ordererEmail: undefined,
              })
            }}
            checked={!isMember}
          />
        </Flex>
        {isMember ? (
          <Member />
        ) : (
          <NonMember nomemberOrderNoCk={nomemberOrderNoCk} />
        )}
      </Flex.Column>
    </FieldLayout>
  )
}

function Member() {
  const [orderInfo, setOrderInfo] = useOrderInfoAtom()
  const [_, setMemberSelectModal] = useMemberSelectModalAtom()
  const modals = useModals()

  const openMemberSelectModal = React.useCallback(() => {
    setMemberSelectModal({
      handleSelectMember: (member: TGetResMembersItemDto) => {
        if (orderInfo.memberCode === member.code) {
          return
        }

        setOrderInfo({
          memberCode: member.code,
          _account: member.account,
          _profileImgUrl: member.profileImgUrl || undefined,
          _memberName: member.name || undefined,
          _memberCall: member.callnum?.replace(/[^0-9]/g, '') || undefined,
          _memberEmail: member.email || undefined,
          ordererEmail: member.email || '',
          ordererName: member.name || '',
          ordererCall: member.callnum?.replace(/[^0-9]/g, '') || '',
        })

        toast.success(
          orderInfo.memberCode
            ? __('회원 정보가 변경됐어요')
            : __('회원 정보가 추가됐어요')
        )
      },
      curMemberCode: orderInfo.memberCode,
    })
    modals.open('/:unitCode/members', {
      params: { unitCode: orderInfo.unitSite?.code as string },
    })
  }, [orderInfo])

  return (
    <>
      {orderInfo.memberCode ? (
        <Flex alignItems="center" columnGap={vars.spacing[3]}>
          <SafeAvatar
            size="small"
            src={orderInfo._profileImgUrl ?? undefined}
          />
          <Flex.Column rowGap={vars.spacing['05']} flex={1}>
            <Typography
              variant="label-medium-bold"
              sx={{ wordBreak: 'break-all' }}
            >
              {orderInfo._account}
            </Typography>
            {(orderInfo._memberName || orderInfo._memberCall) && (
              <Typography variant="body-small" sx={{ wordBreak: 'break-all' }}>
                {orderInfo._memberName || orderInfo._memberName}
                {orderInfo._memberCall ? `·${orderInfo._memberCall}` : ''}
              </Typography>
            )}
          </Flex.Column>
          <Button
            text={__('변경')}
            size="small"
            variant="outlined"
            onClick={() => openMemberSelectModal()}
          />
        </Flex>
      ) : (
        <Flex columnGap={vars.spacing[25]}>
          <Textfield.Box variant="filled" size="medium">
            <Textfield.Addon>
              <SearchSM colorToken="icon-sub" />
            </Textfield.Addon>
            <Textfield.Input
              placeholder={__('회원 이름 또는 이메일을 입력해 주세요')}
              type="search"
              onFocus={openMemberSelectModal}
            />
          </Textfield.Box>
          <Button
            variant="outlined"
            text={__('찾아보기')}
            onClick={() => openMemberSelectModal()}
            sx={{ flexShrink: 0 }}
          />
        </Flex>
      )}
    </>
  )
}

function NonMember({ nomemberOrderNoCk }: { nomemberOrderNoCk: boolean }) {
  return (
    <>
      {nomemberOrderNoCk && (
        <ContentsBanner
          variant="critical"
          type="secondary"
          text={__(
            '비회원 주문 불가 설정돼 있으면 주문 생성 후 구매자가 주문 정보를 확인할 수 없어요.'
          )}
        />
      )}
    </>
  )
}
