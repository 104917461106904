import { queryOptions } from '@tanstack/react-query'
import { getOrderSearchTab } from './order-search-tab.api'

export function orderSearchTabQueryOptions() {
  return queryOptions({
    queryKey: ['order-search-tab'],
    queryFn: getOrderSearchTab(),
    staleTime: 1000 * 10,
  })
}
