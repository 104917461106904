import {
  type TProductCard001,
  OrderEditAddSectionContainer,
  useOrderSectionAtom,
  ProductSearchContainerMemo,
  useProductSearchAtom,
} from '~t'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import React from 'react'
import withProductSearch from '~/container/product-search/product-search'
import { useOrderAddConfirmAtom } from '~/container/order/order-add/order-add.store'
import { __, __g } from '~/shared/i18n'
import { ButtonGroup } from '@imwebme/clay-components'
import { Button } from '@imwebme/clay-components'
import { Dialog } from '~/shared/components/ui/dialog'

const SearchProduct = React.memo(withProductSearch(ProductSearchContainerMemo))

const OrderSectionContainerOverlay = ({
  orderCode,
  id,
  label,
  statusCd,
  memberCode,
  orderType,
  items: _items,
}: {
  orderCode: string
  id: string
  label: string
  statusCd: string
  memberCode: string
  orderType: string
  items?: TProductCard001.props[]
}) => {
  const [open, setOpen] = React.useState(false)

  // ==========================================
  // 상태값
  // ==========================================
  const searchKey = `${id}-search`
  const selectedItemsToProductCard =
    useProductSearchAtom.selectedItemsToTProductCard001(searchKey)
  const selectedItemsConfirm =
    useProductSearchAtom.selectedItemsToConfirm(searchKey)
  const selectedCount = useProductSearchAtom.allSelectedCount(searchKey)
  const [, setConfrimData] = useOrderAddConfirmAtom.section(orderCode, id)
  const [confirmData, deleteSetConfrimData] = useOrderAddConfirmAtom.delete(
    orderCode,
    id
  )
  const [items, setItems] = useOrderSectionAtom.section(id)

  // ==========================================
  // 상태 액션
  // ==========================================
  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleAddItems = () => {
    if (selectedItemsToProductCard === undefined) {
      return undefined
    }
    pipe(
      selectedItemsToProductCard,
      A.map((e) => ({
        ...e,
        qty: 1,
        qtyChange: 1,
        isNew: true,
        originalPrice: e.originalPrice,
        currency: 'KRW',
      })),
      setItems
    )
    // 추가된 상품에 대한 데이터를 담고있는 상태값
    if (selectedItemsConfirm === undefined) {
      return undefined
    }
    pipe(
      selectedItemsConfirm,
      setConfrimData
      // O.fromNullable,
      // O.fold(() => undefined, flow(setConfrimData)),
    )
    return undefined
  }

  const handleResetItems = () => {
    pipe(
      items,
      O.fromNullable,
      O.fold(
        () => undefined,
        (e) => {
          pipe(
            e.value,
            O.fromNullable,
            O.fold(
              () => undefined,
              flow(
                A.filter((x) => x.isNew === false),
                A.map((x) => ({ ...x, qtyChange: 0 })),
                (x) =>
                  setItems({
                    ...e,
                    value: x,
                  })
              )
            )
          )
        }
      )
    )
    deleteSetConfrimData()
  }

  const handleDeleteItem = (item: TProductCard001.props) => {
    pipe(
      items?.value,
      O.fromNullable,
      O.fold(
        () => undefined,
        (e) => {
          pipe(
            e,
            A.findIndex((x) => x.id === item.id),
            O.getOrElse(() => -1),
            O.fromPredicate((x) => x !== -1),
            O.fold(
              () => undefined,
              (r) => {
                pipe(
                  e,
                  A.deleteAt(r),
                  O.fold(
                    () => undefined,
                    (y) =>
                      setItems({
                        ...items,
                        value: y,
                      })
                  )
                )
              }
            )
          )
        }
      )
    )
    // item에 아이디를 가지고 od, s 체크해야한다.
    let type = ''
    // 필수조합상품
    if (item.id.split(':').length === 1 && item.id.startsWith('od')) {
      type = '필수조합상품'
    }
    // 단일상품
    if (item.id.split(':').length === 1 && item.id.startsWith('s')) {
      type = '단일상품'
    }
    if (item.id.split(':').length > 1 && item.id.startsWith('OR_')) {
      type = '필수비조합'
    }
    if (item.id.split(':').length > 1) {
      type = '선택옵션'
    }
    // 필수비조합
    pipe(
      confirmData?.prods,
      O.fromNullable,
      O.fold(
        () => [],
        flow(
          A.filter((x) => {
            if (type === '선택옵션') {
              const [, prodValueCode] = item.id.split(':')
              return pipe(
                x.optionDataList,
                O.fromNullable,
                O.fold(
                  () => false,
                  flow(
                    A.findFirst((y) => y.valueCode === prodValueCode),
                    O.fold(
                      () => false,
                      () => true
                    )
                  )
                )
              )
            }
            if (type === '필수조합상품') {
              return x.prodOptionDetailCode === item.id
            }
            if (type === '단일상품') {
              if (x.optionDataList) {
                return false
              }
              return x.prodCode === item.id
            }
            if (type === '필수비조합') {
              return pipe(
                x.optionDataList,
                O.fromNullable,
                O.fold(
                  () => false,
                  flow(
                    A.findFirst(
                      (y) =>
                        `RNCO=${y.optionCode}:${y.valueCode}` === item.id ||
                        y.optionCode === item.id
                    ),
                    O.fold(
                      () => false,
                      () => true
                    )
                  )
                )
              )
            }

            return x.prodCode !== item.id
          })
        )
      ),
      (e) => {
        log.debug(e)
        return e
      },
      deleteSetConfrimData
    )
  }

  // ==========================================
  // 렌더링
  // ==========================================
  return (
    <>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="backdrop-blur-none bg-clay-semantic-backdrop bg-opacity-75" />
          <Dialog.Content className=" min-w-[540px] rounded-[16px] bg-clay-semantic-surface px-0 dark:bg-clay-semantic-surface p-0 gap-0">
            <Dialog.Header className="px-[32px] pt-[32px] pb-[20px]">
              <Dialog.Title className="semantic-h4-bold dark:text-clay-semantic-text">
                {__('상품추가')}
              </Dialog.Title>
            </Dialog.Header>
            <div className="h-[540px]">
              {/* TODO: search 상태를 template와 공유 할 수 없는 문제 */}
              <SearchProduct
                isOpen={open}
                searchId={searchKey}
                memberCode={memberCode}
                orderType={orderType}
              />
            </div>
            <Dialog.Footer className="px-[32px] py-[20px] border-t border-clay-semantic-divide">
              <ButtonGroup>
                <Dialog.Close asChild>
                  <Button
                    native={{ type: 'button' }}
                    variant="outlined"
                    text={__('취소')}
                    fullWidth
                  />
                </Dialog.Close>
                <Dialog.Close asChild>
                  <Button
                    native={{ type: 'button' }}
                    variant="primary"
                    onClick={handleAddItems}
                    isDisabled={selectedCount === undefined}
                    text={__('{{count}}개 상품추가', {
                      count: selectedCount?.count || 0, // selectedCount.count,
                    })}
                    fullWidth
                  />
                </Dialog.Close>
              </ButtonGroup>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <OrderEditAddSectionContainer
        id={id}
        label={label}
        onSearchModal={handleOpenModal}
        onReset={handleResetItems}
        onDeleteItem={handleDeleteItem}
        items={_items || []}
        title={__g(statusCd)}
      />
    </>
  )
}

export default OrderSectionContainerOverlay
