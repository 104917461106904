import React from 'react'
import { TItem } from '../../product-search.type'
import { RCOOptionContainer } from './rco-option.container'

/**
 * 필수비조합형
 */
export const RcoOption = ({
  productData,
  searchId,
  optionKey,
}: {
  productData: TItem
  searchId: string
  optionKey: string
}) => (
  <>
    <RCOOptionContainer
      productData={productData}
      searchId={searchId}
      optionKey={optionKey}
      data-type="rco-product"
      {...productData}
    />
    {/* 여기에 선택옵션 렌더링해야한다. */}
    <div />
  </>
)

export const RcoOptionMemo = React.memo(RcoOption)
