import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { SetStateAction, atom, useAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import type { TExportTemplateTabItem } from './export-template-tab-item.type'
import { orderExportFormTabState } from '../export-template-tab.store'
import React from 'react'

const orderExportFormTabItemState = atomFamily(
  ({ id }: { id: string }) => {
    const tabItemsAtom = atom(
      (get) => {
        const tabItem = get(orderExportFormTabState)
        return pipe(
          tabItem.forms,
          O.fromNullable,
          O.fold(
            () => null,
            flow(
              A.findFirst((item) => item.id === id),
              O.fold(
                () => null,
                (item) => item
              )
            )
          )
        )
      },
      (get, set, update: SetStateAction<TExportTemplateTabItem>) => {
        const tabItems = get(orderExportFormTabState)
        // item을 찾는다.
        const item = pipe(
          tabItems.forms,
          O.fromNullable,
          O.fold(
            () => null,
            flow(
              A.findFirst((_item) => _item.id === id),
              O.fold(
                () => null,
                (_item) => _item
              )
            )
          )
        )
        if (item === null) {
          return
        }
        if (typeof update === 'function') {
          set(orderExportFormTabState, (prev) => {
            const updatedItem = update(item)
            // prev에서 item을 업데이트한다
            const updatedItems = pipe(
              prev.forms,
              O.fromNullable,
              O.fold(
                () => null,
                flow(
                  A.map((e) => {
                    if (e.id === id) {
                      return updatedItem
                    }
                    e.active = false
                    return e
                  })
                )
              )
            )
            if (updatedItems === null) {
              return prev
            }
            return {
              ...prev,
              forms: updatedItems,
            }
          })
          return
        }
        const updatedItems = pipe(
          tabItems.forms,
          O.fromNullable,
          O.fold(
            () => null,
            flow(
              A.map((e) => {
                if (e.id === id) {
                  return update
                }
                e.active = false
                return e
              })
            )
          )
        )
        if (updatedItems === null) {
          return
        }
        set(orderExportFormTabState, {
          ...tabItems,
          forms: updatedItems,
        })
      }
    )
    tabItemsAtom.debugLabel = `orderExportFormTabItemState-${id}`
    return tabItemsAtom
  },
  (a, b) => a.id === b.id
)

export const useExportTemplateTabItemAtom = {
  getItem: (id: string) =>
    useAtom(React.useMemo(() => orderExportFormTabItemState({ id }), [id])),
}
