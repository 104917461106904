import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { IconTrash03 } from '@imwebme/icon'
import type { payload } from './product-search-item-rco.type'
import { __ } from '~/shared/i18n'
import { useCurrency } from '~/shared/hooks/use-currency'

function ProductSearchOptionItemRNCOcombined({
  optionName,
  optionValueName,
  optionValue,
}: {
  // optionCode: string
  optionName: string
  // optionValueCode?: string
  optionValueName?: string
  optionValue?: string
}) {
  return (
    <>
      <span>{optionName}</span>{' '}
      {optionValue ? (
        <span>{optionValue}</span>
      ) : (
        <span>{optionValueName}</span>
      )}
    </>
  )
}

export function ProductSearchItemRCOValues({
  productPrice,
  values,
  setValues,
}: {
  productPrice: number
  values?: payload[]
  setValues: (e: payload[]) => void
}) {
  const currency = useCurrency()
  if (values === undefined) {
    return null
  }
  const price = pipe(
    values,
    A.map(
      flow(
        R.map((r) => Number(r.price || 0)),
        Object.values,
        A.reduce(0, (acc, cur) => acc + cur)
      )
    ),
    A.reduce(0, (acc, cur) => acc + cur),
    (e) => e + productPrice
  )

  return (
    <div className="mt-[20px] flex flex-col gap-y-[12px] typo-clay-body-medium">
      {pipe(
        values,
        A.mapWithIndex((i, r) => (
          <div
            className="flex justify-between gap-x-[16px] py-[6px]"
            key={`rnco-options-${i}`}
          >
            <div
              key={`select-item-${i}`}
              className="flex items-center gap-x-[8px]"
            >
              <button
                type="button"
                onClick={() => {
                  const assginValues = [...values]
                  assginValues.splice(i, 1)
                  setValues(assginValues)
                }}
                className="p-[6px]"
              >
                <IconTrash03 />
              </button>
              <div className="line-clamp-1">
                {pipe(
                  r.optionDetails,
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      R.map((e) =>
                        // 옵션명 만들기
                        pipe(
                          e.options,
                          A.reduce(
                            '',
                            (acc, cur) =>
                              `${acc + cur.optionName} ${cur.valueName} · `
                          )
                        )
                      ),
                      Object.values,
                      A.reduce('', (acc, cur) => acc + cur),
                      (e) => e.slice(0, -3)
                    )
                  )
                )}
                {pipe(
                  r.options,
                  Object.entries,
                  A.map((e) => ({ id: e[0], value: e[1] })),
                  A.mapWithIndex((ii, e) => (
                    <>
                      {ii === 0 ? ' · ' : ''}
                      <span key={e.id}>
                        <ProductSearchOptionItemRNCOcombined {...e.value} />{' '}
                      </span>
                      {ii < pipe(r.options, Object.entries, (t) => t.length) - 1
                        ? ' · '
                        : ''}
                    </>
                  ))
                )}
              </div>
            </div>
            <div className="typo-clay-body-medium self-center whitespace-nowrap">
              {__('{{price, 3comma}} {{currency, currency}}', {
                price,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })}
            </div>
          </div>
        ))
      )}
    </div>
  )
}
