import { ApiClient } from '~/shared/api'
import type { TGetResOrderHistorySchema } from './order-history.type'
import { getResOrderHistorySchema } from './order-history.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/T7U5Eu
 * @description 주문 히스토리
 */
export async function getOrderHistory(orderCode: string) {
  const result = await axios
    .get<
      ApiResponse<TGetResOrderHistorySchema>
    >(`/order/order-history/${orderCode}`)
    .then((res) => res.data)

  getResOrderHistorySchema.parse(result.data)
  return result
}
