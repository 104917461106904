import { __ } from '~/shared/i18n'
import { StepLayout } from '../partials/step-layout'
import { Button, ButtonGroup, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  useActiveStepIdxAtom,
  useProdSelectIsValid,
  useProdSelectAtom,
  useSelectedProdCommons,
  useDeliverySetupAtom,
  useOrderInfoAtom,
} from '../order-create.store'
import { match, P } from 'ts-pattern'
import { SearchBar } from './search-bar'
import { EmptyProd } from './empty-prod'
import { ProdListEditable } from './prod-list-editable'
import { ProdListStatic } from './prod-list-static'
import { getDefaultDelivPayType } from '../order-create.fn'
import { useIdle } from '../use-idle'
import { StepProdSelectSkeleton } from '../partials/step-prod-select-skeleton'
import { useQueryClient } from '@tanstack/react-query'
import { memberDiscountInfoQueryOptions } from '~/entities/member-discount-info'

const STEP_IDX = 1 as const

export function StepProdSelect() {
  const [activeStepIdx] = useActiveStepIdxAtom()
  const isIdle = useIdle(STEP_IDX)

  return match(activeStepIdx)
    .with(P.number.gt(STEP_IDX), () => <CompleteStatusView isIdle={isIdle} />)
    .with(STEP_IDX, () => <CurrentStatusView />)
    .with(P.number.lt(STEP_IDX), () => <IncompleteStatusView />)
    .otherwise(() => null)
}

function CurrentStatusView() {
  const [_activeStepIdx, setActiveStepIdx] = useActiveStepIdxAtom()
  const [_deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const [prodSelect] = useProdSelectAtom()
  const prodCommons = useSelectedProdCommons()
  const isValid = useProdSelectIsValid()
  const queryClient = useQueryClient()
  const [orderInfo] = useOrderInfoAtom()
  const currency = orderInfo.unitSite?.currency || 'KRW'

  return (
    <StepLayout stepIdx={STEP_IDX} status="current">
      <Clay
        paddingTop={vars.spacing[6]}
        borderRadius={vars.rounded.large}
        background={vars.semantic.color.surface}
      >
        <SearchBar />
        {match(prodSelect.length)
          .with(0, () => <EmptyProd />)
          .otherwise(() => (
            <ProdListEditable />
          ))}
      </Clay>

      <ButtonGroup
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="secondary"
          text={__('이전')}
          onClick={() => setActiveStepIdx((prev) => prev - 1)}
        />
        <Button
          isDisabled={!isValid}
          variant="primary"
          text={__('다음')}
          onClick={() => {
            setActiveStepIdx((prev) => prev + 1)

            // 지정발행 쿠폰 발급을 위해 사전 페치
            queryClient.prefetchQuery({
              ...memberDiscountInfoQueryOptions({
                memberCode: orderInfo.memberCode as string,
                params: {
                  currency,
                  itemPrice: prodSelect.reduce(
                    (acc, cur) => acc + cur.itemPrice * cur.qty,
                    0
                  ),
                  prodCode: prodSelect.map((el) => el.prodCode),
                },
              }),
              gcTime: 0,
            })

            // 배송방식, 배송비 초기값 설정
            const delivTypeCd = prodCommons.delivTypeList[0]
            const delivPayTypeCd = getDefaultDelivPayType(
              delivTypeCd,
              prodCommons.delivPayTypeList
            )
            setDeliverySetup({
              _deliveryTypeCd: delivTypeCd,
              _deliveryPayTypeCd: delivPayTypeCd,
            })
          }}
        />
      </ButtonGroup>
    </StepLayout>
  )
}

function CompleteStatusView({ isIdle }: { isIdle: boolean }) {
  return (
    <StepLayout stepIdx={STEP_IDX} status="complete">
      {isIdle ? (
        <StepProdSelectSkeleton />
      ) : (
        <Clay
          paddingTop={vars.spacing[2]}
          borderRadius={vars.rounded.large}
          background={vars.semantic.color.surface}
        >
          <ProdListStatic />
        </Clay>
      )}
    </StepLayout>
  )
}

function IncompleteStatusView() {
  return <StepLayout stepIdx={STEP_IDX} status="incomplete"></StepLayout>
}
