import axios from 'axios'
import * as Sentry from '@sentry/react'
import { z } from 'zod'
import { ApiClientDev } from './axios.dev'
import { __ } from '~/shared/i18n'

export interface ApiResponse<T> {
  // config: AxiosRequestConfig
  data: T
  // headers: AxiosHeaders
  // request?: XMLHttpRequest
  statusCode: number
  message: string
  timestamp: string
  path: string
}
export interface ApiVoidResponse {
  statusCode: number
  message: string
  timestamp: string
  path: string
}

/**
 * ApiResponse 스키마를 일반으로 정의 (with: zod)
 * @example
 * const testSchema = z.object({
 *  id: z.string(),
 *  name: z.string(),
 * })
 * const ApiResponseTestSchema = ApiResponseSchema(testSchema)
 */
export const ApiResponseSchema = z.object({
  statusCode: z.number(),
  message: z.string(),
  timestamp: z.string(),
  path: z.string(),
})

const ApiClientProd = () => {
  const instance = axios.create({
    baseURL: import.meta.env.VITE_PUBLIC_C3API_BASEURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  instance.interceptors.request.use(
    async (request) => {
      if (
        import.meta.env.STORYBOOK === 'true' ||
        import.meta.env.TEST === 'true'
      ) {
        return request
      }

      const token = ImwebOAuth2ClientAPI.getAccessTokenFromStore()

      request.headers.Authorization = `Bearer ${token}`

      const level = 'info' as Sentry.SeverityLevel
      // 요청을 보내기 전에 브레드크럼을 추가
      Sentry.addBreadcrumb({
        category: 'oms-api.request',
        message: `${import.meta.env.VITE_PUBLIC_C3API_BASEURL}${request.url}`,
        level,
        data: request,
      })

      return request
    },
    (error) => {
      // 요청 오류 처리
      Sentry.captureException(error)
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      const level = 'info' as Sentry.SeverityLevel
      // 요청을 보내기 전에 브레드크럼을 추가
      Sentry.addBreadcrumb({
        category: 'oms-api.response',
        message: `${response.config.baseURL}${response.config.url}`,
        level,
        data: response.data.data,
      })
      return response
    },
    (error) => {
      if (
        error.isAxiosError &&
        error.code === 'ERR_NETWORK' &&
        error.name === 'AxiosError'
      ) {
        // Log the error to Sentry
        const levelA = 'error' as Sentry.SeverityLevel
        // You can also log additional information if needed
        Sentry.withScope((scope) => {
          scope.setTag('oms.api', '서버장애500')
          scope.setLevel(levelA)
          Sentry.captureException(error)
        })

        // Pass the error down the promise chain
        return Promise.reject(error)
      }

      Sentry.captureException(error)

      // ====================================== 503 토큰 에러 처리 ======================================
      // 에러코드가 503이다.
      const isErrorCode503 = error.response?.status === 503
      // 에러메세지가 'access_token is not exist.'이다.
      const isErrorMessageAccessTokenNotExist =
        error.response?.data.message === 'access_token is not exist.'
      // 이때 딱한번 토큰을 재발급 받는다.
      if (isErrorCode503 && isErrorMessageAccessTokenNotExist) {
        // 재발급 받기위해서는 토큰을 제거한다.
        ImwebOAuth2ClientAPI.renewToken()
      }

      // ====================================== 401 조회 에러 처리 ======================================
      const isErrorCode401 = error.response?.status === 401
      // 에러메세지가 'Invalid token: access token has expired'이다.
      const isErrorMessageInvalidToken =
        error.response?.data.message ===
        'Invalid token: access token has expired'
      // 이때 딱한번 토큰을 재발급 받는다.
      if (isErrorCode401 && isErrorMessageInvalidToken) {
        // 재발급 받기위해서는 토큰을 제거한다.
        ImwebOAuth2ClientAPI.renewToken()
      }
      throw error
    }
  )

  return instance
}

export const ApiClient = () => {
  if (import.meta.env.VITE_NODE_ENV === 'local') {
    return ApiClientDev()
  }
  return ApiClientProd()
}
