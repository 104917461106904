import { TInvoiceExcelBatchSchema } from '~/entities/invoice-excel-batch'
import { T상수_액셀배치상태 } from '~/entities/@x'

export class 모델_송장배치 {
  batchInvoiceCode: TInvoiceExcelBatchSchema['batchInvoiceCode']
  statusCd: T상수_액셀배치상태
  totalCnt: number
  successCnt: number
  failureCnt: number
  wtime: string
  fileName: string
  fileSize: number

  constructor(data: TInvoiceExcelBatchSchema) {
    this.batchInvoiceCode = data.batchInvoiceCode
    this.statusCd = data.statusCd
    this.totalCnt = data.totalCnt
    this.successCnt = data.successCnt
    this.failureCnt = data.failureCnt
    this.wtime = data.wtime
    this.fileName = data.fileName
    this.fileSize = data.fileSize
  }
}
