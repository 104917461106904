import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as B from 'fp-ts/boolean'
import { OrderSectionItemDto } from '~/entities/order-detail'
import { __ } from '~/shared/i18n'
import { fDate } from '~/shared/utils'

export class 모델_주문섹션_품목 {
  data: OrderSectionItemDto

  constructor(data: OrderSectionItemDto) {
    this.data = data
  }
}

export class 모델_주문섹션_품목_UI extends 모델_주문섹션_품목 {
  get 옵션() {
    return pipe(
      this.data.optionInfo,
      O.fromNullable,
      O.fold(
        () => '',
        flow(
          A.map((e) => `${e.key} ${e.value}`),
          (e) => e.join(', ')
        )
      )
    )
  }

  get 디지털상품다운로드() {
    return pipe(
      this.data.digitalProductInfo,
      O.fromNullable,
      O.fold(
        () => '',
        (digital) =>
          pipe(
            digital.downloadLimitTime,
            this.다운로드_기간이_무제한인가,
            B.fold(
              () =>
                __('잔여 다운로드 횟수 {{count}}, 다운로드 기간 {{date}}까지', {
                  count: digital.downloadAbleCnt,
                  date: fDate(
                    new Date(digital.downloadLimitTime),
                    'yyyy.MM.dd HH:mm'
                  ),
                }),
              () =>
                __('잔여 다운로드 횟수 {{count}}, 다운로드 기간 무제한', {
                  count: digital.downloadAbleCnt,
                })
            )
          )
      )
    )
  }

  private 다운로드_기간이_무제한인가(date: string): boolean {
    const year = new Date(date).getFullYear()
    return year >= 3000 //ex 9999-12-31T23:59:59.000Z
  }
}
