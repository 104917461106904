import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { TExternalOrderSectionDto } from '~/entities/external-order'

export function totalPrice(
  sectionList: TExternalOrderSectionDto[] | undefined
) {
  const result = pipe(
    sectionList,
    O.fromNullable,
    O.getOrElseW(() => {
      throw new Error('섹션 리스트가 없습니다.')
    }),
    A.map((section) => {
      const price = pipe(
        section.orderSectionItemList,
        O.fromNullable,
        O.fold(
          () => 0,
          flow(A.reduce(0, (acc, item) => acc + item.qty * item.itemPrice))
        )
      )
      return price
    }),
    A.reduce(0, (acc, price) => acc + price)
  )

  return result
}
