/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'
import { 상수_환불금액_타입 } from '~/entities/@x'

const cancelInfoSchema = z.object({
  siteCode: z.string(),
  idx: z.number().nullish(),
  orderSectionCode: z.string(),
  isCustomerRequest: z.union([z.literal('Y'), z.literal('N')]),
  cancelReason: z.string(),
  cancelReasonDetail: z.string().nullish(),
  refundPriceTypeCd: z.nativeEnum(상수_환불금액_타입),
  deliveryExtraPrice: z.number(),
  etcPrice: z.number().nullish(),
  etcPriceReason: z.string().nullish(),
  refundAmount: z.number(),
  refundTaxFreeAmount: z.number(),
  refundPoint: z.number(),
  isRefund: z.string(),
  isAlternativeRefund: z.union([z.literal('Y'), z.literal('N')]),
  alternativeRefundData: z.string().nullish(),
  isDel: z.string(),
  wtime: z.string().datetime(),
  mtime: z.string().datetime(),
})
export const couponInfoSchema = z.object({
  couponIssueCode: z.string(),
  couponName: z.string(),
  endDate: z.string().nullable(),
  isUnlimitedDate: z.union([z.literal('Y'), z.literal('N')]),
})
export const cancelSettlementsSchema = z.object({
  orderSectionItemList: z.array(
    z.object({
      orderSectionItemCode: z.string(),
      qty: z.number(),
      baseItemPrice: z.number(),
      itemPrice: z.number(),
      prodName: z.string(),
      optionInfo: z
        .array(
          z.object({
            key: z.string(),
            value: z.any(),
          })
        )
        .nullish(),
      imageUrl: z.string().nullish(),
    })
  ),
  totalItemPrice: z.number(),
  totalActualPaymentPrice: z.number(),
  totalAverageActualPaymentPrice: z.number(),
  totalTaxFreeItemPrice: z.number(),
  refundableRemainPaymentPrice: z.number(),
  refundableAmount: z.number(),
  recreditedPoint: z.number(),
  totalGradeDiscount: z.number(),
  totalItemCouponDiscount: z.number(),
  totalItemPointAmount: z.number(),
  cancelInfo: cancelInfoSchema,
  usedCouponInfo: z.array(couponInfoSchema).nullish(),
})
export const postResCancelSettlementsSchema = ApiResponseSchema.extend({
  data: cancelSettlementsSchema,
})
export const postPayloadCancelSettlementsSchema = z.object({
  targetItemInformation: z.array(
    z.object({
      orderSectionItemCode: z.string(),
      qty: z.number(),
    })
  ),
})
