import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResDeliveryHoldDto,
  type TPatchPayloadDeliveryHoldDto,
  patchResDeliveryHoldDto,
  patchPayloadDeliveryHoldDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/r7DPBj
 * @description [order-section-delivery] 섹션 배송보류 설정/해제
 */
export function patchDeliveryHold({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadDeliveryHoldDto) {
    type T = TPatchResDeliveryHoldDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/delivery-hold`, body)
      .then((res) => res.data)

    patchResDeliveryHoldDto.parse(result.data)
    return result
  }
}
