import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

export function extractFilenameFromContentDisposition(
  contentDisposition: string
): O.Option<string> {
  return pipe(
    O.fromNullable(contentDisposition),
    O.chain((cd) =>
      O.fromNullable(cd.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/))
    ),
    O.map((matches) => matches[1].replace(/['"]/g, '')),
    O.map(decodeURIComponent)
  )
}
