import { useAtom } from 'jotai'
import { useFormContext } from 'react-hook-form'
import { Dialog } from '~/shared/components/ui/dialog'
import { IconInfoCircle } from '@imwebme/icon'
import { Button } from '@imwebme/clay-components'
import {
  CCancelRequest,
  TCancelRequestForm,
} from '~/container/order/order-cancel-info/schema'
import { CancelRequest } from '~/container/order/order-cancel-info/order-cancel-info-api'
import { OrderCancelInfoCancelRequestModalWithRefundAtom } from '../order-cancel-info.store'
import { __, useLL } from '~/shared/i18n'
import { useNavigate } from '~/router'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { orderDetailOptions } from '~/entities/order-detail'
import { 상수_판매채널 } from '~/entities/@x'

/**
 * ```
 * 단일 섹션일때 환불도 같이 가능하다.
 * 이때 접수완로후에 환불페이지로 이동 `/order/{orderCode}/{orderSectionCode}/return` 해당 경로로 이동
 * 그래서 sectionCode를 받는다.
 * ```
 */
export const WithCancelRequestModal = () => {
  const router = useNavigate()
  const orderCode = useOrderCode()
  const location = useLL()
  const queryClient = useQueryClient()
  if (orderCode === undefined) {
    throw new Error('orderCode is undefined')
  }
  const { getValues } = useFormContext<TCancelRequestForm>()
  const [open, setOpen] = useAtom(
    OrderCancelInfoCancelRequestModalWithRefundAtom
  )
  const { mutate, isPending } = useMutation({
    mutationFn: CancelRequest,
    onSuccess: (e) => {
      const afterOrderSectionCode = e?.data[0]?.afterOrderSectionCode
      if (afterOrderSectionCode) {
        setOpen(false)
        queryClient.removeQueries({
          queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
        })
        router('/order/:saleChannel/:orderCode/:sectionCode/cancel/calc', {
          params: {
            saleChannel: String(상수_판매채널.아임웹),
            orderCode,
            sectionCode: afterOrderSectionCode,
          },
        })
      }
    },
  })
  const onSubmit = async () => {
    const CCR = new CCancelRequest(getValues())
    if (process.env.NODE_ENV === 'development') {
      log.debug(
        '🚀 ~ file: return-request-modal.tsx:14 ~ onSubmit ~ data:',
        CCR.submitData
      )
    }
    mutate({
      orderCode,
      body: CCR.submitData,
    })
  }
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="sm:max-w-[380px] gap-y-[20px] sm:rounded-[16px] pt-[32px] pb-[20px] px-[32px]">
          <Dialog.Header>
            <Dialog.Title className="semantic-h4-bold">
              {__('환불도 함께 진행 할까요?')}
            </Dialog.Title>
          </Dialog.Header>
          <div className="px-[16px] py-[12px] grid grid-cols-[16px,1fr] gap-[8px] bg-clay-semantic-surfaceAccent rounded-lg semantic-p14">
            <IconInfoCircle className="stroke-[2] mt-[4px]" />
            {__('선택한 품목은 취소접수 처리되고 환불도 이어서 진행되어요')}
          </div>
          <Dialog.Footer className="mt-[4px]">
            <Dialog.Close asChild>
              <Button
                native={{ type: 'button' }}
                variant="outlined"
                text={__('닫기')}
              />
            </Dialog.Close>
            <Button
              native={{ type: 'button' }}
              variant="primary"
              onClick={onSubmit}
              isLoading={isPending}
              text={__('환불 접수')}
            />
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
