import {
  Flex,
  TextButton,
  IconButton,
  Typography,
  Clay,
  OptionList,
  OptionItem,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { DotsVertical } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { useOnboardingStep } from '~/container/onboarding/onboarding.store'

export function PurchaserInfoCard() {
  const [step] = useOnboardingStep()
  return (
    <Clay
      backgroundColor={vars.semantic.color.surface}
      borderRadius={vars.rounded.large}
      padding={`${vars.spacing[5]} 0`}
    >
      <Flex
        justifyContent="space-between"
        paddingInline={vars.spacing[6]}
        paddingBottom={vars.spacing[5]}
      >
        <Typography variant="heading-medium-bold">
          {__('구매자 정보')}
        </Typography>
        {match(step)
          .with(12, () => (
            <Clay position="relative" zIndex={1}>
              <IconButton
                icon={<DotsVertical />}
                variant="secondary"
                size="tiny"
                sx={{
                  backgroundColor: vars.semantic.color.actionSecondaryPressed,
                }}
              />
              <OptionList
                popOver
                sx={{
                  position: 'absolute',
                  width: '168px',
                  right: 0,
                  top: 36,
                }}
              >
                <OptionItem text={__('구매자 정보 수정')} />
                <OptionItem text={__('회원 정보 확인')} />
              </OptionList>
            </Clay>
          ))
          .otherwise(() => (
            <IconButton
              icon={<DotsVertical />}
              variant="secondary"
              size="tiny"
            />
          ))}
      </Flex>

      <Typography variant="label-medium" as="div">
        <Flex.Column gap={vars.spacing[1]} paddingInline={vars.spacing[6]}>
          <Typography>Customer (customer@gmail.com)</Typography>
          <Typography>{__('VIP')}</Typography>
          <TextButton text="070-1234-5678" variant="primary" />
          <Typography>customer@gmail.com</Typography>
          <Typography>192.168.0.x</Typography>
        </Flex.Column>
      </Typography>
    </Clay>
  )
}
