import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  tabCode: string
}
type AtomType = {
  key: string
  value?: boolean
}
const RegisterInvoiceExcelState = atomFamily(
  ({ key, value = false }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)
type PollingType = {
  key: string
  value?: boolean
}
const RegisterInvoiceExcelPollingState = atomFamily(
  ({ key, value = false }: PollingType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)

export const useRegisterInvoiceExcelAtom = {
  key: (p: AtomKey) => `tabCode(${p.tabCode}):bulk-invoice-upload-excel`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          RegisterInvoiceExcelState({
            key: useRegisterInvoiceExcelAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          RegisterInvoiceExcelState({
            key: useRegisterInvoiceExcelAtom.key(p),
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          RegisterInvoiceExcelState({
            key: useRegisterInvoiceExcelAtom.key(p),
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          RegisterInvoiceExcelState({
            key: useRegisterInvoiceExcelAtom.key(p),
          }),
        [p]
      )
    ),
  // ======================================
  pollingKey: (p: AtomKey) =>
    `tabCode(${p.tabCode}):bulk-invoice-upload-excel-poling`,
  polling: {
    state: (p: AtomKey) =>
      useAtom(
        React.useMemo(
          () =>
            RegisterInvoiceExcelPollingState({
              key: useRegisterInvoiceExcelAtom.pollingKey(p),
            }),
          [p]
        )
      ),
    get: (p: AtomKey) =>
      useAtomValue(
        React.useMemo(
          () =>
            RegisterInvoiceExcelPollingState({
              key: useRegisterInvoiceExcelAtom.pollingKey(p),
            }),
          [p]
        )
      ),
    set: (p: AtomKey) =>
      useSetAtom(
        React.useMemo(
          () =>
            RegisterInvoiceExcelPollingState({
              key: useRegisterInvoiceExcelAtom.pollingKey(p),
            }),
          [p]
        )
      ),
    reset: (p: AtomKey) =>
      useResetAtom(
        React.useMemo(
          () =>
            RegisterInvoiceExcelPollingState({
              key: useRegisterInvoiceExcelAtom.pollingKey(p),
            }),
          [p]
        )
      ),
  },
}
