/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { IMWEB_BANK_CODE, 상수_결제수단 } from '~/entities/@x'

export const refundReceiveAccount = z.object({
  bank: z.nativeEnum(IMWEB_BANK_CODE),
  accountNumber: z.string(),
  holderName: z.string(),
})

const detail = z.object({
  code: z.string().nullish(),
  resultCode: z.string(),
  resultMessage: z.string().nullish(),
  paymentCode: z.string(),
  paymentNo: z.string(),
  pgName: z.string(),
  methodCd: z.nativeEnum(상수_결제수단).nullish(),
  canceledPrice: z.number(),
  tid: z.string(),
})
/**
 * post refund
 */
export const postResRefundDto = z.object({
  resultCode: z.string(),
  details: z.array(detail),
  successPrice: z.number(),
  failurePrice: z.number(),
})
export const postPayloadRefundDto = z.object({
  orderCode: z.string(),
  refundPrice: z.number(),
  isBankTransferRefund: z.string().nullish(),
  refundReceiveAccount: refundReceiveAccount.nullish(),
})
