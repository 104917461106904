import { z } from 'zod'

export const 상수_외부채널주문_채널상태 = {
  입금대기: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-3EA1',
  결제완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-47C9',
  배송준비중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-2904',
  배송중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-E434',
  배송완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-D216',
  구매확정: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-9FC2',
  미입금취소: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-CCA1',
  취소처리중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-DFAD',
  취소요청: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-BD57',
  취소처리완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-AD7A',
  취소철회: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-88E6',
  반품요청: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-A119',
  반품완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-8869',
  반품철회: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-59CF',
  수거처리중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-0E0C',
  수거완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-A764',
  교환요청: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-F4A5',
  교환완료_구매확정: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-BB3E',
  교환완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-656A',
  교환철회: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-22A6',
  교환재배송대기: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-C101',
  교환재배송중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-F451',
  직권취소처리중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-3811',
  직권취소처리완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-26C5',
  교환재배송처리중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-1AD3',
  수거완료_교환재배송처리중: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-3AE0',
  수거완료_교환완료: 'OMS_CUSTOM_CODE_CHANNEL_STATUS-028D',
} as const

const type = z.nativeEnum(상수_외부채널주문_채널상태)
export type T상수_외부채널주문_채널상태 = z.infer<typeof type>
