import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryShippingValidateSchema,
  type TPostPayloadBulkActionSectionDeliveryShippingValidateSchema,
  postResBulkActionSectionDeliveryShippingValidateSchema,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/TuTF75
 * @description 주문 배송 중 대량 처리 검증
 */
export async function postBulkActionSectionDeliveryShippingValidate({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryShippingValidateSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryShippingValidateSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/shipping/validate', body)
    .then((res) => res.data)

  postResBulkActionSectionDeliveryShippingValidateSchema.parse(result.data)
  return result.data
}
