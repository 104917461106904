import * as _DropdownMenu from './dropdown-menu'

/**
 * radix-ui의 DropdownMenu와 클레이 디자인을 사용합니다.
 */
const DropdownMenu = Object.assign(_DropdownMenu.DropdownMenu, {
  Trigger: _DropdownMenu.DropdownMenuTrigger,
  Content: _DropdownMenu.DropdownMenuContent,
  Item: _DropdownMenu.DropdownMenuItem,
  Separator: _DropdownMenu.DropdownMenuSeparator,
  Label: _DropdownMenu.DropdownMenuLabel,
  CheckboxItem: _DropdownMenu.DropdownMenuCheckboxItem,
  RadioGroup: _DropdownMenu.DropdownMenuRadioGroup,
  RadioItem: _DropdownMenu.DropdownMenuRadioItem,
  Portal: _DropdownMenu.DropdownMenuPortal,
  SubContent: _DropdownMenu.DropdownMenuSubContent,
  SubTrigger: _DropdownMenu.DropdownMenuSubTrigger,
  Group: _DropdownMenu.DropdownMenuGroup,
  Sub: _DropdownMenu.DropdownMenuSub,
})

export { DropdownMenu }
