import { atom } from 'jotai'

// // 반품모달
export const OrderCancelInfoCancelRequestModalAtom = atom(false)
OrderCancelInfoCancelRequestModalAtom.debugLabel =
  'OrderCancelInfoCancelRequestModalAtom'

// // 환불 같이하는 모달
export const OrderCancelInfoCancelRequestModalWithRefundAtom = atom(false)
OrderCancelInfoCancelRequestModalAtom.debugLabel =
  'OrderCancelInfoCancelRequestModalAtom'

// 환불도 함께 진행할지 여부
export const OrderCancelInfoCancelRequestWithRefundAtom = atom(false)
OrderCancelInfoCancelRequestWithRefundAtom.debugLabel =
  'OrderCancelInfoCancelRequestWithRefundAtom'
