import { ApiClient } from '~/shared/api'
import { type TGetResOrderListDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description order-list
 */
export async function getOrderList(params: Record<string, unknown>) {
  const result = await axios
    .get<ApiResponse<TGetResOrderListDto>>('/order', {
      params,
      paramsSerializer: {
        indexes: false,
      },
    })
    .then((res) => res.data)

  return result
}
