import React from 'react'
import { OrderSectionCancelCalc } from './order-section-cancel-calc'
import { Loading } from '~/shared/components/loading'

export function OrderSectionCancelCalcPack() {
  return (
    <React.Suspense fallback={<Loading />}>
      <OrderSectionCancelCalc />
    </React.Suspense>
  )
}
