import React from 'react'
import type { TInvoiceViewerBase } from './invoice-viewer.type'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { useInvoiceViewerAtom } from './invoice-viewer.store'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __ } from '~/shared/i18n'
import { IconX } from '@imwebme/icon'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'

const InvoiceViewer = React.lazy(() =>
  import('./invoice-viewer').then((module) => ({
    default: React.memo(module.InvoiceViewer),
  }))
)

interface TInvoiceViewerContainer extends TInvoiceViewerBase {}
export function InvoiceViewerContainer(props: TInvoiceViewerContainer) {
  const orderCode = useOrderCode()

  const [open, setState] = useInvoiceViewerAtom.state({
    orderCode,
    sectionCode: props.sectionCode,
  })

  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[16px] p-0 pt-[24px] pb-[24px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <InvoiceViewer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
