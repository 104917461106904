/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { postPayloadRefundDto, refundReceiveAccount } from '.'
import { z } from 'zod'

export const postPayloadRefundRdo = postPayloadRefundDto

/**
 * refun rdo는 무통장환불일때도 사용하는 해당 rdo는 이것으로 사용한다.
 */
export const postPayloadRefundRdo_bankTransfer = postPayloadRefundDto.extend({
  refundReceiveAccount: refundReceiveAccount.extend({
    accountNumber: z.string().min(1).max(20),
    holderName: z.string().min(2).max(20),
  }),
})
