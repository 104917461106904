import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithReset, RESET } from 'jotai/utils'

type AtomKey = {
  orderCode: string
  sectionCode: string
  type: 'merge' | 'split'
}

// 주문당 활성화된 섹션 합치기/나누기 상태는 하나만 존재하도록 합니다
const sectionMergeSplitAtom = atomWithReset<AtomKey | null>(null)
sectionMergeSplitAtom.debugLabel = '섹션mrege/split:현재'

const sectionMergeSplitFamily = atomFamily(
  (key: AtomKey) => {
    const at = atom(
      (get) => {
        const active = get(sectionMergeSplitAtom)
        return (
          active !== null &&
          active.type === key.type &&
          active.orderCode === key.orderCode &&
          active.sectionCode === key.sectionCode
        )
      },
      (_get, set, update: boolean) => {
        if (update) {
          set(sectionMergeSplitAtom, key)
        } else {
          set(sectionMergeSplitAtom, RESET)
        }
      }
    )

    at.debugLabel = `섹션${key.type}:${key.orderCode}:${key.sectionCode}`
    return at
  },
  (a, b) =>
    a.type === b.type &&
    a.orderCode === b.orderCode &&
    a.sectionCode === b.sectionCode
)

export const useSectionMergeSplitAtom = {
  state: (p: AtomKey) => useAtom(sectionMergeSplitFamily(p)),
  get: (p: AtomKey) => useAtomValue(sectionMergeSplitFamily(p)),
  set: (p: AtomKey) => useSetAtom(sectionMergeSplitFamily(p)),
}
