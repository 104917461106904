import React from 'react'
import {
  // eslint-disable-next-line no-restricted-imports
  Link as OriginLink,
  useNavigate as useOriginNavigate,
  type LinkProps,
  useLocation,
} from 'react-router-dom'
import { match } from 'ts-pattern'

// 새탭열기 이벤트 감지
function isModifiedEvent(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

/**
 * Link에 locale을 붙여주는 컴포넌트
 */
export interface TLocalLink extends LinkProps {
  goto?: boolean
  internal?: boolean
}
export const LocaleLink = React.forwardRef<
  React.ElementRef<typeof OriginLink>,
  TLocalLink
>(({ to, goto = false, internal = false, ...props }, ref) => {
  const navigate = useOriginNavigate()
  const url = `${to}`
  const newTabUrl = `${
    ImwebOAuth2ClientAPI.getInfo().siteUrl
  }/admin/shopping/order-v1?subPath=${btoa(url)}`
  const internalUrl = `${ImwebOAuth2ClientAPI.getInfo().siteUrl}${to}`

  if (goto) {
    const _to = match<boolean>(true)
      .with(internal, () => internalUrl)
      .otherwise(() => to)
    return (
      <OriginLink
        to={_to}
        {...props}
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          window.parent.postMessage(
            {
              source: 'OMSA',
              message: {
                goto: _to,
                target: '_blank',
              },
            },
            '*'
          )
        }}
        onAuxClick={(e) => {
          e.preventDefault()
          window.parent.postMessage(
            {
              source: 'OMSA',
              message: {
                goto: _to,
                target: '_blank',
              },
            },
            '*'
          )
        }}
      />
    )
  }

  return (
    <OriginLink
      to={to}
      {...props}
      ref={ref}
      onClick={(e) => {
        props.onClick && props.onClick(e)
        e.preventDefault()
        if (isModifiedEvent(e)) {
          window.open(newTabUrl, '_blank')
          return
        }
        navigate(url)
      }}
      onAuxClick={(e) => {
        e.preventDefault()
        window.open(newTabUrl, '_blank')
      }}
    />
  )
})
/**
 * LocaleLink - react-router-dom Link에 locale을 붙여주는 컴포넌트
 * LocaleLink의 alias
 */
export const Link = LocaleLink

export const useLocaleLocation = () => {
  const location = useLocation()
  return location
}

/**
 * useLocation 기능의 locale만 제거 useLocaleLocation을 리턴한다.
 * useLocaleLocation의 alias
 */
export const useLL = useLocaleLocation
