import React from 'react'
import { IInvoiceExcelHistoryListContainer } from './invoice-excel-history-list.container'

const InvoiceExcelHistoryListContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: module.InvoiceExcelHistoryListContainer,
  }))
)

export interface IInvoiceExcelHistoryListPack
  extends IInvoiceExcelHistoryListContainer {
  open: boolean
}
export function InvoiceExcelHistoryListPack({
  open,
  ...props
}: IInvoiceExcelHistoryListPack) {
  if (!open) {
    return null
  }
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <InvoiceExcelHistoryListContainer {...props} />
    </React.Suspense>
  )
}
