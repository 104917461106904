import React from 'react'
import { fDate } from '~/shared/utils'
import { OrderFormData } from '~/entities/order-detail'
import { Item } from './components'
import { Button } from '@imwebme/clay-components'
import { Download01 } from '@imwebme/clay-icons'
import { IconButton } from '@imwebme/clay-components'

export function File({
  title,
  description,
  value,
  fileDownloadUrl,
  wtime,
}: OrderFormData) {
  const onClickDownload = React.useCallback(() => {
    if (fileDownloadUrl?.objectSignedUrl) {
      const objectSignedUrl = fileDownloadUrl.objectSignedUrl
      fetch(objectSignedUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const a = document.createElement('a')
          a.href = url
          a.download = fileDownloadUrl.fileName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        })
        .catch((error) => {
          log.error('파일 다운로드 오류:', error)
        })
    }
  }, [fileDownloadUrl])

  if (value[0] === undefined || !fileDownloadUrl) {
    return null
  }
  return (
    <Item>
      <Item.Title>{title}</Item.Title>
      <Item.Description>{description}</Item.Description>
      <Item.Content>
        <div className="p-[4px] bg-clay-semantic-surfaceSub rounded-[8px]">
          <div className="px-[12px] py-[4px] flex justify-between items-center gap-x-[8px]">
            <div className="typo-clay-body-medium">
              <div className="line-clamp-1">{fileDownloadUrl?.fileName}</div>
              <div className="text-clay-semantic-textSub line-clamp-1">
                {fDate(new Date(wtime), 'yyyy-MM-dd HH:mm')}
              </div>
            </div>
            <IconButton
              native={{ type: 'button' }}
              onClick={onClickDownload}
              variant="secondary"
              size="small"
              icon={<Download01 />}
            />
          </div>
        </div>
      </Item.Content>
    </Item>
  )
}
