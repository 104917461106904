/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '~/shared/utils'

const inputVariants = cva(
  'border rounded-md py-2 px-3 pr-[calc(0.5rem+0.4em+1em)] text-sm',
  {
    variants: {
      variant: {
        default: 'border-slate-300 bg-transparent',
        disabled: 'cursor-not-allowed bg-clay-semantic-surfaceSub',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

type Callbacks = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  valueIncrease: (v: number) => void
  valueDecrease: (v: number) => void
}

type Value = {
  value: string | number
}

type PropsWithCallbacksIfValue<T> = T extends null | undefined
  ? T // 값이 null이거나 정의되지 않은 경우 값을 그대로 반환합니다.
  : T & Callbacks // 값이 있으면 값 유형을 콜백 속성과 병합합니다.

export type InputNumberProps = React.InputHTMLAttributes<HTMLInputElement> &
  PropsWithCallbacksIfValue<Value>

const InputNumber = ({
  children,
  valueIncrease,
  valueDecrease,
  className,
  ...props
}: InputNumberProps) => {
  const { onChange, ...propsRev } = Object.assign({}, props)
  // @ts-ignore
  delete propsRev.valueIncrease
  // @ts-ignore
  delete propsRev.valueDecrease
  const ref = React.useRef<HTMLInputElement>(null)

  // ===================================================
  let handleIncrease = (value: number) => {
    if (ref.current) {
      if (ref.current.value === '') {
        ref.current.value = '1'
      } else {
        ref.current.value = String(parseInt(ref.current.value) + value)
      }
    }
  }
  let handleDecrease = (value: number) => {
    if (ref.current) {
      ref.current.value = String(parseInt(ref.current.value) + value)
    }
  }

  if (props?.value !== undefined) {
    handleIncrease = (v) => {
      if (props.max !== undefined && props.value >= props.max) {
        return
      }
      valueIncrease(v)
    }
    handleDecrease = (v) => {
      if (props.min !== undefined && props.value <= props.min) {
        return
      }
      valueDecrease(v)
    }
  }

  // input에 포커스가되고 키보드 이벤트로 위아래 키를 누르면 숫자가 증가하거나 감소한다.
  // When you hit the ⬆ or ⬇ key, the input value will be increased or decreased by step
  // With the Shift key (Shift+⬆, Shift+⬇), the input value will be changed by 10 * step
  // With the Ctrl or ⌘ key (Ctrl+⬆ or ⌘+⬆ or Ctrl+⬇ or ⌘+⬇ ), the input value will be changed by 0.1 * step
  // IF문 정리해야합니다. 가독성있게
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { value: v } = event.target as HTMLInputElement
      const value = Number(v)
      if (event.key === 'ArrowUp') {
        event.preventDefault()
        handleIncrease(1)
      }
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        handleDecrease(-1)
      }
      // shift + arrow up
      if (event.shiftKey && event.key === 'ArrowUp') {
        event.preventDefault()
        // 10씩 증가
        // 하지만 max값이 있다면 max값을 넘지 않는다.
        if (props.max !== undefined) {
          if (value + 10 > Number(props.max)) {
            return handleIncrease(Number(props.max) - value)
          }
        }
        handleIncrease(10)
      }
      // shift + arrow down
      if (event.shiftKey && event.key === 'ArrowDown') {
        event.preventDefault()
        // 10씩 감소
        // 하지만 min값이 있다면 min값을 넘지 않는다.
        if (value < 10) {
          return handleDecrease(-1 * value)
        }
        handleDecrease(-10)
      }
    }
    if (ref.current) {
      ref.current.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleIncrease, handleDecrease])

  // ===================================================
  // slot
  const childrenArray = React.Children.toArray(
    children
  ) as unknown as React.ReactElement[]

  const findChild = (childName: string) => {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined) {
      return null
    }
    return result
  }
  const IncreaseSlot = findChild('increase')
  const DecreaseSlot = findChild('decrease')

  // ===================================================
  const isIncreaseDisabled = () => {
    if (props.max === undefined) {
      return false
    }
    if (props?.value === undefined) {
      return false
    }

    return props.value >= props.max
  }

  const isDecreaseDisabled = () => {
    if (props.min === undefined) {
      return false
    }
    if (props?.value === undefined) {
      return false
    }

    return props.value <= props.min
  }

  // 부모한태서 ref를 받는다
  // ref
  return (
    // value 값이 없다면 state로 관리한다.
    // value 값을 넣어준다면?
    <div
      className={cn(
        'inline-block relative',
        props.disabled && 'cursor-not-allowed'
      )}
    >
      <input
        ref={ref}
        type="number"
        className={cn(
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-solid border-clay-semantic-border rounded-md py-2 px-3 pr-[calc(0.5rem+0.4em+1em)] text-sm',
          props.disabled
            ? ' cursor-not-allowed !bg-clay-semantic-surfaceSub text-clay-semantic-textDisabled'
            : 'bg-transparent',
          className
        )}
        onChange={(e) => {
          // 소수점을 받지 않을 것이다
          if (e.target.value.includes('.')) {
            e.target.value = e.target.value.replace('.', '')
          }
          onChange?.(e)
        }}
        {...propsRev}
      />
      <button
        type="button"
        onClick={() => handleIncrease(1)}
        className={cn(
          'group absolute right-[0.4em] top-1/2 -translate-y-full',
          isIncreaseDisabled() && '[--slide-from:80px]',
          props.disabled && 'cursor-not-allowed text-clay-semantic-iconDisabled'
        )}
        disabled={isIncreaseDisabled()}
      >
        {IncreaseSlot ? (
          <div {...IncreaseSlot?.props} />
        ) : (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m18 15-6-6-6 6" />
          </svg>
        )}
      </button>
      <button
        type="button"
        onClick={() => handleDecrease(-1)}
        className={cn(
          'group absolute right-[0.4em] top-1/2',
          isDecreaseDisabled() && '[--slide-from:80px]',
          props.disabled && 'cursor-not-allowed text-clay-semantic-iconDisabled'
        )}
        disabled={isDecreaseDisabled()}
      >
        {DecreaseSlot ? (
          <div {...DecreaseSlot?.props} />
        ) : (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        )}
      </button>
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'increase' | 'decrease'
}
const Slot: React.FC<SlotProps> = () => null
InputNumber.Slot = Slot

export { InputNumber }
