import React from 'react'
import { Selector } from '../components'
import { __, __c } from '~/shared/i18n'
import { useController } from 'react-hook-form'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'
import { YN } from '~/entities/@x'

interface Props extends FormControlProp {
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
}

const fieldKey = 'isDeliveryHold'

export const IsDeliveryHoldField = ({
  control,
  handleRemoveOrderInfoField,
}: Props) => {
  const { onChange } = useController({
    name: fieldKey,
    control,
  }).field

  const handleResetField = React.useCallback(() => {
    onChange(null)
    handleRemoveOrderInfoField(fieldKey)
  }, [])

  React.useEffect(() => {
    onChange(YN.Y)
  }, [])

  return (
    <Selector
      label={__('배송보류 주문')}
      isActive={true}
      resetField={handleResetField}
    />
  )
}
