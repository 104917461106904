import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import {
  ProductSearchItemRNCO,
  type T_ProductSearchItemRNCO,
} from '../../../product-search-item/product-search-item-rnco/product-search-item-rnco'
import { useRNCOOptionAtom } from './rnco-option.store'
import { ProductSearchItemRNCOValues } from '../../../product-search-item/product-search-item-rnco/product-search-item-rnco-values'

export const withProductSearchItemContainer =
  (WrappedComponent: React.ComponentType<T_ProductSearchItemRNCO>) =>
  ({
    searchId,
    prodCode,
    optionKey,
    ...props
  }: Omit<T_ProductSearchItemRNCO, 'options' | 'values' | 'setValues'> & {
    searchId: string
    prodCode: string
    optionKey: string
    onValueChange?: (e: any) => void
  }) => {
    // jotai가
    const [options, setOptions] = useRNCOOptionAtom.option(
      searchId,
      prodCode,
      optionKey
    )
    const [values, setValues] = useRNCOOptionAtom.values(
      searchId,
      prodCode,
      optionKey
    )

    // options가있다면 필수조합타입이다. 그러니 렌더링할 필요가 없어서 완전히 예외처리한다.
    if (options === undefined) {
      return null
    }
    return (
      <>
        <WrappedComponent {...props} setValues={setOptions} options={options} />
        {values && (
          <ProductSearchItemRNCOValues
            values={values}
            setValues={setValues}
            productPrice={pipe(props.productPrice, Number)}
          />
        )}
      </>
    )
  }

export const RNCOOptionContainer = withProductSearchItemContainer(
  ProductSearchItemRNCO
)
