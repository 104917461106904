import React from 'react'
import { useAtom } from 'jotai'
import { OrderEditReturnSectionRow } from './order-edit-return-section-row'
import { TProductCard001 } from '../../../product'
import { orderSectionRowAtom } from '../../../order-section/order-section-template/order-section-template.store'

const OrderEditReturnSectionRowContainer = ({
  sectionId,
  id,
  onDeleteItem,
}: {
  sectionId: string
  id: string
  onDeleteItem?: (item: TProductCard001.props) => void
}) => {
  const [item, setItem] = useAtom(
    orderSectionRowAtom({
      // key.split('-')[0] 하는 이유는 품목의 id는 '품목id-섹션id' 이기 때문에
      // 그렇지 않으면 jotai 중복키로 동일한 id의 아이템과 상태가 동일해진다.
      key: id,
      parentKey: sectionId,
    })
  )

  const valueIncrease = (v: number) => {
    if (item) {
      setItem({
        ...item,
        qtyChange: v > (item.qty || 0) ? item.qty : v,
      })
    }
  }
  const valueDecrease = (v: number) => {
    if (item) {
      setItem({
        ...item,
        qtyChange: v > (item.qty || 0) ? item.qty : v,
      })
    }
  }

  const onDelete = (i: TProductCard001.props) => {
    onDeleteItem?.(i)
    setItem(i)
  }

  const onItemChange = (i: TProductCard001.props) => {
    setItem(i)
  }
  const onChange = (v: number) => {
    if (item) {
      setItem({
        ...item,
        qtyChange: v > (item.qty || 0) ? item.qty : v,
      })
    }
  }

  if (item) {
    return (
      <OrderEditReturnSectionRow
        item={item}
        valueIncrease={valueIncrease}
        valueDecrease={valueDecrease}
        onDelete={onDelete}
        onChange={onChange}
        onItemChange={onItemChange}
      />
    )
  }
  return null
}

export { OrderEditReturnSectionRowContainer }
