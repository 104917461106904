class SessionStorageManager {
  static setItem(key: string, data: any) {
    sessionStorage.setItem(key, data)
  }
  static getItem(key: string) {
    const value = sessionStorage.getItem(key)
    if (value === null) {
      return undefined
    }
    if (value === 'undefined') {
      return undefined
    }
    if (value === 'null') {
      return undefined
    }

    return value
  }
  static removeItem(key: string) {
    const value = this.getItem(key)
    sessionStorage.removeItem(key)
    return value
  }
  static clear() {
    sessionStorage.clear()
  }
}

export default SessionStorageManager
