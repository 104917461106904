import { pipe, flow } from 'fp-ts/function'
import { useParams } from 'react-router-dom'
import { useModalParams } from '../use-modal-params'

/**
 * 현재 URL의 동적 매개변수에서 주문 코드(memoCode)를 가져옴
 * @allow
 * - 🟩 page
 * - 🟩 modal
 */
export const useMemoCode = (): string => {
  const params = useParams()
  const modalParams = useModalParams()

  if (params.memoCode) {
    return params.memoCode
  }

  // generouted에서 만든 모달에서 orderCode를 가져오기 위해 사용
  if (modalParams) {
    const _memoCode = modalParams?.memoCode
    if (_memoCode === undefined) {
      throw new Error('memoCode is not defined (modal.tsx)')
    }
    return _memoCode
  }
  throw new Error('memoCode is not defined')
}
