export const 믹스패널 = {
  view_bo_oms_order_list: 'view_bo_oms_order_list',
  click_bo_oms_order_list_export: 'click_bo_oms_order_list_export',
  click_bo_oms_order_list_export_cancel:
    'click_bo_oms_order_list_export_cancel',
  click_bo_oms_order_list_export_complete:
    'click_bo_oms_order_list_export_complete',
  click_bo_oms_order_list_export_format:
    'click_bo_oms_order_list_export_format',
  click_bo_oms_order_list_export_format_cancel:
    'click_bo_oms_order_list_export_format_cancel',
  click_bo_oms_order_list_export_format_save:
    'click_bo_oms_order_list_export_format_save',
  click_bo_oms_order_list_tap_select_tap:
    'click_bo_oms_order_list_tap_select_tap',
  click_bo_oms_order_list_tap_add_tap: 'click_bo_oms_order_list_tap_add_tap',
  click_bo_oms_order_list_tap_add_tap_cancel:
    'click_bo_oms_order_list_tap_add_tap_cancel',
  click_bo_oms_order_list_tap_add_tap_save:
    'click_bo_oms_order_list_tap_add_tap_save',
  click_bo_oms_order_list_tap_edit_tap: 'click_bo_oms_order_list_tap_edit_tap',
  click_bo_oms_order_list_tap_copy_tap: 'click_bo_oms_order_list_tap_copy_tap',
  click_bo_oms_order_list_tap_delete_tap:
    'click_bo_oms_order_list_tap_delete_tap',
  search_bo_oms_order_list_basic: 'search_bo_oms_order_list_basic',
  click_bo_oms_order_list_filter: 'click_bo_oms_order_list_filter',
  click_bo_oms_order_list_filter_select_filter:
    'click_bo_oms_order_list_filter_select_filter',
  click_bo_oms_order_list_filter_add_filter:
    'click_bo_oms_order_list_filter_add_filter',
  click_bo_oms_order_list_filter_delete_filter:
    'click_bo_oms_order_list_filter_delete_filter',
  click_bo_oms_order_list_filter_edit_filter:
    'click_bo_oms_order_list_filter_edit_filter',
  click_bo_oms_order_list_filter_edit_filter_cancel:
    'click_bo_oms_order_list_filter_edit_filter_cancel',
  click_bo_oms_order_list_filter_edit_filter_save:
    'click_bo_oms_order_list_filter_edit_filter_save',
  click_bo_oms_order_list_column: 'click_bo_oms_order_list_column',
  click_bo_oms_order_list_column_edit_column:
    'click_bo_oms_order_list_column_edit_column',
  click_bo_oms_order_list_table_preview:
    'click_bo_oms_order_list_table_preview',
  click_bo_oms_order_list_table_preview_action:
    'click_bo_oms_order_list_table_preview_action',
  click_bo_oms_order_list_table_edit_view:
    'click_bo_oms_order_list_table_edit_view',
  click_bo_oms_order_list_bulkaction: 'click_bo_oms_order_list_bulkaction',
  view_bo_oms_order_detail: 'view_bo_oms_order_detail',
  click_bo_oms_order_detail_print: 'click_bo_oms_order_detail_print',
  view_bo_oms_order_detail_timeline: 'view_bo_oms_order_detail_timeline',
  click_bo_oms_order_detail_timeline_filter:
    'click_bo_oms_order_detail_timeline_filter',
  view_bo_oms_order_detail_timeline_memo:
    'view_bo_oms_order_detail_timeline_memo',
  click_bo_oms_order_detail_payment_action:
    'click_bo_oms_order_detail_payment_action',
  view_bo_oms_order_detail_paymentdetail:
    'view_bo_oms_order_detail_paymentdetail',
  view_bo_oms_order_detail_paymentdetail_price:
    'view_bo_oms_order_detail_paymentdetail_price',
  click_bo_oms_order_detail_paymentdetail_revertpendingprice:
    'click_bo_oms_order_detail_paymentdetail_revertpendingprice',
  click_bo_oms_order_detail_paymentdetail_cashreceipt:
    'click_bo_oms_order_detail_paymentdetail_cashreceipt',
  click_bo_oms_order_detail_section_tab_select:
    'click_bo_oms_order_detail_section_tab_select',
  view_bo_oms_order_detail_section_deliverytracking:
    'view_bo_oms_order_detail_section_deliverytracking',
  view_bo_oms_order_detail_section_cancelreason:
    'view_bo_oms_order_detail_section_cancelreason',
  view_bo_oms_order_detail_section_returnreason:
    'view_bo_oms_order_detail_section_returnreason',
  view_bo_oms_order_detail_section_deliveryinvoicenumber:
    'view_bo_oms_order_detail_section_deliveryinvoicenumber',
  click_bo_oms_order_detail_section_action:
    'click_bo_oms_order_detail_section_action',
  view_bo_oms_order_detail_memberdetail:
    'view_bo_oms_order_detail_memberdetail',
  click_bo_oms_order_detail_editordererinfo:
    'click_bo_oms_order_detail_editordererinfo',
  click_bo_oms_order_detail_sendmessageorderer:
    'click_bo_oms_order_detail_sendmessageorderer',
  click_bo_oms_order_detail_addreceiverinfo:
    'click_bo_oms_order_detail_addreceiverinfo',
  click_bo_oms_order_detail_editreceiverinfo:
    'click_bo_oms_order_detail_editreceiverinfo',
  click_bo_oms_order_detail_deletereceiverinfo:
    'click_bo_oms_order_detail_deletereceiverinfo',
  click_bo_oms_order_detail_addproduct: 'click_bo_oms_order_detail_addproduct',
  click_bo_oms_order_detail_addproduct_exit:
    'click_bo_oms_order_detail_addproduct_exit',
  click_bo_oms_order_detail_addproduct_previous:
    'click_bo_oms_order_detail_addproduct_previous',
  click_bo_oms_order_detail_addproduct_complete:
    'click_bo_oms_order_detail_addproduct_complete',
  click_bo_oms_order_detail_cancelproduct:
    'click_bo_oms_order_detail_cancelproduct',
  click_bo_oms_order_detail_cancelproduct_totalitemselect:
    'click_bo_oms_order_detail_cancelproduct_totalitemselect',
  click_bo_oms_order_detail_cancelproduct_exit:
    'click_bo_oms_order_detail_cancelproduct_exit',
  click_bo_oms_order_detail_cancelproduct_previous:
    'click_bo_oms_order_detail_cancelproduct_previous',
  click_bo_oms_order_detail_cancelproduct_cancelrequest:
    'click_bo_oms_order_detail_cancelproduct_cancelrequest',
  click_bo_oms_order_detail_returnproduct:
    'click_bo_oms_order_detail_returnproduct',
  click_bo_oms_order_detail_returnproduct_totalitemselect:
    'click_bo_oms_order_detail_returnproduct_totalitemselect',
  click_bo_oms_order_detail_returnproduct_exit:
    'click_bo_oms_order_detail_returnproduct_exit',
  click_bo_oms_order_detail_returnproduct_previous:
    'click_bo_oms_order_detail_returnproduct_previous',
  click_bo_oms_order_detail_returnproduct_returnrequest:
    'click_bo_oms_order_detail_returnproduct_returnrequest',
  click_bo_oms_order_detail_refundcalculation_complete:
    'click_bo_oms_order_detail_refundcalculation_complete',
}
