import React from 'react'
import { __ } from '~/shared/i18n'
import { useDeliveryReadyAtom } from '.'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { cn } from '~/shared/utils'
import { Loading } from '~/shared/components/loading'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { ErrorCard } from '~/shared/components/error'
import { DropdownMenu } from '~/shared/components/dropdown-menu'

const DeliveryReadyContainer = React.lazy(() =>
  import('./').then((module) => ({
    default: React.memo(module.DeliveryReadyContainer),
  }))
)

function DeliveryReadyPack() {
  const tabCode = useTabCode()
  const [state, setState] = useDeliveryReadyAtom.state({ tabCode })

  const onOpenChange = (open: boolean) => {
    setState((prev) => ({ ...prev, value: open }))
  }
  return (
    <AlertDialog.Root open={state.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="backdrop-blur-none bg-clay-semantic-backdrop bg-opacity-75" />
        <AlertDialog.Content
          className={cn(
            'sm:max-w-[380px] bg-clay-semantic-surface px-0 rounded-[20px]',
            'dark:bg-clay-semantic-surface'
          )}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense
              fallback={
                <div className="max-h-[240px] overflow-hidden flex items-center justify-center rounded-xl">
                  <Loading />
                </div>
              }
            >
              <DeliveryReadyContainer />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

DeliveryReadyPack.Button = () => {
  const tabCode = useTabCode()
  const setModal = useDeliveryReadyAtom.set({ tabCode })
  return (
    <DropdownMenu.Item
      onSelect={() => setModal((prev) => ({ ...prev, value: true }))}
    >
      {__('배송대기 처리')}
    </DropdownMenu.Item>
  )
}

export { DeliveryReadyPack }
