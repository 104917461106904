export type NonEmptyArray<T> = readonly [T, ...T[]]
export function assert(
  condition: unknown,
  error: Error | string = new Error()
): asserts condition {
  if (!condition) {
    if (typeof error === 'string') {
      throw new Error(error)
    } else {
      throw error
    }
  }
}
