import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { OrderReturn } from './view'
import { useQuery } from '@tanstack/react-query'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import { Trans } from 'react-i18next'
import { SingleOrderCheck } from '../order-api'
import { COrder } from '../class-order'
import { COrderSection } from '../class-order-section'
import { useParams } from 'react-router-dom'
import { orderDetailOptions } from '~/entities/order-detail'
import { __ } from '~/shared/i18n'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const OrderReturnPage = () => {
  const orderCode = useOrderCode()
  if (orderCode === undefined) {
    throw new Error('orderCode is undefined')
  }
  // server status
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
    queryFn: () => SingleOrderCheck(orderCode),
    select: flow((d) => d.data),
  })

  if (isSuccess && data) {
    // ==========================================
    // 스펙
    // 스펙문서
    // https://www.notion.so/imweb/C3-BO-71c4387fa3f84c75879e061d962dbd15
    // ==========================================

    const sections = new COrder(data).orderSectionList

    // OSS03(배송중) 섹션인지 확인하는 스펙
    const isStatusCdOSS03: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS03'
    )

    // OSS04(배송완료) 섹션인지 확인하는 스펙
    const isStatusCdOSS04: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS04'
    )

    // OSS05(구매확정) 섹션인지 확인하는 스펙
    const isStatusCdOSS05: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS05'
    )

    const result = pipe(
      sections,
      A.filter((section) =>
        isStatusCdOSS03
          .or(isStatusCdOSS04)
          .or(isStatusCdOSS05)
          .isSatisfiedBy(section)
      )
    )

    // init
    const readyItems = pipe(
      result,
      O.fromNullable,
      O.fold(
        () => [],
        flow(
          A.map((section) => ({
            id: section.id,
            label: section.orderSectionNo,
            statusCd: section.statusCd,
            value: section.templateItemList,
          }))
        )
      )
    )
    return (
      <>
        <PageTopBar />
        <OrderReturn readyItems={readyItems} orderNo={data.orderNo} />
      </>
    )
  }
  if (isError) {
    return <div>{__('에러')}</div>
  }
  if (isLoading) {
    return <div>{__('로딩중')}</div>
  }

  return <div>{__('없음')}</div>
}

export default OrderReturnPage
