import { Flex, Spinner, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { match } from 'ts-pattern'
import { __ } from '~/shared/i18n'
import GrimIconGrayDocument from '~/shared/components/icon/grim-icon-gray-document.svg?react'

export function NonOrderItemView({
  type,
}: {
  type: 'empty' | 'empty-search' | 'loading'
}) {
  return (
    <Flex.Center height="284px" paddingBottom={vars.spacing[6]}>
      <Flex.Center
        flexDirection="column"
        paddingBottom={vars.spacing[7]}
        rowGap={vars.spacing[5]}
        height="284px"
      >
        {match(type)
          .with('empty', () => (
            <>
              <GrimIconGrayDocument width="72px" height="72px" />
              <Typography variant="body-medium">
                {__('생성된 주문이 없어요')}
              </Typography>
            </>
          ))
          .with('empty-search', () => (
            <>
              <GrimIconGrayDocument width="72px" height="72px" />
              <Flex.Column rowGap={vars.spacing[2]} alignItems="center">
                <Typography variant="body-medium">
                  {__('검색어와 일치하는 주문이 없어요')}
                </Typography>
                <Typography variant="body-small" colorToken="textSub">
                  {__('검색어를 확인해 주세요')}
                </Typography>
              </Flex.Column>
            </>
          ))
          .with('loading', () => (
            <>
              <Spinner size="medium" />
              <Typography variant="body-medium">
                {__('주문을 불러오고 있어요')}
              </Typography>
            </>
          ))
          .exhaustive()}
      </Flex.Center>
    </Flex.Center>
  )
}
