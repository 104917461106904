/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN, 상수_주문상태, 상수_주문타입, 상수_판매채널 } from '~/entities/@x'
import { paymentByPaymentNoDataSchema } from '~/entities/order-detail'
import { orderSectionDto, deliveryDto } from '~/entities/@dto'

const orderMemoListDto = z.object({
  siteCode: z.string().describe('사이트코드'),
  orderCode: z.string().describe('주문 코드'),
  memberCode: z.string().describe('회원코드'),
  memo: z.string().nullish().describe('메모내용 (삭제할 경우 내용을 NULL로)'),
  isDone: z.nativeEnum(YN).describe('처리여부'),
  wtime: z.string().datetime().describe('등록일시'),
  mtime: z.string().datetime().describe('변경일시'),
})

const _patchResOrdererDto = z.object({
  idx: z.number().describe('주문일련번호'),
  siteCode: z.string().describe('사이트코드'),
  unitCode: z.string().describe('유닛코드'),
  orderCode: z.string().describe('주문 코드'),
  saleChannelIdx: z.nativeEnum(상수_판매채널).describe('판매채널번호'),
  isMember: z.nativeEnum(YN).describe('회원여부'),
  isSubscription: z.nativeEnum(YN).describe('정기구독용PG여부'),
  isGift: z.nativeEnum(YN).describe('선물하기 주문 여부'),
  orderNo: z.string().describe('주문 번호'),
  orderTypeCd: z.nativeEnum(상수_주문타입).describe('주문 타입 코드'),
  statusCd: z.nativeEnum(상수_주문상태).describe('주문 상태 코드'),
  currency: z.string().describe('통화 구분번호'),
  paymentPrice: z.number().describe('주문금액'),
  refundPoint: z.number().describe('최종환불포인트'),
  refundPendingPoint: z.number().describe('환불예정포인트'),
  baseItemPrice: z.number().describe('할인 적용 전 품목금액'),
  itemPrice: z.number().describe('할인 적용 후 품목금액'),
  deliveryPrice: z.number().describe('배송금액'),
  deliveryExtraPrice: z.number().describe('추가배송금액'),
  deliveryIslandPrice: z.number().describe('도서산간배송금액'),
  deliveryCouponDiscount: z.number().describe('배송쿠폰할인금액'),
  itemPointAmount: z.number().describe('포인트사용액'),
  deliveryPointAmount: z.number().describe('배송비포인트사용액'),
  itemCouponDiscount: z.number().describe('쿠폰할인금액'),
  gradeDiscount: z.number().describe('등급할인금액'),
  taxFreePrice: z.number().describe('면세금액'),
  pgAmount: z.number().describe('결제금액'),
  pgCancelPrice: z.number().describe('취소금액'),
  refundPendingPrice: z.number().describe('환불예정금액'),
  refundPendingTaxFreePrice: z.number().describe('환불예정면세금액'),
  etcRefundPrice: z.number().describe('환불금액'),
  givePoint: z.number().describe('적립포인트'),
  memberCode: z.string().nullish().describe('회원코드'),
  ordererEmail: z.string().describe('주문자 이메일'),
  ordererCall: z.string().describe('주문자 전화번호'),
  ordererName: z.string().describe('주문자명'),
  isCancelReq: z.nativeEnum(YN).describe('취소요청여부'),
  unipassNumber: z.string().nullish().describe('unipassNumber'),
  deviceCd: z.string().describe('디바이스코드'),
  wtime: z.string().datetime().describe('등록일시'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  mtime: z.string().datetime().describe('변경일시'),
  remainPrice: z
    .number()
    .nullish()
    .describe('미결금액 (총 주문금액 중 아직 결제완료되지 않은 금액)'),
  groupPassCode: z
    .string()
    .nullish()
    .describe('그룹 패스 주문일 경우 적용될 그룹 코드'),
  groupPassDayCnt: z
    .number()
    .nullish()
    .describe('그룹 패스 주문일 경우 연장될 기간'),
  isRequestPayment: z.nativeEnum(YN).describe('추가결제요청여부'),
  country: z.string().describe('국가코드'),
  orderSectionList: z.array(orderSectionDto).nullish(),
  orderMemoList: z.array(orderMemoListDto).nullish(),
  paymentList: z.array(paymentByPaymentNoDataSchema).nullish(),
  orderDeliveryList: z.array(deliveryDto),
  currencyFormatViewPosition: z.string().describe('화폐표시위치'),
})

/**
 * patch orderer
 */
export const patchResOrdererDto = z.object({
  ordererCall: z.string().describe('구매자 전화번호'),
  ordererEmail: z.string().describe('구매자 이메일'),
  ordererName: z.string().describe('구매자명'),
})

export const patchPayloadOrdererDto = z.object({
  ordererEmail: z.string(),
  ordererCall: z.string(),
  ordererName: z.string(),
})
