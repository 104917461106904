import { pipe, flow } from 'fp-ts/function'
import { queryOptions } from '@tanstack/react-query'
import { getExternalOrderClaimInfo } from '.'

export function externalOrderClaimInfoQueryOptions({
  orderCode,
}: {
  orderCode: string
}) {
  return function ({
    channelProductNoList,
  }: {
    channelProductNoList: string[]
  }) {
    return queryOptions({
      queryKey: ['external-order-claim-info'],
      queryFn: getExternalOrderClaimInfo({ orderCode })({
        channelProductNoList,
      }),
    })
  }
}
