import React from 'react'
import { Menu01, DotsVertical } from '@imwebme/clay-icons'
import { MenubarItemProps } from '@radix-ui/react-menubar'
import { Menubar } from '~/shared/components/menubar'
import { Button } from '@imwebme/clay-components'
import { IconButton } from '@imwebme/clay-components'

interface Props {
  /**
   * 페이지 제목
   */
  pageTitle?: string
  /**
   * 우측 메뉴 리스트 (radix-ui menubar 아이템 프랍)
   */
  menuItems?: MenubarItemProps[]
}

/**
 * 각 페이지별로 정의되는, 데스크탑 미만에서 보이는 탑바
 * @description 아임웹 사이드 메뉴바를 여는 기능을 담당한다.
 */
export function PageTopBar({ pageTitle, menuItems }: Props) {
  return (
    <div className="desktop:hidden flex items-center justify-between fixed top-0 inset-x-0 bg-clay-semantic-surface py-clay-2 px-clay-4 z-20">
      <div className="flex gap-x-clay-5 items-center">
        <IconButton
          native={{ type: 'button' }}
          variant="secondary"
          size="tiny"
          icon={<Menu01 color="inherit" />}
          onClick={() => {
            window.parent.postMessage(
              {
                source: 'open-menubar',
                message: 'open-menubar',
              },
              '*'
            )
          }}
        />

        <div className="empty:hidden typo-clay-heading-large-bold">
          {pageTitle}
        </div>
      </div>

      {menuItems && (
        <Menubar>
          <Menubar.Menu>
            <Menubar.Trigger asChild>
              <IconButton
                native={{ type: 'button' }}
                variant="secondary"
                size="tiny"
                icon={<DotsVertical color="inherit" />}
                className="data-[state=open]:bg-clay-semantic-actionSecondaryPressed"
              />
            </Menubar.Trigger>
            <Menubar.Portal>
              <Menubar.Content align="end" className="min-w-[168px]">
                {menuItems.map((option, idx) => (
                  <Menubar.Item {...option} key={idx} />
                ))}
              </Menubar.Content>
            </Menubar.Portal>
          </Menubar.Menu>
        </Menubar>
      )}
    </div>
  )
}
