import { vars } from '@imwebme/clay-token'
import { TGetResDeliveryCountryDto } from '~/entities/site/delivery-country'
import { useDelivFormContext } from './use-deliv-form-context'
import {
  Button,
  Clay,
  Flex,
  OptionItem,
  OptionList,
  Select,
  Textarea,
  Textfield,
} from '@imwebme/clay-components'
import { Controller } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import React from 'react'
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode'
import { Collapse } from '~/shared/components/collapse'

export function 배송국가({
  delivCountryList,
}: {
  delivCountryList: TGetResDeliveryCountryDto
}) {
  const { getValues, reset } = useDelivFormContext()
  const [country, countryName] = getValues(['country', 'countryName'])

  return (
    <Select>
      <Select.Trigger>
        <Select.Input value={countryName} label={__('배송국가')} />
      </Select.Trigger>
      <Select.Portal>
        <OptionList
          popOver
          value={country}
          setValue={(code) => {
            const found = delivCountryList.find((el) => el.code === code)
            if (found?.code === country) {
              return
            }
            reset({
              country: found?.code,
              countryName: found?.name,
              whoInput: 'orderer',
            })
          }}
          sx={{
            maxHeight: '320px',
            overflowY: 'auto',
          }}
        >
          {delivCountryList.map((el) => (
            <OptionItem key={el.code} value={el.code} text={el.name} />
          ))}
        </OptionList>
      </Select.Portal>
    </Select>
  )
}

export function 받는분() {
  const { control } = useDelivFormContext()
  return (
    <Controller
      control={control}
      name="receiverName"
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error },
      }) => (
        <Textfield.Set flex={1}>
          <Textfield.Legend text={__('받는 분')} />
          <Textfield.Box variant="outlined">
            <Textfield.Input
              {...rest}
              value={value ?? ''}
              onChangeText={onChange}
              placeholder={__('입력해 주세요')}
              isValid={error ? false : undefined}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>{error?.message}</Textfield.HelperText>
        </Textfield.Set>
      )}
    />
  )
}

export function 연락처() {
  const { control } = useDelivFormContext()
  return (
    <Controller
      control={control}
      name="receiverCall"
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error },
      }) => (
        <Textfield.Set flex={1}>
          <Textfield.Legend text={__('연락처')} />
          <Textfield.Box variant="outlined">
            <Textfield.Input
              {...rest}
              value={value ?? ''}
              onChangeText={onChange}
              placeholder={__('입력해 주세요')}
              isValid={error ? false : undefined}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText>{error?.message}</Textfield.HelperText>
        </Textfield.Set>
      )}
    />
  )
}

export function 주소정보_한국() {
  const { control, setValue } = useDelivFormContext()
  const [open, setOpen] = React.useState(false)

  const onCompleteDaumPostCode = React.useCallback((data: Address) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    setValue('zipcode', data.zonecode, { shouldValidate: true })
    setValue('kor.addr1', fullAddress, { shouldValidate: true })
    setOpen(false)
  }, [])

  return (
    <Clay>
      <Controller
        control={control}
        name="kor.addr1"
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Flex columnGap={vars.spacing[25]}>
            <Textfield.Set flex={1}>
              <Textfield.Legend text={__('주소 정보')} />
              <Textfield.Box sx={{ flex: 1 }}>
                <Textfield.Input
                  {...rest}
                  value={value ?? ''}
                  placeholder={__('도로명 혹은 지번 주소')}
                  isValid={error ? false : undefined}
                  readOnly
                  autoComplete="off"
                />
              </Textfield.Box>
              <Textfield.HelperText>{error?.message}</Textfield.HelperText>
            </Textfield.Set>
            <Button
              native={{ type: 'button' }}
              text={__('주소 찾기')}
              variant="outlined"
              onClick={() => setOpen((prev) => !prev)}
              sx={{ marginTop: '30px' }}
            />
          </Flex>
        )}
      />

      <Collapse in={open}>
        {open && (
          <DaumPostcodeEmbed
            onComplete={onCompleteDaumPostCode}
            onClose={() => setOpen(false)}
            autoClose={false}
          />
        )}
      </Collapse>

      <Controller
        control={control}
        name="kor.addr2"
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Textfield.Set marginTop={vars.spacing[3]}>
            <Textfield.Box>
              <Textfield.Input
                {...rest}
                value={value ?? ''}
                onChangeText={onChange}
                placeholder={__('상세주소')}
                isValid={error ? false : undefined}
                autoComplete="off"
              />
            </Textfield.Box>
            <Textfield.HelperText>{error?.message}</Textfield.HelperText>
          </Textfield.Set>
        )}
      />
    </Clay>
  )
}

export function 주소정보_해외() {
  const { control } = useDelivFormContext()

  return (
    <Flex.Column rowGap={vars.spacing[3]}>
      <Controller
        control={control}
        name="zipcode"
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Textfield.Set>
            <Textfield.Legend text={__('주소정보')} />
            <Textfield.Box>
              <Textfield.Input
                {...rest}
                value={value ?? ''}
                onChangeText={onChange}
                placeholder={__('우편번호 (Zipcode)')}
                isValid={error ? false : undefined}
                autoComplete="off"
              />
            </Textfield.Box>
            <Textfield.HelperText>{error?.message}</Textfield.HelperText>
          </Textfield.Set>
        )}
      />
      <Flex columnGap={vars.spacing[3]}>
        <Controller
          control={control}
          name="intl.state"
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error },
          }) => (
            <Textfield.Set>
              <Textfield.Box>
                <Textfield.Input
                  {...rest}
                  value={value ?? ''}
                  onChangeText={onChange}
                  placeholder={__('시/도 (State)')}
                  isValid={error ? false : undefined}
                  autoComplete="off"
                />
              </Textfield.Box>
              <Textfield.HelperText>{error?.message}</Textfield.HelperText>
            </Textfield.Set>
          )}
        />
        <Controller
          control={control}
          name="intl.city"
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error },
          }) => (
            <Textfield.Set>
              <Textfield.Box>
                <Textfield.Input
                  {...rest}
                  value={value ?? ''}
                  onChangeText={onChange}
                  placeholder={__('구/군/시 (City)')}
                  isValid={error ? false : undefined}
                  autoComplete="off"
                />
              </Textfield.Box>
              <Textfield.HelperText>{error?.message}</Textfield.HelperText>
            </Textfield.Set>
          )}
        />
      </Flex>
      <Controller
        control={control}
        name="intl.street"
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Textfield.Set>
            <Textfield.Box>
              <Textfield.Input
                {...rest}
                value={value ?? ''}
                onChangeText={onChange}
                placeholder={__('도로명주소 (Street)')}
                isValid={error ? false : undefined}
                autoComplete="off"
              />
            </Textfield.Box>
            <Textfield.HelperText>{error?.message}</Textfield.HelperText>
          </Textfield.Set>
        )}
      />
      <Controller
        control={control}
        name="intl.building"
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Textfield.Set>
            <Textfield.Box>
              <Textfield.Input
                {...rest}
                value={value ?? ''}
                onChangeText={onChange}
                placeholder={__('건물 이름 (Building)')}
                isValid={error ? false : undefined}
                autoComplete="off"
              />
            </Textfield.Box>
            <Textfield.HelperText>{error?.message}</Textfield.HelperText>
          </Textfield.Set>
        )}
      />
    </Flex.Column>
  )
}

export function 배송메모() {
  const { control } = useDelivFormContext()
  return (
    <Controller
      control={control}
      name="memo"
      render={({ field: { value, ...rest }, fieldState: { error } }) => (
        <Textarea
          {...rest}
          height="min"
          label={__('배송 메모')}
          value={value ?? ''}
          characterCount
          maxLength={100}
          placeholder={__('입력해 주세요')}
          errorText={error?.message}
          isError={!!error}
        />
      )}
    />
  )
}
