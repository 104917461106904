import { ApiClient } from '~/shared/api'
import {
  TPostPayloadBulkActionPaymentValidateAllDto,
  TPostResBulkActionPaymentValidateAllDto,
} from './bulk-action-payment-validate-all.type'
import { AxiosResponse } from 'axios'
import { postResBulkActionPaymentValidateAllDto } from './bulk-action-payment-validate-all.dto'

const axios = ApiClient()

export async function postBulkActionPaymentValidateAll({
  body,
}: {
  body: TPostPayloadBulkActionPaymentValidateAllDto
}) {
  type R = typeof body
  type T = TPostResBulkActionPaymentValidateAllDto
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/payment/confirmation/validate/all', body)
    .then((res) => res.data)

  log.debug(result)

  postResBulkActionPaymentValidateAllDto.parse(result.data)
  return result.data
}
