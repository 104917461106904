import {
  Typography,
  ButtonGroup,
  Flex,
  IconButton,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { XClose, Scale01 } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { useTimelineViewMode } from '~/container/order/order-timeline/order-timeline.store'
import { useTimelineModalOpen } from '~/container/order/order-timeline/order-timeline.hook'
import { TimelineFilterController } from './components'
import { CustomTooltip } from '~/container/order/order-timeline/components'

export function TimelineHeaderSidePanelView() {
  const [_timelineModal, setTimelineModalOpen] = useTimelineModalOpen()
  const [_timelineViewMode, setTimelineViewMode] = useTimelineViewMode()

  return (
    <>
      <Flex
        padding={vars.spacing[4]}
        paddingBottom={0}
        justifyContent="space-between"
      >
        <Typography
          variant="heading-2xlarge-bold"
          sx={{
            paddingTop: vars.spacing[2],
            paddingInline: vars.spacing[2],
          }}
        >
          {__('타임라인')}
        </Typography>
        <ButtonGroup size="small" isInline sx={{ alignSelf: 'flex-start' }}>
          <CustomTooltip
            label={__('작게 보기')}
            trigger={
              <IconButton
                icon={<Scale01 />}
                variant="secondary"
                size="small"
                onClick={() => setTimelineViewMode('작게보기_펼침')}
              />
            }
            side="bottom"
            asChild
          />
          <CustomTooltip
            label={__('닫기')}
            trigger={
              <IconButton
                icon={<XClose />}
                variant="secondary"
                size="small"
                onClick={() => setTimelineModalOpen(false)}
              />
            }
            side="bottom"
            asChild
          />
        </ButtonGroup>
      </Flex>

      <Flex
        paddingTop={vars.spacing[4]}
        paddingInline={vars.spacing[6]}
        marginBottom={vars.spacing[5]}
        justifyContent="space-between"
      >
        <TimelineFilterController />
      </Flex>
    </>
  )
}
