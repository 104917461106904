import { Clay, Flex } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { PageHeader } from './partials/page-header'
import { __ } from '~/shared/i18n'
import { Price } from './금액'
import { StepOrderInfo } from './스텝0주문정보입력'
import { StepProdSelect } from './스텝1상품선택'
import { StepDeliverySetup } from './스텝2배송설정'
import { StepDiscountSetup } from './스텝3할인설정'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { useDevice } from '~/shared/hooks/use-device'

export default function OrderCreatePage() {
  const { desktop } = useDevice()
  return (
    <>
      <PageTopBar />

      <Flex.Column
        paddingInline={vars.spacing[10]}
        width="1056px"
        margin="0 auto"
        rowGap={vars.spacing[6]}
        marginTop={desktop ? undefined : '44px'}
      >
        <PageHeader />
        <Flex columnGap={vars.spacing[6]}>
          <Clay flex={1}>
            <StepOrderInfo />
            <StepProdSelect />
            <StepDeliverySetup />
            <StepDiscountSetup />
          </Clay>

          <Price />
        </Flex>
      </Flex.Column>
    </>
  )
}
