import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostResBulkActionSectionInvoicePrintApplySchema,
  TPostPayloadBulkActionSectionInvoicePrintApplySchema,
} from './bulk-action-section-invoice-print-apply.type'
import { postResBulkActionSectionInvoicePrintApplySchema } from './bulk-action-section-invoice-print-apply.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/a2DNL6
 * @description 주문 섹션 대량 송장출력 요청
 */
export async function postBulkActionSectionInvoicePrintApply({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoicePrintApplySchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionInvoicePrintApplySchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/invoice/print', body)
    .then((e) => e.data)

  postResBulkActionSectionInvoicePrintApplySchema.parse(result.data)
  return result.data
}
