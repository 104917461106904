import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { OrderSectionItemDto } from '~/entities/order-detail'
import { OrderSectionCancelSectionItem } from '.'

export function OrderSectionitemList({
  orderSectionItemList,
}: {
  orderSectionItemList: OrderSectionItemDto[] | null | undefined
}) {
  return pipe(
    orderSectionItemList,
    O.fromNullable,
    O.fold(
      () => null,
      flow(
        A.map((r) => (
          <React.Fragment key={r.orderSectionItemCode}>
            <OrderSectionCancelSectionItem item={r} />
          </React.Fragment>
        ))
      )
    )
  )
}
