import React from 'react'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { __, __e } from '~/shared/i18n'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { 상수_추가정보타입 } from '~/entities/@x'
import { useModals } from '~/router'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TOrderFormDataEditFormSchema } from './order-form-data-edit.type'
import { orderFormDataEditFormSchema } from './order-form-data-edit.schema'
import { toast } from 'react-toastify'
import { patchFormData } from '~/entities/order/form-data'
import { Modal } from '@imwebme/clay-components'
import { OrderFormDataEdit } from './order-form-data-edit'

export function OrderFormDataEditContainer() {
  const orderCode = useOrderCode()
  const modals = useModals()
  const handleModalClose = () => modals.close()
  const queryClient = useQueryClient()
  const { data: _data } = useSuspenseQuery(orderDetailOptions({ orderCode }))

  const { mutate: mutatePatchFormData } = useMutation({
    mutationFn: patchFormData({ orderCode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
      })
      handleModalClose()
      toast.success(__('주문 추가정보를 수정했어요'))
    },
    onError: (err) => {
      toast.error(
        err.response?.data?.code
          ? __e(err.response.data.code)
          : __('주문 추가정보 수정에 실패했어요')
      )
    },
  })

  const orderFormDataList = pipe(
    _data.orderFormDataList,
    O.fromNullable,
    O.fold(
      () => [],
      (e) => e
    )
  )

  const formInitialValues: TOrderFormDataEditFormSchema['form'] = React.useMemo(
    () =>
      pipe(
        orderFormDataList,
        A.map((e) => ({
          idx: e.idx,
          value: e.value,
        }))
      ),
    [_data]
  )

  const formMethods = useForm({
    resolver: zodResolver(orderFormDataEditFormSchema),
    defaultValues: { form: formInitialValues },
  })

  const handleSubmit = React.useCallback(
    (data: TOrderFormDataEditFormSchema) => {
      let hasError = false
      const payload: TOrderFormDataEditFormSchema['form'] = []

      for (let i = 0; i < data.form.length; i++) {
        const formItem = data.form[i]
        const found = orderFormDataList.find((d) => d.idx === formItem.idx)

        // API 요청 payload에 파일은 제외하고 추가한다. 파일 아이템은 BO에서 수정이 안된다.
        if (found && found.inputTypeCd !== 상수_추가정보타입.파일) {
          payload.push(formItem)
        }

        // 필수 입력 필드인데 값이 없다면 폼에러 설정
        if (found && found.isRequire === 'Y' && !formItem.value[0]) {
          const errorMessage =
            found.inputTypeCd === 상수_추가정보타입.단일선택 ||
            found.inputTypeCd === 상수_추가정보타입.복수선택 ||
            found.inputTypeCd === 상수_추가정보타입.목록선택
              ? __('필수로 선택해야 해요')
              : __('필수로 입력해야 해요')
          formMethods.setError(`form.${i}.value`, {
            type: 'custom',
            message: errorMessage,
          })
          hasError = true
        }
      }

      if (!hasError) {
        mutatePatchFormData(payload)
      }
    },
    [orderFormDataList]
  )

  return (
    <Modal isOpen setClose={handleModalClose} width="small" fullHeight>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleSubmit)}
          className="flex flex-col h-full"
        >
          <OrderFormDataEdit {...{ orderFormDataList, handleModalClose }} />
        </form>
      </FormProvider>
    </Modal>
  )
}
