import { z } from 'zod'

export const 상수_추가정보타입 = {
  한줄텍스트: 'IFT01', // 한 줄 텍스트
  여러줄텍스트: 'IFT02', // 여러 줄 텍스트
  단일선택: 'IFT03', // 단일선택
  목록선택: 'IFT04', // 목록선택
  복수선택: 'IFT05', // 복수선택
  날짜형식: 'IFT06', // 날짜형식
  시간형식: 'IFT07', // 시간형식
  파일: 'IFT08', // 파일 업로드
} as const

const type = z.nativeEnum(상수_추가정보타입)
export type T상수_추가정보타입 = z.infer<typeof type>
