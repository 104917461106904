import React from 'react'
import { Separator } from '~/shared/components/ui/separator'
import AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { findChild } from '~/shared/calc/find-child'
import { cn } from '~/shared/utils'

interface BasicModalProps
  extends AlertDialogPrimitive.AlertDialogProps,
    React.ComponentPropsWithoutRef<'div'> {
  onAction?: () => void
  onCancel?: () => void
  separator?: boolean
}

// optional 값이 false라면 return 타입에 undefined는 올수없다.

function BasicModal({
  children,
  separator,
  className,
  ...props
}: BasicModalProps) {
  const TitleSlot = findChild(children, 'title', true)
  const Content = findChild(children, 'content', true)
  const BodySlot = findChild(children, 'body', true)
  const FooterSlot = findChild(children, 'footer', true)
  return (
    <div className={cn(className)} {...props}>
      <div>
        {TitleSlot && (
          <div
            {...TitleSlot.props}
            className={cn(
              'text-clay-semantic-text px-[32px] typo-clay-heading-xlarge-bold',
              TitleSlot.props.className
            )}
          />
        )}
        {Content && (
          <div
            {...Content.props}
            className={cn(
              'px-[32px] semantic-p14 text-clay-semantic-textSub mt-[8px]',
              Content.props.className
            )}
          />
        )}
      </div>
      {separator ? (
        <div className="my-[24px]">
          <Separator className="mx-0 border-clay-semantic-divide" />
        </div>
      ) : null}
      {BodySlot && (
        <div
          {...BodySlot.props}
          className={cn('px-[32px]', BodySlot.props.className)}
        />
      )}
      {FooterSlot && (
        <div
          {...FooterSlot.props}
          className={cn(
            'px-[32px] flex justify-end gap-x-[8px] mt-[20px]',
            FooterSlot.props.className
          )}
        />
      )}
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'title' | 'content' | 'body' | 'footer'
  disabled?: boolean
}
const Slot: React.FC<SlotProps> = () => null

BasicModal.Slot = Slot

export { BasicModal }
