import React from 'react'
import { Clay, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

/**
 * 좌측에 필드 레이블, 우측에 필드를 배치하는 grid 레이아웃
 */
export const FieldLayout = React.forwardRef<
  HTMLDivElement,
  {
    title: string
    children: React.ReactNode
  }
>(({ title, children }, ref) => (
  <Clay
    display="grid"
    gridTemplateColumns="1fr 441px"
    columnGap={vars.spacing[6]}
    padding={vars.spacing[6]}
    paddingTop={0}
    ref={ref}
  >
    <Typography variant="body-medium-bold">{title}</Typography>
    {children}
  </Clay>
))
