import {
  useMemo,
  useEffect,
  useTransition,
  createContext,
  useContext,
} from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { groupBy } from 'fp-ts/NonEmptyArray'
import * as A from 'fp-ts/Array'
import * as N from 'fp-ts/number'
import { pipe } from 'fp-ts/function'
import { contramap } from 'fp-ts/Ord'
import {
  useTimelineFilter,
  useTimelineMemoFilter,
} from './order-timeline.store'
import { fDate } from '~/shared/utils'
import {
  orderHistoryQueryOptions,
  TOrderHistoryItem,
  TGetResOrderHistorySchema,
} from '~/entities/order-history'
import { YN } from '~/entities/@x'
import { match } from 'ts-pattern'
import { useLL } from '~/shared/i18n'

export const useOrderHistory = ({ orderCode }: { orderCode: string }) => {
  const [timelineFilter] = useTimelineFilter()
  const [memoFilter] = useTimelineMemoFilter()

  const { data: orderHistoryList } = useSuspenseQuery({
    ...orderHistoryQueryOptions(orderCode),
    select: querySelector,
  })

  const processedOrderHistoryList = useMemo(
    () =>
      pipe(
        orderHistoryList,
        A.filter((item) =>
          match({ timelineFilter, memoFilter })
            .with(
              { timelineFilter: 'ORDER' },
              () => item.historyType === 'timeline'
            )
            .with(
              { timelineFilter: 'MEMO', memoFilter: 'ALL' },
              () => item.historyType === 'memo'
            )
            .with(
              { timelineFilter: 'MEMO', memoFilter: 'RESOLVED' },
              () => item.historyType === 'memo' && item.data.isDone === YN.Y
            )
            .with(
              { timelineFilter: 'MEMO', memoFilter: 'UNRESOLVED' },
              () => item.historyType === 'memo' && item.data.isDone === YN.N
            )
            .otherwise(() => true)
        ),
        groupBy((item) => fDate(new Date(item.wtime), 'yyyy-MM-dd'))
      ),
    [orderHistoryList, timelineFilter, memoFilter]
  )

  const hasUnresolvedMemo = useMemo(
    () =>
      orderHistoryList.some(
        (item) => item.historyType === 'memo' && item.data.isDone === YN.N
      ),
    [orderHistoryList]
  )

  return {
    orderHistoryList: processedOrderHistoryList,
    hasUnresolvedMemo,
  }
}

function querySelector(data: ApiResponse<TGetResOrderHistorySchema>) {
  const byWtime = pipe(
    N.Ord,
    contramap((item: TOrderHistoryItem) => -new Date(item.wtime).getTime())
  )

  return pipe(data.data.orderHistoryList, A.sortBy([byWtime]))
}

export const TimelineModalOpenContext = createContext<
  [boolean, (open: boolean) => void]
>([false, () => {}])
export const useTimelineModalOpen = () => useContext(TimelineModalOpenContext)

/**
 * 초기 타임라인 컴포넌트 열기 훅
 * 1) 주문상세 페이지 진입 시
 * 2) 미해결 메모가 있다면  */
export const useTimelineInitialOpen = ({
  orderCode,
  setModalOpen,
}: {
  orderCode: string
  setModalOpen: (open: boolean) => void
}) => {
  const { hasUnresolvedMemo } = useOrderHistory({ orderCode })
  const [__, startTransition] = useTransition()
  const location = useLL()
  const isOrderDetailPage = /^\/order\/[a-zA-Z0-9]+$/.test(location.pathname)

  useEffect(() => {
    if (isOrderDetailPage && hasUnresolvedMemo) {
      startTransition(() => setModalOpen(true))
    }
  }, [isOrderDetailPage])
}
