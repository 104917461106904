import {
  CDialogBulkStatus,
  TDialogBulkStatus,
  isFailed,
} from './dialog-bulk-status.calc'
import { Button } from '@imwebme/clay-components'
import { Dialog } from '~/shared/components/ui/dialog'
import { __ } from '~/shared/i18n'
import { ContentsBanner } from '@imwebme/clay-components'

// dialog-bulk-status를 가지고 text까지 받을수있는 방식으로 재사용가능하도록 만들수있다.
// 기획서에 일괄 주문상태 처리라는 항목이 있어서 이를 재사용하려고 했으나,
// 일괄 송장번호 삭제는 일괄 주문상태 처리와는 다른 방식으로 처리되어서
// 일괄 주문상태 처리와는 다른 방식으로 구현하였다.
// 그래서 중복 코드가 발생하였다.
interface TDialogBulkHeader extends TDialogBulkStatus {}
export const DialogBulkDeliverReleaseHeader = ({
  fulfilled,
  rejected,
}: TDialogBulkHeader) => {
  const dpcc = new CDialogBulkStatus({ fulfilled, rejected })
  if (isFailed.isSatisfiedBy(dpcc)) {
    return <span>{__('배송보류 해제할 수 없어요')}</span>
  }
  return (
    <span>
      {__('{{처리가능건수}}건을 배송보류 해제 할까요?', {
        처리가능건수: fulfilled,
      })}
    </span>
  )
}

interface TDialogBulkBanner extends TDialogBulkStatus {}
export const DialogBulkDeliverReleaseBanner = ({
  fulfilled,
  rejected,
}: TDialogBulkBanner) => {
  const dpcc = new CDialogBulkStatus({ fulfilled, rejected })
  if (isFailed.isSatisfiedBy(dpcc)) {
    return (
      <ContentsBanner
        variant="critical"
        type="secondary"
        text={__('배송보류 상태가 아니면 해제할 수 없어요.')}
      />
    )
  }
  if (rejected === 0) {
    return (
      <div className="semantic-p14 text-clay-semantic-textSub">
        {__('선택한 주문의 배송보류 상태를 한번에 해제 할 수 있어요.')}
      </div>
    )
  }
  return (
    <ContentsBanner
      variant="warning"
      type="secondary"
      text={__('배송전 상태가 아닌 {{미처리건}}건은 처리되지 않아요.', {
        미처리건: rejected,
      })}
    />
  )
}

interface TDialogBulkButtons extends TDialogBulkStatus {
  onSubmit: () => void
}
export const DialogBulkDeliverReleaseButtons = ({
  fulfilled,
  rejected,
  onSubmit,
}: TDialogBulkButtons) => {
  const dpcc = new CDialogBulkStatus({ fulfilled, rejected })
  if (isFailed.isSatisfiedBy(dpcc)) {
    return (
      <div className="w-full flex justify-end gap-x-[8px] pt-[12px]">
        <Dialog.Close asChild>
          <Button
            native={{ type: 'button' }}
            variant="outlined"
            text={__('닫기')}
          />
        </Dialog.Close>
      </div>
    )
  }

  return (
    <div className="w-full flex justify-end gap-x-[8px] pt-[12px]">
      <Dialog.Close asChild>
        <Button
          native={{ type: 'button' }}
          variant="outlined"
          text={__('취소')}
        />
      </Dialog.Close>
      <Button
        native={{ type: 'button' }}
        variant="primary"
        onClick={() => onSubmit()}
        text={__('배송보류 헤제')}
      />
    </div>
  )
}
