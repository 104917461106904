import React from 'react'
import { useActiveStepIdxAtom } from './order-create.store'

/**
 * 다음 스텝으로 넘어갈 때 delay만큼 대기
 */
export function useIdle(stepIdx: number, delay: number = 500): boolean {
  const [isIdle, setIsIdle] = React.useState(false)
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)
  const [curStepIdx] = useActiveStepIdxAtom()
  const prevStepIdxRef = React.useRef<number>(curStepIdx)

  React.useEffect(() => {
    if (prevStepIdxRef.current === stepIdx && curStepIdx === stepIdx + 1) {
      setIsIdle(true)

      timerRef.current = setTimeout(() => {
        setIsIdle(false)
        prevStepIdxRef.current = curStepIdx
      }, delay)
    } else {
      setIsIdle(false)
      prevStepIdxRef.current = curStepIdx
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [curStepIdx, stepIdx, delay])

  return isIdle
}
