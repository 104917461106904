import { IconArrowLeft } from '@imwebme/icon'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import {
  HydrateSectionAtom,
  OrderSection,
  useOrderSectionAtom,
  type TProductCard001,
} from '~t'
import { toast } from 'react-toastify'
import { OrderEditCalcCard } from '~t'
import { Trans } from 'react-i18next'
import { useCalc } from '~/container/order/order-edit/order-edit-fn'
import OrderSectionContainerOverlay from './order-return-section'
import { __, useLL } from '~/shared/i18n'
import { useNavigate } from 'react-router-dom'
import { Link } from '~/shared/components/link'
import { OrderEditHeader } from '~t/order-edit/order-edit-header'
import { OrderHeaderStep } from '~t/order-edit/order-edit-header/partials/order-header-step'
import { Button } from '@imwebme/clay-components'
import { OrderTimelinePack } from '../../order-timeline'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useCurrency } from '~/shared/hooks/use-currency'
import { 상수_판매채널 } from '~/entities/@x'

const OrderReturnCalcCard = () => {
  const currency = useCurrency()
  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price: useCalc(),
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b className="typo-clay-heading-xlarge-bold" /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}

function Header({
  orderNo,
  onClicknextMemo,
}: {
  orderNo: number
  onClicknextMemo: () => void
}) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to={'/order/:saleChannel/:orderCode'}
          params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
          className="flex gap-x-[4px] items-centerflex items-center"
        >
          <IconArrowLeft className="stroke-[2]" />
          <span>{orderNo}</span>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('반품')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: '품목 선택',
              active: true,
            },
            {
              id: '2',
              label: '반품 정보 입력',
              active: false,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <Button
          native={{ type: 'button' }}
          variant="primary"
          onClick={onClicknextMemo}
          text={__('다음')}
        />
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

interface TOrderReturn {
  readyItems: {
    id: string
    label: string
    value: TProductCard001.props[]
    statusCd: string
  }[]
  orderNo: number
}
const OrderReturn = ({ readyItems, orderNo }: TOrderReturn) => {
  const router = useNavigate()
  const orderCode = useOrderCode()
  const location = useLL()
  if (orderCode === undefined) {
    throw new Error('🚀 ~ file: order-return.tsx:66 ~ OrderReturn')
  }
  const updateItems = useOrderSectionAtom.valueUpdateAll()

  const pathName = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : `${location.pathname}`

  // TODO: router > LLink 마이그레이션
  const onClicknextMemo = () => {
    updateItems && updateItems.length > 0
      ? router(`${pathName}/info`)
      : toast.error(__('반품할 수 있는 상품이 없습니다'))
  }

  return (
    <HydrateSectionAtom initialValues={readyItems}>
      <Header orderNo={orderNo} onClicknextMemo={onClicknextMemo} />
      <div className="mt-[32px] mb-[70px] min-w-[992px]">
        <div className="w-[970px] mx-auto">
          <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-x-[20px] gap-y-[20px]">
            <div className="grid gap-y-[20px]">
              {readyItems.length === 0 && (
                <OrderSection name="반품섹션">
                  <OrderSection.Slot name="title">
                    <div className="text-clay-semantic-textCritical">
                      {__('반품가능한 섹션이 없습니다.')}
                    </div>
                  </OrderSection.Slot>
                  <OrderSection.Slot name="body">404</OrderSection.Slot>
                </OrderSection>
              )}
              {pipe(
                readyItems,
                O.fromNullable,
                O.fold(
                  () => undefined,
                  flow(
                    A.mapWithIndex((index, readyItem) => (
                      <div key={`OrderSectionContainerOverlay-${index}`}>
                        <OrderSectionContainerOverlay
                          id={readyItem.id}
                          label={readyItem.label}
                          items={readyItem.value}
                          statusCd={readyItem.statusCd}
                        />
                      </div>
                    ))
                  )
                )
              )}
            </div>
            <div>
              <OrderReturnCalcCard />
            </div>
          </div>
        </div>
      </div>
    </HydrateSectionAtom>
  )
}

export default OrderReturn
