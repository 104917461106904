import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TReturnDoneCustomerBase } from './return-done-customer.type'
import { useReturnDoneCustomerAtom } from './return-done-customer.store'
import { ContentsBanner, ContentsBannerProps } from '@imwebme/clay-components'

const ReturnDoneCustomer = React.lazy(() =>
  import('./return-done-customer').then((module) => ({
    default: React.memo(module.ReturnDoneCustomer),
  }))
)

interface TReturnDoneCustomerContainer extends TReturnDoneCustomerBase {}
export function ReturnDoneCustomerContainer(
  props: TReturnDoneCustomerContainer
) {
  const [open, setState] = useReturnDoneCustomerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ReturnDoneCustomer {...props} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

interface TTReturnDoneCustomerBanner extends TReturnDoneCustomerBase {
  variant?: ContentsBannerProps['variant']
  text?: ContentsBannerProps['text']
  buttonText?: string
}
export function ReturnDoneCustomerBanner({
  variant = 'information',
  text = __('구매자가 접수한 반품 건이에요.'),
  buttonText = __('사유보기'),
  ...props
}: TTReturnDoneCustomerBanner) {
  const [open, setState] = useReturnDoneCustomerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <ContentsBanner
      type="secondary"
      variant={variant}
      text={text}
      button={{ text: buttonText, onClick: () => onOpenChange(true) }}
    />
  )
}
