import { match } from 'ts-pattern'
import {
  Clay,
  Typography,
  Flex,
  ButtonGroup,
  Button,
  ClayProps,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import {
  useOnboardingStep,
  useOnboardingComplete,
} from '~/container/onboarding/onboarding.store'
import { TOOLTIP_DATA } from './onboarding-tooltip.data'
import { STEPS } from '~/container/onboarding/onboarding.constants'

export type TArrow =
  | 'top-center'
  | 'top-left'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'left-top'
  | 'left-center'
  | 'left-bottom'
  | 'right-top'
  | 'right-center'
  | 'right-bottom'
  | 'none'

/**
 * @todo 클레이에 온보딩 툴팁 컴포넌트가 개발되면 마이그레이션합니다.
 */
export const OnboardingTooltip = () => {
  const [step, updateStep] = useOnboardingStep()
  const [_, setOnboardingComplete] = useOnboardingComplete()
  const stepName = STEPS[step - 1]
  const tooltip = TOOLTIP_DATA[stepName]

  return (
    <Clay
      borderRadius={vars.rounded.large}
      boxShadow={vars.dropShadow.layer}
      backgroundColor={vars.semantic.color.surface}
      padding={vars.spacing[4]}
      width={240}
      zIndex={2}
      position="absolute"
      {...tooltipStyle(step)}
    >
      <Typography
        variant="heading-medium-bold"
        sx={{ marginBottom: vars.spacing[3] }}
      >
        {__(tooltip.title)}
      </Typography>
      <Typography
        variant="body-medium"
        colorToken="textSub"
        whiteSpace="pre-line"
      >
        {__(tooltip.desc)}
      </Typography>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop={vars.spacing[6]}
      >
        <Typography variant="label-medium" colorToken="textSub">
          {step}/{STEPS.length}
        </Typography>
        <ButtonGroup size="small" isInline>
          {step > 1 && (
            <Button
              variant="secondary"
              text={__('이전')}
              size="small"
              onClick={() => updateStep('prev')}
            />
          )}

          {match(step)
            .with(STEPS.length, () => (
              <Button
                text={__('완료')}
                variant="primary"
                size="small"
                onClick={() => {
                  setOnboardingComplete(true)
                  window.parent.postMessage(
                    {
                      source: 'close-window',
                      message: 'close-window',
                    },
                    '*'
                  )
                }}
              />
            ))
            .otherwise(() => (
              <Button
                text={__('다음')}
                variant="primary"
                size="small"
                onClick={() => updateStep('next')}
              />
            ))}
        </ButtonGroup>
      </Flex>

      {/* 화샬표 */}
      {match(tooltip.arrow)
        .with('none', () => null)
        .otherwise(() => (
          <Clay position="absolute" {...arrowStyle(tooltip.arrow)}>
            <Clay
              width={0}
              height={0}
              borderLeft={'6px solid transparent'}
              borderRight={'6px solid transparent'}
              borderBottom={`6px solid ${vars.semantic.color.border}`}
            />
            <Clay
              width={0}
              height={0}
              borderLeft={'5px solid transparent'}
              borderRight={'5px solid transparent'}
              borderBottom={`5px solid ${vars.semantic.color.surface}`}
              position="absolute"
              bottom={0}
              left="50%"
              transform="translateX(-50%)"
            />
          </Clay>
        ))}
    </Clay>
  )
}

const tooltipStyle = (step: number): ClayProps<'div'> =>
  match(step)
    .with(1, () => ({ top: 361, left: 380, width: 260 }))
    .with(2, () => ({ top: 166, left: 316 }))
    .with(3, () => ({ top: 238, right: 152 }))
    .with(4, () => ({ top: 94, left: 260 }))
    .with(5, () => ({ top: 466, left: 418 }))
    .with(6, () => ({ bottom: 114, left: 'calc(50% - 184px)' }))
    .with(7, () => ({ top: 342, left: 166, width: 260 }))
    .with(8, () => ({ top: 462, right: 106, width: 280 }))
    .with(9, () => ({ top: 82, right: 186, width: 260 }))
    .with(10, () => ({ top: 542, right: 56, width: 280 }))
    .with(11, () => ({ top: 82, right: 40, width: 280 }))
    .with(12, () => ({
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      width: 280,
    }))
    .otherwise(() => ({}))

const arrowStyle = (arrow: TArrow): ClayProps<'div'> =>
  match(arrow)
    .with('top-right', () => ({ top: -6, right: 12 }))
    .with('top-left', () => ({ top: -6, left: 12 }))
    .with('left-bottom', () => ({
      transform: 'rotate(-90deg) translateY(-50%)',
      left: -6,
      bottom: 12,
    }))
    .with('bottom-center', () => ({
      transform: 'translateX(-50%) rotate(180deg)',
      left: '50%',
      bottom: -6,
    }))
    .otherwise(() => ({}))
