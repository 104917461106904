import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  orderCode: string
  sectionCode: string
}
type AtomType = {
  key: string
  value: {
    open: boolean
    status: 'idle' | 'loading' | 'success' | 'error'
  }
}
const ReturnCompleteConfirmState = atomFamily(
  ({ key, value }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = `${key},ReturnCompleteConfirmState`
    return at
  },
  (a, b) => a.key === b.key
)

export const useReturnCompleteConfirmAtom = {
  key: (p: AtomKey) =>
    `${p.orderCode}-${p.sectionCode}:return_complete_confirm`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          ReturnCompleteConfirmState({
            key: useReturnCompleteConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          ReturnCompleteConfirmState({
            key: useReturnCompleteConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          ReturnCompleteConfirmState({
            key: useReturnCompleteConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          ReturnCompleteConfirmState({
            key: useReturnCompleteConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
}
