import React from 'react'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { __, __g } from '~/shared/i18n'
import {
  useSelectedProdCommons,
  useDeliverySetupAtom,
} from '~/container/order-create/order-create.store'
import { Radio } from '@imwebme/clay-components'
import {
  T상수_배송결제타입,
  상수_배송결제타입,
  상수_배송타입,
} from '~/entities/@x'
import { match } from 'ts-pattern'

export function DelivPayTypeField() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const selectedProdCommons = useSelectedProdCommons()

  const disabled = React.useMemo(() => {
    const 배송비없는경우 =
      (deliverySetup._deliveryTypeCd === 상수_배송타입.다운로드 ||
        deliverySetup._deliveryTypeCd === 상수_배송타입.방문수령 ||
        deliverySetup._deliveryTypeCd === 상수_배송타입.배송없음) &&
      deliverySetup._deliveryPayTypeCd === 상수_배송결제타입.배송비없음

    const 퀵서비스인경우 =
      deliverySetup._deliveryTypeCd === 상수_배송타입.퀵서비스 &&
      deliverySetup._deliveryPayTypeCd === 상수_배송결제타입.착불결제

    return 배송비없는경우 || 퀵서비스인경우
  }, [deliverySetup._deliveryTypeCd, deliverySetup._deliveryPayTypeCd])

  return (
    <FieldLayout title={__('배송비')}>
      <Radio.Group
        direction="row"
        value={
          deliverySetup._deliveryPayTypeCd ??
          selectedProdCommons.delivPayTypeList[0]
        }
        onChangeValue={(v) =>
          setDeliverySetup({ _deliveryPayTypeCd: v as T상수_배송결제타입 })
        }
      >
        {selectedProdCommons.delivPayTypeList.map((el) => (
          <Radio.Label
            key={el}
            label={getLabelByCode(el)}
            name="deliveryPayTypeCd"
            value={el}
            disabled={disabled}
          />
        ))}
      </Radio.Group>
    </FieldLayout>
  )
}

function getLabelByCode(code: T상수_배송결제타입): string {
  return match(code)
    .with(상수_배송결제타입.선결제, () => __('선불'))
    .with(상수_배송결제타입.착불결제, () => __('착불'))
    .with(상수_배송결제타입.배송비없음, () => __('배송비 없음'))
    .otherwise(() => '')
}
