import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { 모델_외부채널_주문섹션_품목_클레임정보 } from './external-order-item-claim-info.model'
import { externalOrderClaimInfoQueryOptions } from '../../external-order-claim-info.query'
import { useSuspenseQuery } from '@tanstack/react-query'
import { TGetResExternalOrderDto } from '~/entities/external-order/external-order.type'

export const useExternalOrderItemClaimInfo = ({
  orderCode,
  saleChannelItem,
  saleChannelItemList,
}: {
  orderCode: string
  saleChannelItem?: TGetResExternalOrderDto['saleChannelItemList'][0]
  saleChannelItemList: TGetResExternalOrderDto['saleChannelItemList']
}) => {
  const channelProductNoList = pipe(
    saleChannelItemList,
    A.filter((e) => !!e.channelOrderItemNo),
    A.map((e) => e.channelOrderItemNo as string)
  )
  const { data: $claimInfo } = useSuspenseQuery({
    ...externalOrderClaimInfoQueryOptions({ orderCode })({
      channelProductNoList,
    }),
    select({ list }) {
      const result = pipe(
        list,
        A.findFirst((e) =>
          match(saleChannelItem)
            .with(
              {
                claimId: e.claimId,
              },
              () => true
            )
            .otherwise(() => false)
        ),
        O.getOrElseW(() => null)
      )

      return new 모델_외부채널_주문섹션_품목_클레임정보(result)
    },
  })
  return $claimInfo
}
