import { atomFamily, useHydrateAtoms } from 'jotai/utils'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import React from 'react'
import deepEqual from 'fast-deep-equal'
import { type TProductCard001 } from '../../product'

type TOrderSection = {
  id: string
  value?: TProductCard001.props[]
}
// init한데이터를 계속 가지고있는 atom
export const orderSectionInitAtom = atom<TOrderSection[] | undefined>(undefined)
orderSectionInitAtom.debugLabel = 'orderSectionInitAtom'
// 섹션 읽기
export const orderSectionAllAtom = atom<TOrderSection[] | undefined>(undefined)
orderSectionAllAtom.debugLabel = 'orderSectionAllAtom'

export const HydrateSectionAtom = ({
  initialValues,
  children,
}: React.PropsWithChildren<{
  initialValues: TOrderSection[]
}>) => {
  // initialising on state with prop on render here
  useHydrateAtoms([
    [orderSectionAllAtom, initialValues],
    [orderSectionInitAtom, initialValues],
  ])
  const [archive, setArchive] = useAtom(
    React.useMemo(() => orderSectionInitAtom, [])
  )
  const setSectionAll = useSetAtom(React.useMemo(() => orderSectionAllAtom, []))

  React.useEffect(() => {
    /**
     * HydrateAtom에서는 atomFamily를 사용하지 않기 때문에
     * 해당 값은 다른 주문서페이지에서 초기화로 저장된 상태값을 갖고있을 수 있다.
     * 그래서 HydrateAtom을 초기화해줘야한다.
     */

    // archive의 아톰상태는 항상 데이터로 넘어온 값으로 등록된다.
    // 그래서 유저 인터렉션에 따라 변경되지 않아서 archive의 값이 변경되지 않는다.
    // 초기값과 archive가 같지 않다면 archive를 초기값으로 변경한다.
    if (!deepEqual(initialValues, archive)) {
      setArchive(initialValues)
      // archive와 동일하게 초기화시켜준다.
      // 데이터는 결국 sectionAll에서 가져온다.
      setSectionAll(initialValues)
    }
  }, [initialValues, archive, setArchive, setSectionAll])
  return <>{children}</>
}

export const orderSectionAtom = atomFamily(
  // 객체로해야하는 이유는 atomFamily이라서
  ({ id: key }: { id: string }) => {
    const at = atom(
      (get) => {
        const section = get(orderSectionAllAtom)
        const resultItem = pipe(
          section,
          O.fromNullable,
          O.fold(
            () => undefined,
            flow(
              A.findFirst((item) => item.id === key),
              (e) => e,
              O.toUndefined
            )
          )
        )
        return resultItem
      },
      (
        get,
        set,
        arg?:
          | TProductCard001.props[]
          | {
              value: TProductCard001.props[]
              action?: 'force'
            }
      ) => {
        const items = get(orderSectionAllAtom)
        if (!arg) {
          set(
            orderSectionAllAtom,
            pipe(
              items,
              O.fromNullable,
              O.fold(() => undefined, flow(A.filter((item) => item.id !== key)))
            )
          )
        } else {
          const args = Array.isArray(arg) ? arg : arg.value
          const updateState = pipe(
            items,
            O.fromNullable,
            O.fold(
              () => [{ id: key, value: args }],
              flow(
                A.map((item) => {
                  if (item.id === key) {
                    // items.value 안쪽에있는 값들을 업데이트한다.
                    // 동일한 것들이있다면.
                    const updateValue = pipe(
                      item.value,
                      O.fromNullable,
                      O.fold(
                        () => undefined,
                        flow(
                          A.map((e) => {
                            // update로직
                            const result = pipe(
                              args,
                              A.findFirst((f) => f.id === e.id),
                              O.fold(
                                () => e,
                                (f) => f
                              )
                            )
                            return result
                          })
                        )
                      )
                    )
                    // 업데이트 이외에 추가되는 상품을 계산한다. items.value - arg
                    // 추가되는 상품이 있다면 추가한다.
                    const addValue = pipe(
                      args,
                      A.filter((e) =>
                        pipe(
                          item.value,
                          O.fromNullable,
                          O.fold(
                            () => true,
                            flow(
                              A.findFirst((f) => f.id === e.id),
                              O.fold(
                                () => true,
                                () => false
                              )
                            )
                          )
                        )
                      )
                    )
                    // 업데이트하는 로직말고 set하면서 전달되는 값으로 업데이트한다.
                    if (!Array.isArray(arg) && arg.action === 'force') {
                      return {
                        ...item,
                        value: args,
                      }
                    }
                    return {
                      ...item,
                      value: [...(updateValue || []), ...addValue],
                    }
                  }
                  return item
                })
              )
            )
          )
          set(orderSectionAllAtom, updateState)
        }
      }
    )
    if (import.meta.env.VITE_NODE_ENV !== 'production') {
      at.debugLabel = key
    }
    return at
  },
  (a, b) => a.id === b.id
)

export const orderSectionRowAtom = atomFamily(
  ({
    parentKey,
    key,
  }: {
    parentKey: string
    key: string
    value?: TProductCard001.props
  }) => {
    const at = atom(
      (get) => {
        // 섹션의 상태값 가져오기
        const section = get(orderSectionAtom({ id: parentKey }))
        const resultItem = pipe(
          section,
          O.fromNullable,
          O.fold(
            () => undefined,
            // 섹션상태값에서 품목의 상태값 가져오기
            flow(
              (s) => s.value,
              O.fromNullable,
              O.fold(
                () => undefined,
                flow(
                  A.findFirst((item) => item.id === key),
                  O.toUndefined
                )
              )
            )
          )
        )
        return resultItem
      },
      (get, set, arg?: TProductCard001.props) => {
        const state = orderSectionAtom({ id: parentKey })
        const section = get(state)
        const updateState = pipe(
          section,
          O.fromNullable,
          O.fold(
            () => undefined,
            flow(
              (e) => e.value,
              O.fromNullable,
              O.fold(
                () => undefined,
                (q) =>
                  pipe(
                    arg,
                    O.fromNullable,
                    O.fold(
                      // 섹션에 value에서 품목의 id가 같지 않은 것만 가져온다.
                      // id와 맞지 않는 것만 가져오기 때문에 삭제된 것이 된다.
                      () =>
                        pipe(
                          q,
                          A.filter((v) => v.id !== key)
                        ),
                      (w) =>
                        pipe(
                          q,
                          A.map((item) => {
                            if (item.id === key) {
                              return { ...item, ...w }
                            }
                            return item
                          })
                        )
                    )
                  )
              )
            )
          ),
          O.fromNullable,
          (e) => e,
          O.fold(
            () => undefined,
            flow((value) => ({
              value,
              action: 'force' as const,
            }))
          ),
          (e) => e
        )
        set(state, updateState)
      }
    )

    if (import.meta.env.VITE_NODE_ENV !== 'production') {
      at.debugLabel = `${parentKey}--${key}`
    }
    return at
  },
  (a, b) => a.key + a.parentKey === b.key + b.parentKey
)

// 전체 섹션에대해서 변경된 상태값을 가져온다.
export const orderSectionUpdateAllState = atom((get) => {
  const osa = get(orderSectionAllAtom)
  return pipe(
    osa,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.map(
          flow((e) => ({
            ...e,
            value: pipe(
              e.value,
              O.fromNullable,
              O.fold(() => [], flow(A.filter((v) => (v.qtyChange || 0) > 0)))
            ),
          }))
        ),
        A.filter((e) => e.value.length > 0)
      )
    )
  )
})
orderSectionUpdateAllState.debugLabel = 'orderSectionUpdateAllState'

// // 첫로딩에서 사용하는 atom
// export const serializeOrderSectionReadyAtom = atom(null, (get, set, args) => {
//   log.debug('test')
// })

export const useOrderSectionAtom = {
  // 전체 섹션 아카이브 (READY ONLY)
  archive: () => useAtomValue(React.useMemo(() => orderSectionInitAtom, [])),
  // 전체 섹션
  sectionAll: () => useAtom(React.useMemo(() => orderSectionAllAtom, [])),
  valueSectionAll: () =>
    useAtomValue(React.useMemo(() => orderSectionAllAtom, [])),
  setSectionAll: () => useSetAtom(React.useMemo(() => orderSectionAllAtom, [])),
  // 섹션 by ID
  section: (id: string) =>
    useAtom(React.useMemo(() => orderSectionAtom({ id }), [id])),
  valueSection: (id: string) =>
    useAtomValue(React.useMemo(() => orderSectionAtom({ id }), [id])),
  setSection: (id: string) =>
    useSetAtom(React.useMemo(() => orderSectionAtom({ id }), [id])),
  // 섹션 by ID, 품목 by ID
  row: (parentKey: string, key: string) =>
    useAtom(
      React.useMemo(
        () => orderSectionRowAtom({ parentKey, key }),
        [parentKey, key]
      )
    ),
  valueRow: (parentKey: string, key: string) =>
    useAtomValue(
      React.useMemo(
        () => orderSectionRowAtom({ parentKey, key }),
        [parentKey, key]
      )
    ),
  setRow: (parentKey: string, key: string) =>
    useSetAtom(
      React.useMemo(
        () => orderSectionRowAtom({ parentKey, key }),
        [parentKey, key]
      )
    ),
  // 업데이트된 상태만 가져오기
  updateAll: () => useAtom(React.useMemo(() => orderSectionUpdateAllState, [])),
  valueUpdateAll: () =>
    useAtomValue(React.useMemo(() => orderSectionUpdateAllState, [])),
}
