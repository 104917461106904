import { z } from 'zod'

export const 상수_배송타입 = {
  배송없음: 'ODT01', // 배송없음
  택배: 'ODT02', // 택배
  다운로드: 'ODT03', // 다운로드
  정기구독: 'ODT04', // 정기구독
  직접배송: 'ODT05', // 직접배송
  퀵서비스: 'ODT06', // 퀵서비스
  방문수령: 'ODT07', // 방문수령
  편의점배송: 'ODT08', // 편의점 배송
} as const

const type = z.nativeEnum(상수_배송타입)
export type T상수_배송타입 = z.infer<typeof type>
