import { queryOptions } from '@tanstack/react-query'
import { getExternalClaims, convertObjectClaims2Array } from '.'

export function externalClaimsQueryOptions({
  saleChannelIdx,
  claimType,
}: {
  saleChannelIdx: 1 | 2 | 3
  claimType: 'cancel' | 'return' | 'delay'
}) {
  return queryOptions({
    queryKey: ['external-claims', saleChannelIdx, claimType],
    queryFn: getExternalClaims({ saleChannelIdx, claimType }),
    select: convertObjectClaims2Array,
  })
}
