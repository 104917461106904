import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { atomFamily } from 'jotai/utils'
import { atom, useAtom } from 'jotai'
import React from 'react'
import type {
  TItem,
  TOptionDetails,
  T_VIEW_Item,
} from '../../product-search.type'
import { productSearchItemState } from '../../product-search.store'
import { Product } from '../../product-search-item-class'

// 상품(id)옵션(id)의 상태를 관리하는 atom
export const RequiredOptionState = atomFamily(
  ({
    searchId,
    prodCode,
    optionDetailCode,
  }: {
    searchId: string
    prodCode: string
    optionDetailCode: string
    // valueCode: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      // ==================== get ====================
      (get) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        if (prodData === undefined) {
          return undefined
        }
        const prod = new Product(prodData, searchId)
        if (prod.childComponentType === '필수조합옵션') {
          const option = pipe(
            prodData,
            (e) => e.optionDetails,
            O.fromNullable,
            O.fold(
              () => undefined,
              flow(
                A.findFirst((e) => e.optionDetailCode === optionDetailCode),
                O.fold(
                  () => undefined,
                  (e) => e
                )
              )
            )
          )
          return option
        }
        return undefined
      },
      // ==================== set ====================
      // 주문서전체 데이터에서 prodCode, optionDetailCode를가지고 optionDetailCode에 해당하는 객체를 업데이트해준다.
      (get, set, arg: TOptionDetails) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        const prod = new Product(prodData, searchId)
        if (prod.childComponentType === '필수조합옵션') {
          // optionDetailCode를 가지고 찾는다. optionDetail은 배열이다.
          const optionDetails = pipe(prodData, (e) => e.optionDetails)
          if (optionDetails === undefined) {
            // throw를 던지지만 필수조합옵션은 optionDetails가 없을 수 없다.
            throw new Error('optionDetails is undefined')
          }
          // optionDetailCode를 가지고 인덱스를 찾는다.
          const optionDetailsIndex = pipe(
            optionDetails,
            A.findIndex((e) => e.optionDetailCode === optionDetailCode),
            O.fold(
              () => undefined,
              (e) => e
            )
          )
          if (optionDetailsIndex === undefined) {
            // throw를 던지지만 필수조합옵션은 optionDetailsIndex가 없을 수 없다.
            throw new Error('optionDetailsIndex is undefined')
          }

          // prodData에서 optionDetails를 업데이트한다.
          const newProdData = {
            ...prodData,
            optionDetails: pipe(
              optionDetails,
              A.updateAt(optionDetailsIndex, arg),
              O.getOrElse(() => optionDetails)
            ),
          }
          set(productSearchItemAtom, newProdData)
        }
      }
    )
    at.debugLabel = `search-product(${prodCode})-optionDetailCode(${optionDetailCode})`
    return at
  },
  (a, b) =>
    a.searchId === b.searchId &&
    a.prodCode === b.prodCode &&
    a.optionDetailCode === b.optionDetailCode
)

export const useRequiredOptionAtom = {
  option: (searchId: string, prodCode: string, optionDetailCode: string) =>
    useAtom(
      React.useMemo(
        () =>
          RequiredOptionState({
            searchId,
            prodCode,
            optionDetailCode,
          }),
        [searchId, prodCode, optionDetailCode]
      )
    ),
}
