/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import {
  patchPayloadBackShippingCompleteDto,
  targetItemInformationDto,
} from '.'

export const patchPayloadBackShippingCompleteRdo =
  patchPayloadBackShippingCompleteDto.extend({
    targetItemInformation: z.array(
      targetItemInformationDto.extend({
        _check: z.boolean().optional(),
      })
    ),
  })
