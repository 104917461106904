import { ApiClient, ApiResponse } from '~/shared/api'
import type { TGetResPendingCancelInfoSchema } from './pending-cancel-info.type'
import { getResPendingCancelInfoSchema } from './pending-cancel-info.schema'

const axios = ApiClient()

/**
 * @API문서 https://api.oms.imstage.me/admin/docs#tag/%EC%A3%BC%EB%AC%B8/operation/OrderController_findPendingCancelInfoByOrder
 * @description 실제로 승인을 취소하지는 않고 취소할 금액에 대해 예상되는 금액을 조회합니다.
 */
export async function getPendingCancelInfo({
  orderCode,
}: {
  orderCode: string
}) {
  const result = await axios
    .get<
      ApiResponse<TGetResPendingCancelInfoSchema>
    >(`/order/${orderCode}/pending-cancel-info`)
    .then((res) => res.data)

  getResPendingCancelInfoSchema.parse(result.data)
  return result.data
}
