import React from 'react'
import { TDeliveryBannerManualPickupBase } from './delivery-banner-manual-pickup.type'

const DeliveryBannerManualPickupContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.DeliveryBannerManualPickup }))
)

interface TDeliveryBannerManualPickupPack
  extends TDeliveryBannerManualPickupBase {}
export function DeliveryBannerManualPickupPack(
  props: TDeliveryBannerManualPickupPack
) {
  return (
    <React.Suspense>
      <DeliveryBannerManualPickupContainer {...props} />
    </React.Suspense>
  )
}
