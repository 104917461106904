import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { AxiosResponse } from 'axios'
import { ApiClient, type ApiVoidResponse } from '~/shared/api'
import type { TPostPayloadBulkActionSectionInvoiceExcelAllSchema } from './bulk-action-section-invoice-excel-all.type'
import { extractFilenameFromContentDisposition } from '~/shared/utils'

const axios = ApiClient()

export async function postBulkActionSectionInvoiceExcelAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoiceExcelAllSchema
}) {
  type R = typeof body
  type T = ApiVoidResponse
  const res = await axios
    .post<T, AxiosResponse<BlobPart>, R>(
      '/order/bulk-action/section/invoice/excel/all',
      body,
      {
        responseType: 'blob',
      }
    )
    .catch((err) => {
      log.debug(err)
      return new Error(err)
    })

  // res가 error가 아니라면
  if (res instanceof Error) {
    return res
  } else {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers['content-type'] })
    )
    const contentDisposition = res.headers['content-disposition'] as
      | string
      | undefined
    let fileName = 'unknown'
    if (contentDisposition) {
      fileName = pipe(
        contentDisposition,
        extractFilenameFromContentDisposition,
        O.fold(
          () => 'unknown.xlsx',
          (str) => str
        )
      )
    }
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    link.target = '_blank'
    link.click()
    return null
  }
}
