import { queryOptions } from '@tanstack/react-query'
import { getExternalOrder } from '.'

export function externalOrderQueryOptions({
  orderCode,
}: {
  orderCode: string
}) {
  return queryOptions({
    queryKey: ['orderDetail', orderCode, 'external'],
    queryFn: getExternalOrder({ orderCode }),
    select: (data) => data.data,
  })
}
