import { useMemo, useRef } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { OrderTimelineBody } from './order-timeline-body'
import * as API from '../order-timeline.api'
import { TYN } from '~/entities/@x'
import { useOrderHistory } from '../order-timeline.hook'
import { toast } from 'react-toastify'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { orderHistoryQueryOptions } from '~/entities/order-history'
import { __e, __ } from '~/shared/i18n'
import { useTimelineFilter } from '~/container/order/order-timeline/order-timeline.store'
import { OrderTimelineEmptyMemo } from './order-timeline-empty-memo'
import { Clay, TextButton } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

const MEMO_DELETE_TIMEOUT = 5000

export function OrderTimelineBodyContainer() {
  const orderCode = useOrderCode()
  const queryClient = useQueryClient()
  const undoDeleteRef = useRef<() => void>()
  const [timelineFilter] = useTimelineFilter()
  const { orderHistoryList } = useOrderHistory({
    orderCode,
  })

  const {
    mutateAsync: patchOrderMemoMutation,
    isPending: isPatchingOrderMemo,
  } = useMutation({
    mutationFn: API.patchOrderMemo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderHistoryQueryOptions(orderCode).queryKey,
      })
      toast.success('메모를 수정했어요')
    },
    onError: (e) => {
      toast.error(
        e.response?.data?.code
          ? __e(e.response.data.code)
          : __('메모 수정에 실패했어요')
      )
    },
  })

  // 타임아웃 이후에 메모 삭제 요청을 보냄 (Optimistic Updates)
  const { mutate: deleteOrderMemoMutation, isPending: isDeletingOrderMemo } =
    useMutation({
      mutationFn: ({
        orderCode: _orderCode,
        idx,
      }: {
        orderCode: string
        idx: number
      }) => {
        const mutationPromise = new Promise((resolve, reject) => {
          const mutationTimeout = setTimeout(() => {
            API.deleteOrderMemo({ orderCode: _orderCode, idx })
              .then((result) => resolve(result))
              .catch((err) => reject(err))
          }, MEMO_DELETE_TIMEOUT + 1000)

          const cancelMutation = () => {
            clearTimeout(mutationTimeout)
            reject({ message: 'mutationCancelled' })
          }

          undoDeleteRef.current = cancelMutation
        })
        return mutationPromise
      },
      onMutate: async ({ idx }: { idx: number }) => {
        const queryKey = orderHistoryQueryOptions(orderCode).queryKey
        await queryClient.cancelQueries({
          queryKey,
        })
        const prevQueryData = queryClient.getQueryData(
          orderHistoryQueryOptions(orderCode).queryKey
        )
        queryClient.setQueryData(queryKey, (old) => {
          if (!old) {
            return old
          }
          const nextOrderHistoryList = old.data.orderHistoryList.filter(
            (item) => !(item.historyType === 'memo' && item.idx === idx)
          )
          return {
            ...old,
            data: {
              ...old.data,
              orderHistoryList: nextOrderHistoryList,
            },
          }
        })
        return { prevQueryData }
      },
      onError: (e, _, context) => {
        queryClient.setQueryData(
          orderHistoryQueryOptions(orderCode).queryKey,
          context?.prevQueryData
        )
        if (e.message === 'mutationCancelled') {
          log.debug('메모 삭제 취소')
        } else {
          toast.error(
            e.response?.data?.code
              ? __e(e.response.data.code)
              : __('메모 삭제에 실패했어요')
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: orderHistoryQueryOptions(orderCode).queryKey,
        })
      },
    })

  const memoActions = useMemo(
    () => ({
      toggleIsDone: ({ idx, value }: { idx: number; value: TYN }) => {
        patchOrderMemoMutation({
          orderCode,
          idx,
          params: { isDone: value },
        })
      },
      delete: ({ idx }: { idx: number }) => {
        toast.success(__('메모를 삭제했어요'), {
          autoClose: MEMO_DELETE_TIMEOUT,
          pauseOnFocusLoss: false,
          closeButton: ({ closeToast }) => (
            <Clay marginLeft={vars.spacing[2]} paddingLeft={vars.spacing[3]}>
              <TextButton
                text={__('되돌리기')}
                variant="on"
                onClick={(e) => {
                  undoDeleteRef.current?.()
                  closeToast(e)
                }}
              />
            </Clay>
          ),
        })
        deleteOrderMemoMutation({
          orderCode,
          idx,
        })
      },
      update: ({ idx, value }: { idx: number; value: string }) =>
        patchOrderMemoMutation({
          orderCode,
          idx,
          params: { memo: value },
        }),
    }),
    [orderCode]
  )

  if (timelineFilter === 'MEMO' && Object.keys(orderHistoryList).length === 0) {
    return <OrderTimelineEmptyMemo />
  }
  return (
    <OrderTimelineBody
      orderHistoryList={orderHistoryList}
      memoActions={memoActions}
      isDeletingOrderMemo={isDeletingOrderMemo}
      isPatchingOrderMemo={isPatchingOrderMemo}
    />
  )
}
