import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TGetResBankTransferAccountsDto,
  getResBankTransferAccountsDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/tW3Ime
 * @description 등록되어있는 무통장입금 계좌 리스트
 */
export function getBankTransferAccounts() {
  return async function () {
    const result = await axios
      .get<
        ApiResponse<TGetResBankTransferAccountsDto>
      >('/site/bank-transfer-accounts')
      .then((res) => res.data)

    getResBankTransferAccountsDto.parse(result.data)
    return result.data
  }
}
