import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { Check, Minus } from '@imwebme/clay-icons'
import { match } from 'ts-pattern'
import { cn } from '~/shared/utils'

const checkboxVariants = cva(
  'relative inline-flex items-center justify-content shrink-0 focus:ring-0 focus:ring-offset-0 transition-all has-[:disabled]:cursor-not-allowed',
  {
    variants: {
      size: {
        medium:
          'w-[16px] h-[16px] rounded-[3.5px] [&>div]:w-[12px] [&>svg]:h-[12px]',
        large: 'w-[24px] h-[24px] rounded-[5.5px]',
      },
      checked: {
        true: `bg-clay-semantic-actionPrimary text-clay-semantic-iconOn
        has-[:hover:enabled]:bg-clay-semantic-actionPrimaryHover
        has-[:disabled]:opacity-30
        `,
        false: `bg-clay-semantic-surface border border-solid border-clay-semantic-border 
          has-[:hover:enabled]:bg-clay-semantic-fieldPrimary has-[:hover:enabled]:border-clay-semantic-actionPrimaryHover
          has-[:disabled]:border-none has-[:disabled]:bg-clay-semantic-actionPrimaryDisabled
          `,
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  }
)

export interface CheckboxProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'size' | 'type'>,
    VariantProps<typeof checkboxVariants> {
  indeterminate?: boolean
  checked?: boolean
  onChangeChecked?: (checked: boolean) => void
}

function Checkbox({
  className,
  size,
  indeterminate = false,
  checked: controlledChecked,
  onChange,
  onChangeChecked,
  ...props
}: CheckboxProps) {
  const isControlled = controlledChecked !== undefined
  const [checked, setChecked] = React.useState(false)

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const _checked = ev.target.checked

    if (!isControlled) {
      setChecked(_checked)
    }

    onChange?.(ev)
    onChangeChecked?.(_checked)
  }

  return (
    <div
      className={cn(
        checkboxVariants({
          size,
          checked: isControlled ? controlledChecked : checked,
        }),
        className
      )}
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center">
        {match({
          indeterminate,
          checked: isControlled ? controlledChecked : checked,
        })
          .with({ checked: true, indeterminate: true }, () => (
            <Minus color="inherit" />
          ))
          .with({ checked: true, indeterminate: false }, () => (
            <Check color="inherit" />
          ))
          .otherwise(() => null)}
      </div>
      <input
        {...props}
        checked={isControlled ? controlledChecked : checked}
        onChange={handleChange}
        type="checkbox"
        className="absolute opacity-0 top-0 right-0 bottom-0 left-0 cursor-pointer disabled:cursor-not-allowed"
      />
    </div>
  )
}

export { Checkbox, checkboxVariants }
