import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type { TPostResOrderOpenSchema } from './order-open.type'
import { postResOrderOpenSchema } from './order-open.schema'

const axios = ApiClient()

/**
 * @API문서
 * @description order-open
 */
export function postOrderOpen({ orderCode }: { orderCode: string }) {
  return async function () {
    type T = TPostResOrderOpenSchema
    const result = await axios.post<T, AxiosResponse<ApiResponse<T>>>(
      `/order/${orderCode}/open`
    )

    postResOrderOpenSchema.parse(result.data)
    return result
  }
}
