export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/[^0-9*]/g, '')
  if (cleaned.startsWith('050')) {
    // 안심번호
    return cleaned.replace(/(\d{4})(.{4})(.{4})/, '$1-$2-$3')
  } else if (cleaned.startsWith('010')) {
    // 휴대전화
    return cleaned.replace(/(\d{3})(.{4})(.{4})/, '$1-$2-$3')
  } else {
    // 지역번호
    return cleaned.replace(/(\d{2,3})(.{3,4})(.{4})/, '$1-$2-$3')
  }
}
