import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResOrderDeliveryGoodsflowSchema,
  TPostPayloadOrderDeliveryGoodsflowSchema,
} from './order-delivery-goodsflow.type'
import { postResOrderDeliveryGoodsflowSchema } from './order-delivery-goodsflow.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/z1BUUj
 * @description 굿스플로 배송서비스 송장출력할 배송건 등록 및 송장출력 요청(송장출력에 필요한 Url 및 정보 반환)
 */
export function postOrderDeliveryGoodsflow({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPostPayloadOrderDeliveryGoodsflowSchema) {
    type T = TPostResOrderDeliveryGoodsflowSchema
    type R = typeof body
    const result = await axios
      .post<
        T,
        AxiosResponse<T>,
        R
      >(`/order/${orderCode}/delivery/print-invoice`, body)
      .then((res) => res.data)

    postResOrderDeliveryGoodsflowSchema.parse(result)
    return result
  }
}
