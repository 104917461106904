import React from 'react'
import { IconChevronDown } from '@imwebme/icon'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { Select } from '~/shared/components/ui/select'
import { useFormContext } from 'react-hook-form'
import type { TOptionDetails } from '../../product-search/product-search.type'
import { __ } from '~/shared/i18n'

/**
 * 필수비조합형
 */
export function ProductSearchOptionItemRCO({
  values,
  id,
}: {
  values: TOptionDetails[]
  id: string
}) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const key = `optionDetails.${id}-rco`
  return (
    <>
      <input type="hidden" {...register(key)} />
      <Select.Root
        value={watch(key)?.optionDetailCode}
        onValueChange={(e) => {
          pipe(
            values,
            A.findFirst((r) => r.optionDetailCode === e),
            O.fold(
              () => undefined,
              (r) => setValue(key, r)
            )
          )
        }}
      >
        <Select.Trigger className="border-solid">
          <Select.Value placeholder={__('옵션을 선택해주세요')} />
          <IconChevronDown className="stroke-[2]" />
        </Select.Trigger>
        <Select.Content>
          <Select.Group>
            {pipe(
              values,
              A.map((r) => {
                const name = pipe(
                  r.options,
                  A.reduce(
                    '',
                    (acc, curr) =>
                      `${acc} ${curr.optionName} ${curr.valueName},`
                  ),
                  // 마지막 ,제거
                  (t) => t.slice(0, -1)
                )
                return (
                  <Select.Item
                    key={r.optionDetailCode}
                    value={r.optionDetailCode}
                  >
                    {name}
                  </Select.Item>
                )
              })
            )}
          </Select.Group>
        </Select.Content>
      </Select.Root>
    </>
  )
}
