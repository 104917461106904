import * as O from 'fp-ts/Option'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { useQuery } from '@tanstack/react-query'
import { SingleOrderCheck } from '../../../../../order-api'
import type { TRRT01Component } from './rrt01component.type'
import { orderDetailOptions } from '~/entities/order-detail'
import { useOrderCode } from '~/shared/hooks/use-order-code'

export const withRRT01Component =
  (WrappedComponent: React.ComponentType<TRRT01Component>) =>
  (props: Omit<TRRT01Component, 'deliveryList'>) => {
    const orderCode = useOrderCode()
    if (orderCode === undefined) {
      throw new Error(
        '🚀 ~ file: order-cancel-info.tsx:17 ~ OrderCancelInfoPage ~ OrderCancelInfoPage:'
      )
    }
    // server status
    const { data, isSuccess, isLoading } = useQuery({
      queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
      queryFn: () => SingleOrderCheck(orderCode),
      select: flow(
        (a) => a.data,
        (a) => a.orderDeliveryList
      ),
    })
    if (isSuccess && data) {
      // data to deliveryList
      const result = pipe(
        data,
        O.fromNullable,
        O.fold(
          () => undefined,
          flow(
            A.map((e) => ({
              orderDeliveryCode: e.orderDeliveryCode as string,
              receiverName: e.receiverName,
              receiverCall: e.receiverCall,
              addr1: e.addr1,
              addr2: e.addr2,
              zipcode: e.zipcode,
            }))
          )
        )
      )

      // result값이 최대 5개까지만
      // 배송지 설정은 5개까지만 되기 때문에.
      return <WrappedComponent {...props} deliveryList={result?.slice(0, 5)} />
    }
    return null
  }
