import { __ } from '~/shared/i18n'
import { useProductAddModalAtom } from '../product-add-modal.store'

export function FormattedPrice({ price }: { price: number }) {
  const { currency } = useProductAddModalAtom.get()

  return __('{{price, 3comma}}{{currency, currency}}', {
    price,
    formatParams: {
      currency: {
        currency,
      },
    },
  })
}
