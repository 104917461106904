import { ApiClient, ApiResponse } from '~/shared/api'
import { type TInvoiceExcelBulkUploadSchema } from './invoice-excel-bulk-upload.type'
import { InvoiceExcelBulkUploadSchema } from './invoice-excel-bulk-upload.schema'
import { AxiosResponse } from 'axios'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/nDVitk
 * @description 선택된 주문들에 대한 대량 엑셀 송장 등록
 */
export async function postInvoiceExcelBulkUpload(params: FormData) {
  type T = TInvoiceExcelBulkUploadSchema
  type R = typeof params
  const res = await axios
    .post<T, AxiosResponse<ApiResponse<T>>, R>(
      '/order/bulk-action/section/invoice/excel/upload',
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((_res) => _res.data)

  InvoiceExcelBulkUploadSchema.parse(res.data)
  return res.data
}
