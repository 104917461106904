import React from 'react'
import { Selector } from '../components'
import { __, __c } from '~/shared/i18n'
import { useController } from 'react-hook-form'
import { pipe } from 'fp-ts/function'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'
import { OptionItem } from '@imwebme/clay-components'
import {
  T상수_메모입력여부,
  상수_메모입력여부,
} from '~/entities/@x/메모입력여부'

interface Props extends FormControlProp {
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
}

const fieldKey = 'memoIsInput'

export const MemoIsInputField = ({
  control,
  handleRemoveOrderInfoField,
}: Props) => {
  const { onChange, value } = useController({
    name: fieldKey,
    control,
  }).field
  const [dropdownOpen, setDropdownOpen] = React.useState(
    () => value === undefined || value === null
  )

  const fieldLabel = React.useMemo(
    () =>
      pipe(
        value,
        (v) => (v ? __c(v) : __('선택해 주세요')),
        (label) => __('메모 입력 유무') + ': ' + label
      ),
    [value]
  )

  const handleResetField = React.useCallback(() => {
    onChange(null)
  }, [])

  const handleItemClick = React.useCallback(
    (selectedValue: T상수_메모입력여부) => {
      onChange(selectedValue)
      setDropdownOpen(false)
    },
    []
  )

  React.useEffect(() => {
    ;(function removeFieldOnExit() {
      if (dropdownOpen === false && !value) {
        handleRemoveOrderInfoField(fieldKey)
      }
    })()
  }, [value, dropdownOpen])

  return (
    <Selector
      label={fieldLabel}
      isActive={true}
      resetField={handleResetField}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {Object.values(상수_메모입력여부).map((option) => (
            <OptionItem
              key={option}
              text={__c(option)}
              onClick={() => handleItemClick(option)}
              isSelected={value === option}
            />
          ))}
        </div>
      }
    />
  )
}
