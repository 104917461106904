import { Button, ButtonGroup, Clay, Modal } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'

interface Props {
  isOpen: boolean
  setClose: () => void
  onConfirm: () => void
}

export function FilterExitModal({ isOpen, setClose, onConfirm }: Props) {
  return (
    <Modal isOpen={isOpen} setClose={setClose} width="small">
      <Modal.Header
        text={__('필터 수정 내용이 사라져요')}
        subtle={__('저장하지 않은 필터 수정 정보는 사라져요')}
      />
      <Clay height={vars.spacing[6]} />
      <Modal.Footer>
        <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
          <Button text={__('취소')} variant="outlined" onClick={setClose} />
          <Button text={__('확인')} variant="critical" onClick={onConfirm} />
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}
