import {
  Checkbox,
  Clay,
  Flex,
  IconButton,
  SafeImageLoading,
  Tag,
  Typography,
  Button,
  TextButton,
  OptionList,
  OptionItem,
} from '@imwebme/clay-components'
import { DotsVertical, AccordionDown, X } from '@imwebme/clay-icons'
import { vars } from '@imwebme/clay-token'
import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'
import { fDate } from '~/shared/utils'
import { SkyRing } from '../partials/sky-ring'
import prodThumb6 from '~/container/onboarding/assets/prod-thumb-6.png'
import prodThumb7 from '~/container/onboarding/assets/prod-thumb-7.png'
import prodThumb8 from '~/container/onboarding/assets/prod-thumb-8.png'
import prodThumb9 from '~/container/onboarding/assets/prod-thumb-9.png'
import TruckBlue from '~/container/onboarding/assets/truck-blue.svg?react'
import { formatPrice } from '~/container/onboarding/onboarding.fn'
import { match, P } from 'ts-pattern'
import { useOnboardingStep } from '~/container/onboarding/onboarding.store'

/**
 * 상품준비 섹션
 */
export function ProdPreparationSection() {
  const [step] = useOnboardingStep()

  return (
    <Clay
      backgroundColor={vars.semantic.color.surface}
      borderRadius={vars.rounded.large}
      padding={`${vars.spacing[6]} 0`}
      position="relative"
      zIndex={match(step)
        .with(8, () => 1)
        .otherwise(() => 0)}
    >
      <Clay
        display="grid"
        gridTemplateColumns="1fr auto"
        padding={vars.spacing[6]}
        paddingTop={0}
        columnGap={vars.spacing[4]}
        rowGap={vars.spacing[2]}
      >
        <Typography variant="heading-xlarge-bold">
          <Trans
            i18nKey="상품준비 <t>{{count}}</t>"
            values={{
              count: 3,
            }}
            components={{
              t: (
                <Typography
                  as="span"
                  colorToken="textAccent"
                  variant="heading-xlarge-bold"
                />
              ),
            }}
          />
        </Typography>

        <IconButton icon={<DotsVertical />} variant="secondary" size="tiny" />

        <Flex gridColumnStart={1} gridColumnEnd={-1} gap={vars.spacing[15]}>
          <Tag size="xsmall" variant="plain" text={__('#5138451321-S')} />
          <Tag
            size="xsmall"
            variant="plain"
            text={__('{{date}} 생성', {
              date: fDate(new Date(), 'yyyy년 MM월 dd일'),
            })}
          />
        </Flex>
      </Clay>

      <Clay paddingInline={vars.spacing[6]} paddingBottom={vars.spacing[1]}>
        <Checkbox.Label
          label={__('전체')}
          checked={false}
          disabled={step === 10}
        />
      </Clay>

      {step === 10 && (
        <SectionItemRowNormal
          img={prodThumb9}
          name="문라이트 솔로"
          options={['사이즈 S', '조명컬러 전구색']}
          qty={1}
          unitPrice={28000}
          originalTotalPrice={359000}
        />
      )}

      {match(step)
        .with(8, () => (
          <SectionItemRowChecked
            img={prodThumb6}
            name="워셔블 패브릭 커버 소파"
            options={['사이즈 M', '컬러 Mustard']}
            qty={12}
            unitPrice={24000}
          />
        ))
        .otherwise(() => (
          <SectionItemRowNormal
            img={prodThumb6}
            name="워셔블 패브릭 커버 소파"
            options={['사이즈 M', '컬러 Mustard']}
            qty={1}
            unitPrice={450000}
            originalTotalPrice={500000}
          />
        ))}

      <SectionItemRowNormal
        img={prodThumb7}
        name="워셔블 패브릭 커버 소파"
        options={['높이 160cm', '조명컬러 전구색']}
        qty={12}
        unitPrice={24000}
        originalTotalPrice={359000}
      />

      {match(step)
        .with(P.union(8, 9, 10), () => (
          <SectionItemRowNormal
            img={prodThumb8}
            name="컬러 프레임 빈티치 체어"
            options={['컬러 옵션2']}
            qty={12}
            unitPrice={24000}
            originalTotalPrice={359000}
          />
        ))
        .otherwise(() => null)}

      <Flex
        paddingInline={vars.spacing[6]}
        paddingTop={vars.spacing[4]}
        justifyContent="flex-end"
      >
        {match(step)
          .with(8, () => (
            <Clay position="relative" width="fit-content">
              <SkyRing />
              <Button text={__('배송대기 처리')} variant="primary" />
            </Clay>
          ))
          .otherwise(() => (
            <Button text={__('배송대기 처리')} variant="primary" />
          ))}
      </Flex>
    </Clay>
  )
}

/** 배송대기 섹션 */
export function ShippingReadySection() {
  const [step] = useOnboardingStep()

  return match(step)
    .with(P.union(11, 12), () => (
      <Clay
        backgroundColor={vars.semantic.color.surface}
        borderRadius={vars.rounded.large}
        padding={`${vars.spacing[6]} 0`}
      >
        <Clay
          display="grid"
          gridTemplateColumns="1fr auto"
          padding={vars.spacing[6]}
          paddingTop={0}
          columnGap={vars.spacing[4]}
          rowGap={vars.spacing[2]}
        >
          <Typography variant="heading-xlarge-bold">
            <Trans
              i18nKey="배송대기 <t>{{count}}</t>"
              values={{
                count: 1,
              }}
              components={{
                t: (
                  <Typography
                    as="span"
                    colorToken="textAccent"
                    variant="heading-xlarge-bold"
                  />
                ),
              }}
            />
          </Typography>

          {match(step)
            .with(12, () => (
              <Clay position="relative" zIndex={1}>
                <IconButton
                  icon={<DotsVertical />}
                  variant="secondary"
                  size="tiny"
                  sx={{
                    backgroundColor: vars.semantic.color.actionSecondaryPressed,
                  }}
                />
                <OptionList
                  popOver
                  sx={{
                    position: 'absolute',
                    width: '168px',
                    left: 0,
                    top: 36,
                  }}
                >
                  <OptionItem text={__('배송보류 설정')} />
                  <OptionItem text={__('배송지 변경')} />
                  <OptionItem text={__('주문서 출력')} />
                  <OptionItem text={__('상품준비로 되돌리기')} />
                </OptionList>
              </Clay>
            ))
            .otherwise(() => (
              <IconButton
                icon={<DotsVertical />}
                variant="secondary"
                size="tiny"
              />
            ))}

          <Flex gridColumnStart={1} gridColumnEnd={-1} gap={vars.spacing[15]}>
            <Tag size="xsmall" variant="plain" text={__('#5138451321-S')} />
            <Tag
              size="xsmall"
              variant="plain"
              text={__('{{date}} 생성', {
                date: fDate(new Date(), 'yyyy년 MM월 dd일'),
              })}
            />
          </Flex>
        </Clay>

        <Flex
          paddingInline={vars.spacing[6]}
          paddingBottom={vars.spacing[5]}
          gap={vars.spacing[3]}
        >
          <TruckBlue />
          {
            <TextButton
              text={__('CJ대한통운 2150_5878_5120')}
              variant="primary"
            />
          }
        </Flex>

        <SectionItemRowNormal
          img={prodThumb8}
          name="컬러 프레임 빈티치 체어"
          options={['컬러 옵션2']}
          qty={1}
          unitPrice={445000}
          originalTotalPrice={680000}
        />

        <Flex
          paddingInline={vars.spacing[6]}
          paddingTop={vars.spacing[4]}
          justifyContent="flex-end"
        >
          <Button text={__('배송중 처리')} variant="primary" />
        </Flex>
      </Clay>
    ))
    .otherwise(() => null)
}

/**
 * 섹션 아이템 (체크된 경우)
 */
function SectionItemRowChecked({
  img,
  name,
  options,
  qty,
  unitPrice,
}: {
  img: string
  name: string
  options: string[]
  qty: number
  unitPrice: number
}) {
  return (
    <Flex
      width="100%"
      padding={`${vars.spacing[4]} ${vars.spacing[6]}`}
      backgroundColor={vars.semantic.color.layerSelected}
    >
      {/* 아이템 정보 */}
      <Flex
        alignItems="center"
        gap={vars.spacing[3]}
        paddingRight={vars.spacing[8]}
        flexGrow={1}
      >
        <Flex.Center position="relative">
          <Checkbox checked />
          <SkyRing />
        </Flex.Center>

        <Clay
          width={48}
          height={48}
          borderRadius={vars.rounded.small}
          overflow="hidden"
        >
          <SafeImageLoading src={img} width="100%" height="100%" />
        </Clay>

        <Flex.Column gap={vars.spacing[1]}>
          <Typography variant="label-medium">{__(name)}</Typography>
          <Flex gap={vars.spacing[1]}>
            {options.map((option, idx) => (
              <Tag
                key={idx}
                variant="other"
                text={__(option)}
                sx={{
                  '--clay-tag-background-color': '#15181E1A',
                }}
              />
            ))}
          </Flex>
        </Flex.Column>
      </Flex>

      {/* 수량 */}
      <Clay paddingLeft={vars.spacing[6]} paddingRight={vars.spacing[5]}>
        <Flex
          padding={vars.spacing[1]}
          paddingLeft={vars.spacing[3]}
          gap={vars.spacing[2]}
          alignItems="center"
          borderRadius={vars.rounded.medium}
          border={`1px solid ${vars.semantic.color.border}`}
          backgroundColor={vars.semantic.color.surface}
          width="112px"
          height="40px"
          justifyContent="flex-end"
        >
          <Typography variant="body-medium">{qty}</Typography>
          <Flex.Column
            padding={`${vars.spacing['05']} 0`}
            width="35px"
            alignItems="center"
          >
            <AccordionDown rotate={180} colorToken="icon-disabled" />
            <AccordionDown colorToken="icon-sub" />
          </Flex.Column>
        </Flex>
      </Clay>

      {/* 총금액 */}
      <Clay paddingLeft={vars.spacing[5]}>
        <Typography variant="label-medium">
          {formatPrice(unitPrice * qty)}
        </Typography>
      </Clay>
    </Flex>
  )
}

/**
 * 섹션 아이템 (체크되지 않은 경우)
 */
function SectionItemRowNormal({
  img,
  name,
  options,
  qty,
  unitPrice,
  originalTotalPrice,
}: {
  img: string
  name: string
  options: string[]
  qty: number
  unitPrice: number
  originalTotalPrice: number
  disabled?: boolean
}) {
  const [step] = useOnboardingStep()
  return (
    <Flex
      width="100%"
      padding={`${vars.spacing[4]} ${vars.spacing[6]}`}
      className="odd:bg-clay-semantic-layerSub"
    >
      {/* 아이템 정보 */}
      <Flex
        alignItems="center"
        gap={vars.spacing[3]}
        paddingRight={vars.spacing[8]}
        flexGrow={1}
      >
        <Checkbox checked={false} disabled={step === 10} />

        <Clay
          width={48}
          height={48}
          borderRadius={vars.rounded.small}
          overflow="hidden"
        >
          <SafeImageLoading src={img} width="100%" height="100%" />
        </Clay>

        <Flex.Column gap={vars.spacing[1]}>
          <Typography variant="label-medium">{__(name)}</Typography>
          <Flex gap={vars.spacing[1]}>
            {options.map((option, idx) => (
              <Tag
                key={idx}
                variant="other"
                text={__(option)}
                sx={{
                  '--clay-tag-background-color': '#15181E1A',
                }}
              />
            ))}
          </Flex>
        </Flex.Column>
      </Flex>

      {/* 수량 */}
      <Typography as="div" variant="label-medium">
        <Clay
          paddingLeft={vars.spacing[6]}
          paddingRight={vars.spacing[5]}
          columnGap={vars.spacing[1]}
          display="grid"
          alignItems="center"
          gridTemplateColumns="max-content auto auto"
        >
          <Clay justifySelf="end">{formatPrice(unitPrice)}</Clay>
          <X />
          <Clay>{qty}</Clay>
          <Clay
            gridColumnStart={1}
            gridColumnEnd={2}
            color={vars.semantic.color.textDisabled}
            textDecoration="line-through"
            justifySelf="end"
          >
            {formatPrice(originalTotalPrice)}
          </Clay>
        </Clay>
      </Typography>

      {/* 총금액 */}
      <Clay paddingLeft={vars.spacing[5]}>
        <Typography variant="label-medium">
          {formatPrice(qty * unitPrice)}
        </Typography>
      </Clay>
    </Flex>
  )
}
