import React from 'react'
import type { TReturnReasonEditBase } from './return-reason-edit.type'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { useReturnReasonEditAtom } from './return-reason-edit.store'

const ReturnReasonEdit = React.lazy(() =>
  import('./return-reason-edit').then((module) => ({
    default: React.memo(module.ReturnReasonEdit),
  }))
)

interface TReturnReasonEditContainer extends TReturnReasonEditBase {}
export function ReturnReasonEditContainer(props: TReturnReasonEditContainer) {
  const [open, setState] = useReturnReasonEditAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    // close될때
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ReturnReasonEdit {...props} onSuccess={() => onOpenChange(false)} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
