import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { IconArrowLeft } from '@imwebme/icon'
import { useSuspenseQuery } from '@tanstack/react-query'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { OrderEditCalcCard, OrderEditHeader, OrderHeaderStep } from '~t'
import { OrderTimelinePack } from '~/container/order/order-timeline'
import { Button } from '@imwebme/clay-components'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TPatchPayloadCancelRequestRdo,
  patchPayloadCancelRequestRdo,
} from '~/entities/external-order-section/cancel-request'
import React from 'react'
import { ExternalCancelItemMutableList } from './partials/external-cancel-item-mutable-list'
import { ExternalOrderCancelSelectSectionPack } from './partials/section'
import { Trans } from 'react-i18next'
import { useCurrency } from '~/shared/hooks/use-currency'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ContentsBanner } from '@imwebme/clay-components'
import {
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '~/entities/external-order/model/external-order-section'
import { 스펙_외부채널_주문섹션_품목 } from '~/entities/external-order/model/external-order-section-item'

export function ExternalOrderCancelSelect() {
  const router = useNavigate()

  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })
  const methods = useForm({
    resolver: zodResolver(
      patchPayloadCancelRequestRdo.omit({
        cancelReasonCode: true,
        cancelReasonDetail: true,
      })
    ),
    defaultValues: React.useMemo(() => {
      const result = pipe(
        data.orderSectionList,
        O.fromNullable,
        O.fold(
          () => null,
          flow(
            A.map((e) => ({
              orderSectionCode: e.orderSectionCode,
              targetItemInformation: pipe(
                e.orderSectionItemList!,
                A.map((e2) => ({
                  _check: false,
                  qty: e2.qty,
                  orderSectionItemCode: e2.orderSectionItemCode,
                }))
              ),
            })),
            (e) => ({
              targetSectionInformation: e,
            })
          )
        )
      )
      log.debug(result)
      return result ?? undefined
    }, []),
    mode: 'all',
  })
  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const onSubmit = handleSubmit((body) => {
    const isChecked = pipe(
      body.targetSectionInformation,
      A.filter((e) => e.targetItemInformation.some((e2) => e2._check === true)),
      (e) => e.length > 0
    )
    if (isChecked === false) {
      toast.error(__('취소할 상품을 선택해주세요'))
      return
    }
    const sectionItemCodesBase64Params = pipe(
      body.targetSectionInformation,
      A.map(
        flow((e) => {
          const _result = pipe(
            e.targetItemInformation,
            A.filter((e2) => e2._check),
            A.map((e2) => e2.orderSectionItemCode)
          )
          return `${e.orderSectionCode}=${_result.join(',')}`
        })
      ),
      // 상품코드를 base64로 인코딩
      (e) => btoa(e.join('|')),
      pipeLog('취소할 상품 코드')
    )
    router(
      `/order/${data.saleChannelIdx}/${orderCode}/cancel/info?data=${sectionItemCodesBase64Params}`
    )
  })
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Header />
        <BannerArea />
        <div className="mt-[32px] mb-[70px] min-w-[992px] overflow-auto">
          <div className="w-[970px] mx-auto">
            <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-x-[20px] gap-y-[20px]">
              <div className="grid gap-y-[20px]">
                {pipe(
                  data.orderSectionList,
                  O.fromNullable,
                  O.fold(
                    () => null,
                    flow(
                      A.filter((e) => {
                        const $exSection = new 모델_외부채널_주문섹션(
                          e,
                          data.saleChannelItemList
                        )
                        const result = pipe(
                          스펙_외부채널_주문섹션.섹션
                            .and(
                              스펙_외부채널_주문섹션.상품준비.and(
                                스펙_외부채널_주문섹션
                                  .외부채널주문섹션품목some([
                                    스펙_외부채널_주문섹션_품목.채널상태_교환재배송처리중,
                                    스펙_외부채널_주문섹션_품목.채널상태_교환재배송대기,
                                    스펙_외부채널_주문섹션_품목.채널상태_교환재배송중,
                                  ])
                                  .not()
                              )
                            )
                            .or(
                              스펙_외부채널_주문섹션.배송대기_송장등록전.and(
                                스펙_외부채널_주문섹션
                                  .외부채널주문섹션품목some([
                                    스펙_외부채널_주문섹션_품목.채널상태_교환재배송처리중,
                                    스펙_외부채널_주문섹션_품목.채널상태_교환재배송대기,
                                    스펙_외부채널_주문섹션_품목.채널상태_교환재배송중,
                                  ])
                                  .not()
                              )
                            )
                            .or(
                              스펙_외부채널_주문섹션.배송대기_송장등록후
                                .and(스펙_외부채널_주문섹션.배송없는섹션)
                                .and(
                                  스펙_외부채널_주문섹션
                                    .외부채널주문섹션품목some([
                                      스펙_외부채널_주문섹션_품목.채널상태_교환재배송처리중,
                                      스펙_외부채널_주문섹션_품목.채널상태_교환재배송대기,
                                      스펙_외부채널_주문섹션_품목.채널상태_교환재배송중,
                                    ])
                                    .not()
                                )
                            )
                            .isSatisfiedBy($exSection)
                        )
                        return result
                      }),
                      A.mapWithIndex((index, item) => (
                        <React.Fragment key={item.orderSectionNo}>
                          <ExternalOrderCancelSelectSectionPack
                            sectionCode={item.orderSectionCode}
                            index={index}
                          >
                            <ExternalCancelItemMutableList
                              index={index}
                              sectionCode={item.orderSectionCode}
                            />
                          </ExternalOrderCancelSelectSectionPack>
                        </React.Fragment>
                      ))
                    )
                  )
                )}
              </div>
              <div>
                <TotalCard />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export function useFormContextExternalOrderCancelSelect() {
  const form = useFormContext<TPatchPayloadCancelRequestRdo>()
  return form
}

function Header() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{ orderCode, saleChannel: String(data.saleChannelIdx) }}
        >
          <div className="flex gap-x-[4px] items-center">
            <IconArrowLeft className="stroke-[2]" />
            <span>{data.orderNo}</span>
          </div>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('취소')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: __('품목 선택'),
              active: true,
            },
            {
              id: '2',
              label: __('취소 정보 입력'),
              active: false,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <div className="space-x-[8px]">
          <Button
            native={{ type: 'submit' }}
            variant="primary"
            text={__('다음')}
          />
        </div>
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

function BannerArea() {
  return (
    <div className="mt-[32px] mb-[24px] min-w-[992px] overflow-auto">
      <div className="w-[970px] mx-auto">
        <ContentsBanner
          type="secondary"
          variant="critical"
          text={__(
            '외부몰 연동 주문은 상품 단위 별 취소만 가능하며 정확한 환불 금액은 해당 판매자센터에서 확인해 주세요.'
          )}
        />
      </div>
    </div>
  )
}

function TotalCard() {
  const currency = useCurrency({ isExternal: true })
  const { watch } = useFormContextExternalOrderCancelSelect()
  const orderCode = useOrderCode()

  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })

  const selectItemData = pipe(
    data.orderSectionList,
    O.fromNullable,
    O.fold(
      () => null,
      flow(
        A.map(
          flow(
            O.fromNullable,
            O.getOrElseW(() => {
              throw new Error(
                '🚀 ~ file: external-order-cancel-info.tsx:108 ~ O.getOrElseW ~ data.orderSectionList'
              )
            }),
            (e) => e.orderSectionItemList,
            O.fromNullable,
            O.getOrElseW(() => {
              throw new Error(
                '🚀 ~ file: external-order-cancel-info.tsx:108 ~ O.getOrElseW ~ data.orderSectionList'
              )
            })
          )
        ),
        A.flatten,
        A.filter((_item) =>
          pipe(
            watch('targetSectionInformation'),
            A.map((e) => e.targetItemInformation),
            A.flatten,
            A.reduce(
              false,
              (acc, item) =>
                acc ||
                (item.orderSectionItemCode === _item.orderSectionItemCode &&
                  item._check)
            )
          )
        ),
        A.reduce(0, (acc, item) => acc + item.qty * item.itemPrice)
      )
    )
  )

  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price: selectItemData ?? 0,
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b className="typo-clay-heading-large-bold" /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}
