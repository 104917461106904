/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */
import {
  상수_송장입력타입,
  상수_결제수단,
  상수_수거타입,
  상수_수거결제타입,
  상수_환불금액_타입,
  YN,
} from '~/entities/@x'
import * as C from './order-history.constants'

import { z } from 'zod'

// ====================================================== 타임라인 logTypeCd별 timelineInfo 스키마

// OTL01
export const createOrderTimelineResSchema = z.object({
  memberName: z.string().nullish(),
  saleChannelName: z.string(),
})

// OTL02
export const orderSectionItemJoinOrderItemDtoSchema = z.object({
  prodName: z.string(),
  optionInfo: z
    .union([
      z.array(z.record(z.union([z.string(), z.number(), z.boolean()]))),
      z.string(),
    ])
    .nullish(),
  qty: z.number(),
})

export const orderSectionWithItemDtoSchema = z.object({
  orderSectionItems: z.array(orderSectionItemJoinOrderItemDtoSchema),
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
})

export const addItemTimelineResSchema = z.object({
  orderSections: z.array(orderSectionWithItemDtoSchema),
})

// OTL03
export const orderDeliveryEntitySchema = z.object({
  siteCode: z.string(),
  orderCode: z.string(),
  orderDeliveryCode: z.string(),
  receiverName: z.string(),
  receiverCall: z.string(),
  zipcode: z.string(),
  addr1: z.string(),
  addr2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  building: z.string().nullish(),
  street: z.string().nullish(),
  country: z.string().nullish(),
  countryName: z.string().nullish(),
  isDel: z.string(),
  wtime: z.string().nullish(),
  mtime: z.string(),
  memo: z.string().nullish(),
})

export const addDeliveryTimelineResSchema = z.object({
  deliveryData: orderDeliveryEntitySchema,
})

// OTL04
export const updateDeliveryTimelineResSchema = z.object({
  beforeDeliveryData: orderDeliveryEntitySchema,
  afterDeliveryData: orderDeliveryEntitySchema,
})

// OTL05
export const delayShipmentTimelineResSchema = z.object({
  channelProductNoList: z.array(z.string()),
  reason: z.string(),
  dueDate: z.string(),
})

// OTL06
const paymentBankTransferEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  bankName: z.string(),
  bankAccount: z.string(),
  accountHolderName: z.string(),
  expireTime: z.string(),
  memberCode: z.string().nullish(),
  depositTime: z.string().nullish(),
  depositor: z.string(),
  wtime: z.string().nullish(),
})

const paymentVirtualEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  bankName: z.string(),
  bankAccount: z.string(),
  accountHolderName: z.string(),
  expireTime: z.string(),
  depositTime: z.string().nullish(),
  depositor: z.string(),
  wtime: z.string().nullish(),
})

export const waitPaymentTimelineResSchema = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  paidPrice: z.number(),
  methodCd: z.string(),
  bankAccount: z.string(),
  bankName: z.string().nullish(),
  expireTime: z.string(),
})

// OTL07
export const overdueDeadlinePaymentTimelineResSchema = z.object({
  paymentCode: z.string(),
  isFirstPayment: z.boolean(),
  paymentNo: z.string(),
  paidPrice: z.number(),
  methodCd: z.string(),
  bankTransferInfo: paymentBankTransferEntitySchema.nullish(),
  virtualInfo: paymentVirtualEntitySchema.nullish(),
  couponIssueCodeNames: z.array(z.string().nullish()).optional(),
  refundPoint: z.number().nullish(),
})

// OTL08
export const bankTransferDtoSchema = z.object({
  bankName: z.string(),
  bankAccount: z.string(),
  accountHolderName: z.string(),
  depositor: z.string(),
})

export const extensionDeadlinePaymentTimelineResSchema = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  paidPrice: z.number(),
  beforeExpireTime: z.string(),
  afterExpireTime: z.string(),
  bankTransferInfo: bankTransferDtoSchema,
})

// OTL09
export const completePaymentTimelineResSchema = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  paidPrice: z.number(),
  methodCd: z.nativeEnum(상수_결제수단),
  pgTrxTime: z.string(),
  bankTransferInfo: paymentBankTransferEntitySchema.nullish(),
})

// OTL10
export const failRefundTimelineResSchema = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  paidPrice: z.number(),
  methodCd: z.nativeEnum(상수_결제수단),
  message: z.string().nullish(),
  bankTransferInfo: bankTransferDtoSchema.nullish(),
})

// OTL11
export const completeRefundTimelineResSchema = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  paidPrice: z.number(),
  methodCd: z.nativeEnum(상수_결제수단),
  bankTransferInfo: bankTransferDtoSchema.nullish(),
})

// OTL12
export const completeAllReadyShippingTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  beforeOrderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterOrderSectionNo: z.string(),
  afterStatus: z.string(),
})

// OTL13
const orderSectionDtoSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
})

export const completePartialReadyShippingTimelineResSchema = z.object({
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

// OTL14
export const revertStatusItemPreparationTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  beforeOrderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterOrderSectionNo: z.string(),
  afterStatus: z.string(),
})

// OTL15
export const changeStatusShippingTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterStatus: z.string(),
  invoiceNo: z.string().nullish(),
  parcelCompanyIdx: z.number().nullish(),
})

// OTL16
export const revertStatusReadyShippingTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterStatus: z.string(),
})

// OTL17
export const changeStatusCompleteShippingTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterStatus: z.string(),
})

// OTL18
export const revertStatusShippingTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterStatus: z.string(),
})

// OTL19
export const onHoldDeliveryTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
})

// OTL20
export const offHoldDeliveryTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
})

// OTL21
export const createInvoiceTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  inputType: z.nativeEnum(상수_송장입력타입),
  invoiceNo: z.string().nullish(),
  parcelCompanyIdx: z.number(),
})

// OTL22
export const updateInvoiceTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeInvoiceNo: z.string().nullish(),
  beforeParcelCompanyIdx: z.number(),
  afterParcelCompanyIdx: z.number(),
  afterInvoiceNo: z.string().nullish(),
})

// OTL23
export const deleteInvoiceTimelineResSchema = z.object({
  invoiceCode: z.string(),
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  parcelCompanyIdx: z.number(),
  invoiceNo: z.string(),
})

// OTL24
const beforeAfterOrderSectionDtoSchema = z.object({
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

export const requestCancelTimelineResSchema = z.object({
  isPartial: z.boolean(),
  orderSections: beforeAfterOrderSectionDtoSchema,
  cancelReason: z.string().nullish(),
  cancelReasonDetail: z.string().nullish(),
})

// OTL25
export const withdrawCancelTimelineResSchema = z.object({
  isPartial: z.boolean(),
  isCustomerRequest: z.nativeEnum(YN).or(z.literal('')),
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

// OTL26
export const rejectCancelTimelineResSchema = z.object({
  isPartial: z.boolean(),
  isCustomerRequest: z.nativeEnum(YN).or(z.literal('')),
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

// OTL27, OTL28
export const completeCancelTimelineResSchema = z.object({
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
  refundPriceTypeCd: z.nativeEnum(상수_환불금액_타입),
  refundAmount: z.number(),
  deliveryExtraPrice: z.number().nullish(),
  couponIssueCodeNames: z.array(z.string().nullish()).optional(),
  refundPoint: z.number().nullish(),
})

// OTL29
export const revertRequestCancelTimelineResSchema = z.object({
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

// OTL30
export const requestReturnTimelineResSchema = z.object({
  isPartial: z.boolean(),
  orderSections: beforeAfterOrderSectionDtoSchema,
  returnReason: z.string(),
  returnReasonDetail: z.string().nullish(),
  retrieveTypeCd: z.nativeEnum(상수_수거타입),
  retrievePayTypeCd: z.nativeEnum(상수_수거결제타입),
  orderDeliveryCode: z.string().nullish(),
})

// OTL31
export const requestRetrieveTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
  retrieveTypeCd: z.nativeEnum(상수_수거타입),
  invoiceCode: z.string().nullish(),
  invoiceNo: z.string().nullish(),
  parcelCompanyIdx: z.number().nullish(),
  retrievePayTypeCd: z.nativeEnum(상수_수거결제타입),
})

const requestReturnReason = z.object({
  returnReason: z.string(),
  returnReasonDetail: z.string().nullish(),
})

// OTL32
export const updateRequestReturnTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
  beforeRequestReturnReason: requestReturnReason,
  afterRequestReturnReason: requestReturnReason,
})

// OTL33
export const rejectReturnTimelineResSchema = z.object({
  isPartial: z.boolean(),
  isCustomerRequest: z.nativeEnum(YN).or(z.literal('')),
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

// OTL34
export const withdrawReturnTimelineResSchema = z.object({
  isPartial: z.boolean(),
  isCustomerRequest: z.nativeEnum(YN).or(z.literal('')),
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
})

// OTL35, OTL36
export const completeReturnTimelineResSchema = z.object({
  beforeOrderSection: orderSectionDtoSchema,
  afterOrderSection: orderSectionWithItemDtoSchema,
  refundPriceTypeCd: z.nativeEnum(상수_환불금액_타입),
  refundAmount: z.number(),
  deliveryExtraPrice: z.number().nullish(),
  couponIssueCodeNames: z.array(z.string().nullish()).optional(),
  refundPoint: z.number().nullish(),
})

// OTL37
export const revertRequestReturnTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeOrderSectionStatusCd: z.string(),
  afterOrderSectionStatusCd: z.string(),
})

// OTL38, OTL39
export const requestPaymentCashReceiptTimelineResSchema = z.object({
  paymentNo: z.string(),
  cashReceiptType: z.string(),
  cashReceiptValue: z.string(),
})

// OTL40 ~ OTL43
export const cashReciptTimelineResSchema = z.object({
  paymentCode: z.string(),
  paymentNo: z.string(),
  cashReceiptType: z.string(),
  cashReceiptValue: z.string(),
  paidPrice: z.union([z.number(), z.string()]),
  supplyPrice: z.union([z.number(), z.string()]),
  vatPrice: z.union([z.number(), z.string()]),
})

// OTL44
export const revertWaitDepositResSchema = z.object({
  paymentCode: z.string(),
  paidPrice: z.union([z.number(), z.string()]),
  paymentNo: z.string(),
})

// OTL45
export const requestRetrieveCompleteResSchema = z.object({
  orderSectionNo: z.string(),
  orderDeliveryCode: z.string(),
  invoiceNo: z.string(),
  parcelCompanyIdx: z.string(),
})

const cacheReceiptType = z.object({
  cashReceiptType: z.string(),
  cashReceiptValue: z.string(),
})

// OTL46
export const updateRequestCashReceiptResSchema = z.object({
  paymentNo: z.string(),
  beforeCashReceipt: cacheReceiptType,
  afterCashReceipt: cacheReceiptType,
})

// OTL47
export const updateSectionDeliveryResSchema = z.object({
  orderSectionNo: z.string(),
  beforeDeliveryData: orderDeliveryEntitySchema,
  afterDeliveryData: orderDeliveryEntitySchema,
})

// OTL48
export const deleteDeliveryResSchema = z.object({
  deliveryData: orderDeliveryEntitySchema,
})

const requestCancelReason = z.object({
  cancelReason: z.string(),
  cancelReasonDetail: z.string().nullish(),
})

// OTL49
export const updateRequestCancelReasonResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  orderSectionStatus: z.string(),
  beforeRequestCancelReason: requestCancelReason,
  afterRequestCancelReason: requestCancelReason,
})

// OTL50
export const cancelUnpaidDepositOrder = z.object({
  paymentNo: z.string().nullish(),
  paidPrice: z.number().nullish(),
  methodCd: z.string().nullish(),
  bankName: z.string().nullish(),
  bankAccount: z.string().nullish(),
})

// OTL51
export const changeStatusConfirmTimelineResSchema = z.object({
  orderSectionCode: z.string(),
  orderSectionNo: z.string(),
  beforeStatus: z.string(),
  afterStatus: z.string(),
})

// OTL52, OTL53
export const splitOrderSectionSchema =
  completePartialReadyShippingTimelineResSchema

// OTL54
export const mergeOrderSectionSchema = z.object({
  statusCd: z.string(),
  targetOrderSectionNo: z.string(),
  sourceOrderSectionNos: z.string().array(),
  items: z
    .object({
      prodName: z.string(),
      optionInfo: z.string().nullish(),
      qty: z.number(),
    })
    .array(),
})

// OTL55, OTL56
export const completeReturnForExchangeSchema = z.object({
  newOrderSectionNo: z
    .string()
    .describe('신규생성, 교환 품목이 포함되어 새롭게 생성된 섹션 번호'),
  requestOrderSectionNo: z.string().describe('교환접수, 최초 요청 섹션 번호'),
  completeOrderSectionNo: z
    .string()
    .describe('교환완료, 최종 완료 처리된 섹션 번호'),
  orderSectionItems: orderSectionItemJoinOrderItemDtoSchema
    .array()
    .describe('품목정보, 새롭게 생성된 교환 품목 정보'),
  deliveryExtraPrice: z.number().describe('추가 배송비(음수, 양수)'),
})

// OTL57, OTL58
export const switchToReturnOrExchange = z.object({
  orderSectionNo: z.string(),
})

// OTL59, OTL60
export const holdBackReleaseSchema = z.object({
  orderSectionNo: z.string(),
  channelOrderItemNos: z.string().array(),
})

// ====================================================== 타임라인 아이템
export const orderTimelineDataDtoSchema = z.object({
  logTypeCd: z.nativeEnum(C.TIMELINE_LOG_TYPE_CODE),
  actionTriggerCd: z.nativeEnum(C.TIMELINE_ACTION_TRIGGER_CODE),
  timelineInfo: z.record(z.string(), z.any()),
})

const findOrderHistoryTimelineResponseDtoSchema = z.object({
  idx: z.number(),
  name: z.string(),
  historyType: z.literal(C.HISTORY_TYPE.ORDER_HISTORY_TIMELINE),
  memberCode: z.string().optional(),
  wtime: z.string(),
  profileImgUrl: z.string().nullable(),
  data: orderTimelineDataDtoSchema,
})

// ====================================================== 메모 아이템
export const orderMemoDataDtoSchema = z.object({
  memo: z.string().optional(),
  isDone: z.nativeEnum(YN),
  mtime: z.string(),
})

const findOrderHistoryMemoResponseDtoSchema = z.object({
  idx: z.number(),
  name: z.string(),
  historyType: z.literal(C.HISTORY_TYPE.ORDER_HISTORY_MEMO),
  memberCode: z.string().optional(),
  wtime: z.string(),
  profileImgUrl: z.string().nullable(),
  data: orderMemoDataDtoSchema,
})

/**
 * get order-history
 */
export const getResOrderHistorySchema = z.object({
  orderHistoryList: z
    .discriminatedUnion('historyType', [
      findOrderHistoryTimelineResponseDtoSchema,
      findOrderHistoryMemoResponseDtoSchema,
    ])
    .array(),
})
