import React from 'react'
import {
  Clay,
  Flex,
  IconButton,
  Typography,
  Tag,
  ButtonGroup,
  Button,
  PageBanner,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  ArrowNarrowLeft,
  MessageTextSquare,
  Printer,
} from '@imwebme/clay-icons'
import { getOrderNo } from '~/container/onboarding/onboarding.fn'
import { __ } from '~/shared/i18n'
import { fDate } from '~/shared/utils'
import { PaymentInfoCard } from './payment-info-card'
import { PurchaserInfoCard } from './purchaser-info-card'
import { ShippingInfoCard } from './shipping-info-card'
import { ProdPreparationSection, ShippingReadySection } from './section-card'
import { OnboardingTooltip } from '~/container/onboarding/onboarding-tooltip'
import { match } from 'ts-pattern'
import { useOnboardingStep } from '~/container/onboarding/onboarding.store'

export function OnboardingOrderDetailPage() {
  const [step] = useOnboardingStep()
  return (
    <Clay
      marginInline="auto"
      padding={`0 ${vars.spacing[10]}`}
      maxWidth="1232px"
      minHeight="100vh"
      position="relative"
    >
      <OnboardingTooltip />

      {/* 헤더 */}
      <Flex padding={`${vars.spacing[8]} 0`} gap={vars.spacing[4]}>
        <Clay
          padding={`${vars.spacing[25]} 0 ${vars.spacing[25]} ${vars.spacing[2]}`}
          height="fit-content"
        >
          <ArrowNarrowLeft colorToken="icon-sub" />
        </Clay>

        <Clay
          flexGrow={1}
          display="grid"
          gridTemplateColumns="max-content 1fr"
          columnGap={vars.spacing[3]}
          rowGap={vars.spacing[2]}
          alignItems="center"
          justifyItems="start"
          padding={`${vars.spacing['05']} 0`}
        >
          <Typography variant="heading-3xlarge-bold">{getOrderNo()}</Typography>
          <Flex gap={vars.spacing[2]}>
            {match(step)
              .with(10, () => (
                <>
                  <Tag variant="warning" text={__('상품준비')} />
                  <Tag variant="warning" text={__('부분결제')} />
                </>
              ))
              .otherwise(() => (
                <>
                  <Tag variant="warning" text={__('상품준비')} />
                  <Tag variant="plain" text={__('결제완료')} />
                </>
              ))}
          </Flex>
          <Typography variant="body-small" colorToken="textSub">
            {fDate(new Date(), 'yyyy년 MM월 dd일 a hh:mm')}
          </Typography>
        </Clay>

        <Flex.Center height="fit-content" gap={vars.spacing[3]}>
          <Flex.Center gap={vars.spacing[15]}>
            {match(step)
              .with(12, () => (
                <Clay position="relative" zIndex={1}>
                  <IconButton
                    icon={<MessageTextSquare hasDot />}
                    variant="secondary"
                    sx={{
                      background: 'var(--bg, #EDF0F4)',
                    }}
                  />
                  <Flex.Column
                    width={186}
                    padding={`${vars.spacing[2]} ${vars.spacing[4]}`}
                    borderRadius={vars.rounded.medium}
                    gap={vars.spacing[2]}
                    position="absolute"
                    backgroundColor={vars.semantic.color.surface}
                    right={48}
                    top={0}
                  >
                    <Typography variant="label-small">
                      {__('타임라인')}
                    </Typography>
                    <Typography
                      variant="label-small"
                      colorToken="textSub"
                      whiteSpace="pre-wrap"
                    >
                      {__('관리자 메모와 주문 히스토리를\n확인할 수 있어요')}
                    </Typography>
                  </Flex.Column>
                </Clay>
              ))
              .otherwise(() => (
                <IconButton
                  icon={<MessageTextSquare hasDot />}
                  variant="secondary"
                />
              ))}
            <IconButton icon={<Printer />} variant="secondary" />
          </Flex.Center>
          <ButtonGroup>
            {match(step)
              .with(9, () => (
                <>
                  <ButtonHighlight text={__('추가')} />
                  <Button text={__('취소')} variant="primary" tonal />
                  <Button text={__('반품')} variant="primary" tonal />
                </>
              ))
              .with(11, () => (
                <>
                  <>
                    <Button text={__('추가')} variant="primary" tonal />
                    <ButtonHighlight text={__('취소')} />
                    <ButtonHighlight text={__('반품')} />
                  </>
                </>
              ))
              .otherwise(() => (
                <>
                  <Button text={__('추가')} variant="primary" tonal />
                  <Button text={__('취소')} variant="primary" tonal />
                  <Button text={__('반품')} variant="primary" tonal />
                </>
              ))}
          </ButtonGroup>
        </Flex.Center>
      </Flex>

      {step === 10 && (
        <PageBanner
          text={__(
            '결제 금액에 대한 입금이 확인되어야 주문 상태를 업데이트 할 수 있어요. 단 상품 추가, 취소, 반품 처리는 가능해요.'
          )}
          icon
          variant="warning"
          action="primary"
          buttonText={__('가이드')}
          onClick={log.debug}
          sx={{ marginBottom: vars.spacing[5] }}
        />
      )}

      <Flex gap={vars.spacing[5]}>
        <Flex.Column gap={vars.spacing[4]} flex={1}>
          {/* 섹션 탭 */}
          <Typography variant="label-medium-bold" as="div">
            <Flex
              backgroundColor={vars.semantic.color.surfaceSub}
              borderRadius={vars.rounded.medium}
              padding={vars.spacing[1]}
              justifyContent="space-around"
            >
              {[
                { name: '전체', count: 3 },
                { name: '배송', count: 0 },
                { name: '취소', count: 0 },
                { name: '반품', count: 0 },
              ].map((section, idx) => (
                <Flex.Center
                  padding={`${vars.spacing[15]} ${vars.spacing[3]}`}
                  key={idx}
                  flex={1}
                  borderRadius={vars.rounded.medium}
                  backgroundColor={
                    section.name === '전체'
                      ? vars.semantic.color.actionSecondary
                      : undefined
                  }
                  boxShadow={
                    section.name === '전체'
                      ? vars.dropShadow.raisedButton
                      : undefined
                  }
                >
                  {__('{{섹션이름}} {{count}}', {
                    섹션이름: section.name,
                    count: section.count,
                  })}
                </Flex.Center>
              ))}
            </Flex>
          </Typography>

          {/* 섹션 카드 목록 */}
          <ProdPreparationSection />
          <ShippingReadySection />
        </Flex.Column>

        {/* Info 판넬 */}
        <Flex.Column flexShrink={0} width={336} gap={vars.spacing[4]}>
          <PaymentInfoCard />
          <PurchaserInfoCard />
          <ShippingInfoCard />
        </Flex.Column>
      </Flex>
    </Clay>
  )
}

function ButtonHighlight({ text }: { text: string }) {
  return (
    <Clay position="relative" zIndex={1}>
      <Button
        text={text}
        variant="primary"
        tonal
        sx={{
          background:
            'linear-gradient(0deg, var(--action-primaryTonal, rgba(113, 118, 128, 0.10)) 0%, var(--action-primaryTonal, rgba(113, 118, 128, 0.10)) 100%), #FFF;',
        }}
      />
    </Clay>
  )
}
