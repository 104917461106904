import React from 'react'
import { Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { OnboardingOrderListPage } from './order-list-page'
import { OnboardingOrderDetailPage } from './order-detail-page'
import { MobileAccessView } from './mobile-access-view'
import { match, P } from 'ts-pattern'
import { useOnboardingStep } from './onboarding.store'
import { useDevice } from '~/shared/hooks/use-device'

export default function () {
  const [step] = useOnboardingStep()
  const device = useDevice()

  return (
    <>
      {match({ step, isDesktop: device.desktop })
        .with({ isDesktop: false }, () => <MobileAccessView />)
        .with({ step: P.number.lt(8) }, () => (
          <>
            <OnboardingOrderListPage />
            {/* 딤배경 */}
            <Clay
              position="fixed"
              inset={0}
              backgroundColor={vars.semantic.color.backdrop}
            />
          </>
        ))
        .otherwise(() => (
          <>
            <OnboardingOrderDetailPage />
            {/* 딤배경 */}
            <Clay
              position="fixed"
              inset={0}
              backgroundColor={vars.semantic.color.backdrop}
            />
          </>
        ))}
    </>
  )
}
