import * as React from 'react'
import { Popover } from '~/shared/components/ui/popover'
import { Tag } from '@imwebme/clay-components'
import { IconCheck, IconChevronDown, IconXClose } from '@imwebme/icon'
import { Checkbox } from '@imwebme/clay-components'
import { Control, Controller, useWatch } from 'react-hook-form'
import { Coupon } from '../order-edit/order-edit-type'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'
import { Command } from '~/shared/components/ui/command'

export const CouponSelect = ({
  onChangeCouponSelect,
  control,
  coupons,
  onDeleteCoupon,
}: {
  onChangeCouponSelect?: (couponIssueCode: string) => void
  control: Control<any>
  coupons: Coupon[]
  onDeleteCoupon: (couponIssueCode: string) => void
}) => {
  const [open, setOpen] = React.useState(false)
  const selectedCoupon = useWatch({
    control,
    name: 'coupons',
    defaultValue: [] as Coupon[],
  })
  const getChecked = (couponIssueCode: string, fieldValue: Coupon[]) =>
    fieldValue.some(({ couponIssueCode: code }) => code === couponIssueCode)
  const toggleCouponSelection = (
    couponIssueCode: string,
    isUnlimitedDate: 'Y' | 'N',
    field: any
  ) => {
    const fieldValue = field.value as Coupon[]
    // 유효기간 체크해서 지낫으면 체크 안되게
    const date_object = coupons.find(
      (coupon) => coupon.couponIssueCode === couponIssueCode
    )?.expireDate
    const currentTime = new Date().getTime() // current time in milliseconds
    const expireTime = date_object ? new Date(date_object).getTime() : 0 // expiration time in milliseconds

    if (expireTime - currentTime < 0 && isUnlimitedDate === 'N') {
      return
    }

    if (
      fieldValue.some(({ couponIssueCode: code }) => code === couponIssueCode)
    ) {
      const newCoupons = fieldValue.filter(
        (v) => v.couponIssueCode !== couponIssueCode
      )
      field.onChange(newCoupons)
    } else {
      const newCoupon = coupons.find(
        (coupon) => coupon.couponIssueCode === couponIssueCode
      )
      const newCoupons = [...fieldValue, newCoupon]
      field.onChange(newCoupons)
    }
  }
  const [buttonWidth, setButtonWidth] = React.useState<string | null>(null)
  const buttonRef = React.useRef<HTMLButtonElement | null>(null)

  React.useEffect(() => {
    if (buttonRef.current && !buttonWidth) {
      setButtonWidth(`${buttonRef.current.offsetWidth}px`)
    }
  }, [buttonRef, buttonWidth])
  return (
    <Popover.Root onOpenChange={setOpen}>
      <Popover.Trigger asChild className="border border-solid">
        <button
          ref={buttonRef}
          role="combobox"
          aria-expanded={open}
          className={cn(
            'border-clay-semantic-border h-[40px] flex items-center justify-between w-full max-w-full overflow-x-auto active:scale-x-100 active:scale-y-100 pl-[8px] pr-[12px] rounded-[8px]  bg-clay-semantic-surface hover:bg-clay-semantic-surface data-[state=open]:bg-clay-semantic-surface',
            open && 'border-clay-semantic-focus'
          )}
          style={{ width: buttonWidth ? buttonWidth : '100%' }}
        >
          {selectedCoupon.length ? (
            <div className="flex w-fit gap-[4px] flex-nowrap overflow-x-hidden flex-grow-0">
              {selectedCoupon?.map((coupon: { couponIssueCode: string }) => (
                <div key={coupon.couponIssueCode}>
                  <Tag
                    variant="plain"
                    background={false}
                    className="border border-clay-semantic-border"
                    text={
                      coupons.find(
                        (v) => v.couponIssueCode === coupon.couponIssueCode
                      )?.couponName || ''
                    }
                    trailingIcon={
                      <IconXClose
                        className="pointer-events-auto stroke-2"
                        onClick={(e) => {
                          e.stopPropagation()
                          onDeleteCoupon(coupon.couponIssueCode)
                        }}
                      />
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="text-clay-semantic-textSub typo-clay-body-medium">
              {__('쿠폰을 선택해주세요')}
            </div>
          )}
          <div className="flex relative h-full items-center">
            <div className="h-[28px] w-[8px] absolute right-[-8px] bg-core-gradient-fade-left" />
          </div>
          <IconChevronDown className="shrink-0 stroke-[1.2px] text-clay-semantic-icon ml-[8px]" />
        </button>
      </Popover.Trigger>
      <Popover.Content
        className="!p-0 [&>div]:w-full [&>div]:p-0"
        align="start"
      >
        <Command.Root className="w-full">
          <Command.Group className="p-[8px] h-[400px] overflow-y-auto">
            {coupons.map(
              ({
                couponName,
                couponIssueCode,
                expireDate,
                isUnlimitedDate,
              }) => {
                const date_object = expireDate ? new Date(expireDate) : ''
                const currentTime = new Date().getTime() // current time in milliseconds
                const expireTime = date_object ? date_object.getTime() : 0 // expiration time in milliseconds
                const disabled =
                  expireTime - currentTime < 0 && isUnlimitedDate === 'N'
                const transformedExpireDate = (() => {
                  if (isUnlimitedDate === 'Y') {
                    return __('무제한')
                  }
                  if (disabled) {
                    return __('기간만료')
                  }
                  return expireDate
                })()

                return (
                  <div key={couponIssueCode}>
                    <Controller
                      name="coupons"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Command.Item
                          onSelect={() => {
                            toggleCouponSelection(
                              couponIssueCode,
                              isUnlimitedDate,
                              field
                            )
                            onChangeCouponSelect?.(couponIssueCode)
                          }}
                          className={cn(
                            'py-[6px] px-[20px]',
                            disabled && 'cursor-not-allowed'
                          )}
                          disabled={disabled}
                        >
                          <div className="flex flex-col justify-start">
                            <div className="flex gap-[8px] items-center p-[2px 0]">
                              <Checkbox
                                checked={getChecked(
                                  couponIssueCode,
                                  field.value
                                )}
                                disabled={disabled}
                              />
                              <div
                                className={cn(
                                  'typo-clay-label-medium',
                                  disabled && 'text-clay-semantic-textDisabled'
                                )}
                              >
                                {couponName}
                              </div>
                              <div>
                                {disabled && (
                                  <IconCheck className="stroke-2 text-clay-semantic-iconOn" />
                                )}
                              </div>
                            </div>
                            <div
                              className={cn(
                                'pl-[24px] text-clay-semantic-textSub typo-clay-body-small',
                                disabled && 'text-clay-semantic-textDisabled'
                              )}
                            >
                              {transformedExpireDate}
                            </div>
                          </div>
                        </Command.Item>
                      )}
                    />
                  </div>
                )
              }
            )}
          </Command.Group>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  )
}
