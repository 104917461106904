import {
  type SetStateAction,
  atom,
  useAtom,
  useAtomValue,
  useSetAtom,
} from 'jotai'
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils'
import { type DateRange } from 'react-day-picker'

type AtomType = DateRange
const RadioOptionDaterangeOriginalState = atomWithReset<AtomType>({
  from: undefined,
  to: undefined,
})
RadioOptionDaterangeOriginalState.debugLabel =
  'RadioOptionDaterangeOriginalState'

const RadioOptionDaterangeState = atom(
  (get) => get(RadioOptionDaterangeOriginalState),
  (_get, set, update: SetStateAction<AtomType> | typeof RESET) => {
    if (update === RESET) {
      set(RadioOptionDaterangeOriginalState, RESET)
      return
    }
    if (typeof update === 'function') {
      set(RadioOptionDaterangeOriginalState, (prev) => update(prev))
      return
    }
    set(RadioOptionDaterangeOriginalState, update)
  }
)
RadioOptionDaterangeState.debugLabel = 'RadioOptionDaterangeState'

export const useRadioOptionDaterangeAtom = {
  state: () => useAtom(RadioOptionDaterangeState),
  get: () => useAtomValue(RadioOptionDaterangeState),
  set: () => useSetAtom(RadioOptionDaterangeState),
  reset: () => useResetAtom(RadioOptionDaterangeState),
}
