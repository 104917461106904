import { useOrderSectionAtom } from '~t'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { useOrderAddConfirmAtom } from '~/container/order/order-add/order-add.store'
import { useCalc } from '~/container/order/order-edit/order-edit-fn'

export const useGetAddData = () => {
  const itemPrice = useCalc()
  const items = useOrderSectionAtom.valueUpdateAll()

  const [addComfirmData] = useOrderAddConfirmAtom.submit()
  if (!items || !addComfirmData) {
    return {
      itemPrice,
      itemAddedItems: [],
      countIncreasedItems: [],
      addComfirmData: [],
    }
  }

  const baseItems = pipe(
    items,
    A.map((e) => e.value),
    A.flatten
  )
  // 추가된 상품
  const itemAddedItems = pipe(
    baseItems,
    A.filter((e) => e.isNew),
    A.map((e) => ({
      ...e,
      qty: e.qtyChange,
      price: (e.originalPrice || 0) - (e.discountPrice || 0),
    }))
  )
  const countIncreasedItems = pipe(
    baseItems,
    A.filter((e) => !e.isNew),
    A.filter((e) => e.qty !== 0 && (e.qtyChange || 0) > 0),
    A.map((e) => ({ ...e, qty: e.qtyChange }))
  )
  return {
    itemPrice,
    itemAddedItems,
    countIncreasedItems,
    addComfirmData: pipe(
      addComfirmData,
      A.map((e) => {
        if (e.orderSectionCode === 'new') {
          return {
            prods: e.prods,
          }
        }
        return e
      })
    ),
  }
}
