import { z } from 'zod'
import { getResSiteUnitItemDto } from '~/entities/site/unit'
import { __ } from '~/shared/i18n'
import {
  YN,
  상수_배송결제타입,
  상수_배송타입,
  상수_상품옵션타입,
  상수_상품타입,
  상수_주소형식,
} from '~/entities/@x'
import { couponDto } from '~/entities/member-discount-info'
import { 배송지_직접입력 } from './스텝2배송설정/delivery-setup.constants'
import { match } from 'ts-pattern'

//================================= 스텝0:주문정보입력 =================================//

export const emailRequiredSchema = z.object({
  ordererName: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요')),
  ordererCall: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .min(5, __('5자 이상 입력해주세요'))
    .max(30, __('30자 이내로 입력해주세요')),
  ordererEmail: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .email({ message: __('이메일 형식을 확인해주세요') })
    .max(90, __('90자 이내로 입력해 주세요')),
})
export type TEmailRequiredSchema = z.infer<typeof emailRequiredSchema>

export const emailNonRequiredSchema = emailRequiredSchema.extend({
  ordererEmail: z.union([
    z
      .string()
      .email({ message: __('이메일 형식을 확인해주세요') })
      .max(90, __('90자 이내로 입력해 주세요')),
    z.literal(''),
    z.undefined(),
  ]),
})
export type TEmailNonRequiredSchema = z.infer<typeof emailNonRequiredSchema>

export const orderInfoSchema = z
  .object({
    unitSite: getResSiteUnitItemDto,
    memberCode: z.string().optional(),
    ordererName: z.string(),
    ordererCall: z.string(),
    ordererEmail: z.string().optional(),
    _isMember: z.boolean().describe('회원여부'),
    _shopUseNotRequireEmail: z.boolean().describe('비회원 이메일 필수 여부'),
    _account: z.string().optional(),
    _profileImgUrl: z.string().optional(),
    _memberName: z.string().optional(),
    _memberCall: z.string().optional(),
    _memberEmail: z.string().optional(),
  })
  .refine((val) =>
    match({
      회원여부: val._isMember,
      비회원이메일선택: val._shopUseNotRequireEmail,
    })
      .with(
        { 회원여부: true },
        () =>
          emailRequiredSchema.extend({ memberCode: z.string() }).safeParse(val)
            .success
      )
      .with(
        { 비회원이메일선택: true },
        () => emailNonRequiredSchema.safeParse(val).success
      )
      .with(
        { 비회원이메일선택: false },
        () => emailRequiredSchema.safeParse(val).success
      )
      .otherwise(() => true)
  )

export type TOrderInfo = z.infer<typeof orderInfoSchema>

//================================= 스텝1:상품선택 =================================//
export const prodSelectSchema = z
  .object({
    prodOptionDetailCode: z.string().nullish().optional(),
    isIndividualOption: z.nativeEnum(YN),
    isRequireOption: z.nativeEnum(YN),
    optionDataList: z
      .array(
        z.object({
          optionType: z.nativeEnum(상수_상품옵션타입),
          optionCode: z.string(),
          valueCode: z.string(),
        })
      )
      .optional(),
    prodCode: z.string(),
    qty: z.number(),
    itemPrice: z.number(),
    _id: z.string().describe('상품코드와 옵션을 포함하는 커스텀 id'),
    _prodName: z.string(),
    _prodImg: z.string().optional(),
    _optionLabels: z.string().array().optional(),
    _baseItemPrice: z.number(),
    _delivCountryList: z.string().array(),
    _delivTypeList: z.nativeEnum(상수_배송타입).array(),
    _delivPayTypeList: z.nativeEnum(상수_배송결제타입).array(),
    _prodType: z.nativeEnum(상수_상품타입),
    _useUnipassNumber: z.nativeEnum(YN).optional(),
    _useCoupon: z.nativeEnum(YN),
    _usePoint: z.nativeEnum(YN),
    _useShoppingGroup: z.nativeEnum(YN),
  })
  .array()
  .min(1)
export type TProdSelect = z.infer<typeof prodSelectSchema>

//================================= 스텝2:배송설정 =================================//
// 1. 목록에서 배송지를 선택한 경우 스키마
const delivSelectSchema = z.object({
  shippingAddressCode: z.string(),
  addressFormat: z.nativeEnum(상수_주소형식),
})

// 2. 배송지를 직접 입력한 경우 스키마(한국)
export const koreanDelivDirectInputSchema = z.object({
  receiverName: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요')),
  receiverCall: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .min(5, __('5자 이상 입력해주세요'))
    .max(30, __('30자 이내로 입력해주세요')),
  addr1: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(200, __('200자 이내로 입력해주세요')),
  addr2: z.string().max(200, __('200자 이내로 입력해주세요')).optional(),
  zipcode: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(20, __('20자 이내로 입력해주세요')),
})
export type TKoreanDelivDirectInputSchema = z.infer<
  typeof koreanDelivDirectInputSchema
>

// 3. 배송지를 직접 입력한 경우 스키마(국제)
export const internationalDelivDirectInputSchema = z.object({
  receiverName: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요')),
  receiverCall: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .min(5, __('5자 이상 입력해주세요'))
    .max(30, __('30자 이내로 입력해주세요')),
  zipcode: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(20, __('20자 이내로 입력해주세요')),
  state: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요')),
  city: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요')),
  street: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요')),
  building: z.string().max(100).optional(),
})
export type TInternationalDelivDirectInputSchema = z.infer<
  typeof internationalDelivDirectInputSchema
>

export const deliverySetupSchema = z
  .object({
    shippingAddressCode: z.string().optional().describe('회원 배송지 코드'),
    receiverName: z.string().optional().describe('수령자명'),
    receiverCall: z.string().optional().describe('수령자전화번호'),
    zipcode: z.string().optional().describe('배송지 우편번호'),
    addr1: z.string().optional().describe('배송지 주소1'),
    addr2: z.string().optional().describe('배송지 주소2'),
    building: z.string().optional().describe('배송지 건물명'),
    street: z.string().optional().describe('배송지 거리명'),
    city: z.string().optional().describe('배송지 도시'),
    state: z.string().optional().describe('배송지 주'),
    country: z.string().optional().describe('배송지 국가코드(ISO_3166)'),
    countryName: z.string().optional().describe('배송지 국가명'),
    addressFormat: z
      .nativeEnum(상수_주소형식)
      .optional()
      .describe('배송지 주소 format'),
    isAddShippingAddress: z
      .nativeEnum(YN)
      .optional()
      .describe('배송지 목록에 추가(배송지 신규 입력 일 때만 넘겨주세요)'),
    isDefaultShippingAddress: z
      .nativeEnum(YN)
      .optional()
      .describe('기본 배송지로 설정(배송지 신규 입력 일 때만 넘겨주세요)'),
    memo: z.string().optional().describe('메모 (배송 요청사항)'),
    pickupMemo: z.string().optional().describe('방문 픽업 메모'),
    unipassNumber: z.string().optional().describe('개인 통관 고유 부호'),
    _deliveryTypeCd: z.nativeEnum(상수_배송타입),
    _deliveryPayTypeCd: z.nativeEnum(상수_배송결제타입),
    _useUnipassNumber: z.boolean().describe('개인통관고유부호 필수 여부'),
  })
  .refine(
    (val) => {
      // 다운로드, 방문수령, 배송없음인 경우 배송지 입력 필요 없음
      if (
        val._deliveryTypeCd === 상수_배송타입.다운로드 ||
        val._deliveryTypeCd === 상수_배송타입.방문수령 ||
        val._deliveryTypeCd === 상수_배송타입.배송없음
      ) {
        return !!val._deliveryPayTypeCd
      }

      // 직접입력 여부, 국가에 따라 스키마 분기
      if (
        val.shippingAddressCode &&
        val.shippingAddressCode !== 배송지_직접입력.code
      ) {
        return delivSelectSchema.safeParse(val).success
      }
      if (val.country === 'KR') {
        return koreanDelivDirectInputSchema.safeParse(val).success
      } else if (val.country !== 'KR') {
        return internationalDelivDirectInputSchema.safeParse(val).success
      }

      return true
    },
    { message: '배송지 스키마 에러' }
  )
  .refine(
    (val) => {
      if (val._useUnipassNumber && !val.unipassNumber) {
        return false
      }
      return true
    },
    { message: '개인통관고유부호 없음' }
  )

export type TDeliverySetup = z.infer<typeof deliverySetupSchema>

//================================= 스텝3:할인설정 =================================//
export const discountSetupSchema = z
  .object({
    unipassNumber: z.string().optional(),
    isGradeDiscount: z.boolean(),
    inputPointAmount: z.number(),
    _selectedCoupons: couponDto.array().optional(),
    _maxPoint: z.number().describe('사용 가능한 적립금'),
  })
  .refine(
    (val) => val.inputPointAmount >= 0 && val.inputPointAmount <= val._maxPoint
  )
export type TDiscountSetup = z.infer<typeof discountSetupSchema>
