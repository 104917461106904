import React from 'react'
import { Dialog } from '~/shared/components/ui/dialog'
import { Button } from '@imwebme/clay-components'
import { Trans } from 'react-i18next'
import { useAtom } from 'jotai'
import { useFormContext } from 'react-hook-form'
import { patchOrderReturnRequest } from '~/container/order/order-return/order-return-api'
import { CRetrieveRequest } from '~/container/order/order-return-info/schema'
import { TReturnRequestUI } from '~/container/order/order-return-info/order-retrun-info.type'
import { IconInfoCircle, IconWarningTriangle } from '@imwebme/icon'
import {
  OrderReturnInfoReturnRequestModalAtom,
  OrderReturnInfoReturnRequestModalWithRefundAtom,
} from '../order-return-info.store'
import { __, __e, useLL } from '~/shared/i18n'
import { useNavigate } from '~/router'
import { useSyncMutation } from '~/shared/hooks/use-sync-mutation'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { orderDetailOptions } from '~/entities/order-detail'
import { 상수_판매채널 } from '~/entities/@x'

/**
 * 반품접수 모달
 */
export const ReturnRequestModal = () => {
  const router = useNavigate()
  const orderCode = useOrderCode()
  const queryClient = useQueryClient()
  const { getValues } = useFormContext<TReturnRequestUI>()
  const [open, setOpen] = useAtom(OrderReturnInfoReturnRequestModalAtom)
  const { mutate, isPending } = useSyncMutation({
    mutationFn: patchOrderReturnRequest,
    mutationKey: ['patchOrderReturnRequest'],
  })
  const onSubmit = async () => {
    const CRR = new CRetrieveRequest(getValues())

    mutate(
      {
        orderCode,
        data: CRR.submitData,
      },
      {
        onSuccess(e) {
          if (e?.data?.length) {
            setOpen(false)
            router('/order/:saleChannel/:orderCode', {
              params: {
                saleChannel: String(상수_판매채널.아임웹),
                orderCode,
              },
            })
            queryClient.invalidateQueries({
              queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
            })
          }
        },
      }
    )
  }
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="sm:max-w-[380px] gap-y-[20px] sm:rounded-[16px] pt-[32px] pb-[20px] px-[32px]">
          <Dialog.Header>
            <Dialog.Title className="semantic-h4-bold">
              <Trans>반품접수 처리 할까요?</Trans>
            </Dialog.Title>
          </Dialog.Header>
          <Dialog.Footer>
            <Dialog.Close asChild>
              <Button
                native={{ type: 'button' }}
                variant="outlined"
                text={__('취소')}
              />
            </Dialog.Close>
            <Button
              native={{ type: 'button' }}
              variant="primary"
              onClick={onSubmit}
              text={__('반품접수 처리')}
              isLoading={isPending}
            />
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

/**
 * 반품접수후 환불까지 같이하는 모달
 */
export const WithReturnRequestModal = () => {
  const router = useNavigate()
  const orderCode = useOrderCode()
  const location = useLL()
  const queryClient = useQueryClient()
  if (orderCode === undefined) {
    throw new Error('🚀 ~ file: order-return.tsx:66 ~ OrderReturn')
  }
  const { getValues } = useFormContext<TReturnRequestUI>()
  const [open, setOpen] = useAtom(
    OrderReturnInfoReturnRequestModalWithRefundAtom
  )
  const { mutate, isPending } = useSyncMutation({
    mutationFn: patchOrderReturnRequest,
    onSuccess: (e) => {
      const afterOrderSectionCode = e?.data[0]?.afterOrderSectionCode
      if (afterOrderSectionCode) {
        setOpen(false)
        queryClient.invalidateQueries({
          queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
        })
        router('/order/:saleChannel/:orderCode/:sectionCode/return/calc', {
          params: {
            saleChannel: String(상수_판매채널.아임웹),
            orderCode,
            sectionCode: afterOrderSectionCode,
          },
        })
      }
    },
    onError: () => {
      toast.error(__('추가 반영되었습니다'), {
        icon: <IconWarningTriangle className="stroke-[2]" />,
      })
    },
    mutationKey: ['patchOrderReturnRequest'],
  })
  const onSubmit = async () => {
    const CRR = new CRetrieveRequest(getValues())
    if (process.env.NODE_ENV === 'development') {
      log.debug(
        '🚀 ~ file: return-request-modal.tsx:14 ~ onSubmit ~ data:',
        CRR.submitData
      )
    }
    mutate({
      orderCode,
      data: CRR.submitData,
    })
  }
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="sm:max-w-[380px] gap-y-[20px] sm:rounded-[16px] pt-[32px] pb-[20px] px-[32px]">
          <Dialog.Header>
            <Dialog.Title className="semantic-h4-bold">
              <Trans>환불도 함께 진행 할까요?</Trans>
            </Dialog.Title>
          </Dialog.Header>
          <div className="px-[16px] py-[12px] grid grid-cols-[16px,1fr] gap-[8px] bg-clay-semantic-surfaceAccent rounded-lg semantic-p14">
            <IconInfoCircle className="stroke-[2] mt-[4px]" />
            <Trans>
              선택한 품목은 반품 접수 처리되고 환불도 이어서 진행되어요
            </Trans>
          </div>
          <Dialog.Footer className="mt-[4px]">
            <Dialog.Close asChild>
              <Button
                native={{ type: 'button' }}
                variant="outlined"
                text={__('닫기')}
              />
            </Dialog.Close>
            <Button
              native={{ type: 'button' }}
              variant="primary"
              onClick={onSubmit}
              text={__('환불 접수')}
              isLoading={isPending}
            />
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
