import React from 'react'
import { Clay, Textfield } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SearchSM } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { debounce } from 'lodash'

interface Props {
  setSearchQuery: (keyword: string) => void
}

export function ProdSearchBar({ setSearchQuery }: Props) {
  const [localQuery, setLocalQuery] = React.useState('')

  const onChangeText = React.useCallback(
    debounce((text: string) => {
      setSearchQuery(text)
    }, 800),
    []
  )

  return (
    <Clay
      sx={{
        marginTop: vars.spacing[5],
        paddingInline: vars.spacing[6],
        marginBottom: vars.spacing[5],
      }}
    >
      <Textfield.Box variant="secondary" size="medium">
        <Textfield.Addon>
          <SearchSM colorToken="icon-sub" />
        </Textfield.Addon>
        <Textfield.Input
          value={localQuery}
          onChangeText={(v) => {
            setLocalQuery(v)
            onChangeText(v)
          }}
          placeholder={__('상품을 검색해 보세요')}
        />
      </Textfield.Box>
    </Clay>
  )
}
