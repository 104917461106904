/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'

/**
 * patch cancel-reason-edit
 */
export const patchResCancelReasonEditSchema = ApiResponseSchema
export const patchPayloadCancelReasonEditSchema = z.object({
  cancelReason: z.string(),
  cancelReasonDetail: z.string().min(1).max(100),
})
