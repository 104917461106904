import { AxiosResponse } from 'axios'
import { ApiClient, type ApiVoidResponse } from '~/shared/api'
import { TPayloadDeliveryHoldSchema } from './delivery-hold.type'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/FqYW66
 * @description delivery-hold-approve
 */
export async function patchDeliveryHold({
  orderCode,
  sectionCode,
  body,
}: {
  orderCode: string
  sectionCode: string
  body: TPayloadDeliveryHoldSchema
}) {
  type T = ApiVoidResponse
  type R = typeof body
  const result = await axios.patch<T, AxiosResponse<ApiVoidResponse>, R>(
    `/order/${orderCode}/sections/${sectionCode}/delivery-hold`,
    body
  )

  return result
}
