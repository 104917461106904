import React from 'react'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'
import { TExternalCancelRequestBase } from '.'

const ExternalCancelRequestContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalCancelRequest }))
)

interface TExternalCancelRequestPack extends TExternalCancelRequestBase {}
export function ExternalCancelRequestPack(props: TExternalCancelRequestPack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalCancelRequestContainer {...props} />
    </React.Suspense>
  )
}
