import React from 'react'
import { flow, pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { type OrderTableNS } from '~/container/orders/orders-table/orders-table-body'
import { fDateToKo } from '~/shared/utils'
import type {
  c3resToTemplateOrderList,
  PrefixedTabColumnConditionDto,
} from './orders-type'
import { __, __c, __g } from '~/shared/i18n'
import { fromSnakeCase } from '~/shared/utils'
import { Gift01, First, Copy02 } from '@imwebme/clay-icons'
import {
  TGetResOrderSearchTabSchema,
  TColumnCondition,
} from '~/entities/order-search-tab'
import { Tooltip, Flex, Typography, IconButton } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { match } from 'ts-pattern'
import GrimIconGrayShop from '~/shared/components/icon/grim-icon-gray-shop.svg?react'
import NaverpayOrderLogo from '~/shared/components/icon/naverpay-order-logo.svg?react'
import TalkcheckoutOrderLogo from '~/shared/components/icon/talkcheckout-order-logo.svg?react'
import { TGetResOrderListDto } from '~/entities/order-list'
import { 상수_배송타입 } from '~/entities/@x'
import { deliveryDto } from '~/entities/@dto'
import { useCopyToClipboard } from 'usehooks-ts'
import { Tooltip as TooltipLegacy } from '~/shared/components/ui/tooltip'
import { toast } from 'react-toastify'

// 메모의 갯수가 0이라면 '-'으로 표시한다.
function getMemoCount(memoCount: number): string | number {
  return memoCount === 0 ? '-' : memoCount
}

const CopyIconButton = ({ text }: { text: string }) => {
  const [_, setCopyClipboard] = useCopyToClipboard()

  return (
    <IconButton
      native={{ type: 'button' }}
      variant="secondary"
      size="tiny"
      icon={<Copy02 color="inherit" />}
      className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      onClick={(e2) => {
        e2.preventDefault()
        e2.stopPropagation()
        setCopyClipboard(text)
        toast.success(__('주문번호가 복사되었습니다.'))
      }}
    />
  )
}

export const transformC3OrderListToUiOrderTable = (
  result: TGetResOrderListDto
) => {
  const transformedResult: c3resToTemplateOrderList = {
    ...result,
    list: pipe(
      result.list,
      A.map(
        flow(
          (e): OrderTableNS.columns => ({
            order_code: e.orderCode,
            order_no: pipe(e, ({ saleChannelIdx, orderNo }) => (
              <Flex gap={vars.spacing[2]} alignItems="center">
                <div className="group-hover:hidden">
                  {match(saleChannelIdx)
                    .with(2, () => <NaverpayOrderLogo />)
                    .with(3, () => <TalkcheckoutOrderLogo />)
                    .otherwise(() => (
                      <GrimIconGrayShop />
                    ))}
                </div>
                <div className="relative w-[20px] h-[20px] group-hover:block hidden">
                  <TooltipLegacy.Provider delayDuration={0}>
                    <TooltipLegacy.Root disableHoverableContent={true}>
                      <TooltipLegacy.Trigger asChild>
                        <div>
                          <CopyIconButton text={orderNo.toString()} />
                        </div>
                      </TooltipLegacy.Trigger>
                      <TooltipLegacy.Portal>
                        <TooltipLegacy.Content
                          className="px-[8px] py-[6px] rounded-[8px] typo-clay-label-small text-clay-semantic-text bg-clay-semantic-surface max-w-[177px]"
                          sideOffset={8}
                          side="top"
                          hideWhenDetached={true}
                        >
                          <Typography variant="label-small" as="div">
                            {__('주문번호 복사')}
                          </Typography>
                        </TooltipLegacy.Content>
                      </TooltipLegacy.Portal>
                    </TooltipLegacy.Root>
                  </TooltipLegacy.Provider>
                  {/* <Tooltip>
                    <Tooltip.Trigger as="div" css={{ position: 'static' }}>
                      <CopyIconButton text={orderNo.toString()} />
                    </Tooltip.Trigger>
                    <Tooltip.Content position="top">
                      <Typography variant="label-small" as="div">
                        {__('주문번호 복사')}
                      </Typography>
                    </Tooltip.Content>
                  </Tooltip> */}
                </div>
                <Typography variant="body-medium" sx={{ width: '130px' }}>
                  {orderNo}
                </Typography>
                {e.isGift === 'Y' && (
                  <IconTooltip
                    icon={<Gift01 color="inherit" />}
                    content={__('선물하기 주문')}
                  />
                )}
                {e.isFirst === 'Y' && (
                  <IconTooltip
                    icon={<First color="inherit" />}
                    content={__('첫구매 주문')}
                  />
                )}
              </Flex>
            )),
            channel: pipe(e.saleChannelName),
            channel_type: pipe(e.saleChannelIdx),
            order_date: pipe(e.orderDate, (date) => fDateToKo(new Date(date))),
            payment_date: pipe(
              e.paymentDate || null,
              O.fromNullable,
              O.fold(
                // 결제일이 없다면 '-'으로 표시한다.
                () => '-',
                (date) => fDateToKo(new Date(date))
              )
            ),
            orderer_name: pipe(e.ordererName),
            item_count: pipe(e.orderSectionList),
            num_items: e.totalOrderItemQty,
            paid_price: pipe(
              e.totalPgAmount,
              Number,
              (p) => (p === 0 ? undefined : p),
              O.fromNullable,
              O.fold(
                () => '-',
                (pa) =>
                  __('{{price, 3comma}} {{currency, currency}}', {
                    price: pa,
                    formatParams: {
                      currency: {
                        currency: e.currency,
                      },
                    },
                  })
              )
            ),
            payment_method: pipe(
              e.pgMethodCd,
              (text) => (!text ? undefined : text),
              O.fromNullable,
              O.fold(
                () => '-',
                flow((r) => r, __g)
              )
            ),
            transaction_status: pipe(e.orderStatusCd, __g),
            /**
             * flowchart TD
             *    A[order_status] -->B{단일문자열 _ 포함되었는지 확인}
             *    B --> C[용어집으로 번역]
             *    B --> D[용어집으로 번역]
             *    D --> E{번역했지만 _ 포함된 snake_case인가?}
             *    E --> R[snake_case가 아니다. 용어집 번역본을 사용]
             *    E --> T[snake_case가 맞다. 커스텀 __c 번역본을 사용]
             */
            order_status: pipe(
              e.sectionStatusCd,
              // _있는지 없는지 확인하기
              (r) => {
                if (r.includes('_')) {
                  return O.some(r)
                }
                return O.none
              },
              O.fold(
                () => pipe(e.sectionStatusCd, __g),
                flow(
                  (t) => t,
                  __g,
                  fromSnakeCase,
                  O.fold(
                    () => pipe(e.sectionStatusCd, __c),
                    flow((r) => r)
                  )
                )
              )
            ),
            section_status: pipe(e.sectionStatusCd),
            payment_status: pipe(e.paymentStatusCd),
            /**
             * __('NORMAL') 정상
             * __('PENDING') 처리필요
             * __('ING') 처리중
             * __('COMPLETED') 처리완료
             */
            cs_status: pipe(e.csStatusCd),
            tracking_number: pipe(e.invoiceNo),
            memo_count: pipe(e.memoCount, getMemoCount),
            /**
             * __('국내')
             * __('해외')
             */
            delivery_type: pipe(
              e.deliveryType,
              O.fromNullable,
              O.fold(() => '', __)
            ),
            member_code: pipe(e.memberCode),
            is_member: pipe(e.isMember),
            cash_receipt: pipe(
              e.cashReceiptInfo?.cashReceiptStatusCd,
              O.fromNullable,
              O.fold(
                () => '',
                (cashReceiptStatusCd) => __g(cashReceiptStatusCd)
              )
            ),
            delivery_type_cd: pipe(e.orderSectionList),
            // external_channel_order_status: pipe(
            //   e.externalChannelOrderInfo?.status ?? ''
            // ),
            external_channel_order_no: pipe(
              e.externalChannelOrderInfo?.orderNo ?? ''
            ),
            is_request_payment: pipe(e.isRequestPayment),
            has_delivery_hold: pipe(e.orderSectionList, (orderSectionList) => {
              const hasDeliveryHold =
                orderSectionList?.some(
                  (orderSection) => orderSection.isDeliveryHold === 'Y'
                ) ?? false

              return hasDeliveryHold
            }),
            delivery: pipe(
              e,
              ({ orderDeliveryList, orderSectionList, unipassNumber }) => {
                if (!orderDeliveryList || orderDeliveryList.length === 0) {
                  return __('없음')
                }

                const deliveryTypeCd = orderSectionList[0]?.deliveryTypeCd
                if (
                  !deliveryTypeCd ||
                  deliveryTypeCd === 상수_배송타입.배송없음 ||
                  deliveryTypeCd === 상수_배송타입.다운로드 ||
                  deliveryTypeCd === 상수_배송타입.방문수령
                ) {
                  return __('없음')
                }

                const parsed = deliveryDto.safeParse(orderDeliveryList[0])
                if (!parsed.success) {
                  return __('입력 필요')
                }

                return { orderDeliveryList, unipassNumber }
              }
            ),
          })
        )
      )
    ),
  }
  return transformedResult
}

export const transformC3OrderTabToUiOrderTab = (
  result: TGetResOrderSearchTabSchema
) =>
  pipe(
    result.list,
    A.map((e) => ({
      adminOrderSearchTabCode: e.adminOrderSearchTabCode,
      id: e.adminOrderSearchTabCode,
      name: e.name,
      isAllTab: e.name === '전체',
    }))
  )

/**
 * 탭 컬럼컨디션을 받아 t 접두사가 붙은 쿼리스트링을 반환
 */
export const getTabQueryString = (
  columnCondition: TColumnCondition | undefined | null
): PrefixedTabColumnConditionDto =>
  pipe(
    columnCondition,
    O.fromNullable,
    O.fold(
      () => ({}),
      (condition) =>
        pipe(
          condition,
          R.filter((v) => v !== undefined && v !== null),
          R.keys<keyof TColumnCondition>,
          A.reduce<string, Record<string, string>>({}, (acc, key) => {
            const prefixedKey = `t${key.charAt(0).toUpperCase() + key.slice(1)}`
            Object.assign(acc, {
              [prefixedKey]: condition[key as keyof TColumnCondition],
            })
            return acc
          })
        )
    )
  )

function IconTooltip({
  icon,
  content,
}: {
  icon: React.ReactNode
  content: string
}) {
  return (
    <Tooltip>
      <Tooltip.Trigger as="div">{icon}</Tooltip.Trigger>
      <Tooltip.Content position="top">
        <Typography variant="label-small">{content}</Typography>
      </Tooltip.Content>
    </Tooltip>
  )
}
