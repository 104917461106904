/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { patchPayloadNextShippingReadyDto, targetItemInformationDto } from '.'

export const patchPayloadNextShippingReadyRdo =
  patchPayloadNextShippingReadyDto.extend({
    targetItemInformation: z.array(
      targetItemInformationDto.extend({
        _check: z.boolean().optional().optional(),
      })
    ),
  })
