/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import {
  상수_결제상태,
  상수_판매채널,
  상수_주문상태,
  상수_주문타입,
  YN,
  zYn,
} from '~/entities/@x'
import { 상수_외부채널주문_채널상태 } from '~/entities/@x/외부채널주문_채널상태'
import {
  deliveryDto,
  orderBadgeResponseDto,
  orderSectionDto,
} from '~/entities/@dto'

// ====================================== salechannel
export const saleChannelItemDto = z
  .object({
    saleChannelIdx: z
      .nativeEnum(상수_판매채널)
      .describe('판매 채널 인덱스 (2: naverpay, 3: talkcheckout)'),
    siteCode: z.string().describe('사이트 코드'),
    orderCode: z.string().describe('주문 코드'),
    orderSectionItemCode: z.string().describe('주문 품목 코드'),
    channelOrderNo: z.string().nullish().describe('채널 주문 번호'),
    channelOrderItemNo: z.string().nullish().describe('채널 품목 주문 번호'),
    claimId: z.string().nullish().describe('채널 품목 클레임 ID'),
    channelStatus: z
      .nativeEnum(상수_외부채널주문_채널상태)
      .nullish()
      .describe('채널 품목 주문 상태 (채널별로 다름)'),
    apiProductInfo: z
      .object({
        deliveryDelayInfo: z
          .object({
            deliveryDelayReason: z.string(),
            deliveryDelayReasonDetail: z.string(),
            dueDate: z.string().datetime(),
            requestDate: z.string().datetime(),
          })
          .nullable(),
        memo: z.string().nullish(),
      })
      .describe('외부채널 API로 전달되는 품목정보'),
    wtime: z.string().nullish().describe('등록 일시'),
    mtime: z.string().describe('변경 일시'),
  })
  .describe('외부채널 주문 연동 품목 정보 리스트')

// ====================================== payment
const bodyDataDto = z.object({
  price: z.number().describe('현금 결제 금액'),
  pointAmount: z.number().describe('포인트 사용액'),
  chargeAmount: z.number().describe('충전금 사용액'),
  payLaterPaymentAmount: z.number().describe('후불 결제 최종금액'),
  returnDeliveryFeeAmount: z.number().describe('반품 배송비'),
  paymentMethod: z.string().nullish().describe('간편결제 결제수단정보'),
  paymentDate: z.string().nullish().describe('결제일시 (kst)'),
  completeDate: z.string().describe('처리완료일시 (kst)'),
})

const easyDto = z
  .object({
    siteCode: z.string().describe('사이트코드'),
    paymentCode: z.string().describe('payment code'),
    bodyData: bodyDataDto.describe('외부채널 간편결제 데이터'),
    wtime: z.string().datetime().describe('등록일시'),
  })
  .describe('간편결제 정보')

export const paymentListItemDto = z.object({
  pgName: z.string().describe('PG사 이름'),
  paymentNo: z.string().describe('결제번호(노출용)'),
  statusCd: z.nativeEnum(상수_결제상태).describe('결제상태코드'),
  paidPrice: z.number().nullish().describe('결제금액(취소일때는마이너스)'),
  taxFreePrice: z.number().default(0).nullish().describe('면세금액'),
  paymentEasy: easyDto.describe('간편결제 정보'),
  isCancel: zYn.describe('취소건 여부'),
})

const paymentInfoDto = z.object({
  paymentNo: z.string().describe('결제번호(노출용)'),
  complexTax: z.nativeEnum(YN).describe('복합과세 여부'),
  statusCd: z.nativeEnum(상수_결제상태).describe('결제상태구분코드(OPS00)'),
  totalProductPrice: z.number().default(0).describe('상품 금액'),
  totalDeliveryPrice: z
    .number()
    .default(0)
    .describe('배송비 (일반 배송비 + 도서산간 배송비)'),
  firstPaidPrice: z
    .number()
    .default(0)
    .describe('최초 결제 금액(입금대기일 경우 결제 예정 금액으로 인지)'),
  taxFreePrice: z.number().default(0).describe('면세금액'),
  totalRefundPrice: z.number().default(0).describe('총 환불 금액'),
  remainPrice: z.number().default(0).describe('총 합계 금액'),
  paymentList: z.array(paymentListItemDto).describe('주문 결제정보 목록'),
  deliveryPayType: z.string().describe('배송비 결제방식'),
})

/**
 * get external-order
 */
export const getResExternalOrderDto = z.object({
  siteCode: z.string().describe('사이트코드'),
  unitCode: z.string().describe('유닛코드'),
  orderCode: z.string().describe('주문 코드'),
  saleChannelIdx: z
    .nativeEnum(상수_판매채널)
    .describe('판매채널번호 1: imweb, 2: naverpay, 3: talkcheckout'),
  isMember: z.nativeEnum(YN).describe('회원여부'),
  orderNo: z.number().describe('주문 번호'),
  orderTypeCd: z.nativeEnum(상수_주문타입).describe('주문 타입 코드'),
  statusCd: z.nativeEnum(상수_주문상태).describe('주문 상태 코드'),
  currency: z.string().describe('통화 구분번호'),
  paymentPrice: z.number().describe('주문금액'),
  baseItemPrice: z.number().describe('할인 적용 전 품목금액'),
  itemPrice: z.number().describe('할인 적용 후 품목금액'),
  deliveryPrice: z.number().describe('배송금액'),
  deliveryIslandPrice: z.number().describe('도서산간배송비'),
  taxFreePrice: z.number().describe('면세금액'),
  pgAmount: z.number().describe('결제금액'),
  pgCancelPrice: z.number().describe('취소금액'),
  memberCode: z.string().describe('주문고객코드'),
  ordererEmail: z.string().describe('주문자이메일'),
  ordererCall: z.string().describe('주문자전화번호'),
  ordererName: z.string().describe('주문자명'),
  unipassNumber: z.string().nullish().describe('개인통관고유부호'),
  deviceCd: z
    .string()
    .describe('디바이스타입코드(DTA00) - DTA01(pc), DTA02(mobile)'),
  wtime: z.string().datetime().describe('등록일시'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  mtime: z.string().datetime().describe('변경일시'),
  groupPassCode: z
    .string()
    .nullish()
    .describe('그룹 패스 주문일 경우 적용될 그룹 코드'),
  groupPassDayCnt: z
    .number()
    .nullish()
    .describe('그룹 패스 주문일 경우 연장될 기간'),
  country: z.string().describe('국가코드(ISO_3166)'),
  badge: orderBadgeResponseDto.nullish().describe('주문 뱃지 정보'),
  saleChannelName: z.string().describe('판매채널명'),
  orderSectionList: z
    .array(orderSectionDto)
    .nullish()
    .describe('주문 하위 섹션 리스트'),
  paymentInfo: paymentInfoDto,
  orderDeliveryList: z.array(deliveryDto),
  saleChannelItemList: z.array(saleChannelItemDto),
})
