/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

export const patchResDeliveryDto = z.any()

export const patchPayloadDeliveryDto = z.object({
  orderSectionCode: z.string().describe('섹션 코드'),
  orderDeliveryCode: z.string().describe('주문 배송지 코드'),
})
