import React from 'react'
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@imwebme/clay-components'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { AppProvider } from '~/app/provider/_app.provider'
import { useModals } from '~/router'
import { useMemoCode } from '~/shared/hooks/use-memo-code'
import { useOrderCode } from '~/shared/hooks/use-order-code'

function MemoDelete() {
  const params = useParams()
  const orderCode = useOrderCode()
  const memoCode = useMemoCode()
  const modals = useModals()
  log.debug('params', params)
  log.debug('orderCode', orderCode)
  log.debug('memoCode', memoCode)

  const handleClose = () => modals.close()
  const queryClient = useQueryClient()

  return (
    <Modal isOpen setClose={() => void {}} width="medium">
      <ModalHeader text="Title" type="leftAlign" />
      <ModalBody>Modal content</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            fullWidth
            text="닫기"
            variant="secondary"
            onClick={() => handleClose()}
          />
          <Button fullWidth text="안녕" variant="primary" />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

export default AppProvider(MemoDelete)
