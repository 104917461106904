import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Coupon } from '~t'
import { CouponRequestDto } from './order-add-calc.type'
import { OrderDetailKey } from './order-add-calc-constants'
import { DiscountResultData, FetchDiscountParams } from './order-add-calc.type'
import { ApiClient } from '~/shared/api'
import { AddItemsToOrderParams } from './order-add-calc-query.type'

const axiosInstance = ApiClient()

// ====================================== 1. useQuery ======================================

/** 주문서 반영 */
export const addItemsToOrder = async ({
  orderCode,
  params,
}: AddItemsToOrderParams) => {
  const ENDPOINT = `/order/${orderCode}/sections/items`
  const { data } = await axiosInstance.post(ENDPOINT, params)
  return data
}

// 주문 상세 조회
export const getOrderDetailData = async (orderCode: string) => {
  const ENDPOINT = `/order/${orderCode}`
  const { data } = await axiosInstance({
    url: ENDPOINT,
    method: 'get',
  })

  return data.data
}

/** 할인 데이터 가져오기 */

export const fetchDiscountData = async ({
  orderCode,
  params,
}: FetchDiscountParams) => {
  const ENDPOINT = `/order/${orderCode}/sections/items/calculate-price`
  const { data } = await axiosInstance.post(ENDPOINT, params)
  return data.data
}

// 쿠폰, 등급할인, 포인트 데이터 가져오기
export const getAdditionalDiscount = async (orderCode: string) => {
  const ENDPOINT = `/order/${orderCode}/orderer/additional-discount`
  const { data } = await axiosInstance.get(ENDPOINT)
  const { isUsedMembership, points, coupons } = data.data
  return { isUsedMembership, points, coupons }
}

// ====================================== 1. useQuery ======================================
export const useGetOrderEditCalcPageData = (orderCode: string) =>
  useQuery({
    queryKey: OrderDetailKey.getOrderDetailData,
    queryFn: () => getOrderDetailData(orderCode),
    select: (data) => {
      const { currency } = data
      return currency
    },
  })

export const useGetAdditionalDiscount = (orderCode: string) =>
  useQuery({
    queryKey: ['additional-discount'],
    queryFn: () => getAdditionalDiscount(orderCode),
    select: (data) => {
      const { coupons, points, isUsedMembership } = data
      const transformedCoupons =
        coupons?.map((coupon: CouponRequestDto) => {
          const { code, name, isUnlimitedDate, endDate } = coupon

          const transformedCoupon: Coupon = {
            couponIssueCode: code,
            couponName: name,
            expireDate: isUnlimitedDate ? '무제한' : endDate,
            isUnlimitedDate,
          }
          return transformedCoupon
        }) ?? []

      return { coupons: transformedCoupons, points, isUsedMembership }
    },
  })

export const useFetchDiscountData = ({
  fetchDiscountDataParam,
  successCb: _,
}: {
  fetchDiscountDataParam: FetchDiscountParams
  successCb?: (res: DiscountResultData) => void
}) =>
  useQuery({
    queryKey: ['discount'],
    queryFn: () => fetchDiscountData(fetchDiscountDataParam),
    select: (data: DiscountResultData) => data,
  })
