import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResBulkActionSectionInvoicePrintApplyAllSchema,
  TPostPayloadBulkActionSectionInvoicePrintApplyAllSchema,
} from './bulk-action-section-invoice-print-apply-all.type'
import { postResBulkActionSectionInvoicePrintApplyAllSchema } from './bulk-action-section-invoice-print-apply-all.schema'

const axios = ApiClient()

export async function postBulkActionSectionInvoicePrintApplyAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoicePrintApplyAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionInvoicePrintApplyAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/invoice/print', body)
    .then((e) => e.data)

  postResBulkActionSectionInvoicePrintApplyAllSchema.parse(result.data)
  return result.data
}
