import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBankTransferConfirmRevertDto,
  type TPostPayloadBankTransferConfirmRevertDto,
  postResBankTransferConfirmRevertDto,
  postPayloadBankTransferConfirmRevertDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/U3eEJc
 * @description 주문 무통장 결제 수동 입금확인 처리 되돌리기
 */
export function postBankTransferConfirmRevert({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPostPayloadBankTransferConfirmRevertDto) {
    type R = typeof body
    type T = TPostResBankTransferConfirmRevertDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/payment/bank-transfer/confirm/revert`, body)
      .then((res) => res.data)

    postResBankTransferConfirmRevertDto.parse(result.data)
    return result
  }
}
