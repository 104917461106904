import React from 'react'
import { TItem } from '../../product-search.type'
import { RNCOOptionContainer } from './rnco-option.container'

/**
 * 필수비조합형
 */
export const RncoOption = ({
  productData,
  searchId,
  optionKey,
}: {
  productData: TItem
  searchId: string
  optionKey: string
}) => (
  <>
    <RNCOOptionContainer
      id={productData.prodCode}
      searchId={searchId}
      optionKey={optionKey}
      // 처음에는 상품값 + 옵션값으로 넣어줬는데 아임웹 비조합계산에서는 상품값을 추가하지 않는다.
      productPrice="0"
      data-type="rnco-product"
      {...productData}
      // options={pipe(
      //   options,
      //   A.filter((e) => e.isRequiredOption === true)
      // )}
    />
    {/* 여기에 선택옵션 렌더링해야한다. */}
    <div />
  </>
)

export const RncoOptionMemo = React.memo(RncoOption)
