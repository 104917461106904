import React from 'react'
import { __ } from '~/shared/i18n'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { MessageSendPack } from '~/features/message-send'
import { PurchaserDataType } from '~t/order-detail'
import { PurchaserInfoEditPack } from './modal/구매자정보수정'
import { useSuspenseQuery } from '@tanstack/react-query'
import { MemberInfoModalPack } from '../member-info-modal'
import { UnipassNumberPack } from '~/container/order/order-detail/partials/unipass'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { formatPhoneNumber } from '~/shared/utils/format-phone-number'
import { Typography } from '@imwebme/clay-components'

export interface ModeratedPurchaseData
  extends Omit<PurchaserDataType, 'ordererEmail'> {
  ordererEmailId: string
  ordererEmailDomain: string
}
interface ExternalPurchaserInfoCardProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export function ExternalPurchaserInfoCard(_: ExternalPurchaserInfoCardProps) {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))

  return (
    <>
      <div className="bg-clay-semantic-surface rounded-[12px] py-[20px]">
        <div className="flex justify-between items-center px-[24px] pb-[20px]">
          <Typography variant="heading-medium-bold">
            {__('구매자 정보')}
          </Typography>
        </div>
        <div className="px-[24px] space-y-[4px]">
          {data.ordererEmail && (
            <div className="leading-[24px] text-[14px]">
              {data.ordererEmail}
            </div>
          )}
          <div className="leading-[24px] text-[14px]">
            {data.ordererName ? (
              <div className="flex items-center gap-x-clay-15">
                <div>{data.ordererName}</div>
              </div>
            ) : (
              __('데이터 없음')
            )}
          </div>
          {/* 연락처 */}
          <MessageSendPack
            receiverCall={formatPhoneNumber(data.ordererCall)}
            receiverName={data.ordererName}
            unitCode={data.unitCode}
            atomKey="purchase-info-card"
          />
          <UnipassNumberPack unipassNumber={data.unipassNumber} />
        </div>
      </div>
      <PurchaserInfoEditPack />
      <MemberInfoModalPack memberCode={data.memberCode} />
    </>
  )
}
