import React from 'react'
import {
  ExternalOrderReturnSelectSection,
  TExternalOrderReturnSelectSectionBase as TExternalOrderReturnSelectSectionBase,
} from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

interface TExternalOrderReturnSelectSectionPack
  extends TExternalOrderReturnSelectSectionBase {}
export function ExternalOrderReturnSelectSectionPack(
  props: TExternalOrderReturnSelectSectionPack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalOrderReturnSelectSection {...props} />
    </React.Suspense>
  )
}
