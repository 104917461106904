import { z } from 'zod'
import { zYn } from '~/entities/@x'

const SEARCH_DATA_TYPE = {
  ORDER_DATE: 'orderDate',
  PAYMENT_DATE: 'paymentDate',
  INVOICE_NO_CREATE_DATE: 'invoiceNoCreateDate',
  DELIVERY_SEND_REQUEST_DATE: 'deliverySendRequestDate',
  MEMO_CREATE_DATE: 'memoCreateDate',
  DELIVERY_COMPLETE_DATE: 'deliveryCompleteDate',
  DELIVERY_DELAY_DATE: 'deliveryDelayDate',
} as const
const DATE_RANGE = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  CUSTOM: 'CUSTOM',
} as const

export const postResBulkActionSectionInvoicePrintValidateAllSchema = z.object({
  fulfilled: z.number().nullish(),
  rejected: z.number().nullish(),
})
export const postPayloadBulkActionSectionInvoicePrintValidateAllSchema =
  z.object({
    searchTab: z.object({
      tSaleChannelIdx: z.array(z.number()).nullish(),
      tOrderStatusCd: z.array(z.string()).nullish(),
      tUnitCode: z.array(z.string()).nullish(),
      tPaymentStatusCd: z.array(z.string()).nullish(),
      tOrderSectionStatusCd: z.array(z.string()).nullish(),
      tPgMethod: z.array(z.string()).nullish(),
      tIsMember: zYn.nullish(),
      tIsDomestic: zYn.nullish(),
    }),
    searchFilter: z.object({
      fSearchDateType: z.nativeEnum(SEARCH_DATA_TYPE).nullish(),
      fDateRange: z.nativeEnum(DATE_RANGE).nullish(),
      fStartDate: z.string().nullish(),
      fEndDate: z.string().nullish(),
      fPgMethod: z.array(z.string()).nullish(),
      fDiscountType: z.array(z.string()).nullish(),
      fMinPaymentPrice: z.number().nullish(),
      fMaxPaymentPrice: z.number().nullish(),
      fOrderItemCount: z.number().nullish(),
      fOrderNo: z.number().nullish(),
      fOrdererName: z.string().nullish(),
      fOrdererEmail: z.string().nullish(),
      fOrdererCall: z.string().nullish(),
      fInvoiceNo: z.string().nullish(),
      fDepositorName: z.string().nullish(),
      fReceiverName: z.string().nullish(),
      fReceiverCall: z.string().nullish(),
      fReceiverAddress: z.string().nullish(),
      fDepositBankName: z.array(z.string()).nullish(),
      fCashReceiptStatus: z.string().nullish(),
      fProdCode: z.array(z.string()).nullish(),
      fIsMember: zYn.nullish(),
      fIsFirstOrderUser: zYn.nullish(),
      fDeliveryDelayDay: z.number().nullish(),
      fOrderCategory: z.array(z.string()).nullish(),
      fDeliveryIsInput: z.string().nullish(),
      fDeliveryTypeCd: z.array(z.string()).nullish(),
    }),
    keyword: z.string().nullish(),
  })
