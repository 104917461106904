import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TContainerProps } from './cash-receipt-preview.container'
import { Skeleton, DropdownTriggerBtn } from '../partials'
import { ErrorCard } from '~/shared/components/error'

const CashReceiptPreviewContainer = React.lazy(() =>
  import('./cash-receipt-preview.container').then((module) => ({
    default: module.CashReceiptPreviewContainer,
  }))
)

interface TPackProps extends TContainerProps {
  children: React.ReactNode
}

export function CashReceiptPreviewPack({ children, ...props }: TPackProps) {
  const [open, setOpen] = React.useState(false)

  if (!children) {
    return null
  }
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownTriggerBtn {...{ setOpen }}>{children}</DropdownTriggerBtn>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={8}
          align="start"
          onCloseAutoFocus={(evt) => evt.preventDefault()}
          className="w-[240px] bg-clay-semantic-surface py-[16px] rounded-[8px] _drop-shadow h-fit max-h-[min(var(--radix-popper-available-height),400px)] overflow-y-auto"
          hideWhenDetached
          collisionPadding={12}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Skeleton />}>
              <CashReceiptPreviewContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
