import { ApiClient } from '~/shared/api'
import { type TGetResMembersDto, type TGetPayloadMembersDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 사이트에 하위 유닛 사이트 회원 목록 조회
 */
export async function getMembers(params: TGetPayloadMembersDto) {
  const result = await axios
    .get<ApiResponse<TGetResMembersDto>>('/site/members', { params })
    .then((res) => res.data)

  return result.data
}
