import * as Portal from '@radix-ui/react-portal'
import React from 'react'
import { __ } from '../../i18n'
import { usePopper } from 'react-popper'
import { OutsideClickComponenet, OutsideClickHandlerProps } from '../outside'
import {
  AutoPlacement,
  BasePlacement,
  VariationPlacement,
} from '@popperjs/core'

// ====================================== CALC ======================================
function getSpaceBelowElement(element: HTMLElement | null): number {
  // 요소가 null인 경우, 0을 반환
  if (!element) {
    return 0
  }

  // 윈도우의 전체 높이를 구합니다.
  const windowHeight: number = window.innerHeight

  // referenceElement의 위치 정보를 구합니다.
  const rect = element.getBoundingClientRect()

  // referenceElement의 하단 위치를 구합니다.
  const bottomPosition: number = rect.bottom

  // 윈도우 하단까지 남은 공간을 계산합니다.
  const spaceBelow: number = windowHeight - bottomPosition - rect.height

  // 계산된 공간을 반환합니다.
  return spaceBelow
}

// ====================================== COMPONENT ======================================
type CommonProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>
interface ContentProps extends CommonProps {
  name: 'content'
  placement?: AutoPlacement | BasePlacement | VariationPlacement
  children: ({ height }: { height: number }) => React.ReactNode
}
interface OtherProps extends CommonProps {
  name: 'trigger' | string
  children?: React.ReactNode
}
type SlotProps = ContentProps | OtherProps
const Slot: React.FC<SlotProps> = () => null

interface TTargetPortal extends Portal.PortalProps {
  open?: boolean
  onOutsideClick?: OutsideClickHandlerProps['onOutsideClick']
}
export const TargetPortal = Object.assign(
  ({
    children,
    open = false,
    onOutsideClick,
    asChild = true,
    container,
    ...props
  }: TTargetPortal) => {
    // ====================================== SLOT ======================================
    const childrenArray = React.Children.toArray(
      children
    ) as unknown as React.ReactElement[]
    function findChild(childName: string): React.ReactElement
    function findChild(
      childName: string,
      optional?: boolean
    ): React.ReactElement | undefined
    function findChild(childName: string, optional?: boolean) {
      const result = childrenArray.find(
        (child) => child?.props?.name === childName
      )
      if (result === undefined && !optional) {
        throw new Error(`${childName} is undefined`)
      }
      return result
    }
    // ====================================== SLOT ======================================
    const TriggerSlot = findChild('trigger')
    const ContentSlot = findChild('content')
    // ====================================== COMPONENT ======================================
    const [referenceElement, setReferenceElement] =
      React.useState<HTMLDivElement | null>(null)
    const [popperElement, setPopperElement] =
      React.useState<HTMLDivElement | null>(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: ContentSlot.props.placement ?? 'bottom',
    })

    const _onOutsideClick = (e: MouseEvent) => {
      onOutsideClick?.(e)
    }

    return (
      <div ref={setReferenceElement}>
        <div {...TriggerSlot.props} />
        {open && (
          <Portal.Root asChild={asChild} container={container}>
            <OutsideClickComponenet onOutsideClick={_onOutsideClick}>
              <div
                ref={setPopperElement}
                style={{
                  ...styles.popper,
                  width: referenceElement?.offsetWidth,
                }}
                {...attributes.popper}
                {...props}
              >
                <div
                  {...ContentSlot.props}
                  children={
                    typeof ContentSlot.props.children === 'function'
                      ? ContentSlot.props.children({
                          height: getSpaceBelowElement(referenceElement),
                        })
                      : ContentSlot.props.children
                  }
                />
              </div>
            </OutsideClickComponenet>
          </Portal.Root>
        )}
      </div>
    )
  },
  { Slot }
)
