import React from 'react'
import { SetStateAction, atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'
import { RowSelectionState } from '@tanstack/react-table'

type AtomKey = {
  tabCode: string
}
type AtomType = {
  key: string
  value?: string[]
}
const codeSelectionState = atomFamily(
  ({ key, value = [] }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)

const useCodeSelectionAtom = {
  key: (p: AtomKey) => `tabCode(${p.tabCode}):order-list-selected`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          codeSelectionState({
            key: useCodeSelectionAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          codeSelectionState({
            key: useCodeSelectionAtom.key(p),
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          codeSelectionState({
            key: useCodeSelectionAtom.key(p),
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          codeSelectionState({
            key: useCodeSelectionAtom.key(p),
          }),
        [p]
      )
    ),
}

type rowSelectionAtomKey = {
  tabCode: string
}
type rowSelectionAtomType = {
  key: string
  value: RowSelectionState
}
const rowSelectionState = atomFamily(
  ({ key, value }: rowSelectionAtomType) => {
    const at = atomWithReset<rowSelectionAtomType>({
      key,
      value,
    })
    const at2 = atom(
      (get) => {
        const _at = get(at)
        return _at.value
      },
      (
        _get,
        set,
        update: SetStateAction<rowSelectionAtomType['value']> | typeof RESET
      ) => {
        if (update === RESET) {
          set(at, RESET)
          return
        }
        if (typeof update === 'function') {
          set(at, (prev) => ({
            key,
            value: update(prev.value),
          }))
          return
        }
        set(at, {
          key,
          value: update,
        })
      }
    )
    at2.debugLabel = key
    return at2
  },
  (a, b) => a.key === b.key
)

const useRowSelectionAtom = {
  key: (p: rowSelectionAtomKey) => `tabCode(${p.tabCode}):order-list-selected`,
  state: (p: rowSelectionAtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          rowSelectionState({
            key: useRowSelectionAtom.key(p),
            value: {},
          }),
        [p]
      )
    ),
  get: (p: rowSelectionAtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          rowSelectionState({
            key: useRowSelectionAtom.key(p),
            value: {},
          }),
        [p]
      )
    ),
  set: (p: rowSelectionAtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          rowSelectionState({
            key: useRowSelectionAtom.key(p),
            value: {},
          }),
        [p]
      )
    ),
  reset: (p: rowSelectionAtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          rowSelectionState({
            key: useRowSelectionAtom.key(p),
            value: {},
          }),
        [p]
      )
    ),
}

export const useOrderAtom = {
  codeSelection: useCodeSelectionAtom,
  rowSelection: useRowSelectionAtom,
}
