import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import type { TProductCard001 } from '~t'
import type { TOrderSectionItemList } from './class-order.type'

export class OrderSectionItemClass {
  #data: TOrderSectionItemList

  #currency: string

  constructor(data: TOrderSectionItemList, currency: string) {
    this.#data = data
    this.#currency = currency
  }

  get template(): TProductCard001.props {
    const result = {
      id: this.#data.orderItemCode,
      thumbnail: this.#data.imageUrl,
      name: this.#data.prodName,
      option: pipe(
        this.#data.optionInfo,
        // FIXME: api bug
        (e) => (typeof e === 'string' ? undefined : e),
        O.fromNullable,
        O.fold(
          () => '',
          flow(
            A.map((e) => `${e.key} ${e.value}`),
            (e) => e.join(', ')
          )
        )
      ),
      qty: this.#data.qty,
      originalPrice: Number(this.#data.baseItemPrice),
      price: Number(this.#data.itemPrice),
      currency: this.#currency,
    }
    return result
  }
}

export default OrderSectionItemClass
