/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { 상수_결제수단 } from '~/entities/@x'

/**
 * get site-pg
 */
export const getResSitePgSchema = z.array(
  z.object({
    methodCd: z.nativeEnum(상수_결제수단),
    methodName: z.string(),
  })
)
