import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const optionSchema = z.object({
  optionCode: z.string().min(1, '필수 입력사항입니다.'),
  optionName: z.string().min(1, '필수 입력사항입니다.'),
  optionValueCode: z.string().optional(),
  optionValueName: z.string().optional(),
  optionValue: z.string().optional(),
  price: z.number().optional(),
  type: z.string().optional(),
})

const optionDetailOptionSchema = z.object({
  isRequiredOption: z.boolean(),
  type: z.string(),
  optionCode: z.string(),
  optionName: z.string(),
  valueCode: z.string(),
  valueName: z.string(),
})
const optionDetailSchema = z.object({
  isRequiredOption: z.boolean(),
  optionDetailCode: z.string().min(1, '필수 입력사항입니다.'),
  optionDetailPrice: z.number().optional(),
  options: z.array(optionDetailOptionSchema),
})

export const productSearchItemRNCOResolver = zodResolver(z.record(optionSchema))
export const productSearchItemRCOResolver = zodResolver(
  // 이부분이 record에서 내부 구조가 optionSchema일수있고 optionDetailSchema일수있다.
  z.object({
    options: z.record(optionSchema),
    optionDetails: z.record(optionDetailSchema),
  })
)
