import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { Checkbox } from '~/shared/components/ui/checkbox'
import type { TItem, TOptionDetails } from '../../product-search.type'
import { ProductSearchItemB } from '../../../product-search-item/product-search-item-b'
import { ProductSearchItemBase } from '../../../product-search-item/product-search-item-base'
import { RequiredOptionContainer } from './required-option.container'

export const RequiredOption = ({
  searchId,
  optionDetails,
  productData,
}: {
  searchId: string
  optionDetails: TOptionDetails[]
  productData: TItem
}) => (
  <div className="space-y-[12px]" data-type="required-option">
    {pipe(
      optionDetails,
      A.map((e) => (
        <div
          key={e.optionDetailCode}
          data-option-detail-code={e.optionDetailCode}
        >
          {pipe(
            e.options,
            A.reduce(
              '',
              (acc, curr) => `${acc} ${curr.optionName} ${curr.valueName},`
            ),
            // 마지막 ,제거
            (r) => r.slice(0, -1),
            (name) => (
              <div key={e.optionDetailCode}>
                <RequiredOptionContainer
                  searchId={searchId}
                  prodCode={productData.prodCode}
                  optionCode={pipe(e.optionDetailCode, String)}
                >
                  {({ checked, setChecked }) => (
                    <ProductSearchItemB
                      id={pipe(e.optionDetailCode, String)}
                      name={name}
                      price={pipe(e.optionDetailPrice, String)}
                      data-type="required-option"
                      labelId={e.optionDetailCode}
                    >
                      <ProductSearchItemBase.Slot name="LeftArea">
                        <Checkbox
                          className="rounded-[4px]"
                          checked={checked}
                          onChangeChecked={(t) =>
                            typeof t === 'boolean' && setChecked(t)
                          }
                          id={e.optionDetailCode}
                        />
                      </ProductSearchItemBase.Slot>
                    </ProductSearchItemB>
                  )}
                </RequiredOptionContainer>
              </div>
            )
          )}
        </div>
      ))
    )}
  </div>
)

export const RequiredOptionMemo = React.memo(RequiredOption)
