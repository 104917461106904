import { z } from 'zod'

export const 상수_수거타입 = {
  자동수거신청: 'RRT01', // 자동 수거 신청
  구매자발송: 'RRT02', // 구매자 발송
  기타: 'RRT03', // 기타
  수동수거신청: 'RRT04', // 수동 수거 신청 (판매자 직접 송장번호 입력)
  수거정보입력_대기: 'RRT05',
} as const

const type = z.nativeEnum(상수_수거타입)
export type T상수_수거타입 = z.infer<typeof type>
