import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { useRegisterInvoiceExcelAtom } from '.'

export function useRegisterInvoiceExcelClose() {
  const tabCode = useTabCode()
  const reset = useRegisterInvoiceExcelAtom.reset({ tabCode })
  const pollingReset = useRegisterInvoiceExcelAtom.polling.reset({ tabCode })

  return function () {
    reset()
    pollingReset()
  }
}

export function useRegisterInvoiceExcelOpen() {
  const tabCode = useTabCode()
  const setState = useRegisterInvoiceExcelAtom.set({ tabCode })

  return function () {
    setState((prev) => ({ ...prev, value: true }))
  }
}
