import React from 'react'
import { __ } from '~/shared/i18n'
import { StepLayout } from '../partials/step-layout'
import { Button, ButtonGroup, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useActiveStepIdxAtom, useOrderInfoAtom } from '../order-create.store'
import { match, P } from 'ts-pattern'
import { NonMemberView } from './non-member-view'
import { StepSkeleton } from '~/container/order-create/partials/step-skeleton'

const MemberView = React.lazy(() =>
  import('./member-view').then((mod) => ({
    default: mod.MemberView,
  }))
)

const STEP_IDX = 3 as const

export function StepDiscountSetup() {
  const [activeStepIdx] = useActiveStepIdxAtom()

  return match(activeStepIdx)
    .with(P.number.lt(STEP_IDX), () => <IncompleteStatusView />)
    .otherwise(() => <CurrentStatusView />)
}

function CurrentStatusView() {
  const [orderInfo] = useOrderInfoAtom()
  const [_, setActiveStepIdx] = useActiveStepIdxAtom()

  return (
    <StepLayout stepIdx={STEP_IDX} status="current">
      <React.Suspense fallback={<StepSkeleton />}>
        <Clay
          paddingTop={vars.spacing[6]}
          borderRadius={vars.rounded.large}
          background={vars.semantic.color.surface}
        >
          {orderInfo._isMember ? <MemberView /> : <NonMemberView />}
        </Clay>

        <ButtonGroup
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="secondary"
            text={__('이전')}
            onClick={() => setActiveStepIdx((prev) => prev - 1)}
          />
        </ButtonGroup>
      </React.Suspense>
    </StepLayout>
  )
}

function IncompleteStatusView() {
  return <StepLayout stepIdx={STEP_IDX} status="incomplete" />
}
