import * as math from 'mathjs'
import { pipe, flow } from 'fp-ts/function'
import { Controller } from 'react-hook-form'
import { useOrderSectionCancelComplete } from '..'
import { __ } from '~/shared/i18n'
import { Trans } from 'react-i18next'
import _ from 'lodash'
import {
  모델_취소금액계산,
  스펙_취소금액계산,
} from '~/entities/order-section/cancel-settlements/model'
import { Input } from '~/shared/components/input'
import React from 'react'
import { ContentsBanner } from '@imwebme/clay-components'
import { match } from 'ts-pattern'
import { cn } from '~/shared/utils'
import { 상수_환불금액_타입 } from '~/entities/@x'

export function RefundPoint({ $cancel }: { $cancel: 모델_취소금액계산 }) {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
    getValues,
    watch,
    setValue,
  } = useOrderSectionCancelComplete()

  React.useEffect(() => {
    const _error = errors['refundPoint']
    const _refundPoint = Number($cancel.form.refundPoint)
    if (_refundPoint > $cancel.최대_환불가능_적립금 && _error === undefined) {
      setError('refundPoint', {
        message: __('최대 환불 가능 적립금을 초과하였습니다.'),
      })
    } else if (_refundPoint <= $cancel.최대_환불가능_적립금 && _error) {
      clearErrors('refundPoint')
    }
  }, [$cancel])

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'refundPoint' && type === 'change') {
        if ($cancel.최소_환불_적립금 > Number(value.refundPoint)) {
          setError('refundPoint', {
            message: __('최소 환불 가능 적립금보다 작습니다.'),
          })
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  React.useEffect(() => {
    if ($cancel.form.deliveryExtraPriceType === 'SUB') {
      setValue('refundPoint', $cancel.cancelSettlements.recreditedPoint || 0)
    } else {
      setValue(
        'refundPoint',
        $cancel.cancelSettlements.totalItemPointAmount || 0
      )
    }
  }, [$cancel.form.deliveryExtraPriceType])

  const disabled = React.useMemo(
    () => 스펙_취소금액계산.적립금환불가능여부.not().isSatisfiedBy($cancel),
    [$cancel.form.refundPriceTypeCd]
  )
  /**
   * 최대 환불가능 적립금
   * - 반품하려는 상품의 가격을 넘을 수 없다. (total)
   */
  return (
    <div className="space-y-[8px]">
      {match<boolean>(true)
        .with($cancel.form.refundPriceTypeCd === 'ORT05', () => (
          <div>
            <ContentsBanner
              type="secondary"
              variant="warning"
              text={__('금액 설정을 직접하는 경우 적립금 설정이 제한되어요')}
            />
          </div>
        ))
        .with($cancel.cancelSettlements.recreditedPoint === 0, () => (
          <div>
            <ContentsBanner
              type="secondary"
              variant="warning"
              text={__('환불가능한 포인트가 없습니다')}
            />
          </div>
        ))
        .otherwise(() => null)}
      <div
        className={cn(
          'typo-clay-label-medium',
          disabled && 'text-clay-semantic-textDisabled'
        )}
      >
        {__('환불할 적립금')}
      </div>
      <div className="mt-[6px]">
        <Controller
          control={control}
          name="refundPoint"
          render={({
            field: { value, onChange, ...field },
            fieldState: { error },
          }) => (
            <div className="relative w-[180px]">
              <Trans>
                <Input
                  type="number"
                  value={pipe(
                    math.min(
                      value || 0,
                      $cancel.form.refundPriceTypeCd ===
                        상수_환불금액_타입.직접입력
                        ? $cancel.form.requestRefundPrice || 0
                        : 999999999
                    ),
                    String
                  )}
                  onChange={flow((e) => e.target.value, Number, onChange)}
                  {...field}
                  autoComplete="off"
                  disabled={disabled}
                  className={cn(
                    'pr-[calc(12px*2+12px)]',
                    error && 'bg-clay-semantic-surfaceCritical border-none'
                  )}
                />
                <span className="text-clay-semantic-textSub typo-clay-body-medium absolute right-[12px] top-1/2 -translate-y-1/2">
                  원
                </span>
              </Trans>
            </div>
          )}
        />
      </div>
      <div
        className={cn(
          'typo-clay-body-small ',
          disabled
            ? 'text-clay-semantic-textDisabled'
            : 'text-clay-semantic-textSub'
        )}
      >
        {__('최대 {{적립금, 3comma}} 환불 가능', {
          적립금: $cancel.최대_환불가능_적립금,
        })}
      </div>
    </div>
  )
}
