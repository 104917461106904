import React from 'react'
import { Textfield } from '@imwebme/clay-components'
import { SearchSM } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'
import { useCurOrderSearchFilter } from '~/container/order-search-filter/order-search-filter.store'

export function OrderSearch() {
  const [orderSearchQuery, setOrderSearchQuery] = useOrderSearchQuery()
  const [value, setValue] = React.useState(orderSearchQuery)
  const [filter] = useCurOrderSearchFilter()

  const handleSearchQuerySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const trimmedValue = value.trim()
    if (trimmedValue !== orderSearchQuery) {
      setOrderSearchQuery(trimmedValue || null)
    }
    setValue(trimmedValue)
  }

  const handleChange = (newValue: string) => {
    setValue(newValue.slice(0, 20))
  }

  // 외부에서 orderSearchQuery가 바뀌는 경우 로컬 state에도 반영
  React.useEffect(() => {
    setValue(orderSearchQuery)
  }, [orderSearchQuery])

  return (
    <form
      className="relative w-full desktop:w-fit"
      onSubmit={handleSearchQuerySubmit}
      aria-label="order-search-form"
    >
      <Textfield.Box
        variant="secondary"
        className={cn(
          'w-full',
          'desktop:w-[280px] desktop:focus-within:w-[300px] transition-[width] ease-linear duration-180'
        )}
      >
        <Textfield.Addon>
          <SearchSM />
        </Textfield.Addon>
        <Textfield.Input
          data-testid="order-search-input"
          name="keyword"
          type="search"
          value={value}
          onChangeText={handleChange}
          disabled={filter.open}
          onBlur={(e) => e.target.form?.requestSubmit()}
          autoComplete="off"
          placeholder={
            filter.open
              ? __('필터 내 검색')
              : __('이름, 연락처, 주문번호, 송장번호')
          }
        />
      </Textfield.Box>
    </form>
  )
}
