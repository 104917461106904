import {
  Typography,
  ButtonGroup,
  Flex,
  IconButton,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Minus, LayoutRight, XClose } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { useTimelineViewMode } from '~/container/order/order-timeline/order-timeline.store'
import { useTimelineModalOpen } from '~/container/order/order-timeline/order-timeline.hook'
import { TimelineFilterController } from './components'
import { CustomTooltip } from '~/container/order/order-timeline/components'

export function TimelineHeaderSmallViewExpanded() {
  const [_modalOpen, setModalOpen] = useTimelineModalOpen()
  const [_viewMode, setViewMode] = useTimelineViewMode()

  return (
    <>
      <Flex
        columnGap={vars.spacing[1]}
        padding={`${vars.spacing[2]} ${vars.spacing[4]} ${vars.spacing[2]} ${vars.spacing[6]}`}
        justifyContent="space-between"
        alignItems="center"
        border={`1px solid ${vars.semantic.color.divideMinimal}`}
      >
        <Typography
          variant="heading-medium-bold"
          sx={{
            padding: `${vars.spacing[1]} 0`,
            flex: 1,
          }}
        >
          {__('타임라인')}
        </Typography>
        <ButtonGroup size="small" isInline sx={{ alignSelf: 'flex-start' }}>
          <CustomTooltip
            label={__('접기')}
            trigger={
              <IconButton
                icon={<Minus />}
                variant="secondary"
                size="small"
                onClick={() => setViewMode('작게보기_접힘')}
              />
            }
            side="bottom"
            asChild
          />
          <CustomTooltip
            label={__('우측 패널로 보기')}
            trigger={
              <IconButton
                icon={<LayoutRight fill="none" />} // 클레이 버그, fill 속성을 줘야 정상적으로 렌더됨
                variant="secondary"
                size="small"
                onClick={() => setViewMode('우측패널')}
              />
            }
            side="bottom"
            asChild
          />
          <CustomTooltip
            label={__('닫기')}
            trigger={
              <IconButton
                icon={<XClose />}
                variant="secondary"
                size="small"
                onClick={() => setModalOpen(false)}
              />
            }
            side="bottom"
            asChild
          />
        </ButtonGroup>
      </Flex>

      <Flex
        paddingTop={vars.spacing[3]}
        paddingInline={vars.spacing[6]}
        marginBottom={vars.spacing[5]}
        justifyContent="space-between"
      >
        <TimelineFilterController />
      </Flex>
    </>
  )
}
