import { TypographyProps } from '@imwebme/clay-components/dist/Typography/Typography.types'
import { useOrderInfoAtom } from '../order-create.store'
import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Trans } from 'react-i18next'

export function PriceItem({
  label,
  price,
  color,
  minus,
}: {
  label: string
  price?: number
  color?: TypographyProps<'p'>['colorToken']
  minus?: boolean
}) {
  const [orderInfo] = useOrderInfoAtom()
  const currency = orderInfo.unitSite?.currency || 'KRW'
  if (price === undefined) {
    return null
  }
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      columnGap={vars.spacing[1]}
    >
      <Typography variant="label-medium" colorToken={color}>
        {label}
      </Typography>
      <Typography
        variant="body-medium"
        colorToken={color}
        sx={{ display: 'flex', columnGap: vars.spacing[1] }}
      >
        <Trans
          i18nKey={
            minus && price > 0
              ? '<t>-</t><t>{{price, 3comma}}</t><t>{{currency, currency}}</t>'
              : '<t>{{price, 3comma}}</t><t>{{currency, currency}}</t>'
          }
          values={{
            price: price,
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{
            t: (
              <Typography variant="body-medium" as="span" colorToken={color} />
            ),
          }}
        />
      </Typography>
    </Flex>
  )
}
