import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import type { TExportExcelTemplateSelect } from './export-excel-template-select.type'
import { TExportTemplates } from '../export-template/export-template.type'

const ExportExcelTemplateSelectState = atom<
  TExportExcelTemplateSelect['list'] | null
>(null)

const ExportExcelTemplateSelectDataState = atom<TExportTemplates | null>(null)
ExportExcelTemplateSelectDataState.debugLabel =
  'ExportExcelTemplateSelectDataState'

export const useExportExcelTemplateSelectAtom = {
  state: () => useAtom(ExportExcelTemplateSelectState),
  get: () => useAtomValue(ExportExcelTemplateSelectState),
  set: () => useSetAtom(ExportExcelTemplateSelectState),
  data: () => useAtom(ExportExcelTemplateSelectDataState),
  getData: () => useAtomValue(ExportExcelTemplateSelectDataState),
  setData: () => useSetAtom(ExportExcelTemplateSelectDataState),
}
