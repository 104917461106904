import { z } from 'zod'
export const 상수_현금영수증_상태 = {
  미발행: 'CRS01', // 미발행
  발행완료: 'CRS02', // 발행완료
  발행취소: 'CRS03', // 발행취소
  발행실패: 'CRS04', // 발행실패
  발행전_취소: 'CRS05', // 발행전 취소
} as const

const type = z.nativeEnum(상수_현금영수증_상태)
export type T상수_현금영수증_상태 = z.infer<typeof type>
