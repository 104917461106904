import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResOrderCloseDto,
  type TPostPayloadOrderCloseDto,
  postResOrderCloseDto,
  postPayloadOrderCloseDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description order-close
 */
export function postOrderClose({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadOrderCloseDto) {
    type R = typeof body
    type T = TPostResOrderCloseDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/close`, body)
      .then((res) => res.data)

    return result
  }
}
