import { atom, useAtom } from 'jotai'

interface TDialogDeliverRelease {
  isOpen: boolean
  fulfilled?: number
  rejected?: number
  onOpenChange?: (open: boolean) => void
  onSubmit?: () => void
}
const DialogDeliverReleaseState = atom<TDialogDeliverRelease>({
  isOpen: false,
})
DialogDeliverReleaseState.debugLabel = 'DialogDeliverReleaseState'

export const useDialogDeliverRelease = {
  dialog: () => useAtom(DialogDeliverReleaseState),
}
