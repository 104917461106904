import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import {
  // eslint-disable-next-line no-restricted-imports
  Link as OriginLink,
  useNavigate as useOriginNavigate,
  useLocation,
  generatePath,
  LinkProps as _LinkProps,
} from 'react-router-dom'
import { match } from 'ts-pattern'
import { type Path, type Params } from '~/router'
import { LinkProps, To } from './link.type'

// 새탭열기 이벤트 감지
function isModifiedEvent(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

type LinkRef = React.ForwardedRef<HTMLAnchorElement>

const OverrideLink = React.forwardRef(
  <P extends Path | To<Path>>(
    { to, params, searchParams, ...props }: LinkProps<P, Params>,
    ref: LinkRef
  ) => {
    if (import.meta.env.STORYBOOK === 'true') {
      return <OriginLink to={to} {...props} ref={ref} />
    }

    /**
     * @description
     * react-router-dom의 search 속성은 해당 페이지의 특정 search로 이동하는것이다.
     * 그래서 searchParams를 추가했다.
     */
    const isSearchParams = (_path: string) => {
      if (searchParams) {
        // searchparams가 record일떄
        if (typeof searchParams === 'object') {
          const search = new URLSearchParams()
          for (const [key, value] of Object.entries(searchParams)) {
            search.append(key, value)
          }
          _path += `?${search.toString()}`
        } else {
          _path += searchParams
        }
      }
      return _path
    }

    const navigate = useOriginNavigate()
    const path = pipe(
      generatePath(
        typeof to === 'string' ? to : to.pathname,
        params || ({} as any)
      ),
      isSearchParams
    )

    const newTabUrl = `${
      ImwebOAuth2ClientAPI.getInfo().siteUrl
    }/admin/shopping/order-v1?subPath=${pipe(path, btoa)}`

    return (
      <OriginLink
        ref={ref}
        {...props}
        to={
          typeof to === 'string'
            ? path
            : { pathname: path, search: to.search, hash: to.hash }
        }
        onClick={(e) => {
          props.onClick && props.onClick(e)
          e.preventDefault()
          if (isModifiedEvent(e)) {
            window.open(newTabUrl, '_blank')
            return
          }
          navigate(path)
        }}
        onAuxClick={(e) => {
          e.preventDefault()
          window.open(newTabUrl, '_blank')
        }}
      />
    )
  }
)

/**
 * Link에 locale을 붙여주는 컴포넌트
 */
export interface TLocalLink extends _LinkProps {
  internal?: boolean
}
export const BlankLink = React.forwardRef<
  React.ElementRef<typeof OriginLink>,
  TLocalLink
>(({ to, internal = false, ...props }, ref) => {
  if (import.meta.env.STORYBOOK === 'true') {
    return <OriginLink to={to} {...props} ref={ref} />
  }
  const internalUrl = `${ImwebOAuth2ClientAPI.getInfo().siteUrl}${to}`

  const _to = match<boolean>(true)
    .with(internal, () => internalUrl)
    .otherwise(() => to)
  return (
    <OriginLink
      to={_to}
      {...props}
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        window.parent.postMessage(
          {
            source: 'OMSA',
            message: {
              goto: _to,
              target: '_blank',
            },
          },
          '*'
        )
      }}
      onAuxClick={(e) => {
        e.preventDefault()
        window.parent.postMessage(
          {
            source: 'OMSA',
            message: {
              goto: _to,
              target: '_blank',
            },
          },
          '*'
        )
      }}
    />
  )
})

// path 타입을 받지 않는 Link
/**
 * Link에 locale을 붙여주는 컴포넌트
 */
export interface TLocalLink extends _LinkProps {
  internal?: boolean
}
export const LocalLink = React.forwardRef<
  React.ElementRef<typeof OriginLink>,
  TLocalLink
>(({ to, internal = false, ...props }, ref) => {
  if (import.meta.env.STORYBOOK === 'true') {
    return <OriginLink to={to} {...props} ref={ref} />
  }
  const navigate = useOriginNavigate()
  const url = `${to}`
  const newTabUrl = `${
    ImwebOAuth2ClientAPI.getInfo().siteUrl
  }/admin/shopping/order-v1?subPath=${btoa(url)}`

  return (
    <OriginLink
      to={to}
      {...props}
      ref={ref}
      onClick={(e) => {
        props.onClick && props.onClick(e)
        e.preventDefault()
        if (isModifiedEvent(e)) {
          window.open(newTabUrl, '_blank')
          return
        }
        navigate(url)
      }}
      onAuxClick={(e) => {
        e.preventDefault()
        window.open(newTabUrl, '_blank')
      }}
    />
  )
})

/**
 * LocaleLink - react-router-dom Link에 locale을 붙여주는 컴포넌트
 * LocaleLink의 alias
 */
export const Link = OverrideLink

/**
 * useLocaleNav의 alias
 */
export const useLN = useOriginNavigate

export const useLocaleLocation = () => {
  const location = useLocation()
  return location
}

/**
 * useLocation 기능의 locale만 제거 useLocaleLocation을 리턴한다.
 * useLocaleLocation의 alias
 */
export const useLL = useLocaleLocation
