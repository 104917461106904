import { z } from 'zod'
import { 상수_외부채널주문_클레임_보류상태 } from '../@x/외부채널주문_클레임_보류상태'
import { 상수_외부채널주문_클레임_보류사유 } from '../@x/외부채널주문_클레임_보류사유'

export const ClaimInfoDto = z.object({
  channelOrderItemNo: z.string().describe('채널 품목 번호'),
  claimType: z.string().describe('클레임 타입'),
  claimStatus: z.string().describe('클레임 상태'),
  requester: z.string().describe('요청자'),
  claimDeliveryFeePayMethod: z.string().describe('클레임 비용 결제 방식'),
  claimDeliveryFeeProductOrderIds: z.array(
    z.string().describe('클레임 비용 상품 주문 번호')
  ),
  claimDeliveryFeeDemandAmount: z.number().describe('클레임 비용 요청 금액'),
  remoteAreaCostChargeAmt: z.number().describe('도서산간 수거비용'),
  holdbackStatus: z.nativeEnum(상수_외부채널주문_클레임_보류상태),
  holdbackReason: z.nativeEnum(상수_외부채널주문_클레임_보류사유),
  holdbackDetailedReason: z.string().describe('보류 상세 사유'),
  claimId: z.string().nullish().describe('채널 품목 클레임 ID'),
})

export const getResExternalOrderClaimInfoDto = z.object({
  list: z.array(ClaimInfoDto),
})
export const getParameterExternalOrderClaimInfoDto = z.object({
  channelProductNoList: z.array(z.string()),
})
