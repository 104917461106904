import {
  UseMutationResult,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

/**
 * react-query의 useMutation의 랩퍼 훅. 동일한 mutationKey를 가진 mutation이 이미 진행 중이면 mutate를 호출하지 않음.
 */
export const useSyncMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & {
    mutationKey: NonNullable<UseMutationOptions['mutationKey']>
  }
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const queryClient = useQueryClient()
  const mutation = useMutation<TData, TError, TVariables, TContext>(options)

  return {
    ...mutation,
    mutate: (...params) => {
      const isMutating = !!queryClient.isMutating({
        mutationKey: options.mutationKey,
        exact: true,
      })

      isMutating
        ? log.debug('Mutation is already in progress')
        : mutation.mutate(...params)
    },
    mutateAsync: (...params) => {
      const isMutating = !!queryClient.isMutating({
        mutationKey: options.mutationKey,
        exact: true,
      })

      return isMutating
        ? Promise.reject(new Error('Mutation is already in progress'))
        : mutation.mutateAsync(...params)
    },
  }
}
