import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { __ } from '~/shared/i18n'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useFormContextExternalOrderReturnSelect } from '../external-order-return-select'
import { ExternalReturnItemMutable } from './external-return-item-mutable'

export function ExternalReturnItemMutableList({
  index,
  sectionCode,
  children,
}: React.PropsWithChildren<{ index: number; sectionCode: string }>) {
  const orderCode = useOrderCode()
  const { data: sectionItems } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(
        // sectionCode를 받았기 때문에 sectionList는 항상있다.
        e.data.orderSectionList!,
        A.findFirst((e2) => e2.orderSectionCode === sectionCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트가 없습니다.')
        }),
        (e2) => e2.orderSectionItemList,
        O.fromNullable,
        O.getOrElseW(() => {
          throw new Error('섹션 리스트에 아이템리스트가 없습니다.')
        })
      )
      return result
    },
  })

  return (
    <div>
      {pipe(
        sectionItems,
        A.mapWithIndex((_index, e) => (
          <React.Fragment key={e.orderSectionItemCode}>
            <div className="odd:bg-clay-semantic-layerSub even:bg-white">
              <ExternalReturnItemMutable
                index={_index}
                sectionCode={sectionCode}
                sectionItemCode={e.orderSectionItemCode}
                sectionIndex={index}
              />
            </div>
          </React.Fragment>
        ))
      )}
      {children}
    </div>
  )
}
