import { ExternalOrderCancelInfo } from '~/container/external-order/external-order-cancel-info'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { useParams } from '~/router'
import OrderPage from '~/container/order/order-cancel-info/order-cancel-info'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

function ExternalPage() {
  return (
    <>
      <PageTopBar />
      <ExternalOrderCancelInfo />
    </>
  )
}

export default function Page() {
  const { saleChannel } = useParams('/order/:saleChannel/:orderCode')
  if (saleChannel !== '1') {
    return <ExternalPage />
  }
  return <OrderPage />
}
