import log from 'loglevel'
import { traceWithValue } from 'fp-ts-std/Debug'
import { ImwebOAuth2ClientAPI } from '@imwebme/imweb-oauth2-client-sdk'

// ======================================
// VITE_LOG_LEVEL 설정에 따라 로그 레벨을 설정합니다.
// 프로덕션에서는 에러로그만 출력합니다.
// ======================================
const setLogLevel = () => {
  const level = import.meta.env.VITE_LOG_LEVEL
  log.setLevel(level)
}

setLogLevel()

window.log = log
window.pipeLog = traceWithValue

window.ImwebOAuth2ClientAPI = ImwebOAuth2ClientAPI
