import { __, __g, price } from '~/shared/i18n'
import { Button } from '@imwebme/clay-components'
import { 모델_취소금액계산 } from '~/entities/order-section/cancel-settlements/model'
import { P, match } from 'ts-pattern'
import { Tooltip } from '~/shared/components/ui/tooltip'
import { IconCornerDownRight, IconHelpCircle } from '@imwebme/icon'
import { useCancelCompleteConfirmAtom } from '../modal/cancel-complete-confirm/cancel-complete-confirm.store'
import { useOrderSectionCancelComplete } from '..'
import { useCurrency } from '~/shared/hooks/use-currency'
import { Trans } from 'react-i18next'

interface TCancelPriceTable {
  $cancel: 모델_취소금액계산
  orderCode: string
  sectionCode: string
}
export function CancelPriceTable({
  $cancel,
  orderCode,
  sectionCode,
}: TCancelPriceTable) {
  const currency = useCurrency()
  const setOpenCancelCompleteConfirm = useCancelCompleteConfirmAtom.set({
    orderCode,
    sectionCode,
  })
  const {
    formState: { errors },
  } = useOrderSectionCancelComplete()
  return (
    <>
      <div className="[--mx:24px] py-[24px] bg-white rounded-[12px] self-start">
        <div className="mx-[var(--mx)]">
          <span className="typo-clay-heading-xlarge-bold">
            {__('환불금액')}
          </span>
        </div>
        <div className="mx-[var(--mx)] mt-[24px] space-y-[20px]">
          <div>
            <div className="flex justify-between items-center">
              <div className="flex gap-[4px]">
                {__('합계')}
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button type="button">
                        <IconHelpCircle className="stroke-[2]" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content>
                        {__(
                          '환불요청금액은 결제잔액을 초과하여 진행할수 없습니다.'
                        )}
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
              <div>
                <Trans
                  i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
                  values={{
                    price: $cancel.환불금액_총_금액,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  }}
                  components={{
                    b: <b className="typo-clay-heading-xlarge-bold" />,
                  }}
                />
              </div>
            </div>
            <div className="typo-clay-body-medium mt-[12px] space-y-[4px]">
              <div className="flex justify-between gap-x-[8px] items-center">
                <div className="flex items-start gap-x-[4px] typo-clay-label-medium">
                  <IconCornerDownRight className="mt-[2px] stroke-[2] text-clay-semantic-iconDisabled" />
                  <div className="text-clay-semantic-textSub">{__('금액')}</div>
                </div>
                <div>
                  {__('{{price, 3comma}} {{currency, currency}}', {
                    price: $cancel.환불금액_금액,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </div>
              </div>
              <div className="flex justify-between gap-x-[8px] items-center">
                <div className="flex items-start gap-x-[4px] typo-clay-label-medium">
                  <IconCornerDownRight className="mt-[2px] stroke-[2] text-clay-semantic-iconDisabled" />
                  <div className="text-clay-semantic-textSub">
                    {__('적립금')}
                  </div>
                </div>
                <div>
                  {__('{{price, 3comma}} {{currency, currency}}', {
                    price: $cancel.환불금액_적립금,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="p-[16px] bg-clay-semantic-surfaceSub rounded-[8px] typo-clay-label-medium">
            <div className="grid grid-cols-[1fr,auto] gap-y-[12px]">
              <div>
                {match($cancel.form)
                  .with(
                    {
                      refundPriceTypeCd: P.union(
                        'ORT01',
                        'ORT02',
                        'ORT03',
                        'ORT04'
                      ),
                    },
                    (e) => __g(e.refundPriceTypeCd)
                  )
                  .with(
                    {
                      refundPriceTypeCd: 'ORT05',
                    },
                    () => __g('ORT01')
                  )
                  .otherwise(() => null)}
              </div>
              <div className="text-right">
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: $cancel.환불금액_설정조건금액,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              <div>{__('배송비')}</div>
              <div className="text-right">
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: $cancel.환불금액_배송비,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              {$cancel.form.refundPriceTypeCd === 'ORT05' && (
                <>
                  <div>{__('기타 비용')}</div>
                  <div className="text-right">
                    {__('{{price, 3comma}} {{currency, currency}}', {
                      price: $cancel.환불금액_기타비용,
                      formatParams: {
                        currency: {
                          currency,
                        },
                      },
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[20px]">
        <Button
          native={{ type: 'button' }}
          fullWidth
          variant="primary"
          isDisabled={Object.keys(errors).length > 0}
          onClick={() => {
            if (Object.keys(errors).length === 0) {
              return setOpenCancelCompleteConfirm((prev) => ({
                ...prev,
                value: {
                  ...prev.value,
                  open: true,
                },
              }))
            }
          }}
          text={__('환불 처리')}
        />
      </div>
    </>
  )
}
