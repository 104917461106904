import { z } from 'zod'
export const 상수_환불금액_타입 = {
  실결제가: 'ORT01',
  상품판매가: 'ORT02',
  평균실결제가: 'ORT03',
  환불타입_미지정: 'ORT04',
  직접입력: 'ORT05',
} as const

const type = z.nativeEnum(상수_환불금액_타입)
export type T상수_환불금액_타입 = z.infer<typeof type>
