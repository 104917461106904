/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * patch cash-receipt
 */
export const patchResCashReceiptDto = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  typeCd: z.string(),
  value: z.string(),
})
export const patchPayloadCashReceiptDto = z.object({
  typeCd: z.string(),
  value: z.string(),
})
