import React from 'react'
import { atom, useAtom } from 'jotai'
import { pipe, flow } from 'fp-ts/function'
import * as N from 'fp-ts/number'
import * as Ord from 'fp-ts/lib/Ord'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { TSheetItem } from './export-template-selection.type'
import { atomFamily } from 'jotai/utils'
import { orderExportFormsState } from '../export-templates/export-templates.store'
import { __c } from '~/shared/i18n'

// template atom에서 가져오도록 변경
export const orderExportFormSheetOriginalState = atom(
  (get) => {
    const forms = get(orderExportFormsState)
    if (!forms) {
      return null
    }
    return pipe(
      forms,
      A.map((form) => ({
        id: form.idx,
        values: pipe(
          form.templateInfo.selection,
          A.map(
            flow(
              // 스키마에 없는 타입 추가
              (e) => ({ checked: false, ...e }),
              (selection) =>
                ({
                  id: selection.column,
                  name: __c(selection.column),
                  order: selection.sort,
                  checked: selection.checked,
                }) as {
                  id: typeof selection.column
                  name: string
                  order: typeof selection.sort
                  checked?: boolean
                }
            )
          )
        ),
      }))
    )
  },
  (
    get,
    set,
    update:
      | {
          id: string
          values: TSheetItem[]
        }[]
      | null
  ) => {
    const a = get(orderExportFormsState)
    if (update === null) {
      set(orderExportFormsState, null)
      return
    }
    pipe(
      a,
      O.fromNullable,
      O.fold(
        () => null,
        flow(
          A.map((item) => {
            const form = update.find((e) => e.id === item.idx)
            if (!form) {
              return item
            }
            return {
              ...item,
              templateInfo: {
                ...item.templateInfo,
                selection: pipe(
                  form.values,
                  A.map((value) => ({
                    column: value.id,
                    sort: value.order,
                    checked: value.checked,
                  }))
                ),
              },
            }
          })
        )
      ),
      (e) => set(orderExportFormsState, e)
    )
  }
)
orderExportFormSheetOriginalState.debugLabel =
  'orderExportFormSheetOriginalState'

const byOrder = pipe(
  N.Ord,
  Ord.contramap((p: TSheetItem) => p.order)
)
export const orderExportFormSheetState = atomFamily(
  ({ id }: { id: string }) => {
    const at = atom(
      (get) => {
        const sheet = get(orderExportFormSheetOriginalState)
        if (!sheet) {
          return null
        }
        return pipe(
          sheet,
          O.fromNullable,
          O.fold(
            () => null,
            flow(
              A.findFirst((item) => item.id === id),
              O.fold(
                () => null,
                (item) => item.values
              )
            )
          )
        )
      },
      (
        get,
        set,
        update:
          | TSheetItem[]
          | {
              action: 'delete' | 'update' | 'reset'
              values: TSheetItem[]
            }
          | null
      ) => {
        const sheet = get(orderExportFormSheetOriginalState)

        // update id, values
        if (!sheet || update === null) {
          return
        }

        const otherSheet = sheet.filter((item) => item.id !== id)
        // 객체로 액션을 받을때
        if (!Array.isArray(update)) {
          if (update?.action === 'delete') {
            pipe(
              otherSheet.concat({
                id,
                values: pipe(
                  update,
                  (e) => e?.values,
                  O.fromNullable,
                  O.fold(() => [], flow(A.sortBy([byOrder])))
                ),
              }),
              (e) => set(orderExportFormSheetOriginalState, e)
            )
            return
          }
          if (update?.action === 'reset') {
            set(orderExportFormSheetOriginalState, null)
            return
          }
          return
        }
        const targetSheet = pipe(
          sheet,
          A.findFirst((item) => item.id === id),
          O.fold(
            () => undefined,
            flow((items) => {
              const values = pipe(
                items.values,
                A.map((e) => {
                  const value = update?.find((v) => v.id === e.id)
                  if (!value) {
                    return e
                  }
                  return {
                    ...e,
                    order: value.order,
                    checked: value.checked,
                  }
                })
              )
              const filterUpdate = pipe(
                update,
                O.fromNullable,
                O.fold(
                  () => undefined,
                  flow(
                    A.filter((e) => {
                      const value = values.find((v) => v.id === e.id)
                      if (!value) {
                        return true
                      }
                      return false
                    })
                  )
                )
              )
              return {
                ...items,
                values: [...values, ...(filterUpdate ? filterUpdate : [])],
              }
            })
          )
        )
        pipe(
          otherSheet.concat({
            id,
            values: pipe(
              targetSheet?.values,
              O.fromNullable,
              O.fold(() => [], flow(A.sortBy([byOrder])))
            ),
          }),
          (e) => set(orderExportFormSheetOriginalState, e)
        )
      }
    )
    at.debugLabel = `orderExportFormSheet-${id}`
    return at
  },
  (a, b) => a.id === b.id
)

export const useExportTemplateSelectionAtom = {
  selections: () => useAtom(orderExportFormSheetOriginalState),
  state: (id: string) =>
    useAtom(React.useMemo(() => orderExportFormSheetState({ id }), [id])),
}
