import { z } from 'zod'
import { templateInfoDto } from '../_shared/template-info'

export const deleteResExcelTemplatesDto = z.any()

const defaultTemplateDto = z.object({
  name: z.enum(['기본 양식']),
  templateInfo: templateInfoDto,
})
const templateListDto = z.object({
  idx: z.number(),
  name: z.string(),
  templateInfo: templateInfoDto,
  wtime: z.string().datetime(),
  mtime: z.string().datetime(),
})
export const getResExcelTemplatesDto = z.object({
  defaultTemplate: defaultTemplateDto,
  list: z.array(templateListDto),
})

export const getParamsExcelTemplatesDto = z.any()
export const postResExcelTemplatesDto = z.any()
export const postPayloadExcelTemplatesDto = z.object({
  name: z.string().max(10),
  templateInfo: templateInfoDto,
})
