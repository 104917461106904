import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { Children } from 'react'

/**
 *  TODO: @hansanghyeon
 * @param children 컴포넌트 children
 * @param childName slot에서 name prop으로 지정한 이름
 * @param optional optional이 false이면 result는 undefined일 수 없다.
 * @returns
 */

export function findChild(
  children: React.ReactNode,
  childName: string,
  optional = false
) {
  const childrenArray = Children.toArray(children) as React.ReactElement[]
  const result = childrenArray.find((child) => child?.props?.name === childName)

  // 여기서 optional이 false이면 result는 undefined일 수 없다.
  // 리턴되는 타입은 완전히 바꿀수없다
  return pipe(
    result,
    O.fromNullable,
    O.fold(
      // undefined, null일때
      () => {
        // result가 undefined이지만 optional이 true일때는 undefined를 반환해도 무방하다
        if (optional) {
          return undefined
        }
        throw new Error(`${childName} is undefined, 슬롯에서 찾지 못하였습니다`)
      },
      (r) => r
    )
  )
}
