import { z } from 'zod'
import { patchPayloadReturnCompleteDto } from './return-complete.dto'
import { __ } from '~/shared/i18n'

/**
 * patch return-complete
 */
export const patchPayloadReturnCompleteRdo =
  patchPayloadReturnCompleteDto.extend({
    requestRefundPrice: z.number().nullish(),
    refundPoint: z.number().nullish(),
    returnedCoupons: z
      .array(
        z.object({
          _code: z.string(),
          _check: z.boolean().optional(),
        })
      )
      .nullish(),
    deliveryExtraPrice: z.object({
      _add: z
        .number()
        .min(-1, __('배송비 추가금액은 0원 이상으로 입력해주세요.'))
        .max(20_0000, __('배송비 추가금액은 20만원 이하로 입력해주세요.'))
        .nullish(),
      _sub: z
        .number()
        .min(-1, __('배송비 추가금액은 0원 이상으로 입력해주세요.'))
        .nullish(),
    }),
  })
