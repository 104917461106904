import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { IconX } from '@imwebme/icon'
import { __ } from '~/shared/i18n'
import { OrderSectionItem } from '~t/order-section/order-section-item'
import { useCurrency } from '~/shared/hooks/use-currency'
import { cn, fDate } from '~/shared/utils'
import { externalOrderQueryOptions } from '~/entities/external-order'
import {
  모델_외부채널_주문섹션_품목,
  모델_외부채널_주문섹션_품목_UI,
  스펙_외부채널_주문섹션_품목,
} from '~/entities/external-order/model/external-order-section-item'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useFormContextExternalItemImmutableList } from '../external-item-immutable-list'
import { useController } from 'react-hook-form'
import { ContentsBanner, Tag, Typography } from '@imwebme/clay-components'
import {
  DeliveryDelayReasonPack,
  useDeliveryDelayReasonAtom,
} from '../../modal/delivery-delay-reason'
import { SectionItemHover } from '~/widgets/section-item-hover'
import { ExternalChannelStatusTag } from '~/shared/domain-components'
import { useExternalOrderItemClaimInfo } from '~/entities/external-order-claim-info/model/external-order-item-claim-info'
import { 모델_외부채널_주문서 } from '~/entities/external-order/model/external-order'
import { ExternalItemTag } from '../extenral-item-tag'

interface TExternalItemImmutable {
  index: number
  sectionCode: string
  sectionItemCode: string
}
export function ExternalItemImmutable({
  index,
  sectionCode,
  sectionItemCode,
}: TExternalItemImmutable) {
  const orderCode = useOrderCode()
  const {
    data: { order, item, saleChannelItem },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const _item = pipe(
        e.data.orderSectionList!,
        A.findFirst((e2) => e2.orderSectionCode === sectionCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트가 없습니다.')
        }),
        (e2) => e2.orderSectionItemList!,
        A.findFirst((e2) => e2.orderSectionItemCode === sectionItemCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트에 해당 아이템이 없습니다.')
        })
      )
      const _saleChannelItem = pipe(
        e.data.saleChannelItemList,
        A.findFirst((e2) => e2.orderSectionItemCode === sectionItemCode),
        O.fold(
          () => undefined,
          (el) => el
        )
      )
      return {
        order: e.data,
        item: _item,
        saleChannelItem: _saleChannelItem,
      }
    },
  })

  const onDeliveryDelayReasonModal = useDeliveryDelayReasonAtom.set({
    orderCode,
    sectionCode,
    sectionItemCode,
  })

  const { control, register } = useFormContextExternalItemImmutableList()
  const { field: _check } = useController({
    name: `targetItemInformation.${index}._check`,
    control,
  })
  const $exOrder = new 모델_외부채널_주문서(order)
  const $prod = new 모델_외부채널_주문섹션_품목_UI(item, saleChannelItem)
  const $item = new 모델_외부채널_주문섹션_품목(item, saleChannelItem)
  const currency = useCurrency()

  const rowBgClass = match({ index: index % 2 === 0 })
    .with({ index: true }, () => 'bg-clay-semantic-layerSub')
    .with({ index: false }, () => 'bg-white')
    .otherwise(() => '')

  const $claimInfo = useExternalOrderItemClaimInfo({
    orderCode,
    saleChannelItem,
    saleChannelItemList: order.saleChannelItemList,
  })

  return (
    <>
      <SectionItemHover prodNo={item.prodNo}>
        <tr className={cn('w-full text-left [&>td]:py-clay-4', rowBgClass)}>
          <td className="w-[var(--mx)]" />
          <input
            type="hidden"
            {...register(`targetItemInformation.${index}.orderSectionItemCode`)}
          />
          <input
            type="hidden"
            {...register(`targetItemInformation.${index}.qty`)}
          />
          <input
            type="hidden"
            {...register(`targetItemInformation.${index}._check`)}
          />
          <td>
            <OrderSectionItem>
              <OrderSectionItem.Slot name="image">
                {item.imageUrl ? (
                  <img
                    src={item.imageUrl}
                    loading="lazy"
                    draggable="false"
                    alt=""
                  />
                ) : (
                  <div className="bg-clay-semantic-surfaceSlate" />
                )}
              </OrderSectionItem.Slot>
              <OrderSectionItem.Slot name="name">
                <div className="flex gap-x-[6px] gap-y-[4px] items-center flex-wrap">
                  {saleChannelItem ? (
                    <>
                      <ExternalChannelStatusTag
                        statusCd={saleChannelItem.channelStatus}
                        toggleDimmed={(index + 1) % 2 !== 0}
                      />
                      <ExternalItemTag $claimInfo={$claimInfo} />
                      <div className="text-clay-semantic-textSub">
                        {saleChannelItem?.channelOrderItemNo}
                      </div>
                    </>
                  ) : (
                    <Tag
                      variant="critical"
                      size="small"
                      text={__('연동실패')}
                    />
                  )}
                </div>
                <div className="mt-[6px]">{$prod.상품이름}</div>
              </OrderSectionItem.Slot>
              <OrderSectionItem.Slot name="option">
                <div className="flex gap-clay-1 flex-wrap">
                  {pipe(
                    $prod.item.optionInfo,
                    O.fromNullable,
                    O.fold(
                      () => null,
                      flow(
                        A.map((e) => (
                          <Tag
                            variant="other"
                            background
                            size="small"
                            text={`${e.key} ${e.value}`}
                            className="bg-[#15181E1A]"
                          />
                        ))
                      )
                    )
                  )}
                </div>
                {스펙_외부채널_주문섹션_품목.품목배송메모.model($item) && (
                  <div className="mt-clay-1 break-all typo-clay-body-medium text-clay-semantic-textSecondary">
                    {$prod.배송메모}
                  </div>
                )}
              </OrderSectionItem.Slot>
            </OrderSectionItem>
          </td>
          <td className="align-top">
            <div className="grid grid-cols-[auto,auto,auto] typo-clay-body-medium items-center gap-x-[4px]">
              <div className="justify-self-end whitespace-nowrap">
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: $prod.상품가격,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              <IconX className="stroke-[2] text-clay-semantic-icon" />
              {item.qty}
              <div
                className={cn(
                  'text-clay-semantic-textDisabled whitespace-nowrap',
                  스펙_외부채널_주문섹션_품목.할인유무.isSatisfiedBy($item) &&
                    'line-through'
                )}
              >
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: item.baseItemPrice,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              <div></div>
              <div></div>
            </div>
          </td>
          <td className="text-right typo-clay-body-medium align-top whitespace-nowrap">
            {__('{{price, 3comma}} {{currency, currency}}', {
              price: item.itemPrice * item.qty,
              formatParams: {
                currency: {
                  currency,
                },
              },
            })}
          </td>
          <td className="w-[var(--mx)]" />
        </tr>
      </SectionItemHover>
      {saleChannelItem &&
        saleChannelItem.apiProductInfo.deliveryDelayInfo !== null && (
          <tr className={cn(rowBgClass, '[&>td]:pb-clay-4')}>
            <td colSpan={1} />
            <td colSpan={3}>
              <ContentsBanner type="secondary" variant="warning">
                <Typography variant="body-medium">
                  {__(
                    `발송지연 품목이며 ${fDate(
                      new Date(
                        saleChannelItem.apiProductInfo.deliveryDelayInfo.dueDate
                      ),
                      'yyyy.MM.dd'
                    )} 까지 발송이 필요해요. `
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeliveryDelayReasonModal(true)
                    }}
                  >
                    {__('지연 사유 확인')}
                  </button>
                </Typography>
              </ContentsBanner>
              <DeliveryDelayReasonPack
                sectionCode={sectionCode}
                sectionItemCode={sectionItemCode}
              />
            </td>
            <td />
          </tr>
        )}
    </>
  )
}
