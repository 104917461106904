import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

export const TAB_CDS = {
  ALL: '전체',
  DELIVERY: '배송',
  CANCEL: '취소',
  RETURN: '반품',
} as const
export type TypeTabCds = (typeof TAB_CDS)[keyof typeof TAB_CDS]
type AtomKey = {
  id: string
}
type AtomType = {
  key: string
  value?: TypeTabCds
}
const OrderDetailViewTabState = atomFamily(
  ({ key, value = '전체' }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)

export const useOrderDetailViewTabAtom = {
  key: (p: AtomKey) => `${p.id}:order_section_view`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          OrderDetailViewTabState({
            key: useOrderDetailViewTabAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          OrderDetailViewTabState({ key: useOrderDetailViewTabAtom.key(p) }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          OrderDetailViewTabState({ key: useOrderDetailViewTabAtom.key(p) }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          OrderDetailViewTabState({ key: useOrderDetailViewTabAtom.key(p) }),
        [p]
      )
    ),
}
