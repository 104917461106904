import React from 'react'
import { TExternalDeliveryHoldBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalDeliveryHoldContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalDeliveryHold }))
)

interface TExternalDeliveryHoldPack extends TExternalDeliveryHoldBase {}
export function ExternalDeliveryHoldPack(props: TExternalDeliveryHoldPack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalDeliveryHoldContainer {...props} />
    </React.Suspense>
  )
}
