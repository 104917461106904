import { queryOptions } from '@tanstack/react-query'
import { getDeliveryCountry } from '.'

export function deliveryCountryQueryOptions(unitCode: string) {
  return queryOptions({
    queryKey: ['delivery-country', unitCode],
    queryFn: () => getDeliveryCountry(unitCode),
    staleTime: 1000 * 60 * 15,
  })
}
