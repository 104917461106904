/**
 * @README
 * 일반주문 전체취소 페이지
 */
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

/**
 * @description
 * ```
 * 전체취소
 * - 입금전에 취소하는 경우는 전체취소
 * ```
 */
export { OrderCancelAllPack as default } from '~/container/order/order-cancel-all'
