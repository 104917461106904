import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { __ } from '~/shared/i18n'
import { useDeliverySetupAtom } from '../../order-create.store'
import { Textfield } from '@imwebme/clay-components'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = z.object({
  unipassNumber: z
    .string()
    .min(1, __('필수로 입력해야 해요'))
    .max(50, __('50자 이내로 입력해주세요')),
})
export type TSchema = z.infer<typeof schema>

export function UnipassNumberField() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const {
    register,
    formState: { errors },
  } = useForm<TSchema>({
    resolver: zodResolver(schema),
    mode: 'onTouched',
  })

  if (!deliverySetup._useUnipassNumber) {
    return null
  }
  return (
    <FieldLayout title={__('개인통관고유부호')}>
      <Textfield.Set>
        <Textfield.Box>
          <Textfield.Input
            {...register('unipassNumber')}
            value={deliverySetup.unipassNumber ?? ''}
            onChangeText={(v) => setDeliverySetup({ unipassNumber: v })}
            isValid={errors.unipassNumber ? false : undefined}
            autoComplete="off"
            placeholder={__('P로 시작하는 13자리 번호')}
          />
        </Textfield.Box>
        <Textfield.HelperText>
          {errors.unipassNumber?.message}
        </Textfield.HelperText>
      </Textfield.Set>
    </FieldLayout>
  )
}
