import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResBackShippingReadyDto,
  type TPatchPayloadBackShippingReadyDto,
  patchResBackShippingReadyDto,
  patchPayloadBackShippingReadyDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/wdUaXX
 * @description [back] 주문 섹션 상태 배송대기로 되돌리기
 */
export function patchBackShippingReady({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadBackShippingReadyDto) {
    type T = TPatchResBackShippingReadyDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/back/shipping-ready`, body)
      .then((res) => res.data)

    patchResBackShippingReadyDto.parse(result.data)
    return result
  }
}
