import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { atom, useAtom, useAtomValue } from 'jotai'
import React from 'react'
import { atomFamily } from 'jotai/utils'
import { orderExportFormsState } from '../export-templates/export-templates.store'

export const orderExportFormState = atomFamily(
  ({ idx }: { idx: string }) => {
    const at = atom((get) => {
      const value = get(orderExportFormsState)
      if (value === null) {
        return null
      }
      const result = pipe(
        value,
        A.findFirst((e) => e.idx === idx),
        O.fold(
          () => null,
          (e) => e
        )
      )
      return result
    })
    at.debugLabel = `orderExportFormState-${idx}`
    return at
  },
  (a, b) => a.idx === b.idx
)

export const useExportTemplateAtom = {
  forms: () => useAtom(React.useMemo(() => orderExportFormsState, [])),
  form: ({ id }: { id: string }) =>
    useAtom(React.useMemo(() => orderExportFormState({ idx: id }), [])),
}
