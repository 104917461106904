import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TCancelCompleteConfirmBase, useCancelCompleteConfirmAtom } from '.'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { ErrorBoundary } from '@sentry/react'

const CancelCompleteConfirm = React.lazy(() =>
  import('./cancel-complete-confirm').then((module) => ({
    default: React.memo(module.CancelCompleteConfirm),
  }))
)

interface TCancelCompleteConfirmContainer extends TCancelCompleteConfirmBase {}
export function CancelCompleteConfirmContainer(
  props: TCancelCompleteConfirmContainer
) {
  const [state, setState] = useCancelCompleteConfirmAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        open: o,
      },
    }))
  }

  return (
    <AlertDialog.Root open={state.value.open} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <CancelCompleteConfirm {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
