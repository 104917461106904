/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

const externalClaimsDto = z.object({
  description: z.string(),
  responsibility: z.string(),
})
export const getResExternalClaimsDto = z.object({
  claims: z.record(z.string(), externalClaimsDto),
})

export const convertExternlClaimsDto = z.array(
  externalClaimsDto.extend({
    key: z.string(),
  })
)
