import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { __ } from '~/shared/i18n'
import { couponSortBySpecs, 스펙_쿠폰 } from '.'
import { TReturnSettlementsDto } from '~/entities/order-section/return-settlements'

const Coupon = React.lazy(() =>
  import('./').then((module) => ({
    default: React.memo(module.Coupon),
  }))
)

interface TCouponList {
  data: TReturnSettlementsDto
}
export function CouponList({ data }: TCouponList) {
  if (data.usedCouponInfo === null || data.usedCouponInfo === undefined) {
    return null
  }
  return (
    <div>
      <div className="typo-clay-label-medium">{__('반환할 쿠폰')}</div>
      <ul className="space-y-[4px] mt-[4px]">
        {pipe(
          data.usedCouponInfo,
          couponSortBySpecs([스펙_쿠폰.쿠폰반환가능여부]),
          A.mapWithIndex((index, e) => (
            <li key={e.couponIssueCode}>
              <Coupon data={e} index={index} />
            </li>
          ))
        )}
      </ul>
    </div>
  )
}
