import React, { useEffect } from 'react'
import { useDialogDeliverRelease } from './dialog-deliver-release.store'
import {
  type TDialogBulkDeliverReleaseProps,
  DialogDeliverRelease,
} from './dialog-deliver-release'

interface TDialogDeliverRelease
  extends Omit<
    TDialogBulkDeliverReleaseProps,
    'open' | 'onOpenChange' | 'onSubmit'
  > {
  initCallback?: () => void
}
const DialogDeliverReleaseContainer = ({
  initCallback,
  ...props
}: TDialogDeliverRelease) => {
  const [state, setState] = useDialogDeliverRelease.dialog()
  const {
    isOpen,
    fulfilled,
    rejected,
    onOpenChange: _onOpenChange,
    onSubmit: _onSubmit,
  } = state

  const onOpenChange = (open: boolean) => {
    setState((prev) => ({ ...prev, isOpen: open }))
    _onOpenChange?.(open)
  }

  const onSubmit = () => {
    _onSubmit?.()
  }

  useEffect(() => {
    initCallback?.()
  }, [initCallback])

  return (
    <DialogDeliverRelease
      open={isOpen}
      onOpenChange={onOpenChange}
      fulfilled={fulfilled}
      rejected={rejected}
      onSubmit={onSubmit}
      {...props}
    />
  )
}

export { DialogDeliverReleaseContainer }
