import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TCashReceiptIssueFailureBase, useCashReceiptIssueFailureAtom } from '.'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'

const CashReceiptIssueFailureContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.CashReceiptIssueFailure),
  }))
)

interface TCashReceiptIssueFailurePack extends TCashReceiptIssueFailureBase {}
export function CashReceiptIssueFailurePack(
  props: TCashReceiptIssueFailurePack
) {
  const [open, setState] = useCashReceiptIssueFailureAtom.state({
    paymentCode: props.paymentCode + (props.atomKey || ''),
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pb-[20px] gap-0 max-w-[380px] pt-0">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <CashReceiptIssueFailureContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
