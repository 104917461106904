import { z } from 'zod'

export const getResMenuBannerDto = z.object({
  body: z.string().nullish().describe('내용'),
  bannerType: z
    .union([
      z.literal('default'),
      z.literal('information'),
      z.literal('critical'),
      z.literal('warning'),
    ])
    .nullish()
    .describe('배너 타입'),
})
