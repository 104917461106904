/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post bulk-action-section-delivery-shipping-validate
 */
export const postResBulkActionSectionDeliveryShippingValidateSchema = z.object({
  fulfilled: z.number().nullish(),
  rejected: z.number().nullish(),
})
export const postPayloadBulkActionSectionDeliveryShippingValidateSchema =
  z.object({
    targetOrderCodes: z.array(z.string()),
  })
