import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { useDeliveryHoldCancelAtom } from './delivery-hold-cancel.store'
import { TDeliveryHoldCancelBase } from './delivery-hold-cancel.type'

const DeliveryHoldCancel = React.lazy(() =>
  import('./delivery-hold-cancel').then((module) => ({
    default: React.memo(module.DeliveryHoldCancel),
  }))
)

interface TDeliveryHoldCancelContainer extends TDeliveryHoldCancelBase {}
export function DeliveryHoldCancelContainer(
  props: TDeliveryHoldCancelContainer
) {
  const [open, setState] = useDeliveryHoldCancelAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <DeliveryHoldCancel {...props} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
