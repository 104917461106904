import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TExtendDepositDueDateBase, useExtendDepositDueDateAtom } from '.'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'

const ExtendDepositDueDateContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExtendDepositDueDate),
  }))
)

interface TExtendDepositDueDatePack extends TExtendDepositDueDateBase {}
export function ExtendDepositDueDatePack(props: TExtendDepositDueDatePack) {
  const [open, setState] = useExtendDepositDueDateAtom.state({
    paymentCode: props.paymentCode + (props.atomKey || ''),
  })
  return (
    <AlertDialog.Root defaultOpen={false} open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <ExtendDepositDueDateContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
