import React, { useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import { Selector } from '../components'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import {
  type CouponResponseDto,
  getCoupons,
  OrderSearchFilterOrderInfoFields,
} from '~/entities/order-search-filter'
import {
  ButtonGroup,
  Checkbox,
  Clay,
  Flex,
  Modal,
  Spinner,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { Button } from '@imwebme/clay-components'
import { flow, pipe } from 'fp-ts/function'
import { useInfiniteQuery } from '@tanstack/react-query'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { find, includes } from 'lodash'
import { SearchSM } from '@imwebme/clay-icons'
import { vars } from '@imwebme/clay-token'
import { Virtuoso } from 'react-virtuoso'
import { tr } from 'date-fns/locale'
import { BlankLink } from '~/shared/components/link'

interface Props extends FormControlProp {
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
}

const fieldKey = 'shopCouponCode'

//  검색 효과를 주기 위해
const useDebounce = (
  value: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  delay = 500
) => {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value)
      setIsLoading(false)
    }, delay)
    return () => clearTimeout(handler)
  }, [value, delay])

  return debounced
}

export const OrderCouponField = ({
  control,
  handleRemoveOrderInfoField,
}: Props) => {
  const { onChange, value } = useController({
    name: fieldKey,
    control,
  }).field
  const [dropdownOpen, setDropdownOpen] = React.useState(() => !value)
  const [selectCoupon, setSelectCoupon] = useState<string[]>(
    value?.length ? value : []
  )
  const [keyword, setKeyword] = useState('')
  const isDisabled = selectCoupon.length === 0
  const addButtonText = isDisabled
    ? '쿠폰을 선택해 주세요'
    : `${selectCoupon.length}개 쿠폰 추가`
  const [isLoading, setIsLoading] = useState(false)
  const debouncedKeyword = useDebounce(keyword, setIsLoading, 500)
  const [selectedDisabled, setSelectedDisabled] = useState(false)

  // 쿠폰 목록 조회
  const { data, isSuccess, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['coupon-search', encodeURIComponent(debouncedKeyword)],
      queryFn: ({ pageParam }) =>
        getCoupons({
          query: debouncedKeyword,
          cursor: pageParam === '' ? undefined : pageParam,
        }),
      getNextPageParam: (page) => page.cursorForNext,
      getPreviousPageParam: (page) => page.cursorForPrev,
      initialPageParam: '',
    })

  // 선택된 쿠폰코드
  const handleSelectCoupon = (couponCode: string) => {
    const index = selectCoupon.indexOf(couponCode)
    let selectedCount = 0

    if (index !== -1) {
      setSelectCoupon(selectCoupon.filter((_, i) => i !== index))
      selectedCount = selectCoupon.filter((_, i) => i !== index).length
    } else {
      setSelectCoupon([...selectCoupon, couponCode])
      selectedCount = [...selectCoupon, couponCode].length
    }

    setSelectedDisabled(selectedCount >= 5)
  }

  const items = React.useMemo(() => {
    const result = pipe(
      data?.pages,
      O.fromNullable,
      O.fold(
        () => [],
        flow(
          (e) => e,
          A.map((e) => e.list),
          A.flatten,
          (e) => e
        )
      )
    )
    return result
  }, [data?.pages])

  const fieldLabel =
    React.useMemo(() => {
      let baseLabel = __('쿠폰검색')

      if (Array.isArray(value) && value.length) {
        const selectedItem = find(items, (o) => includes(value, o.code))
        baseLabel += ': '

        if (!selectedItem) {
          return
        }

        if (value.length > 1) {
          baseLabel += __('{{name}} 외 {{count}}건', {
            name: selectedItem.name,
            count: value.length - 1,
          })
        } else {
          baseLabel += selectedItem.name
        }
      }

      return baseLabel
    }, [value, items]) ?? __('쿠폰검색')

  const handleSubmitModal = () => {
    onChange(selectCoupon)
    setKeyword('')
    setDropdownOpen(false)
  }

  const handleCancelModal = () => {
    setSelectCoupon(value?.length ? value : [])
    setKeyword('')
    setDropdownOpen(false)
    handleRemoveOrderInfoField(fieldKey)
  }

  const handleResetField = React.useCallback(() => {
    onChange(null)
    handleRemoveOrderInfoField(fieldKey)
  }, [])

  return (
    <>
      <Selector
        label={fieldLabel}
        isActive={true}
        resetField={handleResetField}
        setDropdownOpen={setDropdownOpen}
      />

      <Modal
        isOpen={dropdownOpen}
        setClose={() => setDropdownOpen(false)}
        width="medium"
      >
        <Modal.Header
          text={__('쿠폰 목록')}
          type="leftAlign"
          subtle={__('최대 5개까지 선택할 수 있어요.')}
        />
        <Modal.Body>
          <Flex.Column gap="16px">
            <CouponSearchInput
              handleChangeSearchInput={setKeyword}
              setIsLoading={setIsLoading}
            />
            <CouponSearchList
              selectCoupon={isSuccess ? selectCoupon : []}
              handleSelectCoupon={handleSelectCoupon}
              keyword={debouncedKeyword}
              data={items}
              isLoading={isLoading}
              selectedDisabled={selectedDisabled}
              onInViewCallback={fetchNextPage}
              onInViewDisabled={!hasNextPage || isFetchingNextPage}
            />
          </Flex.Column>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button
              native={{ type: 'button' }}
              fullWidth
              onClick={handleCancelModal}
              text="취소"
              variant="outlined"
            />
            <Button
              native={{ type: 'button' }}
              fullWidth
              onClick={handleSubmitModal}
              text={addButtonText}
              variant="primary"
              isDisabled={isDisabled}
            />
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const CouponSearchInput = ({
  handleChangeSearchInput,
  setIsLoading,
}: {
  handleChangeSearchInput: (searchText: string) => void
  setIsLoading: (value: boolean) => void
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }, [])

  return (
    <Clay>
      <Textfield.Box variant="secondary" size="large">
        <Textfield.Addon>
          <SearchSM />
        </Textfield.Addon>
        <Textfield.Input
          placeholder="쿠폰명을 검색해 보세요"
          onChangeText={(value) => {
            handleChangeSearchInput(value)
            setIsLoading(true)
          }}
          ref={inputRef}
        />
      </Textfield.Box>
    </Clay>
  )
}

const CouponSearchList = ({
  selectCoupon,
  handleSelectCoupon,
  keyword,
  data,
  isLoading,
  selectedDisabled,
  onInViewCallback,
  onInViewDisabled,
}: {
  selectCoupon: string[]
  handleSelectCoupon: (couponCode: string) => void
  keyword: string
  data: CouponResponseDto[]
  isLoading: boolean
  selectedDisabled: boolean
  onInViewCallback?: () => void
  onInViewDisabled?: boolean
}) => {
  if (isLoading) {
    return <LoadingSearch />
  }

  if (data.length === 0) {
    if (keyword === '') {
      return (
        <Flex
          padding="20px 24px"
          alignItems="center"
          justifyContent="center"
          minHeight="376px"
        >
          <Flex.Column
            gap={vars.spacing[5]}
            paddingBottom={vars.spacing[7]}
            alignItems="center"
            justifyContent="center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 17C4 13.6863 6.68629 11 10 11H38C41.3137 11 44 13.6863 44 17V20C44 20.5523 43.54 20.9837 43.0181 21.1644C41.8435 21.5711 41 22.687 41 24C41 25.313 41.8435 26.4289 43.0181 26.8356C43.54 27.0163 44 27.4477 44 28V31C44 34.3137 41.3137 37 38 37H10C6.68629 37 4 34.3137 4 31V17ZM18.8801 22.48C18.1401 22.48 17.5601 21.92 17.5601 21.08C17.5601 20.26 18.1401 19.7 18.8801 19.7C19.6401 19.7 20.2001 20.26 20.2001 21.08C20.2001 21.9 19.6401 22.48 18.8801 22.48ZM18.8801 17.8C17.0801 17.8 15.5601 19.2401 15.5601 21.08C15.5601 22.9 17.0601 24.34 18.8801 24.34C20.6801 24.34 22.2201 22.94 22.2201 21.08C22.2201 19.2401 20.6801 17.8 18.8801 17.8ZM27.6201 29.3601C26.8801 29.3601 26.3001 28.8 26.3001 27.96C26.3001 27.14 26.8801 26.58 27.6201 26.58C28.3801 26.58 28.9401 27.14 28.9401 27.96C28.9401 28.78 28.3801 29.3601 27.6201 29.3601ZM27.6201 24.68C25.8201 24.68 24.3001 26.12 24.3001 27.96C24.3001 29.78 25.8001 31.22 27.6201 31.22C29.4201 31.22 30.9601 29.82 30.9601 27.96C30.9601 26.12 29.4201 24.68 27.6201 24.68ZM17.5201 31H19.8401L29.0001 18.02H26.6801L17.5201 31Z"
                fill="#BCC0C6"
              />
            </svg>
            <Typography>선택할 수 있는 쿠폰이 없어요</Typography>
            <BlankLink to="/admin/shopping/coupon">
              <Typography variant="body-small" className="text-[#0090D4]">
                쿠폰 추가하기
              </Typography>
            </BlankLink>
          </Flex.Column>
        </Flex>
      )
    } else {
      return (
        <Flex
          padding="20px 24px"
          alignItems="center"
          justifyContent="center"
          minHeight="376px"
        >
          <Flex.Column
            gap={vars.spacing[5]}
            paddingBottom={vars.spacing[7]}
            alignItems="center"
            justifyContent="center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 17C4 13.6863 6.68629 11 10 11H38C41.3137 11 44 13.6863 44 17V20C44 20.5523 43.54 20.9837 43.0181 21.1644C41.8435 21.5711 41 22.687 41 24C41 25.313 41.8435 26.4289 43.0181 26.8356C43.54 27.0163 44 27.4477 44 28V31C44 34.3137 41.3137 37 38 37H10C6.68629 37 4 34.3137 4 31V17ZM18.8801 22.48C18.1401 22.48 17.5601 21.92 17.5601 21.08C17.5601 20.26 18.1401 19.7 18.8801 19.7C19.6401 19.7 20.2001 20.26 20.2001 21.08C20.2001 21.9 19.6401 22.48 18.8801 22.48ZM18.8801 17.8C17.0801 17.8 15.5601 19.2401 15.5601 21.08C15.5601 22.9 17.0601 24.34 18.8801 24.34C20.6801 24.34 22.2201 22.94 22.2201 21.08C22.2201 19.2401 20.6801 17.8 18.8801 17.8ZM27.6201 29.3601C26.8801 29.3601 26.3001 28.8 26.3001 27.96C26.3001 27.14 26.8801 26.58 27.6201 26.58C28.3801 26.58 28.9401 27.14 28.9401 27.96C28.9401 28.78 28.3801 29.3601 27.6201 29.3601ZM27.6201 24.68C25.8201 24.68 24.3001 26.12 24.3001 27.96C24.3001 29.78 25.8001 31.22 27.6201 31.22C29.4201 31.22 30.9601 29.82 30.9601 27.96C30.9601 26.12 29.4201 24.68 27.6201 24.68ZM17.5201 31H19.8401L29.0001 18.02H26.6801L17.5201 31Z"
                fill="#BCC0C6"
              />
            </svg>
            <Flex.Column gap={vars.spacing[2]} alignItems="center">
              <Typography>검색어와 일치하는 쿠폰이 없어요</Typography>
              <Typography variant="body-small" colorToken="textSub">
                검색어를 확인해 주세요
              </Typography>
            </Flex.Column>
          </Flex.Column>
        </Flex>
      )
    }
  }

  return (
    <Flex.Column
      height="464px"
      overflowY="auto"
      paddingLeft="8px"
      paddingRight="8px"
    >
      <Virtuoso
        className="h-full"
        data={data}
        endReached={() => {
          if (onInViewDisabled) {
            return
          }
          onInViewCallback?.()
        }}
        itemContent={(_, item) => (
          <Flex
            key={item.code}
            paddingTop="10px"
            paddingBottom="10px"
            width="100%"
          >
            <Checkbox.Set>
              <Checkbox.Label label={item.name} sx={{ gap: vars.spacing[3] }}>
                <Checkbox
                  onChangeChecked={() => {
                    handleSelectCoupon(item.code)
                  }}
                  checked={selectCoupon.includes(item.code)}
                  disabled={
                    selectedDisabled && !selectCoupon.includes(item.code)
                  }
                />
              </Checkbox.Label>
              <Checkbox.HelperText>
                {(() => {
                  if (item.applySaleType === 'price') {
                    return `${__('{{price, 3comma}}', {
                      price: item.applySalePrice,
                    })}원 할인 (${__('{{price, 3comma}}', {
                      price: item.applySaleTypeMinimumPrice,
                    })}원 이상 구매 시)`
                  } else if (item.applySaleType === 'delivery') {
                    return `${__('{{price, 3comma}}', {
                      price: item.applySaleTypeMinimumPrice,
                    })}원 이상 구매 시 / 최대 ${__('{{price, 3comma}}', {
                      price: item.applySaleTypeMaxPrice,
                    })}원`
                  } else if (item.applySaleType === 'percent') {
                    return `${item.applySalePercent}% 할인 (${__(
                      '{{price, 3comma}}',
                      {
                        price: item.applySaleTypeMinimumPrice,
                      }
                    )}원 이상 구매 시 / 최대 ${__('{{price, 3comma}}', {
                      price: item.applySaleTypeMaxPrice,
                    })}원)`
                  } else {
                    return ''
                  }
                })()}
              </Checkbox.HelperText>
            </Checkbox.Set>
          </Flex>
        )}
      />
    </Flex.Column>
  )
}

const LoadingSearch = () => (
  <Flex.Column
    width="100%"
    height="100%"
    minHeight="376px"
    justifyContent="center"
    alignItems="center"
    gap="12px"
  >
    <Spinner size="medium" bgColor="#fff" />
    <Typography variant="body-medium" colorToken="textSub">
      쿠폰을 검색하고 있어요
    </Typography>
  </Flex.Column>
)
