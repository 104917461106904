import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { 믹스패널 } from '~/shared/mixpanel'
import { LoaderFunction } from 'react-router-dom'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />
export const Loader: LoaderFunction = async () => {
  dataLayer.push({
    event: 믹스패널.click_bo_oms_order_list_tap_edit_tap,
  })
  return null
}

export { default } from '~/container/order-search-tab/order-search-tab-edit'
