import React from 'react'
import { __ } from '~/shared/i18n'
import { StepLayout } from '../partials/step-layout'
import { Button, ButtonGroup, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  useActiveStepIdxAtom,
  useDeliverySetupIsValid,
} from '../order-create.store'
import { match, P } from 'ts-pattern'
import { StepSkeleton } from '~/container/order-create/partials/step-skeleton'
import { EditableView } from './editable-view'
import { StaticView } from './static-view'
import { useIdle } from '../use-idle'

const STEP_IDX = 2 as const

export function StepDeliverySetup() {
  const [activeStepIdx] = useActiveStepIdxAtom()
  const isIdle = useIdle(STEP_IDX)

  return match(activeStepIdx)
    .with(P.number.gt(STEP_IDX), () => <CompleteStatusView isIdle={isIdle} />)
    .with(STEP_IDX, () => <CurrentStatusView />)
    .with(P.number.lt(STEP_IDX), () => <IncompleteStatusView />)
    .otherwise(() => null)
}

function CurrentStatusView() {
  const [_activeStepIdx, setActiveStepIdx] = useActiveStepIdxAtom()
  const isValid = useDeliverySetupIsValid()

  return (
    <StepLayout stepIdx={STEP_IDX} status="current">
      <React.Suspense fallback={<StepSkeleton />}>
        <Clay
          paddingTop={vars.spacing[6]}
          borderRadius={vars.rounded.large}
          background={vars.semantic.color.surface}
        >
          <EditableView />
        </Clay>

        <ButtonGroup
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="secondary"
            text={__('이전')}
            onClick={() => setActiveStepIdx((prev) => prev - 1)}
          />
          <Button
            isDisabled={!isValid}
            variant="primary"
            text={__('다음')}
            onClick={() => setActiveStepIdx((prev) => prev + 1)}
          />
        </ButtonGroup>
      </React.Suspense>
    </StepLayout>
  )
}

function CompleteStatusView({ isIdle }: { isIdle: boolean }) {
  return (
    <StepLayout stepIdx={STEP_IDX} status="complete">
      {isIdle ? (
        <StepSkeleton />
      ) : (
        <Clay
          paddingTop={vars.spacing[6]}
          borderRadius={vars.rounded.large}
          background={vars.semantic.color.surface}
        >
          <StaticView />
        </Clay>
      )}
    </StepLayout>
  )
}

function IncompleteStatusView() {
  return <StepLayout stepIdx={STEP_IDX} status="incomplete"></StepLayout>
}
