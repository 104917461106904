import {
  TOrderCreatePayloadDeliveryDto,
  TOrderCreatePayloadItemDto,
} from '~/entities/@dto'
import { TDeliverySetup, TProdSelect } from './order-create.schema'
import {
  T상수_배송결제타입,
  T상수_배송타입,
  상수_배송결제타입,
  상수_배송타입,
} from '~/entities/@x'
import { 배송지_직접입력 } from './스텝2배송설정/delivery-setup.constants'

/** 사이트별 스토리지 키 생성 */
export function genStorageKey(key: string) {
  return `order-create__${key}__${ImwebOAuth2ClientAPI.getInfo().siteCode}`
}

/**
 * FE 상태 값을 ItemsDto로 변환
 */
export function transformItemsDto(
  prod: TProdSelect,
  deliv: Partial<TDeliverySetup>
): TOrderCreatePayloadItemDto[] {
  return prod.map((el) => ({
    prodOptionDetailCode: el.prodOptionDetailCode ?? undefined,
    isIndividualOption: el.isIndividualOption,
    isRequireOption: el.isRequireOption,
    optionDataList: el.optionDataList?.map((e) => ({
      optionType: e.optionType,
      optionCode: e.optionCode,
      valueCode: e.valueCode,
    })),
    prodCode: el.prodCode,
    qty: el.qty,
    deliveryTypeCd: deliv._deliveryTypeCd!,
    deliveryPayTypeCd: deliv._deliveryPayTypeCd!,
    itemPrice: el.itemPrice,
  }))
}

/**
 * FE 상태 값을 DeliveryDto로 변환
 */
export function transformDeliveryDto(
  deliv: Partial<TDeliverySetup>
): TOrderCreatePayloadDeliveryDto | undefined {
  // 배송없음
  if (
    deliv._deliveryTypeCd === 상수_배송타입.다운로드 ||
    deliv._deliveryTypeCd === 상수_배송타입.배송없음
  ) {
    return undefined
  }

  // 방문수령
  if (deliv._deliveryTypeCd === 상수_배송타입.방문수령) {
    return deliv.pickupMemo ? { pickupMemo: deliv.pickupMemo } : undefined
  }

  // 회원 배송지 목록에서 선택
  if (
    deliv.shippingAddressCode &&
    deliv.shippingAddressCode !== 배송지_직접입력.code
  ) {
    return {
      shippingAddressCode: deliv.shippingAddressCode,
      addressFormat: deliv.addressFormat,
      memo: deliv.memo || undefined,
      pickupMemo: deliv.pickupMemo || undefined,
    }
  }

  // 직접입력, 한국
  if (deliv.zipcode && deliv.country === 'KR') {
    return {
      country: deliv.country,
      countryName: deliv.countryName,
      receiverCall: deliv.receiverCall,
      receiverName: deliv.receiverName,
      zipcode: deliv.zipcode,
      addr1: deliv.addr1,
      addr2: deliv.addr2 || undefined,
      memo: deliv.memo || undefined,
      pickupMemo: deliv.pickupMemo || undefined,
    }
  }

  // 직접입력, 국제
  if (deliv.zipcode && deliv.country !== 'KR') {
    return {
      country: deliv.country,
      countryName: deliv.countryName,
      receiverCall: deliv.receiverCall,
      receiverName: deliv.receiverName,
      zipcode: deliv.zipcode,
      building: deliv.building || undefined,
      street: deliv.street,
      city: deliv.city,
      state: deliv.state,
      memo: deliv.memo || undefined,
      pickupMemo: deliv.pickupMemo || undefined,
    }
  }

  return undefined
}

/**
 * 배송방식에 따른 배송비 초기값 설정
 */
export function getDefaultDelivPayType(
  delivTypeCd: T상수_배송타입,
  delivPayTypeList: T상수_배송결제타입[]
) {
  if (
    (delivTypeCd === 상수_배송타입.다운로드 ||
      delivTypeCd === 상수_배송타입.방문수령 ||
      delivTypeCd === 상수_배송타입.배송없음) &&
    delivPayTypeList.includes(상수_배송결제타입.배송비없음)
  ) {
    return 상수_배송결제타입.배송비없음
  }
  if (
    delivTypeCd === 상수_배송타입.퀵서비스 &&
    delivPayTypeList.includes(상수_배송결제타입.착불결제)
  ) {
    return 상수_배송결제타입.착불결제
  }
  return delivPayTypeList[0]
}
