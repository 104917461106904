import React from 'react'
import { TExternalReturnRequestBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalReturnRequestContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalReturnRequest }))
)

interface TExternalReturnRequestPack extends TExternalReturnRequestBase {}
export function ExternalReturnRequestPack(props: TExternalReturnRequestPack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalReturnRequestContainer {...props} />
    </React.Suspense>
  )
}
