import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TClaimsSwitchModalBase, useClaimsSwitchModalAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSectionCode } from '~/shared/hooks/use-section-code'
import { Modal } from '@imwebme/clay-components'

const ClaimsSwitchModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ClaimsSwitchModal),
  }))
)

interface TClaimsSwitchModalPack extends TClaimsSwitchModalBase {}
export function ClaimsSwitchModalPack(props: TClaimsSwitchModalPack) {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode(true)
  const [open, setState] = useClaimsSwitchModalAtom.state({
    atomKey: props.atomKey,
    orderCode,
    sectionCode,
  })
  return (
    <Modal
      isOpen={open || false}
      setClose={() => setState(false)}
      width="small"
    >
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <ClaimsSwitchModalContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
