import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import type {
  TPatchResUnpaidDepositOrderCancelSchema,
  TPatchPayloadUnpaidDepositOrderCancelSchema,
} from './unpaid-deposit-order-cancel.type'
import { patchResUnpaidDepositOrderCancelSchema } from './unpaid-deposit-order-cancel.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/rFwQ8g
 * @description 주문 하위 전체섹션(품목) 취소완료 처리
 */
export async function patchUnpaidDepositOrderCancel({
  body,
  orderCode,
}: {
  body: TPatchPayloadUnpaidDepositOrderCancelSchema
  orderCode: string
}) {
  type T = TPatchResUnpaidDepositOrderCancelSchema
  type R = typeof body
  const result = await axios.patch<T, AxiosResponse<T>, R>(
    `/order/${orderCode}/unpaid-deposit-order/cancel`,
    body
  )

  patchResUnpaidDepositOrderCancelSchema.parse(result.data)
  return result
}
