import { Navigate } from 'react-router-dom'
import { useLL } from '~/shared/i18n'
import { usePermissionAtom } from './provider/permission'
import { Path } from '../router'

const VIEW_OR_EDIT_PROTECTED: Path[] = ['/order-create']

/**
 * 회원 권한을 체크하여 보호된 경로는 리다이렉트
 * 403: 권한 없음
 * */
export const Redirects = ({ children }: { children: React.ReactNode }) => {
  const permission = usePermissionAtom()
  const location = useLL()

  const viewOrEditProtected =
    (permission.adminShoppingOms.view === 'N' ||
      permission.adminShoppingOms.edit === 'N') &&
    VIEW_OR_EDIT_PROTECTED.includes(location.pathname as Path)

  if (viewOrEditProtected) {
    return <Navigate to="/403" replace />
  }

  return children
}
