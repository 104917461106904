import React from 'react'
import { TDeliveryBannerParcelBase } from '.'

const DeliveryBannerParcelContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryBannerParcel),
  }))
)

interface TDeliveryBannerParcelPack extends TDeliveryBannerParcelBase {}
export function DeliveryBannerParcelPack(props: TDeliveryBannerParcelPack) {
  return (
    <React.Suspense>
      <DeliveryBannerParcelContainer {...props} />
    </React.Suspense>
  )
}
