import React from 'react'
import { ProductSearchItemProps } from '../product-search-item.type'
import { match } from 'ts-pattern'
import { cn } from '~/shared/utils'
import { Collapse } from '~/shared/components/collapse'

const DivComp = (
  props: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
) => <div {...props} />
const ButtonComp = (
  props: React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>>
) => <button type="button" {...props} />
interface ItemWrapperProps extends React.HTMLAttributes<HTMLElement> {
  isButton: boolean
  onClick: () => void
}
const ItemWrapper = ({
  isButton,
  onClick,
  children,
  ...props
}: React.PropsWithChildren<ItemWrapperProps>) => {
  const Wrapper = isButton ? ButtonComp : DivComp
  const wrapperProps = isButton ? { onClick } : {}
  return (
    <Wrapper {...props} {...wrapperProps}>
      {children}
    </Wrapper>
  )
}

export type TProductSearchItemBase = {
  open?: boolean
  setOpen?: (e: boolean) => void
  className?: string
  labelId?: string
}
export const ProductSearchItemBase = ({
  thumbnail,
  name,
  children,
  disabled,
  open = false,
  setOpen,
  className,
  labelId,
  id,
  ...props
}: Omit<ProductSearchItemProps, 'optionCount' | 'price' | 'currency'> &
  React.PropsWithChildren<TProductSearchItemBase>) => {
  const childrenArray = React.Children.toArray(
    children
  ) as unknown as React.ReactElement[]

  function findChild(childName: string): React.ReactElement
  function findChild(
    childName: string,
    optional?: boolean
  ): React.ReactElement | undefined
  function findChild(childName: string, optional?: boolean) {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined && !optional) {
      throw new Error(`${childName} is undefined`)
    }
    return result
  }

  const RightArea = findChild('RightArea', true)
  const LeftArea = findChild('LeftArea', true)
  const BodyArea = findChild('BodyArea', true)
  const NameArea = findChild('NameArea', true)
  const LabelComp = labelId ? 'label' : 'div'
  return (
    <div>
      <ItemWrapper
        onClick={() => setOpen?.(!open)}
        isButton={BodyArea !== undefined}
        className={cn(
          'grid gap-x-[12px] min-h-[40px] py-[6px] typo-clay-label-medium w-full text-left',
          disabled && 'text-[#BCC0C6]',
          className
        )}
        style={match<boolean>(true)
          .with(!!(LeftArea && thumbnail), () => ({
            gridTemplateAreas: "'left-area thumbnail name right-area'",
            gridTemplateColumns: '16px minmax(0, 48px) 1fr auto',
          }))
          .with(!!(!LeftArea && thumbnail), () => ({
            gridTemplateAreas: "'thumbnail name right-area'",
            gridTemplateColumns: 'minmax(0, 48px) 1fr auto',
          }))
          .with(!!(!LeftArea && !thumbnail), () => ({
            gridTemplateAreas: "'name right-area'",
            gridTemplateColumns: '1fr auto',
          }))
          .with(!!(LeftArea && !thumbnail), () => ({
            gridTemplateAreas: "'left-area name right-area'",
            gridTemplateColumns: '16px 1fr auto',
          }))
          .otherwise(() => ({}))}
        {...props}
      >
        {LeftArea && (
          <div
            className="flex justify-center items-center"
            style={{ gridArea: 'left-area' }}
            {...LeftArea?.props}
          />
        )}
        {thumbnail && (
          <div
            style={{
              gridArea: 'thumbnail',
            }}
          >
            <div className="aspect-1 relative rounded-[8px] overflow-hidden bg-[#efefef]">
              <img
                src={thumbnail}
                alt=""
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>
        )}
        <LabelComp
          className={cn('self-center', labelId && 'cursor-pointer')}
          style={{
            gridArea: 'name',
          }}
          htmlFor={labelId}
        >
          {NameArea ? <div {...NameArea.props} /> : name}
        </LabelComp>
        <div
          className="self-center"
          style={{
            gridArea: 'right-area',
          }}
          {...RightArea?.props}
        />
      </ItemWrapper>
      {BodyArea && (
        <Collapse in={open}>
          <div
            className="pl-[60px] pt-[4px] pb-[10px]"
            data-imweb-component-label="body"
            {...BodyArea.props}
          />
        </Collapse>
      )}
    </div>
  )
}
interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'RightArea' | 'LeftArea' | 'BodyArea' | 'NameArea'
}
const Slot: React.FC<SlotProps> = () => null
ProductSearchItemBase.Slot = Slot
