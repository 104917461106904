/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { IS_YN } from './order-memo.constants'

/**
 * get order-memo
 */

export const getResOrderMemoSchema = z
  .object({
    idx: z.number().describe('메모 인덱스'),
    memberCode: z.string().describe('멤버코드'),
    name: z.string().describe('작성자'),
    profileImgUrl: z.string().nullable().describe('프로필 사진'),
    memo: z.string().describe('메모 내용'),
    isDone: z.nativeEnum(IS_YN).describe('확인 완료 여부'),
    wtime: z.string().describe('작성일'),
    mtime: z.string().describe('수정일'),
  })
  .array()
