import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TExchangedItemsModalBase, useExchangedItemsModalAtom } from '.'
import { Modal } from '@imwebme/clay-components'

const ExchangedItemsModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExchangedItemsModal),
  }))
)

interface TExchangedItemsModalPack extends TExchangedItemsModalBase {}
export function ExchangedItemsModalPack(props: TExchangedItemsModalPack) {
  const [open, setState] = useExchangedItemsModalAtom.state({
    atomKey: props.atomKey,
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })
  return (
    <Modal isOpen={!!open} setClose={() => setState(false)} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <ExchangedItemsModalContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
