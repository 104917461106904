import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { TExternalOrderSectionDto } from '~/entities/external-order'

export function ExternalOrder2ReturnRequest(
  sectionList: TExternalOrderSectionDto[] | undefined
) {
  try {
    const result = pipe(
      sectionList,
      O.fromNullable,
      O.getOrElseW(() => {
        throw new Error('🚀 ~ orderSectionList')
      }),
      A.map((e) => ({
        orderSectionCode: e.orderSectionCode,
        targetItemInformation: pipe(
          e.orderSectionItemList,
          O.fromNullable,
          O.getOrElseW(() => {
            throw new Error('🚀 ~ orderSectionItemList')
          }),
          A.map((e2) => ({
            orderSectionItemCode: e2.orderSectionItemCode,
            qty: e2.qty,
          }))
        ),
      })),
      A.filter((e) => e.targetItemInformation.length > 0),
      (e) => ({
        returnReasonCode: '',
        returnReasonDetail: '',
        targetSectionInformation: e,
      })
    )
    return result
  } catch (error) {
    log.warn(error)
    return undefined
  }
}
