import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import {
  useOrderSectionAtom,
  OrderEditApplyItemSection,
  OrderSection,
} from '~t'
import React from 'react'
import {
  IconArrowLeft,
  IconChevronDown,
  IconWarningTriangle,
} from '@imwebme/icon'
import { Select } from '~/shared/components/ui/select'
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from 'react-hook-form'
import { DevTool } from '~/app/provider/react-hook-form'
import { Button } from '@imwebme/clay-components'
import { type TC3payloadItems } from '~/container/order/order-cancel-info/order-cancel-info.type'
import { CancelRequest } from '~/container/order/order-cancel-info/order-cancel-info-api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  orderCancelnfoResolver,
  TCancelRequestForm,
} from '~/container/order/order-cancel-info/schema'
import { OrderCancelCalcCard } from './order-cancel-info-calc-card'
import {
  OrderCancelInfoCancelRequestModalWithRefundAtom,
  OrderCancelInfoCancelRequestWithRefundAtom,
} from './order-cancel-info.store'
import { WithCancelRequestModal } from './order-cancel-info-form/cancel-request-modal'
import { useLL, __ } from '~/shared/i18n'
import { useNavigate } from 'react-router-dom'
import { Link } from '~/shared/components/link'
import { useGetOrderDetailContextState } from '../../order-detail/order-detail-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { OrderHeaderStep } from '~t/order-edit/order-edit-header/partials/order-header-step'
import { OrderEditHeader } from '~t/order-edit/order-edit-header'
import { OrderTimelinePack } from '../../order-timeline'
import { cn } from '~/shared/utils'
import { TClaims, siteClaimsQueryOptions } from '~/entities/site/claims'
import { orderDetailOptions } from '~/entities/order-detail'
import { 상수_판매채널 } from '~/entities/@x'

const CancelReasonCdSelect = () => {
  const { control } = useFormContext()
  const orderCode = useOrderCode()
  const { data: orderData, isSuccess } =
    useGetOrderDetailContextState(orderCode)

  const {
    data,
    isPending,
    error: isError,
  } = useQuery({
    ...siteClaimsQueryOptions({
      type: 'cancel',
      unit_code: orderData?.unitCode,
    }),
    enabled: isSuccess,
  })
  if (isPending) {
    return <div>{__('로딩중')}</div>
  }
  if (isError) {
    return <div>{__('에러')}</div>
  }

  return (
    <Controller
      name="cancelReason"
      control={control}
      render={({ field: { onChange, ref }, fieldState: { error } }) => (
        <div>
          <Select.Root
            onValueChange={(e: TClaims) =>
              pipe(
                data,
                A.findFirst(([k]) => k === e),
                O.fold(
                  // 해당 키가 없으면 안되기 떄문에 console 처리
                  () => log.debug('error'),
                  flow(([, v]) => v, onChange)
                )
              )
            }
          >
            <Select.Trigger
              className={cn(
                'w-full relative border-solid',
                error && 'bg-clay-semantic-surfaceCritical border-none'
              )}
              ref={ref}
            >
              <Select.Value placeholder={__('사유를 선택해주세요')} />
              {error && (
                <IconWarningTriangle
                  width={16}
                  height={16}
                  className="text-clay-semantic-iconCritical stroke-[2] absolute right-[36px] top-1/2 -translate-y-1/2"
                />
              )}
              <IconChevronDown className="stroke-[2]" />
            </Select.Trigger>
            {error && (
              <div className="typo-clay-body-small text-clay-semantic-textCritical mt-[4px]">
                {error.message}
              </div>
            )}
            <Select.Content>
              <Select.Group>
                {pipe(
                  data,
                  A.map(([key, value]) => (
                    <Select.Item value={key} key={key}>
                      {value}
                    </Select.Item>
                  ))
                )}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </div>
      )}
    />
  )
}

function Header({
  orderNo,
  onClickPrevious,
}: {
  orderNo: string
  onClickPrevious: () => void
}) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
        >
          <div className="flex gap-x-[4px] items-center">
            <IconArrowLeft className="stroke-[2]" />
            <span>{orderNo}</span>
          </div>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('취소')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: '품목 선택',
              active: false,
            },
            {
              id: '2',
              label: '취소 정보 입력',
              active: true,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <div className="space-x-[8px]">
          <Button
            native={{ type: 'button' }}
            variant="secondary"
            onClick={onClickPrevious}
            text={__('이전')}
          />
          <Button
            native={{ type: 'button' }}
            variant="primary"
            onClick={onClickPrevious}
            isDisabled
            text={__('다음')}
          />
        </div>
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

export default function OrderCancelInfo({
  c3payloadItems,
  orderNo,
}: {
  c3payloadItems: TC3payloadItems
  orderNo: number
}) {
  const router = useNavigate()
  const orderCode = useOrderCode()
  const location = useLL()
  const queryClient = useQueryClient()

  const OrderCancelInfoCancelRequestWithRefund = useAtomValue(
    OrderCancelInfoCancelRequestWithRefundAtom
  )
  const setOrderCancelInfoCancelRequestModalWithRefundAtom = useSetAtom(
    OrderCancelInfoCancelRequestModalWithRefundAtom
  )
  const updateitems = useOrderSectionAtom.valueUpdateAll()

  const method = useForm<TCancelRequestForm>({
    resolver: orderCancelnfoResolver,
    defaultValues: {
      targetSectionInformation: c3payloadItems,
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    getValues,
    setValue,
  } = method

  React.useEffect(() => {
    const targetSectionInformation = getValues('targetSectionInformation')
    if (targetSectionInformation.length === 0) {
      setValue('targetSectionInformation', c3payloadItems)
    }
  }, [])

  const onSubmit = async (formValues: TCancelRequestForm) => {
    // 환불도 함께 진행할때 환불 모달을 띄우기
    if (OrderCancelInfoCancelRequestWithRefund) {
      setOrderCancelInfoCancelRequestModalWithRefundAtom(true)
    } else {
      const responseData = await CancelRequest({
        orderCode,
        body: formValues,
      })
        .then((v) => v)
        .catch((err) => {
          log.error(err)
          new Error(err)
        })

      if (responseData && responseData?.data?.length) {
        queryClient.invalidateQueries({
          queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
        })
        router(location.pathname.replace('/cancel/info', ''))
      }
    }
  }

  // ==========================================
  // auth: @Hansanghyeon
  // 상품추가, 상품수량
  // ==========================================
  const items = useOrderSectionAtom.valueUpdateAll()

  const baseItems = pipe(
    items,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.map((e) => e.value),
        A.flatten
      )
    )
  )

  const itemAddedItems = pipe(
    baseItems,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.filter((e) => e.qty === 0),
        A.map((e) => ({
          ...e,
          qty: e.qtyChange,
        }))
      )
    )
  )

  const countIncreasedItems = pipe(
    baseItems,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.filter((e) => e.qty !== 0 && (e.qtyChange || 0) > 0),
        A.map((e) => ({ ...e, qty: e.qtyChange }))
      )
    )
  )

  // items white guard
  if (!items) {
    return <div>{__('변경된 아이템이 없다.')}</div>
  }
  if (!itemAddedItems) {
    return <div>{__('추가된 아이템이 없다.')}</div>
  }
  if (!countIncreasedItems) {
    return <div>{__('수량이 변경된 아이템이 없다.')}</div>
  }

  return (
    <>
      <Header
        orderNo={String(orderNo)}
        onClickPrevious={() => router(`/order/1/${orderCode}/cancel`)}
      />
      <div className="mt-[32px] mb-[70px] min-w-[992px] overflow-auto">
        <div className="w-[970px] mx-auto">
          <FormProvider {...method}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register('targetSectionInformation')} />
              <input type="hidden" {...register('cancelReason')} />
              <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-x-[20px] gap-y-[20px]">
                <div className="grid gap-y-[20px]">
                  <OrderSection name="취소정보입력">
                    <OrderSection.Slot name="title">
                      {__('취소 정보')}
                    </OrderSection.Slot>
                    <OrderSection.Slot name="body">
                      <div className="grid grid-cols-[145px,1fr] gap-x-[20px] gap-y-[16px]">
                        <div className="semantic-h6-bold">{__('품목')}</div>
                        <div>
                          {itemAddedItems.length > 0 && (
                            <OrderEditApplyItemSection
                              items={itemAddedItems}
                              className="grid gap-y-[20px]"
                            />
                          )}
                          {countIncreasedItems.length > 0 && (
                            <OrderEditApplyItemSection
                              items={countIncreasedItems}
                              className="grid gap-y-[20px]"
                            />
                          )}
                        </div>
                        <div className="semantic-h6-bold">{__('사유')}</div>
                        <div className="grid gap-y-[12px]">
                          <div>
                            <CancelReasonCdSelect />
                          </div>
                          <Controller
                            name="cancelReasonDetail"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <div>
                                <textarea
                                  placeholder={__('상세 사유를 입력해주세요')}
                                  className={cn(
                                    error && 'focus:ring-red-500',
                                    'flex h-20 w-full rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900'
                                  )}
                                  {...field}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </OrderSection.Slot>
                  </OrderSection>
                </div>
                <div>
                  <div className="flex flex-col gap-[16px]">
                    <OrderCancelCalcCard />
                    <Button
                      native={{ type: 'submit' }}
                      variant="primary"
                      isDisabled={!errors}
                      fullWidth
                      size="large"
                      text={
                        OrderCancelInfoCancelRequestWithRefund
                          ? __('취소 접수 후 환불')
                          : __('취소 접수')
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
            {updateitems && <WithCancelRequestModal />}
          </FormProvider>
        </div>
      </div>
      <DevTool control={control} />
    </>
  )
}
