import React from 'react'
import { TExternalDeliveryDoneBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalDeliveryDoneContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalDeliveryDone }))
)

interface TExternalDeliveryDonePack extends TExternalDeliveryDoneBase {}
export function ExternalDeliveryDonePack(props: TExternalDeliveryDonePack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalDeliveryDoneContainer {...props} />
    </React.Suspense>
  )
}
