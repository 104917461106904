export const RETRIEVE_TYPE_CODE = {
  AUTO_RETRIEVE_REQUEST: 'RRT01', // 자동 수거 신청
  SENDING_BY_CUSTOMER: 'RRT02', // 구매자 발송
  MANUAL_RETRIEVE_REQUEST: 'RRT04', // 수동 수거 신청 (판매자 직접 송장번호 입력)
  ETC: 'RRT03', // 기타
} as const
export type TypeRetrieveTypeCode =
  (typeof RETRIEVE_TYPE_CODE)[keyof typeof RETRIEVE_TYPE_CODE]

export const RETRIEVE_PAY_TYPE_CODE = {
  CREDIT: 'RPT01', // 신용
  PREPAYMENT: 'RPT02', // 선불
  CASH_ON_DELIVERY: 'RPT03', // 착불
  ETC: 'RPT04', // 기타, 택배 아닌 경우 등
} as const
export type TypeRetrievePayTypeCode =
  (typeof RETRIEVE_PAY_TYPE_CODE)[keyof typeof RETRIEVE_PAY_TYPE_CODE]
