import { Flex, Skeleton, IconButton } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Plus } from '@imwebme/clay-icons'

export function OrdersTabSkeleton() {
  return (
    <Flex
      padding={`${vars.spacing[3]} ${vars.spacing[4]}`}
      backgroundColor={vars.semantic.color.surface}
      borderRadius={vars.rounded.large}
      overflow="hidden"
    >
      <Flex.Center paddingInline={vars.spacing[2]}>
        <Skeleton width="50px" height="20px" rounded="full" />
      </Flex.Center>
      {Array.from(Array(4)).map((_, idx) => (
        <Flex.Center key={idx} paddingInline={vars.spacing[2]}>
          <Skeleton width="80px" height="20px" rounded="full" />
        </Flex.Center>
      ))}
      <IconButton size="tiny" icon={<Plus />} variant="secondary" />
    </Flex>
  )
}
