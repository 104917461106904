import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import { type TPostResOrderDto, type TPostPayloadOrderDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 주문 생성
 */
export function postOrder() {
  return async function (body: TPostPayloadOrderDto) {
    type R = typeof body
    type T = TPostResOrderDto
    const result = await axios
      .post<T, AxiosResponse<ApiResponse<T>>, R>('/order', body)
      .then((res) => res.data)

    return result
  }
}
