import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'

export type T_SelectedLabel = {
  count: number
}
export function SelectedLabel({ count }: T_SelectedLabel) {
  if (count < 1) {
    throw new Error('선택됨 라벨 컴포넌트의 count는 1 이상이어야 합니다.')
  }
  return (
    <div className="bg-clay-semantic-surfaceSuccess text-clay-semantic-textSuccess rounded-[14px] px-[6px] py-[4px] semantic-l11">
      <Trans
        defaults="<0>{{ count }}</0>개 선택됨"
        values={{ count }}
        count={count}
        components={{ 0: <span /> }}
      />
    </div>
  )
}
