import { z } from 'zod'

export const 상수_주문타입 = {
  쇼핑: 'OOT01',
  예약: 'OOT02',
  디지털: 'OOT03',
  그룹패스: 'OOT04',
} as const

const type = z.nativeEnum(상수_주문타입)
export type T주문타입 = z.infer<typeof type>
