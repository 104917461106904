import {
  Flex,
  LocalColorSchemeArea,
  Button,
  IconButton,
  Clay,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { ChevronDown, DotsHorizontal } from '@imwebme/clay-icons'
import { SkyRing } from '~/container/onboarding/partials/sky-ring'

export function BulkAction() {
  return (
    <LocalColorSchemeArea theme="dark">
      <Flex
        padding={vars.spacing[3]}
        backgroundColor={vars.semantic.color.surface}
        borderRadius={vars.rounded.large}
        gap={vars.spacing[15]}
        width="fit-content"
        position="absolute"
        bottom={36}
        left="50%"
        transform="translate(-50%, 0)"
        zIndex={1}
      >
        <Clay position="relative">
          <SkyRing />
          <Button
            variant="primary"
            text={__('주문처리')}
            trailingIcon={<ChevronDown />}
          />
        </Clay>
        <Button variant="outlined" text={__('처리 내역')} />
        <IconButton variant="outlined" icon={<DotsHorizontal />} />
      </Flex>
    </LocalColorSchemeArea>
  )
}
