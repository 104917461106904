import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResRetrieveRequestSchema,
  TPostPayloadRetrieveRequestSchema,
  TPatchResRetrieveRequestSchema,
  TPatchPayloadRetrieveRequestSchema,
} from './retrieve-request.type'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Pss1PK
 * @description 외부 채널 주문 수거지시 (수거 요청)
 */
export async function postRetrieveRequest({
  body,
  orderCode,
  sectionCode,
}: {
  body: TPostPayloadRetrieveRequestSchema
  orderCode: string
  sectionCode: string
}) {
  type T = TPostResRetrieveRequestSchema
  type R = typeof body
  await axios
    .post<
      T,
      AxiosResponse<T>,
      R
    >(`/external-order/${orderCode}/sections/${sectionCode}/retrieve-request`, body)
    .then((res) => res.data)
}

/**
 * @API문서 https://5k.gg/XU19Ko
 * @description 외부 채널 주문 수거지시 (수거 요청) 정보 수정
 */
export async function patchRetrieveRequest<
  T = TPatchResRetrieveRequestSchema,
  R = TPatchPayloadRetrieveRequestSchema,
>({ body, orderCode }: { body: R; orderCode: string }) {
  const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
    `/external-order/${orderCode}/sections/retrieve-info`,
    body
  )

  return result
}
