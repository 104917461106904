/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'

/**
 * get invoice
 */
export const getResInvoiceSchema = z.undefined()

/**
 * post invoice
 */
export const postResInvoiceSchema = ApiResponseSchema
export const postPayloadInvoiceSchema = z.object({
  orderCode: z.string(),
  orderSectionCode: z.string(),
  invoiceNo: z.string().min(3),
  parcelCompanyIdx: z.number(),
})

/**
 * patch invoice
 */
export const patchResInvoiceSchema = ApiResponseSchema
export const patchPayloadInvoiceSchema = z.object({
  parcelCompanyIdx: z.number(),
  orderSectionCode: z.string(),
  orderCode: z.string(),
  invoiceNo: z.string(),
})

/**
 * delete invoice
 */
export const deleteResInvoiceSchema = ApiResponseSchema
export const deletePayloadInvoiceSchema = z.object({
  orderCode: z.string(),
  orderSectionCode: z.string(),
})
