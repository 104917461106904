import React from 'react'
import { Button } from '@imwebme/clay-components'
import { ChevronUp, DotsHorizontal } from '@imwebme/clay-icons'
import { DropdownMenu } from '~/shared/components/dropdown-menu'
import { __ } from '~/shared/i18n'
import { PaymantConfirmationPack } from '~/container/orders/partials/벌크_입금확인/index'
import { ProductPreparationPack } from '~/container/orders/partials/벌크_상품준비중/index'
import { RegisterInvoiceExcelPack } from '~/container/orders/partials/벌크_송장등록_엑셀/index'
import { DeliveryReadyPack } from '~/container/orders/partials/벌크_배송대기/index'
import { RegisterInvoiceGoodsflowPack } from '~/container/orders/partials/벌크_송장등록_굿스플로/index'
import { InvoiceDeletePack } from '~/container/orders/partials/벌크_송장삭제/index'
import { DeliveryHoldOnPack } from '~/container/orders/partials/벌크_배송보류_설정/index'
import { DeliveryHoldOffPack } from '~/container/orders/partials/벌크_배송보류_해제/index'
import { DeliveryShippingPack } from '~/container/orders/partials/벌크_배송중/index'
import { PrintPack } from '~/container/orders/partials/벌크_주문서출력/index'
import { DeliveryCompletePack } from '~/container/orders/partials/벌크_배송완료/index'
import { CashReceiptPack } from '~/container/orders/partials/벌크_현금영수증/index'
import { IconButton, LocalColorSchemeArea } from '@imwebme/clay-components'
import { Portal } from '@radix-ui/react-portal'
import { useRecoilValue } from 'recoil'
import { orderTableSelectAllOrdersState } from '~/container/orders/orders-table/orders-table-body'
import { PaymantConfirmationAllPack } from '~/container/orders/partials/전체_벌크_입금확인'
import { ProductPreparationAllPack } from '~/container/orders/partials/전체_벌크_상품준비중'
import { DeliveryReadyAllPack } from '~/container/orders/partials/전체_벌크_배송대기'
import { DeliveryCompleteAllPack } from '~/container/orders/partials/전체_벌크_배송완료'
import { DeliveryShippingAllPack } from '~/container/orders/partials/전체_벌크_배송중'
import { DeliveryHoldOnAllPack } from '~/container/orders/partials/전체_벌크_배송보류_설정'
import { DeliveryHoldOffAllPack } from '~/container/orders/partials/전체_벌크_배송보류_해제'
import { RegisterInvoiceGoodsflowAllPack } from '~/container/orders/partials/전체_벌크_송장등록_굿스플로'
import { InvoiceDeleteAllPack } from '~/container/orders/partials/전체_벌크_송장삭제'
import { CashReceiptAllPack } from '~/container/orders/partials/전체_벌크_현금영수증'

const OrderProcessingButton = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const selectAllOrders = useRecoilValue(orderTableSelectAllOrdersState)

  return (
    <>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <LocalColorSchemeArea theme="dark">
          <DropdownMenu.Trigger asChild>
            <Button
              native={{ type: 'button' }}
              variant="primary"
              text={__('주문처리')}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              trailingIcon={
                <ChevronUp color="inherit" rotate={isOpen ? 0 : 180} />
              }
            />
          </DropdownMenu.Trigger>
        </LocalColorSchemeArea>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            isDark
            align="end"
            sideOffset={20}
            className="w-[168px]"
          >
            {selectAllOrders ? (
              <PaymantConfirmationAllPack.Button />
            ) : (
              <PaymantConfirmationPack.Button />
            )}
            {selectAllOrders ? (
              <ProductPreparationAllPack.Button />
            ) : (
              <ProductPreparationPack.Button />
            )}
            {selectAllOrders ? (
              <DeliveryReadyAllPack.Button />
            ) : (
              <DeliveryReadyPack.Button />
            )}
            <RegisterInvoiceExcelPack.Button />
            {selectAllOrders ? (
              <RegisterInvoiceGoodsflowAllPack.Button />
            ) : (
              <RegisterInvoiceGoodsflowPack.Button />
            )}
            {selectAllOrders ? (
              <InvoiceDeleteAllPack.Button />
            ) : (
              <InvoiceDeletePack.Button />
            )}
            {selectAllOrders ? (
              <DeliveryHoldOnAllPack.Button />
            ) : (
              <DeliveryHoldOnPack.Button />
            )}
            {selectAllOrders ? (
              <DeliveryHoldOffAllPack.Button />
            ) : (
              <DeliveryHoldOffPack.Button />
            )}
            {selectAllOrders ? (
              <DeliveryShippingAllPack.Button />
            ) : (
              <DeliveryShippingPack.Button />
            )}
            {selectAllOrders ? (
              <DeliveryCompleteAllPack.Button />
            ) : (
              <DeliveryCompletePack.Button />
            )}
            {selectAllOrders ? (
              <CashReceiptAllPack.Button />
            ) : (
              <CashReceiptPack.Button />
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu>
    </>
  )
}

const CancelRefundButton = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      <LocalColorSchemeArea theme="dark">
        <DropdownMenu.Trigger asChild>
          <Button
            native={{ type: 'button' }}
            variant="outlined"
            text={__('취소/환불')}
            onClick={() => setIsOpen(!isOpen)}
            leadingIcon={
              <ChevronUp color="inherit" rotate={isOpen ? 0 : 180} />
            }
          />
        </DropdownMenu.Trigger>
      </LocalColorSchemeArea>
      <DropdownMenu.Content
        isDark
        align="end"
        sideOffset={20}
        className="w-[140px]"
      >
        {'준비중'}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

const MoreDotButton = () => {
  const [_, setIsOpen] = React.useState(false)
  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      <LocalColorSchemeArea theme="dark">
        <DropdownMenu.Trigger asChild>
          <IconButton
            native={{ type: 'button' }}
            variant="outlined"
            icon={<DotsHorizontal color="inherit" />}
          />
        </DropdownMenu.Trigger>
      </LocalColorSchemeArea>
      <DropdownMenu.Content
        isDark
        align="end"
        sideOffset={20}
        className="w-[140px]"
      >
        <PrintPack.Button />
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

export interface OrderToastProps {
  open: boolean
}
export function OrderToast({ open = false }: OrderToastProps) {
  return (
    <>
      {open && (
        <Portal>
          <div className="fixed bottom-[--toastify-toast-bottom] left-1/2 -translate-x-1/2">
            <div className="text-black bg-black rounded-[12px] drop-shadow-sm h-[64px] p-[12px] space-x-[6px] grid grid-cols-[auto,auto,auto] mr-[var(--removed-body-scroll-bar-size)]">
              <OrderProcessingButton />
              {/*<CancelRefundButton />*/}
              <MoreDotButton />
            </div>
          </div>
        </Portal>
      )}
      <PaymantConfirmationPack />
      <PaymantConfirmationAllPack />
      <ProductPreparationPack />
      <ProductPreparationAllPack />
      <RegisterInvoiceExcelPack />
      <RegisterInvoiceGoodsflowPack />
      <RegisterInvoiceGoodsflowAllPack />
      <DeliveryReadyPack />
      <DeliveryReadyAllPack />
      <InvoiceDeletePack />
      <InvoiceDeleteAllPack />
      <DeliveryHoldOnPack />
      <DeliveryHoldOnAllPack />
      <DeliveryHoldOffPack />
      <DeliveryHoldOffAllPack />
      <DeliveryShippingPack />
      <DeliveryShippingAllPack />
      <DeliveryCompletePack />
      <DeliveryCompleteAllPack />
      <CashReceiptPack />
      <CashReceiptAllPack />
    </>
  )
}
