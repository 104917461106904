import { ExternalOrderCancelSelect } from '~/container/external-order/external-order-cancel-select'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

export default function Page() {
  return (
    <>
      <PageTopBar />
      <ExternalOrderCancelSelect />
    </>
  )
}
