import { atom, useAtom } from 'jotai'
import { TGetResMembersItemDto } from '~/entities/site/members'

type TMemberSelectModal = {
  keyword?: string
  curMemberCode?: string | null
  handleSelectMember: (member: TGetResMembersItemDto) => void
}

const memberSelectModalAtom = atom<TMemberSelectModal>({
  handleSelectMember: log.debug,
})

export function useMemberSelectModalAtom() {
  return useAtom(memberSelectModalAtom)
}
