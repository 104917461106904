/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

export const postResDeliveryDelayDto = z.object({
  failedOrderSectionItemCodes: z
    .array(z.string())
    .describe('요청에 실패한 품목번호'),
})
export const postPayloadDeliveryDelayDto = z.object({
  orderSectionCode: z.string().describe('요청 섹션 코드'),
  orderSectionItemCodes: z.array(z.string()).describe('요청 품목 코드'),
  dueDate: z
    .string()
    .describe(
      '발송지연 처리될 품목의 배송기한 (요청시간보다 나중이어야 합니다.)'
    ),
  reason: z.string().describe('각 외부채널에 맞는 발송지연 사유 코드'),
  reasonDetail: z.string().nullable().describe('발송지연 사유 상세 내용'),
})
