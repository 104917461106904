import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionInvoiceDeleteApplySchema,
  type TPostPayloadBulkActionInvoiceDeleteApplySchema,
  postResBulkActionInvoiceDeleteApplySchema,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/hiwReZ
 * @description 주문 송장 삭제 대량 처리 등록
 */
export async function postBulkActionInvoiceDeleteApply({
  body,
}: {
  body: TPostPayloadBulkActionInvoiceDeleteApplySchema
}) {
  type R = typeof body
  type T = TPostResBulkActionInvoiceDeleteApplySchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/invoice/delete', body)
    .then((res) => res.data)

  postResBulkActionInvoiceDeleteApplySchema.parse(result.data)
  return result
}
