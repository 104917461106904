import React from 'react'
import { TFieldProps } from '../order-form-data-edit.type'
import { fDate } from '~/shared/utils'
import { Download01 } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { Clay, IconButton, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

export function FileField({ fileDownloadUrl, wtime }: TFieldProps) {
  const onClickDownload = React.useCallback(() => {
    if (fileDownloadUrl?.objectSignedUrl) {
      const objectSignedUrl = fileDownloadUrl.objectSignedUrl
      fetch(objectSignedUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const a = document.createElement('a')
          a.href = url
          a.download = fileDownloadUrl.fileName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        })
        .catch((error) => {
          log.error('파일 다운로드 오류:', error)
        })
    }
  }, [fileDownloadUrl])

  return (
    <Clay
      borderRadius={vars.rounded.medium}
      padding={vars.spacing[1]}
      background={vars.semantic.color.surfaceSub}
    >
      <Clay
        display="grid"
        width="100%"
        gridTemplateColumns="237px auto"
        padding={`${vars.spacing[1]} ${vars.spacing[3]}`}
        justifyContent="space-between"
        alignItems="center"
        gap={vars.spacing[4]}
      >
        <Clay>
          <Typography
            numOfLine={1}
            variant="body-medium"
            sx={{ display: 'block' }} //display 속성이 -webkit-box인 경우 numOfLine이 동작하지 않는 이슈
          >
            {fileDownloadUrl?.fileName ?? ''}
          </Typography>
          <Typography colorToken="textSub" variant="body-medium">
            {fDate(new Date(wtime), 'yyyy-MM-dd HH:mm')}
          </Typography>
        </Clay>

        <IconButton
          native={{ type: 'button' }}
          onClick={onClickDownload}
          variant="secondary"
          icon={<Download01 />}
          aria-label={__('파일 다운로드')}
          size="small"
        />
      </Clay>
    </Clay>
  )
}
