import { Getter, Setter, atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import React from 'react'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { pipe, flow } from 'fp-ts/function'
import deepEqual from 'fast-deep-equal'
import type { TOrder } from '../class-order.type'

export const OrderAddAtom = atom<TOrder | undefined>(undefined)
OrderAddAtom.debugLabel = 'OrderAddAtom'

export type TOrderAddConfirmItems = {
  orderSectionItemCode?: string
  prodCode: string
  prodOptionDetailCode?: string
  qty?: number
  baseItemPrice: number
  itemPrice: number
  isIndividualOption?: 'Y' | 'N' | string
  isRequireOption?: 'Y' | 'N' | string
  optionDataList?: {
    optionType: string
    optionCode: string
    valueCode: string
  }[]
  [key: string]: unknown
}

export type TOrderAddConfirm = {
  orderSectionCode: string
  prods: TOrderAddConfirmItems[]
}

const submitConfirmState = atom<TOrderAddConfirm[] | undefined>(undefined)
submitConfirmState.debugLabel = 'submitConfirmState'
const OrderAddConfirmState = atom<TOrderAddConfirm[] | undefined>(undefined)
OrderAddConfirmState.debugLabel = 'OrderAddConfirmAtom'
const OrderAddConfirmSectionState = atomFamily(
  ({
    orderCode,
    sectionCode,
  }: {
    orderCode: string
    sectionCode: string
    values?: TOrderAddConfirm[]
  }) => {
    const at = atom(
      (get) => {
        const confirmState = get(OrderAddConfirmState)
        const result = pipe(
          confirmState,
          O.fromNullable,
          O.chainNullableK((e) =>
            e.find((t) => t.orderSectionCode === sectionCode)
          ),
          O.fold(
            () => undefined,
            (e) => e
          )
        )
        return result
      },
      // arg에서 string을 받는 이유는 이때 액션을 넘겨주기위해서
      (get, set, arg?: TOrderAddConfirmItems[]) => {
        if (arg === undefined) {
          return
        }
        const confirmState = get(OrderAddConfirmState)
        // 만약 해당섹션의 데이터가있다면?
        // 이미 검색으로 추가를 한상태에서 더 추가하려면?
        // 이미있는 상품과 대조해서 추가해야한다.
        const isDuplicate = pipe(
          confirmState,
          O.fromNullable,
          O.fold(
            () => false,
            flow(
              A.findFirst((e) => e.orderSectionCode === sectionCode),
              O.fold(
                () => false,
                () => true
              )
            )
          )
        )
        // 이미 데이터에 추가된상태라면 덮어띄워야한다.
        if (isDuplicate && confirmState) {
          set(
            OrderAddConfirmState,
            pipe(
              confirmState,
              A.map((e) => {
                if (e.orderSectionCode === sectionCode) {
                  return {
                    ...e,
                    prods: [...e.prods, ...arg],
                  }
                }
                return e
              })
            )
          )
        } else {
          set(OrderAddConfirmState, [
            ...(confirmState || []),
            {
              orderSectionCode: sectionCode,
              prods: arg,
            },
          ])
        }
      }
    )
    at.debugLabel = `OrderAddConfirmAtom(${orderCode})\nsection(${sectionCode})`
    return at
  },
  (a, b) => a.orderCode === b.orderCode && a.sectionCode === b.sectionCode
)
const OrderAddConfirmSectionDeleteState = atomFamily(
  ({
    orderCode,
    sectionCode,
  }: {
    orderCode: string
    sectionCode: string
    values?: TOrderAddConfirm[]
  }) => {
    const at = atom(
      (get) => {
        const confirmState = get(OrderAddConfirmState)
        const result = pipe(
          confirmState,
          O.fromNullable,
          O.chainNullableK((e) =>
            e.find((t) => t.orderSectionCode === sectionCode)
          ),
          O.fold(
            () => undefined,
            (e) => e
          )
        )
        return result
      },
      (get, set, arg?: TOrderAddConfirmItems[]) => {
        const confirmState = get(OrderAddConfirmState)
        if (arg === undefined) {
          set(
            OrderAddConfirmState,
            pipe(
              [...(confirmState || [])],
              A.filter((e) => e.orderSectionCode !== sectionCode)
            )
          )
          return
        }
        // 받아온 arg를 통해서 해당 섹션의 데이터를 삭제해야한다.
        const sectionConfirmData = pipe(
          [...(confirmState || [])],
          A.filter((e) => e.orderSectionCode === sectionCode),

          (e) => (e.length > 0 ? e : undefined),
          O.fromNullable,
          O.fold(
            () => [],
            flow(
              A.map((e) => ({
                ...e,
                prods: pipe(
                  e.prods,
                  // arg의 데이터와 비교해서 필터링해야한다.
                  A.filter((t) => {
                    const isDelete = pipe(
                      arg,
                      A.findFirst((y) => {
                        if (y.optionDataList) {
                          return deepEqual(y.optionDataList, t.optionDataList)
                        }
                        return deepEqual(y, t)
                      }),
                      O.fold(
                        () => false,
                        () => true
                      )
                    )
                    return !isDelete
                  })
                ),
              }))
            )
          )
        )
        set(
          OrderAddConfirmState,
          pipe(
            [...(confirmState || [])],
            A.filter((e) => e.orderSectionCode !== sectionCode),
            A.concat(sectionConfirmData)
          )
        )
      }
    )
    at.debugLabel = `OrderAddConfirmAtom(${orderCode})\nsection(${sectionCode})\ndelete`
    return at
  },
  (a, b) => a.orderCode === b.orderCode && a.sectionCode === b.sectionCode
)

export const useOrderAddConfirmAtom = {
  submit: () => useAtom(submitConfirmState),
  confirm: () => useAtomValue(OrderAddConfirmState),
  section: (orderCode: string, sectionCode: string) =>
    useAtom(
      React.useMemo(
        () => OrderAddConfirmSectionState({ orderCode, sectionCode }),
        [orderCode, sectionCode]
      )
    ),
  delete: (orderCode: string, sectionCode: string) =>
    useAtom(
      React.useMemo(
        () => OrderAddConfirmSectionDeleteState({ orderCode, sectionCode }),
        [orderCode, sectionCode]
      )
    ),
}
