import React from 'react'
import { flow, pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import IconGrayShopping from './assets/grim-icon-gray-shopping.svg?react'
import { TProductCard001 } from '../../product'
import { OrderSection } from '../../order-section/order-section'
import { OrderEditAddSectionRowContainer } from './order-edit-add-section-row'
import { OrderEditAddSectionHeaderMemo } from './order-edit-add-section-header'
import { __ } from '~/shared/i18n'

const OrderEditAddSectionRowContainerMemo = React.memo(
  OrderEditAddSectionRowContainer
)

export interface OrderEditAddSectionProps {
  id: string
  label: string
  title: string
  items?: TProductCard001.props[]
  onReset?: () => void
  onSearchModal?: () => void
  deliveryName?: string
  deliveryNumber?: string | React.ReactNode
  onDeleteItem?: (item: TProductCard001.props) => void
}
export const OrderEditAddSection = ({
  id,
  label,
  title,
  items,
  onReset,
  onSearchModal,
  deliveryName,
  deliveryNumber,
  onDeleteItem,
}: OrderEditAddSectionProps) => {
  const count = items?.length ?? 0

  return (
    <OrderSection name={title} data-id={id}>
      <OrderSection.Slot name="title">
        <OrderEditAddSectionHeaderMemo
          label={label === 'new' ? undefined : `#${label}`}
          title={title}
          count={count}
          onReset={onReset}
          onSearchModal={onSearchModal}
          deliveryName={deliveryName}
          deliveryNumber={deliveryNumber}
          sectionId={id}
        />
      </OrderSection.Slot>
      <OrderSection.Slot name="body" className="mx-0 [--mx:24px] mt-[24px]">
        {/* 받아온 아이템이있으면 먼저 렌더링한다. */}
        {pipe(
          items,
          // undefined이거나 length가 0일때를 가져와야한다
          (e) => {
            const result = e && e.length === 0 ? undefined : e
            return result
          },
          O.fromNullable,
          O.fold(
            // undefined, null일때
            () => (
              <div className="text-center pb-[24px]">
                <div className="flex justify-center items-center">
                  <IconGrayShopping />
                </div>
                <div className="mt-[8px] text-clay-body-medium text-clay-semantic-text">
                  {__('상품을 추가해주세요')}
                </div>
                <div className="mt-[8px] typo-clay-body-small text-clay-semantic-textSub">
                  {__('상품추가 버튼을 눌러 추가할 상품을 선택해주세요')}
                </div>
              </div>
            ),
            flow((_items) => (
              <div>
                {pipe(
                  _items,
                  A.map((item) => (
                    <div
                      key={`index-${item.id}`}
                      className="odd:bg-clay-semantic-layerSub"
                    >
                      <OrderEditAddSectionRowContainerMemo
                        id={item.id}
                        sectionId={id}
                        onDeleteItem={onDeleteItem}
                      />
                    </div>
                  ))
                )}
              </div>
            ))
          )
        )}
      </OrderSection.Slot>
    </OrderSection>
  )
}
