/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import {
  YN,
  상수_판매채널,
  상수_결제수단,
  상수_통화코드,
  상수_주문상태,
  상수_섹션상태,
  상수_결제상태,
  상수_CS상태,
  상수_섹션상태통합,
} from '~/entities/@x'
import {
  cashReceiptInfoData,
  externalChannelOrderInfoDataDto,
  orderSectionDto,
  selectPaymentForOrderDto,
  deliveryDto,
} from '~/entities/@dto'

export const orderItemDto = z.object({
  unitCode: z.string().describe('주문서 유닛 코드 (글로벌사이트 구분)'),
  orderNo: z.number().describe('주문 번호 (노출용)'),
  orderCode: z.string().describe('주문 코드 (내부용)'),
  memberCode: z.string().describe('멤버 코드'),
  isMember: z.nativeEnum(YN).describe('회원여부'),
  isRequestPayment: z.nativeEnum(YN).describe('추가 결제 요청 여부'),
  orderDate: z.string().describe('주문 일자'),
  saleChannelIdx: z.nativeEnum(상수_판매채널).describe('판매채널번호'),
  saleChannelName: z.string().describe('판매 채널 이름'),
  saleChannelImageUrl: z.string().describe('판매 채널 이미지 경로'),
  externalChannelOrderInfo: externalChannelOrderInfoDataDto
    .nullish()
    .describe('외부 채널 주문 정보'),
  paymentDate: z.string().describe('결제 일자 (여러건인 경우 최초 결제일)'),
  pgMethodCd: z.nativeEnum(상수_결제수단).describe('결제 수단 코드'),
  ordererName: z.string().describe('주문자 명'),
  currency: z.nativeEnum(상수_통화코드).describe('통화구분(KRW, USD, JPY등)'),
  paymentPrice: z.number().describe('주문 금액'),
  totalOrderItemQty: z
    .number()
    .describe('총 구매수량(주문하위 아이템의 수량 합계)'),
  totalPgAmount: z.number().describe('최종 실결제금액'),
  orderStatusCd: z.nativeEnum(상수_주문상태).describe('거래상태 코드'),
  sectionStatusCd: z
    .union([z.nativeEnum(상수_섹션상태), z.nativeEnum(상수_섹션상태통합)])
    .describe('주문상태 코드'),
  paymentStatusCd: z.nativeEnum(상수_결제상태).describe('결제 상태 코드'),
  csStatusCd: z.nativeEnum(상수_CS상태).describe('CS 상태 코드'),
  invoiceNo: z.string().describe('송장번호'),
  memoCount: z.number().describe('메모'),
  deliveryType: z.string().optional().describe('배송타입'),
  isGift: z.nativeEnum(YN).describe('선물하기 주문 여부'),
  isFirst: z.nativeEnum(YN).describe('첫 주문 여부'),
  unipassNumber: z.string().nullable().describe('개인 통관 고유 부호'),
  paymentList: selectPaymentForOrderDto
    .omit({ pgName: true, pgMethodCd: true })
    .array()
    .optional(),
  cashReceiptInfo: cashReceiptInfoData.nullable(),
  orderSectionList: orderSectionDto
    .pick({
      siteCode: true,
      orderSectionCode: true,
      statusCd: true,
      isDeliveryHold: true,
      deliveryTypeCd: true,
      invoiceCode: true,
      mtime: true,
      orderSectionItemList: true,
    })
    .array(),
  orderDeliveryList: deliveryDto.array(),
})

/**
 * get orders
 */
export const getResOrderListDto = z.object({
  total: z.number(),
  totalPage: z.number(),
  page: z.number(),
  list: orderItemDto.array(),
})

export const getPayloadOrderListDto = z.object({
  order: z.enum(['desc', 'asc']).optional().describe('최신순 여부'),
  page: z
    .number()
    .int()
    .positive()
    .optional()
    .describe('페이지 번호 (1부터 시작), 기본값 1'),
  keyword: z.string().nullish().describe('검색 키워드 (주문번호, 주문자 등)'),
  sort: z
    .enum([
      'wtime',
      'orderNo',
      'orderDate',
      'paymentDate',
      'ordererName',
      'orderItemCount',
      'pgAmount',
    ])
    .optional()
    .describe('정렬 필드명'),
  pageSize: z
    .number()
    .int()
    .min(1)
    .max(100)
    .optional()
    .describe('페이지 사이즈 (1~100), 기본값: 50'),
  orderStatusCd: z
    .nativeEnum(상수_주문상태)
    .array()
    .optional()
    .describe('주문 상태'),
})
