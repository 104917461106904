import { Clay, ClayProps } from '@imwebme/clay-components'
import skyRing from '~/container/onboarding/assets/sky-ring.gif'

interface Props extends ClayProps<'div'> {}

export function SkyRing(props: Props) {
  return (
    <Clay
      position="absolute"
      transform="translate(-50%, -50%)"
      top="50%"
      left="50%"
      width={56}
      height={56}
      pointerEvents="none"
      {...props}
    >
      <img src={skyRing} alt="highlight" />
    </Clay>
  )
}
