import { TSelctData } from '../order-seasrch-tab.hook'
import { NameField } from './name-field'
import { Button, ButtonGroup, Flex } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SelectField } from './select-field'
import { __ } from '~/shared/i18n'

interface Props {
  selectDataList: TSelctData[]
  handleCancel: () => void
  isLoading: boolean
  isDisabled: boolean
}

export function OrderSearchTabForm({
  selectDataList,
  handleCancel,
  isLoading,
  isDisabled,
}: Props) {
  return (
    <>
      <NameField />
      <Flex
        flexWrap="wrap"
        columnGap={vars.spacing[2]}
        rowGap={vars.spacing[5]}
        alignItems="flex-start"
        marginBottom={vars.spacing[3]}
        paddingInline={vars.spacing[1]}
      >
        {selectDataList.map((selectData, idx) => (
          <SelectField key={idx} {...selectData} />
        ))}
      </Flex>

      <ButtonGroup size="small" sx={{ justifyContent: 'flex-end' }}>
        <Button
          text={__('취소')}
          variant="secondary"
          native={{ type: 'button' }}
          onClick={handleCancel}
        />
        <Button
          text={__('저장')}
          variant="primary"
          native={{ type: 'submit' }}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </ButtonGroup>
    </>
  )
}
