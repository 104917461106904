import { z } from 'zod'

export const postResCancelRejectRequestDto = z.any()

export const postPayloadCancelRejectRequestDto = z.object({
  orderSectionCode: z.string(),
  orderSectionItemCodes: z.array(z.string()),
  parcelCompanyIdx: z.number().nullish(),
  invoiceNo: z.string().nullish(),
})
