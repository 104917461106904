/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { 상수_환불금액_타입 } from '~/entities/@x'
import { ApiResponseSchema } from '~/shared/api'

/**
 * patch cancel-complete
 */
export const patchResCancelCompleteDto = ApiResponseSchema
export const patchPayloadCancelCompleteDto = z.object({
  refundPriceTypeCd: z.nativeEnum(상수_환불금액_타입),
  etcPriceReason: z.string().optional(),
  requestRefundPrice: z.number(),
  deliveryExtraPrice: z.number(),
  refundPoint: z.number(),
  returnedCoupons: z.array(z.string()),
  deliveryExtraPriceType: z.union([z.literal('ADD'), z.literal('SUB')]),
  targetItemInformation: z.array(
    z.object({
      orderSectionItemCode: z.string(),
      qty: z.number(),
    })
  ),
})
