import { __ } from '~/shared/i18n'
import {
  CDialogBulkStatus,
  isFailed,
  type TDialogBulkStatus,
} from './dialog-bulk-status.calc'
import { Button } from '@imwebme/clay-components'
import { Dialog } from '~/shared/components/ui/dialog'
import { ContentsBanner } from '@imwebme/clay-components'

interface TDialogBulkStatusDirection {
  nextStatus: string
  prevStatus: string
}

interface TDialogBulkHeader
  extends TDialogBulkStatus,
    TDialogBulkStatusDirection {}
export const DialogBulkStatusHeader = ({
  nextStatus,
  // prevStatus,
  fulfilled,
  rejected,
}: TDialogBulkHeader) => {
  const dpcc = new CDialogBulkStatus({ fulfilled, rejected })
  if (isFailed.isSatisfiedBy(dpcc)) {
    return (
      <span>
        {__('{{섹션_변경상태}} 처리할 수 없어요', {
          섹션_변경상태: nextStatus,
        })}
      </span>
    )
  }
  return (
    <span>
      {__('{{처리가능주문건수}}건을 {{섹션_변경상태}} 처리할까요?', {
        처리가능주문건수: fulfilled,
        섹션_변경상태: nextStatus,
      })}
    </span>
  )
}

interface TDialogBulkBanner
  extends TDialogBulkStatus,
    TDialogBulkStatusDirection {}
export const DialogBulkStatusBanner = ({
  nextStatus,
  prevStatus,
  fulfilled,
  rejected,
}: TDialogBulkBanner) => {
  const dpcc = new CDialogBulkStatus({ fulfilled, rejected })
  if (isFailed.isSatisfiedBy(dpcc)) {
    return (
      <ContentsBanner
        variant="critical"
        type="secondary"
        text={__('{{섹션_변경상태}} 처리할 수 없어요', {
          섹션_변경상태: nextStatus,
        })}
      />
    )
  }
  if (rejected === 0) {
    return (
      <div className="semantic-p14 text-clay-semantic-textSub">
        {__('선택한 주문의 상태를 한번에 변경할 수 있어요.')}
      </div>
    )
  }
  return (
    <ContentsBanner
      variant="warning"
      type="secondary"
      text={__(
        '{{변경상태_직전섹션}}가 아닌 {{미처리주문수}}건은 처리되지 않아요.',
        {
          미처리주문수: rejected,
          변경상태_직전섹션: prevStatus,
        }
      )}
    />
  )
}

interface TDialogBulkButtons
  extends TDialogBulkStatus,
    TDialogBulkStatusDirection {
  onSubmit: () => void
}
export const DialogBulkStatusButtons = ({
  nextStatus,
  fulfilled,
  rejected,
  onSubmit,
}: TDialogBulkButtons) => {
  const dpcc = new CDialogBulkStatus({ fulfilled, rejected })
  if (isFailed.isSatisfiedBy(dpcc)) {
    return (
      <div className="w-full flex justify-end gap-x-[8px] pt-[12px]">
        <Dialog.Close asChild>
          <Button
            native={{ type: 'button' }}
            variant="outlined"
            text={__('닫기')}
          />
        </Dialog.Close>
      </div>
    )
  }

  return (
    <div className="w-full flex justify-end gap-x-[8px] pt-[12px]">
      <Dialog.Close asChild>
        <Button
          native={{ type: 'button' }}
          variant="outlined"
          text={__('취소')}
        />
      </Dialog.Close>
      <Button
        native={{ type: 'button' }}
        variant="primary"
        onClick={() => onSubmit()}
        text={__('{{처리_상태명}} 처리', {
          처리_상태명: nextStatus,
        })}
      />
    </div>
  )
}
