import React from 'react'
import { IconDotsVertical, IconMuTruck } from '@imwebme/icon'

export function OrderSectionSkeleton(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  return (
    <div
      className="border h-auto items-center py-[24px] bg-white grid w-full p-0 rounded-[12px] border-none"
      {...props}
    >
      <div className="h-[24px]" />
      <div className="px-[24px] flex justify-between">
        <div className="skeleton w-[100px]" />
        <div className="p-[16px]">
          <IconDotsVertical />
        </div>
      </div>
      <div className="h-[24px]" />
      <div className="flex items-center gap-[10px] px-[24px]">
        <IconMuTruck />
        <div className="skeleton w-[84px]" />
      </div>
      <div className="h-[20px]" />
      <div className="flex justify-between">
        <div className="flex flex-col gap-[8px] p-[16px 24px]">
          <div className="skeleton w-[180px]" />
          <div className="skeleton w-[130px]" />
        </div>
        <div className="flex gap-[12px] p-[16px 24px]">
          <div className="skeleton w-[102px]" />
          <div className="skeleton w-[140px]" />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-[8px] p-[16px 24px]">
          <div className="skeleton w-[180px]" />
          <div className="skeleton w-[130px]" />
        </div>
        <div className="flex gap-[12px] p-[16px 24px]">
          <div className="skeleton w-[102px]" />
          <div className="skeleton w-[140px]" />
        </div>
      </div>
      <div className="h-[24px]" />
    </div>
  )
}
