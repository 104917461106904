import React from 'react'
import { Button, Flex, Textfield } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SearchSM } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { toast } from 'react-toastify'
import { useModals } from '~/router'
import { useSelectedProdCommons, useOrderInfoAtom } from '../order-create.store'
import {
  useProductAddModalAtom,
  useResetProductAddModalAtom,
  TProdAddResult,
} from '~/widgets/product-add-modal'
import { converter } from './prod-select.fn'
import { useProdSelectAtom } from '../order-create.store'

export function SearchBar() {
  const [orderInfo] = useOrderInfoAtom()
  const [_prodSelect, setProdSelect] = useProdSelectAtom()
  const setProductAddModal = useProductAddModalAtom.set()
  const resetProductAddModalAtom = useResetProductAddModalAtom()
  const selectedProdCommons = useSelectedProdCommons()
  const modals = useModals()

  const openProductAddModal = React.useCallback(() => {
    resetProductAddModalAtom()

    setProductAddModal({
      handleAddProduct: (result: TProdAddResult) => {
        toast.success(__('상품을 추가했어요'))
        setProdSelect({ type: 'add', payload: converter(result) })
      },
      currency: orderInfo.unitSite?.currency || 'KRW',
      memberCode: orderInfo.memberCode || undefined,
      unitCode: orderInfo.unitSite?.code || undefined,
      includeDeliveryInfo: true,
      ...selectedProdCommons,
    })
    modals.open('/product/add')
  }, [orderInfo, selectedProdCommons])

  return (
    <Flex
      flex={1}
      columnGap={vars.spacing[25]}
      paddingInline={vars.spacing[6]}
      marginBottom={vars.spacing[5]}
    >
      <Textfield.Box variant="filled" size="medium">
        <Textfield.Addon>
          <SearchSM colorToken="icon-sub" />
        </Textfield.Addon>
        <Textfield.Input
          placeholder={__('상품을 검색해 보세요')}
          type="search"
          onFocus={openProductAddModal}
        />
      </Textfield.Box>
      <Button
        variant="outlined"
        text={__('찾아보기')}
        onClick={() => openProductAddModal()}
        sx={{ flexShrink: 0 }}
      />
    </Flex>
  )
}
