import { z } from 'zod'

export const 상수_주문상태 = {
  이탈결제: 'OOS01', // 이탈 결제
  매칭대기: 'OOS02', // 매칭대기
  거래개시: 'OOS03', // 거래개시
  거래종료: 'OOS04', // 거래종료
  주문시작: 'OOS05', // 주문 시작
  이탈주문: 'OOS06', // 이탈주문
  거래대기: 'OOS07', // 거래대기
} as const

const type = z.nativeEnum(상수_주문상태)
export type T주문상태 = z.infer<typeof type>
