import { queryOptions } from '@tanstack/react-query'
import {
  TPostPayloadBulkActionSectionInvoicePrintValidateSchema,
  postBulkActionSectionInvoicePrintValidate,
} from '.'

export function bulkActionSectionInvoicePrintValidateQueryOptions(
  p: TPostPayloadBulkActionSectionInvoicePrintValidateSchema['targetOrderCodes']
) {
  return queryOptions({
    queryKey: ['bulk-action', 'section-invoice-print-validate'],
    queryFn: () =>
      postBulkActionSectionInvoicePrintValidate({
        body: {
          targetOrderCodes: p,
        },
      }),
  })
}
