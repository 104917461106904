import { ApiClient, type ApiResponse } from '~/shared/api'
import type {
  CouponResponseDto,
  FilterReqDto,
} from './order-search-filter.type'

const axios = ApiClient()

export const postOrderSearchFilter = async ({
  tabCode,
  params,
}: {
  tabCode: string
  params: FilterReqDto
}) => {
  const { data } = await axios.post<ApiResponse<{ idx: number }>>(
    `/order/search/tabs/${tabCode}/filters`,
    params
  )
  return data.data
}

export const patchOrderSearchFilter = async ({
  tabCode,
  filterIdx,
  params,
}: {
  tabCode: string
  filterIdx: number
  params: FilterReqDto
}) => {
  const { data } = await axios.patch<ApiResponse<number>>(
    `/order/search/tabs/${tabCode}/filters/${filterIdx}`,
    params
  )
  return data.data
}

export const deleteOrderSearchFilter = async ({
  tabCode,
  filterIdx,
}: {
  tabCode: string
  filterIdx: number
}) => {
  const { data } = await axios.delete<ApiResponse<number>>(
    `/order/search/tabs/${tabCode}/filters/${filterIdx}`
  )
  return data.data
}

export const getCoupons = async ({
  query,
  cursor,
}: {
  query?: string
  cursor?: string
}): Promise<{
  list: CouponResponseDto[]
  cursorForNext: string
  cursorForPrev: string
}> => {
  const r = await axios
    .get('/coupon', {
      params: {
        direction: 'next',
        count: 50,
        keyword: query === '' ? undefined : query,
        cursor,
      },
    })
    .then((res) => res.data)
  if (r.statusCode === 200) {
    return r.data
  }
  throw new Error(r.message)
}
