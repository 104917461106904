import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'

/**
 * patch order-section-delivery
 */
export const patchResOrderSectionDeliveryDto = ApiResponseSchema
export const patchPayloadOrderSectionDeliveryDto = z.object({
  orderDeliveryCode: z.string(),
})
