import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_송장배치 } from '.'
import { 상수_액셀배치상태 } from '~/entities/@x'

export const 모두성공: ISpecification<모델_송장배치> = new Spec(
  (candidate) =>
    (candidate.statusCd === 상수_액셀배치상태.업로드완료 ||
      candidate.statusCd === 상수_액셀배치상태.배치처리완료) &&
    candidate.failureCnt === 0
)

export const 모두실패: ISpecification<모델_송장배치> = new Spec(
  (candidate) =>
    (candidate.statusCd === 상수_액셀배치상태.업로드완료 ||
      candidate.statusCd === 상수_액셀배치상태.배치처리완료) &&
    candidate.successCnt === 0 &&
    candidate.totalCnt === candidate.failureCnt
)

export const 부분실패: ISpecification<모델_송장배치> = new Spec(
  (candidate) =>
    (candidate.statusCd === 상수_액셀배치상태.업로드완료 ||
      candidate.statusCd === 상수_액셀배치상태.배치처리완료) &&
    candidate.failureCnt > 0
)

export const 업로드실패: ISpecification<모델_송장배치> = new Spec(
  (candidate) =>
    candidate.statusCd === 상수_액셀배치상태.업로드실패 ||
    candidate.statusCd === 상수_액셀배치상태.배치실패
)

export const 잘못된형식: ISpecification<모델_송장배치> = new Spec(
  (candidate) => candidate.statusCd === 상수_액셀배치상태.잘못된형식
)
