import { TGetResProducttListItemDto } from '~/entities/product/product-list'
import {
  useProductAddModalAtom,
  useSelectedProdAtom,
} from '~/widgets/product-add-modal'
import { genUniqueId } from '~/widgets/product-add-modal/product-add-modal.fn'
import { Checkbox, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { FormattedPrice } from '../formatted-price'
import { __ } from '~/shared/i18n'

interface Props {
  prod: TGetResProducttListItemDto
}

/**
 * 비필수 옵션 (본상품에 부가되는 옵션)
 */
export function NonRequiredOption({ prod }: Props) {
  const [selectedProd, setSelectedProd] = useSelectedProdAtom(prod.prodCode)
  const modalData = useProductAddModalAtom.get()

  // 비필수 옵션은 필수 옵션이 선택된 경우에만 선택 가능
  const 선택된필수옵션이존재하는가 =
    selectedProd.체크박스옵션?.length > 0 ||
    selectedProd.사용자정의옵션?.length > 0

  return (
    <>
      {prod.options?.map((option) => {
        if (option.isRequiredOption) {
          return null
        }
        return option.values?.map((value) => {
          const id = genUniqueId({
            prodCode: prod.prodCode,
            optionDetailCode: value.optionDetailCode,
          })

          return (
            <Flex
              key={`${id}-${option.optionCode}`}
              alignItems="center"
              justifyContent="space-between"
              columnGap={vars.spacing[4]}
              padding={`${vars.spacing[15]} 0`}
              marginTop={vars.spacing[3]}
            >
              <Checkbox.Label
                disabled={
                  !선택된필수옵션이존재하는가 || modalData.idList?.includes(id)
                }
                checked={!!selectedProd?.비필수옵션?.includes(id)}
                onChangeChecked={(v: boolean) => {
                  if (v) {
                    setSelectedProd({
                      action: 'add',
                      type: '비필수옵션',
                      payload: {
                        optionDetailCode: value.optionDetailCode,
                        options: [
                          {
                            label: `${option.optionName} ${value.valueName}`,
                            price: value.price ?? 0,
                          },
                        ],
                        prod,
                      },
                    })
                  } else {
                    setSelectedProd({
                      action: 'remove',
                      type: '비필수옵션',
                      payload: id,
                    })
                  }
                }}
                label={__('(선택) {{상품옵션명}} {{상품옵션값}}', {
                  상품옵션명: option.optionName,
                  상품옵션값: value.valueName,
                })}
              />
              <Typography variant="body-medium" whiteSpace="nowrap">
                <FormattedPrice price={value.price ?? 0} />
              </Typography>
            </Flex>
          )
        })
      })}

      {prod.optionDetails?.map((optionDetail) => {
        if (optionDetail.isRequiredOption) {
          return null
        }
        return optionDetail.options.map((option) => {
          const id = genUniqueId({
            prodCode: prod.prodCode,
            optionDetailCode: optionDetail.optionDetailCode,
          })

          return (
            <Flex
              key={`${id}-${option.optionCode}`}
              alignItems="center"
              justifyContent="space-between"
              columnGap={vars.spacing[4]}
              padding={`${vars.spacing[15]} 0`}
              marginTop={vars.spacing[3]}
            >
              <Checkbox.Label
                disabled={
                  !선택된필수옵션이존재하는가 || modalData.idList?.includes(id)
                }
                checked={!!selectedProd?.비필수옵션?.includes(id)}
                onChangeChecked={(v: boolean) => {
                  if (v) {
                    setSelectedProd({
                      action: 'add',
                      type: '비필수옵션',
                      payload: {
                        optionDetailCode: optionDetail.optionDetailCode,
                        options: [
                          {
                            label: `${option.optionName} ${option.valueName}`,
                            price: optionDetail.optionDetailPrice,
                          },
                        ],
                        prod,
                      },
                    })
                  } else {
                    setSelectedProd({
                      action: 'remove',
                      type: '비필수옵션',
                      payload: id,
                    })
                  }
                }}
                label={__('(선택) {{상품옵션명}} {{상품옵션값}}', {
                  상품옵션명: option.optionName,
                  상품옵션값: option.valueName,
                })}
              />
              <Typography variant="body-medium" whiteSpace="nowrap">
                <FormattedPrice price={optionDetail.optionDetailPrice} />
              </Typography>
            </Flex>
          )
        })
      })}
    </>
  )
}
