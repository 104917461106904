import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import * as Popover from '@radix-ui/react-popover'
import {
  Flex,
  OptionItemComposition,
  OptionList,
  Typography,
} from '@imwebme/clay-components'
import { ChevronDown } from '@imwebme/clay-icons'
import { vars } from '@imwebme/clay-token'
import { 상수_언어코드 } from '~/entities/@x'
import {
  siteUnitQueryOptions,
  TGetResSiteUnitSchema,
} from '~/entities/site/unit'
import { ImgByLangCode } from '~/shared/components/img-by-lang-code'
import { FieldLayout } from '../partials/field-layout'
import { __ } from '~/shared/i18n'
import { useOrderInfoAtom } from '../order-create.store'

/** 주문 사이트 필드 */
export function UnitSiteField() {
  const [open, setOpen] = React.useState(false)
  const [orderInfo, setOrderInfo] = useOrderInfoAtom()

  const accessedUnitSiteCode: string | undefined =
    ImwebOAuth2ClientAPI.getInfo().unitCode

  const { data: unitSiteList } = useSuspenseQuery({
    ...siteUnitQueryOptions(),
    select: (e) => e.list,
  })

  const selectedUnitSite: TGetResSiteUnitSchema['list'][number] | undefined =
    unitSiteList.find((el) => el.code === orderInfo.unitSite?.code)

  // 초기값 설정
  React.useEffect(() => {
    if (!orderInfo.unitSite) {
      const mainSite =
        unitSiteList.find((el) => el.code === accessedUnitSiteCode) ??
        unitSiteList.find((el) => el.langCode === 상수_언어코드.KR) ??
        unitSiteList[0]

      setOrderInfo({ unitSite: mainSite })
    }
  }, [unitSiteList, accessedUnitSiteCode])

  if (unitSiteList.length === 1) {
    return null
  }
  return (
    <FieldLayout title={__('주문 사이트')}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger style={{ width: '441px' }}>
          <Flex
            alignItems="center"
            padding={`${vars.spacing[25]} ${vars.spacing[3]}`}
            outline={
              open
                ? `2px solid ${vars.semantic.color.focus}`
                : `1px solid ${vars.semantic.color.border}`
            }
            borderRadius={vars.rounded.medium}
          >
            <ImgByLangCode langCode={selectedUnitSite?.langCode} />
            <Flex.Column
              rowGap={vars.spacing['05']}
              flex={1}
              marginLeft={vars.spacing[3]}
              marginRight={vars.spacing[2]}
            >
              <Typography variant="body-medium-bold">
                {selectedUnitSite?.name}
              </Typography>
              <Typography variant="body-small">
                {selectedUnitSite?.mainDomain}
              </Typography>
            </Flex.Column>
            <ChevronDown rotate={open ? 180 : 0} style={{ flexShrink: 0 }} />
          </Flex>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align="start"
            sideOffset={8}
            style={{ width: '441px' }}
          >
            <OptionList popOver value={selectedUnitSite?.code}>
              {unitSiteList.map((site) => (
                <OptionItemComposition
                  key={site.code}
                  value={site.code}
                  onClick={() => {
                    setOrderInfo({ unitSite: site })
                    setOpen(false)
                  }}
                >
                  <Flex
                    columnGap={vars.spacing[3]}
                    alignItems="center"
                    padding={`${vars.spacing[2]} 0`}
                  >
                    <ImgByLangCode langCode={site.langCode} />
                    <Flex.Column rowGap={vars.spacing['05']}>
                      <Typography variant="body-medium-bold">
                        {site.name}
                      </Typography>
                      <Typography variant="body-small">
                        {site.mainDomain}
                      </Typography>
                    </Flex.Column>
                  </Flex>
                </OptionItemComposition>
              ))}
            </OptionList>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </FieldLayout>
  )
}
