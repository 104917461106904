import { z } from 'zod'
import {
  YN,
  상수_배송결제타입,
  상수_배송타입,
  상수_상품상태,
  상수_상품옵션타입,
  상수_상품타입,
  상수_상품품절상태,
  상수_주문타입,
} from '~/entities/@x'

const productOptionValueData = z.object({
  valueCode: z.string().describe('옵션 세트의 사용 가능한 값 코드'),
  valueName: z.string().describe('옵션 세트의 사용 가능한 값 이름 ex 화이트'),
  price: z
    .number()
    .nullish()
    .describe('옵션 세트의 사용 가능한 값 가격(비조합형 옵션인 경우에만 존재)'),
  optionDetailCode: z
    .string()
    .nullish()
    .describe('`shop_prod_option_detail.code` 값입니다.'),
})

const prodOptionSetData = z.object({
  isRequiredOption: z.boolean().describe('필수 옵션 여부'),
  isAggregated: z.boolean().describe('조합 옵션 포함 여부'),
  type: z
    .nativeEnum(상수_상품옵션타입)
    .describe('옵션 세트의 선택형, 컬러, 입력형 등의 옵션 타입'),
  optionCode: z.string().describe('옵션 세트의 코드'),
  optionName: z.string().describe('옵션 세트의 이름 ex 컬러'),
  values: z
    .array(productOptionValueData)
    .nullish()
    .describe(
      '옵션 세트의 사용 가능한 값 목록(옵션 타입이 입력형이 아닌 경우에만 존재)'
    ),
})

const productOptionDetailOptionData = z.object({
  isRequiredOption: z.boolean().describe('필수 옵션 여부'),
  type: z
    .nativeEnum(상수_상품옵션타입)
    .describe('옵션 세트의 선택형, 컬러, 입력형 등의 옵션 타입'),
  optionCode: z.string().describe('옵션 세트의 코드'),
  optionName: z.string().describe('옵션 세트의 이름'),
  valueCode: z.string().describe('옵션 세트의 사용 가능한 값 코드'),
  valueName: z.string().describe('옵션 세트의 사용 가능한 값 이름'),
})

const productOptionDetailData = z.object({
  optionDetailCode: z
    .string()
    .describe('`shop_prod_option_detail.code` 값입니다.'),
  optionDetailPrice: z.number().describe('조합된 옵션의 가격입니다.'),
  isRequiredOption: z.boolean().describe('필수 옵션 여부입니다.'),
  options: z
    .array(productOptionDetailOptionData)
    .describe('조합된 옵션을 이루고 있는 옵션 세트 목록입니다.'),
})

export const findProductsApiProductData = z.object({
  cursor: z
    .string()
    .describe("왼쪽으로 '0'을 채운 10자리의 `shop_prod.idx` 값입니다."),
  prodCode: z.string().describe('상품고유코드'),
  prodStatus: z.nativeEnum(상수_상품상태).describe('상품 상태 코드'),
  prodSoldoutStatus: z
    .nativeEnum(상수_상품품절상태)
    .describe('상품 품절 상태 코드'),
  type: z.nativeEnum(상수_상품타입).describe('상품 종류'),
  isIndividualOption: z
    .boolean()
    .describe(
      '조합형 옵션 비활성 여부입니다.\n- `true`: 조합형 옵션 비활성\n- `false`: 조합형 옵션 활성'
    ),
  imageUrls: z.string().array().optional().describe('이미지 URL 리스트'),
  prodName: z.string().describe('해당 unit의 상품명'),
  price: z.number().describe('상품 기본 가격입니다.'),
  useCoupon: z.nativeEnum(YN).describe('쿠폰 사용 가능 여부'),
  usePoint: z.nativeEnum(YN).describe('적립금 사용 가능 여부'),
  useShoppingGroup: z.nativeEnum(YN).describe('쇼핑등급 사용 가능 여부'),
  options: z
    .array(prodOptionSetData)
    .optional()
    .describe('상품의 옵션 세트 목록입니다.'),
  optionDetails: z
    .array(productOptionDetailData)
    .optional()
    .describe('상품의 옵션 세트가 조합된 옵션 상세 정보입니다.'),
  optionDetailCount: z.number().describe('옵션 상세 정보의 개수입니다.'),
  delivCountryList: z
    .string()
    .array()
    .optional()
    .describe('배송 가능한 국가 목록'),
  delivTypeList: z.nativeEnum(상수_배송타입).array().optional(),
  delivPayTypeList: z.nativeEnum(상수_배송결제타입).array().optional(),
  useUnipassNumber: z
    .nativeEnum(YN)
    .optional()
    .describe('개인통관고유부호 사용여부'),
})

export const getResProductListDto = z.object({
  list: findProductsApiProductData.array(),
  cursorForPrev: z.string().optional(),
  cursorForNext: z.string().optional(),
  count: z.number(),
})

export const getPayloadProductListDto = z.object({
  direction: z.union([z.literal('next'), z.literal('prev')]),
  searchingKeyword: z.string().optional(),
  cursor: z.string().optional(),
  count: z
    .number()
    .describe(
      '한번에 검색할 개수 / 무한스크롤: 50 / 상품에 속한 품목 전체 검색: 1000 '
    ),
  orderType: z.nativeEnum(상수_주문타입).optional(),
  memberCode: z.string().optional(),
  unitCode: z.string().optional(),
  includeDeliveryInfo: z.boolean().optional().describe('배송설정 포함 여부'),
})
