import React, { useEffect } from 'react'
import { useDialogDelivery } from './dialog-delivery.store'
import { type TDialogDeliveryProps, DialogDelivery } from './dialog-delivery'

interface TDialogPaymantConfirmationConfirm
  extends Omit<TDialogDeliveryProps, 'open' | 'onOpenChange' | 'onSubmit'> {
  initCallback?: () => void
}
const DialogDeliveryContainer = ({
  initCallback,
  ...props
}: TDialogPaymantConfirmationConfirm) => {
  const [state, setState] = useDialogDelivery.dialog()
  const {
    isOpen,
    fulfilled,
    rejected,
    onOpenChange: _onOpenChange,
    onSubmit: _onSubmit,
  } = state

  const onOpenChange = (open: boolean) => {
    setState((prev) => ({ ...prev, isOpen: open }))
    _onOpenChange?.(open)
  }

  const onSubmit = () => {
    _onSubmit?.()
  }

  useEffect(() => {
    initCallback?.()
  }, [initCallback])

  return (
    <DialogDelivery
      open={isOpen}
      onOpenChange={onOpenChange}
      fulfilled={fulfilled}
      rejected={rejected}
      onSubmit={onSubmit}
      {...props}
    />
  )
}

export { DialogDeliveryContainer }
