import { match, P } from 'ts-pattern'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_주문섹션 } from '.'
import { 상수_배송타입 } from '~/entities/@x/배송타입'
import {
  YN,
  상수_배송결제타입,
  상수_섹션상태,
  상수_송장배송상태,
  상수_수거결제타입,
  상수_수거타입,
} from '~/entities/@x'

export const 섹션: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        orderSectionCode: P.nonNullable,
      },
      () => true
    )
    .otherwise(() => false)
)
// ====================================== 섹션 상태 스펙

/**
 * @description 상품준비
 * - 상태코드: OSS01
 * - 배송보류: N
 */
export const 상품준비: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.상품준비,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 배송대기 송장등록전
 * - 상태코드: OSS02
 * - 배송보류: N
 * - 송장번호: null
 */
export const 배송대기_송장등록전: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          statusCd: 상수_섹션상태.배송대기,
          isDeliveryHold: YN.N,
          invoice: P.union(null, { invoiceNo: null }),
          deliveryTypeCd: P.when(
            (deliveryTypeCd) =>
              deliveryTypeCd !== 상수_배송타입.배송없음 &&
              deliveryTypeCd !== 상수_배송타입.방문수령
          ),
        },
        () => true
      )
      .otherwise(() => false)
)

/**
 * @description 배송대기 송장등록후
 * - 상태코드: OSS02
 * - 배송보류: N
 */
export const 배송대기_송장등록후: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      /**
       * @description
       */
      .with(
        {
          statusCd: 상수_섹션상태.배송대기,
          isDeliveryHold: YN.N,
          deliveryTypeCd: 상수_배송타입.방문수령,
        },
        () => true
      )
      /**
       * @description
       * invoiceNo가 없는경우 배송타입이 배송없음, 방문수령인경우
       */
      .with(
        {
          statusCd: 상수_섹션상태.배송대기,
          isDeliveryHold: YN.N,
          invoice: null,
          deliveryTypeCd: 상수_배송타입.배송없음,
        },
        () => true
      )
      /**
       * @description
       * invoiceNo가 있는경우 배송타입이 배송없음, 방문수령이 아닌경우
       */
      .with(
        {
          statusCd: 상수_섹션상태.배송대기,
          isDeliveryHold: YN.N,
          invoice: { invoiceNo: P.nonNullable },
          deliveryTypeCd: P.when(
            (deliveryTypeCd) =>
              deliveryTypeCd !== 상수_배송타입.배송없음 &&
              deliveryTypeCd !== 상수_배송타입.방문수령
          ),
        },
        () => true
      )
      .otherwise(() => false)
)

/**
 * @description 배송보류
 * - 상태코드: OSS02
 * - 배송보류: Y
 */
export const 배송보류: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        isDeliveryHold: YN.Y,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 배송중
 */
export const 배송중: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.배송중,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 배송완료
 */
export const 배송완료: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.배송완료,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 취소접수
 */
export const 취소접수: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.취소접수,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 취소완료
 */
export const 취소완료: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.취소완료,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 반품접수
 */
export const 반품접수: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품접수,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 교환접수
 */
export const 교환접수: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품접수,
        returnInfo: {
          isExchange: YN.Y,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 반품완료
 */
export const 반품완료: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품완료,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 교환완료
 */
export const 교환완료: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품완료,
        returnInfo: {
          isExchange: YN.Y,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @description 구매확정
 */
export const 구매확정: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.구매확정,
        isDeliveryHold: YN.N,
      },
      () => true
    )
    .otherwise(() => false)
)

/**
 * @history
 * 점프가 없어서 일단 배송없는 섹션 로직을 추가해야한다.
 * 그리고 배송상품인데 배송없음이라는 것이 말이 안되는 것이다.
 * 나중에 바꿔야한다 나~~중에~~
 */
export const 배송없는섹션: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          invoice: null,
          orderDeliveryCode: P.union(null, ''),
          deliveryTypeCd: P.union(
            상수_배송타입.배송없음,
            상수_배송타입.방문수령
          ),
        },
        () => true
      )
      .otherwise(() => false)
)

export const 섹션상태카테고리 = {
  배송: [
    상품준비,
    배송대기_송장등록전,
    배송대기_송장등록후,
    배송보류,
    배송중,
    배송완료,
    구매확정,
  ],
  취소: [취소접수, 취소완료],
  반품: [반품접수, 반품완료],
}

// ====================================== 섹션 액션 스펙
export const 배송기능활성화: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          deliveryTypeCd: P.when(
            (deliveryTypeCd) => deliveryTypeCd !== 상수_배송타입.다운로드
          ),
        },
        () => true
      )
      .otherwise(() => false)
)

export const 배송설정활성화: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          orderDeliveryCode: P.nonNullable,
        },
        () => true
      )
      .otherwise(() => false)
)

export const 구매자반품: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품접수,
        returnInfo: {
          isCustomerRequest: true,
          isExchange: YN.N,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 판매자반품: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품접수,
        returnInfo: {
          isCustomerRequest: false,
          isExchange: YN.N,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 구매자교환: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품접수,
        returnInfo: {
          isCustomerRequest: true,
          isExchange: YN.Y,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 판매자교환: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품접수,
        returnInfo: {
          isCustomerRequest: false,
          isExchange: YN.Y,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 자동수거신청: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      /**
       * @description
       * 자동수거신청을 누르고 굿스플로창이 띄워졌는데 콜백을 받지않고 닫으면 invoice가 null이 아니다.
       */
      .with(
        {
          returnInfo: {
            isCustomerRequest: true,
            retrieveTypeCd: 상수_수거타입.자동수거신청,
            invoice: P.optional({ invoiceNo: P.nullish }),
          },
        },
        () => true
      )
      .with(
        {
          returnInfo: {
            isCustomerRequest: true,
            retrieveTypeCd: 상수_수거타입.자동수거신청,
            invoice: null,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

const 자동수거완료: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        returnInfo: {
          isCustomerRequest: true,
          retrieveTypeCd: 상수_수거타입.자동수거신청,
          invoice: {
            deliveryStatusCd: 상수_송장배송상태.배송완료,
          },
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 수거정보수정: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            retrieveTypeCd: P.nonNullable,
            invoice: {
              deliveryStatusCd: P.when(
                (deliveryStatusCd) =>
                  deliveryStatusCd !== 상수_송장배송상태.배송중
              ),
            },
          },
        },
        () => true
      )
      .with(
        {
          returnInfo: {
            retrieveTypeCd: P.nonNullable,
            invoice: null,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 판매자반품완료: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        statusCd: 상수_섹션상태.반품완료,
        returnInfo: {
          isCustomerRequest: false,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 구매자반품완료: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          statusCd: 상수_섹션상태.반품완료,
          returnInfo: {
            isCustomerRequest: true,
            isExchange: YN.N,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 판매자취소완료: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        statusCd: 상수_섹션상태.취소완료,
        cancelInfo: {
          isCustomerRequest: false,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 구매자취소완료: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        statusCd: 상수_섹션상태.취소완료,
        cancelInfo: {
          isCustomerRequest: false,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 구매자교환완료: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          statusCd: 상수_섹션상태.반품완료,
          returnInfo: {
            isCustomerRequest: true,
            isExchange: YN.Y,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 판매자취소접수: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        statusCd: 상수_섹션상태.취소접수,
        cancelInfo: {
          isCustomerRequest: false,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 구매자취소접수: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        statusCd: 상수_섹션상태.취소접수,
        cancelInfo: {
          isCustomerRequest: true,
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 섹션합치기나누기가능: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          statusCd: P.union(상수_섹션상태.상품준비, 상수_섹션상태.배송대기),
          isDeliveryHold: YN.N,
          invoice: P.nullish,
        },
        () => true
      )
      .otherwise(() => false)
)

// ====================================== 반품배송
export const 반품택배수거예정: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        returnInfo: {
          invoice: {
            deliveryStatusCd: 상수_송장배송상태.집하예정,
          },
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 반품택배수거중: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        returnInfo: {
          invoice: {
            deliveryStatusCd: 상수_송장배송상태.집하,
          },
        },
      },
      () => true
    )
    .otherwise(() => false)
)

export const 반품택배수거완료: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        returnInfo: {
          invoice: {
            deliveryStatusCd: 상수_송장배송상태.배송완료,
          },
        },
      },
      () => true
    )
    .otherwise(() => false)
)

// ====================================== 반품
export const 반품수거완료: ISpecification<모델_주문섹션> = new Spec((ex) =>
  match(ex.data)
    .with(
      {
        returnInfo: {
          isRetrieved: YN.Y,
        },
      },
      () => true
    )
    .otherwise(() => false)
)
export const 반품수거신청가능여부: ISpecification<모델_주문섹션> = new Spec(
  (ex) =>
    match(ex.data)
      .with(
        {
          returnInfo: {
            invoice: P.nonNullable,
          },
        },
        () => true
      )
      .with(
        {
          returnInfo: {
            retrievePayTypeCd: 상수_수거결제타입.기타,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

// ====================================== 종합
/**
 * @description
 * 수거신청이나오는 케이스 : FO에서 구매자가 반품/교환을 접수하면서 자동수거신청한 케이스
 */
export const 수거신청 = 구매자반품
  .or(구매자교환)
  .and(반품수거신청가능여부.not())
  .and(반품수거완료.not())

export const 수거완료신청 = 구매자반품
  .or(구매자교환)
  .or(판매자반품)
  .or(판매자교환)
  .and(반품수거신청가능여부)
  .and(반품수거완료.not())

/**
 * @description
 * 반품승인이 나오는 케이스 : FO에서 구매자가 반품/교환을 접수하면서 그외 방식으로 신청한 케이스
 * BO에서 관리자가 반품접수를 한 케이스
 */
export const 반품승인신청 = 구매자반품
  .or(구매자교환)
  .or(판매자반품)
  .or(판매자교환)
  .and(반품수거완료)
  .and(반품수거신청가능여부)

// ====================================== 수거
export const 반품배송_수거요청: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            invoice: {
              deliveryStatusCd: 상수_송장배송상태.배송대기,
            },
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 반품배송_수거중: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            invoice: {
              deliveryStatusCd: 상수_송장배송상태.배송중,
            },
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 반품배송_수거완료: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            invoice: {
              deliveryStatusCd: 상수_송장배송상태.배송완료,
            },
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 반품배송_구매자발송: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            isCustomerRequest: true,
            retrieveTypeCd: 상수_수거타입.구매자발송,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 반품배송_자동수거: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            retrieveTypeCd: 상수_수거타입.자동수거신청,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 반품송장등록여부: ISpecification<모델_주문섹션> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          returnInfo: {
            invoice: P.nonNullable,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 착불택배: ISpecification<모델_주문섹션> = new Spec((candidate) =>
  match(candidate.data)
    .with(
      {
        deliveryPayTypeCd: 상수_배송결제타입.착불결제,
      },
      () => true
    )
    .otherwise(() => false)
)
