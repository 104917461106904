import React from 'react'
import DaumPostcodeEmbed, { type Address } from 'react-daum-postcode'
import { Dialog } from '~/shared/components/ui/dialog'
import { Button } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'

export const PostcodeSearchButton = ({
  onComplete,
}: {
  onComplete: (
    addr: {
      fullAddress: string
    } & Address
  ) => void
}) => {
  const [open, setOpen] = React.useState(false)
  const handleComplete = (data: Address) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    onComplete({ fullAddress, ...data }) // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    setOpen(false)
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Button
          native={{ type: 'button' }}
          variant="outlined"
          text={__('검색')}
        />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="sm:max-w-[425px] px-0 py-0 rounded-[8px] overflow-hidden">
          <div>
            <DaumPostcodeEmbed onComplete={handleComplete} />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
