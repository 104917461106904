import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '~/shared/utils'

type VariantType = 'line' | 'segment' | 'elevated'

interface PropsContextType {
  variant?: VariantType
}
const PropsContext = React.createContext<PropsContextType>({
  variant: undefined,
})

export interface RootProps
  extends React.ComponentProps<typeof TabsPrimitive.Root> {
  variant?: VariantType
}

function Tabs({ variant, ...props }: RootProps) {
  const value = React.useMemo(() => ({ variant }), [variant])

  return (
    <PropsContext.Provider value={value}>
      <TabsPrimitive.Root {...props} />
    </PropsContext.Provider>
  )
}

const LIST_VARIANT_STYLE_MAP = {
  line: '',
  segment: 'bg-clay-semantic-surfaceSub',
  elevated: 'bg-transparent',
}
const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  const { variant } = React.useContext(PropsContext)
  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        'inline-flex items-center justify-center bg-clay-semantic-surfaceSub',
        variant && LIST_VARIANT_STYLE_MAP[variant],
        className
      )}
      {...props}
    />
  )
})
TabsList.displayName = TabsPrimitive.List.displayName

const TRIGGER_VARIANT_STYLE = {
  line: "data-[state=active]:relative data-[state=active]:after:content-[''] data-[state=active]:after:h-[2px] data-[state=active]:after:bg-clay-semantic-focus data-[state=active]:after:absolute data-[state=active]:after:bottom-0 data-[state=active]:after:left-0 data-[state=active]:after:right-0 data-[state=active]:text-clay-semantic-focus data-[state=active]:bg-transparent data-[state=active]:shadow-none",
  segment:
    'text-clay-semantic-textSub shadow-none rounded-[6px] data-[state=active]:text-white daata-[state=active]:bg-clay-semantic-actionSecondary data-[state=active]:text-clay-semantic-text data-[state=active]:shadow-clay-raisedButton',
  elevated:
    'text-clay-semantic-textSecondary bg-transparent data-[state=active]:bg-[#15181E] data-[state=active]:text-clay-semantic-textOn',
}
const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { variant } = React.useContext(PropsContext)
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap ring-offset-0 transition-all focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-slate-950 active:ring-offset-0 active:ring-transparent !border-none',
        variant && TRIGGER_VARIANT_STYLE[variant],
        className
      )}
      {...props}
    />
  )
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn('mt-2 !ring-0 !ring-offset-0 !outline-none', className)}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
