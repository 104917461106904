import React from 'react'
import { cn } from '~/shared/utils'
import { ChevronDown } from '@imwebme/clay-icons'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { match } from 'ts-pattern'
import { vars } from '@imwebme/clay-token'

interface TProps extends React.ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isOrderItemsColumn?: boolean //품목보기 컬럼 여부
}

const MQ_HOVER = '@media (hover: hover) and (pointer: fine)' as const

/**
 * 주문목록 상세보기 드랍다운 트리거 버튼
 * @description DropdownMenu.Root 내부에서 사용해야 한다
 */
export function DropdownTriggerBtn({
  children,
  setOpen,
  className,
  isOrderItemsColumn,
}: TProps) {
  const touchStartPos = React.useRef({ x: 0, y: 0 })
  const swipeThreshold = 10 // 픽셀 단위의 스와이프 임계값

  const handleTouchStart = React.useCallback(
    (event: React.TouchEvent<HTMLButtonElement>) => {
      const touch = event.touches[0]
      touchStartPos.current = { x: touch.clientX, y: touch.clientY }
      setOpen(false)
    },
    []
  )

  const handleTouchEnd = React.useCallback(
    (event: React.TouchEvent<HTMLButtonElement>) => {
      const touch = event.changedTouches[0]
      const diffX = Math.abs(touch.clientX - touchStartPos.current.x)
      const diffY = Math.abs(touch.clientY - touchStartPos.current.y)

      if (diffX < swipeThreshold && diffY < swipeThreshold) {
        setOpen(true)
      }
    },
    []
  )

  return (
    <DropdownMenu.Trigger
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      asChild
    >
      {match(isOrderItemsColumn)
        .with(true, () => (
          <button
            type="button"
            className={cn(
              'text-clay-semantic-text flex gap-x-[4px] items-center w-full typo-clay-label-medium h-[44px] px-clay-2',
              'data-[state=open]:bg-clay-semantic-layerSelected',
              className
            )}
            css={{
              [MQ_HOVER]: {
                '&:hover': {
                  background: vars.semantic.color.actionPrimaryTonalHover,
                },
              },
            }}
          >
            {children}
          </button>
        ))
        .otherwise(() => (
          <button
            type="button"
            className={cn(
              'text-clay-semantic-text flex gap-x-[4px] items-center w-full typo-clay-label-medium py-[10px] px-clay-2',
              'hover:text-clay-semantic-textSecondary [&_div]:hover:text-clay-semantic-textSecondaryHover',
              '[&_div]:data-[state=open]:visible [&_div]:data-[state=open]:opacity-100',
              'data-[state=open]:bg-clay-semantic-layerSelected',
              className
            )}
            css={{
              [MQ_HOVER]: {
                '&:hover': {
                  background: vars.semantic.color.actionPrimaryTonalHover,
                },
              },
            }}
          >
            <div className="truncate">{children}</div>
            <div
              className="w-[16px] h-[16px] transition-opacity text-clay-semantic-textSecondary"
              css={{
                opacity: 0,
                visibility: 'hidden',
                [MQ_HOVER]: {
                  'tr.group:hover &': {
                    opacity: 100,
                    visibility: 'visible',
                  },
                },
              }}
            >
              <ChevronDown color="inherit" />
            </div>
          </button>
        ))}
    </DropdownMenu.Trigger>
  )
}
