/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

const afterOrderSectionItemDto = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
})

const resCancelRequestDto = z.object({
  isPartial: z.boolean(),
  beforeOrderSectionNo: z.string(),
  afterOrderSectionNo: z.string(),
  beforeOrderSectionCode: z.string(),
  afterOrderSectionCode: z.string(),
  afterOrderSectionItems: z.array(afterOrderSectionItemDto),
})

export const patchResCancelRequestDto = z.array(resCancelRequestDto)

export const targetItemInformationDto = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
})
export const targetSectionInformationDto = z.object({
  orderSectionCode: z.string(),
  targetItemInformation: z.array(targetItemInformationDto),
})
export const patchPayloadCancelRequestDto = z.object({
  cancelReasonCode: z.string().min(1),
  cancelReasonDetail: z.string().nullish(),
  targetSectionInformation: z.array(targetSectionInformationDto),
})
