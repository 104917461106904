import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithReset, RESET } from 'jotai/utils'
import {
  T상수_상품옵션타입,
  T상수_배송타입,
  T상수_배송결제타입,
  T상수_상품타입,
} from '~/entities/@x'
import {
  TGetPayloadProductListDto,
  TGetResProducttListItemDto,
} from '~/entities/product/product-list'
import { calcTotalPrice, genUniqueId } from './product-add-modal.fn'
import { toast } from 'react-toastify'
import { __ } from '~/shared/i18n'
import { intersection, isEmpty } from 'lodash'

// 모달 인터페이스 ================================================================ //

type TModalParams = TGetPayloadProductListDto & {
  currency: string
  delivCountryList?: string[] //제한되어야 하는 상품의 배송국가
  delivTypeList?: T상수_배송타입[] //제한되어야 하는 상품의 배송타입
  delivPayTypeList?: T상수_배송결제타입[] //제한되어야 하는 상품의 배송결제타입
  idList?: string[] //제외되어야 하는 이미 추가된 상품의 커스텀 아이디
  prodType?: T상수_상품타입
  handleAddProduct: (result: TProdAddResult) => void
}

export type TProdAddResult = {
  id: string
  optionDetailCode?: string | null
  options?:
    | {
        optionType?: T상수_상품옵션타입
        optionCode?: string
        valueCode?: string
        label: string
      }[]
    | null
  isRequireOption: boolean
  price: number
  prod: TGetResProducttListItemDto // 상품 서버 데이터
}[]

const productAddModalAtom = atomWithReset<TModalParams>({
  handleAddProduct: log.debug,
  currency: 'KRW',
  count: 10,
  direction: 'next',
})

const productAddModalAtom_derived = atom(
  (get) => get(productAddModalAtom),
  (get, set, update: Partial<TModalParams>) => {
    const prev = get(productAddModalAtom)
    set(productAddModalAtom, { ...prev, ...update })
  }
)

export const useProductAddModalAtom = {
  state: () => useAtom(productAddModalAtom),
  get: () => useAtomValue(productAddModalAtom),
  set: () => useSetAtom(productAddModalAtom_derived),
}

// 선택된 상품 id(상품코드별) ======================================================== //

export type ProdType = '체크박스옵션' | '사용자정의옵션' | '비필수옵션'
const selectedProdAtom = atomWithReset<{
  [key in string]: { [K in ProdType]: string[] }
}>({})

interface TSelectedProd {
  prod: TGetResProducttListItemDto
  optionDetailCode?: string | null
  options?:
    | {
        optionType?: T상수_상품옵션타입
        optionCode?: string
        valueCode?: string
        label: string
        price: number
      }[]
    | null // 선택된 옵션 목록
}

type UpdateSelectedProdFamily =
  | {
      action: 'add'
      type: ProdType
      payload: TSelectedProd
    }
  | { action: 'remove'; type: ProdType; payload: string }

const selectedProdFamily = atomFamily((prodCode: string) =>
  atom(
    (get) => get(selectedProdAtom)[prodCode] ?? {},
    (get, set, { action, type, payload }: UpdateSelectedProdFamily) => {
      const prev = get(selectedProdAtom)
      const prevProd = prev[prodCode] ?? {}
      const masterProd = get(masterProdAtom)
      const modalData = get(productAddModalAtom)

      if (action === 'add') {
        const { prod, optionDetailCode, options } = payload
        const id = genUniqueId({
          prodCode: prod.prodCode,
          optionDetailCode,
          options,
        })

        if (type === '사용자정의옵션') {
          if (
            prevProd.사용자정의옵션?.includes(id) ||
            modalData.idList?.includes(id)
          ) {
            toast.error(__('이미 추가된 옵션이에요'))
            return
          }
        }

        const nextProd = {
          ...prevProd,
          [type]: [...(prevProd[type] ?? []), id],
        }
        set(selectedProdAtom, {
          ...prev,
          [prodCode]: nextProd,
        })

        // 마스터 아톰에 추가
        set(masterProdAtom, {
          ...masterProd,
          [id]: {
            id,
            optionDetailCode,
            options: options?.map((opt) => {
              const { price, ...rest } = opt
              return rest
            }),
            isRequireOption: type !== '비필수옵션',
            price: calcTotalPrice({
              prod,
              selectedOptions: options,
            }),
            prod,
          },
        })

        if (type === '사용자정의옵션') {
          toast.success(__('상품이 추가됐어요'))
        }
        return
      }

      if (action === 'remove') {
        const newArr = (prevProd[type] ?? []).filter((id) => id !== payload)

        // 모든 비필수옵션을 제거해주는 로직 (비필수옵션은 본상품이 있어야 존재 가능)
        if (
          newArr.length === 0 &&
          (type === '체크박스옵션' || type === '사용자정의옵션') &&
          prevProd.비필수옵션
        ) {
          const { [prodCode]: value, ...rest } = prev
          set(selectedProdAtom, rest)

          // 마스터 아톰에서 제거
          const prodIdsToRemove = [payload, ...prevProd.비필수옵션]
          const newProdAtomPayload = Object.keys(masterProd).reduce<
            typeof masterProd
          >((acc, currentKey) => {
            if (!prodIdsToRemove.includes(currentKey)) {
              acc[currentKey] = masterProd[currentKey]
            }
            return acc
          }, {})
          set(masterProdAtom, newProdAtomPayload)

          return
        }

        set(selectedProdAtom, {
          ...prev,
          [prodCode]: { ...prevProd, [type]: newArr },
        })

        // 마스터 아톰에서 제거
        const { [payload]: value, ...rest } = masterProd
        set(masterProdAtom, rest)
      }
    }
  )
)

export function useSelectedProdAtom(prodCode: string) {
  return useAtom(selectedProdFamily(prodCode))
}

// 사용자정의로 추가된 옵션(UI용) ====================================================== //
const userDefinedOptionFamily = atomFamily((prodCode: string) =>
  atom((get) => {
    const ids = get(selectedProdAtom)[prodCode]?.사용자정의옵션 ?? []

    return ids.reduce<{ id: string; label: string; price: number }[]>(
      (prev, id) => {
        const prod = get(masterProdAtom)[id]
        if (!prod) {
          return prev
        }
        return [
          ...prev,
          {
            id,
            label: prod.options?.map((opt) => opt.label).join(' · ') ?? '',
            price: prod.price,
          },
        ]
      },
      []
    )
  })
)

export function useUserDefinedOption(prodCode: string) {
  return useAtomValue(userDefinedOptionFamily(prodCode))
}

// 선택된 상품 배송설정의 교집합(배송방식, 배송국가, 배송비용) ====================================== //

const delivConfigsAtom = atom((get) => {
  const masterProds = get(masterProdAtom)
  const prodList = Object.values(masterProds)
  const modalData = get(productAddModalAtom)

  return {
    delivCountryList: !isEmpty(modalData.delivCountryList)
      ? intersection(
          ...prodList.map((el) => el.prod.delivCountryList),
          modalData.delivCountryList
        )
      : intersection(...prodList.map((el) => el.prod.delivCountryList)),
    delivTypeList: !isEmpty(modalData.delivTypeList)
      ? intersection(
          ...prodList.map((el) => el.prod.delivTypeList),
          modalData.delivTypeList
        )
      : intersection(...prodList.map((el) => el.prod.delivTypeList)),
    delivPayTypeList: !isEmpty(modalData.delivPayTypeList)
      ? intersection(
          ...prodList.map((el) => el.prod.delivPayTypeList),
          modalData.delivPayTypeList
        )
      : intersection(...prodList.map((el) => el.prod.delivPayTypeList)),
  }
})

// 추가 가능한 상품인가 =================================================================== //
// 동일한 상품타입과 배송설정의 상품만 추가가 가능하다

export function useIsValidProd(prod: TGetResProducttListItemDto) {
  const masterProd = useAtomValue(masterProdAtom)
  const masterProdList = Object.values(masterProd)
  const delivConfigs = useAtomValue(delivConfigsAtom)
  const modalData = useAtomValue(productAddModalAtom)

  // 배송국가 비교
  if (masterProdList.length === 0 && isEmpty(modalData.delivCountryList)) {
    return true
  } else if (
    intersection(prod.delivCountryList, delivConfigs.delivCountryList)
      .length === 0
  ) {
    return false
  }

  // 배송타입 비교
  if (masterProdList.length === 0 && isEmpty(modalData.delivTypeList)) {
    return true
  } else if (
    intersection(prod.delivTypeList, delivConfigs.delivTypeList).length === 0
  ) {
    return false
  }

  // 배송비결제타입 비교
  if (masterProdList.length === 0 && isEmpty(modalData.delivPayTypeList)) {
    return true
  } else if (
    intersection(prod.delivPayTypeList, delivConfigs.delivPayTypeList)
      .length === 0
  ) {
    return false
  }

  // 상품타입 비교
  if (masterProdList.length === 0 && !modalData.prodType) {
    return true
  } else {
    const mainProdType = masterProdList[0]?.prod.type || modalData.prodType
    return prod.type === mainProdType
  }
}

// 선택된 전체 상품(고유 id로 구분, 상품추가 버튼의 페이로드) ===================================== //

const masterProdAtom = atomWithReset<{
  [key in string]: TProdAddResult[number]
}>({})

export function useMasterProd() {
  return useAtomValue(masterProdAtom)
}

// 모든 아톰 초기화 ================================================================== //
const resetAllAtom = atom(null, (_get, set) => {
  set(productAddModalAtom, RESET)
  set(selectedProdAtom, RESET)
  set(masterProdAtom, RESET)
})

export function useResetProductAddModalAtom() {
  return useSetAtom(resetAllAtom)
}
