import React from 'react'
import { TInvoiceAddManualModalBase, useInvoiceAddManualAtom } from '.'
import { Loading } from '~/shared/components/loading'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'

const InvoiceAddManualModalContainer = React.lazy(() =>
  import('.').then((mod) => ({
    default: React.memo(mod.InvoiceAddManualModal),
  }))
)

interface TInvoiceAddManualModalPack extends TInvoiceAddManualModalBase {}
export function InvoiceAddManualModalPack(props: TInvoiceAddManualModalPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useInvoiceAddManualAtom.state({
    orderCode,
    sectionCode: props.sectionCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <React.Suspense fallback={<Loading />}>
            <InvoiceAddManualModalContainer {...props} />
          </React.Suspense>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
