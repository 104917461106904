/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post bulk-action-invoice-delete-validate
 */
export const postResBulkActionInvoiceDeleteValidateSchema = z.object({
  fulfilled: z.number().nullish(),
  rejected: z.number().nullish(),
})
export const postPayloadBulkActionInvoiceDeleteValidateSchema = z.object({
  targetOrderCodes: z.array(z.string()),
})
