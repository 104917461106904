import { match, P } from 'ts-pattern'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_권한 } from './persmission.model'
import { YN } from '~/entities/@x'

export const 보기권한: ISpecification<모델_권한> = new Spec(
  ({ data: candidate }) =>
    match(candidate)
      .with(
        {
          adminShoppingOms: {
            view: YN.Y,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 수정권한: ISpecification<모델_권한> = new Spec(
  ({ data: candidate }) =>
    match(candidate)
      .with(
        {
          adminShoppingOms: {
            edit: YN.Y,
          },
        },
        () => true
      )
      .otherwise(() => false)
)

export const 개인정보접근권한: ISpecification<모델_권한> = new Spec(
  ({ data: candidate }) =>
    match(candidate)
      .with(
        {
          etcPermissionData: {
            isPersonalInformationAccessPermission: YN.Y,
          },
        },
        () => true
      )
      .otherwise(() => false)
)
