import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import {
  deleteResExcelTemplatesDto,
  type TDeleteResExcelTemplatesDto,
  getResExcelTemplatesDto,
  type TGetResExcelTemplatesDto,
  type TGetParamsExcelTemplatesDto,
  postResExcelTemplatesDto,
  type TPostResExcelTemplatesDto,
  type TPostPayloadExcelTemplatesDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 */
export function deleteExcelTemplates({ idx }: { idx: string }) {
  return async function () {
    type T = TDeleteResExcelTemplatesDto
    const result = await axios
      .delete<T, AxiosResponse<ApiResponse<T>>>(`/excel/templates/${idx}`)
      .then((res) => res.data)

    try {
      deleteResExcelTemplatesDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
/**
 * @description
 */
export function getExcelTemplates() {
  return async function (params: TGetParamsExcelTemplatesDto) {
    const result = await axios
      .get<
        ApiResponse<TGetResExcelTemplatesDto>
      >('/excel/templates', { params })
      .then((res) => res.data)

    try {
      getResExcelTemplatesDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result.data
  }
}

/**
 * @description
 */
export function postExcelTemplates() {
  return async function (body: TPostPayloadExcelTemplatesDto) {
    type R = typeof body
    type T = TPostResExcelTemplatesDto
    const result = await axios
      .post<T, AxiosResponse<ApiResponse<T>>, R>('/excel/templates', body)
      .then((res) => res.data)

    try {
      postResExcelTemplatesDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @description
 */
export function postExcelTemplateById({ idx }: { idx: string }) {
  return async function (body: TPostPayloadExcelTemplatesDto) {
    type R = typeof body
    type T = TPostResExcelTemplatesDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/excel/templates/${idx}`, body)
      .then((res) => res.data)

    try {
      postResExcelTemplatesDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
