/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post cash-receipt-process
 */
export const postResCashReceiptProcessDto = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  statusCd: z.string(),
  methodTypeCd: z.string().nullish(),
})
export const postPayloadCashReceiptProcessDto = z.object({
  typeCd: z.string(),
  value: z.string().min(5),
})
