import { TDeliveryDto } from '~/entities/@dto'
import { match } from 'ts-pattern'

/**
 * 국가별 배송지 주소를 표시합니다.
 */
export function AddressByCountry({ delivery }: { delivery: TDeliveryDto }) {
  return (
    <>
      {match(delivery.country)
        .with('KR', () => (
          <>
            ({delivery.zipcode}) {delivery.addr1} {delivery.addr2}
          </>
        ))
        .otherwise(() => (
          <>
            {delivery.country || ''}
            {`\n${delivery.zipcode}`}
            {[delivery.state, delivery.city].filter(Boolean).join(', ')}
            {delivery.street ? `\n${delivery.street}` : ''}
            {delivery.building ? `\n${delivery.building}` : ''}
          </>
        ))}
    </>
  )
}
