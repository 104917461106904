import { match, P } from 'ts-pattern'
import { Tag, TagProps } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  상수_섹션상태,
  상수_섹션상태통합,
  상수_결제상태,
  상수_결제상태대표,
  T상수_섹션상태,
  T상수_섹션상태통합,
  T상수_결제상태대표,
  T상수_결제상태,
} from '~/entities/@x'
import { __g } from '~/shared/i18n'
import { StatusTagBaseProps } from './status-tag.type'

interface SectionStatusTagProps
  extends StatusTagBaseProps<T상수_섹션상태 | T상수_섹션상태통합> {
  paymentStatusCd: T상수_결제상태 | T상수_결제상태대표
}

/**
 * 섹션상태 태그
 * @param toggleDimmed - plain variant일 때 배경색을 변경할지 여부
 * @param paymentStatusCd - 주문 결제상태코드, 환불여부에 따라 variant를 분기함
 */
export function SectionStatusTag({
  statusCd,
  paymentStatusCd,
  size,
  toggleDimmed,
}: SectionStatusTagProps) {
  if (!statusCd) {
    return null
  }

  const variant = match({ statusCd, paymentStatusCd })
    .returnType<TagProps['variant']>()
    .with(
      {
        statusCd: P.union(
          상수_섹션상태.상품준비,
          상수_섹션상태.배송대기,
          상수_섹션상태.배송중,
          상수_섹션상태통합.배송전,
          상수_섹션상태통합.출고완료,
          상수_섹션상태통합.부분출고
        ),
      },
      () => 'warning'
    )
    .with(
      { statusCd: P.union(상수_섹션상태.취소접수, 상수_섹션상태.반품접수) },
      () => 'critical'
    )
    .with(
      {
        statusCd: P.union(
          상수_섹션상태통합.부분취소,
          상수_섹션상태통합.부분반품,
          상수_섹션상태통합.부분취소반품
        ),
        paymentStatusCd: P.not(
          P.union(
            상수_결제상태.부분환불완료,
            상수_결제상태.환불완료,
            상수_결제상태대표.부분환불
          )
        ),
      },
      () => 'warning'
    )
    .with(
      {
        statusCd: P.union(상수_섹션상태통합.전체취소반품),
        paymentStatusCd: P.not(P.union(상수_결제상태.환불완료)),
      },
      () => 'critical'
    )
    .otherwise(() => 'plain')

  const shouldToggleColor = !!toggleDimmed && variant === 'plain'

  return (
    <Tag
      variant={shouldToggleColor ? 'other' : variant}
      text={__g(statusCd)}
      size={size}
      sx={
        shouldToggleColor
          ? {
              '--clay-tag-background-color': vars.semantic.color.surface,
              '--clay-tag-color': vars.semantic.color.textSub,
            }
          : undefined
      }
    />
  )
}
