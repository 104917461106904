import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TCancelReasonSellerBase } from './cancel-reason-seller.type'
import { useCancelReasonSellerAtom } from './cancel-reason-seller.store'
import { ContentsBanner } from '@imwebme/clay-components'

const CancelReasonSeller = React.lazy(() =>
  import('./cancel-reason-seller').then((module) => ({
    default: React.memo(module.CancelReasonSeller),
  }))
)

interface TCancelReasonSellerContainer extends TCancelReasonSellerBase {}
export function CancelReasonSellerContainer(
  props: TCancelReasonSellerContainer
) {
  const [open, setState] = useCancelReasonSellerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <>
      <ContentsBanner
        type="secondary"
        variant="critical"
        text={__('판매자가 접수한 취소건이예요.')}
        button={{ text: __('사유보기'), onClick: () => onOpenChange(true) }}
      />
      <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay />
          <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
            <CancelReasonSeller {...props} />
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </>
  )
}
