import { ISpecification, Spec } from 'spec-pattern-ts'
import { match } from 'ts-pattern'
import * as math from 'mathjs'
import { 모델_취소금액계산 } from '.'

export const 적립금환불가능여부: ISpecification<모델_취소금액계산> = new Spec(
  (ex) => {
    if (ex.form.refundPriceTypeCd === 'ORT05') {
      return false
    }
    return ex.cancelSettlements.recreditedPoint > 0
  }
)

export const 배송비환불타입: ISpecification<모델_취소금액계산> = new Spec(
  (ex) => ex.form.deliveryExtraPriceType === 'SUB'
)

export const 배송비환불가능여부: ISpecification<모델_취소금액계산> = new Spec(
  // 0보다 커야한다.
  // (ex) => math.evaluate('value > 0', { value: ex.최대_환불가능_배송비 })
  (ex) =>
    // 67000 < 68100 + @
    match<boolean>(true)
      .with(ex.form.refundPriceTypeCd === 'ORT05', () => false)
      .with(ex.최대_환불가능_배송비 === 0, () => false)
      .with(
        math.evaluate('requestRefundPrice <= refundableAmount', {
          requestRefundPrice: math.chain(ex.환불금액_금액).multiply(-1).done(),
          refundableAmount: ex.cancelSettlements.refundableAmount,
        }),
        () => true
      )
      .otherwise(() => false)
)
