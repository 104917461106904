import { z } from 'zod'
import { 상수_배송타입, 상수_배송결제타입 } from '~/entities/@x'
import { targetOrderSectionItemDto } from '~/entities/@dto'

export const patchResOrderSectionSplitDto = z.void()

export const patchPayloadOrderSectionSplitDto = z.object({
  targetItemInformation: targetOrderSectionItemDto.array(),
  updatedColumns: z
    .object({
      deliveryTypeCd: z.nativeEnum(상수_배송타입),
      deliveryPayTypeCd: z.nativeEnum(상수_배송결제타입),
    })
    .optional(),
})
