import React from 'react'
import * as F from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { ProductCard002Template } from '../../product'
import { type TProductCard002 } from '../../product/product-card/product-card-002/product-card-002.ns.d'
import { __ } from '~/shared/i18n'
import { Trans } from 'react-i18next'
import { useCurrency } from '~/shared/hooks/use-currency'

interface TOrderEditApplyItemSection
  extends React.HTMLAttributes<HTMLOListElement> {
  items: TProductCard002.props[]
}
export function OrderEditApplyItemSection({
  items,
  ...props
}: TOrderEditApplyItemSection) {
  const currency = useCurrency()
  return (
    <ol {...props}>
      {F.pipe(
        items,
        A.mapWithIndex((index, item) => (
          <li
            key={`product-template-${index}`}
            className="grid grid-cols-[1fr,auto]"
          >
            <div>
              <ProductCard002Template {...item} />
            </div>
            <div>
              <Trans
                i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
                values={{
                  price:
                    (item.qty as number) *
                    (item.price || item.originalPrice || 0),
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                }}
                components={{ b: <b /> }}
              />
            </div>
          </li>
        ))
      )}
    </ol>
  )
}
