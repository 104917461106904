import * as math from 'mathjs'
import { __g, __ } from '~/shared/i18n'
import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TDeliveryDto } from '~/entities/@dto'
import { AddressByCountry } from './address-by-country'

interface Props {
  delivery: TDeliveryDto[]
  unipassNumber: string | null
}

export function DeliveryDetail({ delivery: _delivery, unipassNumber }: Props) {
  const delivery = _delivery[0]
  const multipleDelivery = _delivery.length > 1
  return (
    <Typography as="div" variant="body-medium">
      <Flex.Column
        padding={`${vars.spacing[3]} ${vars.spacing[2]}`}
        gap={vars.spacing[1]}
        width="280px"
      >
        <Typography>
          {delivery.receiverName}&middot;
          {delivery.receiverCall}
        </Typography>
        <Typography whiteSpace="pre-line">
          <AddressByCountry {...{ delivery }} />
        </Typography>
        <Typography colorToken="textSub" whiteSpace="pre-line">
          {delivery.memo || __('배송메모 없음')}
        </Typography>
        {unipassNumber && (
          <Typography colorToken="textSub">{unipassNumber}</Typography>
        )}
        {multipleDelivery && (
          <Typography>
            {__('외 {{count}}곳', {
              count: math.chain(_delivery.length).subtract(1).done(),
            })}
          </Typography>
        )}
      </Flex.Column>
    </Typography>
  )
}
