import React from 'react'
import { __ } from '~/shared/i18n'
import { StepLayout } from '../partials/step-layout'
import {
  Button,
  ButtonGroup,
  Clay,
  ContentsBanner,
  Flex,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SafeAvatar } from '~/shared/components/safe-avatar'
import { UnitSiteField } from './unit-site-field'
import { PurchaserInfoField } from './purchaser-info-field'
import { FieldLayout } from '../partials/field-layout'
import {
  useActiveStepIdxAtom,
  useOrderInfoAtom,
  useOrderInfoIsValid,
} from '../order-create.store'
import { match, P } from 'ts-pattern'
import { ImgByLangCode } from '~/shared/components/img-by-lang-code'
import { StepSkeleton } from '~/container/order-create/partials/step-skeleton'
import { useIdle } from '../use-idle'
import { MemberInfoField } from './member-info-field'

const STEP_IDX = 0 as const

export function StepOrderInfo() {
  const [activeStepIdx] = useActiveStepIdxAtom()
  const isIdle = useIdle(STEP_IDX)

  return match(activeStepIdx)
    .with(P.number.gt(STEP_IDX), () => <CompleteStatusView isIdle={isIdle} />)
    .with(STEP_IDX, () => <CurrentStatusView />)
    .with(P.number.lt(STEP_IDX), () => <IncompleteStatusView />)
    .otherwise(() => null)
}

function CurrentStatusView() {
  const [_activeStepIdx, setActiveStepIdx] = useActiveStepIdxAtom()
  const isValid = useOrderInfoIsValid()

  return (
    <StepLayout stepIdx={STEP_IDX} status="current">
      <React.Suspense fallback={<StepSkeleton />}>
        <Clay
          paddingTop={vars.spacing[6]}
          borderRadius={vars.rounded.large}
          background={vars.semantic.color.surface}
        >
          <UnitSiteField />
          <MemberInfoField />
          <PurchaserInfoField />
        </Clay>

        <ButtonGroup
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            isDisabled={!isValid}
            variant="primary"
            text={__('다음')}
            onClick={() => setActiveStepIdx((prev) => prev + 1)}
          />
        </ButtonGroup>
      </React.Suspense>
    </StepLayout>
  )
}

function CompleteStatusView({ isIdle }: { isIdle: boolean }) {
  const [orderInfo] = useOrderInfoAtom()
  return (
    <StepLayout stepIdx={STEP_IDX} status="complete">
      {isIdle ? (
        <StepSkeleton />
      ) : (
        <Clay
          paddingTop={vars.spacing[6]}
          borderRadius={vars.rounded.large}
          background={vars.semantic.color.surface}
        >
          <FieldLayout title={__('주문 사이트')}>
            <Flex alignItems="center" columnGap={vars.spacing[3]}>
              <ImgByLangCode langCode={orderInfo.unitSite?.langCode} />
              <Flex.Column rowGap={vars.spacing['05']}>
                <Typography
                  variant="label-medium-bold"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {orderInfo.unitSite?.name}
                </Typography>
                <Typography
                  variant="body-small"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {orderInfo.unitSite?.mainDomain}
                </Typography>
              </Flex.Column>
            </Flex>
          </FieldLayout>

          <FieldLayout title={__('회원 정보')}>
            {orderInfo._isMember ? (
              <Flex alignItems="center" columnGap={vars.spacing[3]}>
                <SafeAvatar
                  size="small"
                  src={orderInfo._profileImgUrl ?? undefined}
                />
                <Flex.Column rowGap={vars.spacing['05']}>
                  <Typography
                    variant="label-medium-bold"
                    sx={{ wordBreak: 'break-all' }}
                  >
                    {orderInfo._account}
                  </Typography>
                  {(orderInfo._memberName || orderInfo._memberCall) && (
                    <Typography
                      variant="body-small"
                      sx={{ wordBreak: 'break-all' }}
                    >
                      {orderInfo._memberName}
                      {orderInfo._memberName && orderInfo._memberCall && '·'}
                      {orderInfo._memberCall}
                    </Typography>
                  )}
                </Flex.Column>
              </Flex>
            ) : (
              <ContentsBanner
                text={__('비회원 주문이에요')}
                variant="information"
                type="secondary"
              />
            )}
          </FieldLayout>

          <FieldLayout title={__('구매자 정보')}>
            <Flex.Column rowGap={vars.spacing['05']}>
              <Typography
                variant="label-medium-bold"
                sx={{ wordBreak: 'break-all' }}
              >
                {orderInfo.ordererEmail}
              </Typography>
              {(orderInfo.ordererName || orderInfo.ordererCall) && (
                <Typography
                  variant="body-small"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {orderInfo.ordererName}
                  {orderInfo.ordererName && orderInfo.ordererCall && '·'}
                  {orderInfo.ordererCall}
                </Typography>
              )}
            </Flex.Column>
          </FieldLayout>
        </Clay>
      )}
    </StepLayout>
  )
}

function IncompleteStatusView() {
  return <StepLayout stepIdx={STEP_IDX} status="incomplete"></StepLayout>
}
