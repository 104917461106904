import React from 'react'
import { Textfield } from '@imwebme/clay-components'
import { Controller } from 'react-hook-form'
import { __ } from '~/shared/i18n'

export interface OrderEditCalcDeliveryFeeProps {
  onChangeDeliveryFee?: (value: any) => void
  onBlurDeliveryFee?: (value: any) => void
  currency: string
  control?: any
  deliveryFeeDisabled?: boolean
  deliveryRefundLimit: number
  deliveryFeeMethod: 'ADD' | 'SUB'
}

const OrderEditCalcDeliveryFee = ({
  currency = 'KRW',
  onChangeDeliveryFee,
  onBlurDeliveryFee,
  control,
  deliveryFeeDisabled = false,
  deliveryRefundLimit,
  deliveryFeeMethod,
}: OrderEditCalcDeliveryFeeProps) => (
  <div className="flex flex-col gap-[6px] w-fit">
    <Controller
      name="deliveryFee"
      control={control}
      render={({ field }) => (
        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              disabled={deliveryFeeDisabled}
              value={new Intl.NumberFormat().format(field.value)}
              onChange={onChangeDeliveryFee}
              onBlur={/** 포커스 아웃 되면 유효성 검증하기 */ onBlurDeliveryFee}
            />
            <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
              {__('{{currency, currency}}', {
                currency,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })}
            </Textfield.Addon>
          </Textfield.Box>
          <Textfield.HelperText>
            {deliveryFeeMethod === 'ADD' && // 배송비 방식이 추가이고 ko, KRW일경우에만 200000.
            currency === 'KRW'
              ? __('최대 200,000원 가능')
              : __('최대 {{price, 3comma}}{{currency, currency}} 가능', {
                  price: deliveryRefundLimit,
                  formatParams: {
                    currency: {
                      currency: currency || 'KRW',
                    },
                  },
                })}
          </Textfield.HelperText>
        </Textfield.Set>
      )}
    />
  </div>
)

export { OrderEditCalcDeliveryFee }
