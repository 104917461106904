import React from 'react'
import {
  Button,
  Flex,
  IconButton,
  OptionItem,
  OptionList,
  Select,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TGetResProducttListItemDto } from '~/entities/product/product-list'
import { __ } from '~/shared/i18n'
import { FormattedPrice } from '../../formatted-price'
import _ from 'lodash'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useUserDefinedOption,
  useSelectedProdAtom,
} from '../../../product-add-modal.store'
import { 상수_상품옵션타입 } from '~/entities/@x'
import { Trash03 } from '@imwebme/clay-icons'

interface Props {
  prod: TGetResProducttListItemDto
}

const formSchema = z.object({
  select: z.string().min(1),
  input: z
    .object({
      value: z.string().min(1),
    })
    .array(),
})
type TForm = z.infer<typeof formSchema>

export function CombinationProdWithInput({ prod }: Props) {
  const selectOptionData =
    prod.optionDetails?.filter((el) => el.isRequiredOption) ?? []
  const inputOptionData =
    prod.options?.filter((el) => el.type === 상수_상품옵션타입.입력) ?? []
  const [_selectedProd, setSelectedProd] = useSelectedProdAtom(prod.prodCode)
  const userDefinedOption = useUserDefinedOption(prod.prodCode)

  const { control, register, handleSubmit, formState, reset } = useForm<TForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      select: '',
      input: inputOptionData.map(() => ({ value: '' })),
    },
  })

  const { fields: inputFields } = useFieldArray({
    control,
    name: 'input',
  })

  function onRemoveUserDefinedOption(id: string) {
    setSelectedProd({
      action: 'remove',
      type: '사용자정의옵션',
      payload: id,
    })
  }

  function onSubmit(form: TForm) {
    const selectOptions = {
      label:
        selectOptionData
          .find((el) => el.optionDetailCode === form.select)
          ?.options.map((el) => `${el.optionName} ${el.valueName}`)
          .join(', ') ?? '',
      price: 0,
    }

    const inputOptions = form.input.map((input, idx) => ({
      optionType: 상수_상품옵션타입.입력,
      optionCode: inputOptionData[idx].optionCode,
      valueCode: input.value,
      label: `${inputOptionData[idx].optionName} ${input.value}`,
      price: 0,
    }))

    setSelectedProd({
      action: 'add',
      type: '사용자정의옵션',
      payload: {
        optionDetailCode: form.select,
        options: [selectOptions, ...inputOptions],
        prod,
      },
    })

    reset()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex.Column rowGap={vars.spacing[3]} padding={`${vars.spacing[2]} 0`}>
          {/* 조합형 옵션 */}
          <Controller
            name="select"
            control={control}
            render={({ field: { onChange, value, ref, ...rest } }) => (
              <Select>
                <Select.Trigger>
                  <Select.Input
                    label={__('옵션 구성')}
                    labelProps={{ bold: false }}
                    placeholder={__('선택해 주세요')}
                    value={
                      selectOptionData
                        .find((el) => el.optionDetailCode === value)
                        ?.options.map(
                          (el) => `${el.optionName} ${el.valueName}`
                        )
                        .join(', ') ?? ''
                    }
                    {...rest}
                  />
                </Select.Trigger>
                <Select.Portal>
                  <OptionList
                    popOver
                    setValue={(v) => onChange(v)}
                    sx={{ maxHeight: '280px', overflow: 'auto' }}
                  >
                    {selectOptionData.map((opt, idx) => (
                      <OptionItem
                        key={idx}
                        text={opt.options
                          .map((el) => `${el.optionName} ${el.valueName}`)
                          .join(', ')}
                        value={opt.optionDetailCode}
                      />
                    ))}
                  </OptionList>
                </Select.Portal>
              </Select>
            )}
          />

          {/* 입력형 옵션 */}
          {inputFields.map((field, index) => (
            <React.Fragment key={index}>
              <Textfield.Set>
                <Textfield.Legend
                  text={inputOptionData[index].optionName}
                  bold={false}
                />
                <Textfield.Box>
                  <Textfield.Input
                    key={field.id}
                    {...register(`input.${index}.value` as const)}
                    autoComplete="off"
                    placeholder={__('입력해 주세요')}
                  />
                </Textfield.Box>
              </Textfield.Set>
            </React.Fragment>
          ))}
          <Button
            text={__('목록에 추가')}
            variant="primary"
            fullWidth
            tonal
            isDisabled={!formState.isValid}
            native={{ type: 'submit' }}
          />
        </Flex.Column>
      </form>

      {/* 추가된 사용자정의 옵션 */}
      {userDefinedOption.map((opt) => (
        <Flex
          key={opt.id}
          gap={vars.spacing[2]}
          alignItems="center"
          sx={{ marginTop: vars.spacing[3] }}
        >
          <IconButton
            icon={<Trash03 />}
            size="tiny"
            variant="secondary"
            onClick={() => onRemoveUserDefinedOption(opt.id)}
          />
          <Typography
            variant="body-medium"
            sx={{ flex: 1, paddingRight: vars.spacing[2] }}
          >
            {opt.label}
          </Typography>
          <Typography variant="body-medium">
            <FormattedPrice price={opt.price} />
          </Typography>
        </Flex>
      ))}
    </>
  )
}
