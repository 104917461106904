import { z } from 'zod'

export const 상수_판매채널 = {
  아임웹: 1,
  네이버페이: 2,
  톡체크아웃: 3,
} as const

const type = z.nativeEnum(상수_판매채널)
export type T상수_판매채널 = z.infer<typeof type>
