import React from 'react'
import { TGetResProducttListItemDto } from '~/entities/product/product-list'
import { useIsValidProd, useSelectedProdAtom } from '../product-add-modal.store'
import {
  CombinationProdNoInput,
  CombinationProdWithInput,
  NoRequiredOptionProd,
  ProdWithOptions,
} from './필수옵션'
import { Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { ProdItemHeader } from './prod-item-header'
import { Collapse } from '~/shared/components/collapse'
import { NonRequiredOption } from './비필수옵션'
import { match } from 'ts-pattern'
import { getProdOptionType, calcNumTotalProd } from '../product-add-modal.fn'

export const ProdItem = React.memo(
  ({ prod, ...rest }: { prod: TGetResProducttListItemDto }) => {
    const [open, setOpen] = React.useState(false)
    const [selectedProd] = useSelectedProdAtom(prod.prodCode)
    const numSelectedProd = Object.keys(selectedProd).reduce(
      (acc, key) => acc + selectedProd[key as keyof typeof selectedProd].length,
      0
    )
    const isValid = useIsValidProd(prod)

    return (
      <Clay paddingInline={vars.spacing[6]} {...rest}>
        <ProdItemHeader
          imgUrl={prod.imageUrls?.[0]}
          prodName={prod.prodName}
          prodCode={prod.prodCode}
          open={open}
          toggleOpen={() => setOpen((prev) => !prev)}
          numTotalProd={calcNumTotalProd(prod)}
          numSelectedProd={numSelectedProd}
          disabled={!isValid}
        />
        <Collapse in={isValid ? open : false}>
          <Clay padding={`${vars.spacing[1]} 0 ${vars.spacing[1]} 60px`}>
            {/* 필수옵션 */}
            {match(getProdOptionType(prod))
              .with('필수옵션X', () => <NoRequiredOptionProd prod={prod} />)
              .with('조합형_입력형X', () => (
                <CombinationProdNoInput prod={prod} />
              ))
              .with('조합형_입력형O', () => (
                <CombinationProdWithInput prod={prod} />
              ))
              .with('비조합형', () => <ProdWithOptions prod={prod} />)
              .exhaustive()}

            {/* 비필수옵션 */}
            <NonRequiredOption prod={prod} />
          </Clay>
        </Collapse>
      </Clay>
    )
  }
)
