import { TextButton } from '@imwebme/clay-components'
import { RefreshCCW01, Trash03 } from '@imwebme/clay-icons'
import { IconMuTruck } from '@imwebme/icon'
import React from 'react'
import { useOrderSectionAtom } from '../../order-section/order-section-template'
import { __ } from '~/shared/i18n'

export const OrderEditCancelSectionHeader = ({
  label,
  title,
  count,
  onDeleteAll,
  deliveryName,
  deliveryNumber,
  onReset,
  sectionId,
}: {
  label: string
  title: string
  count: number
  onDeleteAll?: () => void
  deliveryName?: string
  deliveryNumber?: string | React.ReactNode
  onReset?: () => void
  sectionId: string
}) => {
  const updateItems = useOrderSectionAtom.valueUpdateAll()
  return (
    <>
      <div className="flex justify-between">
        <div>
          <div className="semantic-h4-bold">
            {title}{' '}
            <span className="text-clay-semantic-textPrimary">{count}</span>
          </div>
          <div className="mt-[8px] semantic-p14 font-normal text-clay-semantic-textSub">
            {label}
          </div>
        </div>
        <div className="flex gap-x-clay-4">
          {updateItems &&
            updateItems.length > 0 &&
            updateItems.some((item) => item.id === sectionId) && (
              <TextButton
                variant="secondary"
                onClick={onReset}
                text={__('초기화')}
                icon={<RefreshCCW01 color="inherit" />}
                iconPosition="right"
              />
            )}
          <TextButton
            variant="secondary"
            onClick={onDeleteAll}
            text={__('전체 취소')}
            icon={<Trash03 color="inherit" />}
            iconPosition="right"
          />
        </div>
      </div>
      {deliveryName && deliveryNumber && (
        <div className="mt-[24px] flex flex-wrap">
          <div className="grid grid-cols-[auto,auto] grid-rows-2 semantic-p14 font-normal gap-x-[8px] gap-y-[2px]">
            <div className="row-span-2">
              <IconMuTruck />
            </div>
            <div className="self-center text-clay-semantic-textSub">
              {deliveryName}
            </div>
            <div className="self-center">{deliveryNumber}</div>
          </div>
        </div>
      )}
    </>
  )
}

export const OrderEditCancelSectionHeaderMemo = React.memo(
  OrderEditCancelSectionHeader
)
