/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { 상수_언어코드 } from '~/entities/@x'

/**
 * get site-unit
 */
export const getResSiteUnitItemDto = z.object({
  code: z.string(),
  siteCode: z.string(),
  langCode: z.nativeEnum(상수_언어코드).nullish(),
  currency: z.string().nullish(),
  name: z.string().nullish(),
  makeTime: z.string(),
  updateTime: z.string(),
  mainDomain: z.string(),
})

export const getResSiteUnitSchema = z.object({
  list: getResSiteUnitItemDto.array(),
})
