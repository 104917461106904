import { z } from 'zod'
import { targetItemInformationDto } from '.'

export const targetItemInformationRdo = z.object({
  targetItemInformation: z.array(
    targetItemInformationDto.extend({
      _check: z.boolean(),
      _originalQty: z.number(),
    })
  ),
})

export type TTargetItemInformationRdo = z.infer<typeof targetItemInformationRdo>
