import { findChild } from '~/shared/utils'
import { __ } from '~/shared/i18n'
import React from 'react'

interface OrderEditHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  step: string[]
  activeStep?: number
  children?: React.ReactNode
  onClickNext?: () => void
  isNextButtonAvailable?: boolean
  onClickPrevious?: () => void
}

function OrderEditHeader({
  children,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const LinkSlot = findChild(children, 'link', true)
  const StepSlot = findChild(children, 'step', true)
  const TitleSlot = findChild(children, 'title', true)
  const RightTopSlot = findChild(children, 'right-top', true)
  const RightBottomSlot = findChild(children, 'right-bottom', true)
  return (
    <div
      className="bg-white grid mt-[64px] desktop:mt-0 min-w-[992px] overflow-auto"
      {...props}
    >
      <div className="w-[970px] mx-auto relative mt-[32px] mb-[24px]">
        {/* contaienr inner */}
        <div className="relative">
          {/* top */}
          <div>
            <div
              className="flex gap-x-[4px] items-center text-clay-semantic-textSub"
              {...LinkSlot?.props}
            />
          </div>
          {/* body */}
          <div className="mt-[8px]">
            <div
              className="text-clay-semantic-text semantic-h2-bold"
              {...TitleSlot?.props}
            ></div>
          </div>
          {/* footer */}
          {StepSlot && (
            <div className="mt-[32px]">
              <div {...StepSlot.props} />
            </div>
          )}
          <div className="absolute right-0 top-0" {...RightTopSlot?.props} />
          <div
            className="absolute right-0 bottom-0 flex gap-[8px] "
            {...RightBottomSlot?.props}
          />
        </div>
      </div>
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'title' | 'link' | 'step' | 'right-bottom' | 'right-top'
}
const Slot: React.FC<SlotProps> = () => null

OrderEditHeader.Slot = Slot

export { OrderEditHeader }
