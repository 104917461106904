import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TDeliveryHoldModalBase, useDeliveryHoldModalAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const DeliveryHoldContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryHoldModal),
  }))
)

interface TDeliveryHoldPack extends TDeliveryHoldModalBase {}
export function DeliveryHoldModalPack(props: TDeliveryHoldPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useDeliveryHoldModalAtom.state({
    atomKey: props.atomKey,
    orderCode,
    sectionCode: props.sectionCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <DeliveryHoldContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
