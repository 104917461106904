import { ApiClient, ApiResponse } from '~/shared/api'
import { type TC3payloadItems } from './order-cancel-info.type'

const axios = ApiClient()
export type TCancelRequest = {
  cancelReason: string
  cancelReasonDetail?: string
  targetSectionInformation: TC3payloadItems
}

export const CancelRequest = async ({
  orderCode,
  body,
}: {
  orderCode: string
  body: TCancelRequest
}): Promise<
  ApiResponse<
    {
      isPartial: boolean
      beforeOrderSectionNo: string
      afterOrderSectionNo: string
      beforeOrderSectionCode: string
      afterOrderSectionCode: string
      afterOrderSectionItems: [orderSectionItemCode: string, qty: number]
    }[]
  >
> => {
  const result = await axios
    .patch(`/order/${orderCode}/sections/cancel-request`, body)
    .then((res) => res.data)
  return result
}
