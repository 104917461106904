import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  TPatchPayloadOrderSectionMergeDto,
  TPatchResOrderSectionMergeDto,
  type TGetResOrderSectionMergeDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description order-section-merge
 */
export function getOrderSectionMerge({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function () {
    const result = await axios
      .get<
        ApiResponse<TGetResOrderSectionMergeDto>
      >(`/order/${orderCode}/sections/${sectionCode}/merge`)
      .then((res) => res.data)

    return result.data
  }
}

/**
 * @API문서
 * @description order-section-merge
 */
export function patchOrderSectionMerge({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadOrderSectionMergeDto) {
    type R = typeof body
    type T = TPatchResOrderSectionMergeDto
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/sections/${sectionCode}/merge`, body)
      .then((res) => res.data)

    return result
  }
}
