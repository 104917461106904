/**
 * @README
 * 일반주문 취소 페이지
 */
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

export { default } from '~/container/order/order-cancel/order-cancel'
