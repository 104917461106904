import React from 'react'
import SessionStorageManager from '~/shared/managers/session-storage-manger'

/**
 * 아임웹 PHP에서 쿼리 파라미터로 전달하는 사이트 정보를 다룬다.
 * @description OMS가 아닌 PHP에 의존하는 데이터인 경우 사용한다.
 */
export const SiteInfoProvider = (props: React.PropsWithChildren) => {
  React.useEffect(() => {
    SessionStorageManager.setItem(
      'is_b2b',
      new URL(window.location.href).searchParams.get('is_b2b') ?? 'false'
    )
  }, [])
  return props.children
}
