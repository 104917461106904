import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

if (import.meta.env.VITE_NODE_ENV !== 'local') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/app\.oms\.imweb\.me/,
      /^https:\/\/app\.oms\.imstage\.me/,
      /^https:\/\/app\.oms\.imtest\.me/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/#max-value-length
    normalizeDepth: 999,
  })
}

export const sentryCreateBrowserRouter =
  import.meta.env.VITE_NODE_ENV === 'local'
    ? createBrowserRouter
    : Sentry.wrapCreateBrowserRouter(createBrowserRouter)

// 로컬환경에서는 Sentry를 사용하지 않는다.
// React Router v6와 sentry 통합
// https://docs.sentry.io/platforms/javascript/guides/react/?original_referrer=https{{3A}}2F%2Fwww.google.com%2F#set-up-react-router
export const SentryRoutes = pipe(Routes, (R) => {
  if (import.meta.env.VITE_NODE_ENV === 'local') {
    return R
  }
  return Sentry.withSentryReactRouterV6Routing(R)
})
