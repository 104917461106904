import { z } from 'zod'

export const 상수_결제상태 = {
  결제대기: 'OPS01', // 결제 대기
  결제기한초과: 'OPS02', // 결제 기한 초과
  결제완료: 'OPS03', // 결제 완료
  부분환불완료: 'OPS04', // 부분 환불 완료
  환불완료: 'OPS05', // 환불 완료
  결제실패: 'OPS06', // 결제 실패
  결제이탈: 'OPS07', // 결제 이탈
  입금전취소: 'OPS08', // 입금전 취소(무통장입금, 가상계좌 대상)
} as const

const schema = z.nativeEnum(상수_결제상태)
export type T상수_결제상태 = z.infer<typeof schema>

export const 상수_결제상태대표 = {
  부분결제: 'PARTIAL_PAYMENT_COMPLETE',
  부분환불: 'PARTIAL_REFUND_COMPLETE',
} as const

export type T상수_결제상태대표 =
  (typeof 상수_결제상태대표)[keyof typeof 상수_결제상태대표]
