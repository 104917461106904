import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { OrderTimelinePack } from '~/container/order/order-timeline'
import { CreditCardSearch, ArrowLeft } from '@imwebme/clay-icons'
import { Tooltip } from '~/shared/components/ui/tooltip'
import {
  PaymentInfoModalPack,
  usePaymentinfoModalAtom,
} from '~/widgets/payment-info-modal'
import { IconButton } from '@imwebme/clay-components'
import { 상수_판매채널 } from '~/entities/@x'

interface OrderEditCancelCalcHeaderProps {
  orderNo: string
  orderCode: string
}

export function OrderSectionCancelCalcHeader({
  orderNo,
  orderCode,
}: OrderEditCancelCalcHeaderProps) {
  const setPaymentModal = usePaymentinfoModalAtom.set({
    orderCode,
  })
  return (
    <div className="flex justify-between">
      {/* contaienr inner */}
      {/* body */}
      <div className="flex p-0 flex-col items-start gap-[8px] flex-[1 0 0]">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
          className="flex py-[2px] content-center items-center gap-[4px] cursor-pointer"
        >
          <ArrowLeft colorToken="icon-secondary" />
          <div className="text-clay-semantic-textSub semantic-p14">
            {orderNo}
          </div>
        </Link>
        <div>
          <div className="text-clay-semantic-text semantic-h2-bold">
            {__('금액계산')}
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="flex items-center gap-x-[8px]">
        <OrderTimelinePack />
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={400}>
            <Tooltip.Trigger asChild>
              <IconButton
                native={{ type: 'button' }}
                variant="secondary"
                onClick={() => setPaymentModal({ open: true })}
                aria-label={__('결제정보')}
                icon={<CreditCardSearch color="inherit" />}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                align="end"
                className="py-[8px] px-[16px]"
              >
                <div className="max-w-[144px] typo-clay-label-small">
                  <div className="text-clay-semantic-text">
                    {__('결제 상세 정보')}
                  </div>
                  <div className="mt-[8px] text-clay-semantic-textSub">
                    {__('주문의 전체 결제 내역을 확인할 수 있어요')}
                  </div>
                </div>
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <PaymentInfoModalPack />
      </div>
    </div>
  )
}
