import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { 믹스패널 } from '~/shared/mixpanel'

export default function Layout() {
  React.useEffect(() => {
    /**
     * @description
     * Loader로 호출하지 않은 이유는 tabCode, pagenumber
     * 모두 1회 죄회하는 데이터 수집
     */
    dataLayer.push({
      event: 믹스패널.view_bo_oms_order_list,
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>주문목록</title>
      </Helmet>
      <Outlet />
    </>
  )
}
