import { z } from 'zod'

export const 상수_송장입력타입 = {
  판매자수동: 'IIT01', // 판매자 직접 수동 입력
  굿스플로: 'IIT02', // 굿스플로 자동채번
  엑셀업로드: 'IIT03', // 송장 엑셀 일괄업로드
  구매자수동: 'IIT04', // 구매자 반품/교환 시 직접 수동 입력
  외부채널: 'IIT05', // 외부채널(open api 등)을 통한 입력
} as const

const type = z.nativeEnum(상수_송장입력타입)
export type T상수_송장입력타입 = z.infer<typeof type>
