import React from 'react'
import { Clay, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { PriceItem } from './price-item'
import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'
import { useProdSelectAtom } from '../order-create.store'

interface Props {
  currency: string
}

export function Step1PriceDetails({ currency }: Props) {
  const [prodSelect] = useProdSelectAtom()
  const totalProdPrice = React.useMemo(
    () => prodSelect.reduce((acc, prod) => acc + prod.itemPrice * prod.qty, 0),
    [prodSelect]
  )

  return (
    <Clay paddingInline={vars.spacing[6]}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={vars.spacing[5]}
      >
        <Typography variant="label-medium-bold">{__('합계')}</Typography>
        <Flex alignItems="center" columnGap={vars.spacing[1]}>
          <Trans
            i18nKey="<t1>{{price, 3comma}}</t1> <t2>{{currency, currency}}</t2>"
            values={{
              price: totalProdPrice,
              formatParams: {
                currency: {
                  currency,
                },
              },
            }}
            components={{
              t1: <Typography variant="heading-xlarge-bold" as="span" />,
              t2: <Typography variant="body-medium" as="span" />,
            }}
          />
        </Flex>
      </Flex>
      <Clay
        borderRadius={vars.rounded.medium}
        padding={vars.spacing[4]}
        background={vars.semantic.color.surfaceSub}
      >
        <PriceItem label={__('상품 금액')} price={totalProdPrice} />
      </Clay>
    </Clay>
  )
}
