import React, { useEffect } from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { useAtom } from 'jotai'
import {
  OrderEditCancelSection,
  type OrderEditCancelSectionProps,
} from './order-edit-cancel-section'
import { type TProductCard001 } from '../../product'
import { orderSectionAtom } from '../../order-section/order-section-template/order-section-template.store'
import { Trans } from '~/shared/i18n'

interface OrderEditCancelSectionContainerProps
  extends OrderEditCancelSectionProps {
  onItemsChange?: (items: TProductCard001.props[] | undefined) => void
  onPriceChange?: (price: number) => void
}
const OrderEditCancelSectionContainer = ({
  id,
  items: _items,
  onItemsChange,
  onPriceChange,
  onDeleteAll: _onDeleteAll,
  ...props
}: OrderEditCancelSectionContainerProps & {
  id: string
}) => {
  const [section, setItems] = useAtom(orderSectionAtom({ id }))
  // 추가된 상품(isNew)은 제거한다.
  if (section?.value && section?.value?.length !== _items.length) {
    section.value = section?.value?.filter((e) => !e.isNew)
  }

  // props로 아이템을 받는다면 초기값이라서 setItems를 해준다.
  useEffect(() => {
    // 상태값으로 없다면? props로 받은 아이템을 초기값으로 넣어준다.
    if (!section) {
      setItems(_items)
    }
  }, [_items, setItems, section])

  const onDeleteAll = () => {
    pipe(
      _items,
      A.map((e) => ({ ...e, qtyChange: e.qty })),
      setItems
    )
    _onDeleteAll?.()
  }

  const onReset = () => {
    pipe(
      _items,
      A.map((e) => ({ ...e, qtyChange: 0 })),
      setItems
    )
  }

  // 아이템이 변경되면 qtyChange의 값들을 합산해서 총합을 구한다.
  useEffect(() => {
    const result = pipe(
      section,
      (s) => s?.value,
      O.fromNullable,
      O.fold(
        () => 0,
        flow(
          A.map((item) => (item.originalPrice || 0) * (item.qtyChange || 0)),
          A.reduce(0, (acc, cur) => acc + cur)
        )
      )
    )
    onPriceChange?.(result)
  }, [_items, onPriceChange, section])

  // 아이템 초기화
  useEffect(() => {
    if (section && _items) {
      onReset()
    }
  }, [])

  if (section && section.value) {
    return (
      <OrderEditCancelSection
        items={section.value}
        onDeleteAll={onDeleteAll}
        onReset={onReset}
        {...props}
        id={id}
      />
    )
  }

  log.warn(
    '🚀 ~ file: order-edit-return-section.container.tsx:77 ~ section:',
    section
  )

  return <Trans.T>섹션데이터가 없습니다.</Trans.T>
}

export { OrderEditCancelSectionContainer }
