/**
 * @notice
 * 해당 entities에서 사용되다 외부로 공유해서 사용해야한다면
 * `entities/@x`로 이동시켜주세요.
 */

export const FIND_ALL_FILTER_SEARCH_DATES_MAP = {
  ORDER_DATE: 'orderDate', // 주문일자
  PAYMENT_DATE: 'paymentDate', // 결제일자
  INVOICE_NO_CREATE_DATE: 'invoiceNoCreateDate', // 송장 등록 일자
  DELIVERY_SEND_REQUEST_DATE: 'deliverySendRequestDate', // 배송 처리일
  MEMO_CREATE_DATE: 'memoCreateDate', // 메모 작성일
  DELIVERY_COMPLETE_DATE: 'deliveryCompleteDate', // 배송 완료일
  DELIVERY_DELAY_DATE: 'deliveryDelayDate', // 배송 지연일
} as const

export type FindAllFilterSearchDatesType =
  (typeof FIND_ALL_FILTER_SEARCH_DATES_MAP)[keyof typeof FIND_ALL_FILTER_SEARCH_DATES_MAP]

export const FindAllYN = ['Y', 'N'] as const
export type FindAllYNType = (typeof FindAllYN)[number]

export const MEMBER_TYPE = {
  NON_MEMBER: '비회원',
  MEMBER: '회원',
  FIRST_PURCHASE_MEMBER: '첫구매 회원',
} as const
export type MemberType = (typeof MEMBER_TYPE)[keyof typeof MEMBER_TYPE]

export const FILTER_SEARCH_DATES_RANGE_MAP = {
  TODAY: 'TODAY', // 오늘
  YESTERDAY: 'YESTERDAY', // 어제
  WEEK: 'WEEK', // 일주일
  MONTH: 'MONTH', // 1개월
  THREE_MONTHS: 'THREE_MONTHS', // 3개월
  CUSTOM: 'CUSTOM', // 직접 입력
} as const

export type FilterSearchDateRangeType =
  (typeof FILTER_SEARCH_DATES_RANGE_MAP)[keyof typeof FILTER_SEARCH_DATES_RANGE_MAP]

export const CUSTOM_FILTER_IDX = {
  DEFAULT: -1,
} as const

export type CustomFilterIdx =
  (typeof CUSTOM_FILTER_IDX)[keyof typeof CUSTOM_FILTER_IDX]
