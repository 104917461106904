import { z } from 'zod'
import { templateInfoDto } from '../_shared/template-info'
import { 상수_엑셀내보내기상태 } from '~/entities/@x/엑셀내보내기상태'

export const exportTarget = z.enum([
  'currentTab',
  'currentPage',
  'orderDateRange',
  'selectedOrders',
])

// 주문일 기간
const fromDate = z.string().datetime().optional()
const toDate = z.string().datetime().optional()
// 선택한주문
const orderCodes = z.array(z.string()).optional()
const tabColumnCondition = z.any()
const filterCondition = z.any()
const keyword = z.string().nullish()

const requiredOptionsDto = z.object({
  exportTarget,
  templateInfo: templateInfoDto,
  fromDate,
  toDate,
  orderCodes,
  tabColumnCondition,
  filterCondition,
  keyword,
})

const requiredOptionsDtoRefined = requiredOptionsDto
  // ====================================== 주문일 기간
  .refine(
    (data) => {
      if (data.exportTarget === exportTarget.enum.orderDateRange) {
        return !!data.fromDate
      }
      return true
    },
    {
      path: ['fromDate'],
      message: '주문일 기간 - 시작일은 필수입니다.',
    }
  )
  .refine(
    (data) => {
      if (data.exportTarget === exportTarget.enum.orderDateRange) {
        return !!data.toDate
      }
      return true
    },
    {
      path: ['toDate'],
      message: '주문일 기간 - 종료일은 필수입니다.',
    }
  )
  // ====================================== 선택한 주문
  .refine(
    (data) => {
      if (data.exportTarget === exportTarget.enum.selectedOrders) {
        // orderCodes는 필수이어야하고, 50개 이하이어야함
        return !!data.orderCodes
      }
      return true
    },
    {
      path: ['orderCodes'],
      message: '선택한 주문은 1개 이상이어야 합니다.',
    }
  )
  .refine(
    (data) => {
      if (data.exportTarget === exportTarget.enum.selectedOrders) {
        // orderCodes는 필수이어야하고, 50개 이하이어야함
        return !!data.orderCodes && data.orderCodes.length <= 50
      }
      return true
    },
    {
      path: ['orderCodes'],
      message: '선택한 주문은 50개 이하이어야함',
    }
  )
  /**
   * TODO:
   * 전체탭 tabColumnCondition데이터가 string으로 오는데 이것 임시로 막음
   */
  .refine(
    (data) => {
      const _exportTarget = data.exportTarget
      const _tabColumnCondition = data.tabColumnCondition as
        | typeof data.tabColumnCondition
        | string
      if (
        _exportTarget === exportTarget.enum.currentTab &&
        _tabColumnCondition === '{}'
      ) {
        // tabColumnCondition은 필수이어야함
        return false
      }
      return true
    },
    {
      path: ['tabColumnCondition'],
      message: '전체탭은 아직 지원하지 않습니다. (API오류)',
    }
  )

export const postResExcelExportOrderDto = z.any()
export const postPayloadExcelExportOrderDto = z.object({
  requiredOptions: requiredOptionsDtoRefined,
})

export const postResExcelExportPaymentDto = z.any()
export const postPayloadExcelExportPaymentDto = z.object({
  requiredOptions: requiredOptionsDto.omit({ templateInfo: true }),
})

const ExcelExportItemDto = z.object({
  orderExcelExportCode: z.string(),
  fileName: z.string(),
  excelCreator: z.string(),
  statusCd: z.nativeEnum(상수_엑셀내보내기상태),
  expirationTime: z.string(),
  wtime: z.string().datetime(),
})
export const getResExcelExportDto = z.array(ExcelExportItemDto)
export const getParamsExcelExportDto = z.any()

export const getResExcelExportDownloadUrlDto = z.object({
  objectSignedUrl: z.string(),
})
export const getParamsExcelExportDownloadUrlDto = z.any()

/**
 * delete excel-export
 */
export const deleteResExcelExportDto = z.any()
