import React from 'react'
import { __, __e } from '~/shared/i18n'
import { Button, ButtonGroup, Flex, Modal } from '@imwebme/clay-components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { postOrderDelivery } from '~/entities/order-delivery'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { orderDetailOptions } from '~/entities/order-detail'
import { vars } from '@imwebme/clay-token'
import { deliveryCountryQueryOptions } from '~/entities/site/delivery-country'
import { useModals } from '~/router'
import { useModalParams } from '~/shared/hooks/use-modal-params'
import { delivPayloadSchema, TDelivPayloadSchema } from '~/entities/@dto'
import {
  배송국가,
  받는분,
  연락처,
  주소정보_한국,
  주소정보_해외,
  배송메모,
} from '../form-fields'

export function DeliveryAddModal() {
  const modals = useModals()
  const modalParams = useModalParams()
  const orderCode = modalParams?.orderCode as string
  const unitCode = modalParams?.unitCode as string
  const queryClient = useQueryClient()

  const { data: delivCountryList } = useSuspenseQuery(
    deliveryCountryQueryOptions(unitCode)
  )

  const { mutate } = useMutation({
    mutationFn: postOrderDelivery({
      orderCode,
    }),
  })

  const defaultCountry = React.useMemo(
    () =>
      delivCountryList?.find((el) => el.code === 'KR') ?? delivCountryList?.[0],
    [delivCountryList]
  )

  const methods = useForm<TDelivPayloadSchema>({
    resolver: zodResolver(delivPayloadSchema),
    defaultValues: {
      country: defaultCountry.code,
      countryName: defaultCountry.name,
      whoInput: 'orderer',
    },
  })
  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = methods

  const country = getValues('country')

  const onSubmit = handleSubmit((form) => {
    const { kor, intl, ...rest } = form
    mutate(
      { ...kor, ...intl, ...rest },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
          })
          modals.close()
          toast.success(__('배송지가 추가되었습니다'))
        },
        onError(error) {
          toast.error(
            error.response
              ? __e(error.response.data.code)
              : __('배송지 추가에 실패했어요')
          )
        },
      }
    )
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <Flex.Column rowGap={vars.spacing[5]}>
            <배송국가 {...{ delivCountryList }} />
            <Flex columnGap={vars.spacing[3]}>
              <받는분 />
              <연락처 />
            </Flex>
            {country === 'KR' ? <주소정보_한국 /> : <주소정보_해외 />}
            <배송메모 />
          </Flex.Column>
        </Modal.Body>

        <Modal.Footer>
          <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
            <Button
              native={{ type: 'button' }}
              variant="outlined"
              onClick={() => modals.close()}
              text={__('취소')}
            />
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              isDisabled={!isValid}
              text={__('완료')}
            />
          </ButtonGroup>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}
