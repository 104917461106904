import { queryOptions } from '@tanstack/react-query'
import { getOrderDelivery } from './order-delivery.api'

export function orderDeliveryQueryOptions(params: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return queryOptions({
    queryKey: ['order-delivery', params],
    queryFn: getOrderDelivery(params),
  })
}
