import {
  Clay,
  Typography,
  Flex,
  SafeImageLoading,
  Tag,
  Button,
} from '@imwebme/clay-components'
import { X } from '@imwebme/clay-icons'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import prodThumb3 from '~/container/onboarding/assets/prod-thumb-3.png'
import prodThumb4 from '~/container/onboarding/assets/prod-thumb-4.png'
import prodThumb5 from '~/container/onboarding/assets/prod-thumb-5.png'
import { SkyRing } from '~/container/onboarding/partials/sky-ring'

type OrderItem = {
  name: string
  qty: number
  options: string[]
  img: string
}

const ORDER_ITEMS: OrderItem[] = [
  {
    name: __('린넨 - 코튼 버뮤다 팬츠'),
    qty: 3,
    options: [__('사이즈 M'), __('컬러 Wood')],
    img: prodThumb3,
  },
  {
    name: __('오픈 카라 라이트 코튼 폴로 니트'),
    qty: 8,
    options: [__('사이즈 M'), __('컬러 Wood')],
    img: prodThumb4,
  },
  {
    name: __('수피마 코튼 세미 오버핏 유 넥라인 티셔츠'),
    qty: 1,
    options: [__('사이즈 M'), __('컬러 Wood')],
    img: prodThumb5,
  },
]

export function OrderItemsPreview() {
  return (
    <Flex.Column
      padding={`${vars.spacing[4]}`}
      borderRadius={vars.rounded.medium}
      boxShadow={vars.dropShadow.layer}
      backgroundColor={vars.semantic.color.surface}
      width={320}
      gap={vars.spacing[3]}
      position="absolute"
      top={340}
      left={88}
      zIndex={2}
    >
      <Typography variant="body-medium-bold">{__('상품준비')}</Typography>
      <Flex.Column gap={vars.spacing[3]} paddingBottom={vars.spacing[1]}>
        {ORDER_ITEMS.map((item, idx) => (
          <OrderItem key={idx} {...item} />
        ))}
      </Flex.Column>
      <Clay position="relative">
        <Button variant="primary" tonal fullWidth text={__('배송대기 처리')} />
        <SkyRing />
      </Clay>
    </Flex.Column>
  )
}

function OrderItem(props: OrderItem) {
  return (
    <Flex gap={vars.spacing[3]} alignItems="flex-start">
      <Clay
        width={40}
        height={40}
        borderRadius={vars.rounded.small}
        overflow="hidden"
      >
        <SafeImageLoading src={props.img} width="100%" height="100%" />
      </Clay>

      <Flex.Column
        gap={vars.spacing[1]}
        paddingTop={vars.spacing['05']}
        flexGrow={1}
      >
        <Typography variant="body-medium">{props.name}</Typography>
        <Flex gap={vars.spacing[1]}>
          {props.options.map((option, idx) => (
            <Tag key={idx} variant="plain" text={option} />
          ))}
        </Flex>
      </Flex.Column>

      <Flex alignItems="center" paddingTop={vars.spacing['05']}>
        <X />
        <Typography variant="body-medium">{props.qty}</Typography>
      </Flex>
    </Flex>
  )
}
