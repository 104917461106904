import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { Circle } from 'lucide-react'

import { cn } from '~/shared/utils'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Root
    className={cn('grid gap-2', className)}
    {...props}
    ref={ref}
  />
))
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Item
    ref={ref}
    className={cn(
      'aspect-square h-4 w-4 rounded-full border border-solid border-clay-semantic-border hover:border-clay-semantic-borderHover bg-clay-semantic-surface !ring-0 !ring-offset-0 text-clay-semantic-actionPrimary aria-checked:border-clay-semantic-actionPrimary',
      props.disabled &&
        'bg-clay-semantic-actionSecondaryDisabled border-clay-semantic-actionSecondaryDisabled hover:border-0',
      className
    )}
    {...props}
  >
    <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
      <Circle className="h-2 w-2 fill-current text-current" />
    </RadioGroupPrimitive.Indicator>
  </RadioGroupPrimitive.Item>
))
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

// label에 대한
const Label = React.forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<'label'> & { disabled?: boolean }
>(({ className, disabled, ...props }, ref) => (
  <label
    ref={ref}
    className={cn(
      'text-clay-semantic-text',
      className,
      disabled && 'text-clay-semantic-textDisabled cursor-not-allowed'
    )}
    {...props}
  />
))

export { RadioGroup, RadioGroupItem, Label }
