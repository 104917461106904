import { useModals } from '~/router'
import { OrderMemoPreviewWriteFormModal } from './order-memo-preview-write-form-modal'
import { useState } from 'react'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { patchOrderMemo } from '~/entities/order-memo'
import { orderMemoQueryOptions } from '~/entities/order/order-memo'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { useMemoCode } from '~/shared/hooks/use-memo-code'
import { __ } from '~/shared/i18n'
import { querySelector } from './order-memo-preview.fn'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'

export interface OrderMemoPreviewWriteFormModalProps {
  handleModalClose: () => void
  handleSubmitOrderMemo: () => void
  modalTitle: string
  textAreaValue: string
  setTextAreaValue: (value: string) => void
}

export const OrderMemoPreviewWriteFormUpdateModalContainer = () => {
  const queryClient = useQueryClient()
  const modals = useModals()
  const orderCode = useOrderCode()
  const memoCode = useMemoCode()
  const { data: memoData } = useSuspenseQuery({
    ...orderMemoQueryOptions(orderCode),
    select(e) {
      return e.data.find((memo) => memo.idx === Number(memoCode))
    },
  })
  const [textAreaValue, setTextAreaValue] = useState<string>(
    memoData?.memo || ''
  )
  const { mutate: updateOrderMemoMutation } = useMutation({
    mutationFn: patchOrderMemo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderMemoQueryOptions(orderCode).queryKey,
      })
      toast.success(__('메모를 수정했어요.'))
      handleModalClose()
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message)
      }
    },
    mutationKey: ['updateOrderMemo'],
  })

  const handleModalClose = () => modals.close()
  const handleSubmitOrderMemo = () => {
    updateOrderMemoMutation({
      body: {
        memo: textAreaValue,
      },
      orderCode,
      idx: Number(memoCode),
    })
  }

  const props: OrderMemoPreviewWriteFormModalProps = {
    handleModalClose,
    handleSubmitOrderMemo,
    modalTitle: __('메모'),
    textAreaValue,
    setTextAreaValue,
  }

  return <OrderMemoPreviewWriteFormModal {...props} />
}
