/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */
import { z } from 'zod'

export const postResBulkActionCashReceiptValidateDto = z.object({
  totalOrderCount: z.number().nullish().describe('총 주문 건수'),
  rejectedOrderCount: z.number().nullish().describe('처리 불가능한 주문 건수'),
  pgCashReceiptCount: z.number().nullish().describe('연동발급 가능 건수'),
  manualCashReceiptCount: z.number().nullish().describe('수동발급 가능 건수'),
})
export const postPayloadBulkActionCashReceiptValidateDto = z.object({
  targetOrderCodes: z.array(z.string()),
})
