import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Skeleton, DropdownTriggerBtn } from '../partials'
import { ErrorCard } from '~/shared/components/error'
import { match } from 'ts-pattern'
import { YN, TYN } from '~/entities/@x'
import { cn } from '~/shared/utils'

const OrdererPreviewContainer = React.lazy(() =>
  import('./회원/orderer-preview.container').then((module) => ({
    default: module.OrdererPreviewContainer,
  }))
)

const NonmemberOrdererPreviewContainer = React.lazy(() =>
  import('./비회원/nonmember-orderer-preview.container').then((module) => ({
    default: module.NonmemberOrdererPreviewContainer,
  }))
)

const ExternalOrdererPreviewContainer = React.lazy(() =>
  import('./외부채널주문/external-orderer-preview.container').then(
    (module) => ({
      default: module.ExternalOrdererPreviewContainer,
    })
  )
)

interface TPackProps {
  memberCode: string
  saleChannel: React.ReactNode
  orderCode: string
  isMember: TYN
  isExternalOrder: boolean
  children: React.ReactNode
}

export function OrdererPreviewPack({
  memberCode,
  saleChannel,
  orderCode,
  isMember,
  isExternalOrder,
  children,
}: TPackProps) {
  const [open, setOpen] = React.useState(false)

  if (!children) {
    return null
  }
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownTriggerBtn {...{ setOpen }}>{children}</DropdownTriggerBtn>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={8}
          align="start"
          onCloseAutoFocus={(evt) => evt.preventDefault()}
          className={cn(
            'bg-clay-semantic-surface py-clay-4 rounded-clay-medium shadow-clay-layer max-h-[var(--radix-popper-available-height)] overflow-auto',
            isExternalOrder || isMember === YN.N ? 'w-[220px]' : 'w-[200px]'
          )}
          hideWhenDetached
          collisionPadding={12}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Skeleton />}>
              {match({ isMember, isExternalOrder })
                .when(
                  (args) => args.isExternalOrder,
                  () => (
                    <div data-external>
                      <ExternalOrdererPreviewContainer {...{ orderCode }} />
                    </div>
                  )
                )
                .when(
                  (args) => args.isMember === YN.N,
                  () => <NonmemberOrdererPreviewContainer {...{ orderCode }} />
                )
                .when(
                  (args) => args.isMember === YN.Y,
                  () => (
                    <OrdererPreviewContainer
                      {...{
                        memberCode,
                        saleChannel,
                        orderCode,
                      }}
                    />
                  )
                )
                .otherwise(() => null)}
            </React.Suspense>
          </ErrorBoundary>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
