/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */
import { z } from 'zod'

/**
 * post bulk-action-payment
 */
export const postResBulkActionSectionStatusValidateSchema = z.object({
  fulfilled: z.number().nullish(),
  rejected: z.number().nullish(),
})
export const postPayloadBulkActionSectionStatusValidateSchema = z.object({
  targetOrderCodes: z.array(z.string()),
})
