/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { YN } from '~/entities/@x'

/**
 * get parcel-company
 */
export const getResParcelCompanyDto = z.object({
  list: z
    .object({
      idx: z.number(),
      code: z.string().describe('아임웹 택배사 고유코드'),
      name: z.string().describe('택배사명'),
      sortNo: z.number().describe('정렬번호'),
      isUse: z
        .nativeEnum(YN)
        .describe(
          '사용 유무. BO에서 택배사를 지정할 때는 Y인 항목만 보여줘야 한다. 단, 써드 파티를 통해서는 값이 N인 택배사도 저장될 수 있다.'
        ),
      contactType: z
        .enum(['tel', 'mail', 'site'])
        .describe('연락처 타입(전화번호, 이메일 주소, 사이트url)'),
      contact: z
        .string()
        .nullish()
        .describe('연락처 정보(전화번호, 이메일 주소, 사이트url)'),
      website: z.string().nullish().describe('택배사 홈페이지 url'),
      isDefault: z.boolean().describe('기본 택배사 여부'),
    })
    .array(),
})
export const getParamsParcelCompanyDto = z.object({
  orderSectionCode: z.string(),
})
