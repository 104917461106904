import { z } from 'zod'
import {
  postPayloadExcelExportOrderDto,
  postPayloadExcelExportPaymentDto,
} from '.'

export const postPayloadExcelExportOrderRdo =
  postPayloadExcelExportOrderDto.extend({
    _selectTemplate: z.string().optional(),
    _type: z.string().nullish(),
  })

export const postPayloadExcelExportPaymentRdo =
  postPayloadExcelExportPaymentDto.extend({
    _selectTemplate: z.string().optional(),
  })
