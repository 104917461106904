import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { pipe } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import { TReturnRequestUI, type TReturnRequest } from './order-retrun-info.type'
import { __ } from '~/shared/i18n'

export const retrieveTypeCd = ['RRT01', 'RRT02', 'RRT04', 'RRT03'] as const
export const retrievePayTypeCd = ['RPT01', 'RPT02', 'RPT03', 'RPT04'] as const

const targetItemInformationSchema = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
})

const schema = z
  .object({
    returnReasonObj: z.object({
      returnReason: z.string({ required_error: __('필수로 선택해야 해요') }),
    }),
    returnReasonDetail: z.string().optional(),
    targetSectionInformation: z.array(
      z.object({
        orderSectionCode: z.string(),
        targetItemInformation: z.array(targetItemInformationSchema),
      })
    ),
    retrieveRequestDto: z.object({
      // 반품시 물품 수거 타입 코드 RRT01(자동 수거 신청), RRT02(구매자 발송), RRT03(기타)
      retrieveTypeCd: z.enum(retrieveTypeCd),
      // 반품시 수거비용 지불 타입 코드 RPT01(신용), RPT02(선불), RPT03(착불), RPT04(기타)
      retrievePayTypeCd: z.enum(retrievePayTypeCd).optional(),
      // 송장번호
      invoiceNo: z.string().optional(),
      // 굿스플로 계약정보코드(goodsflow_delivery_service_detail.center_code) - 자동수거 신청시 필수
      centerCode: z.string().optional(),
      // 배송지코드
      orderDeliveryCode: z.string().optional(),
      // 받는분명 - 자동 수거 신청, 배송지 임의입력인 경우
      // 수령자명 - 자동 수거 신청, 배송지 임의입력인 경우
      receiverName: z.string().optional(),
      // 수령자전화번호 - 자동 수거 신청, 배송지 임의입력인 경우
      receiverCall: z.string().optional(),
      // 주소 - 자동 수거 신청, 배송지 임의입력인 경우
      addr1: z.string().optional(),
      // 상세주소 - 자동 수거 신청, 배송지 임의입력인 경우
      addr2: z.string().optional(),
      // 배송지 우편번호 - 자동 수거 신청, 배송지 임의입력인 경우
      zipcode: z.string().optional(),
      // 기타 사유
      retrieveMemo: z.string().optional(),
      parcelCompanyIdx: z.number().optional(),
    }),
  })
  // ================================================================================================
  // 반품시 물품 수거 타입(retrieveTypeCd)이 자동 수거 신청(RRT01)이면
  // 수령자명(receiverName), 수령자전화번호(receiverCall), 주소(addr1), 배송지 우편번호(zipcode), 굿스플로 centerCode 필수
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT01') {
        return !!data.retrieveRequestDto.receiverName
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'receiverName'],
      message: __('필수로 입력해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT01') {
        return !!data.retrieveRequestDto.receiverCall
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'receiverCall'],
      message: __('필수로 입력해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT01') {
        return !!data.retrieveRequestDto.addr1
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'receiverCall'],
      message: __('필수로 입력해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT01') {
        return !!data.retrieveRequestDto.zipcode
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'zipcode'],
      message: __('필수로 입력해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT01') {
        return !!data.retrieveRequestDto.centerCode
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'centerCode'],
      message: __('필수로 선택해야 해요'),
    }
  )
  // ================================================================================================
  // 반품시 물품 수거 타입(retrieveTypeCd)이 구매자 발송(RRT02)
  // 반품시 수거비용 지불 타입 코드(retrievePayTypeCd), 송장번호(invoiceNo), 택배사id(parcelCompanyIdx)필수
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT02') {
        return !!data.retrieveRequestDto.retrievePayTypeCd
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'retrievePayTypeCd'],
      message: __('필수로 선택해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT02') {
        return !!data.retrieveRequestDto.invoiceNo
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'invoiceNo'],
      message: __('필수로 입력해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT02') {
        return !!data.retrieveRequestDto.parcelCompanyIdx
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'parcelCompanyIdx'],
      message: __('필수로 선택해야 해요'),
    }
  )
  // 반품시 물품 수거 타입(retrieveTypeCd)이 택배사 수동 등록(RRT04)이면
  // 반품시 수거비용 지불 타입 코드(retrievePayTypeCd), 송장번호(invoiceNo), 택배사id(parcelCompanyIdx)필수
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT04') {
        return !!data.retrieveRequestDto.retrievePayTypeCd
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'retrievePayTypeCd'],
      message: __('필수로 선택해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT04') {
        return !!data.retrieveRequestDto.invoiceNo
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'invoiceNo'],
      message: __('필수로 입력해야 해요'),
    }
  )
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT04') {
        return !!data.retrieveRequestDto.parcelCompanyIdx
      }
      return true
    },
    {
      path: ['retrieveRequestDto', 'parcelCompanyIdx'],
      message: __('필수로 선택해야 해요'),
    }
  )
  // ================================================================================================
  // 반품시 물품 수거 타입(retrieveTypeCd)이 구매자 발송(RRT03)이면 수거 특이사항 메모(retrieveMemo) 필수
  .refine(
    (data) => {
      if (data.retrieveRequestDto.retrieveTypeCd === 'RRT03') {
        return !!data.retrieveRequestDto.retrieveMemo
      }
      // retrieveTypeCd이 RRT03이 아니면 위 컨디셔널 조건을 체크하지 않아도 된다.
      return true
    },
    {
      path: ['retrieveRequestDto', 'retrieveMemo'],
      message: __('필수로 입력해야 해요'),
    }
  )

/**
 * 리퀘스트 값을 다루는 클래스
 */
export class CRetrieveRequest {
  #data: TReturnRequest

  constructor({ returnReasonObj, ...props }: TReturnRequestUI) {
    this.#data = {
      returnReason: returnReasonObj.returnReason,
      ...props,
    }
  }

  // zod.refined의 컨디셔널 조건들에 맞는 payload를 반환한다.
  get submitData() {
    // RRT01(자동 수거 신청)으로 반품신청
    if (this.#data.retrieveRequestDto.retrieveTypeCd === 'RRT01') {
      // 굿스플로우는 자동수거 신청일때만 필요한 데이터다.
      const goodsflow = {
        ...(this.#data.retrieveRequestDto.centerCode
          ? { centerCode: this.#data.retrieveRequestDto.centerCode }
          : {}),
      }
      return {
        ...this.#data,
        retrieveRequestDto: {
          ...goodsflow,
          ...(this.#data.retrieveRequestDto?.orderDeliveryCode && {
            orderDeliveryCode: this.#data.retrieveRequestDto?.orderDeliveryCode,
          }),
          retrieveTypeCd: this.#data.retrieveRequestDto.retrieveTypeCd,
          receiverName: this.#data.retrieveRequestDto.receiverName,
          receiverCall: this.#data.retrieveRequestDto.receiverCall,
          addr1: this.#data.retrieveRequestDto.addr1,
          addr2: this.#data.retrieveRequestDto.addr2,
          zipcode: this.#data.retrieveRequestDto.zipcode,
        },
      } as TReturnRequest
    }
    // RRT02(구매자 발송) or RRT04(택배사 수동 등록)으로 반품신청
    if (
      this.#data.retrieveRequestDto.retrieveTypeCd === 'RRT02' ||
      this.#data.retrieveRequestDto.retrieveTypeCd === 'RRT04'
    ) {
      const { retrieveRequestDto, ...rest } = this.#data
      return {
        ...pipe(
          rest,
          R.filter((a) => a !== undefined)
        ),
        retrieveRequestDto: {
          retrieveTypeCd: this.#data.retrieveRequestDto.retrieveTypeCd,
          retrievePayTypeCd: this.#data.retrieveRequestDto.retrievePayTypeCd,
          invoiceNo: this.#data.retrieveRequestDto.invoiceNo,
          parcelCompanyIdx: this.#data.retrieveRequestDto.parcelCompanyIdx,
        },
      } as TReturnRequest
    }
    // RRT03(기타)으로 반품신청
    if (this.#data.retrieveRequestDto.retrieveTypeCd === 'RRT03') {
      // filter data
      const { retrieveRequestDto, ...rest } = this.#data
      return {
        ...pipe(
          rest,
          R.filter((a) => a !== undefined)
        ),
        retrieveRequestDto: {
          retrieveTypeCd: this.#data.retrieveRequestDto.retrieveTypeCd,
          retrieveMemo: this.#data.retrieveRequestDto.retrieveMemo,
        },
      } as TReturnRequest
    }
    return this.#data
  }
}

export const resolver = zodResolver(schema)
