import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { type ProductSearchContainerProps } from '~t'
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { getProduct } from './product-search-api'
import { useProductSearchAtom } from './product-search.store'
import { siteUnitQueryOptions } from '~/entities/site/unit'

const withProductSearch =
  (WrappedComponent: React.ComponentType<ProductSearchContainerProps>) =>
  ({
    isOpen,
    memberCode,
    orderType,
    ...props
  }: Omit<
    ProductSearchContainerProps,
    | 'items'
    | 'onSelectedItemsChange'
    | 'onValueChange'
    | 'searchValue'
    | 'isLoading'
  > & {
    memberCode: string
    orderType: string
    isOpen?: boolean
  }) => {
    const [searchValue, setSearchValue] = React.useState('')
    const [, selectedSearchAtom] = useProductSearchAtom.search()

    useEffect(() => {
      if (!isOpen) {
        setSearchValue('')
      }
    }, [isOpen])

    // ==================== 사이트정보 START ====================
    const siteRes = useQuery({
      ...siteUnitQueryOptions(),
      select: (d) => d.list[0].code,
    })
    // ==================== 사이트정보 END   ====================

    const { data, isSuccess, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteQuery({
        queryKey: ['상품조회', encodeURIComponent(searchValue)],
        // FIXME: cursor가 없는 경우에는 어떻게 처리해야할까?
        queryFn: ({ pageParam }) =>
          getProduct({
            query: searchValue, //  searchValue && encodeURIComponent(searchValue),
            cursor: pageParam === '' ? undefined : pageParam,
            unitCode: siteRes?.data as string,
            memberCode,
            orderType,
          }),
        getNextPageParam: (lastPage) => lastPage.cursorForNext,
        getPreviousPageParam: (firstPage) => firstPage.cursorForPrev,
        enabled: siteRes.isSuccess,
        initialPageParam: '',
      })

    const items = React.useMemo(() => {
      const result = pipe(
        data?.pages,
        O.fromNullable,
        O.fold(
          () => [],
          flow(
            (e) => e,
            A.map((e) => e.list),
            A.flatten,
            A.map((e) => ({
              ...e,
              options: e.options?.length > 0 ? e.options : undefined,
              optionDetails:
                e.optionDetails?.length > 0 ? e.optionDetails : undefined,
            })),
            (e) => e
          )
        )
      )
      return result
    }, [data?.pages])

    return (
      <WrappedComponent
        {...props}
        searchValue={searchValue}
        onSearchSubmit={(e) => setSearchValue(e)}
        items={isSuccess ? items : []}
        isLoading={!isSuccess}
        onSelectedItemsChange={selectedSearchAtom}
        onInViewCallback={fetchNextPage}
        onInViewDisabled={!hasNextPage || isFetchingNextPage}
      />
    )
  }

export default withProductSearch
