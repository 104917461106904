import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import * as C from './order-search-filter.constants'
import { __ } from '~/shared/i18n'
import {
  YN,
  상수_결제수단,
  상수_배송타입,
  상수_할인타입,
  상수_현금영수증_상태,
} from '~/entities/@x'

export const discountTypeSchema = z.nativeEnum(상수_할인타입)

export const paymentMethodCodeSchema = z.nativeEnum(상수_결제수단)

export const cashReceiptStatusSchema = z.nativeEnum(상수_현금영수증_상태)

export const deliveryTypeCdSchema = z.nativeEnum(상수_배송타입)

export const findAllFilterSearchDatesTypeSchema = z.nativeEnum(
  C.FIND_ALL_FILTER_SEARCH_DATES_MAP
)

export const filterSearchDateRangeTypeSchema: z.ZodType<C.FilterSearchDateRangeType> =
  z.nativeEnum(C.FILTER_SEARCH_DATES_RANGE_MAP)

/**
 * 주문정보 필드 하위 필드들
 */
export const orderSearchProdInfoDto = z.object({
  orderNo: z.number().nullish(),
  ordererName: z.string().nullish(),
  ordererEmail: z.string().nullish(),
  ordererCall: z.string().nullish(),
  invoiceNo: z.string().nullish(),
  depositorName: z.string().nullish(),
  receiverName: z.string().nullish(),
  receiverCall: z.string().nullish(),
  receiverAddress: z.string().nullish(),
  depositBankName: z.string().array().nullish(),
  cashReceiptStatus: cashReceiptStatusSchema.nullish(),
  orderCategory: z.string().array().nullish(),
  deliveryIsInput: z.string().nullish(),
  shopCouponCode: z.string().array().nullish(),
  invoiceIsInput: z.string().nullish(),
  isDeliveryHold: z.nativeEnum(YN).nullish().describe('배송 보류 주문 여부'),
  isRequestPayment: z.nativeEnum(YN).nullish().describe('결제 요청 주문 여부'),
  memoIsInput: z.string().nullish(),
})

export const orderSearchFilterFormDto = orderSearchProdInfoDto.extend({
  name: z
    .string()
    .min(2, { message: __('최소 2자 이상') })
    .max(20, { message: __('최대 20자 이하') }),
  searchDateType: findAllFilterSearchDatesTypeSchema.nullish(),
  dateRange: filterSearchDateRangeTypeSchema.nullish(),
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  pgMethod: paymentMethodCodeSchema.array().nullish(),
  discountType: discountTypeSchema.array().nullish(),
  minPaymentPrice: z.number().nullish(),
  maxPaymentPrice: z.number().nullish(),
  orderItemCount: z.number().nullish(),
  isMember: z.nativeEnum(YN).nullish(),
  isFirstOrderUser: z.nativeEnum(YN).nullish(),
  deliveryDelayDay: z.number().nullish(),
  deliveryTypeCd: deliveryTypeCdSchema.array().nullish(),
  prodCode: z.string().array().nullish(),
})

export const orderSearchFilterFormResolver = zodResolver(
  orderSearchFilterFormDto
)
