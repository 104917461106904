import { ApiClient } from '~/shared/api'
import type {
  TGetParamsParcelCompanyDto,
  TGetResParcelCompanyDto,
} from './parcel-company.type'
import { getResParcelCompanyDto } from './parcel-company.dto'
import { T상수_판매채널 } from '../@x'

const axios = ApiClient()

/**
 * @API문서
 * @description 판매채널 택배사 리스트 조회
 */
export function getParcelCompany({
  saleChannelIdx,
}: {
  saleChannelIdx: T상수_판매채널
}) {
  return function (_params?: TGetParamsParcelCompanyDto) {
    return async function (_p: {
      queryKey: [
        string,
        {
          saleChannelIdx: T상수_판매채널
          orderSectionCode?: string
        },
      ]
    }) {
      const params = _params ?? {}
      const result = await axios
        .get<ApiResponse<TGetResParcelCompanyDto>>(
          `/parcel-company/sale-channel/${saleChannelIdx}`,
          {
            params,
          }
        )
        .then((res) => res.data)

      return result
    }
  }
}
