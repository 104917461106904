import { ApiClient } from '~/shared/api'
import {
  type TGetResMemberDiscountInfoDto,
  type TGetPayloadMemberDiscountInfoDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 회원 할인 정보 조회
 */
export async function getMemberDiscountInfo({
  memberCode,
  params,
}: {
  memberCode: string
  params: TGetPayloadMemberDiscountInfoDto
}) {
  const result = await axios
    .get<
      ApiResponse<TGetResMemberDiscountInfoDto>
    >(`/member/${memberCode}/discount-info`, { params })
    .then((res) => res.data)

  return result.data
}
