import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TReturnDoneSellerBase } from './return-done-seller.type'
import { useReturnDoneSellerAtom } from './return-done-seller.store'
import { ContentsBanner, ContentsBannerProps } from '@imwebme/clay-components'

const ReturnDoneSeller = React.lazy(() =>
  import('./return-done-seller').then((module) => ({
    default: React.memo(module.ReturnDoneSeller),
  }))
)

interface TReturnDoneSellerContainer extends TReturnDoneSellerBase {}
export function ReturnDoneSellerContainer(props: TReturnDoneSellerContainer) {
  const [open, setState] = useReturnDoneSellerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ReturnDoneSeller {...props} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

interface TReturnDoneSellerBanner extends TReturnDoneSellerBase {
  variant?: ContentsBannerProps['variant']
  text?: ContentsBannerProps['text']
  buttonText?: string
}
export function ReturnDoneSellerBanner({
  variant = 'critical',
  text = __('판매자가 접수한 반품 건이예요.'),
  buttonText = __('사유보기'),
  ...props
}: TReturnDoneSellerBanner) {
  const [open, setState] = useReturnDoneSellerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <ContentsBanner
      type="secondary"
      variant={variant}
      text={text}
      button={{ text: buttonText, onClick: () => onOpenChange(true) }}
    />
  )
}
