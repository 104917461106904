import React from 'react'
import { Card } from '~/shared/components/ui/card'
import { OrderFormData } from '~/entities/order-detail'
import { __ } from '~/shared/i18n'
import * as ListItem from './form-data-info-item'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { OrderFormDataEditButton } from '../order-form-data-edit/order-form-data-edit.button'
import { showFormDataInfo } from './form-data-info.fn'
import { 상수_추가정보타입 } from '~/entities/@x'
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  orderFormData: OrderFormData[]
}

const FormDataComponent: Record<
  OrderFormData['inputTypeCd'],
  (props: OrderFormData) => EmotionJSX.Element | null
> = {
  [상수_추가정보타입.한줄텍스트]: ListItem.Text,
  [상수_추가정보타입.여러줄텍스트]: ListItem.Textarea,
  [상수_추가정보타입.단일선택]: ListItem.Radio,
  [상수_추가정보타입.목록선택]: ListItem.Select,
  [상수_추가정보타입.복수선택]: ListItem.Checkbox,
  [상수_추가정보타입.날짜형식]: ListItem.Date,
  [상수_추가정보타입.시간형식]: ListItem.Time,
  [상수_추가정보타입.파일]: ListItem.File,
}

export function FormDataInfo({ orderFormData, ...props }: Props) {
  if (!showFormDataInfo(orderFormData)) {
    return null
  }
  return (
    <Card.Root
      {...props}
      className="border h-auto w-[auto] items-center bg-clay-semantic-surface rounded-[12px] border-none py-[20px]"
    >
      <Card.Header className="flex flex-row justify-between items-center p-0 px-[24px] pb-[20px]">
        <Card.Title className="typo-clay-heading-medium-bold">
          {__('주문 추가정보')}
        </Card.Title>
        <OrderFormDataEditButton />
      </Card.Header>
      <Card.Content className="space-y-[24px] p-0 px-[24px]">
        {orderFormData.map((data) => {
          const Component = FormDataComponent[data.inputTypeCd]
          if (!Component) {
            return null
          }
          return <Component key={data.idx} {...data} />
        })}
      </Card.Content>
    </Card.Root>
  )
}
