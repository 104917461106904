import { useOrderSectionAtom } from '~t'
import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'

export const useCalc = () => {
  // 추가페이지에서 변경된 사항으로 최종 계산된 금액
  const updateItemHasSection = useOrderSectionAtom.valueUpdateAll()
  const calc = React.useCallback(
    () =>
      pipe(
        updateItemHasSection,
        O.fromNullable,
        O.fold(
          () => 0,
          flow(
            A.map(
              flow(
                (e) => e.value,
                O.fromNullable,
                O.fold(
                  () => 0,
                  flow(
                    A.map(
                      (e) =>
                        ((e.price || e.originalPrice || 0) -
                          (e.discountPrice || 0)) *
                        (e.qtyChange || 0)
                    ),
                    A.reduce(0, (acc, cur) => acc + cur)
                  )
                )
              )
            ),
            A.reduce(0, (acc, cur) => acc + cur)
          )
        )
      ),
    [updateItemHasSection]
  )
  return calc()
}
