/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { nullish } from 'ts-pattern/dist/patterns'
import { z } from 'zod'
import { YN } from '../@x'

/**
 * get external-order-delivery
 */
export const getResExternalOrderDeliveryDto = z.object({
  siteCode: z.string().describe('사이트 코드'),
  orderDeliveryCode: z.string().describe('주문배송정보코드'),
  orderCode: z.string().describe('주문 코드'),
  receiverName: z.string().describe('수령자명'),
  receiverCall: z.string().describe('수령자전화번호'),
  zipcode: z.string().min(1).max(8).describe('배송지 우편번호'),
  addr1: z.string().min(1).max(200).describe('배송지 주소1'),
  addr2: z.string().min(1).max(200).nullish().describe('배송지 주소2'),
  building: z.string().nullish().describe('배송지 건물명'),
  street: z.string().nullish().describe('배송지 거리명'),
  city: z.string().nullish().describe('배송지 도시'),
  state: z.string().nullish().describe('배송지 주'),
  country: z.string().nullish().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().nullish().describe('배송지 국가명'),
  memo: z.string().min(1).max(200).nullish().describe('배송 요청사항'),
  isInput: z.nativeEnum(YN).describe('선물배송지입력여부'),
  whoInput: z
    .union([z.literal('orderer'), z.literal('receiver')])
    .describe('배송지입력한대상'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  wtime: z.string().datetime().describe('등록일시'),
  mtime: z.string().datetime().describe('변경일시'),
})

/**
 * post external-order-delivery
 */
export const postResExternalOrderDeliveryDto = z.object({
  siteCode: z.string().describe('사이트 코드'),
  orderDeliveryCode: z.string().describe('주문배송정보코드'),
  orderCode: z.string().describe('주문 코드'),
  receiverName: z.string().describe('수령자명'),
  receiverCall: z.string().describe('수령자전화번호'),
  zipcode: z.string().min(1).max(8).describe('배송지 우편번호'),
  addr1: z.string().min(1).max(200).describe('배송지 주소1'),
  addr2: z.string().min(1).max(200).nullish().describe('배송지 주소2'),
  building: z.string().nullish().describe('배송지 건물명'),
  street: z.string().nullish().describe('배송지 거리명'),
  city: z.string().nullish().describe('배송지 도시'),
  state: z.string().nullish().describe('배송지 주'),
  country: z.string().nullish().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().nullish().describe('배송지 국가명'),
  memo: z.string().min(1).max(200).nullish().describe('배송 요청사항'),
  isInput: z.nativeEnum(YN).describe('선물배송지입력여부'),
  whoInput: z
    .union([z.literal('orderer'), z.literal('receiver')])
    .describe('배송지입력한대상'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  wtime: z.string().describe('등록일시'),
  mtime: z.string().describe('변경일시'),
})
export const postPayloadExternalOrderDeliveryDto = z.object({
  receiverName: z.string().describe('수령자명'),
  receiverCall: z.string().describe('수령자전화번호'),
  zipcode: z.string().min(1).max(8).describe('배송지 우편번호'),
  addr1: z.string().min(1).max(200).describe('배송지 주소1'),
  addr2: z.string().min(1).max(200).nullish().describe('배송지 주소2'),
  building: z.string().nullish().describe('배송지 건물명'),
  street: z.string().nullish().describe('배송지 거리명'),
  city: z.string().nullish().describe('배송지 도시'),
  state: z.string().nullish().describe('배송지 주'),
  country: z.string().nullish().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().nullish().describe('배송지 국가명'),
  whoInput: z
    .union([z.literal('orderer'), z.literal('receiver')])
    .describe('배송지입력한대상'),
})

/**
 * patch external-order-delivery
 */
export const patchResExternalOrderDeliveryDto = z.object({
  siteCode: z.string().describe('사이트 코드'),
  orderDeliveryCode: z.string().describe('주문배송정보코드'),
  orderCode: z.string().describe('주문 코드'),
  receiverName: z.string().describe('수령자명'),
  receiverCall: z.string().describe('수령자전화번호'),
  zipcode: z.string().min(1).max(8).describe('배송지 우편번호'),
  addr1: z.string().min(1).max(200).describe('배송지 주소1'),
  addr2: z.string().min(1).max(200).nullish().describe('배송지 주소2'),
  building: z.string().nullish().describe('배송지 건물명'),
  street: z.string().nullish().describe('배송지 거리명'),
  city: z.string().nullish().describe('배송지 도시'),
  state: z.string().nullish().describe('배송지 주'),
  country: z.string().nullish().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().nullish().describe('배송지 국가명'),
  memo: z.string().min(1).max(200).nullish().describe('배송 요청사항'),
  isInput: z.nativeEnum(YN).describe('선물배송지입력여부'),
  whoInput: z
    .union([z.literal('orderer'), z.literal('receiver')])
    .describe('배송지입력한대상'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  // wtime: z.string().describe('등록일시'),
  mtime: z.string().describe('변경일시'),
})
export const patchPayloadExternalOrderDeliveryDto = z.object({
  receiverName: z.string().describe('수령자명'),
  receiverCall: z.string().describe('수령자전화번호'),
  zipcode: z.string().min(1).max(8).describe('배송지 우편번호'),
  addr1: z.string().min(1).max(200).describe('배송지 주소1'),
  addr2: z.string().min(1).max(200).nullish().describe('배송지 주소2'),
  building: z.string().nullish().describe('배송지 건물명'),
  street: z.string().nullish().describe('배송지 거리명'),
  city: z.string().nullish().describe('배송지 도시'),
  state: z.string().nullish().describe('배송지 주'),
  country: z.string().nullish().describe('배송지 국가코드(ISO_3166)'),
  countryName: z.string().nullish().describe('배송지 국가명'),
  whoInput: z
    .union([z.literal('orderer'), z.literal('receiver')])
    .describe('배송지입력한대상'),
})

/**
 * delete external-order-delivery
 */
export const deleteResExternalOrderDeliveryDto = z.any()
export const deletePayloadExternalOrderDeliveryDto = z.any()

/**
 * post order-delivery-goodsflow
 */
export const postResExternalOrderDeliveryGoodsflowDto = z.object({
  data: z.object({
    goodsflowPopUpUrl: z.string(),
  }),
})
export const postPayloadExternalOrderDeliveryGoodsflowDto = z.object({
  orderSectionCode: z.string(),
  goodsflowCenterCode: z.string(),
})
