import * as React from 'react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '~/shared/utils'
import { OptionList, LocalColorSchemeArea } from '@imwebme/clay-components'

const DropdownMenuGroup = DropdownMenuPrimitive.Group

const DropdownMenuPortal = DropdownMenuPrimitive.Portal

const DropdownMenuSub = DropdownMenuPrimitive.Sub

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuSubTrigger = DropdownMenuPrimitive.SubTrigger

const DropdownMenuSubContent = DropdownMenuPrimitive.SubContent

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    isDark?: boolean
  }
>(({ sideOffset = 8, children, isDark = false, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content ref={ref} sideOffset={sideOffset} {...props}>
      {isDark ? (
        <LocalColorSchemeArea theme="dark">
          <OptionList popOver as="div">
            {children}
          </OptionList>
        </LocalColorSchemeArea>
      ) : (
        <OptionList popOver as="div">
          {children}
        </OptionList>
      )}
    </DropdownMenuPrimitive.Content>
  </DropdownMenuPrimitive.Portal>
))
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const itemBaseVariants = cva(
  `cursor-pointer bg-clay-semantic-surface hover:bg-clay-semantic-actionSecondaryHover !text-clay-semantic-text data-[state=checked]:bg-clay-semantic-actionPrimaryTonal focus-visible:outline-none
  data-[disabled]:text-clay-semantic-textDisabled data-[disabled]:bg-transparent data-[disabled]:cursor-not-allowed
  `,
  {
    variants: {
      size: {
        default:
          'px-clay-3 py-clay-2 rounded-clay-medium typo-clay-label-medium',
        large: 'px-clay-3 py-clay-3 rounded-clay-medium typo-clay-label-medium',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
)

/**
 * 클레이의 Option Item을 따릅니다.
 * clay-components의 OptionItem이 radix-ui와 호환성이 좋지 않아(예를 들어 OptionItem에 forwardRef가 필요해 보입니다.) 스타일을 직접 작성했습니다.
 */
const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  Omit<
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>,
    'onClick'
  > &
    VariantProps<typeof itemBaseVariants>
>(({ className, size, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(itemBaseVariants({ size }), className)}
    {...props}
  />
))
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

const DropdownMenuCheckboxItem = DropdownMenuPrimitive.CheckboxItem

const DropdownMenuRadioItem = DropdownMenuPrimitive.RadioItem

const DropdownMenuLabel = DropdownMenuPrimitive.Label

const DropdownMenuSeparator = DropdownMenuPrimitive.Separator

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuLabel,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuGroup,
  DropdownMenuSub,
}
