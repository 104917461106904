import React from 'react'
import { SetStateAction, atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

/**
 * @Require
 * rename
 * UUID
 * key_name
 * debug_label
 * key_name
 */

type AtomKey = {
  UUID: string
}
type AtomType = {
  key: string
  value?: boolean
}
const receiverInfoCardState = atomFamily(
  ({ key, value = false }: AtomType) => {
    const rat = atomWithReset<AtomType>({
      key,
      value,
    })

    const at = atom(
      (get) => get(rat).value,
      (_get, set, update: SetStateAction<AtomType['value']> | typeof RESET) => {
        if (update === RESET) {
          set(rat, RESET)
          return
        }
        if (typeof update === 'function') {
          set(rat, (prev) => {
            const v = update(prev.value)
            return { key, value: v }
          })
          return
        }
        set(rat, { key, value: update })
      }
    )
    at.debugLabel = `[key_name:${key}]:debug_label`
    return at
  },
  (a, b) => a.key === b.key
)

export const useReceiverInfoCardAtom = {
  key: (p: AtomKey) => `${p.UUID}:key_name`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          receiverInfoCardState({
            key: useReceiverInfoCardAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          receiverInfoCardState({
            key: useReceiverInfoCardAtom.key(p),
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          receiverInfoCardState({
            key: useReceiverInfoCardAtom.key(p),
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          receiverInfoCardState({
            key: useReceiverInfoCardAtom.key(p),
          }),
        [p]
      )
    ),
}
