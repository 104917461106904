import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { IconArrowLeft } from '@imwebme/icon'
import { useSuspenseQuery } from '@tanstack/react-query'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { OrderEditCalcCard, OrderEditHeader, OrderHeaderStep } from '~t'
import { OrderTimelinePack } from '~/container/order/order-timeline'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { patchPayloadCancelRequestRdo } from '~/entities/external-order-section/cancel-request'
import React from 'react'
import { ExternalReturnItemMutableList } from './partials/external-return-item-mutable-list'
import { ExternalOrderReturnSelectSectionPack } from './partials/section'
import { Trans } from 'react-i18next'
import { useCurrency } from '~/shared/hooks/use-currency'
import { Button } from '@imwebme/clay-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ContentsBanner } from '@imwebme/clay-components'
import { TTargetSectionInformationRdo } from '~/entities/external-order-section/return-request'
import {
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '~/entities/external-order/model/external-order-section'

export function ExternalOrderReturnSelect() {
  const router = useNavigate()

  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })

  const filteredOrderSectionList = React.useMemo(
    () =>
      pipe(
        data.orderSectionList,
        O.fromNullable,
        O.fold(
          () => null,
          A.filter((e) => {
            const $exSection = new 모델_외부채널_주문섹션(e)
            return 스펙_외부채널_주문섹션.배송중
              .or(스펙_외부채널_주문섹션.배송완료)
              .or(스펙_외부채널_주문섹션.구매확정)
              .isSatisfiedBy($exSection)
          })
        )
      ),
    [data]
  )

  const methods = useForm({
    resolver: zodResolver(
      patchPayloadCancelRequestRdo.omit({
        cancelReasonCode: true,
        cancelReasonDetail: true,
      })
    ),
    defaultValues: React.useMemo(() => {
      const result = pipe(
        filteredOrderSectionList,
        O.fromNullable,
        O.fold(
          () => null,
          flow(
            A.map((e) => ({
              orderSectionCode: e.orderSectionCode,
              targetItemInformation: pipe(
                e.orderSectionItemList!,
                A.map((e2) => ({
                  _check: false,
                  qty: e2.qty,
                  orderSectionItemCode: e2.orderSectionItemCode,
                }))
              ),
            })),
            (e) => ({
              targetSectionInformation: e,
            })
          )
        )
      )
      return result ?? undefined
    }, [filteredOrderSectionList]),
    mode: 'all',
  })
  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const onSubmit = handleSubmit((body) => {
    const isChecked = pipe(
      body.targetSectionInformation,
      A.filter((e) => e.targetItemInformation.some((e2) => e2._check === true)),
      (e) => e.length > 0
    )
    if (isChecked === false) {
      toast.error(__('취소할 상품을 선택해주세요'))
      return
    }

    const sectionItemCodesBase64Params = pipe(
      body.targetSectionInformation,
      A.map(
        flow((e) => {
          const _result = pipe(
            e.targetItemInformation,
            A.filter((e2) => e2._check),
            A.map((e2) => e2.orderSectionItemCode)
          )
          return `${e.orderSectionCode}=${_result.join(',')}`
        })
      ),
      // 상품코드를 base64로 인코딩
      (e) => btoa(e.join('|')),
      pipeLog('취소할 상품 코드')
    )
    router(
      `/order/${data.saleChannelIdx}/${orderCode}/return/info?data=${sectionItemCodesBase64Params}`
    )
  })
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Header />
        <BannerArea />
        <div className="mt-[32px] mb-[70px] min-w-[992px] overflow-auto">
          <div className="w-[970px] mx-auto">
            <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-x-[20px] gap-y-[20px]">
              <div className="grid gap-y-[20px]">
                {pipe(
                  filteredOrderSectionList,
                  O.fromNullable,
                  O.fold(
                    () => null,
                    flow(
                      A.mapWithIndex((index, item) => (
                        <React.Fragment key={item.orderSectionNo}>
                          <ExternalOrderReturnSelectSectionPack
                            sectionCode={item.orderSectionCode}
                            index={index}
                          >
                            <ExternalReturnItemMutableList
                              index={index}
                              sectionCode={item.orderSectionCode}
                            />
                          </ExternalOrderReturnSelectSectionPack>
                        </React.Fragment>
                      ))
                    )
                  )
                )}
              </div>
              <div>
                <TotalCard />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export function useFormContextExternalOrderReturnSelect() {
  const form = useFormContext<TTargetSectionInformationRdo>()
  return form
}

function Header() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{ orderCode, saleChannel: String(data.saleChannelIdx) }}
        >
          <div className="flex gap-x-[4px] items-center">
            <IconArrowLeft className="stroke-[2]" />
            <span>{data.orderNo}</span>
          </div>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('반품')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: __('품목 선택'),
              active: true,
            },
            {
              id: '2',
              label: __('반품 정보 입력'),
              active: false,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <div className="space-x-[8px]">
          <Button
            native={{ type: 'submit' }}
            variant="primary"
            text={__('다음')}
          />
        </div>
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

function BannerArea() {
  return (
    <div className="mt-[32px] mb-[24px] min-w-[992px] overflow-auto">
      <div className="w-[970px] mx-auto">
        <ContentsBanner
          type="secondary"
          variant="critical"
          text={__(
            '외부몰 연동 주문은 상품 단위 별 반품만 가능하며 정확한 환불 금액은 해당 판매자센터에서 확인해 주세요.'
          )}
        />
      </div>
    </div>
  )
}

function TotalCard() {
  const currency = useCurrency({ isExternal: true })
  const { watch } = useFormContextExternalOrderReturnSelect()
  const orderCode = useOrderCode()

  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })

  const selectItemData = pipe(
    data.orderSectionList,
    O.fromNullable,
    O.fold(
      () => null,
      flow(
        A.map(
          flow(
            O.fromNullable,
            O.getOrElseW(() => {
              throw new Error(
                '🚀 ~ file: external-order-cancel-info.tsx:108 ~ O.getOrElseW ~ data.orderSectionList'
              )
            }),
            (e) => e.orderSectionItemList,
            O.fromNullable,
            O.getOrElseW(() => {
              throw new Error(
                '🚀 ~ file: external-order-cancel-info.tsx:108 ~ O.getOrElseW ~ data.orderSectionList'
              )
            })
          )
        ),
        A.flatten,
        A.filter((_item) =>
          pipe(
            watch('targetSectionInformation'),
            A.map((e) => e.targetItemInformation),
            A.flatten,
            A.reduce(
              false,
              (acc, item) =>
                acc ||
                (item.orderSectionItemCode === _item.orderSectionItemCode &&
                  item._check)
            )
          )
        ),
        A.reduce(0, (acc, item) => acc + item.qty * item.itemPrice)
      )
    )
  )

  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price: selectItemData ?? 0,
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b className="typo-clay-heading-large-bold" /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}
