import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@tanstack/react-query'
import {
  orderSearchTabQueryOptions,
  TGetResOrderSearchTabSchema,
} from '~/entities/order-search-tab'

export function useTabCode() {
  const { data: allTabCode } = useSuspenseQuery({
    ...orderSearchTabQueryOptions(),
    select: querySelectorFirstTab,
  })

  const params = useParams()
  const tabCode = params.tabCode

  if (tabCode === undefined) {
    return allTabCode
  }
  return tabCode
}

export function useIsDefaultTab() {
  const { data: allTabCode } = useSuspenseQuery({
    ...orderSearchTabQueryOptions(),
    select: querySelectorDefaultTab,
  })

  const params = useParams()
  const tabCode = params.tabCode

  if (tabCode === undefined) {
    return true
  }
  if (tabCode === allTabCode) {
    return true
  }
  return false
}

function querySelectorDefaultTab(
  data: ApiResponse<TGetResOrderSearchTabSchema>
) {
  return data.data.list.find((t) => t.name === '전체')?.adminOrderSearchTabCode
}

function querySelectorFirstTab(data: ApiResponse<TGetResOrderSearchTabSchema>) {
  return data.data.list[0].adminOrderSearchTabCode ?? 'all'
}
