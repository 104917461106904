/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { pipe } from 'fp-ts/lib/function'
import { z } from 'zod'
import * as O from 'fp-ts/Option'
import { 상수_수거결제타입, 상수_수거타입 } from '~/entities/@x'

const afterOrderSectionItemDto = z.object({
  orderSectionItemCode: z.string().describe('변경하려는 품목 코드'),
  qty: z.number().describe('변경하려는 품목의 수량'),
})
export const patchResReturnRequestDto = z.object({
  isPartial: z.boolean().nullish().describe('부분처리 여부'),
  beforeOrderSectionNo: z.string().describe('기존 섹션번호'),
  afterOrderSectionNo: z
    .string()
    .describe('요청된 또는 새롭게 생성된 섹션번호'),
  beforeOrderSectionCode: z.string().describe('기존 섹션코드'),
  afterOrderSectionCode: z
    .string()
    .describe('요청된 또는 새롭게 생성된 섹션 코드'),
  afterOrderSectionItems: z
    .array(afterOrderSectionItemDto)
    .describe('이동된 품목정보'),
})

export const targetItemInformationDto = z.object({
  orderSectionItemCode: z.string().describe('변경하려는 품목의 코드'),
  qty: z.number().describe('변경하려는 품목의 수량'),
})

export const targetSectionInformationDto = z.object({
  targetSectionInformation: z
    .array(
      z.object({
        orderSectionCode: z.string().describe('섹션코드'),
        targetItemInformation: z
          .array(targetItemInformationDto)
          .describe('변경하려는 품목의 코드와 수량'),
      })
    )
    .describe('변경하려는 섹션코드와 하위 품목의 코드와 수량'),
})

export const retrieveRequestDto = z
  .object({
    retrieveTypeCd: z
      .nativeEnum(상수_수거타입)
      .describe(
        '반품시 물품 수거 타입 코드 RRT01(자동 수거 신청), RRT02(구매자 발송), RRT03(기타), RRT04(수동 수거 신청 (판매자 직접 송장번호 입력))'
      ),
    centerCode: z
      .string()
      .nullish()
      .describe(
        '굿스플로 계약정보코드(goodsflow_delivery_service_detail.center_code) - 자동수거 신청시 필수'
      ),
    orderDeliveryCode: z.string().nullish().describe('배송지코드'),
    receiverName: z
      .string()
      .nullish()
      .describe('수령자명 - 자동 수거 신청, 배송지 임의입력인 경우'),
    receiverCall: z
      .string()
      .nullish()
      .describe('수령자전화번호 - 자동 수거 신청, 배송지 임의입력인 경우'),
    addr1: z
      .string()
      .nullish()
      .describe('주소 - 자동 수거 신청, 배송지 임의입력인 경우'),
    addr2: z
      .string()
      .nullish()
      .describe('상세주소 - 자동 수거 신청, 배송지 임의입력인 경우'),
    zipcode: z
      .string()
      .nullish()
      .describe('배송지 우편번호 - 자동 수거 신청, 배송지 임의입력인 경우'),
    // RRT02
    retrievePayTypeCd: z
      .nativeEnum(상수_수거결제타입)
      .nullish()
      .describe(
        '반품시 수거비용 지불 타입 코드 RPT01(신용), RPT02(선불), RPT03(착불), RPT04(기타)'
      ),
    invoiceNo: z.string().min(3).nullish().describe('송장번호'),
    parcelCompanyIdx: z.number().nullish().describe('택배사코드'),
    // RRT03
    retrieveMemo: z.string().nullish().describe('수거 특이사항 메모'),
  })
  .superRefine((_retrieveRequestDto, ctx) => {
    if (_retrieveRequestDto.retrieveTypeCd === 상수_수거타입.자동수거신청) {
      if (!_retrieveRequestDto.centerCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          message: 'centerCode is required',
          path: ['retrieveRequestDto', 'centerCode'],
          expected: 'string',
          received: 'null',
        })
      }
    }
    if (_retrieveRequestDto.retrieveTypeCd === 상수_수거타입.구매자발송) {
      pipe(
        _retrieveRequestDto.retrievePayTypeCd,
        O.fromNullable,
        O.getOrElseW(() => {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            message: 'retrievePayTypeCd is required',
            path: ['retrieveRequestDto', 'retrievePayTypeCd'],
            expected: 'string',
            received: 'null',
          })
        })
      )
    }
    if (_retrieveRequestDto.retrieveTypeCd === 상수_수거타입.수동수거신청) {
      pipe(
        _retrieveRequestDto.retrievePayTypeCd,
        O.fromNullable,
        O.getOrElseW(() => {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            message: 'retrievePayTypeCd is required',
            path: ['retrieveRequestDto', 'retrievePayTypeCd'],
            expected: 'string',
            received: 'null',
          })
        })
      )
      pipe(
        _retrieveRequestDto.invoiceNo,
        O.fromNullable,
        O.getOrElseW(() => {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            message: 'invoiceNo is required',
            path: ['retrieveRequestDto', 'invoiceNo'],
            expected: 'string',
            received: 'null',
          })
        })
      )
      pipe(
        _retrieveRequestDto.parcelCompanyIdx,
        O.fromNullable,
        O.getOrElseW(() => {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            message: 'parcelCompanyIdx is required',
            path: ['retrieveRequestDto', 'parcelCompanyIdx'],
            expected: 'string',
            received: 'null',
          })
        })
      )
    }
  })

export const patchPayloadReturnRequestDto = targetSectionInformationDto.extend({
  retrieveRequestDto,
  returnReasonCode: z.string().min(1).describe('반품사유코드'),
  returnReasonDetail: z.string().nullish().describe('반품사유 상세'),
})
