import React from 'react'
import { TExternalReturnDoneBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalReturnDoneContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalReturnDone }))
)

interface TExternalReturnDonePack extends TExternalReturnDoneBase {}
export function ExternalReturnDonePack(props: TExternalReturnDonePack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalReturnDoneContainer {...props} />
    </React.Suspense>
  )
}
