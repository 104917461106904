import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import type { TOrder } from '../class-order.type'

export const OrderCancelAtom = atom<TOrder | undefined>(undefined)
OrderCancelAtom.debugLabel = 'OrderCancelAtom'

export const OrderCancelConfirmAtom = atom<
  | {
      orderSectionCode: string
      items: {
        orderSectionItemCode?: string
        prodCode: string
        prodOptionDetailCode?: string
        prodName: string
        qty: number
        baseItemPrice: number
        itemPrice: number
      }[]
    }[]
  | undefined
>(undefined)
OrderCancelConfirmAtom.debugLabel = 'OrderCancelConfirmAtom'

export const OrderCancelBannerAtom = atomFamily(
  ({ id, component }: { id: string; component?: React.ReactNode }) => {
    const at = atom(
      {
        id,
        component,
      },
      (_, set, update) => {
        set(at, update)
      }
    )
    return at
  },
  (a, b) => a.id === b.id
)
