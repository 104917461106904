import React from 'react'
import { __ } from '~/shared/i18n'
import { TMemberInfoModalBase, useMemberInfoModalAtom } from '.'
import {
  Modal,
  ModalHeader,
  Tab,
  TabList,
  Tabs,
  Clay,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { match } from 'ts-pattern'

const MemberInfoDetail = React.lazy(() =>
  import('./member-info-views/member-info-detail').then((mod) => ({
    default: mod.MemberInfoDetail,
  }))
)
const OrderHistory = React.lazy(() =>
  import('./member-info-views/order-history').then((mod) => ({
    default: mod.OrderHistory,
  }))
)
const Qna = React.lazy(() =>
  import('./member-info-views/qna').then((mod) => ({ default: mod.Qna }))
)
const AdminMemo = React.lazy(() =>
  import('./member-info-views/admin-memo').then((mod) => ({
    default: mod.AdminMemo,
  }))
)

const TAB_NAMES = [
  '회원 정보',
  '주문 내역',
  '문의 내역',
  '관리자 메모',
] as const

interface TMemberInfoModalPack extends TMemberInfoModalBase {}
export function MemberInfoModalPack(props: TMemberInfoModalPack) {
  const [open, setModal] = useMemberInfoModalAtom.state({
    memberCode: props.memberCode + (props.atomKey || ''),
  })
  const [tabIdx, setTabIdx] = React.useState(0)

  function handleClose() {
    setModal(false)
    setTabIdx(0)
  }

  return (
    <Modal width="medium" isOpen={!!open} setClose={handleClose}>
      <ModalHeader text={__('회원 정보')} closeButton />
      <Clay
        padding={`${vars.spacing[6]} ${vars.spacing[6]} 0px`}
        marginBottom={vars.spacing[5]}
      >
        <Tabs variant="filled" onChange={setTabIdx} index={tabIdx}>
          <TabList>
            {TAB_NAMES.map((tabName, idx) => (
              <Tab key={idx} text={__(tabName)} />
            ))}
          </TabList>
        </Tabs>
      </Clay>

      <React.Suspense fallback={<Clay height="552px" />}>
        <Clay height="552px">
          {match(TAB_NAMES[tabIdx])
            .with('회원 정보', () => <MemberInfoDetail />)
            .with('주문 내역', () => <OrderHistory />)
            .with('문의 내역', () => <Qna />)
            .with('관리자 메모', () => <AdminMemo />)
            .otherwise(() => null)}
        </Clay>
      </React.Suspense>
    </Modal>
  )
}
