import { Control } from 'react-hook-form'
import { Coupon } from '../../order-edit/order-edit-type'
import { CouponSelect } from '../coupon-select'
import { CouponCheckbox } from '../coupon-checkbox'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'

export interface OrderEditAddCalcCouponProps {
  onChangeCouponSelect?: (couponIssueCode: string) => void
  control: Control<any>
  coupons: Coupon[]
  onDeleteCoupon: (codeIssueCode: string) => void
}

export function OrderEditAddCalcCoupon({
  onChangeCouponSelect,
  control,
  coupons,
  onDeleteCoupon,
}: OrderEditAddCalcCouponProps) {
  const couponCounts = coupons.length
  return (
    <div className="flex flex-col items-start gap-[6px] self-stretch">
      <div className="text-[14px] leading-[24px]">{__('쿠폰 적용')}</div>
      {match(couponCounts)
        .when(
          (cnt) => cnt > 3,
          () => (
            <CouponSelect
              coupons={coupons}
              control={control}
              onDeleteCoupon={onDeleteCoupon}
              onChangeCouponSelect={onChangeCouponSelect}
            />
          )
        )
        .otherwise(() => (
          <CouponCheckbox
            coupons={coupons}
            control={control}
            onChangeCouponSelect={onChangeCouponSelect}
          />
        ))}
    </div>
  )
}
