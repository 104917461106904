import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  orderCode: string
}
type AtomType = {
  key: string
  value: {
    open: boolean
    status: 'idle' | 'loading' | 'success' | 'error'
  }
}
const CancelAllConfirmState = atomFamily(
  ({ key, value }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = `${key},CancelAllConfirmState`
    return at
  },
  (a, b) => a.key === b.key
)

export const useCancelAllConfirmAtom = {
  key: (p: AtomKey) => `${p.orderCode}:cancel_all_complete_confirm`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          CancelAllConfirmState({
            key: useCancelAllConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          CancelAllConfirmState({
            key: useCancelAllConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          CancelAllConfirmState({
            key: useCancelAllConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          CancelAllConfirmState({
            key: useCancelAllConfirmAtom.key(p),
            value: {
              open: false,
              status: 'idle',
            },
          }),
        [p]
      )
    ),
}
