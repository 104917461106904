import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  orderCode: string
  sectionCode: string
}
type AtomType = {
  key: string
  value?: boolean
}
const CancelReasonEditState = atomFamily(
  ({ key, value = false }: AtomType) =>
    atomWithReset<AtomType>({
      key,
      value,
    }),
  (a, b) => a.key === b.key
)

export const useCancelReasonEditAtom = {
  key: (p: AtomKey) => `cancel-reason-edit-${p.orderCode}-${p.sectionCode}`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          CancelReasonEditState({
            key: useCancelReasonEditAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () => CancelReasonEditState({ key: useCancelReasonEditAtom.key(p) }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () => CancelReasonEditState({ key: useCancelReasonEditAtom.key(p) }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () => CancelReasonEditState({ key: useCancelReasonEditAtom.key(p) }),
        [p]
      )
    ),
}
