import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'
import { Calendar } from '~/shared/components/calendar'
import { fDate } from '~/shared/utils'
import { Textfield } from '@imwebme/clay-components'
import { Calendar as IconCalendar } from '@imwebme/clay-icons'
import { useOnClickOutside } from 'usehooks-ts'

export function DateField({ index }: TFieldProps) {
  const { control, register } = useFormContext<TOrderFormDataEditFormSchema>()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: `form.${index}.value`,
    control,
  })
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const calendarRef = React.useRef(null)
  useOnClickOutside(calendarRef, () => setDropdownOpen(false))

  const handleDateSelect = React.useCallback((date: Date | undefined) => {
    if (date) {
      onChange([fDate(date, 'yyyy-MM-dd')])
      setDropdownOpen(false)
    }
  }, [])

  return (
    <>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />

      <Textfield.Set position="relative" ref={calendarRef}>
        <button
          onClick={() => setDropdownOpen(true)}
          type="button"
          className="w-full"
        >
          <Textfield.Box>
            <Textfield.Input
              value={value[0] ?? ''}
              placeholder={__('날짜를 입력해 주세요')}
              isValid={error ? false : undefined}
            />
            <Textfield.Addon>
              <IconCalendar />
            </Textfield.Addon>
          </Textfield.Box>
        </button>

        {dropdownOpen && (
          <div className="z-[50] p-[24px] pt-[20px] absolute left-0 translate-y-full -bottom-[8px] rounded-[8px] bg-clay-semantic-surface overflow-hidden _drop-shadow">
            <Calendar
              mode="single"
              selected={value[0] ? new Date(value[0]) : undefined}
              onSelect={handleDateSelect}
            />
          </div>
        )}

        <Textfield.HelperText>{error?.message}</Textfield.HelperText>
      </Textfield.Set>
    </>
  )
}
