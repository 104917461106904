import React from 'react'
import { Selector } from './components'
import { __ } from '~/shared/i18n'
import { useController } from 'react-hook-form'
import { FormControlProp } from './order-search-filter-form-fields.type'
import {
  MEMBER_TYPE,
  MemberType,
  FindAllYNType,
} from '~/entities/order-search-filter'
import { OptionItem } from '@imwebme/clay-components'
import { match, P } from 'ts-pattern'

interface Props extends FormControlProp {
  tIsMember?: FindAllYNType
}

const options = (
  tIsMember?: FindAllYNType
): ReadonlyArray<{
  label: string
  value: MemberType
  disabled: boolean
}> => [
  {
    label: __('비회원'),
    value: MEMBER_TYPE.NON_MEMBER,
    disabled: tIsMember === 'Y',
  },
  { label: __('회원'), value: MEMBER_TYPE.MEMBER, disabled: tIsMember === 'N' },
  {
    label: __('첫구매 회원'),
    value: MEMBER_TYPE.FIRST_PURCHASE_MEMBER,
    disabled: tIsMember === 'N',
  },
]

export const MemberTypeField = ({ control, tIsMember }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const { onChange: onIsMemberChange, value: isMemberValue } = useController({
    name: 'isMember',
    control,
  }).field
  const { onChange: onIsFirstOrderUserChange, value: isFirstOrderUserValue } =
    useController({
      name: 'isFirstOrderUser',
      control,
    }).field

  const fieldLabel = React.useMemo(
    () =>
      match({ isMemberValue, isFirstOrderUserValue })
        .with({ isMemberValue: 'Y', isFirstOrderUserValue: 'Y' }, () =>
          __('회원구분: 첫구매 회원')
        )
        .with({ isMemberValue: 'Y', isFirstOrderUserValue: P.not('Y') }, () =>
          __('회원구분: 회원')
        )
        .with({ isMemberValue: 'N' }, () => __('회원구분: 비회원'))
        .otherwise(() => __('회원구분')),
    [isMemberValue, isFirstOrderUserValue]
  )

  const handleResetFields = React.useCallback(() => {
    onIsMemberChange(null)
    onIsFirstOrderUserChange(null)
  }, [])

  const handleItemClick = React.useCallback((value: MemberType) => {
    onIsMemberChange(null)
    onIsFirstOrderUserChange(null)

    switch (value) {
      case MEMBER_TYPE.NON_MEMBER:
        onIsMemberChange('N')
        break
      case MEMBER_TYPE.MEMBER:
        onIsMemberChange('Y')
        break
      case MEMBER_TYPE.FIRST_PURCHASE_MEMBER:
        onIsMemberChange('Y')
        onIsFirstOrderUserChange('Y')
        break
      default:
        break
    }
    setDropdownOpen(false)
  }, [])

  return (
    <Selector
      label={fieldLabel}
      isActive={!!isMemberValue || !!isFirstOrderUserValue}
      resetField={handleResetFields}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {options(tIsMember).map((option) => (
            <OptionItem
              key={option.value}
              text={option.label}
              onClick={() => handleItemClick(option.value)}
              isDisabled={option.disabled}
              isSelected={match({
                isFirstOrderUserValue,
                isMemberValue,
                value: option.value,
              })
                .with(
                  {
                    value: MEMBER_TYPE.FIRST_PURCHASE_MEMBER,
                    isMemberValue: 'Y',
                    isFirstOrderUserValue: 'Y',
                  },
                  () => true
                )
                .with(
                  {
                    value: MEMBER_TYPE.MEMBER,
                    isMemberValue: 'Y',
                    isFirstOrderUserValue: P.not('Y'),
                  },
                  () => true
                )
                .with(
                  {
                    value: MEMBER_TYPE.NON_MEMBER,
                    isMemberValue: 'N',
                  },
                  () => true
                )
                .otherwise(() => false)}
            />
          ))}
        </div>
      }
    />
  )
}
