import { match, P } from 'ts-pattern'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_외부채널_주문섹션_품목 } from '.'
import { 상수_외부채널주문_채널상태 } from '~/entities/@x'

export const 할인유무: ISpecification<모델_외부채널_주문섹션_품목> = new Spec(
  (candidate) => candidate.item.baseItemPrice !== candidate.item.itemPrice
)

export const 채널상태_입금대기: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.입금대기
  )
export const 채널상태_결제완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.결제완료
  )
export const 채널상태_배송준비중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.배송준비중
  )
export const 채널상태_배송중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.배송중
  )
export const 채널상태_배송완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.배송완료
  )
export const 채널상태_구매확정: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.구매확정
  )
export const 채널상태_미입금취소: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.미입금취소
  )
export const 채널상태_취소처리중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.취소처리중
  )
export const 채널상태_취소요청: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.취소요청
  )
export const 채널상태_취소처리완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.취소처리완료
  )
export const 채널상태_취소철회: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.취소철회
  )
export const 채널상태_반품요청: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.반품요청
  )
export const 채널상태_반품완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.반품완료
  )
export const 채널상태_반품철회: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.반품철회
  )
export const 채널상태_수거처리중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.수거처리중
  )
export const 채널상태_수거완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.수거완료
  )
export const 채널상태_교환요청: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환요청
  )
export const 채널상태_교환완료_구매확정: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환완료_구매확정
  )
export const 채널상태_교환완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환완료
  )
export const 채널상태_교환철회: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환철회
  )
export const 채널상태_교환재배송대기: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환재배송대기
  )
export const 채널상태_교환재배송중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환재배송중
  )
export const 채널상태_직권취소처리중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.직권취소처리중
  )
export const 채널상태_직권취소처리완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.직권취소처리완료
  )
export const 채널상태_교환재배송처리중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.교환재배송처리중
  )
export const 채널상태_수거완료_교환재배송처리중: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.수거완료_교환재배송처리중
  )
export const 채널상태_수거완료_교환완료: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec(
    (candidate) =>
      candidate.saleChannelItem?.channelStatus ===
      상수_외부채널주문_채널상태.수거완료_교환완료
  )

export const 품목배송메모: ISpecification<모델_외부채널_주문섹션_품목> =
  new Spec((candidate) =>
    match(candidate.saleChannelItem)
      .with({ apiProductInfo: { memo: P.nonNullable } }, () => true)
      .otherwise(() => false)
  )
