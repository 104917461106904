// Generouted, changes to this file will be overriden
/* eslint-disable */

import {
  components,
  hooks,
  utils,
} from '@imwebme/generouted-react-router/client'

export type Path =
  | `/`
  | `/403`
  | `/:orderCode`
  | `/:orderCode/:memoCode`
  | `/:orderType/:orderCode`
  | `/external-order/:orderCode`
  | `/onboarding`
  | `/order-create`
  | `/order/:saleChannel`
  | `/order/:saleChannel/:orderCode`
  | `/order/:saleChannel/:orderCode/:sectionCode/cancel/calc`
  | `/order/:saleChannel/:orderCode/:sectionCode/return/calc`
  | `/order/:saleChannel/:orderCode/add`
  | `/order/:saleChannel/:orderCode/add/calc`
  | `/order/:saleChannel/:orderCode/cancel`
  | `/order/:saleChannel/:orderCode/cancel/all`
  | `/order/:saleChannel/:orderCode/cancel/info`
  | `/order/:saleChannel/:orderCode/cancel/select`
  | `/order/:saleChannel/:orderCode/return`
  | `/order/:saleChannel/:orderCode/return/info`
  | `/order/:saleChannel/:orderCode/return/select`
  | `/orders`
  | `/orders/tab/:tabCode`
  | `/orders/tab/:tabCode/:pageNumber`
  | `/orders/tab/edit`
  | `/orders/tab/new`

export type Params = {
  '/:orderCode': { orderCode: string }
  '/:orderCode/:memoCode': { orderCode: string; memoCode: string }
  '/:orderType/:orderCode': { orderType: string; orderCode: string }
  '/external-order/:orderCode': { orderCode: string }
  '/order/:saleChannel': { saleChannel: string }
  '/order/:saleChannel/:orderCode': { saleChannel: string; orderCode: string }
  '/order/:saleChannel/:orderCode/:sectionCode/cancel/calc': {
    saleChannel: string
    orderCode: string
    sectionCode: string
  }
  '/order/:saleChannel/:orderCode/:sectionCode/return/calc': {
    saleChannel: string
    orderCode: string
    sectionCode: string
  }
  '/order/:saleChannel/:orderCode/add': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/add/calc': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/cancel': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/cancel/all': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/cancel/info': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/cancel/select': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/return': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/return/info': {
    saleChannel: string
    orderCode: string
  }
  '/order/:saleChannel/:orderCode/return/select': {
    saleChannel: string
    orderCode: string
  }
  '/orders/tab/:tabCode': { tabCode: string }
  '/orders/tab/:tabCode/:pageNumber': { tabCode: string; pageNumber: string }
}

export type ModalPath =
  | `/:orderCode/:memoCode/delete`
  | `/:orderCode/:memoCode/test`
  | `/:orderCode/:memoCode/update`
  | `/:orderCode/:sectionCode/release-claim-holdback`
  | `/:orderCode/:unitCode/:orderDeliveryCode/delivery-edit`
  | `/:orderCode/:unitCode/delivery-add`
  | `/:orderCode/form-data/edit`
  | `/:orderCode/memo`
  | `/:unitCode/members`
  | `/order/:saleChannel/:orderCode/section/:sectionCode/cancel-reject-request`
  | `/order/:saleChannel/:orderCode/section/:sectionCode/return-reject-request`
  | `/product/add`
  | `/product/discount`

export type ModalParams = {
  '/:orderCode/:memoCode/delete': { orderCode: string; memoCode: string }
  '/:orderCode/:memoCode/test': { orderCode: string; memoCode: string }
  '/:orderCode/:memoCode/update': { orderCode: string; memoCode: string }
  '/:orderCode/:sectionCode/release-claim-holdback': {
    orderCode: string
    sectionCode: string
  }
  '/:orderCode/:unitCode/:orderDeliveryCode/delivery-edit': {
    orderCode: string
    unitCode: string
    orderDeliveryCode: string
  }
  '/:orderCode/:unitCode/delivery-add': { orderCode: string; unitCode: string }
  '/:orderCode/form-data/edit': { orderCode: string }
  '/:orderCode/memo': { orderCode: string }
  '/:unitCode/members': { unitCode: string }
  '/order/:saleChannel/:orderCode/section/:sectionCode/cancel-reject-request': {
    saleChannel: string
    orderCode: string
    sectionCode: string
  }
  '/order/:saleChannel/:orderCode/section/:sectionCode/return-reject-request': {
    saleChannel: string
    orderCode: string
    sectionCode: string
  }
  '/product/add': undefined
  '/product/discount': undefined
}

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath,
  ModalParams
>()
export const { redirect } = utils<Path, Params>()
