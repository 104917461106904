import 'react-toastify/dist/ReactToastify.min.css'
import './toast-container.css'
import './toast-transition-slide-fade.css'
import {
  cssTransition,
  ToastContainer as ToastContainerPrimitive,
} from 'react-toastify'
import { cva } from 'class-variance-authority'
import { match } from 'ts-pattern'
import { IconXClose } from '@imwebme/icon'
import { AlertTriangleFilled, CheckCircleFilled } from '@imwebme/clay-icons'

const SlideFade = cssTransition({
  enter: 'custom-slide-fade-in',
  exit: 'custom-slide-fade-out',
  collapseDuration: 300,
})

const stateMap = {
  default: 'default',
  success: 'success',
  critical: 'critical',
} as const

const toastVariants = cva(
  'flex items-center justify-content rounded-full text-center break-keep w-fit mx-auto',
  {
    variants: {
      size: {
        default: 'px-clay-5 typo-clay-body-large py-clay-4',
      },
      state: {
        [stateMap.default]: '',
        [stateMap.success]: 'bg-[#0B0C0ED9] text-clay-semantic-textOn',
        [stateMap.critical]:
          'bg-clay-semantic-surfaceToastCritical text-clay-semantic-textOn',
      },
    },
    defaultVariants: {
      size: 'default',
      state: stateMap.default,
    },
  }
)

export const ToastContainer = () => (
  <ToastContainerPrimitive
    toastClassName={(context) =>
      toastVariants({
        state: match(context?.type)
          .with('error', () => stateMap.critical)
          .with('success', () => stateMap.success)
          .with('info', () => stateMap.success)
          .otherwise(() => stateMap.default),
      })
    }
    position="bottom-center"
    autoClose={1500}
    transition={SlideFade}
    css={{ marginBottom: 48 }}
    icon={(context) => {
      const result = match(context?.type)
        .with('success', () => (
          <CheckCircleFilled className="text-clay-semantic-iconSuccess stroke-[2]" />
        ))
        .with('error', () => (
          <AlertTriangleFilled className="" colorToken="icon-on" />
        ))
        .otherwise(() => null)
      return result
    }}
    hideProgressBar={true}
    closeButton={(context) => {
      const result = match(context?.type)
        .with('info', () => (
          <button
            type="button"
            onClick={context?.closeToast}
            className="ml-clay-4"
          >
            <IconXClose className="text-clay-semantic-textOn stroke-[2]" />
          </button>
        ))
        .otherwise(() => false)
      return result
    }}
  />
)
