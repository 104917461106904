import React from 'react'
import { pipe } from 'fp-ts/function'
import * as RA from 'fp-ts/ReadonlyArray'
import { Label } from '~/shared/components/ui/label'
import { RadioGroup } from '~/shared/components/ui/radio-group'
import { Controller, useFormContext } from 'react-hook-form'
import { retrieveTypeCd } from '~/container/order/order-return-info/schema'
import type { TReturnRequest } from '~/container/order/order-return-info/order-retrun-info.type'
import { WRRT01Component } from './rtt01/rrt01component'
import { RRT02Component } from './rrt02/rrt02component'
import { RRT04Component } from './rrt04/rrt04component'
import { RRT03Component } from './rrt03/rrt03component'
import { __g } from '~/shared/i18n'
import { TGoodsflowSchema } from '~/entities/goodsflow'

/**
 * 수거정보
 */
export const ReturnCollectionInformation = ({
  goodsflowList,
}: {
  goodsflowList?: TGoodsflowSchema
}) => {
  const { control, watch, setValue } = useFormContext<TReturnRequest>()
  const retrieveTypeCdValue = watch('retrieveRequestDto.retrieveTypeCd')

  /**
   * 굿스플로 계정있있을 경우에만 RRT01(자동수거) 표시
   */
  const conditionalRetrieveTypeCd = React.useMemo(
    () =>
      pipe(
        retrieveTypeCd,
        RA.filter((e) => {
          if (goodsflowList && goodsflowList.length > 0) {
            return true
          } else {
            return e !== 'RRT01'
          }
        })
      ),
    [goodsflowList]
  )

  // 수거방법 초기값 부여
  React.useEffect(() => {
    setValue('retrieveRequestDto.retrieveTypeCd', conditionalRetrieveTypeCd[0])
  }, [conditionalRetrieveTypeCd])

  return (
    <div className="grid gap-y-[20px]">
      <Controller
        control={control}
        name="retrieveRequestDto.retrieveTypeCd"
        render={({ field: { onChange, ref } }) => (
          <RadioGroup
            defaultValue={retrieveTypeCdValue || retrieveTypeCd[0]}
            onValueChange={onChange}
            ref={ref}
            className="flex gap-x-[24px] semantic-p14 flex-wrap"
          >
            {conditionalRetrieveTypeCd.map((e) => (
              <div className="flex items-center space-x-2 flex-nowrap" key={e}>
                <RadioGroup.Item
                  value={e}
                  id={e}
                  className="text-clay-semantic-textPrimary whitespace-nowrap"
                />
                <Label htmlFor={e}>{__g(e)}</Label>
              </div>
            ))}
          </RadioGroup>
        )}
      />
      {/* 자동 수거 신청 */}
      {retrieveTypeCdValue === 'RRT01' && <WRRT01Component />}
      {/* 구매자 발송 */}
      {retrieveTypeCdValue === 'RRT02' && <RRT02Component />}
      {/* 택배사 수동 등록 */}
      {retrieveTypeCdValue === 'RRT04' && <RRT04Component />}
      {/* 기타 */}
      {retrieveTypeCdValue === 'RRT03' && <RRT03Component />}
    </div>
  )
}
