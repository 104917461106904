import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TDeliveryPatchModalBase, useDeliveryPatchModalAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSectionCode } from '~/shared/hooks/use-section-code'

const DeliveryPatchModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryPatchModal),
  }))
)

interface TDeliveryPatchModalPack extends TDeliveryPatchModalBase {}
export function DeliveryPatchModalPack(props: TDeliveryPatchModalPack) {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode(true)
  const [open, setState] = useDeliveryPatchModalAtom.state({
    atomKey: props.atomKey,
    orderCode,
    sectionCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <DeliveryPatchModalContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
