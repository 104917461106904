import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResCashReceiptProcessDto,
  type TPostPayloadCashReceiptProcessDto,
  postResCashReceiptProcessDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/bGsKSx
 * @description 현금영수증 발급 처리
 */
export function postCashReceiptProcess(paymentCode: string) {
  return async (body: TPostPayloadCashReceiptProcessDto) => {
    type R = typeof body
    type T = TPostResCashReceiptProcessDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/payment/${paymentCode}/cash-receipt/process`, body)
      .then((res) => res.data)

    postResCashReceiptProcessDto.parse(result.data)
    return result
  }
}
