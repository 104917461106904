import { atom, useAtom } from 'jotai'

interface TDialogDelivery {
  isOpen: boolean
  fulfilled?: number
  rejected?: number
  onOpenChange?: (open: boolean) => void
  onSubmit?: () => void
}
const DialogDeliveryState = atom<TDialogDelivery>({
  isOpen: false,
})
DialogDeliveryState.debugLabel = 'DialogDeliveryState'

export const useDialogDelivery = {
  dialog: () => useAtom(DialogDeliveryState),
}
