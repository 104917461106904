import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import {
  getResExcelExportDto,
  type TGetParamsExcelExportDto,
  type TGetResExcelExportDto,
  postResExcelExportOrderDto,
  type TPostResExcelExportOrderDto,
  type TPostPayloadExcelExportOrderDto,
  postResExcelExportPaymentDto,
  type TPostResExcelExportPaymentDto,
  type TPostPayloadExcelExportPaymentDto,
  getResExcelExportDownloadUrlDto,
  type TGetParamsExcelExportDownloadUrlDto,
  type TGetResExcelExportDownloadUrlDto,
  deleteResExcelExportDto,
  type TDeleteResExcelExportDto,
} from '.'

const axios = ApiClient()

/**
 * @description
 */
export function postExcelExportOrder() {
  return async function (body: TPostPayloadExcelExportOrderDto) {
    type R = typeof body
    type T = TPostResExcelExportOrderDto
    const result = await axios
      .post<T, AxiosResponse<ApiResponse<T>>, R>('/excel/export/order', body)
      .then((res) => res.data)

    try {
      postResExcelExportOrderDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @description
 */
export function postExcelExportPayment() {
  return async function (body: TPostPayloadExcelExportPaymentDto) {
    type R = typeof body
    type T = TPostResExcelExportPaymentDto
    const result = await axios
      .post<T, AxiosResponse<ApiResponse<T>>, R>('/excel/export/payment', body)
      .then((res) => res.data)

    try {
      postResExcelExportPaymentDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}

/**
 * @description
 */
export function getExcelExport() {
  return async function (params: TGetParamsExcelExportDto) {
    const result = await axios
      .get<ApiResponse<TGetResExcelExportDto>>('/excel/export', { params })
      .then((res) => res.data)

    try {
      getResExcelExportDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result.data
  }
}

/**
 * @description
 */
export function getExcelExportDownloadUrl({
  orderExcelExportCode,
}: {
  orderExcelExportCode: string
}) {
  return async function (params: TGetParamsExcelExportDownloadUrlDto) {
    const result = await axios
      .get<
        ApiResponse<TGetResExcelExportDownloadUrlDto>
      >(`/excel/export/${orderExcelExportCode}/download-url`, { params })
      .then((res) => res.data)

    try {
      getResExcelExportDownloadUrlDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result.data
  }
}

/**
 * @API문서
 */
export function deleteExcelExport({
  orderExcelExportCode,
}: {
  orderExcelExportCode: string
}) {
  return async function () {
    type T = TDeleteResExcelExportDto
    const result = await axios
      .delete<
        T,
        AxiosResponse<ApiResponse<T>>
      >(`/excel/export/${orderExcelExportCode}`)
      .then((res) => res.data)

    try {
      deleteResExcelExportDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
