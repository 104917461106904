import { z } from 'zod'
import { orderCreatePayloadDto } from '~/entities/@dto'
import { YN } from '~/entities/@x'

export const postResOrderDto = z.object({
  orderNo: z.string(),
  orderCode: z.string(),
  memberCode: z.string(),
  isCompletePayment: z
    .nativeEnum(YN)
    .describe('결제완료 여부 (할인, 적립금으로 결제금액이 0원인 경우 true)'),
})

export const postPayloadOrderDto = orderCreatePayloadDto.extend({
  isRequestPayment: z.nativeEnum(YN).optional().describe('결제 요청 여부'),
})
