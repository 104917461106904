import React from 'react'
import { Selector } from '../components'
import { __, __g } from '~/shared/i18n'
import { includes, pull, find } from 'lodash'
import { useController } from 'react-hook-form'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { BankNameList } from '~/container/order-search-filter/order-search-filter-form'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'
import { OptionItemCheckbox } from '@imwebme/clay-components'

interface Props extends FormControlProp {
  bankNameList: BankNameList
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
}

const fieldKey = 'depositBankName'

export const DepositBankNameField = ({
  control,
  bankNameList,
  handleRemoveOrderInfoField,
}: Props) => {
  const { onChange, value, ...inputProps } = useController({
    name: fieldKey,
    control,
  }).field
  const [dropdownOpen, setDropdownOpen] = React.useState(
    () => value === undefined || value === null
  )

  const handleInputChange = React.useCallback(
    (item: string) => {
      const copiedValue = Array.isArray(value) ? [...value] : []

      if (includes(copiedValue, item)) {
        pull(copiedValue, item)
      } else {
        copiedValue.push(item)
      }

      onChange(copiedValue.length ? copiedValue : null)
    },
    [value]
  )

  const fieldLabel = React.useMemo(() => {
    let baseLabel = __('입금은행') + ': '

    if (Array.isArray(value) && value.length) {
      const firstItem = find(bankNameList, (o) => includes(value, o.value))

      if (value.length > 1) {
        baseLabel += __('{{paymentMethod}} 외 {{count}}건', {
          paymentMethod: firstItem?.label ?? '',
          count: value.length - 1,
        })
      } else {
        baseLabel += firstItem?.label ?? ''
      }
    } else {
      baseLabel += __('선택해 주세요')
    }

    return baseLabel
  }, [value, bankNameList])

  const handleResetField = React.useCallback(() => {
    onChange(null)
    handleRemoveOrderInfoField(fieldKey)
  }, [fieldKey])

  React.useEffect(() => {
    ;(function removeFieldOnExit() {
      if (dropdownOpen === false && !value) {
        handleRemoveOrderInfoField(fieldKey)
      }
    })()
  }, [value, dropdownOpen])

  return (
    <Selector
      label={fieldLabel}
      isActive={true}
      resetField={handleResetField}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {bankNameList.map((option, idx) => (
            <OptionItemCheckbox
              key={idx}
              label={option.label}
              value={option.value}
              checked={
                Array.isArray(value) && value.some((v) => v === option.value)
              }
              onClick={(e) => handleInputChange(e as string)}
              {...inputProps}
            />
          ))}
        </div>
      }
    />
  )
}
