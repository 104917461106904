export const SELECTION = {
  orderNo: 'orderNo',
  orderStatus: 'orderStatus',
  currency: 'currency',
  saleChannelName: 'saleChannelName',
  orderTime: 'orderTime',
  totalItemPrice: 'totalItemPrice',
  totalGradeDiscount: 'totalGradeDiscount',
  totalItemCouponDiscount: 'totalItemCouponDiscount',
  totalBaseDeliveryPrice: 'totalBaseDeliveryPrice',
  totalDeliveryIslandPrice: 'totalDeliveryIslandPrice',
  totalDeliveryExtraPrice: 'totalDeliveryExtraPrice',
  totalDeliveryCouponDiscount: 'totalDeliveryCouponDiscount',
  totalDeliveryPointAmount: 'totalDeliveryPointAmount',
  totalItemPointAmount: 'totalItemPointAmount',
  finalOrderPaymentPrice: 'finalOrderPaymentPrice',
  finalOrderTaxFreePrice: 'finalOrderTaxFreePrice',
  finalOrderRefundPendingPrice: 'finalOrderRefundPendingPrice',
  finalOrderRefundPendingTaxFreePrice: 'finalOrderRefundPendingTaxFreePrice',
  finalRefundPendingPoint: 'finalRefundPendingPoint',
  finalPaymentPrice: 'finalPaymentPrice',
  finalRefundPrice: 'finalRefundPrice',
  givePoint: 'givePoint',
  memberUid: 'memberUid',
  orderIp: 'orderIp',
  ordererName: 'ordererName',
  ordererEmail: 'ordererEmail',
  ordererCall: 'ordererCall',
  unipassNumber: 'unipassNumber',
  orderSectionNo: 'orderSectionNo',
  sectionStatus: 'sectionStatus',
  deliveryMethod: 'deliveryMethod',
  deliveryPayMethod: 'deliveryPayMethod',
  shippingInvoice: 'shippingInvoice',
  pickupMemo: 'pickupMemo',
  shippingStartTime: 'shippingStartTime',
  shippingCompleteTime: 'shippingCompleteTime',
  purchaseConfirmationTime: 'purchaseConfirmationTime',
  orderSectionItemNo: 'orderSectionItemNo',
  qty: 'qty',
  prodOptionCode: 'prodOptionCode',
  prodName: 'prodName',
  optionInfo: 'optionInfo',
  itemPrice: 'itemPrice',
  itemActualPaymentsPrice: 'itemActualPaymentsPrice',
  itemGradeDiscount: 'itemGradeDiscount',
  itemPointDiscount: 'itemPointDiscount',
  itemCouponDiscount: 'itemCouponDiscount',
  weight: 'weight',
  downloadLimitTime: 'downloadLimitTime',
  downloadLimitCnt: 'downloadLimitCnt',
  groupPassCode: 'groupPassCode',
  groupPassDayCnt: 'groupPassDayCnt',
  receiverName: 'receiverName',
  receiverCall: 'receiverCall',
  country: 'country',
  countryName: 'countryName',
  zipcode: 'zipcode',
  address: 'address',
  addressDetail: 'addressDetail',
  orderDeliveryMemo: 'orderDeliveryMemo',
  parcelCompanyName: 'parcelCompanyName',
  deliveryStatus: 'deliveryStatus',
  invoiceAddTime: 'invoiceAddTime',
  cancelReason: 'cancelReason',
  cancelReasonDetail: 'cancelReasonDetail',
  cancelRequestTime: 'cancelRequestTime',
  cancelCompleteTime: 'cancelCompleteTime',
  returnReason: 'returnReason',
  returnInvoice: 'returnInvoice',
  returnReasonDetail: 'returnReasonDetail',
  returnRequestTime: 'returnRequestTime',
  returnCompleteTime: 'returnCompleteTime',
  usedCouponNames: 'usedCouponNames',
  totalDiscount: 'totalDiscount',
  totalDeliveryPrice: 'totalDeliveryPrice',
  totalPointAmount: 'totalPointAmount',
  paymentNo: 'paymentNo',
  paymentCode: 'paymentCode',
  paymentMethod: 'paymentMethod',
  paymentPaidPrice: 'paymentPaidPrice',
  paymentTaxFreePrice: 'paymentTaxFreePrice',
  paymentPgTrxTime: 'paymentPgTrxTime',
  bankTransferBankName: 'bankTransferBankName',
  bankTransferBankAccount: 'bankTransferBankAccount',
  bankTransferBankDepositor: 'bankTransferBankDepositor',
  bankTransferBankDepositTime: 'bankTransferBankDepositTime',
  bankTransferBankMemberCode: 'bankTransferBankMemberCode',
  cashReceiptType: 'cashReceiptType',
  cashReceiptStatusCd: 'cashReceiptStatusCd',
  cashReceiptValue: 'cashReceiptValue',
  cashReceiptCompleteTime: 'cashReceiptCompleteTime',
  cashReceiptIssueNumber: 'cashReceiptIssueNumber',
  prodSkuNo: 'prodSkuNo',
  optionSkuNo: 'optionSkuNo',
  customProdCode: 'customProdCode',
  origin: 'origin',
  maker: 'maker',
  brand: 'brand',
  adminMemo: 'adminMemo',
  orderFormData: 'orderFormData',
  externalChannelOrderNo: 'externalChannelOrderNo',
  externalChannelOrderItemNo: 'externalChannelOrderItemNo',
  externalChannelOrderStatus: 'externalChannelOrderStatus',
  optionInfoWithQty: 'optionInfoWithQty',
} as const
export type TSelection = (typeof SELECTION)[keyof typeof SELECTION]

export const ADDRESS_TYPE = {
  separate: 'separate',
  integrated: 'integrated',
} as const
export type T_ADDRESS_TYPE = (typeof ADDRESS_TYPE)[keyof typeof ADDRESS_TYPE]

export const PHONE_NUMBER_FORMAT = {
  hyphen: 'hyphen',
  onlyNumber: 'onlyNumber',
} as const
export type T_PHONE_NUMBER_FORMAT =
  (typeof PHONE_NUMBER_FORMAT)[keyof typeof PHONE_NUMBER_FORMAT]

export const DATE_FORMAT = {
  second: 'second',
  minute: 'minute',
} as const
export type T_DATE_FORMAT = (typeof DATE_FORMAT)[keyof typeof DATE_FORMAT]

export const DUPULICATE_ITEM_INFO_TYPE = {
  include: 'include', // 모든 행에 중복 데이터 삽입
  exclude: 'exclude', // 중복 데이터는 공백으로 표시
}
