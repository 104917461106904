import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { TCancelRejectRequestModalBase } from '.'
import { useModals } from '~/router'
import { Modal } from '@imwebme/clay-components'

const CancelRejectRequestModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.CancelRejectRequestModal),
  }))
)

interface TCancelRejectRequestModalPack extends TCancelRejectRequestModalBase {}
export function CancelRejectRequestModalPack(
  props: TCancelRejectRequestModalPack
) {
  const modals = useModals()
  const handleModalClose = () => modals.close()
  return (
    <Modal isOpen setClose={handleModalClose} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <CancelRejectRequestModalContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
