export const TIMELINE_LOG_TYPE_CODE = {
  // 주문 타임라인 타입코드
  // ORDER_TIMELINE_LOG_TYPE_CODE: 'OTL00',
  // 주문생성
  CREATE_ORDER: 'OTL01',
  // 상품 추가
  ADD_ITEM: 'OTL02',
  // 배송지 추가
  ADD_DELIVERY: 'OTL03',
  // 배송지 정보 수정
  UPDATE_DELIVERY: 'OTL04',
  // 발송 지연 처리(네이버페이)
  DELAY_SHIPMENT: 'OTL05',
  // 결제 대기
  WAIT_PAYMENT: 'OTL06',
  // 결제기한 초과
  OVERDUE_DEADLINE_PAYMENT: 'OTL07',
  // 결제기한 연장
  EXTENSION_DEADLINE_PAYMENT: 'OTL08',
  // 결제 완료
  COMPLETE_PAYMENT: 'OTL09',
  // 환불실패
  FAIL_REFUND: 'OTL10',
  // 환불 완료
  COMPLETE_REFUND: 'OTL11',
  // 배송대기 전체 처리
  COMPLETE_ALL_READY_SHIPPING: 'OTL12',
  // 배송대기 부분 처리
  COMPLETE_PARTIAL_READY_SHIPPING: 'OTL13',
  // 상품준비로 되돌리기
  REVERT_STATUS_ITEM_PREPARATION: 'OTL14',
  // 배송중 처리
  CHANGE_STATUS_SHIPPING: 'OTL15',
  // 배송대기 되돌리기
  REVERT_STATUS_READY_SHIPPING: 'OTL16',
  // 배송완료 처리
  CHANGE_STATUS_COMPLETE_SHIPPING: 'OTL17',
  // 배송중 되돌리기
  REVERT_STATUS_SHIPPING: 'OTL18',
  // 배송보류 설정
  ON_HOLD_DELIVERY: 'OTL19',
  // 배송보류 해제
  OFF_HOLD_DELIVERY: 'OTL20',
  // 송장번호 입력
  CREATE_INVOICE: 'OTL21',
  // 송장번호 수정
  UPDATE_INVOICE: 'OTL22',
  // 송장번호 삭제
  DELETE_INVOICE: 'OTL23',
  // 취소접수
  REQUEST_CANCEL: 'OTL24',
  // 취소철회
  WITHDRAW_CANCEL: 'OTL25',
  // 취소거부
  REJECT_CANCEL: 'OTL26',
  // 취소 전체 완료
  COMPLETE_ALL_CANCEL: 'OTL27',
  // 취소 부분 완료
  COMPLETE_PARTIAL_CANCEL: 'OTL28',
  // 취소접수 되돌리기
  REVERT_REQUEST_CANCEL: 'OTL29',
  // 반품접수
  REQUEST_RETURN: 'OTL30',
  // 수거지시
  REQUEST_RETRIEVE: 'OTL31',
  // 반품사유 수정
  UPDATE_REQUEST_RETURN: 'OTL32',
  // 반품 거부
  REJECT_RETURN: 'OTL33',
  // 반품철회
  WITHDRAW_RETURN: 'OTL34',
  // 반품 전체 완료
  COMPLETE_ALL_RETURN: 'OTL35',
  // 반품 부분 완료
  COMPLETE_PARTIAL_RETURN: 'OTL36',
  // 반품접수상태 되돌리기
  REVERT_REQUEST_RETURN: 'OTL37',
  // 현금 영수증 요청
  REQUEST_PAYMENT_CASH_RECEIPT: 'OTL38',
  // 현금 영수증 의무 발행 요청
  REQUEST_PAYMENT_REQUIRE_CASH_RECEIPT: 'OTL39',
  // 현금 영수증 발급
  PROCESS_CASH_RECEIPT: 'OTL40',
  // 현금 영수증 수동 발급
  PROCESS_MANUAL_CASH_RECEIPT: 'OTL41',
  // 현금 영수증 수동 발급 되돌리기
  REVERT_PROCESS_MANUAL_CASH_RECEIPT: 'OTL42',
  // 현금 영수증 발급 실패
  FAIL_PROCESS_CASH_RECEIPT: 'OTL43',
  // 입금대기 되돌리기
  REVERT_WAIT_DEPOSIT: 'OTL44',
  // 수거접수 완료 (자동수거지시 후 택배사측에서 송장발급 완료)
  REQUEST_RETRIEVE_COMPLETE: 'OTL45',
  // 현금 영수증 신청 정보 수정
  UPDATE_REQUEST_CASH_RECEIPT: 'OTL46',
  // 섹션 배송지 정보 수정
  UPDATE_SECTION_DELIVERY: 'OTL47',
  // 주문 배송지 정보 삭제
  DELETE_DELIVERY: 'OTL48',
  // 취소접수 사유 수정
  UPDATE_REQUEST_CANCEL_REASON: 'OTL49',
  // 입금전 취소
  CANCEL_UNPAID_DEPOSIT_ORDER: 'OTL50',
  // 구매확정
  CHANGE_STATUS_CONFIRM: 'OTL51',
  // 상품준비 섹션 분할
  SPLIT_ORDER_SECTION_PREPARATION: 'OTL52',
  // 배송대기 섹션 분할
  SPLIT_ORDER_SECTION_READY_SHIPPING: 'OTL53',
  // 섹션합치기
  MERGE_ORDER_SECTION: 'OTL54',
  // 반품완료 및 교환 품목 생성(전체))
  COMPLETE_RETURN_FOR_EXCHANGE: 'OTL55',
  // 반품완료 및 교환 품목 생성(부분)
  COMPLETE_PARTIAL_RETURN_FOR_EXCHANGE: 'OTL56',
  // 교환접수 -> 반품접수 스위칭
  SWITCH_TO_RETURN_REQUEST: 'OTL57',
  // 반품접수 -> 교환접수 스위칭
  SWITCH_TO_EXCHANGE_REQUEST: 'OTL58',
  // 외부채널 반품 보류 해제
  RETURN_HOLDBACK_RELEASE: 'OTL59',
  // 외부채널 교환 보류 해제
  EXCHANGE_HOLDBACK_RELEASE: 'OTL60',
} as const

export type TypeTimelineLogTypeCode =
  (typeof TIMELINE_LOG_TYPE_CODE)[keyof typeof TIMELINE_LOG_TYPE_CODE]

export const TIMELINE_ACTION_TRIGGER_CODE = {
  USER: 'ATC01',
  SYSTEM: 'ATC02',
  OPEN_API: 'ATC03',
}

export type TypeTimelineActionTriggerCode =
  (typeof TIMELINE_ACTION_TRIGGER_CODE)[keyof typeof TIMELINE_ACTION_TRIGGER_CODE]

export const HISTORY_TYPE = {
  ORDER_HISTORY_TIMELINE: 'timeline',
  ORDER_HISTORY_MEMO: 'memo',
} as const
export type TypeHistoryType = (typeof HISTORY_TYPE)[keyof typeof HISTORY_TYPE]
