import React from 'react'
import { ProductSearchItemB } from '../../../product-search-item/product-search-item-b'
import { ProductSearchItemBase } from '../../../product-search-item/product-search-item-base'
import { Checkbox } from '~/shared/components/ui/checkbox'

export type T_SingleProduct = {
  id: string
  name: string
  price: string
  checked?: boolean
  setChecked?: () => void
}
export const SingleProduct = ({
  id,
  name,
  price,
  checked,
  setChecked,
}: T_SingleProduct) => (
  <ProductSearchItemB
    id={id}
    name={name}
    price={price}
    data-type="singe-product"
    labelId={`${id}`}
  >
    <ProductSearchItemBase.Slot name="LeftArea">
      <Checkbox
        className="rounded-[4px]"
        checked={checked}
        onChangeChecked={setChecked}
        id={id}
      />
    </ProductSearchItemBase.Slot>
  </ProductSearchItemB>
)
