import React, { useEffect } from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import {
  OrderEditAddSection,
  type OrderEditAddSectionProps,
} from './order-edit-add-section'
import { type TProductCard001 } from '../../product'
import { useOrderSectionAtom } from '../../order-section/order-section-template/order-section-template.store'

interface OrderEditAddSectionContainerProps extends OrderEditAddSectionProps {
  onItemsChange?: (items: TProductCard001.props[] | undefined) => void
  onPriceChange?: (price: number) => void
}
const OrderEditAddSectionContainer = ({
  id,
  items: _items,
  onItemsChange,
  onPriceChange,
  onReset: _onReset,
  ...props
}: OrderEditAddSectionContainerProps & {
  id: string
}) => {
  const [section, setItems] = useOrderSectionAtom.section(id)
  const archiveSection = useOrderSectionAtom.archive()

  // props로 아이템을 받는다면 초기값이라서 setItems를 해준다.
  useEffect(() => {
    // 상태값으로 없다면? props로 받은 아이템을 초기값으로 넣어준다.
    if (_items && !section) {
      setItems(_items)
    }
  }, [_items, setItems, section])

  // 초기값으로 리셋한다.
  const onReset = () => {
    pipe(
      _items,
      O.fromNullable,
      O.fold(
        () =>
          pipe(
            archiveSection,
            O.fromNullable,
            O.fold(
              () => undefined,
              flow(
                A.findFirst((item) => item.id === id),
                O.fold(
                  () => undefined,
                  (e) => e.value
                )
              )
            )
          ),
        (e) => e
      ),
      O.fromNullable,
      O.fold(
        () => undefined,
        flow(
          (e) => ({
            id,
            value: e,
            action: 'force' as const,
          }),
          (e) => e
        )
      ),
      setItems
    )
    _onReset?.()
  }

  // 아이템이 변경되면 qtyChange의 값들을 합산해서 총합을 구한다.
  useEffect(() => {
    const result = pipe(
      section,
      (s) => s?.value,
      O.fromNullable,
      O.fold(
        () => 0,
        flow(
          A.map((item) => (item.originalPrice || 0) * (item.qtyChange || 0)),
          A.reduce(0, (acc, cur) => acc + cur)
        )
      )
    )
    onPriceChange?.(result)
  }, [_items, onPriceChange, section])

  useEffect(() => {
    onItemsChange?.(section?.value)
  }, [section, onItemsChange])

  // 맨처음에는 section이 없다. 그래서 초기값으로 받은 아이템을 넣어준다.
  useEffect(() => {
    if (section && _items) {
      onReset()
    }
  }, [])

  return (
    <OrderEditAddSection
      items={section?.value}
      onReset={onReset}
      {...props}
      id={id}
    />
  )
}

export { OrderEditAddSectionContainer }
