import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import GrimIconGrayCoin from './grim-icon-gray-coin.svg?react'
import { __ } from '~/shared/i18n'

export function EmptyPrice() {
  return (
    <Flex.Column
      rowGap={vars.spacing[2]}
      paddingInline={vars.spacing[6]}
      paddingBottom={vars.spacing[3]}
      alignItems="center"
    >
      <GrimIconGrayCoin />
      <Typography variant="body-small">
        {__('상품 선택 단계에서 확인할 수 있어요')}
      </Typography>
    </Flex.Column>
  )
}
