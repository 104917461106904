import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryShippingApplyAllSchema,
  type TPostPayloadBulkActionSectionDeliveryShippingApplyAllSchema,
  postResBulkActionSectionDeliveryShippingApplyAllSchema,
} from '.'

const axios = ApiClient()

export async function postBulkActionSectionDeliveryShippingApplyAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryShippingApplyAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryShippingApplyAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/shipping/all', body)
    .then((res) => res.data)

  postResBulkActionSectionDeliveryShippingApplyAllSchema.parse(result.data)
  return result.data
}
