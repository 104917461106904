import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { OrderSectionDto } from '~/entities/order-detail'
import { 모델_주문서 } from '~/container/order/class-order'

export class 모델_섹션배송지 {
  data: OrderSectionDto

  constructor(data: OrderSectionDto) {
    this.data = data
  }
}

const 스펙_송장등록여부: ISpecification<모델_섹션배송지> = new Spec(
  flow(
    (ex) => ex.data.invoice,
    O.fromNullable,
    O.fold(
      () => false,
      () => true
    )
  )
)

/**
 * 택배배송인 경우만 해당
 */
const 스펙_배송지노출여부: ISpecification<모델_주문서> = new Spec(
  flow(
    (ex) => ex.data.orderDeliveryList,
    O.fromNullable,
    O.fold(
      () => false,
      flow((e) => e.length > 1)
    )
  )
)

export const 스펙_배송 = {
  송장등록여부: 스펙_송장등록여부,
  배송지노출여부: 스펙_배송지노출여부,
}
