import React from 'react'
import { ExternalPurchaserInfoCard } from './external-purchaser-info-card'

export function ExternalPurchaserInfoCardPack() {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <ExternalPurchaserInfoCard />
    </React.Suspense>
  )
}
