import React from 'react'
import {
  ExternalOrderCancelSelectSection,
  TExternalOrderCancelSelectSectionBase,
} from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

interface TExternalOrderCancelSelectSectionPack
  extends TExternalOrderCancelSelectSectionBase {}
export function ExternalOrderCancelSelectSectionPack(
  props: TExternalOrderCancelSelectSectionPack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalOrderCancelSelectSection {...props} />
    </React.Suspense>
  )
}
