import { TPostResAmountDueDto } from '~/entities/order/amount-due'

/**
 * 결졔에정금액조회 응답이 무료배송 주문인지 여부를 반환한다
 */
export function getIsFreeShippingOrder(data: TPostResAmountDueDto): boolean {
  if (
    data.deliveryPrice === 0 &&
    data.deliveryIslandPrice === 0 &&
    data.cashOnDeliveryPrice === 0 &&
    data.cashOnDeliveryIslandPrice === 0
  ) {
    return true
  }

  return false
}
