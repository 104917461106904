import React from 'react'
import { DropdownMenu } from '~/shared/components/dropdown-menu'
import { Settings } from '@imwebme/clay-icons'
import { __, __e } from '~/shared/i18n'
import { IconButton } from '@imwebme/clay-components'
import {
  useIsDefaultTab,
  useTabCode,
} from '~/shared/hooks/use-tab-code/use-tab-code'
import {
  OrderSearchTabDeletePack,
  useOrderSearchTabDeleteAtom,
} from '~/entities/order-search-tab/modal/delete'
import { cn } from '~/shared/utils'
import {
  OrderSearchTabCopyPack,
  useOrderSearchTabCopyAtom,
} from '~/entities/order-search-tab/modal/copy'
import { useNavigate } from 'react-router-dom'
import { 믹스패널 } from '~/shared/mixpanel'

export function OrderSettings() {
  const router = useNavigate()
  const tabCode = useTabCode()
  const isDefaultTab = useIsDefaultTab()
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const dropdownTriggerRef = React.useRef(null)

  const onOrderSearchTabDeleteModal = useOrderSearchTabDeleteAtom.set({
    tabCode,
  })
  const onOrderSearchTabCopyModal = useOrderSearchTabCopyAtom.set({
    tabCode,
  })

  return (
    <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <IconButton
            native={{ type: 'button' }}
            variant="secondary"
            ref={dropdownTriggerRef}
            className="data-[state=open]:bg-clay-semantic-actionSecondaryPressed"
            icon={<Settings color="inherit" />}
            aria-label={__('탭 설정')}
          />
        </DropdownMenu.Trigger>

        <DropdownMenu.Content align="end" className="w-[120px]">
          <DropdownMenu.Item
            onSelect={() => {
              router(
                `/orders/tab/edit?tabCode=${tabCode}&from=${location.pathname}${location.search}${location.hash}`
              )
            }}
            disabled={isDefaultTab}
          >
            <span
              className={cn(isDefaultTab && 'text-clay-semantic-textDisabled')}
            >
              {__('탭 수정')}
            </span>
          </DropdownMenu.Item>

          <DropdownMenu.Item
            onSelect={() => {
              onOrderSearchTabCopyModal(true)

              dataLayer.push({
                event: 믹스패널.click_bo_oms_order_list_tap_copy_tap,
              })
            }}
          >
            {__('탭 복제')}
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => {
              onOrderSearchTabDeleteModal(true)
              dataLayer.push({
                event: 믹스패널.click_bo_oms_order_list_tap_delete_tap,
              })
            }}
            disabled={isDefaultTab}
          >
            <span
              className={cn(
                'text-clay-semantic-textCritical',
                isDefaultTab && 'text-clay-semantic-textDisabled'
              )}
            >
              {__('삭제')}
            </span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
      <OrderSearchTabDeletePack tabCode={tabCode} />
      <OrderSearchTabCopyPack tabCode={tabCode} />
    </>
  )
}
