import React from 'react'
import { Modal } from '@imwebme/clay-components'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { TOrderSearchTabCopyBase, useOrderSearchTabCopyAtom } from '.'

const OrderSearchTabCopyContainer = React.lazy(() =>
  import('./order-search-tab-copy').then((module) => ({
    default: React.memo(module.OrderSearchTabCopy),
  }))
)

interface TOrderSearchTabCopyPack extends TOrderSearchTabCopyBase {}
export function OrderSearchTabCopyPack(props: TOrderSearchTabCopyPack) {
  const [open, setState] = useOrderSearchTabCopyAtom.state({
    atomKey: props.atomKey,
    tabCode: props.tabCode,
  })

  return (
    <Modal isOpen={!!open} setClose={() => setState(false)} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense>
          <OrderSearchTabCopyContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
