import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  tabCode: string
}
type AtomType = {
  key: string
  value?: boolean
}
const ExportModalState = atomFamily(
  ({ key, value = false }: AtomType) => {
    const at = atomWithReset<AtomType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)

type StepType = {
  key: string
  value?: '내보내기_모달' | '양식관리' | undefined
}
const ExportStepState = atomFamily(
  ({ key, value }: StepType) => {
    const at = atomWithReset<StepType>({
      key,
      value,
    })
    at.debugLabel = key
    return at
  },
  (a, b) => a.key === b.key
)

export const useExportModalAtom = {
  key: (p: AtomKey) => `tabCode(${p.tabCode}):export-modal`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          ExportModalState({
            key: useExportModalAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          ExportModalState({
            key: useExportModalAtom.key(p),
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          ExportModalState({
            key: useExportModalAtom.key(p),
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          ExportModalState({
            key: useExportModalAtom.key(p),
          }),
        [p]
      )
    ),
  step: {
    key: (p: AtomKey) => `tabCode(${p.tabCode}):export-history`,
    state: (p: AtomKey) =>
      useAtom(
        React.useMemo(
          () =>
            ExportStepState({
              key: useExportModalAtom.step.key(p),
            }),
          [p]
        )
      ),
    get: (p: AtomKey) =>
      useAtomValue(
        React.useMemo(
          () =>
            ExportStepState({
              key: useExportModalAtom.step.key(p),
            }),
          [p]
        )
      ),
    set: (p: AtomKey) =>
      useSetAtom(
        React.useMemo(
          () =>
            ExportStepState({
              key: useExportModalAtom.step.key(p),
            }),
          [p]
        )
      ),
    reset: (p: AtomKey) =>
      useResetAtom(
        React.useMemo(
          () =>
            ExportStepState({
              key: useExportModalAtom.step.key(p),
            }),
          [p]
        )
      ),
  },
}
