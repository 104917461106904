import { ISpecification, Spec } from 'spec-pattern-ts'
import { isAfter } from 'date-fns'
import { TCouponInfoSchema } from '~/entities/order-section/cancel-settlements'

export class 모델_쿠폰 {
  data: TCouponInfoSchema

  constructor(data: TCouponInfoSchema) {
    this.data = data
  }
}

/**
 * 쿠폰의 만료일이 없다면 반환 가능
 * 쿠폰의 만료일이 있다면 만료일이 현재 시간 이후라면 반환 가능
 */
const 스펙_쿠폰반환가능여부: ISpecification<모델_쿠폰> = new Spec((ex) =>
  ex.data.endDate && ex.data.isUnlimitedDate != 'Y'
    ? isAfter(new Date(ex.data.endDate), new Date())
    : true
)

export const 스펙_쿠폰 = {
  쿠폰반환가능여부: 스펙_쿠폰반환가능여부,
}
