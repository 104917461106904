import { __ } from '~/shared/i18n'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { Textarea } from '@imwebme/clay-components'
import { useDeliverySetupAtom } from '~/container/order-create/order-create.store'

export function MemoField() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  return (
    <FieldLayout title={__('배송 메모')}>
      <Textarea
        placeholder={__('입력해 주세요')}
        value={deliverySetup.memo}
        onChange={(v) => setDeliverySetup({ memo: v })}
        characterCount
        maxLength={100}
        height="min"
      />
    </FieldLayout>
  )
}
