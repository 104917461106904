import { type TProductCard001, OrderEditCancelSectionContainer } from '~t'
import React from 'react'
import { __g } from '~/shared/i18n'

const OrderSectionContainerOverlay = ({
  id,
  label,
  items: _items,
  statusCd,
}: {
  id: string
  label: string
  items?: TProductCard001.props[]
  statusCd: string
}) => (
  <OrderEditCancelSectionContainer
    id={id}
    label={label}
    items={_items || []}
    title={__g(statusCd)}
  />
)

export default OrderSectionContainerOverlay
