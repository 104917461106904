import { z } from 'zod'

export const 상수_외부채널주문_클레임_보류사유 = {
  판매자확인필요: 'SELLER_CONFIRM_NEED',
  구매자확인필요: 'PURCHASER_CONFIRM_NEED',
  판매자직접송금: 'SELLER_REMIT',
} as const

const type = z.nativeEnum(상수_외부채널주문_클레임_보류사유)
export type T상수_외부채널주문_클레임_보류사유 = z.infer<typeof type>
