import { useController } from 'react-hook-form'
import { FormControlProp } from './order-search-filter-form-fields.type'

interface Props extends FormControlProp {
  disabled: boolean
}

export const NameField = ({ control, disabled }: Props) => {
  const { field, fieldState } = useController({
    name: 'name',
    control,
  })

  return (
    <div>
      {disabled ? (
        <div className="px-[12px] py-[4px] typo-clay-heading-medium-bold text-clay-semantic-text">
          {field.value}
        </div>
      ) : (
        <>
          <input
            {...field}
            className="px-[12px] py-[4px] typo-clay-heading-medium-bold text-clay-semantic-text rounded-[8px] border border-transparent focus:border-clay-semantic-focus hover:enabled:border-clay-semantic-focus focus:ring-0"
            disabled={disabled}
          />
          <div className="typo-clay-label-small text-clay-semantic-textCritical px-[12px] pt-[6px]">
            {fieldState?.error?.message}
          </div>
        </>
      )}
    </div>
  )
}
