import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { LoaderFunction } from 'react-router-dom'
import { orderDetailOptions } from '~/entities/order-detail'
import { queryClient } from '~/app/provider'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />
export const Loader: LoaderFunction = async ({ params }) => {
  params.orderCode
  if (!params.orderCode) {
    return null
  }

  await queryClient.prefetchQuery(
    orderDetailOptions({ orderCode: params.orderCode })
  )
  return null
}

export { default } from '~/container/order/order-add/order-add'
