import React, { useRef, useEffect, useCallback } from 'react'
import Transition from 'react-transition-group/Transition'
import { cn } from '~/shared/utils'

const duration = 300

export function Collapse({
  in: inProp,
  children,
}: React.PropsWithChildren<{
  in: boolean
}>) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    },
    []
  )

  const getWrapperHeight = useCallback(
    () => (wrapperRef.current ? wrapperRef.current.clientHeight : 0),
    [wrapperRef]
  )

  const handleEnter = useCallback(
    (node: HTMLElement) => {
      const wrapperHeight = getWrapperHeight()
      node.style.height = `${wrapperHeight}px`
    },
    [getWrapperHeight]
  )

  const handleEntering = useCallback(
    (node: HTMLElement) => {
      const wrapperHeight = getWrapperHeight()
      node.style.height = `${wrapperHeight}px`
    },
    [getWrapperHeight]
  )

  const handleEntered = useCallback((node: HTMLElement) => {
    node.style.height = 'auto'
  }, [])

  const handleExit = useCallback(
    (node: HTMLElement) => {
      const wrapperHeight = getWrapperHeight()
      node.style.height = `${wrapperHeight}px`
    },
    [getWrapperHeight]
  )

  const handleExiting = useCallback(
    (node: HTMLElement) => {
      const a = setTimeout(() => {
        node.style.height = '0px'
      }, 0)
      timeoutRef.current = a
    },
    [timeoutRef]
  )

  return (
    <Transition
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      in={inProp}
      timeout={duration}
    >
      {(state, childProps) => (
        <div
          className={cn(
            state === 'entered' ? 'h-auto' : 'h-0',
            'min-h-0 transition-all ease-in-out duration-300 overflow-hidden'
          )}
          {...childProps}
        >
          <div ref={wrapperRef}>
            <div className="flex">
              <div className="w-full">{children}</div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  )
}

export default Collapse
