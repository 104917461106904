import { match, P } from 'ts-pattern'
import { 상수_외부채널주문_채널상태 } from '../@x'

export function omsChannelStatus(e: string) {
  return match(e)
    .with(
      'PAYMENT_WAITING',
      'WAIT_DEPOSIT',
      () => 상수_외부채널주문_채널상태.입금대기
    )
    .with('PAYED', 'PAID', () => 상수_외부채널주문_채널상태.결제완료)
    .with('PREPARING_DELIVERY', () => 상수_외부채널주문_채널상태.배송준비중)
    .with('DELIVERING', 'IN_DELIVERY', () => 상수_외부채널주문_채널상태.배송중)
    .with(
      'DELIVERED',
      'DELIVERY_COMPLETED',
      () => 상수_외부채널주문_채널상태.배송완료
    )
    .with(
      'PURCHASE_DECIDED',
      'PURCHASE_DECISION',
      () => 상수_외부채널주문_채널상태.구매확정
    )
    .with(
      'CANCELED_BY_NOPAYMENT',
      'DEPOSIT_CANCELED',
      () => 상수_외부채널주문_채널상태.미입금취소
    )
    .with('CANCELING', () => 상수_외부채널주문_채널상태.취소처리중)
    .with('CANCEL_REQUEST', () => 상수_외부채널주문_채널상태.취소요청)
    .with(
      'CANCEL_DONE',
      'CANCELED',
      () => 상수_외부채널주문_채널상태.취소처리완료
    )
    .with('CANCEL_REJECT', () => 상수_외부채널주문_채널상태.취소철회)
    .with('RETURN_REQUEST', () => 상수_외부채널주문_채널상태.반품요청)
    .with('RETURN_DONE', 'RETURNED', () => 상수_외부채널주문_채널상태.반품완료)
    .with('RETURN_REJECT', () => 상수_외부채널주문_채널상태.반품철회)
    .with(
      'COLLECTING',
      'RETURN_BACKWARD_DELIVERY',
      'EXCHANGE_BACKWARD_DELIVERY',
      () => 상수_외부채널주문_채널상태.수거처리중
    )
    .with(
      'COLLECT_DONE',
      'RETURN_BACKWARD_DELIVERY_DONE',
      'RETURN_BACKWARD_DELIVERY_CHECKING',
      'EXCHANGE_BACKWARD_DELIVERY_DONE',
      () => 상수_외부채널주문_채널상태.수거완료
    )
    .with('EXCHANGE_REQUEST', () => 상수_외부채널주문_채널상태.교환요청)
    .with('EXCHANGE_DONE', () => 상수_외부채널주문_채널상태.교환완료_구매확정)
    .with('EXCHANGED', () => 상수_외부채널주문_채널상태.교환완료)
    .with('EXCHANGE_REJECT', () => 상수_외부채널주문_채널상태.교환철회)
    .with(
      'EXCHANGE_REDELIVERY_READY',
      () => 상수_외부채널주문_채널상태.교환재배송대기
    )
    .with(
      'EXCHANGE_REDELIVERING',
      'EXCHANGE_FORWARD_DELIVERY',
      () => 상수_외부채널주문_채널상태.교환재배송중
    )
    .with('ADMIN_CANCELING', () => 상수_외부채널주문_채널상태.직권취소처리중)
    .with(
      'ADMIN_CANCEL_DONE',
      () => 상수_외부채널주문_채널상태.직권취소처리완료
    )
    .with(
      'EXCHANGE_REDELIVERING_TARGET',
      () => 상수_외부채널주문_채널상태.교환재배송처리중
    )
    .with(
      'COLLECT_DONE_REDELIVERING',
      () => 상수_외부채널주문_채널상태.수거완료_교환재배송처리중
    )
    .with(
      'COLLECT_DONE_EXCHANGE_DONE',
      () => 상수_외부채널주문_채널상태.수거완료_교환완료
    )
    .otherwise(() => undefined)
}
