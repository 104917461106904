import { SetStateAction, atom, useAtom } from 'jotai'
import { TExportTemplateSetting } from './export-template-setting.type'
import { ADDRESS_TYPE } from './export-template-setting-address'
import { PHONE_NUMBER_FORMAT } from './export-template-setting-phonenumber/export-template-setting-phonenumber.constants'
import { DATE_FORMAT } from './export-template-setting-dateformat/export-template-setting-dateformat.constants'

type AtomType = TExportTemplateSetting | null
const orderExportFormSettingOriginalState = atom<AtomType | null>({
  title: undefined,
  templateName: '기본 양식',
  addressType: ADDRESS_TYPE.separate,
  phoneNumberFormat: PHONE_NUMBER_FORMAT.hyphen,
  dateFormat: DATE_FORMAT.second,
})
orderExportFormSettingOriginalState.debugLabel =
  'orderExportFormSettingOriginalState'

const orderExportFormSettingState = atom(
  (get) => {
    const tabItem = get(orderExportFormSettingOriginalState)
    return tabItem
  },
  (_, set, update: SetStateAction<AtomType>) => {
    if (typeof update === 'function') {
      set(orderExportFormSettingOriginalState, (prev) => {
        const updatedSetting = update(prev)
        return updatedSetting
      })
      return
    }
    set(orderExportFormSettingOriginalState, update)
  }
)
orderExportFormSettingState.debugLabel = 'orderExportFormSettingState'

export const useExportTemplateSettingAtom = {
  setting: () => useAtom(orderExportFormSettingState),
}
