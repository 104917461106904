import { z } from 'zod'
import {
  RETRIEVE_TYPE_CODE,
  RETRIEVE_PAY_TYPE_CODE,
} from '~/entities/order-section/retrieve-request'
import { __ } from '~/shared/i18n'
import { omit } from 'lodash'

// 자동 수거 신청
const autoRetreiveRequestSchema = z.object({
  _customRetrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.AUTO_RETRIEVE_REQUEST),
  retrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.AUTO_RETRIEVE_REQUEST),
  centerCode: z.string({ required_error: __('필수로 입력해야 해요') }),
  orderDeliveryCode: z.string({ required_error: __('필수로 입력해야 해요') }),
})

// 자동 수거 신청 (직접 입력)
const autoRetreiveRequestCustomAddrSchema = z.object({
  _customRetrieveTypeCd: z.literal(
    `${RETRIEVE_TYPE_CODE.AUTO_RETRIEVE_REQUEST}.CUSTOM`
  ),
  retrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.AUTO_RETRIEVE_REQUEST),
  centerCode: z.string({ required_error: __('필수로 입력해야 해요') }),
  receiverName: z
    .string({ required_error: __('필수로 입력해야 해요') })
    .min(1, __('{{count}}자 이상', { count: 1 }))
    .max(100, __('{{count}}자 이하', { count: 100 })),
  receiverCall: z
    .string({ required_error: __('필수로 입력해야 해요') })
    .min(1, __('{{count}}자 이상', { count: 1 }))
    .max(30, __('{{count}}자 이하', { count: 30 })),
  addr1: z.string({ required_error: __('필수로 입력해야 해요') }),
  addr2: z.string().nullish(),
  zipcode: z
    .string({
      required_error: __('필수로 입력해야 해요'),
    })
    .min(1, __('{{count}}자 이상', { count: 1 }))
    .max(8, __('{{count}}자 이하', { count: 8 })),
})

// 구매자 발송
const sendingByCustomerSchema = z.object({
  _customRetrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.SENDING_BY_CUSTOMER),
  retrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.SENDING_BY_CUSTOMER),
  retrievePayTypeCd: z.nativeEnum(omit(RETRIEVE_PAY_TYPE_CODE, ['ETC']), {
    errorMap: () => ({ message: __('필수로 선택해야 해요') }),
  }),
  parcelCompanyIdx: z.number().nullish(),
  invoiceNo: z.string().nullish(),
})

// 택배사 수동 등록
const manualRetreiveRequestSchema = z.object({
  _customRetrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.MANUAL_RETRIEVE_REQUEST),
  retrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.MANUAL_RETRIEVE_REQUEST),
  retrievePayTypeCd: z.nativeEnum(
    omit(RETRIEVE_PAY_TYPE_CODE, ['ETC', 'CREDIT']),
    {
      errorMap: () => ({ message: __('필수로 선택해야 해요') }),
    }
  ),
  parcelCompanyIdx: z.number({ required_error: __('필수로 선택해야 해요') }),
  invoiceNo: z.string().min(1, __('필수로 입력해야 해요')),
})

// 기타
const etcSchema = z.object({
  _customRetrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.ETC),
  retrieveTypeCd: z.literal(RETRIEVE_TYPE_CODE.ETC),
  retrieveMemo: z.string().min(1, '필수로 입력해야 해요'),
})

export const retreiveRequestEditFormSchema = z.discriminatedUnion(
  '_customRetrieveTypeCd',
  [
    autoRetreiveRequestSchema,
    autoRetreiveRequestCustomAddrSchema,
    sendingByCustomerSchema,
    manualRetreiveRequestSchema,
    etcSchema,
  ]
)
