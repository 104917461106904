import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { Select } from '~/shared/components/ui/select'
import { useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'
import { IconChevronDown } from '@imwebme/icon'
import { goodsflowQueryOptions, goodsflowStatus } from '~/entities/goodsflow'
import { useSuspenseQuery } from '@tanstack/react-query'

export function GoodsflowPartnersSelect() {
  const { data: goodsflowList } = useSuspenseQuery({
    ...goodsflowQueryOptions(),
    select: flow(
      (e) => e.data,
      A.filter((e) => e.status === goodsflowStatus.Y)
    ),
  })
  const { setValue, register, watch } = useFormContext()
  const retrieveTypeCdValue = watch('retrieveRequestDto.retrieveTypeCd')

  function onChangeValue(e: string) {
    const value = goodsflowList?.find((item) => item.centerCode === e)
    if (!value) {
      return
    }
    setValue('retrieveRequestDto.centerCode', value.centerCode)
  }

  /**
   * 굿스플로 계정이 하나일 경우
   * UI는 노출하지 않는다.
   * 굿스플로 계정 한개 데이터를 자동 설정되도록 처리
   * useQuery는 onSuccess가 없어서 React.useEffect로 처리
   */
  React.useEffect(() => {
    if (goodsflowList?.length === 1) {
      setValue('retrieveRequestDto.centerCode', goodsflowList[0].centerCode)
    }
  }, [goodsflowList])

  if (!goodsflowList || goodsflowList.length === 0) {
    return null
  }
  if (retrieveTypeCdValue !== 'RRT01' || goodsflowList.length === 1) {
    return (
      <input type="hidden" {...register('retrieveRequestDto.centerCode')} />
    )
  }
  return (
    <>
      <div className="typo-clay-body-medium-bold">
        <Trans>굿스플로 계정</Trans>
      </div>
      <div className="grid gap-y-[12px]">
        <Select.Root onValueChange={onChangeValue}>
          <Select.Trigger className="w-full text-left h-auto border-solid">
            <Select.Value placeholder={__('굿스플로 계정')} />
            <IconChevronDown className="stroke-[2]" />
          </Select.Trigger>
          <Select.Content>
            <div>
              {goodsflowList.map((item) => (
                <Select.Item
                  value={item.centerCode}
                  key={item.centerCode}
                  className="text-left"
                >
                  {item.deliverName}
                  <br />({item.centerZipCode}) {item.centerAddress}
                </Select.Item>
              ))}
            </div>
          </Select.Content>
        </Select.Root>
      </div>
    </>
  )
}
