import { useModals } from '~/router'
import { OrderMemoPreviewWriteFormModal } from './order-memo-preview-write-form-modal'
import { useState } from 'react'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postOrderMemo } from '~/entities/order-memo'
import { orderMemoQueryOptions } from '~/entities/order/order-memo'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { __ } from '~/shared/i18n'

export interface OrderMemoPreviewWriteFormModalProps {
  handleModalClose: () => void
  handleSubmitOrderMemo: () => void
  modalTitle: string
  textAreaValue: string
  setTextAreaValue: (value: string) => void
}

export const OrderMemoPreviewWriteFormCreateModalContainer = () => {
  const queryClient = useQueryClient()
  const modals = useModals()
  const orderCode = useOrderCode()
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const { mutate: createOrderMemoMutation } = useMutation({
    mutationFn: postOrderMemo({ orderCode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderMemoQueryOptions(orderCode).queryKey,
      })
      toast.success(__('메모를 추가했어요.'))
      handleModalClose()
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message)
      }
    },
    mutationKey: ['createOrderMemo'],
  })

  const handleModalClose = () => modals.close()
  const handleSubmitOrderMemo = () => {
    createOrderMemoMutation({
      memo: textAreaValue,
    })
  }

  const props: OrderMemoPreviewWriteFormModalProps = {
    handleModalClose,
    handleSubmitOrderMemo,
    modalTitle: __('메모 추가'),
    textAreaValue,
    setTextAreaValue,
  }

  return <OrderMemoPreviewWriteFormModal {...props} />
}
