import { z } from 'zod'

export const 상수_할인타입 = {
  포인트: 'POINT', // 포인트 할인 금액
  쿠폰: 'COUPON', // 쿠폰 할인 금액
  등급: 'GRADE', // 등급 할인 금액
}

const type = z.nativeEnum(상수_할인타입)
export type T상수_할인타입 = z.infer<typeof type>
