import { ApiClient, ApiResponse } from '~/shared/api'
import { type TGetResPermissionDto, type TGetPayloadPermissionDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description permission
 */
export function getPermission() {
  return async function (params: TGetPayloadPermissionDto) {
    const result = await axios
      .get<ApiResponse<TGetResPermissionDto>>('/permissions', { params })
      .then((res) => res.data)

    return result.data
  }
}
