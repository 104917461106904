import React from 'react'
import { IconArrowNarrowRight, IconCalendar } from '@imwebme/icon'
import { type DateRange, type DayPickerRangeProps } from 'react-day-picker'
import { Calendar, type CalendarProps } from '../calendar'
import { fDate } from '~/shared/utils'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'
import { Popover } from '~/shared/components/ui/popover'

// context api
const CalendarRangeContext = React.createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>] | undefined
>(undefined)
// useContext hook
export function useCalendarRangeContext() {
  const result = React.useContext(CalendarRangeContext)
  if (result === undefined) {
    throw new Error(
      'useCalendarRangeContext must be used within a CalendarRangeContext.Provider'
    )
  }
  return result
}

export type TCalendarRange = {
  range: DateRange
  setRange: DayPickerRangeProps['onSelect']
} & Omit<CalendarProps, 'mode' | 'selected' | 'onSelect' | 'initialFocus'>
export function CalendarRange({ range, setRange, ...props }: TCalendarRange) {
  const [modal, setModal] = useCalendarRangeContext()

  return (
    <Popover.Root open={modal} onOpenChange={setModal}>
      <Popover.Trigger asChild className="border border-solid">
        <button
          type="button"
          className={cn(
            'group flex items-center gap-x-[10px] font-normal rounded-[8px] !ring-0 !ring-offset-0 bg-clay-semantic-fieldSecondary px-[12px] py-[10px] border-transparent border-2 hover:border-clay-semantic-focus hover:bg-white shadow-none',
            modal && 'bg-white border-clay-semantic-focus',
            !range && 'opacity-50'
          )}
        >
          <div>
            <IconCalendar className="stroke-[2] text-clay-semantic-iconSub" />
          </div>
          <div className="grid grid-cols-[1fr,auto,1fr] text-center" data-label>
            <div className="px-[20px] flex h-full items-center justify-center">
              {range?.from ? (
                fDate(range.from, 'yyyy.MM.d')
              ) : (
                <span className="typo-clay-body-medium text-clay-semantic-textSub">
                  {__('시작일')}
                </span>
              )}
            </div>
            <div className="flex items-center">
              <IconArrowNarrowRight className="stroke-[2] text-clay-semantic-icon text-[12px]" />
            </div>
            <div className="px-[20px] flex h-full items-center justify-center">
              {range?.to ? (
                fDate(range.to, 'yyyy.MM.d')
              ) : (
                <span className="typo-clay-body-medium text-clay-semantic-textSub">
                  {__('종료일')}
                </span>
              )}
            </div>
          </div>
        </button>
      </Popover.Trigger>
      <Popover.Content className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          selected={range}
          onSelect={setRange}
          initialFocus
          {...props}
        />
      </Popover.Content>
    </Popover.Root>
  )
}

export function CalendarRangeProvider({ children }: React.PropsWithChildren) {
  const value = React.useState(false)
  return (
    <CalendarRangeContext.Provider value={value}>
      {children}
    </CalendarRangeContext.Provider>
  )
}
