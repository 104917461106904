import { z } from 'zod'

export const 상수_배송결제타입 = {
  배송비없음: 'ODP01', // 배송비없음
  선결제: 'ODP02', // 선결제
  착불결제: 'ODP03', // 착불결제
} as const

const type = z.nativeEnum(상수_배송결제타입)
export type T상수_배송결제타입 = z.infer<typeof type>
