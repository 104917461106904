import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'
import { includes, pull } from 'lodash'
import { Flex, Checkbox, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

export function CheckboxField({ index, formConfigValue }: TFieldProps) {
  const { control, register } = useFormContext<TOrderFormDataEditFormSchema>()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: `form.${index}.value`,
    control,
  })

  const handleCheckboxChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const item = evt.target.value

      const copiedValue = Array.isArray(value) ? [...value] : []

      if (includes(copiedValue, item)) {
        pull(copiedValue, item)
      } else {
        copiedValue.push(item)
      }

      onChange(copiedValue)
    },
    [value]
  )

  return (
    <>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />

      <Flex.Column gap={vars.spacing[1]}>
        {formConfigValue.map((config) => (
          <Checkbox.Label
            key={config}
            label={config}
            onChange={handleCheckboxChange}
            value={config}
            id={config}
            checked={Array.isArray(value) && value.some((v) => v === config)}
          />
        ))}
      </Flex.Column>

      {error && (
        <Typography
          variant="body-small"
          sx={{ marginTop: vars.spacing[15] }}
          colorToken="textCritical"
        >
          {error.message}
        </Typography>
      )}
    </>
  )
}
