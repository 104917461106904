import { z } from 'zod'

export const 상수_외부채널주문_클레임_보류상태 = {
  보류중: 'HOLDBACK',
  보류해제: 'RELEASED',
  미보류: 'NOT_YET',
} as const

const type = z.nativeEnum(상수_외부채널주문_클레임_보류상태)
export type T상수_외부채널주문_클레임_보류상태 = z.infer<typeof type>
