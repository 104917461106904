import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResReturnCompleteDto,
  type TPatchPayloadReturnCompleteDto,
  patchResReturnCompleteDto,
  patchPayloadReturnCompleteDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/A039mS
 * @description [return] 외부 주문서 섹션 반품완료 or 교환수거완료 처리 (실제 해당 품목은 반품승인 또는 교환건 재배송처리 됩니다)
 */
export function patchReturnComplete({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadReturnCompleteDto) {
    type T = TPatchResReturnCompleteDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/return/complete`, body)
      .then((res) => res.data)

    patchResReturnCompleteDto.parse(result.data)
    return result
  }
}
