import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import {
  useStatusNextProductPreparationModalAtom,
  type TStatusNextProductPreparationModalBase,
} from '.'

const StatusNextProductPreparationContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.StatusNextProductPreparation),
  }))
)

interface TStatusNextProductPreparationContainer
  extends TStatusNextProductPreparationModalBase {}
export function StatusNextProductPreparationPack({
  orderCode,
  sectionCode,
  ...props
}: TStatusNextProductPreparationContainer) {
  const [open, setState] = useStatusNextProductPreparationModalAtom.state({
    orderCode,
    sectionCode,
  })

  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <StatusNextProductPreparationContainer
            orderCode={orderCode}
            sectionCode={sectionCode}
            {...props}
          />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
