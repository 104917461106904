import { useCalc } from '~/container/order/order-edit/order-edit-fn'
import { OrderEditCalcCard, useOrderSectionAtom } from '~t'
import { Trans } from 'react-i18next'
import { useAtom } from 'jotai'
import { Checkbox } from '@imwebme/clay-components'

import { OrderCancelInfoCancelRequestWithRefundAtom } from './order-cancel-info.store'
import { __ } from '~/shared/i18n'
import { useCurrency } from '~/shared/hooks/use-currency'

const OrderCancelCalcCardFooter = () => {
  const updateitems = useOrderSectionAtom.valueUpdateAll()
  const [, setOrderCancelInfoCancelRequestWithRefundAtom] = useAtom(
    OrderCancelInfoCancelRequestWithRefundAtom
  )
  // 단일 섹션 아이템인 경우
  if (updateitems && updateitems.length === 1) {
    return (
      <div className="mx-[24px] mt-[20px]">
        <div className="px-[16px] py-[8px] bg-clay-semantic-surfaceAccent rounded-lg">
          <div className="flex gap-[8px]">
            <Checkbox
              id="terms1"
              className="mt-[2px]"
              onChangeChecked={(value: boolean) => {
                setOrderCancelInfoCancelRequestWithRefundAtom(value)
              }}
            />
            <label htmlFor="terms1" className="leading-none">
              <div className="semantic-p14">
                {__('환불도 같이 진행 할래요')}
              </div>
              <div className="typo-clay-body-small text-clay-semantic-textSub mt-[2px]">
                {__('취소 접수와 환불을 한번에 할 수 있어요')}
              </div>
            </label>
          </div>
        </div>
      </div>
    )
  }
  // 2개 이상 섹션의 아이템인 경우
  return null
}
export const OrderCancelCalcCard = () => {
  const currency = useCurrency()
  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price: useCalc(),
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="footer">
        <OrderCancelCalcCardFooter />
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}
