import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

type FileSizeUnit = 'bytes' | 'KB' | 'MB' | 'GB'

function findBestFitUnit(sizeInBytes: number): FileSizeUnit {
  const units: FileSizeUnit[] = ['bytes', 'KB', 'MB', 'GB']
  let unit: FileSizeUnit = 'bytes'

  for (const currentUnit of units) {
    if (sizeInBytes < 1024) {
      unit = currentUnit
      break
    }
    sizeInBytes /= 1024
  }

  return unit
}

function formatFileSize(sizeInBytes: number, unit: FileSizeUnit): string {
  switch (unit) {
    case 'bytes':
      return `${sizeInBytes}bytes`
    case 'KB':
      return `${(sizeInBytes / 1024).toFixed(2)}KB`
    case 'MB':
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)}MB`
    case 'GB':
      return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)}GB`
    default:
      return 'Invalid unit'
  }
}

export function fileSizeToString(sizeInBytes: number): O.Option<string> {
  return pipe(
    O.fromNullable(sizeInBytes),
    O.map((size) => {
      const bestFitUnit = findBestFitUnit(size)
      return formatFileSize(size, bestFitUnit)
    }),
    O.map((formattedSize) => formattedSize)
  )
}
