import { Clay, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import GrimIconGrayUsers from '~/shared/components/icon/grim-icon-gray-users.svg?react'

export function EmptyMember() {
  return (
    <Flex.Center height="480px" paddingBottom={vars.spacing[6]}>
      <Flex.Center flexDirection="column" paddingBottom={vars.spacing[7]}>
        <GrimIconGrayUsers />
        <Typography sx={{ marginTop: vars.spacing[5] }} variant="body-medium">
          {__('검색어와 일치하는 회원 정보가 없어요')}
        </Typography>
        <Typography
          sx={{ marginTop: vars.spacing[2] }}
          variant="body-small"
          colorToken="textSub"
        >
          {__('검색어를 확인해 주세요')}
        </Typography>
      </Flex.Center>
    </Flex.Center>
  )
}
