import { z } from 'zod'
import { YN } from '~/entities/@x'
import { __ } from '~/shared/i18n'

export const deliveryDto = z
  .object({
    siteCode: z.string().describe('사이트 코드'),
    orderDeliveryCode: z.string().describe('주문배송정보코드'),
    orderCode: z.string().describe('주문 코드'),
    receiverName: z.string().describe('수령자명'),
    receiverCall: z.string().describe('수령자전화번호'),
    zipcode: z.string().min(1).max(8).describe('배송지 우편번호'),
    addr1: z.string().min(1).max(200).nullish().describe('배송지 주소1'),
    addr2: z.string().max(200).nullish().describe('배송지 주소2'),
    building: z.string().nullish().describe('배송지 건물명'),
    street: z.string().nullish().describe('배송지 거리명'),
    city: z.string().nullish().describe('배송지 도시'),
    state: z.string().nullish().describe('배송지 주'),
    country: z.string().nullish().describe('배송지 국가코드(ISO_3166)'),
    countryName: z.string().nullish().describe('배송지 국가명'),
    memo: z.string().max(200).nullish().describe('메모 (배송 요청사항)'),
    isInput: z.nativeEnum(YN).describe('선물배송지입력여부'),
    whoInput: z.enum(['orderer', 'receiver']).describe('배송지입력한대상'),
    isDel: z.nativeEnum(YN).describe('삭제여부'),
    wtime: z.string().nullish().describe('등록일시'),
    mtime: z.string().describe('변경일시'),
  })
  .describe('주문 배송지 정보 목록')

export type TDeliveryDto = z.infer<typeof deliveryDto>

const korDelivPayloadSchema = z.object({
  addr1: z
    .string({ message: __('필수로 입력해야 해요') })
    .min(1, __('필수로 입력해야 해요'))
    .max(200, __('200자 이내로 입력해주세요'))
    .describe('배송지 주소1'),
  addr2: z
    .string()
    .max(200, __('200자 이내로 입력해주세요'))
    .optional()
    .describe('배송지 주소2'),
})

const intlDelivPayloadSchema = z.object({
  building: z
    .string()
    .max(100, __('100자 이내로 입력해주세요'))
    .optional()
    .describe('배송지 건물명'),
  street: z
    .string({ message: __('필수로 입력해야 해요') })
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요'))
    .describe('배송지 거리명'),
  city: z
    .string({ message: __('필수로 입력해야 해요') })
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요'))
    .describe('배송지 도시'),
  state: z
    .string({ message: __('필수로 입력해야 해요') })
    .min(1, __('필수로 입력해야 해요'))
    .max(100, __('100자 이내로 입력해주세요'))
    .describe('배송지 주'),
})

export const delivPayloadSchema = z
  .object({
    country: z
      .string()
      .min(1, __('필수로 선택해야 해요'))
      .describe('배송지 국가코드(ISO_3166)'),
    countryName: z
      .string()
      .min(1, __('필수로 선택해야 해요'))
      .describe('배송지 국가명'),
    receiverName: z
      .string({ message: '필수로 입력해야 해요' })
      .min(1, __('필수로 입력해야 해요'))
      .max(100, __('100자 이내로 입력해주세요'))
      .describe('수령자명'),
    receiverCall: z
      .string({ message: '필수로 입력해야 해요' })
      .min(1, __('필수로 입력해야 해요'))
      .max(20, __('20자 이내로 입력해주세요'))
      .describe('수령자전화번호'),
    zipcode: z
      .string({ message: '필수로 입력해야 해요' })
      .min(1, __('필수로 입력해야 해요'))
      .max(20, __('20자 이내로 입력해주세요'))
      .describe('배송지 우편번호'),
    whoInput: z
      .union([z.literal('orderer'), z.literal('receiver')], {
        message: __('필수로 선택해야 해요'),
      })
      .describe('배송지 입력 대상 (선물하기 주문에서 주문자, 수령자)'),
    memo: z
      .string()
      .max(200, __('200자 이내로 입력해주세요'))
      .optional()
      .describe('메모 (배송 요청샆항)'),
    kor: korDelivPayloadSchema.optional().describe('한국 주소'),
    intl: intlDelivPayloadSchema.optional().describe('해외 주소(5형식)'),
  })
  .refine(
    (val) => {
      if (
        val.country === 'KR' &&
        !korDelivPayloadSchema.safeParse(val.kor).success
      ) {
        return false
      }
      if (
        val.country !== 'KR' &&
        !intlDelivPayloadSchema.safeParse(val.intl).success
      ) {
        return false
      }
      return true
    },
    { message: __('한국/해외 주소를 입력해주세요') }
  )
export type TDelivPayloadSchema = z.infer<typeof delivPayloadSchema>
