import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type { TPostPayloadBulkActionPaymentApplySchema } from './bulk-action-payment-apply.type'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/ubCOnX
 * @description 주문 입금 완료 변경 대량 처리 등록
 */
export async function postBulkActionPaymentApply<
  T = ApiVoidResponse,
  R = TPostPayloadBulkActionPaymentApplySchema,
>({ body }: { body: R }) {
  const result = await axios.post<T, AxiosResponse<ApiResponse<T>>, R>(
    '/order/bulk-action/payment',
    body
  )

  return result.data
}
