import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { type TProductCard001 } from '~t'
import type { TOrderSectionList } from './class-order.type'
import OrderSectionItemClass from './class-order-item'

export class COrderSection {
  #data: TOrderSectionList
  #currency: string

  constructor(data: TOrderSectionList, currency: string) {
    this.#data = data
    this.#currency = currency
  }

  get meta() {
    return this.#data
  }

  get statusCd() {
    return this.#data.statusCd
  }

  get orderSectionNo() {
    return this.#data.orderSectionNo
  }

  get invoice(): TOrderSectionList['invoice'] {
    return this.#data.invoice
  }

  get id() {
    return this.#data.orderSectionCode
  }

  get templateItemList(): TProductCard001.props[] {
    const itemList = this.#data.orderSectionItemList
    return pipe(
      itemList,
      A.map(
        flow(
          (item) => new OrderSectionItemClass(item, this.#currency),
          (item) => item.template
        )
      )
    )
  }

  /**
   * c3 api body
   * https://c3api.imstage.me/admin/docs#tag/[[EC]]A3[[BC]]EB[[AC]]B8-[[EC]]84[[B9]]EC[[85]]98/operation/OrderSectionController_createOrderSectionAndItem
   */
  get c3createOrderSectionAndItem() {
    const itemList = this.#data.orderSectionItemList
    return pipe(
      itemList,
      A.map(
        flow((item) => ({
          orderSectionItemCode: item.orderItemCode,
          prodcode: item.prodCode,
          prodOptionDetailCode: item.prodOptionDetailCode,
          prodName: item.prodName,
          qty: '',
          baseItemPrice: item.baseItemPrice,
          itemPrice: item.itemPrice,
        }))
      )
    )
  }
}
