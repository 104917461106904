import { Flex, Typography, Button, ButtonGroup } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __, Link } from '~/shared/i18n'
import { useNavigate } from '~/router'

export default function Page403() {
  const navigate = useNavigate()
  return (
    <Flex.Center flexDirection="column" height="100svh" marginBottom={-200}>
      <Typography variant="heading-5xlarge-bold">403</Typography>
      <Typography variant="heading-large" sx={{ marginTop: vars.spacing[4] }}>
        {__('접근 권한이 없습니다')}
      </Typography>
      <ButtonGroup isInline sx={{ marginTop: vars.spacing[8] }}>
        <Button
          text={__('뒤로가기')}
          variant="secondary"
          onClick={() => navigate(-1)}
        />
        <Link to="/" replace>
          <Button as="div" text={__('홈으로')} variant="primary" />
        </Link>
      </ButtonGroup>
    </Flex.Center>
  )
}
