import {
  Clay,
  Tabs,
  TabList,
  Tab,
  Typography,
  Flex,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Plus } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { SkyRing } from '~/container/onboarding/partials/sky-ring'

export function OrderSearchFilter() {
  return (
    <Flex.Column
      padding={vars.spacing[5]}
      paddingTop={vars.spacing[1]}
      gap={vars.spacing[3]}
      position="relative"
    >
      <Clay position="absolute" top={9} left={148} width={20} height={20}>
        <SkyRing />
      </Clay>

      <Tabs size="small" variant="filled" index={0}>
        <TabList>
          <Tab text={__('기본 필터')} />
          <Tab text={__('내 필터 추가')} icon={<Plus />} />
        </TabList>
      </Tabs>

      <Clay
        paddingTop={vars.spacing[3]}
        paddingBottom={vars.spacing[4]}
        borderRadius={vars.rounded.large}
        border={`1px solid ${vars.semantic.color.border}`}
      >
        <Typography
          variant="heading-medium-bold"
          sx={{
            padding: `${vars.spacing[1]} ${vars.spacing[6]}`,
          }}
        >
          {__('기본 필터')}
        </Typography>
        <Typography
          as="div"
          variant="label-medium-bold"
          colorToken="textSecondary"
        >
          <Flex
            gap={vars.spacing[1]}
            padding={`0 ${vars.spacing[6]}`}
            marginTop={vars.spacing[3]}
          >
            {[
              __('기간'),
              __('결제수단'),
              __('할인수단'),
              __('구매금액'),
              __('주문품목수'),
            ].map((tag) => (
              <Flex
                key={tag}
                backgroundColor={vars.semantic.color.surfaceSub}
                padding={`${vars.spacing[1]} ${vars.spacing[25]}`}
                borderRadius={'100px'}
                gap={vars.spacing[1]}
                alignItems="center"
              >
                {tag}
                <Plus colorToken="icon-secondary" />
              </Flex>
            ))}
          </Flex>
        </Typography>
      </Clay>
    </Flex.Column>
  )
}
