import React from 'react'
import { TDeliveryBannerDirectBase } from '.'

const DeliveryBannerDirectContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryBannerDirect),
  }))
)

interface TDeliveryBannerDirectPack extends TDeliveryBannerDirectBase {}
export function DeliveryBannerDirectPack(props: TDeliveryBannerDirectPack) {
  return (
    <React.Suspense>
      <DeliveryBannerDirectContainer {...props} />
    </React.Suspense>
  )
}
