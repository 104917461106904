import { match, P } from 'ts-pattern'
import { Controller } from 'react-hook-form'
import { useFormContextExternalOrderReturnInfo } from '../external-order-return-info'
import { Radio } from '@imwebme/clay-components'
import { 상수_수거타입 } from '~/entities/@x'
import { __ } from '~/shared/i18n'
import { RRT02 } from './external-rrt02'
import { RRT04 } from './external-rrt04'

export function ReturnRetrieveTypeCd() {
  const { control, watch } = useFormContextExternalOrderReturnInfo()
  const retrieveTypeCd = watch('retrieveRequestDto.retrieveTypeCd')
  return (
    <>
      <Controller
        name="retrieveRequestDto.retrieveTypeCd"
        control={control}
        render={({
          field: { value: formValue, onChange, ...rest },
          fieldState: { error },
        }) => (
          <>
            <Radio.Group
              value={formValue ?? ''}
              onChangeValue={onChange}
              direction="row"
              {...rest}
            >
              <Radio.Label
                value={상수_수거타입.구매자발송}
                id={상수_수거타입.구매자발송}
                label={__('구매자 발송')}
              />
              <Radio.Label
                value={상수_수거타입.수동수거신청}
                id={상수_수거타입.수동수거신청}
                label={__('택배사 수동 등록')}
              />
            </Radio.Group>
            {error && (
              <div className="mt-[6px] typo-clay-body-small text-clay-semantic-textCritical">
                {error.message}
              </div>
            )}
          </>
        )}
      />
      <div className="mt-[20px]">
        {match<boolean>(true)
          .with(retrieveTypeCd == 상수_수거타입.구매자발송, () => <RRT02 />)
          .with(retrieveTypeCd == 상수_수거타입.수동수거신청, () => <RRT04 />)
          .otherwise(() => null)}
      </div>
    </>
  )
}
