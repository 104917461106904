import { pipe, flow } from 'fp-ts/function'
import { Outlet } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ToastContainer } from '~/app/provider/toast-container'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'
import { AppProvider } from '~/app/provider/_app.provider'
import { getPermission } from '~/entities/permission'
import {
  store,
  permissionAtom,
} from '~/app/provider/permission/permission.store'
import { Redirects } from '~/app/redirects'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

export const Loader = async () => {
  const init = async () => {
    try {
      // OAuth2.0 토큰을 발행
      await ImwebOAuth2ClientAPI.init()
      // 해당 유저의 권한을 가져온다
      const data = await getPermission()({})

      store.set(permissionAtom, data)

      return true
    } catch (error) {
      return false
    }
  }
  return await init()
}

/**
 * @description
 * 빌드후 배포시 dist파일이 없어진다. 배포는 정상적으로되었지만 파일이 없는 것임으로 해당 함수로 새로고침해서 새버전의 앱으로 변경한다
 */
window.addEventListener('vite:preloadError', () => {
  if (import.meta.env.VITE_NODE_ENV !== 'local') {
    window.parent.postMessage(
      {
        source: 'refresh',
        message: 'refresh',
      },
      '*'
    )
  }
})

function App() {
  return (
    <main>
      <ToastContainer />
      <Redirects>
        <Outlet />
      </Redirects>
      <div className="pt-[200px]" />
    </main>
  )
}

// 로컬환경에서는 Sentry를 사용하지 않는다.
export default pipe(App, (A) => {
  if (import.meta.env.VITE_NODE_ENV === 'local') {
    return pipe(A, AppProvider)
  }
  return pipe(A, AppProvider, Sentry.withProfiler)
})
