import { atom } from 'recoil'

const ORDER_TABLET_ITLE = 'key-orderTableHeader-title'
const orderTableTitleState = atom<string>({
  key: ORDER_TABLET_ITLE,
  default: '',
})

const ORDER_TABLE_TOTALCOUNT = 'key-orderTableHeader-totalCount'
export const orderTableTotalCountState = atom<number>({
  key: ORDER_TABLE_TOTALCOUNT,
  default: 0,
})

export const OrderTableHeaderState = {
  title: orderTableTitleState,
  totalCount: orderTableTotalCountState,
}
