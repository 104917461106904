import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { __, __g } from '~/shared/i18n'
import { DropdownTriggerBtn } from '../partials'
import { TDeliveryDto, deliveryDto } from '~/entities/@dto'
import { Clay, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

interface TProps {
  children: React.ReactNode
  orderDeliveryList: TDeliveryDto[]
  unipassNumber: string | null
  AddressByCountry: React.ComponentType<{ delivery: TDeliveryDto }>
}

export function DeliveryPreview({
  children,
  orderDeliveryList,
  unipassNumber,
  AddressByCountry,
}: TProps) {
  const [open, setOpen] = React.useState(false)

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownTriggerBtn {...{ setOpen }}>{children}</DropdownTriggerBtn>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={8}
          align="start"
          onCloseAutoFocus={(evt) => evt.preventDefault()}
          className="w-[280px] bg-clay-semantic-surface py-[16px] rounded-[8px] shadow-clay-layer h-fit max-h-[min(var(--radix-popper-available-height),400px)] overflow-y-auto"
          hideWhenDetached
          collisionPadding={12}
        >
          {orderDeliveryList.map((delivery, idx) => {
            const parsed = deliveryDto.safeParse(delivery)
            if (!parsed.success) {
              return null
            }
            return (
              <React.Fragment key={delivery.orderDeliveryCode}>
                <Flex.Column
                  gap={vars.spacing[15]}
                  paddingInline={vars.spacing[5]}
                >
                  <Typography variant="body-medium-bold">
                    {delivery.receiverName}
                  </Typography>
                  <Typography variant="body-medium-bold">
                    {delivery.receiverCall}
                  </Typography>
                  <Typography whiteSpace="pre-line">
                    <AddressByCountry delivery={delivery} />
                  </Typography>
                  <Typography variant="body-medium" colorToken="textSub">
                    {delivery.memo || __('배송메모 없음')}
                  </Typography>
                  {unipassNumber && (
                    <Typography variant="body-medium" colorToken="textSub">
                      {unipassNumber}
                    </Typography>
                  )}
                </Flex.Column>
                {idx < orderDeliveryList.length - 1 && (
                  <Clay
                    as="hr"
                    margin={`${vars.spacing[4]} 0`}
                    borderColor={vars.semantic.color.divide}
                  />
                )}
              </React.Fragment>
            )
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
