import React from 'react'
import { __ } from '~/shared/i18n'
import { DeliveryPreview } from '~/container/order-preview'
import { TDeliveryDto, deliveryDto } from '~/entities/@dto'
import { AddressByCountry } from './address-by-country'
import { match, P } from 'ts-pattern'

interface Props {
  boundaryRef?: React.RefObject<HTMLElement>
  orderDeliveryList: TDeliveryDto[]
  unipassNumber: string | null
}

export function DeliverySummary({
  boundaryRef,
  orderDeliveryList,
  unipassNumber,
}: Props) {
  const filteredList = orderDeliveryList.filter((delivery) => {
    const parsed = deliveryDto.safeParse(delivery)
    return parsed.success
  })

  const mainDelivery = () => {
    const delivery = filteredList[0]
    return (
      match(delivery.country)
        .with('KR', () => delivery.addr1?.split(' ').slice(0, 2).join(', '))
        .otherwise(() =>
          [delivery.country, delivery.state]
            .filter(Boolean)
            .slice(0, 2)
            .join(', ')
        ) || delivery.zipcode
    )
  }

  return (
    <DeliveryPreview
      {...{ boundaryRef, orderDeliveryList, unipassNumber, AddressByCountry }}
    >
      {match(filteredList.length)
        .with(P.number.gt(1), () =>
          __('{{배송지}} 외 {{이외주소개수}}곳', {
            배송지: mainDelivery(),
            이외주소개수: filteredList.length - 1,
          })
        )
        .otherwise(() => mainDelivery())}
    </DeliveryPreview>
  )
}
