import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import deepEqual from 'fast-deep-equal'
import { PageLayout } from './components/page-layout'
import { OrderSearchTabForm } from './components/order-search-tab-form'
import {
  patchOrderSearchTab,
  orderSearchTabQueryOptions,
  TGetResOrderSearchTabSchema,
} from '~/entities/order-search-tab'
import { toast } from 'react-toastify'
import { __, __e } from '~/shared/i18n'
import {
  TOrderSearchTabRdo,
  TOrderSearchTabSelectRdo,
  orderSearchTabResolver,
} from './order-search-tab.rdo'
import {
  useSelectData,
  useHandleCancel,
  useApiPayload,
} from './order-seasrch-tab.hook'
import { 외부채널Idx } from './order-search-tab.constants'
import { 믹스패널 } from '~/shared/mixpanel'

export default function OrderSearchTabEdit() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const selectDataList = useSelectData()
  const handleCancel = useHandleCancel()
  const getApiPayload = useApiPayload()
  const [searchParams] = useSearchParams()
  const tabCode = searchParams.get('tabCode') ?? ''

  const { data: tabData } = useSuspenseQuery({
    ...orderSearchTabQueryOptions(),
    select: React.useCallback(
      (data: ApiResponse<TGetResOrderSearchTabSchema>) =>
        data.data.list.find((tab) => tab.adminOrderSearchTabCode === tabCode),
      [tabCode]
    ),
  })

  const { mutate } = useMutation({
    mutationFn: patchOrderSearchTab({ tabCode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderSearchTabQueryOptions().queryKey,
      })

      toast.success(__('탭을 수정했어요'))
      dataLayer.push({
        event: 믹스패널.click_bo_oms_order_list_tap_add_tap_save,
      })
      navigate(`/orders/tab/${tabCode}`)
    },
    onError: (e) => {
      toast.error(
        e.response?.data.code
          ? __e(e.response?.data.code)
          : '탭 수정에 실패했어요'
      )
    },
  })

  const formInitialValues: TOrderSearchTabRdo = React.useMemo(() => {
    const selectData = selectDataList.reduce(
      (acc, { name, options }) => ({
        ...acc,
        [name]: options.map((option) => option.value),
      }),
      {} as TOrderSearchTabSelectRdo
    )

    if (!tabData) {
      return { ...selectData, name: '' }
    } else if (!tabData?.columnCondition) {
      return { ...selectData, name: tabData.name }
    }

    const selectDataFromServer = {} as TOrderSearchTabSelectRdo
    const _saleChannelIdx: string[] = []
    for (const [key, value] of Object.entries(tabData.columnCondition)) {
      if (!value) {
        continue
      }

      // 주문채널 관련: README 참조
      if (key === 'unitCode') {
        for (const idx of value) {
          _saleChannelIdx.push(String(idx))
        }
        continue
      }
      if (key === 'saleChannelIdx') {
        for (const idx of value) {
          if (외부채널Idx.includes(String(idx))) {
            _saleChannelIdx.push(String(idx))
          }
        }
        continue
      }

      if (key === 'isMember' || key === 'isDomestic') {
        Object.assign(selectDataFromServer, { [key]: [value] })
        continue
      }

      Object.assign(selectDataFromServer, { [key]: value })
    }

    return {
      ...selectData,
      ...selectDataFromServer,
      ...(_saleChannelIdx.length > 0 && {
        _saleChannelIdx: _saleChannelIdx as [string, ...string[]],
      }),
      name: tabData.name,
    }
  }, [selectDataList, tabData])

  const methods = useForm<TOrderSearchTabRdo>({
    resolver: orderSearchTabResolver,
    defaultValues: formInitialValues,
    mode: 'onChange',
  })

  const formValues = methods.watch()
  const formNotChanged = React.useMemo(
    () => deepEqual(formValues, formInitialValues),
    [formInitialValues, formValues]
  )

  function onSubmit(data: TOrderSearchTabRdo) {
    mutate(getApiPayload(data))
  }

  React.useEffect(() => {
    methods.setFocus('name')
  }, [methods.setFocus])

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <OrderSearchTabForm
            selectDataList={selectDataList}
            handleCancel={handleCancel}
            isLoading={methods.formState.isSubmitting}
            isDisabled={formNotChanged || !methods.formState.isValid}
          />
        </form>
      </FormProvider>
    </PageLayout>
  )
}
