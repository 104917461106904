import { Button, ButtonGroup, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'

interface Props {
  isOverflowing: boolean
  setClose: () => void
}

export function ModalFooter({ isOverflowing, setClose }: Props) {
  return (
    <Clay
      paddingInline={vars.spacing[6]}
      paddingBottom={vars.spacing[4]}
      paddingTop={isOverflowing ? vars.spacing[3] : undefined}
      marginTop={isOverflowing ? undefined : vars.spacing[3]}
      boxShadow={isOverflowing ? vars.dropShadow.layer : undefined}
    >
      <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
        <Button text={__('닫기')} variant="outlined" onClick={setClose} />
      </ButtonGroup>
    </Clay>
  )
}
