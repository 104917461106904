import { useFormContext, Controller } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'
import { OptionItem, OptionList, Select } from '@imwebme/clay-components'

export function SelectField({ index, formConfigValue }: TFieldProps) {
  const { control, register } = useFormContext<TOrderFormDataEditFormSchema>()
  return (
    <>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />
      <Controller
        name={`form.${index}.value`}
        control={control}
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <>
            <Select>
              <Select.Trigger>
                <Select.Input
                  value={value[0] ?? ''}
                  placeholder={__('선택해 주세요')}
                  isValid={error ? false : undefined}
                  helperText={error?.message}
                  {...rest}
                />
              </Select.Trigger>
              <Select.Portal>
                <OptionList
                  popOver
                  value={value[0] ?? ''}
                  setValue={(v) => onChange([v])}
                >
                  {formConfigValue.map((config) => (
                    <OptionItem text={config} key={config} />
                  ))}
                </OptionList>
              </Select.Portal>
            </Select>
          </>
        )}
      />
    </>
  )
}
