import { IconArrowLeft } from '@imwebme/icon'
import {
  HydrateSectionAtom,
  useOrderSectionAtom,
  type TProductCard001,
  OrderEditCalcCard,
} from '~t'
import { toast } from 'react-toastify'
import React, { useEffect } from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { useAtomValue } from 'jotai'
import { COrder } from '~/container/order/class-order'
import {
  OrderAddAtom,
  useOrderAddConfirmAtom,
} from '~/container/order/order-add/order-add.store'
import { type TOrderAddConfirm } from '~/container/order/order-add/order-add.store'
import { Trans } from 'react-i18next'
import { useCalc } from '~/container/order/order-edit/order-edit-fn'
import deepEqual from 'fast-deep-equal'
import OrderSectionContainerOverlay from './order-add-section'
import { __, useLL } from '~/shared/i18n'
import { useNavigate } from 'react-router-dom'
import { Link } from '~/shared/components/link'
import { useParams } from 'react-router-dom'
import { OrderEditHeader } from '~t/order-edit/order-edit-header'
import { OrderHeaderStep } from '~t/order-edit/order-edit-header/partials/order-header-step'
import { Button, Flex, Typography } from '@imwebme/clay-components'
import { OrderTimelinePack } from '../../order-timeline'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useCurrency } from '~/shared/hooks/use-currency'
import { vars } from '@imwebme/clay-token'
import { 상수_판매채널 } from '~/entities/@x'

export const OrderAddCalcCard = () => {
  const currency = useCurrency()
  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 추가 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<flex><price>{{price, 3comma}}</price> <currency>{{currency, currency}}</currency></flex>"
          values={{
            price: useCalc(),
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{
            flex: <Flex gap={vars.spacing[1]} alignItems="center" />,
            price: <Typography variant="heading-xlarge-bold" as="span" />,
            currency: <Typography variant="body-medium" as="span" />,
          }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        {__('예상 추가 금액')}
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요. <br />
            품목추가의 경우 할인금액 적용은 <br />
            다음 단계에서 설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}

const RkeyFilter = (keyT: string) => (key: string, value: any) => {
  if (key !== keyT) {
    return O.some(value)
  }
  return O.none
}

function groupArrayElements<T>(groupSize: number): (arr: T[]) => T[][] {
  return (arr: T[]) => pipe(arr, A.chunksOf(groupSize))
}

function Header({
  orderNo,
  onClickNext,
  nextBtnDisabled,
}: {
  orderNo: string
  onClickNext: () => void
  nextBtnDisabled: boolean
}) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{
            orderCode,
            saleChannel: pipe(상수_판매채널.아임웹, String),
          }}
          className="flex gap-x-[4px] items-center"
        >
          <IconArrowLeft className="stroke-[2]" />
          <span>{orderNo}</span>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('추가')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: '품목 선택',
              active: true,
            },
            {
              id: '2',
              label: '금액 계산',
              active: false,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <Button
          native={{ type: 'button' }}
          variant="primary"
          onClick={onClickNext}
          isDisabled={nextBtnDisabled}
          text={__('다음')}
        />
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

interface OrderAddProps {
  readyItems: {
    id: string
    label: string
    value: TProductCard001.props[]
    statusCd: string
  }[]
  memberCode: string
  orderType: string
  orderNo: number
}

const OrderAdd = ({
  readyItems,
  memberCode,
  orderType,
  orderNo,
}: OrderAddProps) => {
  const router = useNavigate()
  const location = useLL()
  const params = useParams()
  const orderCode = params.orderCode
  if (!orderCode) {
    // orderCode가 없으면 안되는 페이지
    throw new Error('orderCode is undefined')
  }
  const updateItems = useOrderSectionAtom.valueUpdateAll()
  const searchConfirmItems = useOrderAddConfirmAtom.confirm()
  const [, setOrderAddConfirmData] = useOrderAddConfirmAtom.submit()
  const OrderAddAtomValue = useAtomValue(OrderAddAtom)

  const updateSearchConfirmItems = React.useCallback(() => {
    if (searchConfirmItems === undefined && updateItems === undefined) {
      return
    }
    // 업데이트 아이템이 생길때 confirm의 qty를 변경해줘야한다.
    // updateItems에 id - sectionCode, values는 od(옵션디테일), oi(orderItemCode), s
    const _updateSearchConfirmItems = pipe(
      updateItems ?? [],
      // id에 oi로 시작하는 객체를 필터링한다.
      A.map((e) => ({
        ...e,
        value: pipe(
          e.value,
          A.filter((r) => r.isNew)
        ),
      })),
      A.filter((e) => !!e.value.length),
      A.map((e) => {
        // 섹션아이템이다.
        // 컨펌데이터에 업데이트해야한다.
        const confirmData = pipe(
          searchConfirmItems ?? [],
          A.findFirst((r) => r.orderSectionCode === e.id),
          O.fold(
            () => undefined,
            flow((r) => ({
              ...r,
              prods: pipe(
                r.prods,
                // 컨펌데이터쪽이다.
                A.map((t) => {
                  // 변경된 아이템을 찾아서 컨펌데이터에 qty를 변경해준다.
                  const updateItemsFindConfirmId = pipe(
                    e.value,
                    A.findFirst((y) => {
                      // FIXME:
                      // 시간없어서 그냥 이렇게합니다. 나중에 리팩토링 필요함

                      // item에 아이디를 가지고 od, s 체크해야한다.
                      // 필수조합상품
                      if (
                        y.id.split(':').length === 1 &&
                        y.id.startsWith('od')
                      ) {
                        return y.id === t.prodOptionDetailCode
                      }
                      // 단일상품
                      if (
                        y.id.split(':').length === 1 &&
                        y.id.startsWith('s')
                      ) {
                        return y.id === t.prodCode
                      }
                      // 단일상품_입력형
                      if (
                        y.id.split(':').length > 1 &&
                        y.id.startsWith('SICO=') &&
                        t.optionDataList
                      ) {
                        const sOptionDataList = pipe(
                          y.id.slice('SICO='.length),
                          (u) => u.split(':'),
                          (u) => u[0]
                        )
                        return pipe(
                          t.optionDataList,
                          A.findFirst((u) => u.optionCode === sOptionDataList),
                          O.fold(
                            () => false,
                            () => true
                          )
                        )
                      }
                      // 필수비조합
                      if (
                        y.id.split(':').length > 1 &&
                        y.id.startsWith('RNCO=') &&
                        t.optionDataList
                      ) {
                        // 선택옵션이면 optionDataList를 비교해야한다.
                        // optionDataList에서 해당 id를 찾아서 비교해야한다.
                        // 필수 비조합 모든 데이터
                        // RNCO=1:2:3:4:5:6:7:8:9:10:11:12:13:14:15:16:17:18:19:20:21:22:23:24:25:26:27:28:29:30
                        // 검색결과 데이터는 t에서 가져올수있다.
                        // 업데이트된 데이터는 y에서 가져올수있다.
                        // RNCO=을 제거하고 나머지만 가져온다

                        const cOptionDataList = pipe(
                          y.id.slice('RNCO='.length),
                          (u) => u.split(':'),
                          A.mapWithIndex((index, u) => {
                            if (index % 2 === 0) {
                              return ['optionCode', u]
                            }
                            return ['valueCode', u]
                          }),
                          groupArrayElements(2),
                          A.map(Object.fromEntries)
                        ) as {
                          optionCode: string
                          valueCode: string
                        }[]
                        // 특이케이스1
                        // cOptionDataList에서 key와 value가 동일한것은
                        // 필수조합옵션에서 입력형 옵션 option object이기 때문에
                        // key와 value가 동일한 녀석은 deepEqual에서 제거해야한다.
                        const 옵션코드와밸류코드가동일한_키들 = pipe(
                          cOptionDataList,
                          A.filter((_e) => _e.optionCode === _e.valueCode),
                          A.map((_e) => _e.optionCode)
                        )

                        // elixir에서 패턴매칭처럼 t의 optionDataList에서 cOptionDataList 배열에서 optionCode와 valueCode가 같은것을 찾아서 비교해야한다.
                        // 같으면 true를 리턴해야한다
                        const optionDataList = pipe(
                          [...t.optionDataList],
                          A.map(R.filterMapWithIndex(RkeyFilter('optionType')))
                        )

                        /**
                         * @title 특이케이스1
                         * 이어지는코드
                         *  optionDataList는 valueCode가 입력된값이다.
                         *  그래서 여기서 검증할때만 `옵션코드와밸류코드가동일한_키들`요 값을
                         *  순회해서 optionDataList에서 동일한 키값은 valueCode의 값을 optionCode로 넣어서
                         *  검증한다
                         */
                        pipe(
                          // array 깊은 복사
                          optionDataList,
                          A.map((_e) => {
                            // _e의 optionCode의 값을 `옵션코드와밸류코드가동일한_키들`와 동일한지 확인한다
                            const isSomeKey = pipe(
                              옵션코드와밸류코드가동일한_키들,
                              A.findFirst((_k) => _k === _e.optionCode),
                              O.fold(
                                () => false,
                                () => true
                              )
                            )

                            if (isSomeKey) {
                              _e.valueCode = _e.optionCode
                            }

                            return _e
                          })
                        )

                        const _result = deepEqual(
                          optionDataList,
                          cOptionDataList
                        )
                        return _result
                      }
                      // 선택옵션
                      if (y.id.startsWith('SIO=')) {
                        // 선택옵션의 아이디는 `SIO=${optionDetailCode}`이다.
                        // SIO= 를제거해서 optionDetailCode를 가져온다.
                        const optionDetailCode = y.id.slice('SIO='.length)
                        return t.prodOptionDetailCode === optionDetailCode
                      }
                      return y.id === t.prodCode
                    }),
                    O.fold(
                      () => undefined,
                      (y) => {
                        // 특수하게 선택옵션일때 baseItemPrice를 변경해줘야해서 여기에 로직을 끼워넣는다.
                        if (y.id.startsWith('SIO=')) {
                          y.baseItemPrice = y.originalPrice
                        }
                        return y
                      }
                    )
                  )
                  if (updateItemsFindConfirmId === undefined) {
                    return t
                  }
                  return {
                    ...t,
                    qty: updateItemsFindConfirmId.qtyChange || 1,
                    itemPrice:
                      (updateItemsFindConfirmId.originalPrice || 0) -
                      (updateItemsFindConfirmId.discountPrice || 0),
                    // 특수하게 선택옵션일때 baseItemPrice를 변경해줘야해서 여기에 로직을 끼워넣는다.
                    ...(updateItemsFindConfirmId.baseItemPrice
                      ? {
                          baseItemPrice: updateItemsFindConfirmId.baseItemPrice,
                        }
                      : {}),
                  }
                })
              ),
            }))
          )
        )
        return confirmData
      }),
      A.filter((e) => e !== undefined)
    )

    return _updateSearchConfirmItems
  }, [updateItems])

  const c3payloadItems = React.useCallback(() => {
    if (OrderAddAtomValue && updateItems) {
      const sections = new COrder(OrderAddAtomValue).orderSectionList
      // 주문서에서 변화된 데이터는 여기서 찾자.
      const _result = pipe(
        sections,
        // 주문서에 존재했던 섹션데이터에서 UI상 업데이트된 섹션테이터를 찾아서 맵핑한다.
        A.map(
          flow(
            (section) => section.meta,
            (s) => ({
              orderSectionCode:
                s.orderSectionCode === 'new' ? undefined : s.orderSectionCode,
              prods: pipe(
                s.orderSectionItemList.map((item) =>
                  pipe(
                    updateItems,
                    A.findFirst((e) => e.id === s.orderSectionCode),
                    // 첫번째 value를 가져오기
                    O.map((e) => e.value),
                    // 첫번째 value에서 itemId를 가진 아이템을 찾기
                    O.map((e) => e.find((v) => v.id === item.orderItemCode)),
                    // TOrderAddConfirm.items의 타입에 맞게 변환
                    // updateItems에서 qtyChange, itemPrice, baseItemPrice만 맵핑하고 나머지는 섹션 아이템값으로 넣어준다.
                    O.fold(
                      () => undefined,
                      flow(
                        // undefined를 필터링한다.
                        O.fromNullable,
                        O.fold(
                          () => undefined,
                          flow((e) => ({
                            orderSectionItemCode: item.orderSectionItemCode,
                            prodCode: item.prodCode,
                            prodOptionDetailCode: item.prodOptionDetailCode,
                            prodName: item.prodName,
                            qty: item.qty + (e.qtyChange || 0),
                            baseItemPrice:
                              e.originalPrice || item.baseItemPrice,
                            itemPrice: item.itemPrice,
                            isRequireOption: item.isRequireOption || 'Y',
                          }))
                        )
                      )
                    )
                  )
                ),
                A.filter((e) => !!e?.orderSectionItemCode)
              ),
            })
          )
        ),
        A.filter((e) => !!e.prods.length),
        (e) => e as TOrderAddConfirm[]
      )

      return _result
      // 검색에서 추가한 상품은 여기서 찾자.

      // 주문서 변화 데이터와 검색에서 추가된 상품을 머지한다.
      // setOrderAddConfirmData(c3payloadItems)
    }
    return undefined
  }, [updateItems])

  const orderAddConfirmData = React.useCallback(
    // 검색결과에 업데이트된 데이터가 있으면 c3payloadItems에 추가해준다.
    () => {
      const usci = updateSearchConfirmItems()
      const c3pli = c3payloadItems()
      if (usci?.length && c3pli?.length) {
        // 검색결과에 업데이트된 데이터가 있으면 c3payloadItems에 추가해준다.
        return pipe(
          usci,
          A.map((e) => {
            if (e === undefined) {
              return undefined
            }
            const findIndex = pipe(
              c3pli,
              A.findIndex((r) => r.orderSectionCode === e.orderSectionCode),
              O.fold(
                () => undefined,
                (t) => t
              )
            )
            if (findIndex === -1 || findIndex === undefined) {
              return e
            }

            // 겹치는 경우 합침
            const mergeItems = {
              ...c3pli[findIndex],
              prods: [...c3pli[findIndex].prods, ...e.prods],
            }

            // c3pli에서 합쳐진 아이템 제거
            c3pli.splice(findIndex, 1)

            return mergeItems
          }),
          A.flatMap((item) => (item === undefined ? [] : [item])),
          // 남은 c3pli 아이템 추가
          A.concat(c3pli)
        )
      } else if (!usci?.length && c3pli?.length) {
        // c3payloadItems만 있는 경우
        return c3pli
      } else if (usci?.length && !c3pli?.length) {
        // updateSearchConfirmItems만 있는 경우
        return usci
      }

      return undefined
    },
    [updateItems]
  )

  useEffect(() => {
    const oacf = orderAddConfirmData()
    log.debug(
      '🚀 ~ file: order-add.tsx:328 ~ useEffect ~ searchConfirmItems:',
      searchConfirmItems
    )
    log.debug(
      '🚀 ~ file: order-add.tsx:329 ~ useEffect ~ updateItems:',
      updateItems
    )
    log.debug('🚀 ~ file: order-add.tsx:331 ~ useEffect ~ oacf:', oacf)
    if (oacf) {
      pipe(oacf as TOrderAddConfirm[], setOrderAddConfirmData)
    }
  }, [updateItems])

  const onClicknextMemo = () => {
    updateItems && updateItems.length > 0
      ? router(`${location.pathname}/calc`)
      : toast.error(__('추가할 수 있는 상품이 없어요'), {
          autoClose: 1000,
        })
  }

  return (
    <HydrateSectionAtom initialValues={readyItems}>
      <Header
        orderNo={String(orderNo)}
        onClickNext={onClicknextMemo}
        nextBtnDisabled={!updateItems?.length}
      />
      <div className="mt-[32px] mb-[70px] min-w-[992px] overflow-auto">
        <div className="w-[970px] mx-auto ">
          <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-[20px]">
            <div className="grid gap-y-[20px]">
              {pipe(
                readyItems,
                O.fromNullable,
                O.fold(
                  () => undefined,
                  flow(
                    A.mapWithIndex((index, readyItem) => (
                      <div key={`OrderSectionContainerOverlay-${index}`}>
                        <OrderSectionContainerOverlay
                          orderCode={orderCode}
                          id={readyItem.id}
                          label={readyItem.label}
                          items={readyItem.value}
                          statusCd={readyItem.statusCd}
                          memberCode={memberCode}
                          orderType={orderType}
                        />
                      </div>
                    ))
                  )
                )
              )}
            </div>
            <div>
              <OrderAddCalcCard />
            </div>
          </div>
        </div>
      </div>
    </HydrateSectionAtom>
  )
}

export default OrderAdd
