import { queryOptions } from '@tanstack/react-query'
import { getShopConfig } from '.'

export function shopConfigQueryOptions() {
  return queryOptions({
    queryKey: ['shop-config'],
    queryFn: getShopConfig,
    staleTime: 1000 * 60 * 5,
  })
}
