import { z } from 'zod'
import { __ } from '~/shared/i18n'

export const orderFormDataEditFormSchema = z.object({
  form: z
    .object({
      idx: z.number(),
      value: z.string().array(),
    })
    .array(),
})
