import {
  모델_주문섹션,
  스펙_주문섹션,
} from '~/entities/order-detail/model/order-section'
import { ISpecification } from 'spec-pattern-ts'
import { OrderSectionDto } from '~/entities/order-detail'

import {
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '~/entities/external-order/model/external-order-section'
import { TExternalOrderSectionDto } from '~/entities/external-order'

export function sortBySpecs(
  orderSectionList: OrderSectionDto[]
): OrderSectionDto[] {
  const specs: ISpecification<모델_주문섹션>[] = [
    스펙_주문섹션.상품준비,
    스펙_주문섹션.배송대기_송장등록전,
    스펙_주문섹션.배송대기_송장등록후,
    스펙_주문섹션.배송보류,
    스펙_주문섹션.배송중,
    스펙_주문섹션.배송완료,
    스펙_주문섹션.구매확정,
  ]

  return [...orderSectionList].sort((a, b) => {
    const aModel = new 모델_주문섹션(a)
    const bModel = new 모델_주문섹션(b)
    for (const spec of specs) {
      if (spec.isSatisfiedBy(aModel) && !spec.isSatisfiedBy(bModel)) {
        return -1
      }
      if (!spec.isSatisfiedBy(aModel) && spec.isSatisfiedBy(bModel)) {
        return 1
      }
    }
    return 0
  })
}

export function sortBySpecsExternal(
  orderSectionList: TExternalOrderSectionDto[]
): TExternalOrderSectionDto[] {
  const specs: ISpecification<모델_외부채널_주문섹션>[] = [
    스펙_외부채널_주문섹션.상품준비,
    스펙_외부채널_주문섹션.배송대기_송장등록전,
    스펙_외부채널_주문섹션.배송대기_송장등록후,
    스펙_외부채널_주문섹션.배송보류,
    스펙_외부채널_주문섹션.배송중,
    스펙_외부채널_주문섹션.배송완료,
    스펙_외부채널_주문섹션.구매확정,
  ]

  return [...orderSectionList].sort((a, b) => {
    const aModel = new 모델_외부채널_주문섹션(a)
    const bModel = new 모델_외부채널_주문섹션(b)
    for (const spec of specs) {
      if (spec.isSatisfiedBy(aModel) && !spec.isSatisfiedBy(bModel)) {
        return -1
      }
      if (!spec.isSatisfiedBy(aModel) && spec.isSatisfiedBy(bModel)) {
        return 1
      }
    }
    return 0
  })
}
