import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPatchResReturnCompleteDto,
  TPatchPayloadReturnCompleteDto,
} from './return-complete.type'
import { patchResReturnCompleteDto } from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/qudHnt
 * @description 주문 섹션 상태 변경 (return-request)
 */
export async function patchReturnComplete({
  body,
  orderCode,
  sectionCode,
}: {
  body: TPatchPayloadReturnCompleteDto
  orderCode: string
  sectionCode: string
}) {
  type T = TPatchResReturnCompleteDto
  type R = typeof body
  const result = await axios.patch<T, AxiosResponse<T>, R>(
    `/order/${orderCode}/sections/${sectionCode}/return-complete`,
    body
  )

  patchResReturnCompleteDto.parse(result.data)
  return result
}
