import { ApiClient, ApiResponse } from '~/shared/api'
import { CreateOrderMemoReq, PatchOrderMemoReq } from './order-timeline.type'

const axios = ApiClient()

export const patchOrderMemo = async ({
  orderCode,
  idx,
  params,
}: {
  orderCode: string
  idx: number
  params: PatchOrderMemoReq
}) => {
  const { data } = await axios.patch<ApiResponse<number>>(
    `/order/${orderCode}/memo/${idx}`,
    params
  )
  return data
}

export const deleteOrderMemo = async ({
  orderCode,
  idx,
}: {
  orderCode: string
  idx: number
}) => {
  const { data } = await axios.delete<ApiResponse<number>>(
    `/order/${orderCode}/memo/${idx}`
  )
  return data.data
}
