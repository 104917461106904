import { AxiosResponse } from 'axios'
import { ApiClient } from '~/shared/api'
import { type TPostResAmountDueDto, type TPostPayloadAmountDueDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 결제 예정 금액 조회 (관리자 주문 생성 시)
 */
export function postAmountDue() {
  return async function (body: TPostPayloadAmountDueDto) {
    type R = typeof body
    type T = TPostResAmountDueDto
    const result = await axios
      .post<T, AxiosResponse<ApiResponse<T>>, R>('/order/amount-due', body)
      .then((res) => res.data)

    return result
  }
}
