import { __ } from '~/shared/i18n'
import { Button, Flex, Textarea } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

interface Props {
  memo: string
  handleMemoChange: (memo: string) => void
  handleSubmit: () => void
  disabled: boolean
  loading: boolean
}

export function OrderTimelineFooter({
  memo: memoText,
  handleMemoChange,
  handleSubmit,
  disabled,
  loading,
}: Props) {
  return (
    <Flex.Column
      padding={`${vars.spacing[3]} ${vars.spacing[6]} ${vars.spacing[4]}`}
      boxShadow={vars.dropShadow.layer}
    >
      <Textarea
        value={memoText}
        onChange={handleMemoChange}
        characterCount
        maxLength={1000}
        height="min"
        placeholder={__('메모를 입력해 주세요.')}
        sx={{ marginBottom: vars.spacing[4] }}
      />
      <Button
        native={{ type: 'button' }}
        onClick={handleSubmit}
        isDisabled={disabled}
        isLoading={loading}
        variant="primary"
        size="small"
        text={__('메모 추가')}
        fullWidth
      />
    </Flex.Column>
  )
}
