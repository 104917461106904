/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'

/**
 * patch return-reason-edit
 */
export const patchResReturnReasonEditSchema = ApiResponseSchema
export const patchPayloadReturnReasonEditSchema = z.object({
  returnReason: z.string(),
  returnReasonDetail: z.string().min(1).max(100),
})
