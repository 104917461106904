import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type {
  TPostResBulkActionPaymentSchema,
  TPostPayloadBulkActionPaymentSchema,
} from './bulk-action-payment-validate.type'
import { postResBulkActionPaymentValidateSchema } from './bulk-action-payment-validate.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Ih1MyF
 * @description 주문 입금 완료 업데이트 대량 검증
 */
export async function postBulkActionPaymentValidate<
  T = TPostResBulkActionPaymentSchema,
  R = TPostPayloadBulkActionPaymentSchema,
>({ body }: { body: R }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/payment/validate', body)
    .then((res) => res.data)

  postResBulkActionPaymentValidateSchema.parse(result.data)
  return result.data
}
