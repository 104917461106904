import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPatchResCancelReasonEditSchema,
  TPatchPayloadCancelReasonEditSchema,
} from './cancel-reason-edit.type'
import { patchResCancelReasonEditSchema } from './cancel-reason-edit.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/NZUUnW
 * @description 취소접수 사유 수정
 */
export async function patchCancelReasonEdit({
  body,
  orderCode,
  sectionCode,
}: {
  body: TPatchPayloadCancelReasonEditSchema
  orderCode: string
  sectionCode: string
}) {
  type T = TPatchResCancelReasonEditSchema
  type R = typeof body
  const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
    `/order/${orderCode}/sections/${sectionCode}/cancel-reason`,
    body
  )

  return result
}
