/*
Author: @Hansanghyeon
Description: 취소접수페이지
Tags: 뷰, 폼
*/

import { pipe, flow } from 'fp-ts/function'
import { useQuery } from '@tanstack/react-query'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import {
  useOrderSectionAtom,
  OrderEditApplyItemSection,
  OrderSection,
  OrderEditCalcCard,
} from '~t'
import React, { useEffect } from 'react'
import { IconArrowLeft } from '@imwebme/icon'
import { Trans } from 'react-i18next'
import { useForm, FormProvider, type DefaultValues } from 'react-hook-form'
import { DevTool } from '~/app/provider/react-hook-form'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { resolver } from '../schema'
import type {
  TTargetSectionInformation,
  TReturnRequestUI,
} from '../order-retrun-info.type'
import { useCalc } from '~/container/order/order-edit/order-edit-fn'
import { Checkbox } from '@imwebme/clay-components'
import {
  ReturnReasonCdSelect,
  ReturnReturnReasonTextArea,
  ReturnCollectionInformation,
} from './order-return-info-from'
import {
  ReturnRequestModal,
  WithReturnRequestModal,
} from './order-return-info-from/return-request-modal'
import {
  OrderReturnInfoReturnRequestModalAtom,
  OrderReturnInfoReturnRequestModalWithRefundAtom,
  OrderReturnInfoReturnRequestWithRefundAtom,
} from './order-return-info.store'
import { goodsflowQueryOptions, goodsflowStatus } from '~/entities/goodsflow'
import { OrderEditHeader } from '~t/order-edit/order-edit-header'
import { OrderTimelinePack } from '../../order-timeline'
import { OrderHeaderStep } from '~t/order-edit/order-edit-header/partials/order-header-step'
import { __, useLL } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useCurrency } from '~/shared/hooks/use-currency'
import { Button } from '@imwebme/clay-components'
import { GoodsflowPartnersSelect } from '~/widgets/goodsflow-partners-select'
import { 상수_판매채널 } from '~/entities/@x'

function Header({ orderNo }: { orderNo: string }) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
          className="flex gap-x-[4px] items-center"
        >
          <IconArrowLeft className="stroke-[2]" />
          <span>{orderNo}</span>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('반품')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: '품목 선택',
              active: false,
            },
            {
              id: '2',
              label: '반품 정보 입력',
              active: true,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <>
          <Link
            to="/order/:saleChannel/:orderCode/return"
            params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
          >
            <Button as="div" variant="secondary" text={__('이전')} />
          </Link>
          <Button
            native={{ type: 'button' }}
            variant="primary"
            isDisabled
            text={__('다음')}
          />
        </>
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

const OrderReturnCalcCardFooter = () => {
  const updateitems = useOrderSectionAtom.valueUpdateAll()
  const [, setOrderReturnInfoReturnRequestWithRefundAtom] = useAtom(
    OrderReturnInfoReturnRequestWithRefundAtom
  )
  // 단일 섹션 아이템인 경우
  if (updateitems && updateitems.length === 1) {
    return (
      <div className="mx-[24px] mt-[20px]">
        <div className="px-[16px] py-[8px] bg-clay-semantic-surfaceAccent rounded-lg">
          <div className="flex gap-[8px]">
            <Checkbox
              id="terms1"
              className="mt-[2px]"
              onChangeChecked={(value: boolean) => {
                setOrderReturnInfoReturnRequestWithRefundAtom(value)
              }}
            />
            <label htmlFor="terms1" className="leading-none">
              <div className="semantic-p14">
                <Trans>환불도 같이 진행 할래요</Trans>
              </div>
              <div className="typo-clay-body-small text-clay-semantic-textSub mt-[2px]">
                <Trans>취소 접수와 환불을 한번에 할 수 있어요</Trans>
              </div>
            </label>
          </div>
        </div>
      </div>
    )
  }
  // 2개 이상 섹션의 아이템인 경우
  return null
}

const OrderReturnCalcCard = () => {
  const currency = useCurrency()
  return (
    // 단일 섹션 아이템인 경우
    // 2개 이상 섹션의 아이템인 경우
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price: useCalc(),
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b className="typo-clay-heading-xlarge-bold" /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="footer">
        <OrderReturnCalcCardFooter />
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}

export default function OrderReturnInfo({
  c3payloadItems,
}: {
  c3payloadItems: TTargetSectionInformation
}) {
  const orderCode = useOrderCode()
  const location = useLL()
  if (orderCode === undefined) {
    throw new Error('🚀 ~ file: order-return.tsx:66 ~ OrderReturn')
  }
  const { data: goodsflowList } = useQuery({
    ...goodsflowQueryOptions(),
    select: flow(
      (e) => e.data,
      A.filter((e) => e.status === goodsflowStatus.Y)
    ),
  })

  const formDefaultValues: DefaultValues<TReturnRequestUI> = React.useMemo(
    () => ({
      targetSectionInformation: c3payloadItems,
    }),
    []
  )

  const methods = useForm<TReturnRequestUI>({
    resolver,
    defaultValues: formDefaultValues,
  })
  const { handleSubmit, control, formState, register, getValues, setValue } =
    methods

  const updateitems = useOrderSectionAtom.valueUpdateAll()
  const setOrderRequestModal = useSetAtom(OrderReturnInfoReturnRequestModalAtom)
  const setOrderReturnInfoReturnRequestModalWithRefundAtom = useSetAtom(
    OrderReturnInfoReturnRequestModalWithRefundAtom
  )
  const OrderReturnInfoReturnRequestWithRefund = useAtomValue(
    OrderReturnInfoReturnRequestWithRefundAtom
  )

  useEffect(() => {
    const targetSectionInformation = getValues('targetSectionInformation')
    if (targetSectionInformation.length === 0) {
      setValue('targetSectionInformation', c3payloadItems)
    }
  }, [])

  const onSubmit = async (e: TReturnRequestUI) => {
    log.debug('🚀 ~ file: order-return-info.tsx:64 ~ onSubmit ~ e:', e)

    // 환불도 함께 진행할때 환불 모달을 띄우기
    if (OrderReturnInfoReturnRequestWithRefund) {
      setOrderReturnInfoReturnRequestModalWithRefundAtom(true)
    } else {
      setOrderRequestModal(true)
    }
  }

  const items = useOrderSectionAtom.valueUpdateAll()

  const baseItems = pipe(
    items,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.map((e) => e.value),
        A.flatten
      )
    )
  )

  const itemAddedItems = pipe(
    baseItems,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.filter((e) => e.qty === 0),
        A.map((e) => ({ ...e, qty: e.qtyChange }))
      )
    )
  )

  const countIncreasedItems = pipe(
    baseItems,
    O.fromNullable,
    O.fold(
      () => undefined,
      flow(
        A.filter((e) => e.qty !== 0 && (e.qtyChange || 0) > 0),
        A.map((e) => ({ ...e, qty: e.qtyChange }))
      )
    )
  )

  // items white guard
  if (!items) {
    return (
      <div>
        <Trans>변경된 아이템이 없다.</Trans>
      </div>
    )
  }
  if (!itemAddedItems) {
    return (
      <div>
        <Trans>추가된 아이템이 없다.</Trans>
      </div>
    )
  }
  if (!countIncreasedItems) {
    return (
      <div>
        <Trans>수량이 변경된 아이템이 없다.</Trans>
      </div>
    )
  }
  return (
    <>
      <Header orderNo={orderCode} />
      <div className="mt-[32px] mb-[70px] min-w-[992px] overflow-auto">
        <div className="w-[970px] mx-auto">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, log.debug)}>
              <input type="hidden" {...register('targetSectionInformation')} />
              <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-x-[20px] gap-y-[20px]">
                <div className="grid gap-y-[20px]">
                  <OrderSection name="반품정보입력">
                    <OrderSection.Slot name="title">
                      <Trans>반품 정보</Trans>
                    </OrderSection.Slot>
                    <OrderSection.Slot name="body">
                      <div className="grid grid-cols-[145px,1fr] gap-x-[20px] gap-y-[16px]">
                        <div className="semantic-h6-bold">
                          <Trans>품목</Trans>
                        </div>
                        <div>
                          {itemAddedItems.length > 0 && (
                            <OrderEditApplyItemSection
                              items={itemAddedItems}
                              className="grid gap-y-[20px]"
                            />
                          )}
                          {countIncreasedItems.length > 0 && (
                            <OrderEditApplyItemSection
                              items={countIncreasedItems}
                              className="grid gap-y-[20px]"
                            />
                          )}
                        </div>
                        <div className="semantic-h6-bold">
                          {__('수거 정보')}
                        </div>
                        <div className="grid gap-y-[12px]">
                          <div>
                            <ReturnCollectionInformation
                              {...{ goodsflowList }}
                            />
                          </div>
                        </div>
                        {/* 굿스플로 row를 옵셔널하게 보여줘야하기 때문에 row돔구조를 아래컴포넌트 내부에서 렌더링한다. */}
                        <GoodsflowPartnersSelect />
                        <div className="semantic-h6-bold">
                          <Trans>사유</Trans>
                        </div>
                        <div className="grid gap-y-[12px]">
                          <div>
                            <ReturnReasonCdSelect />
                          </div>
                          <div>
                            <ReturnReturnReasonTextArea />
                          </div>
                        </div>
                      </div>
                    </OrderSection.Slot>
                  </OrderSection>
                </div>
                <div>
                  <div className="flex flex-col gap-[16px]">
                    <OrderReturnCalcCard />
                    <Button
                      native={{ type: 'submit' }}
                      variant="primary"
                      isDisabled={!formState.errors}
                      fullWidth
                      size="large"
                      text={
                        OrderReturnInfoReturnRequestWithRefund
                          ? __('반품 접수 후 환불')
                          : __('반품 접수')
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
            <ReturnRequestModal />
            {updateitems && <WithReturnRequestModal />}
          </FormProvider>
        </div>
      </div>
      <DevTool control={control} />
    </>
  )
}
