import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { useModals } from '~/router'
import { TReturnRejectRequestModalBase } from '.'
import { Modal } from '@imwebme/clay-components'

const ReturnRejectRequestModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ReturnRejectRequestModal),
  }))
)

interface TReturnRejectRequestModalPack extends TReturnRejectRequestModalBase {}
export function ReturnRejectRequestModalPack(
  props: TReturnRejectRequestModalPack
) {
  const modals = useModals()
  const handleModalClose = () => modals.close()

  return (
    <Modal isOpen setClose={handleModalClose} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense fallback={<Loading />}>
          <ReturnRejectRequestModalContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
