import { match, P } from 'ts-pattern'
import { Button, Typography } from '@imwebme/clay-components'
import { ArrowLeft, Monitor, Phone, Printer } from '@imwebme/clay-icons'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { 상수_주문상태, 상수_판매채널 } from '~/entities/@x'
import { externalOrderQueryOptions } from '~/entities/external-order'
import {
  모델_외부채널_주문서,
  스펙_외부채널_주문서,
} from '~/entities/external-order/model/external-order'
import { useOrderDetailHeaderGoBack } from '~/container/order/order-detail/partials/order-detail-header/order-detail-header.store'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __, __e, __g } from '~/shared/i18n'
import { Link, LocalLink } from '~/shared/components/link'
import { cn, fDate } from '~/shared/utils'
import { OrderTimelinePack } from '~/container/order/order-timeline'
import IconNpay from './npay.svg?react'
import IconTalkcheckout from './talkcheckout.svg?react'
import { Trans } from 'react-i18next'
import { Tooltip } from '~/shared/components/ui/tooltip'
import {
  모델_외부채널_페이먼트_정보,
  스펙_외부채널_결제정보,
} from '~/entities/external-order/model/external-order-payment-info'
import { useQueryClient } from '@tanstack/react-query'
import { postOrderOpen } from '~/entities/order/order-open'
import { IconButton, Tag } from '@imwebme/clay-components'
import {
  OrderStatusTag,
  PaymentStatusTag,
  SectionStatusTag,
} from '~/shared/domain-components'
import { 스펙_외부채널_주문섹션 } from '~/entities/external-order/model/external-order-section'
import { postOrderClose } from '~/entities/order/order-close'
import { toast } from 'react-toastify'
import { orderDetailOptions } from '~/entities/order-detail'

export function ExternalOrderDetailHeader() {
  const goBack = useOrderDetailHeaderGoBack.get()
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))
  const $order = new 모델_외부채널_주문서(data)
  const $paymentInfo = new 모델_외부채널_페이먼트_정보(data)

  const queryClient = useQueryClient()
  // 거래 재개
  const { mutate: orderStatusOpen } = useMutation({
    mutationFn: postOrderOpen({ orderCode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
      })
    },
  })

  const isClosed =
    (스펙_외부채널_결제정보.첫결제입금전취소.isSatisfiedBy($paymentInfo) ||
      스펙_외부채널_결제정보.첫결제결제기한초과.isSatisfiedBy($paymentInfo)) &&
    스펙_외부채널_주문서.주문섹션전체취소.isSatisfiedBy($order)

  function printOrder() {
    window.parent.postMessage(
      {
        source: 'print_order',
        message: {
          orderCode: [orderCode],
        },
      },
      '*'
    )
  }

  return (
    <div className="grid grid-cols-[auto,1fr,auto] gap-x-clay-4 py-clay-8 items-start">
      <div className="pl-clay-2 py-clay-25">
        {goBack ? (
          <LocalLink to={goBack}>
            <ArrowLeft className="text-clay-semantic-textSub" />
          </LocalLink>
        ) : (
          <Link to="/orders">
            <ArrowLeft className="text-clay-semantic-textSub" />
          </Link>
        )}
      </div>

      <div className="py-clay-05">
        <div className="flex gap-x-clay-3">
          <Typography variant="heading-3xlarge-bold">{data.orderNo}</Typography>
          <div className="flex gap-x-[6px] items-center">
            {data.badge && (
              <>
                <SectionStatusTag
                  statusCd={data.badge.deliveryStatus}
                  paymentStatusCd={data.badge.paymentStatus}
                />
                <PaymentStatusTag statusCd={data.badge.paymentStatus} />
                {data.statusCd === 상수_주문상태.거래종료 && (
                  <OrderStatusTag statusCd={data.badge.orderStatus} />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex gap-x-clay-2 items-center mt-clay-2">
          <div className="flex items-center gap-x-clay-15">
            {match(data.saleChannelIdx)
              .with(상수_판매채널.네이버페이, () => <IconNpay />)
              .with(상수_판매채널.톡체크아웃, () => <IconTalkcheckout />)
              .otherwise(() => null)}
            <Typography variant="label-medium" colorToken="textSub" as="div">
              {data.saleChannelItemList[0].channelOrderNo}
            </Typography>
          </div>
          <div className="w-px h-[14px] bg-clay-semantic-divide" />
          <div className="text-clay-semantic-textSub text-[12px] leading-[16px]">
            {fDate(new Date(data.wtime), 'yyyy년 MM월 dd일 HH:mm')}
          </div>
          <div className="text-clay-semantic-textSub text-[14px] leading-[16px]">
            {스펙_외부채널_주문서.PC.isSatisfiedBy($order) ? (
              <Monitor />
            ) : (
              <Phone className="stroke-[2]" />
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="flex gap-x-[12px] items-center">
          <div className="flex gap-x-[8px]">
            <OrderTimelinePack />
            <IconButton
              native={{ type: 'button' }}
              icon={<Printer />}
              size="medium"
              variant="secondary"
              onClick={printOrder}
            />
          </div>
          {match<boolean>(true)
            .with(
              스펙_외부채널_주문서.거래종료여부.isSatisfiedBy($order),
              () => (
                <Tooltip.Provider delayDuration={300}>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <Button
                        isDisabled={isClosed}
                        size="medium"
                        variant="secondary"
                        text={__('거래 재개')}
                        onClick={() => orderStatusOpen()}
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-[8px] py-[6px] rounded-[8px] typo-clay-label-small text-clay-semantic-text bg-clay-semantic-surface max-w-[177px]"
                        sideOffset={8}
                        side="bottom"
                      >
                        {isClosed
                          ? __(
                              '결제기한초과, 입금 전 취소 주문은 거래재개를 할 수 없어요'
                            )
                          : __('종료된 주문을 재개해요')}
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              )
            )
            .otherwise(() => (
              <div className="grid grid-cols-[repeat(4,auto)] gap-x-clay-2 items-center">
                <OredrAddButton />
                <OrderCancelButton />
                <OrderReturnButton />
                <OrderCloseButton />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

function DisabledButton({
  text,
  content,
  align,
}: {
  text: string
  content: React.ReactNode
  align?: 'start' | 'center' | 'end'
}) {
  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div>
            <Button
              isDisabled
              tonal
              size="medium"
              variant="primary"
              text={text}
            />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="px-clay-2 py-clay-15 rounded-clay-medium bg-clay-semantic-surface max-w-[177px]"
            sideOffset={8}
            side="bottom"
            align={align ?? 'center'}
          >
            <Typography as="div" variant="label-small">
              {content}
            </Typography>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

function OredrAddButton() {
  return (
    <DisabledButton
      text={__('추가')}
      content={
        <Trans>
          외부 연동 주문은 상품 추가 기능을
          <br />
          지원하지 않아요
        </Trans>
      }
    />
  )
}

function OrderCancelButton() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))
  const $exOrder = new 모델_외부채널_주문서(data)

  return match<boolean>(true)
    .with(
      스펙_외부채널_주문서.주문서
        .and(스펙_외부채널_주문서.일반상품취소가능여부)
        .or(스펙_외부채널_주문서.디지털그룹패스취소가능여부)
        .isSatisfiedBy($exOrder),
      () => (
        <Link
          to="/order/:saleChannel/:orderCode/cancel/select"
          params={{ orderCode, saleChannel: String(data.saleChannelIdx) }}
        >
          <Button
            as="div"
            isDisabled
            size="medium"
            variant="secondary"
            text={__('취소')}
          />
        </Link>
      )
    )
    .otherwise(() => (
      <DisabledButton
        text={__('취소')}
        content={__('취소 가능한 상품이 없어요')}
      />
    ))
}

function OrderReturnButton() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))
  const $exOrder = new 모델_외부채널_주문서(data)

  return match<boolean>(true)
    .with(
      스펙_외부채널_주문서.주문서
        .and(스펙_외부채널_주문서.디지털그룹패스반품가능여부)
        .or(스펙_외부채널_주문서.일반상품반품가능여부)
        .isSatisfiedBy($exOrder),
      () => (
        <Link
          to="/order/:saleChannel/:orderCode/return/select"
          params={{ orderCode, saleChannel: String(data.saleChannelIdx) }}
        >
          <Button
            as="div"
            size="medium"
            variant="secondary"
            text={__('반품')}
          />
        </Link>
      )
    )
    .otherwise(() => (
      <DisabledButton
        text={__('반품')}
        content={__('반품 가능한 상품이 없어요')}
        align="end"
      />
    ))
}

function OrderCloseButton() {
  const queryClient = useQueryClient()
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))
  const $exOrder = new 모델_외부채널_주문서(data)
  const show = 스펙_외부채널_주문서
    .외부채널주문섹션스펙모두보유([
      스펙_외부채널_주문섹션.구매확정,
      스펙_외부채널_주문섹션.취소완료,
      스펙_외부채널_주문섹션.반품완료,
    ])
    .isSatisfiedBy($exOrder)

  const { mutate, isPending } = useMutation({
    mutationFn: postOrderClose({ orderCode }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: externalOrderQueryOptions({ orderCode }).queryKey,
      })
    },
    onError(error) {
      toast.error(__e(error.response?.data.code || ''))
      queryClient.invalidateQueries({
        queryKey: externalOrderQueryOptions({ orderCode }).queryKey,
      })
    },
  })

  return show ? (
    <Button
      variant="primary"
      tonal
      text={__('거래종료')}
      isLoading={isPending}
      onClick={() => mutate({})}
    />
  ) : null
}
