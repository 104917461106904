import React from 'react'
import { useDeliveryViewMode, setDeliveryViewMode } from './delivery.store'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { 상수_배송지보기모드, T상수_배송지보기모드 } from './delivery.constants'
import {
  OptionList,
  OptionItem,
  Tooltip,
  Typography,
  Flex,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { ChevronSelectorVertical } from '@imwebme/clay-icons'
import * as Popover from '@radix-ui/react-popover'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'

export function DeliveryTableHeader() {
  const tabCode = useTabCode()
  const viewMode = useDeliveryViewMode(tabCode)
  const [open, setOpen] = React.useState(false)
  const [tooltipPos, setTooltipPos] = React.useState({ top: 0, left: 0 })

  // 툴팁 위치를 수동으로 계산하여 툴팁을 fixed로 고정함
  // overflow-auto 속성을 가진 주문목록 테이블로 인해 툴팁이 잘리는 이슈를 해결함
  const handleMounseEnter = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      const targetRect = evt.currentTarget.getBoundingClientRect()

      setTooltipPos({
        top: targetRect.top - 10,
        left: targetRect.left + targetRect.width / 2,
      })
    },
    []
  )

  const imTrigger = React.useMemo(
    () => (
      <Flex.Center
        gap={vars.spacing[1]}
        padding={vars.spacing[2]}
        borderRadius={vars.rounded.medium}
        _hover={{
          background: vars.semantic.color.actionSecondaryHover,
        }}
      >
        {`${__('배송지')}: ${getLabelBy(viewMode)}`}
        <ChevronSelectorVertical colorToken="icon-sub" />
      </Flex.Center>
    ),
    [viewMode]
  )

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger
        aria-label="set-delivery-view-mode"
        onMouseEnter={handleMounseEnter}
      >
        {match(viewMode)
          .with(상수_배송지보기모드.상세보기, () => (
            <Tooltip>
              <Tooltip.Trigger as="div">{imTrigger}</Tooltip.Trigger>
              <Tooltip.Content
                position="top"
                hasArrow
                sx={{
                  position: 'fixed',
                  top: `${tooltipPos.top}px`,
                  left: `${tooltipPos.left}px`,
                  width: 'max-content',
                  padding: `${vars.spacing[15]} ${vars.spacing[2]}`,
                }}
              >
                <Typography variant="label-small" colorToken="text">
                  {__('상세 보기에서는 최초 배송지만 제공돼요')}
                </Typography>
              </Tooltip.Content>
            </Tooltip>
          ))
          .otherwise(() => imTrigger)}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={8}
          align="start"
          aria-label="menu"
          className="w-[148px]"
        >
          <OptionList popOver value={viewMode}>
            {Object.values(상수_배송지보기모드).map((_viewMode) => (
              <OptionItem
                key={_viewMode}
                text={getLabelBy(_viewMode)}
                value={_viewMode}
                onClick={() => {
                  setDeliveryViewMode(tabCode, _viewMode)
                  setOpen(false)
                }}
              />
            ))}
          </OptionList>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export function getLabelBy(viewMode: T상수_배송지보기모드) {
  return match(viewMode)
    .with(상수_배송지보기모드.요약보기, () => __('요약 보기'))
    .with(상수_배송지보기모드.상세보기, () => __('상세 보기'))
    .exhaustive()
}
