import React from 'react'
import { ContentsBanner } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'

const OrderEditAddCalcDiscountWarningBanner = () => (
  <div>
    <ContentsBanner
      variant="warning"
      type="secondary"
      text={__('수량 추가 상품에는 중복 할인이 적용되지 않아요.')}
    />
  </div>
)

export { OrderEditAddCalcDiscountWarningBanner }
