import React from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import {
  getMembers,
  TGetPayloadMembersDto,
  TGetResMembersItemDto,
} from '~/entities/site/members'
import { useModals } from '~/router'
import {
  Clay,
  Flex,
  Modal,
  Spinner,
  Typography,
} from '@imwebme/clay-components'
import { SafeAvatar } from '~/shared/components/safe-avatar'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { SearchBar } from './검색바/prod-search-bar'
import { Virtuoso } from 'react-virtuoso'
import { match } from 'ts-pattern'
import { EmptyMember } from './회원없음/empty-member'
import { useModalParams } from '~/shared/hooks/use-modal-params'
import { useMemberSelectModalAtom } from './member-select-modal.store'

export function MemberSelectModalContainer() {
  const [memberSelectModal] = useMemberSelectModalAtom()
  const [keyword, setKeyword] = React.useState('')
  const deferredKeyword = React.useDeferredValue(keyword)
  const modals = useModals()
  const unitCode = useModalParams()?.unitCode as string

  const queryParams: TGetPayloadMembersDto = {
    ...(deferredKeyword && { keyword: deferredKeyword }),
    direction: 'next',
    count: 50,
  }

  const { data, fetchNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['members', unitCode, queryParams],
      queryFn: ({ pageParam }) =>
        getMembers({
          ...queryParams,
          cursor: pageParam || undefined,
        }),
      initialPageParam: '',
      getNextPageParam: (lastPage) =>
        lastPage.count < 50 ? undefined : lastPage.cursorForNext,
      select: (d) => d.pages.flatMap((page) => page.list),
    })

  function handleSelectMember(member: TGetResMembersItemDto) {
    memberSelectModal.handleSelectMember(member)
    modals.close()
  }

  return (
    <Modal isOpen={true} setClose={modals.close} width="small">
      <Modal.Header text={__('회원 목록')} closeButton />

      {/* 검색바 */}
      <SearchBar setKeyword={setKeyword} />

      {/* 회원 목록 */}
      {match({ isLoading, isEmpty: data?.length === 0 })
        .with({ isLoading: true }, () => (
          <Flex.Center height="480px" paddingBottom={vars.spacing[6]}>
            <Flex.Center
              paddingBottom={vars.spacing[7]}
              flexDirection="column"
              rowGap={vars.spacing[5]}
            >
              <Spinner size="medium" />
              <Typography variant="body-medium">
                {__('회원 정보를 불러오고 있어요')}
              </Typography>
            </Flex.Center>
          </Flex.Center>
        ))
        .with({ isEmpty: true }, () => <EmptyMember />)
        .otherwise(() => (
          <Virtuoso
            style={{
              height: '480px',
            }}
            data={data}
            endReached={() => fetchNextPage()}
            itemContent={(_index, member) => (
              <MemberItem
                member={member}
                selected={memberSelectModal.curMemberCode === member.code}
                selectMember={() => handleSelectMember(member)}
              />
            )}
            components={{
              Footer: () => (
                <Flex.Center minHeight={vars.spacing[6]}>
                  {isFetchingNextPage ? <Spinner size="small" /> : null}
                </Flex.Center>
              ),
            }}
          />
        ))}
    </Modal>
  )
}

function MemberItem({
  member,
  selected,
  selectMember,
  ...props
}: {
  member: TGetResMembersItemDto
  selected: boolean
  selectMember: () => void
}) {
  return (
    <Clay
      marginTop={vars.spacing[2]}
      paddingInline={vars.spacing[6]}
      {...props}
    >
      <Flex
        as="button"
        width="100%"
        borderRadius={vars.rounded.medium}
        padding={`${vars.spacing[1]} ${vars.spacing[2]}`}
        alignItems="center"
        columnGap={vars.spacing[3]}
        _hover={{
          background: selected
            ? undefined
            : vars.semantic.color.actionSecondaryHover,
        }}
        background={
          selected ? vars.semantic.color.actionPrimaryTonal : undefined
        }
        native={{ onClick: selectMember }}
      >
        <SafeAvatar size="small" src={member.profileImgUrl ?? undefined} />
        <Flex.Column rowGap={vars.spacing['05']}>
          <Typography
            variant="label-medium-bold"
            sx={{ wordBreak: 'break-all' }}
          >
            {member.account}
          </Typography>
          {(member.name || member.callnum) && (
            <Typography variant="body-small" sx={{ wordBreak: 'break-all' }}>
              {member.name}
              {member.name && member.callnum && '·'}
              {member.callnum}
            </Typography>
          )}
        </Flex.Column>
      </Flex>
    </Clay>
  )
}
