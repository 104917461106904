import { ApiClient, type ApiResponse } from '~/shared/api'
import type { TReturnRequest } from '../order-return-info/order-retrun-info.type'

const axios = ApiClient()

export type getOrderTabListReturn = Promise<
  ApiResponse<
    {
      isPartial: boolean
      beforeOrderSectionNo: string
      afterOrderSectionNo: string
      beforeOrderSectionCode: string
      afterOrderSectionCode: string
      afterOrderSectionItems: [orderSectionItemCode: string, qty: number]
    }[]
  >
>
export const patchOrderReturnRequest = async ({
  orderCode,
  data,
}: {
  orderCode: string
  data: TReturnRequest
}): getOrderTabListReturn => {
  const result = await axios
    .patch(`/order/${orderCode}/sections/return-request`, {
      ...data,
    })
    .then((res) => res.data)
  return result
}
