import React from 'react'
import { SetStateAction, atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomFamily, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomKey = {
  orderCode: string
  sectionCode: string
}
type AtomType = {
  key: string
  value?: {
    orderSectionItemCode: string
    qty: number
  }[]
}
const OrderSectionCancelCalcState = atomFamily(
  ({ key, value = [] }: AtomType) => {
    const rat = atomWithReset<AtomType>({
      key,
      value,
    })

    const at = atom(
      (get) => get(rat).value,
      (_get, set, update: SetStateAction<AtomType['value']> | typeof RESET) => {
        if (update === RESET) {
          set(rat, RESET)
          return
        }
        if (typeof update === 'function') {
          set(rat, (prev) => {
            const v = update(prev.value)
            return { key, value: v }
          })
          return
        }
        set(rat, { key, value: update })
      }
    )
    at.debugLabel = `${key}:OrderSectionCancelCalc`
    return at
  },
  (a, b) => a.key === b.key
)

export const useOrderSectionCancelCalcAtom = {
  key: (p: AtomKey) => `${p.orderCode}-${p.sectionCode}:OrderSectionCancelCalc`,
  state: (p: AtomKey) =>
    useAtom(
      React.useMemo(
        () =>
          OrderSectionCancelCalcState({
            key: useOrderSectionCancelCalcAtom.key(p),
          }),
        [p]
      )
    ),
  get: (p: AtomKey) =>
    useAtomValue(
      React.useMemo(
        () =>
          OrderSectionCancelCalcState({
            key: useOrderSectionCancelCalcAtom.key(p),
          }),
        [p]
      )
    ),
  set: (p: AtomKey) =>
    useSetAtom(
      React.useMemo(
        () =>
          OrderSectionCancelCalcState({
            key: useOrderSectionCancelCalcAtom.key(p),
          }),
        [p]
      )
    ),
  reset: (p: AtomKey) =>
    useResetAtom(
      React.useMemo(
        () =>
          OrderSectionCancelCalcState({
            key: useOrderSectionCancelCalcAtom.key(p),
          }),
        [p]
      )
    ),
}
