import { z } from 'zod'
import {
  상수_결제상태,
  상수_현금영수증_수단,
  상수_현금영수증_상태,
  상수_현금영수증_타입,
  상수_결제타입,
  상수_판매채널,
  상수_주문상태,
  상수_주문타입,
  YN,
  상수_추가정보타입,
} from '~/entities/@x'
import {
  cashReceiptInfoData,
  orderBadgeResponseDto,
  selectPaymentForOrderDto,
  orderSectionDto,
  deliveryDto,
} from '~/entities/@dto'

export const externalChannelOrderInfoSchema = z.object({
  orderNo: z.string(),
  //status: z.nativeEnum(상수_외부채널주문_주문상태),
})

const paymentBankTransferEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  bankName: z.string().describe('은행명'),
  bankAccount: z.string().describe('은행 계좌번호'),
  accountHolderName: z.string().describe('예금주명'),
  expireTime: z.string().nullish().describe('입금 만료 기간'),
  memberCode: z.string().nullish().describe('주문고객코드'),
  depositTime: z.string().nullish().describe('입금 완료 일시'),
  depositor: z.string().describe('입금자명'),
  wtime: z.string().nullish().describe('등록일시'),
})

const paymentVirtualEntitySchema = z.object({
  siteCode: z.string().describe('사이트 코드'),
  paymentCode: z.string().describe('결제 코드'),
  bankName: z.string().describe('은행명'),
  bankAccount: z.string().describe('은행 계좌번호'),
  accountHolderName: z.string().describe('예금주명'),
  expireTime: z.string().describe('입금 만료 기간'),
  depositTime: z.string().nullish().describe('입금 완료 일시'),
  depositor: z.string().describe('입금자명'),
  wtime: z.string().nullish().describe('등록일시'),
})

const paymentCashReceiptEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  typeCd: z.nativeEnum(상수_현금영수증_타입),
  statusCd: z.nativeEnum(상수_현금영수증_상태),
  value: z.string(),
  completeTime: z.string().nullish(),
  memberCode: z.string().nullish(),
  methodTypeCd: z.nativeEnum(상수_현금영수증_수단).nullish(),
  isRequire: z.nativeEnum(YN),
  isCustomer: z.nativeEnum(YN),
  issueNumber: z.string().nullish(),
  wtime: z.string(),
  mtime: z.string(),
})

export const orderFormDataEntitySchema = z.object({
  idx: z.number(),
  siteCode: z.string(),
  orderCode: z.string(),
  formConfigCode: z.string(),
  inputTypeCd: z.nativeEnum(상수_추가정보타입),
  title: z.string(),
  description: z.string(),
  value: z.string(),
  formConfigValue: z.string(),
  wtime: z.string(),
  isRequire: z.nativeEnum(YN),
})

const paymentEasyEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  bodyData: z.any().nullish().describe('JSON(PG사에서 얻은 간편결제 정보)'),
  wtime: z.string().describe('등록일시'),
})

const paymentTransferEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  bankName: z.string(),
  bankAccount: z.string(),
  accountHolderName: z.string(),
  depositor: z.string(),
  wtime: z.string().nullish(),
})

const paymentCardEntitySchema = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  companyCd: z.string().describe('카드사 코드'),
  cardNo: z.string().describe('카드번호'),
  installment: z.number().describe('할부개월 수 (0이면 일시불)'),
  wtime: z.string().nullish().describe('등록일시'),
})

const orderEntitySchema = z.object({
  siteCode: z.string(),
  unitCode: z.string(),
  orderCode: z.string(),
  saleChannelIdx: z.nativeEnum(상수_판매채널),
  saleChannelName: z.string(),
  isMember: z.nativeEnum(YN),
  isSubscription: z.nativeEnum(YN),
  orderNo: z.number(),
  orderTypeCd: z.nativeEnum(상수_주문타입),
  statusCd: z.nativeEnum(상수_주문상태),
  currency: z.string(),
  paymentPrice: z.number(),
  refundPoint: z.number(),
  refundPendingPoint: z.number(),
  baseItemPrice: z.number(),
  itemPrice: z.number(),
  deliveryPrice: z.number(),
  deliveryExtraPrice: z.number(),
  deliveryIslandPrice: z.number(),
  deliveryCouponDiscount: z.number(),
  itemPointAmount: z.number(),
  deliveryPointAmount: z.number(),
  itemCouponDiscount: z.number(),
  gradeDiscount: z.number(),
  taxFreePrice: z.number(),
  pgAmount: z.number(),
  pgCancelPrice: z.number(),
  refundPendingPrice: z.number(),
  refundPendingTaxFreePrice: z.number(),
  etcRefundPrice: z.number(),
  givePoint: z.number(),
  memberCode: z.string(),
  ordererEmail: z.string().nullish(),
  ordererCall: z.string(),
  ordererName: z.string(),
  isCancelReq: z.nativeEnum(YN),
  unipassNumber: z.string().nullish(),
  deviceCd: z.string(),
  wtime: z.string(),
  isDel: z.nativeEnum(YN),
  mtime: z.string(),
  remainPrice: z.number().nullish(),
  groupPassCode: z.string().nullish(),
  groupPassDayCnt: z.number().nullish(),
  isGift: z.nativeEnum(YN),
  isRequestPayment: z.nativeEnum(YN),
  externalChannelOrderInfo: externalChannelOrderInfoSchema.nullish(),
})

const orderFormDataFileDownloadUrlDto = z.object({
  url: z.string(),
  objectSignedUrl: z.string(),
  fileName: z.string(),
})

export const orderFormDataDtoSchema = orderFormDataEntitySchema
  .omit({
    formConfigValue: true,
    value: true,
  })
  .extend({
    formConfigValue: z.array(z.string()),
    value: z.array(z.string()),
    fileDownloadUrl: orderFormDataFileDownloadUrlDto.nullish(),
  })

export const orderCouponDtoSchema = z.object({
  couponCode: z.string().nullish(),
  couponName: z.string().nullish(),
  couponIssueCode: z.string().nullish(),
})

const orderPaymentTotalPriceInfoDataSchema = z.object({
  totalPrice: z.number().describe('총 주문 금액'),
  totalDeliveryPrice: z.number().describe('총 배송비'),
  totalDiscountPrice: z.number().describe('총 주문 할인 금액'),
  totalRefundPrice: z.number().describe('총 환불 금액'),
  totalPoint: z.number().describe('총 사용 적립금'),
  type: z.nativeEnum(상수_결제타입).describe('결제 금액 상태 타입 정보'),
  amount: z.number().describe('결제 금액 상태 타입 별 금액'),
  bankTransfer: paymentBankTransferEntitySchema
    .nullish()
    .describe('무통장 정보(입금대기 현금영수증 결제수단일 경우에만 전달)'),
  virtual: paymentVirtualEntitySchema
    .nullish()
    .describe('가상계좌 정보(입금대기 가상계좌 결제수단일 경우에만 전달)'),
  isShopUseAutoCancel: z.boolean().describe('자동취소 설정 여부'),
  pgPaymentPrice: z.number().describe('결제 잔액'),
})

export const paymentCashReceiptDataSchema = paymentCashReceiptEntitySchema
  .omit({
    value: true,
  })
  .extend({
    value: z.string(),
    supplyPrice: z.number().nullish(),
    vatPrice: z.number().nullish(),
    isSupportedPgCashReceipt: z.boolean().nullish(),
  })

const paymentEasyDataSchema = paymentEasyEntitySchema.extend({})

const paymentBankTransferDataSchema = paymentBankTransferEntitySchema.extend({})

const paymentCardDataSchema = paymentCardEntitySchema.extend({})

const paymentVirtualDataSchema = paymentVirtualEntitySchema.extend({})

const paymentTransferDataSchema = paymentTransferEntitySchema.extend({})

export const paymentWithMethodDetailDataSchema =
  selectPaymentForOrderDto.extend({
    cashReceipt: paymentCashReceiptDataSchema
      .nullish()
      .describe('결제 현금영수증 정보'),
    easy: paymentEasyDataSchema.nullish().describe('간편결제 정보'),
    bankTransfer: paymentBankTransferDataSchema
      .nullish()
      .describe('무통장입금 결제 정보'),
    card: paymentCardDataSchema.nullish().describe('신용카드 결제 정보'),
    virtual: paymentVirtualDataSchema.nullish().describe('가상계좌 결제 정보'),
    transfer: paymentTransferDataSchema
      .nullish()
      .describe('실시간계좌이체 결제 정보'),
  })

export const paymentByPaymentNoDataSchema = z.object({
  paymentNo: z.string(),
  complexTax: z.nativeEnum(YN),
  statusCd: z.nativeEnum(상수_결제상태),
  paidPrice: z.number().nullish(),
  taxFreePrice: z.number().nullish(),
  data: z.array(paymentWithMethodDetailDataSchema),
})

export const orderPaymentDataSchema =
  orderPaymentTotalPriceInfoDataSchema.extend({
    cashReceiptInfo: cashReceiptInfoData.nullish(),
    paymentList: z.array(paymentByPaymentNoDataSchema).nullish(),
  })

const orderDataSchema = orderEntitySchema.extend({
  orderSectionList: z.array(orderSectionDto).nullish(),
  paymentInfo: orderPaymentDataSchema,
  orderDeliveryList: z.array(deliveryDto).nullish(),
  orderFormDataList: z.array(orderFormDataDtoSchema).nullish(),
  orderCouponList: z.array(orderCouponDtoSchema).nullish(),
  cashOnDeliveryPrice: z.number().describe('착불 배송비'),
})

/**
 * 단일 주문 조회
 */

export const orderDetailResponseSchema = orderDataSchema.extend({
  prodName: z.string(),
  badge: orderBadgeResponseDto.nullish(),
})
