import { IconCornerDownRight, IconHelpCircle } from '@imwebme/icon'
import { Tooltip } from '~/shared/components/ui/tooltip'
import { useMemo } from 'react'
import { Trans, __ } from '~/shared/i18n'

export interface OrderEditAddCalcAmountCardProps {
  itemPrice: number
  levelDiscountPrice: number
  couponDiscountPrice: number
  reservePrice: number
  deliveryFee: number
  currency: string
  deliveryFeeMethod: 'ADD' | 'SUB'
}

export function OrderEditAddCalcAmountCard({
  itemPrice,
  levelDiscountPrice,
  couponDiscountPrice,
  reservePrice,
  currency,
  deliveryFee,
  deliveryFeeMethod,
}: OrderEditAddCalcAmountCardProps) {
  const discountInfo = [
    { title: __('상품가'), price: itemPrice },
    {
      title: __('등급할인'),
      price: levelDiscountPrice === 0 ? 0 : -levelDiscountPrice,
    },
    {
      title: __('쿠폰할인'),
      price: couponDiscountPrice === 0 ? 0 : -couponDiscountPrice,
    },
    {
      title: __('적립금 사용'),
      price: reservePrice === 0 ? 0 : -reservePrice,
    },
  ]
  const totalItemPrice =
    itemPrice -
    levelDiscountPrice -
    couponDiscountPrice -
    reservePrice +
    deliveryFee * (deliveryFeeMethod === 'ADD' ? 1 : -1)

  return (
    <div className="rounded-[12px] bg-white grid pt-[20px] [&>*]:mx-[24px] py-[24px] space-y-[24px]">
      <div>
        <div className="text-clay-semantic-text semantic-h4-bold">
          {totalItemPrice >= 0 ? __('요청 금액') : __('환불 금액')}
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <div className="flex gap-[4px] items-center text-clay-semantic-textSub typo-clay-label-medium">
            <div>{__('금액')}</div>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div>
                    <IconHelpCircle className="stroke-[2] w-[16px] h-[16px]" />
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Content>{__('문구')}</Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
          <div className="flex items-center gap-[4px] typo-clay-heading-xlarge-bold">
            {__('{{price, 3comma}} {{currency, currency}}', {
              price: totalItemPrice,
              formatParams: {
                currency: {
                  currency,
                },
              },
            })}
          </div>
        </div>
      </div>
      <div>
        <div className="m-[20px 24px 24px 24px] p-[16px] bg-clay-semantic-surfaceSub flex flex-col items-start self-stretch rounded-[8px]">
          <div className="flex flex-col gap-[8px] w-full">
            <div className="flex justify-between w-full  text-[14px] leading-[24px]">
              <div>{__('상품금액')}</div>
              <div>
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price:
                    itemPrice -
                    levelDiscountPrice -
                    couponDiscountPrice -
                    reservePrice,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
            </div>
            {discountInfo.map((item) => (
              <div
                className="flex justify-between w-full text-clay-semantic-textSub"
                key={item.title}
              >
                <div className="flex items-start gap-[4px] text-[14px] leading-[20px]">
                  <IconCornerDownRight className="text-clay-semantic-iconDisabled" />
                  <div>{item.title}</div>
                </div>
                <div className="text-[14px] leading-[20px]">
                  {__('{{price, 3comma}} {{currency, currency}}', {
                    price: item.price,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="h-[16px]" />
          <div className="flex justify-between w-full  text-[14px] leading-[24px]">
            <div>{__('배송비')}</div>
            <div>
              {deliveryFeeMethod === 'ADD' ? '' : '-'}
              {__('{{price, 3comma}} {{currency, currency}}', {
                price: deliveryFee,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
