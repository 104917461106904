import { useQuery } from '@tanstack/react-query'
import { getOrderDetailData } from './order-detail-api'
import { __ } from '~/shared/i18n'
import { queryOptions } from '@tanstack/react-query'

/**
 * @deprecated 'src/features/order-detail'로 대체
 */
export const orderDetailOptions = ({ orderCode }: { orderCode: string }) =>
  queryOptions({
    queryKey: ['orderDetail', orderCode],
    queryFn: () => getOrderDetailData(orderCode),
    staleTime: 1000 * 10, // 10초, 조건부로 렌더되는 중첩된 자식 컴포넌트에서 동일한 쿼리를 요청할 때 이중 요청되는 현상을 방지
  })

export const useGetOrderDetailContextState = (orderCode: string) =>
  useQuery({
    ...orderDetailOptions({ orderCode }),
    select: ({ data }) => {
      const {
        currency,
        orderDeliveryList,
        unitCode,
        siteCode,
        memberCode,
        orderNo,
        ordererName,
        ordererEmail,
      } = data
      return {
        currency,
        orderDeliveryList,
        unitCode,
        siteCode,
        memberCode,
        orderNo,
        ordererName,
        ordererEmail,
      }
    },
  })
