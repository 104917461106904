/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'
import { 상수_수거결제타입, 상수_수거타입 } from '~/entities/@x'

const RetrieveRequestSchema = z
  .object({
    retrieveTypeCd: z.nativeEnum(상수_수거타입)
      .describe(`반품시 물품 수거 타입 코드
      RRT01(자동 수거 신청),
      RRT02(구매자 발송),
      RRT03(기타)
      RRT04(수동 수거 신청)
    `),
    retrievePayTypeCd: z.nativeEnum(상수_수거결제타입).nullish()
      .describe(`반품시 수거비용 지불 타입 코드
      RPT01(신용),
      RPT02(선불),
      RPT03(착불),
      RPT04(기타)
    `),
    invoiceNo: z
      .string()
      .nullish()
      .describe('송장번호 - 구매자가 발송하고 송장번호를 알려줬을 경우'),
    parcelCompanyIdx: z
      .number()
      .nullish()
      .describe('택배사코드 - 구매자가 발송하고 송장번호를 알려줬을 경우'),
    retrieveMemo: z.string().nullish().describe('수거 특이사항 메모'),
    centerCode: z
      .string()
      .nullish()
      .describe(
        '굿스플로 계약정보코드(goodsflow_delivery_service_detail.center_code) - 자동수거 신청시 필수'
      ),
    orderDeliveryCode: z.string().nullish().describe('배송지코드'),
    receiverName: z
      .string()
      .min(1)
      .max(100)
      .nullish()
      .describe('수령자명 - 자동 수거 신청, 배송지 임의입력인 경우'),
    receiverCall: z
      .string()
      .min(1)
      .max(30)
      .nullish()
      .describe('수령자전화번호 - 자동 수거 신청, 배송지 임의입력인 경우'),
    addr1: z
      .string()
      .nullish()
      .describe('주소 - 자동 수거 신청, 배송지 임의입력인 경우'),
    addr2: z
      .string()
      .nullish()
      .describe('상세주소 - 자동 수거 신청, 배송지 임의입력인 경우'),
    zipcode: z
      .string()
      .min(1)
      .max(8)
      .nullish()
      .describe('배송지 우편번호 - 자동 수거 신청, 배송지 임의입력인 경우'),
  })
  // ====================================== 자동수거신청
  .refine(
    (v) => {
      if (v.retrieveTypeCd === 상수_수거타입.자동수거신청) {
        return v.centerCode !== null
      }
      return true
    },
    {
      path: ['centerCode'],
      message: '자동수거신청은 센터코드는 필수입니다.',
    }
  )

/**
 * post retrieve-request
 */
export const postResRetrieveRequestSchema = ApiResponseSchema
export const postPayloadRetrieveRequestSchema = RetrieveRequestSchema

/**
 * patch retreive-request
 */

export const patchResRetrieveRequestSchema = ApiResponseSchema
export const patchPayloadRetrieveRequestSchema = RetrieveRequestSchema
