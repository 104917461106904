import { ApiClient } from '~/shared/api'
import { type TGetResMenuBannerDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description get-menu-banner
 */
export function getMenuBanner() {
  return async function () {
    const result = await axios
      .get<ApiResponse<TGetResMenuBannerDto>>('/site/menu-banner')
      .then((res) => res.data)

    return result.data ?? {} // data가 없을 경우 빈 객체 반환
  }
}
