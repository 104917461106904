import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResOrderSectionDeliveryDto,
  type TPatchPayloadOrderSectionDeliveryDto,
  patchResOrderSectionDeliveryDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/sEepIP
 * @description order-section-delivery
 */
export function patchOrderSectionDelivery({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadOrderSectionDeliveryDto) {
    type T = TPatchResOrderSectionDeliveryDto
    type R = typeof body
    const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
      `/order/${orderCode}/sections/${sectionCode}/delivery`,
      body
    )

    patchResOrderSectionDeliveryDto.parse(result.data)
    return result
  }
}
