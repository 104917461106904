import { useCallback, useEffect, useState } from 'react'
import {
  OrderEditAddCalc,
  OrderEditCalcDeliveryFee,
  OrderEditCalcDeliveryFeeMethod,
  OrderEditAddCalcDiscountWarningBanner,
  OrderEditAddCalcPurchaseLevelDiscount,
  OrderEditCalcReservesUse,
  OrderEditAddCalcAmountCard,
  OrderEditAddCalcCoupon,
  Coupon,
  OrderEditApplyItemSection,
  useOrderSectionAtom,
} from '~t'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { OrderEditCalcSubmitModal } from './modal/order-edit-calc-submit-modal'
import { addItemsToOrder } from './order-add-calc-query'
import { useMutation } from '@tanstack/react-query'

import { z } from 'zod'
import { __, __e } from '~/shared/i18n'
import { useNavigate } from 'react-router-dom'
import {
  useFetchDiscountData,
  useGetAdditionalDiscount,
  useGetOrderEditCalcPageData,
} from './order-add-calc-query'
import { useGetAddData } from './order-add-calc-data'

import { zodResolver } from '@hookform/resolvers/zod'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import {
  type TOrderAddConfirm,
  useOrderAddConfirmAtom,
} from '~/container/order/order-add/order-add.store'
import { useQueryClient } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { orderDetailOptions } from '~/entities/order-detail'

function OrderEditCalcPage({ orderNo }: { orderNo: string }) {
  const router = useNavigate()
  const orderCode = useOrderCode()
  const queryClient = useQueryClient()
  const { data: currency, isSuccess: isEditCalcPageDataSuccess } =
    useGetOrderEditCalcPageData(orderCode as string)

  const { itemPrice, itemAddedItems, countIncreasedItems, addComfirmData } =
    useGetAddData()

  const {
    data: { coupons = [], points: defaultReserveLimit, isUsedMembership } = {},
    isSuccess: isAdditionalDiscountSuccess,
  } = useGetAdditionalDiscount(orderCode as string)

  const [submitModalOpen, setSubmitModalOpen] = useState(false)
  const [submitModalLoading, setSubmitModalLoading] = useState(false)

  // [상품추가 상태 초기화]==================================START
  // [상품추가 상태 초기화] 상품추가쪽에서 설정했던 상태값을 초기화해주기위한 코드
  const orderSectionCode = useCallback(() => {
    // TOrderAddConfirm 타입이라면 orderSectionCode를 가져오기
    // 아니라면 new를 가져오기
    const addComfirmDataSection = addComfirmData[0]
    // addComfirmDataSection에 orderSectionCode가 있는지 확인하기
    if (
      addComfirmDataSection &&
      Object.hasOwnProperty.call(addComfirmDataSection, 'orderSectionCode')
    ) {
      return (addComfirmDataSection as TOrderAddConfirm).orderSectionCode
    }
    return 'new'
  }, [addComfirmData])

  const [items, setItems] = useOrderSectionAtom.section(orderSectionCode())
  const [, deleteSetConfrimData] = useOrderAddConfirmAtom.delete(
    orderCode as string,
    orderSectionCode()
  )
  const [, setOrderAddConfirmData] = useOrderAddConfirmAtom.submit()
  // [상품추가 상태 초기화]====================================END

  const submitSuccessCallback = () => {
    toast.success(__('추가 반영되었습니다'))

    // [상품추가 상태 초기화]==================================START
    // 반영완료해서 주문서상세로 이동하기전에 주문추가에서 사용했던 상태값은 모두 초기화해줘야한다.
    // 추가금액계산에서는 1개의 섹션만 가질수있다.
    pipe(
      items,
      O.fromNullable,
      O.fold(
        () => undefined,
        (e) => {
          pipe(
            e.value,
            O.fromNullable,
            O.fold(
              () => undefined,
              flow(
                A.filter((x) => x.isNew === false),
                A.map((x) => ({ ...x, qtyChange: 0 })),
                (x) =>
                  setItems({
                    ...e,
                    value: x,
                  })
              )
            )
          )
        }
      )
    )
    deleteSetConfrimData()
    setOrderAddConfirmData(undefined)
    // [상품추가 상태 초기화]====================================END

    // 상품추가가 완료되었다면 주문서 상세로 다시 보내준다.
    // [상품추가 완료했고 상품주문서 데이터 초기화] =========
    queryClient.invalidateQueries({
      queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
    })
    setSubmitModalOpen(false)
    router(`/order/1/${orderCode}`)
  }

  const { mutate } = useMutation({
    mutationFn: addItemsToOrder,
    onSuccess: submitSuccessCallback,
    onError: (error) => {
      toast.error(__e(error.response?.data.code || ''))
    },
    onSettled: () => {
      setSubmitModalLoading(false)
    },
  })

  const [calcAmountCardProps, setCalcAmountCardProps] = useState<{
    itemPrice: number
    levelDiscountPrice: number
    reservePrice: number
    deliveryFee: number
    deliveryFeeMethod: 'ADD' | 'SUB'
    couponDiscountPrice: number
  }>({
    itemPrice,
    levelDiscountPrice: 0,
    reservePrice: 0,
    deliveryFee: 0,
    deliveryFeeMethod: 'ADD',
    couponDiscountPrice: 0,
  })

  // 적립금한도
  const [reserveLimit, setReserveLimit] = useState(defaultReserveLimit || 0)

  /** 스키마 정의 */

  const couponSchema = z.object({
    couponName: z.string(),
    couponIssueCode: z.string(),
    expireDate: z.date().refine((date) => date.getTime() > Date.now(), {
      message: 'Coupon has expired.',
    }),
  })

  const editCalcSchema = z.object({
    usingReserves: z
      .number({
        invalid_type_error: __('숫자만 입력해주세요') || undefined,
      })
      .max(reserveLimit as number),
    deliveryFee: z
      .number({
        invalid_type_error: __('숫자만 입력해주세요') || undefined,
      })
      // TODO:국가 및 언어 설정에 따른 분기 처리 필요
      .refine((value) => value <= 200000),
    coupons: z.array(couponSchema),
    purchaseDiscount: z.enum(['Y', 'N']),
    deliveryFeeMethod: z.enum(['ADD', 'SUB']),
  })

  const resolver = zodResolver(editCalcSchema)

  const { getValues, setValue, control, watch, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      usingReserves: 0,
      deliveryFee: 0,
      coupons: [] as Coupon[],
      purchaseDiscount: 'Y',
      deliveryFeeMethod: 'ADD',
    },
    resolver,
  })

  /** 할인 계산기  */
  const purchaseDiscount = watch('purchaseDiscount')
  const usingReserves = watch('usingReserves')
  const useCouponCodes = watch('coupons')

  /** 배송비  */

  const onChangeDeliveryFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const deliveryFeeMethod = watch('deliveryFeeMethod')

    if (value.trim() === '') {
      setValue('deliveryFee', 0)
      return
    }
    const numericValue = parseFloat(value.replace(/,/g, ''))
    if (Number.isNaN(numericValue)) {
      return
    }

    // 배송비를 음수로 넘기면 에러를 추가해준다
    if (numericValue < 0) {
      setValue('deliveryFee', 0)
      return
    }

    if (deliveryFeeMethod === 'ADD') {
      setValue('deliveryFee', numericValue)
      return
    }

    if (numericValue > subDeliveryExtraPriceMax) {
      setValue('deliveryFee', subDeliveryExtraPriceMax)
    } else {
      setValue('deliveryFee', numericValue)
    }
  }
  const onBlurDeliveryFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const numericValue = parseFloat(value.replace(/,/g, ''))
    // 국가가 한국이라면 아래 로직을 실행한다. 그리고 화폐가 원화여야한다.

    // 입력한 값이 숫자가 아니라면 값을 설정하지 않는다.
    if (Number.isNaN(numericValue)) {
      return
    }

    let numberTTT: number | undefined = numericValue

    // 빈값이면 안되고, 빈값이면 값을 0으로 초기화한다.
    if (value.trim() === '') {
      numberTTT = 0
    }

    // 값이 200000원을 초과하면 200000원으로 설정한다.
    if (numericValue > 200000) {
      numberTTT = 200000
    }

    setValue('deliveryFee', numberTTT, { shouldValidate: true })
    setCalcAmountCardProps((prev) => ({
      ...prev,
      deliveryFee: numberTTT as number,
    }))
  }

  const onChangeusingReserve = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value.trim() === '') {
      setValue('usingReserves', 0)
      return
    }
    const numericValue = parseFloat(value.replace(/,/g, ''))
    if (Number.isNaN(numericValue)) {
      return
    }
    if (numericValue < 0) {
      setValue('usingReserves', 0)
      return
    }

    setValue('usingReserves', numericValue)
  }

  /** 쿠폰 관련 */

  const onDeleteCoupon = (couponIssueCode: string) => {
    const selectedCoupon = getValues('coupons')
    const newCoupons = selectedCoupon.filter(
      (v) => v.couponIssueCode !== couponIssueCode
    )
    setValue('coupons', newCoupons)
  }

  const getCouponsExpired = () =>
    coupons.filter((coupon: Coupon) => {
      const date_object = coupon.expireDate ? new Date(coupon.expireDate) : null
      if (!date_object) {
        return false
      }
      const currentTime = new Date().getTime()
      const expireTime = date_object.getTime()
      const { couponIssueCode } = coupon
      const selectedCoupon = getValues('coupons')

      return (
        expireTime - currentTime < 0 &&
        selectedCoupon.some(
          ({ couponIssueCode: code }) => code === couponIssueCode
        )
      )
    })
  const dismissExpiredCoupon = (expiredCoupons: Coupon[]) => {
    const selectedCoupon = getValues('coupons')
    const newSelectedCoupon = selectedCoupon.filter(
      ({ couponIssueCode }) =>
        !expiredCoupons.some(
          ({ couponIssueCode: code }) => code === couponIssueCode
        )
    )
    setValue('coupons', newSelectedCoupon)
  }

  const prods = addComfirmData
    .map(({ prods: confirmDataProds }: { prods: any }) => confirmDataProds)
    .flat()
  /** 품목추가만 필터링. 품목추가는 orderSectionItemCode가 없음 */
  /**
   * 주문서에는 이미 상품의 타입, 주문서의 타입(기본, 디지털, 구독)이 등록되어있다.
   * 그래서 배송비 타입, 배송타입이 이미 정해져있다.
   * 주문서에서 해당 데이터를 가져와서 넣어줘야한다.
   */
  const transformedProds: {
    prodOptionDetailCode?: string
    optionDataList?: {
      optionType: string
      optionCode: string
      valueCode: string
    }[]
    prodCode: string
    qty?: number
    deliveryTypeCd: string
    deliveryPayTypeCd: string
    baseItemPrice: number
    itemPrice: number
    isRequireOption: 'Y' | 'N' | string
  }[] = pipe(
    addComfirmData,
    // 모든 prods를 가져오기
    A.map((e) => e.prods),
    A.flatten,
    // 품목추가만 필터링
    A.filter(({ orderSectionItemCode }) => !orderSectionItemCode),
    A.map((e) => ({
      prodOptionDetailCode: e.prodOptionDetailCode || '',
      optionDataList: e.optionDataList,
      prodCode: e.prodCode,
      qty: Number(e.qty || 0),
      deliveryTypeCd: pipe(e.type, (r) => {
        switch (r) {
          case 'normal':
            return 'ODT02' // 택배
          case 'digital':
            return 'ODT03' // 다운로드
          case 'subscribe':
            return 'ODT01' // 배송없음
          default:
            return 'unknown'
        }
      }),
      deliveryPayTypeCd: pipe(e.type, (r) => {
        switch (r) {
          case 'normal':
            return 'ODP02' // 선결제
          case 'digital':
            return 'ODP01' // 배송비없음
          case 'subscribe':
            return 'ODP01' // 배송비없음
          default:
            return 'unknown'
        }
      }),
      baseItemPrice: e.baseItemPrice,
      itemPrice: e.itemPrice,
      isRequireOption: e.isRequireOption || 'Y',
      // od코드가없으면 Y, 있으면 N
      isIndividualOption: e.prodOptionDetailCode ? 'N' : 'Y',
    }))
  )

  // 금액계산 데이터 호출하기
  // 할인가져오기.
  const {
    data: discountData,
    isSuccess: isDisCountDataSuccess,
    refetch,
  } = useFetchDiscountData({
    fetchDiscountDataParam: {
      orderCode: orderCode as string,
      params: {
        prods: transformedProds,
        isGradeDiscount: purchaseDiscount === 'Y',
        usePoint: usingReserves,
        useCouponCodes: useCouponCodes.map(
          ({ couponIssueCode }) => couponIssueCode
        ),
      },
    },
  })

  const subDeliveryExtraPriceMax = discountData?.subDeliveryExtraPriceMax ?? 0

  // mutation을 통해서 ,등급할인금액, 쿠폰할인, 적립금을 받아온다.
  // 받아와서 , Card에 값으로 넣어준다.

  const onChangeDiscountSetting = () => {
    // 등급할인 선택될때, 계산기 api 에 상품가, 등급할인여부, 쿠폰 , 적립금을 payload로 보낸다.
    // 사용가능한 적립금을 가져오고, 최대 사용가능한 적립금으로 업데이트한다.
    // 등급할인, 쿠폰할인, 적립금사용 금액을 업데이트해준다.
    refetch().then(({ data }) => {
      setCalcAmountCardProps((prev) => ({
        ...prev,
        levelDiscountPrice: data?.totalGradeDiscount ?? 0,
        reservePrice: data?.totalPointDiscount ?? 0,
        couponDiscountPrice: data?.totalCouponDiscount ?? 0,
      }))
      setReserveLimit(data?.usablePoints ?? 0)
      setValue('usingReserves', data?.totalPointDiscount ?? 0)
    })
  }

  useEffect(() => {
    onChangeDiscountSetting()
  }, [purchaseDiscount, useCouponCodes])

  /** 적립금 */
  const onBlurUsingReserve = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const numericValue = parseFloat(value.replace(/,/g, ''))
      // 국가가 한국이라면 아래 로직을 실행한다. 그리고 화폐가 원화여야한다.

      // 입력한 값이 숫자가 아니라면 값을 설정하지 않는다.
      if (Number.isNaN(numericValue)) {
        return
      }

      // 음수로 넘기면 에러를 추가해준다
      if (numericValue < 0) {
        setValue('usingReserves', 0)
        return
      }

      let numberTTT: number | undefined = numericValue

      // 빈값이면 안되고, 빈값이면 값을 0으로 초기화한다.
      if (value.trim() === '') {
        numberTTT = 0
      }
      if (numericValue > (reserveLimit as number)) {
        numberTTT = reserveLimit
      }
      // TODO: 계산기 api를 통해서 값을 가져온다. 비동기 처리를 통해서 setValue안에 값으로 넘겨준다.
      setValue('usingReserves', numberTTT as number, { shouldValidate: true })
      onChangeDiscountSetting()
    },
    [reserveLimit, discountData]
  )
  const onClicksubmitModalAction = () => {
    setSubmitModalLoading(true)

    const couponsExpired = getCouponsExpired()
    if (couponsExpired.length) {
      /** TODO: 모달 노출 혹은 주문서 반영 버튼 disabled 후 error 노출 */
      /** 만료된 쿠폰 해제하기 */
      dismissExpiredCoupon(couponsExpired)
      // toast 노출
      toast.error(__('잘못되었습니다!!'))

      return
    }

    mutate({
      orderCode: orderCode as string,
      params: {
        discountWithGrade: getValues('purchaseDiscount') as 'Y' | 'N',
        couponCodes: getValues('coupons').map(
          ({ couponIssueCode }) => couponIssueCode
        ),
        discountPointAmount: getValues('usingReserves'),
        deliveryExtraPriceType: getValues('deliveryFeeMethod') as 'ADD' | 'SUB',
        deliveryExtraPrice: getValues('deliveryFee'),
        orderSections: addComfirmData,
      },
    })
  }

  if (
    !isEditCalcPageDataSuccess ||
    !isAdditionalDiscountSuccess ||
    !isDisCountDataSuccess
  ) {
    return <div>loading...</div>
  }
  const deliveryFeeMethod = watch('deliveryFeeMethod')

  return (
    <div className="min-w-[992px] overflow-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setSubmitModalOpen(true)
        }}
      >
        <OrderEditAddCalc
          orderNo={orderNo}
          itemLength={itemAddedItems.length + countIncreasedItems.length}
        >
          {itemAddedItems.length > 0 && (
            <OrderEditAddCalc.Slot
              name="item-added"
              className="w-full semantic-p14"
            >
              <OrderEditApplyItemSection
                items={itemAddedItems}
                className="grid gap-y-[20px]"
              />
            </OrderEditAddCalc.Slot>
          )}
          {countIncreasedItems.length > 0 && (
            <OrderEditAddCalc.Slot
              name="count-added"
              className="w-full semantic-p14"
            >
              <OrderEditApplyItemSection
                items={countIncreasedItems}
                className="grid gap-y-[20px]"
              />
            </OrderEditAddCalc.Slot>
          )}
          <OrderEditAddCalc.Slot name="discount">
            {/** 수량추가가 있을때 */}
            {countIncreasedItems.length ? (
              <OrderEditAddCalcDiscountWarningBanner />
            ) : null}
            {/** 품목추가만 있을때 */}
            {itemAddedItems?.length ? (
              <>
                {isUsedMembership ? (
                  <OrderEditAddCalcPurchaseLevelDiscount
                    control={control}
                    // onChangePurchaseLevelDiscount={onChangeDiscountSetting}
                  />
                ) : null}
                {coupons.length ? (
                  <OrderEditAddCalcCoupon
                    control={control}
                    coupons={coupons}
                    onDeleteCoupon={onDeleteCoupon}
                    // onChangeCouponSelect={onChangeDiscountSetting}
                  />
                ) : null}
                <OrderEditCalcReservesUse
                  reserveLimit={discountData?.usablePoints ?? 0}
                  currency={currency}
                  onChangeReservesUse={onChangeusingReserve}
                  onBlurReservesUse={onBlurUsingReserve}
                  control={control}
                />
              </>
            ) : null}
          </OrderEditAddCalc.Slot>
          <OrderEditAddCalc.Slot name="delivery-fee">
            <div className="flex flex-col gap-[20px]">
              <OrderEditCalcDeliveryFeeMethod
                control={control}
                onChangeDeliveryFeeMethod={(value: string) => {
                  setCalcAmountCardProps((prev) => ({
                    ...prev,
                    deliveryFeeMethod: value as 'ADD' | 'SUB',
                  }))
                }}
              />
              <OrderEditCalcDeliveryFee
                currency={currency}
                onChangeDeliveryFee={onChangeDeliveryFee}
                onBlurDeliveryFee={onBlurDeliveryFee}
                control={control}
                deliveryRefundLimit={subDeliveryExtraPriceMax ?? 0}
                deliveryFeeMethod={deliveryFeeMethod as 'ADD' | 'SUB'}
              />
            </div>
          </OrderEditAddCalc.Slot>
          <OrderEditAddCalc.Slot name="calc">
            <OrderEditAddCalcAmountCard
              {...calcAmountCardProps}
              currency={currency}
            />
          </OrderEditAddCalc.Slot>
        </OrderEditAddCalc>
      </form>
      <OrderEditCalcSubmitModal
        open={submitModalOpen}
        onOpenChange={setSubmitModalOpen}
        isSubmitting={submitModalLoading}
        handleSubmit={onClicksubmitModalAction}
      />
    </div>
  )
}

export { OrderEditCalcPage }
