import React, { useState } from 'react'
import { Controller, Control } from 'react-hook-form'
import { Textfield } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'

export interface OrderEditCalcReservesUseProps {
  reserveLimit: number
  onBlurReservesUse?: (value: any) => void
  onChangeReservesUse?: (value: any) => void
  currency: string
  control: Control<any>
}

const OrderEditCalcReservesUse = ({
  reserveLimit,
  onBlurReservesUse,
  onChangeReservesUse,
  currency = 'KRW',
  control,
}: OrderEditCalcReservesUseProps) => (
  <div className="flex flex-col gap-[8px] w-fit">
    <Controller
      name="usingReserves"
      control={control}
      render={({ field }) => (
        <Textfield.Set>
          <Textfield.Legend text={__('적립금 사용')} />
          <Textfield.Box>
            <Textfield.Input
              value={new Intl.NumberFormat().format(field.value)}
              onChange={onChangeReservesUse}
              onBlur={onBlurReservesUse}
            />
            <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
              {__('{{currency, currency}}', {
                currency,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })}
            </Textfield.Addon>
          </Textfield.Box>
          <Textfield.HelperText>
            {__('최대 {{price, 3comma}}{{currency, currency}} 가능', {
              price: reserveLimit,
              formatParams: {
                currency: {
                  currency,
                },
              },
            })}
          </Textfield.HelperText>
        </Textfield.Set>
      )}
    />
  </div>
)

export { OrderEditCalcReservesUse }
