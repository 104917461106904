import { pipe, flow } from 'fp-ts/function'
import * as S from 'fp-ts/string'
import { Trans as OriginTrans } from 'react-i18next'
import { overrideI18n } from './i18n-init'
import { type TFunction } from 'i18next'

export const __: TFunction<'admin'> = overrideI18n.getFixedT(null, 'admin')

/**
 * @description glossary 네임스페이스가 기본으로 포함한 t를 리턴한다.
 */
export const __g: TFunction<'glossary'> = overrideI18n.getFixedT(
  null,
  'glossary'
)

/**
 * @description common 네임스페이스가 기본으로 포함한 t를 리턴한다.
 */
export const __c: TFunction<'common'> = overrideI18n.getFixedT(null, 'common')

/**
 * @description error 네임스페이스가 기본으로 포함한 t를 리턴한다.
 */
export const __e: TFunction<'error'> = overrideI18n.getFixedT(null, 'error')

/**
 * @description 아무런 번역이 없는 empty 네임스페이스
 */
export const __ll: TFunction<'empty'> = overrideI18n.getFixedT(null, 'empty')

export const TransT: typeof OriginTrans = (props) => (
  <OriginTrans ns="template" {...props} />
)

export const TransA: typeof OriginTrans = (props) => (
  <OriginTrans ns="admin" {...props} />
)
export const TransG: typeof OriginTrans = (props) => (
  <OriginTrans ns="glossary" {...props} />
)

export const Trans = {
  /**
   * Trans컴포넌트의 ns가 template인 컴포넌트를 리턴한다.
   */
  T: TransT,
  A: TransA,
  G: TransG,
}

interface priceOption extends Intl.NumberFormatOptions {
  removeCountry?: boolean
  removeCurrency?: boolean
  currencyDisplay?: 'code' | 'symbol' | 'name' | 'narrowSymbol'
  template?: string
}
export function price(options?: priceOption) {
  return function (value: number, lng = 'ko') {
    const overrideOptions = { ...options }
    // 기본값을 currency로 설정한다.
    if (!overrideOptions?.style) {
      overrideOptions.style = 'currency'
    }
    if (!overrideOptions?.currency) {
      overrideOptions.currency = 'KRW'
    }
    if (!overrideOptions.currencyDisplay) {
      overrideOptions.currencyDisplay = 'name'
    }
    if (!overrideOptions.removeCountry) {
      overrideOptions.removeCountry = true
    }
    if (!overrideOptions.removeCurrency) {
      overrideOptions.removeCurrency = true
    }

    if (overrideOptions?.removeCurrency) {
      const getCurrencyName = new Intl.NumberFormat(
        lng,
        overrideOptions
      ).format(value)
      const result = getCurrencyName.split(' ')
      // 가격제거
      const _price = result.shift()

      // 기본 template
      const template = overrideOptions?.template || '{{amount}}'
      return pipe(template, S.replace('{{amount}}', String(_price)))
    }
    // 대한민국 통화 커스텀 포맷터
    // Javascript의 기본 Intl.NumberFormat에서 name의 값은 `대한민국 원`으로만 지원한다.
    // 하지만우리는 `1,000 원`을 원한다.
    // 모든 나라 화폐에 대해서 동일하게 화페 단위만 가져올 것이다.
    // - 대한민국 원
    // - 미국 달러
    // - 유로
    // - 일본 엔화
    if (
      overrideOptions?.removeCountry &&
      overrideOptions?.currencyDisplay === 'name'
    ) {
      const getCurrencyName = new Intl.NumberFormat(
        lng,
        overrideOptions
      ).format(value)
      const result = getCurrencyName.split(' ')
      // 가격제거
      const _price = result.shift()

      // 기본 template
      const template = overrideOptions?.template || '{{amount}} {{currency}}'
      return pipe(
        template,
        S.replace('{{amount}}', String(_price)),
        S.replace('{{currency}}', result.slice(-1)[0])
      )
    }
    return new Intl.NumberFormat(lng, overrideOptions).format(value)
  }
}
