import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as math from 'mathjs'
import { TCancelSettlementsSchema } from '~/entities/order-section/cancel-settlements'
import { P, match } from 'ts-pattern'
import {
  TPatchPayloadCancelCompleteDto,
  TPatchPayloadCancelCompleteRdo,
} from '~/entities/order-section/cancel-complete'
import { 상수_환불금액_타입 } from '~/entities/@x'

export class 모델_취소금액계산 {
  form: TPatchPayloadCancelCompleteRdo
  cancelSettlements: TCancelSettlementsSchema

  constructor({
    form,
    cancelSettlements,
  }: {
    form: TPatchPayloadCancelCompleteRdo
    cancelSettlements: TCancelSettlementsSchema
  }) {
    this.form = form
    this.cancelSettlements = cancelSettlements
  }

  get 타입별금액() {
    return match(this.form.refundPriceTypeCd)
      .with(
        상수_환불금액_타입.실결제가,
        () => this.cancelSettlements.totalActualPaymentPrice
      )
      .with(
        상수_환불금액_타입.상품판매가,
        () => this.cancelSettlements.totalItemPrice
      )
      .with(
        상수_환불금액_타입.평균실결제가,
        () => this.cancelSettlements.totalAverageActualPaymentPrice
      )
      .otherwise(() => 0)
  }

  get 최소_환불_적립금() {
    return match(this.form.deliveryExtraPriceType)
      .with('SUB', () => {
        const result = math
          .chain(this.타입별금액)
          .add(this.form.deliveryExtraPrice?._sub || 0)
          .subtract(this.cancelSettlements.refundableRemainPaymentPrice || 0)
          .done()
        return result
      })
      .with('ADD', () => 0)
      .otherwise(() => 0)
  }
  /**
   * @see https://imweb.atlassian.net/browse/TOA-532
   * @description
   * 실결제가 반품을 선택했을때
   * 1. cancelSettlements.totalActuallypaymentPrice
   *
   * 상품판매가 선택했을때
   * 1. cancelSettlements.totalItemPrice
   *
   * 평균실결제가 선택했을때
   * 1. cancelSettlements.totalAverageActualPaymentPrice
   */
  get 최대_환불가능_적립금() {
    return this.cancelSettlements.recreditedPoint
  }

  get 최대_환불가능_배송비() {
    const result = math.max(
      0,
      math
        .chain(this.cancelSettlements.refundableAmount)
        .subtract(this.타입별금액)
        .done()
    )
    return result
  }

  get 최대_환불가능_금액() {
    const result = match(this.form.refundPriceTypeCd)
      .with(
        상수_환불금액_타입.직접입력,
        () => this.cancelSettlements.refundableAmount
      )
      .otherwise(() => 0)
    return result
  }

  get 적립금_배송비_환불가능여부() {
    const result = match(this.form.refundPriceTypeCd)
      .with(상수_환불금액_타입.실결제가, () =>
        math.chain(this.cancelSettlements.totalActualPaymentPrice).done()
      )
      .with(상수_환불금액_타입.상품판매가, () =>
        math.chain(this.cancelSettlements.totalItemPrice).done()
      )
      .with(상수_환불금액_타입.평균실결제가, () =>
        math.chain(this.cancelSettlements.totalAverageActualPaymentPrice).done()
      )
      .otherwise(() => math.chain(0).done())

    return math.evaluate('result > refundableAmount', {
      result,
      refundableAmount: this.cancelSettlements.refundableAmount,
    })
  }

  /**
   * @return 음수
   */
  get 환불금액_설정조건금액() {
    const result = match(this.form.refundPriceTypeCd)
      .with('ORT01', () => this.cancelSettlements.totalActualPaymentPrice)
      .with('ORT02', () => this.cancelSettlements.totalItemPrice)
      .with(
        'ORT03',
        () => this.cancelSettlements.totalAverageActualPaymentPrice
      )
      .with('ORT05', () =>
        pipe(
          this.cancelSettlements.totalActualPaymentPrice,
          O.fromNullable,
          O.getOrElse(() => 0)
        )
      )
      .otherwise(() => 0)
    const _result = math.chain(result).multiply(-1).done()
    return Object.is(_result, -0) ? 0 : _result
  }

  /**
   * @description
   * 환불금액_설정조건금액
   * +- 배송비
   * +- 기타비용
   * 제한조건 - refundableAmount + rerecreditedPoint를 초과할수없다.
   */
  get 환불금액_총_금액() {
    const result = math
      .chain(this.환불금액_설정조건금액) // 음수
      .add(this.환불금액_배송비) // 양수, 음수
      .add(this.form.refundPriceTypeCd === 'ORT05' ? this.환불금액_기타비용 : 0)
      .done()
    return pipe(
      this.cancelSettlements.refundableAmount,
      math.chain,
      (e) => e.multiply(-1).done(),
      // 음수라서 max로 계산한다.
      (e) => math.max(e, result)
    )
  }
  get 환불금액_적립금() {
    if (this.form.refundPriceTypeCd === 'ORT05') {
      return math
        .chain(
          math.min(
            this.form.requestRefundPrice || 0,
            this.form.refundPoint || 0
          )
        )
        .multiply(-1)
        .done()
    }
    const result = math
      .chain(this.form.refundPoint || 0)
      .multiply(-1)
      .done()
    return Object.is(result, -0) ? 0 : result
  }

  /**
   * @description
   * refundableAmount 초과할수없다.
   */
  get 환불금액_금액() {
    const result = math.evaluate(
      'min(max(0, ((requestRefundPrice * -1) - refundPoint)), (refundableAmount - recreditedPoint)) * -1',
      {
        requestRefundPrice: this.환불금액_총_금액,
        refundPoint: this.refundPoint,
        refundableAmount: this.cancelSettlements.refundableAmount,
        recreditedPoint: this.cancelSettlements.recreditedPoint,
      }
    )
    return Object.is(result, -0) ? 0 : result
  }

  /**
   * @description
   * 배송비 - 요청 타입(ADD)
   * 요청타입의 경우 추가금액으로 +로 처리한다.
   * 배송비 - 환불 타입(SUB)
   * 환불타입의 경우 환불금액으로 -로 처리한다.
   * @return ADD(양수), SUB(음수)
   */
  get 환불금액_배송비() {
    const result = match(this.form)
      .with(
        {
          deliveryExtraPriceType: 'ADD',
        },
        () => math.chain(this.deliveryExtraPrice).done()
      )
      .with(
        {
          deliveryExtraPriceType: 'SUB',
        },
        () => math.chain(this.deliveryExtraPrice).multiply(-1).done() // 배송비 환불은 음수 표시
      )
      .otherwise(() => math.chain(0).done())
    return result
  }

  get 환불금액_기타비용() {
    const result = math
      .chain(this.cancelSettlements.totalActualPaymentPrice)
      .subtract(this.form.requestRefundPrice || 0)
      .done()
    return result
  }

  // ====================================== payload
  get cancelCompletePayload(): TPatchPayloadCancelCompleteDto {
    return {
      refundPriceTypeCd: this.form.refundPriceTypeCd,
      etcPriceReason: '',
      requestRefundPrice: this.requestRefundPrice,
      deliveryExtraPrice: this.deliveryExtraPrice,
      refundPoint: this.refundPoint,
      returnedCoupons: pipe(
        this.form.returnedCoupons,
        O.fromNullable,
        O.fold(
          () => [],
          flow(
            A.filter((e) => !!e._check),
            A.map((e) => e._code),
            (e) => e
          )
        )
      ),
      deliveryExtraPriceType: this.form.deliveryExtraPriceType,
      targetItemInformation: this.form.targetItemInformation,
    }
  }

  /**
   * @description
   * 배송비 - 요청 타입(ADD)
   * 요청타입의 경우 추가금액으로 +로 처리한다.
   * 배송비 - 환불 타입(SUB)
   * 환불타입의 경우 환불금액으로 -로 처리한다.
   * @return ADD(양수), SUB(음수)
   */
  get deliveryExtraPrice() {
    const result = match(this.form)
      .with(
        {
          deliveryExtraPriceType: 'ADD',
          deliveryExtraPrice: { _add: P.number.between(0, 200_000) },
        },
        (e) => math.chain(e.deliveryExtraPrice._add).done()
      )
      .with(
        {
          deliveryExtraPriceType: 'SUB',
          deliveryExtraPrice: { _sub: P.number },
        },
        (e) => math.chain(e.deliveryExtraPrice._sub).done()
      )
      .otherwise(() => math.chain(0).done())
    return result
  }

  get requestRefundPrice() {
    const result = match(this.form.refundPriceTypeCd)
      .with(
        상수_환불금액_타입.실결제가,
        () => this.cancelSettlements.totalActualPaymentPrice
      )
      .with(
        상수_환불금액_타입.상품판매가,
        () => this.cancelSettlements.totalItemPrice
      )
      .with(
        상수_환불금액_타입.평균실결제가,
        () => this.cancelSettlements.totalAverageActualPaymentPrice
      )
      .with(상수_환불금액_타입.직접입력, () =>
        pipe(
          this.form.requestRefundPrice,
          O.fromNullable,
          O.getOrElse(() => 0)
        )
      )
      .otherwise(() => 0)
    return result
  }

  get refundPoint() {
    const refundPoint = match(this.form.refundPoint)
      .with(P.nonNullable, (e) => e)
      .otherwise(() => 0)
    const result = match(this.form.refundPriceTypeCd)
      .with(상수_환불금액_타입.직접입력, () =>
        math.min(refundPoint, this.requestRefundPrice)
      )
      .otherwise(() => refundPoint)
    return result
  }
}
