import { TAddressData } from './member-delivery.type'

/**
 * 배송지 데이터를 주소 문자열로 반환
 * @example 아임웹, 070-4246-0106(06168)\n서울 강남구 테헤란로 501 브이플렉스 14, 15F
 * @example 삭삭몬, +12512384531\n{$Building}, {$Street}, {$City}, {$State}, {$zipcode}, {$country}
 */
export function deliveryDataToAddress(
  data: Partial<TAddressData>,
  country: string
): string {
  if (!data.delivPostcode && !data.delivAddressZipcode) {
    return ''
  }

  const commonParts = [data.delivName, data.delivCall]
    .filter(Boolean)
    .join(', ')

  if (country === 'KR') {
    const koreanParts = [
      `(${data.delivPostcode}) ${data.delivAddress}`,
      data.delivAddressDetail,
    ]
      .filter(Boolean)
      .join(', ')
    return [commonParts, koreanParts].filter(Boolean).join('\n')
  }

  const internationalParts = [
    data.delivAddressBuilding,
    data.delivAddressStreet,
    data.delivAddressCity,
    data.delivAddressState,
    data.delivAddressZipcode,
    country,
  ]
    .filter(Boolean)
    .join(', ')
  return [commonParts, internationalParts].filter(Boolean).join('\n')
}
