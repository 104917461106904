import {
  type SetStateAction,
  atom,
  useAtom,
  useAtomValue,
  useSetAtom,
} from 'jotai'
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomType = null | {
  totalCount: number
  successCount: number
}
const BatchAlertOriginalState = atomWithReset<AtomType>(null)
BatchAlertOriginalState.debugLabel = 'BatchAlertOriginalState'

const BatchAlertState = atom(
  (get) => get(BatchAlertOriginalState),
  (_get, set, update: SetStateAction<AtomType> | typeof RESET) => {
    if (update === RESET) {
      set(BatchAlertOriginalState, RESET)
      return
    }
    if (typeof update === 'function') {
      set(BatchAlertOriginalState, (prev) => update(prev))
      return
    }
    set(BatchAlertOriginalState, update)
  }
)
BatchAlertState.debugLabel = 'BatchAlertState'

export const useBatchAlertAtom = {
  state: () => useAtom(BatchAlertState),
  get: () => useAtomValue(BatchAlertState),
  set: () => useSetAtom(BatchAlertState),
  reset: () => useResetAtom(BatchAlertState),
}
