import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { OrderTimelineFooter } from './order-timeline-footer'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { toast } from 'react-toastify'
import { orderHistoryQueryOptions } from '~/entities/order-history'
import { postOrderMemo } from '~/entities/order-memo'
import { __, __e } from '~/shared/i18n'

export function OrderTimelineFooterContainer() {
  const orderCode = useOrderCode()
  const [memo, setMemo] = useState('')
  const queryClient = useQueryClient()

  const { mutate: mutateOrderMemo, isPending } = useMutation({
    mutationFn: postOrderMemo({ orderCode }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderHistoryQueryOptions(orderCode).queryKey,
      })
      setMemo('')
      toast.success('메모를 추가했어요')
    },
    onError: (e) => {
      toast.error(
        e.response?.data?.code
          ? __e(e.response.data.code)
          : __('메모 추가에 실패했어요')
      )
    },
  })

  const handleSubmit = () => {
    mutateOrderMemo({ memo })
  }

  return (
    <OrderTimelineFooter
      memo={memo}
      handleMemoChange={setMemo}
      handleSubmit={handleSubmit}
      disabled={!memo.length}
      loading={isPending}
    />
  )
}
