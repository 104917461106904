import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { __ } from '~/shared/i18n'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { TPaymentInfoBase, usePaymentinfoModalAtom } from '.'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import { Portal } from '@imwebme/clay-components'

const PaymentInfoContent = React.lazy(() =>
  import('.').then((module) => ({ default: module.PaymentInfoContent }))
)
// ====================================== PACK
interface TPaymentInfoModalPack extends TPaymentInfoBase {}
export function PaymentInfoModalPack(props: TPaymentInfoModalPack) {
  const orderCode = useOrderCode()
  const [open, setOpen] = usePaymentinfoModalAtom.state({ orderCode })

  if (!open?.open) {
    return null
  }
  return (
    <Portal>
      <div className="z-10 w-[380px] fixed top-clay-3 right-clay-3 bottom-clay-3 rounded-clay-medium p-clay-6 pt-0 overflow-y-auto bg-clay-semantic-surface shadow-clay-pageAside">
        <ErrorBoundary fallback={ErrorCard}>
          <React.Suspense fallback={<Loading />}>
            <PaymentInfoContent {...props} />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    </Portal>
  )
}
