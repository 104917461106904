import { match } from 'ts-pattern'
import { 상수_품목보기모드 } from '../order-items.constants'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { useOrderItemsViewMode } from '../order-items.store/order-items.store'
import { OrderItemsSummary } from './order-items-summary'
import { OrderItemsAll } from './order-items-all'
import { OrderSectionDto } from '~/entities/order-detail'
import { TExternalOrderSectionDto } from '~/entities/external-order'
import {
  sortBySpecs,
  sortBySpecsExternal,
} from '~/container/order-preview/order-quantity-preview/order-quantity-preview.fn'

interface Props {
  totalQty: number
  boundaryRef?: React.RefObject<HTMLElement>
  orderCode: string
  isExternalOrder: boolean
  orderSectionList: (OrderSectionDto | TExternalOrderSectionDto)[]
}

export function OrderItems({
  orderSectionList,
  totalQty,
  boundaryRef,
  orderCode,
  isExternalOrder,
}: Props) {
  const tabCode = useTabCode()
  const viewMode = useOrderItemsViewMode(tabCode)
  const sortedOrderSectionList = isExternalOrder
    ? sortBySpecsExternal(orderSectionList as TExternalOrderSectionDto[])
    : sortBySpecs(orderSectionList as OrderSectionDto[])

  return (
    <>
      {match(viewMode)
        .with(상수_품목보기모드.요약보기, () => (
          <OrderItemsSummary
            {...{
              orderSectionList: sortedOrderSectionList,
              totalQty,
              boundaryRef,
              orderCode,
              isExternalOrder,
            }}
          />
        ))
        .with(상수_품목보기모드.모두보기, () => (
          <OrderItemsAll {...{ orderSectionList: sortedOrderSectionList }} />
        ))
        .exhaustive()}
    </>
  )
}
