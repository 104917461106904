import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TCancelRefundDoneSellerBase } from './cancel-refund-done-seller.type'
import { useCancelRefundDoneSellerAtom } from './cancel-refund-done-seller.store'
import { ContentsBanner } from '@imwebme/clay-components'

const CancelRefundDoneSeller = React.lazy(() =>
  import('./cancel-refund-done-seller').then((module) => ({
    default: React.memo(module.CancelRefundDoneSeller),
  }))
)

interface TCancelRefundDoneSellerContainer
  extends TCancelRefundDoneSellerBase {}
export function CancelRefundDoneSellerContainer(
  props: TCancelRefundDoneSellerContainer
) {
  const [open, setState] = useCancelRefundDoneSellerAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <>
      <ContentsBanner
        type="secondary"
        variant="default"
        text={__('판매자가 접수한 취소 건이며 환불이 완료되었어요.')}
        button={{ text: __('사유보기'), onClick: () => onOpenChange(true) }}
      />
      <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay />
          <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
            <CancelRefundDoneSeller {...props} />
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </>
  )
}
