import { Avatar, AvatarProps, Skeleton } from '@imwebme/clay-components'
import { useImageLoadingStatus } from '@imwebme/clay-hooks'
import { match } from 'ts-pattern'

/**
 * src가 있어도 실패하는 경우 fallback을 표시하는 클레이 Avatar 컴포넌트
 */
export function SafeAvatar({ src, ...props }: AvatarProps) {
  const loadingStatus = useImageLoadingStatus(src)
  const size = avatarSize(props.size)

  return (
    <>
      {match(loadingStatus)
        .with('loaded', () => <Avatar {...props} src={src} />)
        .with('loading', () => (
          <Skeleton
            variation="diagonal"
            width={size}
            height={size}
            rounded="full"
          />
        ))
        .otherwise(() => (
          <Avatar {...props} />
        ))}
    </>
  )
}

function avatarSize(size: AvatarProps['size']): `${number}px` {
  return match(size)
    .returnType<`${number}px`>()
    .with('xsmall', () => '24px')
    .with('small', () => '32px')
    .with('large', () => '48px')
    .with('xlarge', () => '64px')
    .with('2xlarge', () => '96px')
    .with('3xlarge', () => '120px')
    .otherwise(() => '40px')
}
