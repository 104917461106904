import React, { useEffect } from 'react'
import { useNavigate, useSearchParams, useBlocker } from 'react-router-dom'
import {
  useQuery,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { uniqWith } from 'lodash'
import { Plus } from '@imwebme/clay-icons'
import { __, __e, __g } from '~/shared/i18n'
import { Button } from '@imwebme/clay-components'
import { OrderSearchFilterForm } from './order-search-filter-form'
import { orderSearchTabQueryOptions } from '~/entities/order-search-tab'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { toast } from 'react-toastify'
import {
  useOrderSearchFilterFormHasChanged,
  useCurOrderSearchFilter,
  useResetOrderSearchFilter,
} from './order-search-filter.store'
import {
  CUSTOM_FILTER_IDX,
  postOrderSearchFilter,
} from '~/entities/order-search-filter'
import { FilterExitModal } from './modals'
import { cn } from '~/shared/utils'
import { sitePgQueryOptions } from '~/entities/site/pg'
import { bankTransferAccountsQueryOptions } from '~/entities/site/bank-transfer-accounts'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import {
  defaultFilterFormValues,
  defaultFilter,
} from './order-search-filter.constants'
import { getStoredDefaultFilterForm } from './order-search-filter.fn'

const MAX_FILTER_GROUP = 5

export const OrderSearchFilter = () => {
  const [curFilter, setCurFilter] = useCurOrderSearchFilter()
  const tabCode = useTabCode()
  const queryClient = useQueryClient()
  const formHasChanged = useOrderSearchFilterFormHasChanged.get()
  const resetOrderSearchFilter = useResetOrderSearchFilter()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [exitModal, setExitModal] = React.useState<{
    open: boolean
    callback?: () => void
  }>({ open: false })
  const filterType =
    curFilter.idx === CUSTOM_FILTER_IDX.DEFAULT ? 'default' : 'created'

  const {
    data: { apiFilterList, tabColumnCondition, allTabCode },
  } = useSuspenseQuery({
    ...orderSearchTabQueryOptions(),
    select(data) {
      return {
        apiFilterList:
          data.data.list.find((tab) => tab.adminOrderSearchTabCode === tabCode)
            ?.filters ?? [],
        tabColumnCondition:
          data.data.list.find((tab) => tab.adminOrderSearchTabCode === tabCode)
            ?.columnCondition ?? {},
        allTabCode:
          data.data.list.find((tab) => tab.name === '전체')
            ?.adminOrderSearchTabCode ?? 'all',
      }
    },
  })

  const { data: sitePgList = [] } = useQuery({
    ...sitePgQueryOptions(),
    select(data) {
      return pipe(
        data.data,
        A.map((item) => ({
          value: item.methodCd,
          label: __g(item.methodCd),
          disabled: tabColumnCondition?.pgMethod
            ? !tabColumnCondition.pgMethod.includes(item.methodCd)
            : false,
        }))
      )
    },
  })

  const { data: bankNameList = [] } = useQuery({
    ...bankTransferAccountsQueryOptions(),
    select(data) {
      return pipe(
        data,
        (bankList) => uniqWith(bankList, (a, b) => a.bankName === b.bankName),
        A.map((item) => ({
          value: item.bankName,
          label: item.bankName,
        }))
      )
    },
  })

  const { mutateAsync: mutateCreateFilter, isPending: isPendingCreateFilter } =
    useMutation({
      mutationFn: postOrderSearchFilter,
      onError: (error) => {
        if (isAxiosError(error)) {
          toast.error(
            __e(
              error.response?.data?.code || error.response?.data?.message || ''
            )
          )
        }
      },
    })

  // 현재탭의 1페이지로 이동
  const goToFirstPage = React.useCallback(() => {
    navigate(`/orders/tab/${tabCode}/1?${searchParams.toString()}`)
  }, [tabCode, searchParams])

  const handleChangeFilter = React.useCallback(
    (newFilterIdx: number) => {
      if (formHasChanged) {
        setExitModal({
          open: true,
          callback: () => {
            setCurFilter({ open: true, idx: newFilterIdx })
            goToFirstPage()
          },
        })
      } else {
        resetOrderSearchFilter()
        setCurFilter({ open: true, idx: newFilterIdx })
        goToFirstPage()
      }
    },
    [formHasChanged, goToFirstPage]
  )

  const handleAddNewFilter = React.useCallback(async () => {
    if (apiFilterList.length >= MAX_FILTER_GROUP) {
      toast.error(
        __('필터 그룹 추가는 {{count}}개까지 가능해요', {
          count: MAX_FILTER_GROUP,
        })
      )
      return
    }

    const { idx } = await mutateCreateFilter({
      tabCode,
      params: {
        name: __('새 필터 그룹 {{count}}', {
          count: Math.floor(Math.random() * 90 + 10),
        }),
        columnCondition: {},
      },
    })

    await queryClient.fetchQuery({
      queryKey: orderSearchTabQueryOptions().queryKey,
    })

    handleChangeFilter(idx)

    toast.success(__('새 필터 그룹이 추가되었어요'))
  }, [apiFilterList, handleChangeFilter])

  const handleCloseFilter = React.useCallback(() => {
    if (formHasChanged) {
      setExitModal({
        open: true,
        callback: () => {
          goToFirstPage()
        },
      })
    } else {
      resetOrderSearchFilter()
      goToFirstPage()
    }
  }, [formHasChanged, goToFirstPage])

  const initialFormValues = React.useMemo(() => {
    const filter = apiFilterList.find((f) => f.idx === curFilter.idx)
    const storedFormValues = getStoredDefaultFilterForm()

    if (!filter) {
      return storedFormValues
    }

    return {
      ...defaultFilterFormValues,
      ...(filter.columnCondition && { ...filter.columnCondition }),
      name: filter.name,
    }
  }, [apiFilterList, curFilter.idx])

  // 페이지 이탈 blocker 트리거
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    let nextTabcode: null | string = null

    // 주문목록 전체페이지, 다른탭, 탭 Pagination 변경시 nextTabcode 업데이트
    if (nextLocation.pathname === '/orders') {
      nextTabcode == allTabCode
    } else if (nextLocation.pathname.startsWith('/orders/tab/')) {
      nextTabcode = nextLocation.pathname.split('/')[3]
    }

    // 1. 기본필터인 경우
    if (filterType === 'default') {
      if (nextTabcode === null) {
        return false
      }
      return tabCode !== nextTabcode
    }

    // 2. 기본필터가 아닌 경우
    // 2-1. Pagination 허용 (폼값이 바뀔 때마다 1페이지로 이동시키기 때문에)
    if (tabCode === nextTabcode) {
      return false
    }

    // 2-2. 폼변경이 있다면 페이지이탈 block
    if (formHasChanged) {
      return currentLocation.pathname !== nextLocation.pathname
    }

    // 2-3. 이외에는 페이지이탈 허용
    return false
  })

  // 페이지 이탈 blocker 핸들링, 폼이 변경되었다면 모달을 띄우고 이외에는 필터를 초기화하고 이동
  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (formHasChanged) {
        setExitModal({ open: true })
      } else {
        resetOrderSearchFilter()
        blocker.proceed?.()
      }
    }
  }, [blocker.state, formHasChanged])

  // 어드민에서 OMS에 재진입하는 엣지케이스 핸들링
  useEffect(() => {
    if (
      apiFilterList.length > 0 &&
      curFilter.idx !== CUSTOM_FILTER_IDX.DEFAULT
    ) {
      const found = apiFilterList.find((f) => f.idx === curFilter.idx)
      if (!found) {
        resetOrderSearchFilter()
      }
    }
  }, [apiFilterList, curFilter.idx])

  return (
    <div className="p-[20px] pt-[4px]">
      <div className="flex gap-x-[4px] mb-[8px] flex-wrap">
        <ul className="flex flex-wrap gap-x-clay-1">
          {[defaultFilter, ...apiFilterList].map((filter) => (
            <li key={filter.idx}>
              <Button
                native={{ type: 'button' }}
                variant={curFilter.idx === filter.idx ? 'primary' : 'secondary'}
                size="small"
                tonal={curFilter.idx === filter.idx}
                onClick={() => handleChangeFilter(filter.idx)}
                text={filter.name}
                className={cn(
                  curFilter.idx === filter.idx &&
                    'bg-clay-semantic-actionPrimary text-white'
                )}
              />
            </li>
          ))}
        </ul>
        <Button
          native={{ type: 'button' }}
          variant="secondary"
          size="small"
          onClick={handleAddNewFilter}
          isDisabled={isPendingCreateFilter || formHasChanged}
          text={__('필터 그룹 추가')}
          leadingIcon={<Plus color="inherit" />}
        />
      </div>

      <OrderSearchFilterForm
        {...{
          tabCode,
          initialValues: initialFormValues,
          filterType,
          handleChangeFilter,
          sitePgList,
          bankNameList,
          tIsMember: tabColumnCondition?.isMember ?? undefined,
          handleCloseFilter,
          goToFirstPage,
        }}
      />

      <FilterExitModal
        isOpen={exitModal.open}
        setClose={() => {
          setExitModal({ open: false })
          blocker.reset?.()
        }}
        onConfirm={() => {
          setExitModal({ open: false })
          setTimeout(() => {
            resetOrderSearchFilter()
            exitModal.callback?.()
            blocker.proceed?.()
          }, 0)
        }}
      />
    </div>
  )
}

export default OrderSearchFilter
