import React from 'react'
import { Clay, Flex, Textfield } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { FieldLayout } from '../partials/field-layout'
import { useOrderInfoAtom, useOrderInfoIsValid } from '../order-create.store'
import { useSuspenseQuery } from '@tanstack/react-query'
import { shopConfigQueryOptions } from '~/entities/site/shop-config'
import { YN } from '~/entities/@x'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  emailRequiredSchema,
  emailNonRequiredSchema,
  TEmailRequiredSchema,
  TEmailNonRequiredSchema,
} from '../order-create.schema'

/** 구매자 정보 필드 */
export function PurchaserInfoField() {
  const [orderInfo, setOrderInfo] = useOrderInfoAtom()
  const isValid = useOrderInfoIsValid()

  const { data: shopUseNotRequireEmail } = useSuspenseQuery({
    ...shopConfigQueryOptions(),
    select(data) {
      return data.shopUseNotRequireEmail === YN.Y
    },
  })

  const {
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm<TEmailRequiredSchema | TEmailNonRequiredSchema>({
    resolver: zodResolver(
      !orderInfo._isMember && shopUseNotRequireEmail
        ? emailNonRequiredSchema
        : emailRequiredSchema
    ),
    mode: 'onTouched',
    disabled: React.useMemo(
      () => orderInfo._isMember && !orderInfo.memberCode,
      [orderInfo]
    ),
  })

  React.useEffect(() => {
    setOrderInfo({
      _shopUseNotRequireEmail: shopUseNotRequireEmail,
    })
  }, [shopUseNotRequireEmail])

  // 회원을 선택하면 setValue를 통해 유효성 검사 실행
  React.useEffect(() => {
    if (orderInfo.memberCode) {
      setValue('ordererName', orderInfo.ordererName ?? '', {
        shouldValidate: true,
      })
      setValue('ordererCall', orderInfo.ordererCall ?? '', {
        shouldValidate: true,
      })
      setValue('ordererEmail', orderInfo.ordererEmail ?? '', {
        shouldValidate: true,
      })
    }
  }, [orderInfo.memberCode])

  // 회원/비회원 여부가 바뀌면 폼에러 초기화
  React.useEffect(() => {
    reset()
  }, [orderInfo._isMember])

  // 유효성 검사 성공시 폼에러 초기화
  React.useEffect(() => {
    if (isValid) {
      reset()
    }
  }, [isValid])

  return (
    <FieldLayout title={__('구매자 정보')}>
      <Flex.Column rowGap={vars.spacing[3]}>
        <Clay
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={`${vars.spacing[5]} ${vars.spacing[25]}`}
        >
          <Textfield.Set>
            <Textfield.Legend text={__('이름')} />
            <Textfield.Box variant="outlined" size="medium">
              <Textfield.Input
                {...register('ordererName')}
                placeholder={__('입력해 주세요')}
                type="text"
                value={orderInfo.ordererName ?? ''}
                onChangeText={(ordererName) => setOrderInfo({ ordererName })}
                autoComplete="off"
                isValid={errors.ordererName ? false : undefined}
              />
            </Textfield.Box>
            <Textfield.HelperText>
              {errors.ordererName?.message}
            </Textfield.HelperText>
          </Textfield.Set>
          <Textfield.Set size="medium">
            <Textfield.Legend text={__('연락처')} />
            <Textfield.Box variant="outlined" size="medium">
              <Textfield.Input
                {...register('ordererCall')}
                placeholder={__('입력해 주세요')}
                type="tel"
                value={orderInfo.ordererCall ?? ''}
                onChangeText={(v) =>
                  setOrderInfo({ ordererCall: v.replace(/[^0-9]/g, '') })
                }
                autoComplete="off"
                isValid={errors.ordererCall ? false : undefined}
              />
            </Textfield.Box>
            <Textfield.HelperText>
              {errors.ordererCall?.message}
            </Textfield.HelperText>
          </Textfield.Set>
          <Textfield.Set size="medium" sx={{ gridColumn: '1/-1' }}>
            <Textfield.Legend text={__('이메일')} />
            <Textfield.Box variant="outlined" size="medium">
              <Textfield.Input
                {...register('ordererEmail')}
                placeholder={__('user@example.com')}
                type="email"
                value={orderInfo.ordererEmail ?? ''}
                onChangeText={(ordererEmail) => setOrderInfo({ ordererEmail })}
                autoComplete="off"
                isValid={errors.ordererEmail ? false : undefined}
              />
            </Textfield.Box>
            <Textfield.HelperText>
              {errors.ordererEmail?.message}
            </Textfield.HelperText>
          </Textfield.Set>
        </Clay>
      </Flex.Column>
    </FieldLayout>
  )
}
