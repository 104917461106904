import React from 'react'
import { TExternalDeliveryBase } from '.'

const ExternalDeliveryContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExternalDelivery),
  }))
)

interface TExternalDeliveryPack extends TExternalDeliveryBase {}
export function ExternalDeliveryPack(props: TExternalDeliveryPack) {
  return (
    <React.Suspense>
      <ExternalDeliveryContainer {...props} />
    </React.Suspense>
  )
}
