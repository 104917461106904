import React from 'react'
import { Selector } from './components'
import { __, __g } from '~/shared/i18n'
import { includes, pull, find } from 'lodash'
import { useController } from 'react-hook-form'
import { FormControlProp } from './order-search-filter-form-fields.type'
import { SitePgList } from '../order-search-filter-form'
import { T상수_결제수단 } from '~/entities/@x'
import { OptionItemCheckbox } from '@imwebme/clay-components'

interface Props extends FormControlProp {
  sitePgList: SitePgList
}

export const PaymentMethodField = ({ control, sitePgList }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const { onChange, value, ...inputProps } = useController({
    name: 'pgMethod',
    control,
  }).field

  const handleInputChange = React.useCallback(
    (item: T상수_결제수단) => {
      const copiedValue = Array.isArray(value) ? [...value] : []

      if (includes(copiedValue, item)) {
        pull(copiedValue, item)
      } else {
        copiedValue.push(item)
      }

      onChange(copiedValue.length ? copiedValue : null)
    },
    [value]
  )

  const fieldLabel = React.useMemo(() => {
    let baseLabel = __('결제수단')

    if (Array.isArray(value) && value.length) {
      const firstItem = find(sitePgList, (o) => includes(value, o.value))
      baseLabel += ': '

      if (value.length > 1) {
        baseLabel += __('{{paymentMethod}} 외 {{count}}건', {
          paymentMethod: __g(firstItem?.label ?? ''),
          count: value.length - 1,
        })
      } else {
        baseLabel += __g(firstItem?.label ?? '')
      }
    }

    return baseLabel
  }, [value, sitePgList])

  return (
    <Selector
      label={fieldLabel}
      isActive={Array.isArray(value) && !!value.length}
      resetField={() => onChange(null)}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {sitePgList.map((option) => (
            <OptionItemCheckbox
              key={option.value}
              label={option.label}
              value={option.value}
              checked={
                Array.isArray(value) && value.some((v) => v === option.value)
              }
              isDisabled={option.disabled}
              onClick={() => handleInputChange(option.value)}
              {...inputProps}
            />
          ))}
        </div>
      }
    />
  )
}
