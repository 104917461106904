import React from 'react'
import { cn } from '~/shared/utils'

export function IconFileAddSlate({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="36"
      fill="none"
      className={cn('w-auto h-[1em]', className)}
      viewBox="0 0 30 36"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#DFE4EC"
            d="M0 6a6 6 0 016-6h13.412L30 12v18a6 6 0 01-6 6H6a6 6 0 01-6-6V6z"
          ></path>
          <g>
            <mask
              id="mask0_21809_4427"
              style={{ maskType: 'alpha' }}
              width="30"
              height="36"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#48F"
                d="M0 6a6 6 0 016-6h13.412L30 12v18a6 6 0 01-6 6H6a6 6 0 01-6-6V6z"
              ></path>
            </mask>
            <g mask="url(#mask0_21809_4427)">
              <g filter="url(#filter0_f_21809_4427)">
                <path
                  fill="url(#paint0_radial_21809_4427)"
                  d="M19.365 0l.053-.001C23.542 4.651 30 11.995 30 11.995v6.989H12.78V-.001h6.584z"
                ></path>
              </g>
            </g>
          </g>
          <g filter="url(#filter1_i_21809_4427)">
            <path fill="#fff" d="M19.412 6V0L30 12h-4.588a6 6 0 01-6-6z"></path>
          </g>
          <path
            fill="#DFE4EB"
            d="M19.412 6V0L30 12h-4.588a6 6 0 01-6-6z"
          ></path>
        </g>
        <g>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M15 13a1 1 0 011 1v4h4a1 1 0 110 2h-4v4a1 1 0 11-2 0v-4h-4a1 1 0 110-2h4v-4a1 1 0 011-1z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_21809_4427"
          width="37.219"
          height="38.985"
          x="2.781"
          y="-10.001"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_21809_4427"
            stdDeviation="5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_i_21809_4427"
          width="10.688"
          height="12"
          x="19.412"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_21809_4427"
          ></feBlend>
        </filter>
        <radialGradient
          id="paint0_radial_21809_4427"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-12.91397 12.54514 -11.04837 -11.3732 26.455 5.947)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96A3B8"></stop>
          <stop offset="0.596" stopColor="#DFE4EC"></stop>
        </radialGradient>
      </defs>
    </svg>
  )
}

export function IconFile({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      className={cn('w-auto h-[1em]', className)}
      {...props}
    >
      <g filter="url(#filter0_i_12694_130028)">
        <path
          fill="#48F"
          d="M9 12a6 6 0 016-6h13.412L39 18v18a6 6 0 01-6 6H15a6 6 0 01-6-6V12z"
        ></path>
      </g>
      <g filter="url(#filter1_i_12694_130028)">
        <path fill="#87B3FF" d="M28.412 12V6L39 18h-4.588a6 6 0 01-6-6z"></path>
      </g>
      <g filter="url(#filter2_i_12694_130028)">
        <path
          fill="#F3F8FF"
          fillRule="evenodd"
          d="M24 19a1 1 0 011 1v4h4a1 1 0 110 2h-4v4a1 1 0 11-2 0v-4h-4a1 1 0 110-2h4v-4a1 1 0 011-1z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_12694_130028"
          width="30.2"
          height="36.2"
          x="9"
          y="6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.2" dy="0.2"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_12694_130028"
          ></feBlend>
        </filter>
        <filter
          id="filter1_i_12694_130028"
          width="10.688"
          height="12"
          x="28.412"
          y="6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_12694_130028"
          ></feBlend>
        </filter>
        <filter
          id="filter2_i_12694_130028"
          width="12.1"
          height="12"
          x="18"
          y="19"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_12694_130028"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}
