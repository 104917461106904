import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResNextShippingReadyDto,
  type TPatchPayloadNextShippingReadyDto,
  patchResNextShippingReadyDto,
  patchPayloadNextShippingReadyDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/MSMKQU
 * @description [next] 외부 주문서 섹션 배송대기로 변경
 */
export function patchNextShippingReady({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadNextShippingReadyDto) {
    type T = TPatchResNextShippingReadyDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/next/shipping-ready`, body)
      .then((res) => res.data)

    patchResNextShippingReadyDto.parse(result.data)
    return result
  }
}
