import React from 'react'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { __ } from '~/shared/i18n'
import { OrdersTab } from '~/container/orders/orders-tab/orders-tab'
import { OrdersHeader } from '~/container/orders/orders-header'
import { Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

export function PageLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <PageTopBar pageTitle={__('주문')} />
      <Clay
        paddingInline={vars.spacing[10]}
        className="py-clay-16 desktop:pt-0"
      >
        <OrdersHeader />
        <OrdersTab />
        <Clay
          background={vars.semantic.color.surface}
          borderRadius={vars.rounded.large}
          padding={vars.spacing[5]}
          marginTop={vars.spacing[3]}
        >
          {children}
        </Clay>
      </Clay>
    </>
  )
}
