import React from 'react'
import { Avatar, Clay, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import {
  TypeTimelineLogTypeCode,
  TOrderHistoryTimelineItem,
} from '~/entities/order-history'
import GrimIconGrayBell from './grim-icon-gray-bell.svg?react'
import { __ } from '~/shared/i18n'
import { fDate } from '~/shared/utils'
import { CustomTooltip } from '~/container/order/order-timeline/components'
import { ZodIssue } from 'zod'

export interface TimelineItemProps extends TOrderHistoryTimelineItem {
  name: string
}

export class TimelineItemRenderError extends Error {
  timelineData: TOrderHistoryTimelineItem
  errorType: 'NOT_FOUND_COMPONENT' | 'TYPE_ERROR'
  issues?: ZodIssue[]

  constructor(
    timelineData: TOrderHistoryTimelineItem,
    errorType: 'NOT_FOUND_COMPONENT' | 'TYPE_ERROR',
    issues?: ZodIssue[]
  ) {
    super('Timeline item error')
    this.timelineData = timelineData
    this.errorType = errorType
    this.issues = issues
  }
}

const timelineComponentMapper: Record<
  TypeTimelineLogTypeCode,
  | React.LazyExoticComponent<(props: TimelineItemProps) => EmotionJSX.Element>
  | undefined
> = {
  OTL01: React.lazy(() =>
    import('./item-renderer-by-case/create-order').then((mod) => ({
      default: mod.CreateOrder,
    }))
  ),
  OTL02: React.lazy(() =>
    import('./item-renderer-by-case/add-item').then((mod) => ({
      default: mod.AddItem,
    }))
  ),
  OTL03: React.lazy(() =>
    import('./item-renderer-by-case/add-delivery').then((mod) => ({
      default: mod.AddDelivery,
    }))
  ),
  OTL04: React.lazy(() =>
    import('./item-renderer-by-case/update-delivery').then((mod) => ({
      default: mod.UpdateDelivery,
    }))
  ),
  OTL05: React.lazy(() =>
    import('./item-renderer-by-case/delay-shipment').then((mod) => ({
      default: mod.DelayShipment,
    }))
  ),
  OTL06: React.lazy(() =>
    import('./item-renderer-by-case/wait-payment').then((mod) => ({
      default: mod.WaitPayment,
    }))
  ),
  OTL07: React.lazy(() =>
    import('./item-renderer-by-case/overdue-deadline-payment').then((mod) => ({
      default: mod.OverdueDeadlinePayment,
    }))
  ),
  OTL08: React.lazy(() =>
    import('./item-renderer-by-case/extension-deadline-payment').then(
      (mod) => ({
        default: mod.ExtensionDeadlinePayment,
      })
    )
  ),
  OTL09: React.lazy(() =>
    import('./item-renderer-by-case/complete-payment').then((mod) => ({
      default: mod.CompletePayment,
    }))
  ),
  OTL10: React.lazy(() =>
    import('./item-renderer-by-case/fail-refund').then((mod) => ({
      default: mod.FailRefund,
    }))
  ),
  OTL11: React.lazy(() =>
    import('./item-renderer-by-case/complete-refund').then((mod) => ({
      default: mod.CompleteRefund,
    }))
  ),
  OTL12: React.lazy(() =>
    import('./item-renderer-by-case/complete-all-ready-shipping').then(
      (mod) => ({
        default: mod.CompleteAllReadyShipping,
      })
    )
  ),
  OTL13: React.lazy(() =>
    import('./item-renderer-by-case/complete-partial-ready-shipping').then(
      (mod) => ({
        default: mod.CompletePartialReadyShipping,
      })
    )
  ),
  OTL14: React.lazy(() =>
    import('./item-renderer-by-case/revert-status-item-preparation').then(
      (mod) => ({
        default: mod.RevertStatusItemPreparation,
      })
    )
  ),
  OTL15: React.lazy(() =>
    import('./item-renderer-by-case/change-status-shipping').then((mod) => ({
      default: mod.ChangeStatusShipping,
    }))
  ),
  OTL16: React.lazy(() =>
    import('./item-renderer-by-case/revert-status-ready-shipping').then(
      (mod) => ({
        default: mod.RevertStatusReadyShipping,
      })
    )
  ),
  OTL17: React.lazy(() =>
    import('./item-renderer-by-case/change-status-complete-shipping').then(
      (mod) => ({
        default: mod.ChangeStatusCompleteShipping,
      })
    )
  ),
  OTL18: React.lazy(() =>
    import('./item-renderer-by-case/revert-status-shipping').then((mod) => ({
      default: mod.RevertStatusShipping,
    }))
  ),
  OTL19: React.lazy(() =>
    import('./item-renderer-by-case/on-hold-delivery').then((mod) => ({
      default: mod.OnHoldDelivery,
    }))
  ),
  OTL20: React.lazy(() =>
    import('./item-renderer-by-case/off-hold-delivery').then((mod) => ({
      default: mod.OffHoldDelivery,
    }))
  ),
  OTL21: React.lazy(() =>
    import('./item-renderer-by-case/create-invoice').then((mod) => ({
      default: mod.CreateInvoice,
    }))
  ),
  OTL22: React.lazy(() =>
    import('./item-renderer-by-case/update-invoice').then((mod) => ({
      default: mod.UpdateInvoice,
    }))
  ),
  OTL23: React.lazy(() =>
    import('./item-renderer-by-case/delete-invoice').then((mod) => ({
      default: mod.DeleteInvoice,
    }))
  ),
  OTL24: React.lazy(() =>
    import('./item-renderer-by-case/request-cancel').then((mod) => ({
      default: mod.RequestCancel,
    }))
  ),
  OTL25: React.lazy(() =>
    import('./item-renderer-by-case/withdraw-cancel').then((mod) => ({
      default: mod.WithdrawCancel,
    }))
  ),
  OTL26: React.lazy(() =>
    import('./item-renderer-by-case/reject-cancel').then((mod) => ({
      default: mod.RejectCancel,
    }))
  ),
  OTL27: React.lazy(() =>
    import('./item-renderer-by-case/complete-all-cancel').then((mod) => ({
      default: mod.CompleteAllCancel,
    }))
  ),
  OTL28: React.lazy(() =>
    import('./item-renderer-by-case/complete-partial-cancel').then((mod) => ({
      default: mod.CompletePartialCancel,
    }))
  ),
  OTL29: React.lazy(() =>
    import('./item-renderer-by-case/revert-request-cancel').then((mod) => ({
      default: mod.RevertRequestCancel,
    }))
  ),
  OTL30: React.lazy(() =>
    import('./item-renderer-by-case/request-return').then((mod) => ({
      default: mod.RequestReturn,
    }))
  ),
  OTL31: React.lazy(() =>
    import('./item-renderer-by-case/request-retrieve').then((mod) => ({
      default: mod.RequestRetrieve,
    }))
  ),
  OTL32: React.lazy(() =>
    import('./item-renderer-by-case/update-request-return').then((mod) => ({
      default: mod.AddDelivery,
    }))
  ),
  OTL33: React.lazy(() =>
    import('./item-renderer-by-case/reject-return').then((mod) => ({
      default: mod.RejectReturn,
    }))
  ),
  OTL34: React.lazy(() =>
    import('./item-renderer-by-case/withdraw-return').then((mod) => ({
      default: mod.WithdrawReturn,
    }))
  ),
  OTL35: React.lazy(() =>
    import('./item-renderer-by-case/complete-all-return').then((mod) => ({
      default: mod.CompleteAllReturn,
    }))
  ),
  OTL36: React.lazy(() =>
    import('./item-renderer-by-case/complete-partial-return').then((mod) => ({
      default: mod.CompletePartialReturn,
    }))
  ),
  OTL37: React.lazy(() =>
    import('./item-renderer-by-case/revert-request-return').then((mod) => ({
      default: mod.RevertRequestReturn,
    }))
  ),
  OTL38: React.lazy(() =>
    import('./item-renderer-by-case/request-payment-cash-receipt').then(
      (mod) => ({
        default: mod.RequestPaymentCashReceipt,
      })
    )
  ),
  OTL39: React.lazy(() =>
    import('./item-renderer-by-case/request-payment-cash-receipt').then(
      (mod) => ({
        default: mod.RequestPaymentCashReceipt,
      })
    )
  ),
  OTL40: React.lazy(() =>
    import('./item-renderer-by-case/process-cash-receipt').then((mod) => ({
      default: mod.ProcessCashReceipt,
    }))
  ),
  OTL41: React.lazy(() =>
    import('./item-renderer-by-case/process-manual-cash-receipt').then(
      (mod) => ({
        default: mod.ProcessManualCashReceipt,
      })
    )
  ),
  OTL42: React.lazy(() =>
    import('./item-renderer-by-case/revert-process-manual-cash-receipt').then(
      (mod) => ({
        default: mod.RevertProcessManualCashReceipt,
      })
    )
  ),
  OTL43: React.lazy(() =>
    import('./item-renderer-by-case/fail-process-cash-receipt').then((mod) => ({
      default: mod.FailProcessCashReceipt,
    }))
  ),
  OTL44: React.lazy(() =>
    import('./item-renderer-by-case/revert-wait-deposit').then((mod) => ({
      default: mod.RevertWaitDeposit,
    }))
  ),
  OTL45: React.lazy(() =>
    import('./item-renderer-by-case/request-retrieve-complete').then((mod) => ({
      default: mod.RequestRetrieveComplete,
    }))
  ),
  OTL46: React.lazy(() =>
    import('./item-renderer-by-case/update-request-cash-receipt').then(
      (mod) => ({
        default: mod.UpdateRequestCashReceipt,
      })
    )
  ),
  OTL47: React.lazy(() =>
    import('./item-renderer-by-case/update-section-delivery').then((mod) => ({
      default: mod.UpdateSectionDelivery,
    }))
  ),
  OTL48: React.lazy(() =>
    import('./item-renderer-by-case/delete-delivery').then((mod) => ({
      default: mod.DeleteDelivery,
    }))
  ),
  OTL49: React.lazy(() =>
    import('./item-renderer-by-case/update-request-cancel-reason').then(
      (mod) => ({
        default: mod.AddDelivery,
      })
    )
  ),
  OTL50: React.lazy(() =>
    import('./item-renderer-by-case/cancel-unpaid-deposit-order').then(
      (mod) => ({
        default: mod.CancelUnpaidDepositOrder,
      })
    )
  ),
  OTL51: React.lazy(() =>
    import('./item-renderer-by-case/change-status-confirm').then((mod) => ({
      default: mod.ChangeStatusConfirm,
    }))
  ),
  OTL52: React.lazy(() =>
    import('./item-renderer-by-case/split-order-section').then((mod) => ({
      default: mod.splitOrderSection,
    }))
  ),
  OTL53: React.lazy(() =>
    import('./item-renderer-by-case/split-order-section').then((mod) => ({
      default: mod.splitOrderSection,
    }))
  ),
  OTL54: React.lazy(() =>
    import('./item-renderer-by-case/merge-order-section').then((mod) => ({
      default: mod.mergeOrderSection,
    }))
  ),
  OTL55: React.lazy(() =>
    import('./item-renderer-by-case/complete-return-for-exchange').then(
      (mod) => ({
        default: mod.CompleteReturnForExchange,
      })
    )
  ),
  OTL56: React.lazy(() =>
    import('./item-renderer-by-case/complete-partial-return-for-exchange').then(
      (mod) => ({
        default: mod.CompletePartialReturnForExchange,
      })
    )
  ),
  OTL57: React.lazy(() =>
    import('./item-renderer-by-case/switch-to-return-request').then((mod) => ({
      default: mod.SwitchToReturnRequest,
    }))
  ),
  OTL58: React.lazy(() =>
    import('./item-renderer-by-case/switch-to-exchange-request').then(
      (mod) => ({
        default: mod.SwitchToExchangeRequest,
      })
    )
  ),
  OTL59: React.lazy(() =>
    import('./item-renderer-by-case/return-holdback-release').then((mod) => ({
      default: mod.ReturnHoldbackRelease,
    }))
  ),
  OTL60: React.lazy(() =>
    import('./item-renderer-by-case/exchange-holdback-release').then((mod) => ({
      default: mod.ExchangeHoldbackRelease,
    }))
  ),
}

export const OrderTimelineItem = ({
  profileImgUrl,
  wtime,
  ...props
}: TimelineItemProps & { profileImgUrl: string | null; wtime: string }) => {
  const Component = timelineComponentMapper[props.logTypeCd]

  if (!Component) {
    throw new TimelineItemRenderError(props, 'NOT_FOUND_COMPONENT')
  }

  return (
    <Flex gap={vars.spacing[2]} paddingBottom={vars.spacing[5]}>
      {props.name === __('시스템') || props.name === __('외부시스템') ? (
        <Clay
          background={vars.semantic.color.surface}
          height="fit-content"
          position="relative"
        >
          <GrimIconGrayBell />
        </Clay>
      ) : (
        <Avatar size="xsmall" src={profileImgUrl ?? ''} />
      )}

      <Clay>
        <Component {...props} />

        <CustomTooltip
          trigger={
            <Typography
              as="time"
              variant="body-small-bold"
              colorToken="textSub"
              sx={{
                marginTop: vars.spacing[2],
                display: 'block',
              }}
            >
              {fDate(new Date(wtime), 'a h:mm')}
            </Typography>
          }
          label={fDate(new Date(wtime), 'yyyy년 M월 d일 a h:m')}
        />
      </Clay>
    </Flex>
  )
}
