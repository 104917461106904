import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  Textarea,
} from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { OrderMemoPreviewWriteFormModalProps } from './order-memo-preview-write-form-create-modal.container'

export const OrderMemoPreviewWriteFormModal = ({
  handleModalClose,
  handleSubmitOrderMemo,
  modalTitle,
  textAreaValue,
  setTextAreaValue,
}: OrderMemoPreviewWriteFormModalProps) => (
  <Modal width="small" isOpen setClose={() => {}}>
    <Modal.Header text={modalTitle} type="leftAlign" />
    <Modal.Body>
      <Textarea
        height="default"
        placeholder={__('입력해 주세요.')}
        characterCount
        maxLength={1000}
        value={textAreaValue}
        onChange={setTextAreaValue}
      />
    </Modal.Body>
    <Modal.Footer>
      <Flex.Column alignItems="flex-end">
        <ButtonGroup size="medium" isInline>
          <Button
            fullWidth
            onClick={handleModalClose}
            text={__('취소')}
            variant="secondary"
          />
          <Button
            fullWidth
            onClick={handleSubmitOrderMemo}
            text={__('저장')}
            variant="primary"
          />
        </ButtonGroup>
      </Flex.Column>
    </Modal.Footer>
  </Modal>
)
