import React from 'react'
import { Selector } from './components'
import { __, __c, __g } from '~/shared/i18n'
import { includes, pull, find } from 'lodash'
import { useController } from 'react-hook-form'
import { FormControlProp } from './order-search-filter-form-fields.type'
import { T상수_배송타입, 상수_배송타입 } from '~/entities/@x'
import { OptionItemCheckbox } from '@imwebme/clay-components'

interface Props extends FormControlProp {}

export const DeliveryTypeCdField = ({ control }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const { onChange, value, ...inputProps } = useController({
    name: 'deliveryTypeCd',
    control,
  }).field

  const handleInputChange = React.useCallback(
    (item: T상수_배송타입) => {
      const copiedValue = Array.isArray(value) ? [...value] : []

      if (includes(copiedValue, item)) {
        pull(copiedValue, item)
      } else {
        copiedValue.push(item)
      }

      onChange(copiedValue.length ? copiedValue : null)
    },
    [value]
  )

  const fieldLabel = React.useMemo(() => {
    let baseLabel = __('배송방식')

    if (Array.isArray(value) && value.length) {
      const firstItem = find(상수_배송타입, (o) => includes(value, o))
      baseLabel += ': '

      if (value.length > 1) {
        baseLabel += __('{{deliveryTypeCd}} 외 {{count}}건', {
          deliveryTypeCd: __g(firstItem ?? ''),
          count: value.length - 1,
        })
      } else {
        baseLabel += __g(firstItem ?? '')
      }
    }

    return baseLabel
  }, [value, 상수_배송타입])

  return (
    <Selector
      label={fieldLabel}
      isActive={Array.isArray(value) && !!value.length}
      resetField={() => onChange(null)}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {Object.values(상수_배송타입)
            .filter(
              (option) =>
                ![
                  상수_배송타입.다운로드.toString(),
                  상수_배송타입.정기구독.toString(),
                  상수_배송타입.편의점배송.toString(),
                ].includes(option)
            )
            .map((option, idx) => (
              <OptionItemCheckbox
                key={idx}
                label={__g(option)}
                value={option}
                checked={
                  Array.isArray(value) && value.some((v) => v === option)
                }
                onClick={() => handleInputChange(option)}
                {...inputProps}
              />
            ))}
        </div>
      }
    />
  )
}
