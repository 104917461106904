import {
  Clay,
  Flex,
  Skeleton,
  IconButton,
  Textfield,
  ButtonGroup,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SearchSM, Filter, Columns02, Settings } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'

export function OrdersTableSkeleton() {
  const [orderSearchQuery] = useOrderSearchQuery()
  return (
    <Clay
      paddingTop={vars.spacing[5]}
      paddingBottom={vars.spacing[6]}
      borderRadius={vars.rounded.large}
      background={vars.semantic.color.surface}
    >
      {/* 테이블 헤더 */}
      <Flex
        justifyContent="space-between"
        gap={vars.spacing[5]}
        paddingInline={vars.spacing[6]}
        paddingBottom={vars.spacing[4]}
      >
        <Skeleton width="90px" height="28px" rounded="medium" />

        <Flex gap={vars.spacing[3]}>
          <Textfield.Box variant="filled" size="medium" sx={{ width: '280px' }}>
            <Textfield.Addon>
              <SearchSM colorToken="icon-sub" />
            </Textfield.Addon>
            <Textfield.Input
              placeholder={__('이름, 연락처, 주문번호, 송장번호')}
              value={orderSearchQuery}
              readOnly
            />
          </Textfield.Box>
          <ButtonGroup isInline>
            <IconButton variant="secondary" icon={<Filter />} />
            <IconButton variant="secondary" icon={<Columns02 />} />
            <IconButton variant="secondary" icon={<Settings />} />
          </ButtonGroup>
        </Flex>
      </Flex>

      {/* 테이블 바디 */}
      <OrdersTableBodySkeleton />
    </Clay>
  )
}

function OrdersTableBodySkeleton() {
  return (
    <>
      {/* 테이블 헤드 */}
      <Flex
        paddingInline={vars.spacing[4]}
        height="44px"
        maxWidth="100%"
        overflow="hidden"
      >
        <Clay
          flexShrink={0}
          padding={`${vars.spacing[3]} ${vars.spacing[2]}`}
          width="404px"
        >
          <Skeleton width="80px" height="20px" rounded="full" />
        </Clay>
        {Array.from({ length: 13 }).map((_, idx) => (
          <TableCell key={idx} />
        ))}
      </Flex>

      {/* 테이블 로우 */}
      {Array.from({ length: 8 }).map((_, idx) => (
        <Flex
          key={idx}
          paddingInline={vars.spacing[4]}
          height="44px"
          maxWidth="100%"
          overflow="hidden"
        >
          <Clay
            flexShrink={0}
            padding={`${vars.spacing[3]} ${vars.spacing[2]}`}
            width="404px"
          >
            <Skeleton width="388px" height="20px" rounded="full" />
          </Clay>
          {Array.from({ length: 13 }).map((_v, _idx) => (
            <TableCell key={_idx} />
          ))}
        </Flex>
      ))}
    </>
  )
}

function TableCell() {
  return (
    <Clay
      flexShrink={0}
      padding={`${vars.spacing[3]} ${vars.spacing[2]}`}
      width="140px"
    >
      <Skeleton width="80px" height="20px" rounded="full" float="right" />
    </Clay>
  )
}
