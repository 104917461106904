import { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResRefundDto,
  type TPostPayloadRefundDto,
  postResRefundDto,
  postPayloadRefundDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/6KM7nE
 * @description 환불 예정금액 환불 처리
 */
export function postRefund() {
  return async function (body: TPostPayloadRefundDto) {
    type R = typeof body
    type T = TPostResRefundDto
    const result = await axios
      .post<T, AxiosResponse<ApiResponse<T>>, R>('/payment/refund', body)
      .then((res) => res.data)

    try {
      postResRefundDto.parse(result.data)
    } catch (error) {
      Sentry.captureException(error)
    }
    return result
  }
}
