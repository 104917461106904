import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TBankTransferRefundBase, useBankTransferRefundAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ErrorCard } from '~/shared/components/error'

const BankTransferRefundContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.BankTransferRefund),
  }))
)

interface TBankTransferRefundPack extends TBankTransferRefundBase {}
export function BankTransferRefundPack(props: TBankTransferRefundPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useBankTransferRefundAtom.state({
    atomKey: props.atomKey,
    orderCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] px-0 gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<>{'...loading'}</>}>
              <BankTransferRefundContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
