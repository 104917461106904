import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import { type TGetResSitePgSchema, getResSitePgSchema } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description site-pg
 */
export async function getSitePg() {
  const result = await axios
    .get<ApiResponse<TGetResSitePgSchema>>('/site/pg')
    .then((res) => res.data)

  getResSitePgSchema.parse(result.data)
  return result
}
