import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionStatusValidateAllDto,
  type TPostPayloadBulkActionSectionStatusValidateAllDto,
  postResBulkActionSectionStatusValidateAllSchema,
} from '.'
import { TBulkActionSectionStatusApply } from '../bulk-action-section-status-apply'

const axios = ApiClient()

export async function postBulkActionSectionStatusValidateAll<
  T = TPostResBulkActionSectionStatusValidateAllDto,
  R = TPostPayloadBulkActionSectionStatusValidateAllDto,
>({ body, status }: { body: R; status: TBulkActionSectionStatusApply }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >(`/order/bulk-action/section/${status}/validate/all`, body)
    .then((res) => res.data)

  postResBulkActionSectionStatusValidateAllSchema.parse(result.data)
  return result.data
}
