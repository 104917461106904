/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'

/**
 * post order-delivery-goodsflow
 */
export const postResOrderDeliveryGoodsflowSchema = z.object({
  data: z.object({
    goodsflowPopUpUrl: z.string(),
  }),
})
export const postPayloadOrderDeliveryGoodsflowSchema = z.object({
  orderSectionCode: z.string(),
  goodsflowCenterCode: z.string(),
})
