import deepEqual from 'fast-deep-equal'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Funnel, FunnelProps, Step, StepProps } from './funnel'
import { NonEmptyArray, assert } from './libs'

interface SetStepOptions {
  stepChangeType?: 'push' | 'replace'
  preserveQuery?: boolean
  query?: Record<string, unknown>
}

type RouteFunnelProps<Steps extends NonEmptyArray<string>> = Omit<
  FunnelProps<Steps>,
  'steps' | 'step'
>

type FunnelComponent<Steps extends NonEmptyArray<string>> = ((
  props: RouteFunnelProps<Steps>
) => JSX.Element) & {
  Step: (
    props: StepProps<Steps> & {
      key?: string
    }
  ) => JSX.Element
}

export const useFunnel = <Steps extends NonEmptyArray<string>>(
  steps: Steps,
  options?: {
    initialStep?: Steps[number]
    onStepChange?: (name: Steps[number]) => void
  }
): readonly [
  FunnelComponent<Steps>,
  (step: Steps[number], options?: SetStepOptions) => void,
] & {
  withState: <
    StateExcludeStep extends Record<string, unknown> & { step?: never },
  >(
    initialState: StateExcludeStep
  ) => [
    FunnelComponent<Steps>,
    StateExcludeStep & { step?: Steps[number] },
    (
      next:
        | Partial<StateExcludeStep & { step: Steps[number] }>
        | ((
            next: Partial<StateExcludeStep & { step: Steps[number] }>
          ) => StateExcludeStep & { step: Steps[number] })
    ) => void,
  ]
} => {
  const [currentStep, setCurrentStep] = useState(
    options?.initialStep ?? steps[0]
  )
  assert(steps.length > 0, 'steps가 비어있습니다.')

  const FunnelComponent = useMemo(
    () =>
      Object.assign(
        (props: RouteFunnelProps<Steps>) => {
          const step = options?.initialStep ?? currentStep
          assert(step != null, '표시할 스텝이 null입니다.')

          return <Funnel<Steps> steps={steps} step={step} {...props} />
        },
        {
          Step,
        }
      ),
    [currentStep]
  )

  const setStep = useCallback(
    (_step: Steps[number], _setStepOptions?: SetStepOptions) => {
      setCurrentStep(_step)
    },
    [options]
  )

  /**
   * 아래부터 withState() 구현입니다.
   * 외부 함수로 분리하기 어려워 당장은 inline 해둡니다.
   * FIXME: withState() 구현을 외부 함수로 분리하기
   */

  type S = Record<string, unknown>
  const [state, _setState] = useFunnelState<S>({})
  type Step = Steps[number]
  type NextState = S & { step?: Step }

  const nextPendingStepRef = useRef<Step | null>(null)
  const nextStateRef = useRef<Partial<S> | null>(null)
  const setState = useCallback(
    (next: Partial<NextState> | ((next: Partial<NextState>) => NextState)) => {
      let nextStepValue: Partial<NextState>
      if (typeof next === 'function') {
        nextStepValue = next(state)
      } else {
        nextStepValue = next
      }

      if (nextStepValue.step != null) {
        nextPendingStepRef.current = nextStepValue.step
      }
      nextStateRef.current = nextStepValue

      _setState(next)
    },
    [_setState, state]
  )

  useEffect(() => {
    if (nextPendingStepRef.current == null) {
      return
    }
    if (deepEqual(nextStateRef.current, state)) {
      setStep(nextPendingStepRef.current)
      nextPendingStepRef.current = null
    }
  }, [setStep, state])

  const initializedRef = useRef(false)
  function withState<State extends Record<string, unknown>>(
    initialState: State
  ) {
    if (!initializedRef.current) {
      setState(initialState)
      initializedRef.current = true
    }
    return [FunnelComponent, state, setState] as const
  }

  return Object.assign([FunnelComponent, setStep] as const, {
    withState,
  }) as unknown as readonly [
    FunnelComponent<Steps>,
    (step: Steps[number], options?: SetStepOptions) => Promise<void>,
  ] & {
    withState: <
      StateExcludeStep extends Record<string, unknown> & { step?: never },
    >(
      initialState: StateExcludeStep
    ) => [
      FunnelComponent<Steps>,
      StateExcludeStep & { step?: Steps[number] },
      (
        next:
          | Partial<StateExcludeStep & { step: Steps[number] }>
          | ((
              next: Partial<StateExcludeStep & { step: Steps[number] }>
            ) => StateExcludeStep & { step: Steps[number] })
      ) => void,
    ]
  }
}

function useFunnelState<T extends Record<string, unknown>>(
  defaultValue: Partial<T>
) {
  const [_state, _setState] = useState<Partial<T>>(defaultValue)

  const clearState = useCallback(() => {
    _setState({})
  }, [])

  return [_state, _setState, clearState] as const
}
