import { pipe, flow } from 'fp-ts/function'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { YN, 상수_현금영수증_상태, 상수_현금영수증_수단 } from '~/entities/@x'
import { 모델_현금영수증 } from '.'

export const 현금영수증: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => !receipt)
)

export const 의무발행: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.isRequire === YN.Y)
)

export const 구매자신청: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.isCustomer === YN.Y)
)

export const 미발급: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.statusCd === 상수_현금영수증_상태.미발행)
)

export const 처리대기: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.statusCd === 상수_현금영수증_상태.미발행)
)

export const 발급완료: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.statusCd === 상수_현금영수증_상태.발행완료)
)

export const 발급실패: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.statusCd === 상수_현금영수증_상태.발행실패)
)

export const 발행전취소: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.statusCd === 상수_현금영수증_상태.발행전_취소)
)

export const 연동발급: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.methodTypeCd === 상수_현금영수증_수단.PG연동)
)

/**
 * 환경설정에 현금영수증 자동발행
 * PG연동일떄 + 뱅크다 사용일때
 */
export const 자동발급: ISpecification<모델_현금영수증> = new Spec(
  flow(
    ({ receipt }) =>
      receipt?.methodTypeCd === 상수_현금영수증_수단.PG연동 &&
      Boolean(receipt.memberCode)
  )
)

export const 수동발급: ISpecification<모델_현금영수증> = new Spec(
  flow(({ receipt }) => receipt?.methodTypeCd === 상수_현금영수증_수단.수동)
)
