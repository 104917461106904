import { match, P } from 'ts-pattern'
import { Tag, TagProps } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  상수_외부채널주문_채널상태,
  T상수_외부채널주문_채널상태,
} from '~/entities/@x'
import { __g } from '~/shared/i18n'
import { StatusTagBaseProps } from './status-tag.type'

/**
 * 외부채널 상태 태그
 * @param toggleDimmed - plain variant일 때 배경색을 변경할지 여부
 */
export function ExternalChannelStatusTag({
  statusCd,
  size,
  toggleDimmed,
}: StatusTagBaseProps<T상수_외부채널주문_채널상태>) {
  if (!statusCd) {
    return null
  }

  const variant = match(statusCd)
    .returnType<TagProps['variant']>()
    .with(
      P.union(
        상수_외부채널주문_채널상태.입금대기,
        상수_외부채널주문_채널상태.결제완료,
        상수_외부채널주문_채널상태.배송준비중,
        상수_외부채널주문_채널상태.배송중,
        상수_외부채널주문_채널상태.취소처리중,
        상수_외부채널주문_채널상태.취소철회,
        상수_외부채널주문_채널상태.반품철회,
        상수_외부채널주문_채널상태.수거처리중,
        상수_외부채널주문_채널상태.수거완료,
        상수_외부채널주문_채널상태.교환철회,
        상수_외부채널주문_채널상태.교환재배송대기,
        상수_외부채널주문_채널상태.교환재배송중,
        상수_외부채널주문_채널상태.직권취소처리중,
        상수_외부채널주문_채널상태.직권취소처리완료,
        상수_외부채널주문_채널상태.수거완료_교환재배송처리중
      ),
      () => 'warning'
    )
    .with(
      P.union(
        상수_외부채널주문_채널상태.취소요청,
        상수_외부채널주문_채널상태.반품요청,
        상수_외부채널주문_채널상태.교환요청
      ),
      () => 'critical'
    )
    .otherwise(() => 'plain')

  const shouldToggleColor = !!toggleDimmed && variant === 'plain'

  return (
    <Tag
      variant={shouldToggleColor ? 'other' : variant}
      text={__g(statusCd)}
      size={size}
      sx={
        shouldToggleColor
          ? {
              '--clay-tag-background-color': vars.semantic.color.surface,
              '--clay-tag-color': vars.semantic.color.textSub,
            }
          : undefined
      }
    />
  )
}
