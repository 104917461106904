import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import { FormProvider, useForm } from 'react-hook-form'
import { DevTool } from '~/app/provider/react-hook-form'

import { Button } from '@imwebme/clay-components'
import { productSearchItemRCOResolver } from './schema'
import type {
  TOption,
  TOptionDetails,
} from '../../product-search/product-search.type'
import type { payload } from './product-search-item-rco.type'
import { ProductSearchOptionItemRNCO } from './product-search-item-rnco-option'
import { ProductSearchOptionItemRCO } from './product-search-item-rco-option'
import { __ } from '~/shared/i18n'

export interface T_ProductSearchItemRCO {
  id: string
  productPrice: string
  options: TOption[]
  optionDetails: TOptionDetails[]
  values?: payload[]
  setValues: (e: payload[]) => void
}
export function ProductSearchItemRCO({
  id,
  productPrice,
  options,
  optionDetails,
  values,
  setValues,
}: T_ProductSearchItemRCO) {
  const method = useForm<payload>({
    resolver: productSearchItemRCOResolver,
  })
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = method

  const onSubmit = (e: payload) => {
    const sumPrice = pipe(
      [...(values || []), e],
      A.map(
        flow(
          R.map((r) => Number(r.price || 0)),
          Object.values
        )
      ),
      A.flatten,
      A.reduce(0, (acc, cur) => acc + cur),
      (r) => r + productPrice
    )

    // reset
    pipe(
      e.options,
      R.mapWithIndex((k, v) =>
        setValue(`options.${k}`, {
          optionCode: v.optionCode,
          optionName: v.optionName,
          optionValueCode: '',
          optionValueName: v.optionValueName,
          optionValue: '',
          price: v.price,
          type: v.type,
        })
      )
    )
    pipe(
      e.optionDetails,
      R.mapWithIndex((k) =>
        setValue(`optionDetails.${k}`, {
          isRequiredOption: '',
          optionDetailCode: '',
          optionDetailPrice: 0,
          options: [],
        })
      )
    )

    // set
    setValues([...(values || []), e])
  }

  return (
    <FormProvider {...method}>
      <div className="flex flex-col mb-2 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inline-block w-full">
            <div className="flex flex-col gap-[12px]">
              <div className="semantic-p14">{__('옵션 구성')}</div>
              <ProductSearchOptionItemRCO values={optionDetails} id={id} />
            </div>
          </div>
          <div className="inline-block w-full mt-[12px]">
            <div className="flex flex-col gap-[12px]">
              {pipe(
                options,
                A.map((e) => (
                  <div key={e.optionCode}>
                    <div className="semantic-p14">{e.optionName}</div>
                    <div className="mt-[6px]">
                      <ProductSearchOptionItemRNCO {...e} />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="mt-[12px]">
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              tonal
              fullWidth
              text={__('목록에 추가')}
            />
          </div>
        </form>
      </div>
      <DevTool control={method.control} />
    </FormProvider>
  )
}
