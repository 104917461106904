import React from 'react'
import { useRequiredOptionAtom } from './required-option.store'

export const RequiredOptionContainer = ({
  searchId,
  prodCode,
  optionCode,
  children,
}: {
  prodCode: string
  optionCode: string
  searchId: string
  children: (props: {
    checked: boolean
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
  }) => React.ReactElement
}) => {
  const [values, setValues] = useRequiredOptionAtom.option(
    searchId,
    prodCode,
    optionCode
  )
  if (values === undefined) {
    return null
  }
  return (
    <div>
      {children({
        checked: !!values.checked,
        setChecked: () =>
          setValues({
            ...values,
            checked: !values.checked,
          }),
      })}
    </div>
  )
}
