import { ApiClient, ApiResponse } from '~/shared/api'
import type { TGetResSiteClaimsSchema } from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/4LJIL9
 * @description 등록되어있는 취소/반품 사유 리스트
 */
export async function getSiteClaims({
  claimType,
  unit_code,
}: {
  claimType: 'cancel' | 'return'
  unit_code: string
}) {
  const result = await axios
    .get<ApiResponse<TGetResSiteClaimsSchema>>(`/site/claims/${claimType}`, {
      params: {
        unitCode: unit_code,
      },
    })
    .then((res) => res.data)

  return result
}
