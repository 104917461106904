import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPatchResReturnReasonEditSchema,
  TPatchPayloadReturnReasonEditSchema,
} from './return-reason-edit.type'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/NZUUnW
 * @description 취소접수 사유 수정
 */
export async function patchReturnReasonEdit({
  body,
  orderCode,
  sectionCode,
}: {
  body: TPatchPayloadReturnReasonEditSchema
  orderCode: string
  sectionCode: string
}) {
  type T = TPatchResReturnReasonEditSchema
  type R = typeof body
  const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
    `/order/${orderCode}/sections/${sectionCode}/return-reason`,
    body
  )

  return result
}
