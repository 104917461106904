import { SearchSM } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import React, { ButtonHTMLAttributes, useEffect } from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/lib/Either'
import {
  Button,
  OptionItem,
  OptionList,
  Textfield,
} from '@imwebme/clay-components'
import { CSSTransition } from 'react-transition-group'
import type { TSheetItem } from './export-template-selection-search.type'
import { TargetPortal } from '~/shared/components/target-portal'

function dynamicHeight(height: number, maxHeight = 300): number {
  // maxheight값이 500px이고 height값이 500px보다 크면 max-height: 500px
  return height > maxHeight ? maxHeight : height
}

const searchFilterItemRegex = (query: string) => (name: string) =>
  name
    .toLowerCase()
    .replace(/\s+/g, '')
    .includes(query.toLowerCase().replace(/\s+/g, ''))
const filteredItem = (query: string) => (item: TSheetItem) =>
  query === '' ? true : searchFilterItemRegex(query)(item.name)

function ListButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className="w-full text-left px-[12px] py-[8px] hover:bg-clay-semantic-actionSecondaryHover rounded-[8px]"
    />
  )
}

export function ExportTemplateSelectionSearch({
  sheet,
  onSelected,
}: {
  sheet: TSheetItem[]
  onSelected?: (item: TSheetItem[]) => void
}) {
  const [query, setQuery] = React.useState<string | undefined>(undefined)
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  function onDropdownClose() {
    setDropdownOpen(false)
    // 포커스 아웃되고 검색을 끝내는것이라서 검색어를 초기화
    setQuery(undefined)
    // input에 포커스를 제거 inputRef를 사용
    inputRef.current?.blur()
  }
  function onDropdownOpen() {
    setDropdownOpen(true)
  }

  function onOutsideClick() {
    // onFocus가 true일 때만 동작
    if (dropdownOpen) {
      onDropdownClose()
    }
  }

  function onFocus() {
    onDropdownOpen()
  }

  function onClickItem(item: TSheetItem) {
    onSelected?.([item])
    onDropdownClose()
  }
  function onClickAllItem(items: TSheetItem[]) {
    onSelected?.(items)
    onDropdownClose()
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onDropdownClose()
      }
    }
    // dropdownOpen이 true이면 esc키를 눌렀을 때 dropdown을 닫는다. 이벤트를 등록
    if (dropdownOpen) {
      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
    // dropdownOpen이 false이면 이벤트를 해제
    if (!dropdownOpen) {
      // keydown에 이벤트가 등록되어있으면 해제
      window.removeEventListener('keydown', handleKeyDown)
      return () => void {}
    }
    return () => void {}
  }, [dropdownOpen])

  const ref = React.useRef<HTMLDivElement>(null)
  return (
    <div className="relative" ref={ref}>
      <TargetPortal
        open={dropdownOpen}
        onOutsideClick={onOutsideClick}
        className="z-[10000] pointer-events-auto"
        container={ref.current}
      >
        <TargetPortal.Slot name="trigger">
          <div className="relative">
            <Textfield.Box variant="outlined">
              <Textfield.Addon>
                <SearchSM />
              </Textfield.Addon>
              <Textfield.Input
                placeholder={__('항목명')}
                onFocus={onFocus}
                type="search"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                ref={inputRef}
              />
            </Textfield.Box>
          </div>
        </TargetPortal.Slot>
        <TargetPortal.Slot name="content">
          {({ height }) => (
            <CSSTransition in={dropdownOpen} timeout={0} unmountOnExit>
              <div
                style={{ height: dynamicHeight(height) - 60 + 'px' }}
                className="mt-[8px] w-full bg-white _drop-shadow rounded-[8px] overflow-x-hidden overflow-y-scroll z-[49] pointer-events-auto"
              >
                <div className="py-[8px]">
                  <OptionList>
                    {pipe(
                      query,
                      (e) => {
                        if (query === undefined) {
                          return E.left(e)
                        }
                        const _aosdkos =
                          searchFilterItemRegex(query)('전체추가')
                        return _aosdkos ? E.left(undefined) : E.right(undefined)
                      },
                      E.fold(
                        () => (
                          <li className="px-clay-3">
                            <OptionItem
                              text={__('전체추가')}
                              onClick={() => onClickAllItem(sheet)}
                            />
                          </li>
                        ),
                        () => null
                      )
                    )}
                    {pipe(
                      sheet,
                      A.filter(filteredItem(query ?? '')),
                      A.map((item) => (
                        <li
                          className="px-clay-3 typo-clay-label-medium"
                          key={item.id}
                        >
                          <OptionItem
                            text={item.name}
                            isDisabled={item.disabled}
                            onClick={() => onClickItem(item)}
                          />
                        </li>
                      ))
                    )}
                  </OptionList>
                </div>
              </div>
            </CSSTransition>
          )}
        </TargetPortal.Slot>
      </TargetPortal>
    </div>
  )
}
