import React from 'react'
import { IconArrowLeft } from '@imwebme/icon'
import {
  HydrateSectionAtom,
  OrderEditCalcCard,
  useOrderSectionAtom,
  type TProductCard001,
} from '~t'
import { toast } from 'react-toastify'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useAtomValue } from 'jotai'
import { OrderCancelBannerAtom } from '~/container/order/order-cancel/order-cancel.store'
import { Trans } from 'react-i18next'
import { useCalc } from '~/container/order/order-edit/order-edit-fn'
import OrderSectionContainerOverlay from './order-cancel-section'
import { __, useLL } from '~/shared/i18n'
import { useNavigate } from 'react-router-dom'
import { Link } from '~/shared/components/link'
import { useParams } from 'react-router-dom'
import { OrderEditHeader } from '~t/order-edit/order-edit-header'
import { OrderTimelinePack } from '../../order-timeline'
import { OrderHeaderStep } from '~t/order-edit/order-edit-header/partials/order-header-step'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { Button } from '@imwebme/clay-components'
import { useCurrency } from '~/shared/hooks/use-currency'
import { 상수_판매채널 } from '~/entities/@x'

function OrderCancelCalcCard() {
  const currency = useCurrency()
  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price: useCalc(),
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b className="typo-clay-heading-large-bold" /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}

function Header({
  orderNo,
  onClickNext,
}: {
  orderNo: string
  onClickNext: () => void
}) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode"
          params={{
            orderCode,
            saleChannel: pipe(상수_판매채널.아임웹, String),
          }}
          className="flex gap-x-[4px] items-center"
        >
          <IconArrowLeft className="stroke-[2]" />
          <span>{orderNo}</span>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('취소')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: '품목 선택',
              active: true,
            },
            {
              id: '2',
              label: '취소 정보 입력',
              active: false,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <Button
          native={{ type: 'button' }}
          variant="primary"
          onClick={onClickNext}
          text={__('다음')}
        />
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

const OrderCancelBanner = ({ id }: { id: string }) => {
  const orderCancelBannerValue = useAtomValue(OrderCancelBannerAtom({ id }))
  if (orderCancelBannerValue && orderCancelBannerValue.component) {
    return (
      <div className="mb-[16px]">
        <div>{orderCancelBannerValue.component}</div>
      </div>
    )
  }
  return null
}
interface OrderCancelProps {
  readyItems: {
    id: string
    label: string
    value: TProductCard001.props[]
    statusCd: string
  }[]
  orderNo: number
}
const OrderCancel = ({ readyItems, orderNo }: OrderCancelProps) => {
  const router = useNavigate()
  const location = useLL()
  const query = useParams()
  const orderCode = query.orderCode
  if (orderCode === undefined) {
    // 오더코드가 없으면 안되는 페이지에서 오더코드가 없다.
    throw new Error('🚀 ~ file: order-cancel.tsx:79 ~ OrderCancel')
  }
  const updateItems = useOrderSectionAtom.valueUpdateAll()

  // TODO: router > LLink 마이그레이션
  const onClickNextMemo = () => {
    updateItems && updateItems.length > 0
      ? router(`${location.pathname}/info`)
      : toast.error(__('취소한 상품이 없습니다'))
  }

  return (
    <HydrateSectionAtom initialValues={readyItems}>
      <Header orderNo={String(orderNo)} onClickNext={onClickNextMemo} />
      <div className="mt-[32px] mb-[70px] min-w-[992px] overflow-auto">
        <div className="w-[970px] mx-auto">
          <OrderCancelBanner id={orderCode} />
          <div className="grid lg:grid-cols-[1fr,minmax(310px,auto)] gap-x-[20px]">
            <div className="grid gap-y-[20px]">
              {pipe(
                readyItems,
                O.fromNullable,
                O.fold(
                  () => undefined,
                  flow(
                    A.mapWithIndex((index, readyItem) => (
                      <div key={`OrderSectionContainerOverlay-${index}`}>
                        <OrderSectionContainerOverlay
                          id={readyItem.id}
                          label={readyItem.label}
                          items={readyItem.value}
                          statusCd={readyItem.statusCd}
                        />
                      </div>
                    ))
                  )
                )
              )}
            </div>
            <div>
              <OrderCancelCalcCard />
            </div>
          </div>
        </div>
      </div>
    </HydrateSectionAtom>
  )
}

export default OrderCancel
