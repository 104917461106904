import { z } from 'zod'
import { 상수_섹션상태 } from '~/entities/@x'
import { targetItemInformationDto } from '../_shared/target-item-information'

export const resStatusBackDto = z.object({
  updateResult: z.boolean(),
})

export const payloadStatusBackDto = z.object({
  targetItemInformation: z.array(targetItemInformationDto),
  expectedStatusCd: z.nativeEnum(상수_섹션상태).nullish(),
})
