import { ApiClient } from '~/shared/api'
import { type TGetResProductListDto, type TGetPayloadProductListDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 상품 목록 조회
 */
export async function getProductList(params: TGetPayloadProductListDto) {
  const result = await axios
    .get<ApiResponse<TGetResProductListDto>>('/product', { params })
    .then((res) => res.data)

  return result.data
}
