import { IconFileDownload02 } from '@imwebme/icon'
import type { TExcelUploadTemplateBase } from './excel-upload-template.type'
import { __ } from '~/shared/i18n'
import { Spinner } from '@imwebme/clay-components'

interface TExcelUploadTemplate extends TExcelUploadTemplateBase {
  count: number
  onClick: VoidFunction
  disabled?: boolean
  isLoading?: boolean
}
export function ExcelUploadTemplate({
  count,
  onClick,
  disabled,
  isLoading,
}: TExcelUploadTemplate) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="flex gap-x-[4px] items-center typo-clay-label-medium text-clay-semantic-textPrimary hover:text-clay-semantic-textPrimaryHover disabled:text-clay-semantic-textDisabled"
    >
      {__('{{count}}건 엑셀 양식 받기', {
        count,
      })}{' '}
      {isLoading ? (
        <Spinner
          bgColor="white"
          lineColor="var(--semantic-text-disabled)"
          speed="2s"
        />
      ) : (
        <IconFileDownload02 className="stroke-[1.5]" />
      )}
    </button>
  )
}
