import { vars } from '@imwebme/clay-token'
import { Clay, TextButton, Typography } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { ArrowPrev } from '@imwebme/clay-icons'
import { Link, LocalLink } from '~/shared/components/link'
import { useOrderDetailHeaderGoBack } from '~/container/order/order-detail/partials/order-detail-header/order-detail-header.store'

export function PageHeader() {
  const goBack = useOrderDetailHeaderGoBack.get()

  return (
    <Clay marginTop={vars.spacing[8]} marginBottom={vars.spacing[6]}>
      {goBack ? (
        <LocalLink to={goBack}>
          <OrdersLinkButton />
        </LocalLink>
      ) : (
        <Link to="/orders">
          <OrdersLinkButton />
        </Link>
      )}
      <Typography
        variant="heading-3xlarge-bold"
        sx={{ marginTop: vars.spacing[3] }}
      >
        {__('주문 생성')}
      </Typography>
    </Clay>
  )
}

function OrdersLinkButton() {
  return (
    <TextButton
      as="div"
      text={__('주문 목록')}
      variant="secondary"
      leadingIcon={<ArrowPrev />}
    />
  )
}
