/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import {
  patchPayloadBackProductPreparationDto,
  targetItemInformationDto,
} from '.'

export const patchPayloadBackProductPreparationRdo =
  patchPayloadBackProductPreparationDto.extend({
    targetItemInformation: z.array(
      targetItemInformationDto.extend({
        _check: z.boolean().optional(),
      })
    ),
  })
