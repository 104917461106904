import { IconChevronDown } from '@imwebme/icon'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { Select } from '~/shared/components/ui/select'
import { Textfield } from '@imwebme/clay-components'
import { useFormContext } from 'react-hook-form'
import { TOption } from '../../product-search/product-search.type'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

/**
 * 필수비조합형
 */
export function ProductSearchOptionItemSI({
  optionCode,
  optionName,
  type,
  values,
}: TOption) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  if ((type === 'default' && values) || (type === 'color' && values)) {
    return (
      <>
        <input type="hidden" {...register(optionCode)} />
        <Select.Root
          value={watch(optionCode)?.optionValueCode}
          onValueChange={(e) => {
            setValue(optionCode, {
              type,
              // optionCode를 왜 넣어주는지 옵션코드는
              // API에서 받는 값이 다이나믹하기 때문에 option코드를 넘겨주고
              // 해당 값을 통해서 필드를 리셋하기 위한 용도로 사용한다.
              optionCode,
              optionName,
              optionValueCode: e,
              optionValueName: values.find((r) => r.valueCode === e)?.valueName,
              price: pipe(values.find((r) => r.valueCode === e)?.price),
            })
          }}
        >
          <Select.Trigger
            className={cn(
              'border-solid',
              errors[optionCode] && 'border-red-500'
            )}
          >
            <Select.Value placeholder={__('옵션을 선택해주세요')} />
            <IconChevronDown className="stroke-[2]" />
          </Select.Trigger>
          <Select.Content>
            <Select.Group>
              {pipe(
                values,
                O.fromNullable,
                O.fold(
                  () => null,
                  flow(
                    A.map((r) => (
                      <Select.Item key={r.valueCode} value={r.valueCode}>
                        {r.valueName}
                      </Select.Item>
                    ))
                  )
                )
              )}
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </>
    )
  }
  if (type === 'input') {
    return (
      <>
        <input type="hidden" {...register(optionCode)} />
        <Textfield
          value={watch(optionCode)?.optionValue}
          onChange={(e) => {
            setValue(optionCode, {
              type,
              optionCode,
              optionName,
              optionValueCode: optionCode,
              optionValueName: optionName,
              optionValue: e.target.value,
            })
          }}
          placeholder={__('옵션을 입력해주세요')}
          isValid={errors[optionCode] ? false : undefined}
        />
      </>
    )
  }
  // debug
  // eslint-disable-next-line no-console
  log.error(
    '🚀 ~ file: product-search-item-si.tsx:103 ~ ProductSearchOptionItemSI ~ 옵션타입이 없습니다'
  )
  return null
}
