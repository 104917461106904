import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TContainerProps } from './order-memo-preview.container'
import { Skeleton, DropdownTriggerBtn } from '../partials'
import { ErrorCard } from '~/shared/components/error'
import { match } from 'ts-pattern'

const OrderMemoPreviewContainer = React.lazy(() =>
  import('./order-memo-preview.container').then((module) => ({
    default: module.OrderMemoPreviewContainer,
  }))
)

const ExternalOrderMemoPreviewContainer = React.lazy(() =>
  import('./external-order-memo-preview.container').then((module) => ({
    default: module.ExternalOrderMemoPreviewContainer,
  }))
)

interface TPackProps extends TContainerProps {
  isExternalOrder: boolean
  children: React.ReactNode
}

export function OrderMemoPreviewPack({
  isExternalOrder,
  children,
  ...props
}: TPackProps) {
  const [open, setOpen] = React.useState(false)

  if (!children) {
    return null
  }
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownTriggerBtn {...{ setOpen }}>{children}</DropdownTriggerBtn>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={8}
          align="start"
          onCloseAutoFocus={(evt) => evt.preventDefault()}
          className="w-[240px] bg-clay-semantic-surface rounded-clay-medium shadow-clay-layer h-fit py-clay-2"
          hideWhenDetached
          collisionPadding={12}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Skeleton />}>
              {match(isExternalOrder)
                .with(true, () => (
                  <ExternalOrderMemoPreviewContainer {...props} />
                ))
                .with(false, () => <OrderMemoPreviewContainer {...props} />)
                .exhaustive()}
            </React.Suspense>
          </ErrorBoundary>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
