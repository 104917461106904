import { z } from 'zod'

export const 상수_섹션상태 = {
  상품준비: 'OSS01',
  배송대기: 'OSS02',
  배송중: 'OSS03',
  배송완료: 'OSS04',
  구매확정: 'OSS05',
  취소접수: 'OSS06',
  취소완료: 'OSS07',
  반품접수: 'OSS08',
  회수지시: 'OSS09',
  회수중: 'OSS10',
  상품확인중: 'OSS11',
  반품완료: 'OSS12',
} as const

export const 상수_섹션상태필터 = {
  배송보류: 'OSS51',
  교환접수: 'OSS52',
  교환완료: 'OSS53',
  수거완료: 'OSS54',
} as const

const type = z.nativeEnum(상수_섹션상태)
export type T상수_섹션상태 = z.infer<typeof type>

export const 상수_섹션상태통합 = {
  배송전: 'BEFORE_DELIVERY',
  출고완료: 'SHIPPED',
  부분출고: 'PARTIAL_SHIPPED',
  부분취소: 'PARTIAL_CANCEL',
  부분반품: 'PARTIAL_RETURN',
  부분취소반품: 'PARTIAL_CANCEL_RETURN',
  전체취소반품: 'ALL_CANCEL_RETURN',
} as const

export type T상수_섹션상태통합 =
  (typeof 상수_섹션상태통합)[keyof typeof 상수_섹션상태통합]
