import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import * as E from 'fp-ts/lib/Either'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import {
  OrderEditCalcCard,
  OrderEditHeader,
  OrderHeaderStep,
  OrderSection,
  ProductCard002Template,
} from '~t'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import {
  IconArrowLeft,
  IconChevronDown,
  IconWarningTriangle,
} from '@imwebme/icon'
import { OrderTimelinePack } from '~/container/order/order-timeline'
import { Button } from '@imwebme/clay-components'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useCurrency } from '~/shared/hooks/use-currency'
import { Trans } from 'react-i18next'
import React from 'react'
import {
  convertObjectClaims2Array,
  externalClaimsQueryOptions,
} from '~/entities/site/external-claims'
import { ErrorCard } from '~/shared/components/error'
import { useSearchParams } from 'react-router-dom'
import { cn } from '~/shared/utils'
import { Select } from '~/shared/components/ui/select'
import { X } from '@imwebme/clay-icons'
import {
  TPatchPayloadReturnRequestRdo,
  patchPayloadReturnRequestRdo,
} from '~/entities/external-order-section/return-request'
import { ExternalOrder2ReturnRequest } from '~/entities/external-order-section/return-request/return-request.calc'
import { 상수_수거타입, 상수_판매채널 } from '~/entities/@x'
import { ReturnRetrieveTypeCd } from './partials/return-retrieve-type-cd'
import { GoodsflowPartnersSelect } from '~/widgets/goodsflow-partners-select'
import { goodsflowQueryOptions, goodsflowStatus } from '~/entities/goodsflow'
import {
  ReturnRequestModalPack,
  useReturnRequestModalAtom,
} from '~/entities/external-order-section/return-request/modal'
import { zodResolver } from '@hookform/resolvers/zod'
import { IsValid } from '~/shared/calc/is-valid'
import { params2Order } from '../_shared/params-to-order'
import { totalPrice } from '../_shared/total-price'

export function ExternalOrderReturnInfo() {
  const [searchParams] = useSearchParams()
  const queryList = [...searchParams]
  const sectionItemCodes = pipe(
    queryList,
    A.findFirst((e) => e[0] === 'data'),
    O.getOrElseW(() => null)
  )
  return (
    <>
      <Header />
      <div className="mt-[32px] h-full min-w-[992px] overflow-auto">
        <div className="w-[970px] mx-auto">
          {sectionItemCodes ? (
            <Body />
          ) : (
            <ErrorCard isDebug={false} showButtons={false}>
              <ErrorCard.Slot name="message">
                <div>{__('품목을 불러오지 못했어요')}</div>
              </ErrorCard.Slot>
            </ErrorCard>
          )}
        </div>
      </div>
    </>
  )
}

export function useFormContextExternalOrderReturnInfo() {
  const form = useFormContext<TPatchPayloadReturnRequestRdo>()
  return form
}

function Body() {
  const [searchParams] = useSearchParams()
  const queryList = [...searchParams]
  const currency = useCurrency({ isExternal: true })
  const orderCode = useOrderCode()
  const {
    data: { selectItemData, defaultValues },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      return {
        selectItemData: pipe(e.data, params2Order(queryList)),
        defaultValues: pipe(
          e.data,
          params2Order(queryList),
          ExternalOrder2ReturnRequest
        ),
      }
    },
  })

  const { data: goodsflowList } = useSuspenseQuery({
    ...goodsflowQueryOptions(),
    select: flow(
      (e) => e.data,
      A.filter((e) => e.status === goodsflowStatus.Y)
    ),
  })
  const isGoodsflowList = goodsflowList.length > 0

  const methods = useForm({
    resolver: zodResolver(patchPayloadReturnRequestRdo),
    defaultValues: {
      ...defaultValues,
      retrieveRequestDto: {
        retrieveTypeCd: isGoodsflowList
          ? 상수_수거타입.자동수거신청
          : 상수_수거타입.구매자발송,
      },
    },
    mode: 'all',
  })

  const {
    handleSubmit,
    formState: { errors },
    trigger,
  } = methods

  React.useEffect(() => {
    trigger()
  }, [trigger])

  const onModal = useReturnRequestModalAtom.set({
    orderCode,
  })

  return (
    <FormProvider {...methods}>
      <div className="grid lg:grid-cols-[1fr,310px] gap-x-[20px]">
        <div className="grid gap-y-[20px]">
          <OrderSection name="반품정보입력">
            <OrderSection.Slot
              name="title"
              className="typo-clay-heading-xlarge-bold"
            >
              {__('반품 정보')}
            </OrderSection.Slot>
            <OrderSection.Slot name="body">
              <div className="grid grid-cols-[145px,1fr] gap-x-[20px] gap-y-[16px]">
                <div className="semantic-h6-bold">{__('품목')}</div>
                <div className="space-y-clay-5 mb-clay-6">
                  {pipe(
                    selectItemData!,
                    A.mapWithIndex((index, section) => (
                      <React.Fragment key={section.orderSectionCode + index}>
                        {pipe(
                          section.orderSectionItemList,
                          O.fromNullable,
                          O.fold(
                            () => null,
                            flow(
                              A.map((item) => (
                                <React.Fragment
                                  key={
                                    section.orderSectionCode +
                                    item.orderSectionItemCode
                                  }
                                >
                                  <li className="grid grid-cols-[1fr,auto]">
                                    <div>
                                      <ProductCard002Template
                                        id={item.orderSectionItemCode}
                                        thumbnail={item.imageUrl ?? ''}
                                        name={item.prodName}
                                        option={pipe(
                                          item.optionInfo,
                                          O.fromNullable,
                                          O.fold(
                                            () => '',
                                            flow(
                                              A.map(
                                                (e) => e.key + ' ' + e.value
                                              ),
                                              (e) => e.join(', ')
                                            )
                                          )
                                        )}
                                        price={item.itemPrice}
                                        currency={currency}
                                      />
                                    </div>
                                    <div>
                                      <div>
                                        <Trans
                                          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
                                          values={{
                                            price:
                                              (item.qty as number) *
                                              (item.itemPrice ||
                                                item.baseItemPrice ||
                                                0),
                                            formatParams: {
                                              currency: {
                                                currency,
                                              },
                                            },
                                          }}
                                          components={{ b: <b /> }}
                                        />
                                      </div>
                                      <div className="typo-clay-body-small text-right text-clay-semantic-textSub flex items-center">
                                        <Trans
                                          i18nKey="{{price, 3comma}}{{currency, currency}}"
                                          values={{
                                            price: item.itemPrice,
                                            formatParams: {
                                              currency: {
                                                currency,
                                              },
                                            },
                                          }}
                                        />
                                        <X />
                                        {item.qty}
                                      </div>
                                    </div>
                                  </li>
                                </React.Fragment>
                              ))
                            )
                          )
                        )}
                      </React.Fragment>
                    ))
                  )}
                </div>
                <div className="semantic-h6-bold">{__('수거정보')}</div>
                <div className="grid gap-y-[12px]">
                  <div>
                    <ReturnRetrieveTypeCd />
                  </div>
                </div>
                <GoodsflowPartnersSelect />
                <div className="semantic-h6-bold">{__('사유')}</div>
                <div className="grid gap-y-[12px]">
                  <div>
                    <ReturnReasonCdSelect />
                  </div>
                  <ReturnReasonDetail />
                </div>
              </div>
            </OrderSection.Slot>
          </OrderSection>
        </div>
        <div>
          <div className="flex flex-col gap-[16px]">
            <TotalCard />
            <Button
              native={{ type: 'button' }}
              variant="primary"
              isDisabled={pipe(errors, R.isEmpty, (e) => !e)}
              text={__('반품 접수')}
              fullWidth
              onClick={() => onModal(true)}
            />
          </div>
        </div>
      </div>
      <ReturnRequestModalPack
        onSubmit={(mutate) =>
          handleSubmit((body) => {
            match(body)
              .with(
                {
                  targetSectionInformation: P.nonNullable,
                  returnReasonCode: P.nonNullable,
                },
                ({ returnReasonDetail, ...rest }) => {
                  const result = pipe({
                    ...rest,
                    ...(returnReasonDetail !== ''
                      ? {
                          returnReasonDetail,
                        }
                      : {}),
                  })
                  mutate(result)
                }
              )
              .otherwise(() => {
                throw new Error('invalid body')
              })
          })()
        }
      />
    </FormProvider>
  )
}

function Header() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const result = pipe(e.data)
      return result
    },
  })

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          to="/order/:saleChannel/:orderCode/return/select"
          params={{ orderCode, saleChannel: String(data.saleChannelIdx) }}
        >
          <div className="flex gap-x-[4px] items-center">
            <IconArrowLeft className="stroke-[2]" />
            <span>{data.orderNo}</span>
          </div>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('반품')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: __('품목 선택'),
              active: false,
            },
            {
              id: '2',
              label: __('반품 정보 입력'),
              active: true,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <div className="space-x-[8px]">
          <Link
            to="/order/:saleChannel/:orderCode/return/select"
            params={{ orderCode, saleChannel: String(data.saleChannelIdx) }}
          >
            <Button as="div" variant="secondary" text={__('이전')} />
          </Link>
          <Button
            native={{ type: 'submit' }}
            variant="primary"
            isDisabled
            text={__('다음')}
          />
        </div>
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

function TotalCard() {
  const [searchParams] = useSearchParams()
  const queryList = [...searchParams]
  const sectionItemCodes = pipe(
    queryList,
    A.findFirst((e) => e[0] === 'items'),
    O.map(
      flow(
        (e) => e[1],
        (e) => atob(e),
        (e) => e.split(',')
      )
    ),
    O.getOrElseW(() => null)
  )
  // 섹션들로 필터링해도 다른섹션에 동일한 아이템코드가있어도 해당 품목 자체를 반품하는 것이라 상관없다
  const sectionCodes = pipe(
    queryList,
    A.findFirst((e) => e[0] === 'sections'),
    O.map(
      flow(
        (e) => e[1],
        (e) => atob(e),
        (e) => e.split(',')
      )
    ),
    O.getOrElseW(() => null)
  )
  const currency = useCurrency({ isExternal: true })
  const orderCode = useOrderCode()
  const { data: price } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select: flow((e) => e.data, params2Order(queryList), totalPrice),
  })

  return (
    <OrderEditCalcCard>
      <OrderEditCalcCard.Slot name="title">
        {__('예상 환불 금액')}
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="price">
        <Trans
          i18nKey="<b>{{price, 3comma}}</b> {{currency, currency}}"
          values={{
            price,
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ b: <b className="typo-clay-heading-large-bold" /> }}
        />
      </OrderEditCalcCard.Slot>
      <OrderEditCalcCard.Slot name="description">
        <div>
          <Trans>
            상품판매가 기준의 합산 금액이에요.
            <br />
            환불금액 설정은 환불 계산 페이지에서
            <br />
            설정 가능해요.
          </Trans>
        </div>
      </OrderEditCalcCard.Slot>
    </OrderEditCalcCard>
  )
}

function ReturnReasonCdSelect() {
  const { control } = useFormContextExternalOrderReturnInfo()
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(externalOrderQueryOptions({ orderCode }))
  const { data: orderData } = useSuspenseQuery({
    ...externalClaimsQueryOptions({
      saleChannelIdx: data.saleChannelIdx,
      claimType: 'return',
    }),
    select(e) {
      const result = pipe(
        e,
        convertObjectClaims2Array,
        (e2) => {
          if (data.saleChannelIdx === 상수_판매채널.톡체크아웃) {
            return E.left(e2)
          }
          return E.right(e2)
        },
        E.fold(
          flow(A.filter((e2) => e2.responsibility === '판매자')),
          (e2) => e2
        )
      )
      return result
    },
  })

  return (
    <Controller
      control={control}
      name="returnReasonCode"
      render={({ field: { onChange, ref }, fieldState }) => (
        <div>
          <Select.Root onValueChange={onChange}>
            <Select.Trigger
              className={cn(
                'w-full relative border-solid',
                IsValid(fieldState) &&
                  'bg-clay-semantic-surfaceCritical border-none'
              )}
              ref={ref}
            >
              <Select.Value placeholder={__('사유를 선택해주세요')} />
              {IsValid(fieldState) && (
                <IconWarningTriangle
                  width={16}
                  height={16}
                  className="text-clay-semantic-iconCritical stroke-[2] absolute right-[36px] top-1/2 -translate-y-1/2"
                />
              )}
              <IconChevronDown className="stroke-[2]" />
            </Select.Trigger>
            <Select.Content>
              <Select.Group>
                {pipe(
                  orderData,
                  A.map(({ key, description, responsibility }) => (
                    <Select.Item value={key} key={key}>
                      ({responsibility}) {description}
                    </Select.Item>
                  ))
                )}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </div>
      )}
    />
  )
}

function ReturnReasonDetail() {
  const { control } = useFormContextExternalOrderReturnInfo()
  return (
    <Controller
      control={control}
      name="returnReasonDetail"
      render={({ field, fieldState: { error } }) => (
        <div>
          <textarea
            placeholder={__('상세 사유를 입력해주세요')}
            className={cn(
              error && 'focus:ring-red-500',
              'flex h-20 w-full rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900'
            )}
            value={field.value ?? ''}
            onChange={(e) => field.onChange(e.target.value)}
          />
        </div>
      )}
    />
  )
}
