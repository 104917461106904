import React, { useEffect, useRef, useState } from 'react'
import { Selector } from './components'
import { __ } from '~/shared/i18n'
import { includes, find } from 'lodash'
import { useController } from 'react-hook-form'
import { FormControlProp } from './order-search-filter-form-fields.type'
import {
  Modal,
  ButtonGroup,
  Flex,
  Clay,
  Textfield,
  Typography,
  Checkbox,
  Spinner,
  SafeImageLoading,
} from '@imwebme/clay-components'
import { SearchSM } from '@imwebme/clay-icons'
import { Button } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getProduct } from '~/container/product-search/product-search-api'
import { flow, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { TItem } from '~t'
import ProductThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { Virtuoso } from 'react-virtuoso'

interface Props extends FormControlProp {}

//  검색 효과를 주기 위해
const useDebounce = (
  value: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  delay = 500
) => {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value)
      setIsLoading(false)
    }, delay)
    return () => clearTimeout(handler)
  }, [value, delay])

  return debounced
}

export const ProductField = ({ control }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const { onChange, value } = useController({
    name: 'prodCode',
    control,
  }).field
  const [selectProdItem, setSelectProdItem] = useState<string[]>(
    value?.length ? value : []
  )
  const [keyword, setKeyword] = useState('')
  const isDisabled = selectProdItem.length === 0
  const addButtonText = isDisabled
    ? '상품을 선택해 주세요'
    : `${selectProdItem.length}개 상품 추가`
  const [isLoading, setIsLoading] = useState(false)
  const debouncedKeyword = useDebounce(keyword, setIsLoading, 500)

  // 상품 목록 조회
  const { data, isSuccess, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['product-search', encodeURIComponent(debouncedKeyword)],
      queryFn: ({ pageParam }) =>
        getProduct({
          query: debouncedKeyword,
          cursor: pageParam === '' ? undefined : pageParam,
        }),
      getNextPageParam: (page) => page.cursorForNext,
      getPreviousPageParam: (page) => page.cursorForPrev,
      initialPageParam: '',
    })

  // 선택된 상품코드
  const handleSelectProdItem = (prodCode: string) => {
    const index = selectProdItem.indexOf(prodCode)

    if (index !== -1) {
      setSelectProdItem(selectProdItem.filter((_, i) => i !== index))
    } else {
      setSelectProdItem([...selectProdItem, prodCode])
    }
  }

  const items = React.useMemo(() => {
    const result = pipe(
      data?.pages,
      O.fromNullable,
      O.fold(
        () => [],
        flow(
          (e) => e,
          A.map((e) => e.list),
          A.flatten,
          A.map((e) => ({
            ...e,
            options: e.options?.length > 0 ? e.options : undefined,
            optionDetails:
              e.optionDetails?.length > 0 ? e.optionDetails : undefined,
          })),
          (e) => e
        )
      )
    )
    return result
  }, [data?.pages])

  const fieldLabel =
    React.useMemo(() => {
      let baseLabel = __('상품검색')

      if (Array.isArray(value) && value.length) {
        const selectedItem = find(items, (o) => includes(value, o.prodCode))
        baseLabel += ': '

        if (!selectedItem) {
          return
        }

        if (value.length > 1) {
          baseLabel += __('{{prodName}} 외 {{count}}건', {
            prodName: selectedItem.prodName,
            count: value.length - 1,
          })
        } else {
          baseLabel += selectedItem.prodName
        }
      }

      return baseLabel
    }, [value, items]) ?? __('상품검색')

  const handleSubmitModal = () => {
    onChange(selectProdItem)
    setKeyword('')
    setDropdownOpen(false)
  }

  const handleCancelModal = () => {
    setSelectProdItem(value?.length ? value : [])
    setKeyword('')
    setDropdownOpen(false)
  }

  return (
    <>
      <Selector
        label={fieldLabel}
        isActive={Array.isArray(value) && !!value.length}
        resetField={() => onChange(null)}
        setDropdownOpen={setDropdownOpen}
      />

      <Modal
        isOpen={dropdownOpen}
        setClose={() => setDropdownOpen(false)}
        width="medium"
      >
        <Modal.Header text="상품 검색" type="leftAlign" />
        <Modal.Body>
          <Flex.Column gap="16px">
            <ProductSearchInput
              handleChangeSearchInput={setKeyword}
              setIsLoading={setIsLoading}
            />
            <ProductSearchList
              selectProdItem={isSuccess ? selectProdItem : []}
              handleSelectProdItem={handleSelectProdItem}
              keyword={debouncedKeyword}
              data={items}
              isLoading={isLoading}
              onInViewCallback={fetchNextPage}
              onInViewDisabled={!hasNextPage || isFetchingNextPage}
            />
          </Flex.Column>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button
              native={{ type: 'button' }}
              fullWidth
              onClick={handleCancelModal}
              text="취소"
              variant="outlined"
            />
            <Button
              native={{ type: 'button' }}
              fullWidth
              onClick={handleSubmitModal}
              text={addButtonText}
              variant="primary"
              isDisabled={isDisabled}
            />
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const ProductSearchInput = ({
  handleChangeSearchInput,
  setIsLoading,
}: {
  handleChangeSearchInput: (searchText: string) => void
  setIsLoading: (value: boolean) => void
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }, [])

  return (
    <Clay>
      <Textfield.Box variant="secondary" size="large">
        <Textfield.Addon>
          <SearchSM />
        </Textfield.Addon>
        <Textfield.Input
          placeholder="상품명을 검색해 보세요"
          onChangeText={(value) => {
            handleChangeSearchInput(value)
            setIsLoading(true)
          }}
          ref={inputRef}
        />
      </Textfield.Box>
    </Clay>
  )
}

const ProductSearchList = ({
  selectProdItem,
  handleSelectProdItem,
  keyword,
  data,
  isLoading,
  onInViewCallback,
  onInViewDisabled,
}: {
  selectProdItem: string[]
  handleSelectProdItem: (prodCode: string) => void
  keyword: string
  data: TItem[]
  isLoading: boolean
  onInViewCallback?: () => void
  onInViewDisabled?: boolean
}) => {
  if (isLoading) {
    return <LoadingSearch />
  }

  if (data.length === 0) {
    if (keyword === '') {
      return (
        <Flex
          padding="20px 24px"
          alignItems="center"
          justifyContent="center"
          minHeight="376px"
        >
          <Flex.Column
            gap={vars.spacing[5]}
            paddingBottom={vars.spacing[7]}
            alignItems="center"
            justifyContent="center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
            >
              <path
                d="M52.3711 51.75L44.1211 43.5"
                stroke="#BCC0C6"
                strokeWidth="6"
                strokeLinecap="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.5 49.5C40.098 49.5 49.5 40.098 49.5 28.5C49.5 16.902 40.098 7.5 28.5 7.5C16.902 7.5 7.5 16.902 7.5 28.5C7.5 40.098 16.902 49.5 28.5 49.5ZM28.5 43.5C36.7843 43.5 43.5 36.7843 43.5 28.5C43.5 20.2158 36.7843 13.5 28.5 13.5C20.2158 13.5 13.5 20.2158 13.5 28.5C13.5 36.7843 20.2158 43.5 28.5 43.5Z"
                fill="#BCC0C6"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.6819 61.0608C59.9245 62.8182 57.2153 62.9583 55.6309 61.3737L45.0311 50.7741C44.4454 50.1882 44.4454 49.2385 45.0311 48.6526L49.2737 44.41C49.8595 43.8243 50.8093 43.8243 51.395 44.41L61.9948 55.0098C63.5794 56.5944 63.4393 59.3035 61.6819 61.0608Z"
                fill="#E2E5E9"
              />
            </svg>
            <Typography>상품을 검색해 보세요</Typography>
          </Flex.Column>
        </Flex>
      )
    } else {
      return (
        <Flex
          padding="20px 24px"
          alignItems="center"
          justifyContent="center"
          minHeight="376px"
        >
          <Flex.Column
            gap={vars.spacing[5]}
            paddingBottom={vars.spacing[7]}
            alignItems="center"
            justifyContent="center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
            >
              <path
                d="M13.5 18C13.5 13.0294 17.5294 9 22.5 9H42.6177L58.5 27V54C58.5 58.9706 54.4706 63 49.5 63H22.5C17.5294 63 13.5 58.9706 13.5 54V18Z"
                fill="#BCC0C6"
              />
              <path
                d="M42.6177 18V9L58.5 27H51.6177C46.6471 27 42.6177 22.9705 42.6177 18Z"
                fill="#E2E5E9"
              />
            </svg>
            <Flex.Column gap={vars.spacing[2]} alignItems="center">
              <Typography>검색어와 일치하는 상품이 없어요</Typography>
              <Typography variant="body-small" colorToken="textSub">
                검색어를 확인해 주세요
              </Typography>
            </Flex.Column>
          </Flex.Column>
        </Flex>
      )
    }
  }

  return (
    <Flex.Column
      height="464px"
      overflowY="auto"
      paddingLeft="8px"
      paddingRight="8px"
    >
      <Virtuoso
        className="h-full"
        data={data}
        endReached={() => {
          if (onInViewDisabled) {
            return
          }
          onInViewCallback?.()
        }}
        itemContent={(_, item) => (
          <Flex
            key={item.prodCode}
            paddingTop="10px"
            paddingBottom="10px"
            width="100%"
          >
            <Checkbox.Label label={item.prodName} sx={{ gap: vars.spacing[3] }}>
              <Flex gap={vars.spacing[2]} alignItems="center">
                <Checkbox
                  onChangeChecked={() => {
                    handleSelectProdItem(item.prodCode)
                  }}
                  checked={selectProdItem.includes(item.prodCode)}
                />
                <ProductSearchListItemThumbnail
                  thumbnailUrl={item.imageUrls[0]}
                />
              </Flex>
            </Checkbox.Label>
          </Flex>
        )}
      />
    </Flex.Column>
  )
}

const ProductSearchListItemThumbnail = ({
  thumbnailUrl,
}: {
  thumbnailUrl: string
}) => (
  <Clay width="48px" height="48px" borderRadius="8px" overflow="hidden">
    <SafeImageLoading
      fallback={
        <ProductThumbFallback className="w-full h-full object-cover bg-white" />
      }
      src={thumbnailUrl ?? undefined}
    />
  </Clay>
)

const LoadingSearch = () => (
  <Flex.Column
    width="100%"
    height="100%"
    minHeight="376px"
    justifyContent="center"
    alignItems="center"
    gap="12px"
  >
    <Spinner size="medium" bgColor="#fff" />
    <Typography variant="body-medium" colorToken="textSub">
      상품을 검색하고 있어요
    </Typography>
  </Flex.Column>
)
