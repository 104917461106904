import { __ } from '~/shared/i18n'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { Textarea } from '@imwebme/clay-components'
import { useDeliverySetupAtom } from '~/container/order-create/order-create.store'

export function PickupMemoField() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  return (
    <FieldLayout title={__('방문수령 메모')}>
      <Textarea
        placeholder={__('입력해 주세요')}
        value={deliverySetup.pickupMemo}
        onChange={(v) => setDeliverySetup({ pickupMemo: v })}
        characterCount
        maxLength={100}
        height="min"
      />
    </FieldLayout>
  )
}
