import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TContainerProps } from './일반주문/order-price-preview.container'
import { Skeleton, DropdownTriggerBtn } from '../partials'
import { ErrorCard } from '~/shared/components/error'
import { match } from 'ts-pattern'

const OrderPricePreviewContainer = React.lazy(() =>
  import('./일반주문/order-price-preview.container').then((module) => ({
    default: module.OrderPricePreviewContainer,
  }))
)

const ExternalOrderPricePreviewContainer = React.lazy(() =>
  import('./외부채널주문/external-order-price-preview.container').then(
    (module) => ({
      default: module.ExternalOrderPricePreviewContainer,
    })
  )
)

interface TPackProps extends TContainerProps {
  isExternalOrder: boolean
  children: React.ReactNode
}

export function OrderPricePreviewPack({
  isExternalOrder,
  children,
  ...props
}: TPackProps) {
  const [open, setOpen] = React.useState(false)

  if (!children) {
    return null
  }
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownTriggerBtn {...{ setOpen, className: 'justify-end' }}>
        {children}
      </DropdownTriggerBtn>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={8}
          align="start"
          onCloseAutoFocus={(evt) => evt.preventDefault()}
          className="w-[240px] bg-clay-semantic-surface py-clay-4 rounded-clay-medium shadow-clay-layer max-h-[var(--radix-popper-available-height)] overflow-y-auto"
          hideWhenDetached
          collisionPadding={12}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Skeleton />}>
              {match(isExternalOrder)
                .with(true, () => (
                  <ExternalOrderPricePreviewContainer {...props} />
                ))
                .with(false, () => <OrderPricePreviewContainer {...props} />)
                .exhaustive()}
            </React.Suspense>
          </ErrorBoundary>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
