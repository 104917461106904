import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { __ } from '~/shared/i18n'
import {
  YN,
  상수_결제수단,
  상수_섹션상태,
  상수_결제상태,
  상수_주문상태,
  상수_섹션상태필터,
} from '~/entities/@x'

const orderSearchTabNameRdo = z.object({
  name: z
    .string()
    .min(2, { message: __('최소 2자 이상') })
    .max(20, { message: __('최대 20자 이하') }),
})

export type TOrderSearchTabLabelRdo = z.infer<typeof orderSearchTabNameRdo>

const orderSearchTabSelectRdo = z.object({
  _saleChannelIdx: z
    .string()
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
  orderStatusCd: z
    .nativeEnum(상수_주문상태)
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
  paymentStatusCd: z
    .nativeEnum(상수_결제상태)
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
  orderSectionStatusCd: z
    .nativeEnum({ ...상수_섹션상태, ...상수_섹션상태필터 })
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
  pgMethod: z
    .nativeEnum(상수_결제수단)
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
  isMember: z
    .nativeEnum(YN)
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
  isDomestic: z
    .nativeEnum(YN)
    .array()
    .nonempty({ message: __('필수로 선택해야 해요') }),
})

export type TOrderSearchTabSelectRdo = z.infer<typeof orderSearchTabSelectRdo>

export const orderSearchTabRdo = orderSearchTabNameRdo.merge(
  orderSearchTabSelectRdo
)

export type TOrderSearchTabRdo = z.infer<typeof orderSearchTabRdo>

export const orderSearchTabResolver = zodResolver(orderSearchTabRdo)
