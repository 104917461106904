import { BatchAlert } from './batch-alert'
import { useBatchAlertAtom } from './batch-alert.store'

export function BatchAlertContainer({
  onClose,
  onClickCheckFails,
}: {
  onClose: () => void
  onClickCheckFails: () => void
}) {
  const state = useBatchAlertAtom.get()

  if (!state) {
    return null
  }
  return (
    <BatchAlert
      onClose={onClose}
      onClickCheckFails={onClickCheckFails}
      totalCount={state.totalCount}
      successCount={state.successCount}
    />
  )
}
