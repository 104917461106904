import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { ExternalItemMutable } from '../external-item-mutable'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { __ } from '~/shared/i18n'
import { externalOrderQueryOptions } from '~/entities/external-order'
import {
  TPatchPayloadNextShippingReadyRdo,
  patchPayloadNextShippingReadyRdo,
} from '~/entities/external-order-section/next-shipping-ready'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { z } from 'zod'
import { Checkbox } from '@imwebme/clay-components'
import {
  모델_외부채널_주문서,
  스펙_외부채널_주문서,
} from '~/entities/external-order/model/external-order'
import { useSectionCode } from '~/shared/hooks/use-section-code'

export function ExternalItemMutableList({
  children,
}: {
  children: ({
    List,
  }: {
    List: () => React.ReactNode
  }) => React.ReactNode | null
}) {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode(true)
  const {
    data: { orderData, sectionItems },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const _sectionItems = pipe(
        // sectionCode를 받았기 때문에 sectionList는 항상있다.
        e.data.orderSectionList!,
        A.findFirst((e2) => e2.orderSectionCode === sectionCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트가 없습니다.')
        }),
        (e2) => e2.orderSectionItemList,
        O.fromNullable,
        O.getOrElseW(() => {
          throw new Error('섹션 리스트에 아이템리스트가 없습니다.')
        })
      )
      return { orderData: e.data, sectionItems: _sectionItems }
    },
  })

  const formDefaultValues = React.useMemo(
    () => ({
      orderSectionCode: sectionCode,
      targetItemInformation: pipe(
        sectionItems,
        A.map((e) => ({
          _check: false,
          orderSectionItemCode: e.orderSectionItemCode,
          qty: e.qty,
        }))
      ),
    }),
    [sectionItems]
  )

  const method = useForm<TPatchPayloadNextShippingReadyRdo>({
    resolver: zodResolver(patchPayloadNextShippingReadyRdo),
    defaultValues: formDefaultValues,
  })

  // 체크박스 체크 및 섹션액션 API 요청 → 액션 성공하여 sectionItems 변경됨 → 폼을 리셋하여 체크상태를 해제함
  React.useEffect(() => {
    method.reset(formDefaultValues)
  }, [sectionItems])

  const $exOrder = new 모델_외부채널_주문서(orderData)
  const disabled = 스펙_외부채널_주문서.섹션변경가능여부
    .not()
    .isSatisfiedBy($exOrder)
  return (
    <FormProvider {...method}>
      <div data-section-code={sectionCode}>
        {children({
          List: () => (
            <>
              <input type="hidden" {...method.register('orderSectionCode')} />
              <div>
                {sectionItems.length >= 2 && (
                  <div className="px-[var(--mx)] py-[6px]">
                    <Checkbox.Label label={__('전체')}>
                      <Checkbox
                        checked={pipe(
                          method.watch('targetItemInformation'),
                          (e) => {
                            const isSome = pipe(
                              e,
                              A.some((e2) => !!e2._check)
                            )
                            return isSome
                          }
                        )}
                        isIndeterminate={pipe(
                          method.watch('targetItemInformation'),
                          (e) => {
                            const isAll = pipe(
                              e,
                              A.every((e2) => !!e2._check)
                            )
                            if (isAll) {
                              return false
                            }
                            const isSome = pipe(
                              e,
                              A.some((e2) => !!e2._check)
                            )
                            return isSome
                          }
                        )}
                        onChange={(e) => {
                          const value = e.target.checked
                          pipe(
                            sectionItems,
                            A.mapWithIndex((index) => {
                              method.setValue(
                                `targetItemInformation.${index}._check`,
                                value
                              )
                            })
                          )
                        }}
                        disabled={disabled}
                      />
                    </Checkbox.Label>
                  </div>
                )}
                <table className="w-full">
                  <colgroup>
                    <col className="min-w-[var(--mx)]" />
                    <col className="w-clay-7" />
                    <col className="w-full" />
                    <col className="min-w-[100px]" />
                    <col className="min-w-[100px]" />
                    <col className="min-w-[var(--mx)]" />
                  </colgroup>
                  <tbody>
                    {pipe(
                      sectionItems,
                      A.mapWithIndex((index, e) => (
                        <React.Fragment key={e.orderSectionItemCode}>
                          <ExternalItemMutable
                            index={index}
                            sectionCode={sectionCode}
                            sectionItemCode={e.orderSectionItemCode}
                            disabled={disabled}
                          />
                        </React.Fragment>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ),
        })}
      </div>
    </FormProvider>
  )
}

const targetItemInformationRdo = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
  _check: z.boolean().optional().optional(),
})

const externalItemMutableRdo = z.object({
  orderSectionCode: z.string(),
  targetItemInformation: z.array(targetItemInformationRdo),
})

type TExternalItemMutableList = z.infer<typeof externalItemMutableRdo>

export function useFormContextExternalItemMutableList() {
  const form = useFormContext<TExternalItemMutableList>()
  return form
}

/**
 * @description
 * 체크된 아이템이 없다면 전체 선택으로 인지
 * 체크된 아이템이 있다면 해당 아이템만 선택으로 인지
 */
export function payloadItemMutableList(body: TExternalItemMutableList) {
  const isAll = pipe(
    body.targetItemInformation,
    A.every((r) => !r._check)
  )
  const result = pipe(
    body.targetItemInformation,
    A.filter(
      flow(
        (r) => !!r._check,
        (e) => isAll || e
      )
    ),
    A.map(({ _check, ...e }) => e),
    (e) => ({
      ...body,
      targetItemInformation: e,
    }),
    (e) => e
  )
  return { isAll, value: result }
}
