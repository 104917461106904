import { match } from 'ts-pattern'
import { Tag, TagProps } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { 상수_CS상태, T상수_CS상태 } from '~/entities/@x'
import { __c } from '~/shared/i18n'
import { StatusTagBaseProps } from './status-tag.type'

/**
 * CS상태 태그
 * @param toggleDimmed - plain variant일 때 배경색을 변경할지 여부
 */
export function CsStatusTag({
  statusCd,
  size,
  toggleDimmed,
}: StatusTagBaseProps<T상수_CS상태>) {
  if (!statusCd) {
    return null
  }

  const variant = match(statusCd)
    .returnType<TagProps['variant']>()
    .with(상수_CS상태.PENDING, () => 'critical')
    .with(상수_CS상태.ING, () => 'warning')
    .otherwise(() => 'plain')

  const shouldToggleColor = !!toggleDimmed && variant === 'plain'

  return (
    <Tag
      variant={shouldToggleColor ? 'other' : variant}
      text={__c(statusCd)}
      size={size}
      sx={
        shouldToggleColor
          ? {
              '--clay-tag-background-color': vars.semantic.color.surface,
              '--clay-tag-color': vars.semantic.color.textSub,
            }
          : undefined
      }
    />
  )
}
