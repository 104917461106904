import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { ModalPath, ModalParams } from '~/router'

export const useModalPathParams = <P extends ModalPath>(path: P) => {
  if (history.state.usr.modal !== path) {
    return undefined
  }
  if (pipe(history.state.usr.modal, (e) => e.length > 0 && e !== '')) {
    return undefined
  }
  return history.state.usr.modalParams as ModalParams[P]
}

export const useModalParams = () => {
  if (
    history?.state?.usr?.modalParams &&
    pipe(
      history?.state?.usr?.modal,
      O.fromNullable,
      O.fold(
        () => false,
        (e) => e.length > 0 && e !== ''
      )
    )
  ) {
    return history.state.usr.modalParams as Record<string, any>
  }
  return undefined
}
