import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TExchangeReasonCustomerBase, useExchangeReasonCustomerAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ContentsBanner, ContentsBannerProps } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'

const ExchangeReasonCustomerContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExchangeReasonCustomer),
  }))
)

interface TExchangeReasonCustomerPack extends TExchangeReasonCustomerBase {}
export function ExchangeReasonCustomerPack(props: TExchangeReasonCustomerPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useExchangeReasonCustomerAtom.state({
    atomKey: props.atomKey,
    sectionCode: props.sectionCode,
    orderCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] px-0 gap-0 max-w-[380px]">
          <React.Suspense fallback={<>{'...loading'}</>}>
            <ExchangeReasonCustomerContainer {...props} />
          </React.Suspense>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

interface TExchangeReasonCustomerBanner extends TExchangeReasonCustomerPack {
  variant?: ContentsBannerProps['variant']
  text?: ContentsBannerProps['text']
  buttonText?: string
}
export function ExchangeReasonCustomerBanner({
  variant = 'warning',
  text = __('구매자가 교환 접수한 반품 건이에요.'),
  buttonText = __('사유보기'),
  ...props
}: TExchangeReasonCustomerBanner) {
  const orderCode = useOrderCode()
  const setModal = useExchangeReasonCustomerAtom.set({
    atomKey: props.atomKey,
    sectionCode: props.sectionCode,
    orderCode,
  })

  return (
    <ContentsBanner
      type="secondary"
      variant={variant}
      text={text}
      button={{ text: buttonText, onClick: () => setModal(true) }}
    />
  )
}
