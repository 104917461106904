import React from 'react'
import { Modal } from '@imwebme/clay-components'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'
import { TOrderSearchTabDeleteBase, useOrderSearchTabDeleteAtom } from '.'

const OrderSearchTabDeleteContainer = React.lazy(() =>
  import('./order-search-tab-delete').then((module) => ({
    default: React.memo(module.OrderSearchTabDelete),
  }))
)

interface TOrderSearchTabDeletePack extends TOrderSearchTabDeleteBase {}
export function OrderSearchTabDeletePack(props: TOrderSearchTabDeletePack) {
  const [open, setState] = useOrderSearchTabDeleteAtom.state({
    atomKey: props.atomKey,
    tabCode: props.tabCode,
  })

  return (
    <Modal isOpen={!!open} setClose={() => setState(false)} width="small">
      <ErrorBoundary fallback={ErrorCard}>
        <React.Suspense>
          <OrderSearchTabDeleteContainer {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Modal>
  )
}
