import React from 'react'
import { useController } from 'react-hook-form'
import { __ } from '~/shared/i18n'
import { Selector } from './components'
import { FormControlProp } from './order-search-filter-form-fields.type'
import { Textfield } from '@imwebme/clay-components'

interface Props extends FormControlProp {}

export const OrderItemCountField = ({ control }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const { onChange, value, ...inputProps } = useController({
    name: 'orderItemCount',
    control,
  }).field

  const handleChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (evt.target.value === '') {
        onChange(null)
        return
      }

      const parsed = parseInt(evt.target.value)

      if (!isNaN(parsed) && parsed > 0) {
        onChange(parsed)
      }
    },
    []
  )

  const handleResetField = React.useCallback(() => {
    onChange(null)
  }, [])

  const fieldLabel = React.useMemo(
    () =>
      typeof value === 'number'
        ? __('{{count}}개 품목 이상 주문', { count: value })
        : __('주문품목수'),
    [value]
  )

  return (
    <Selector
      label={fieldLabel}
      isActive={!!value}
      resetField={handleResetField}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="px-clay-5 py-clay-4 w-[200px]">
          <Textfield.Set>
            <Textfield.Legend text={__('주문품목수')} />
            <Textfield.Box variant="secondary">
              <Textfield.Input
                type="tel"
                value={value ?? ''}
                placeholder="1"
                onChange={handleChange}
                {...inputProps}
                autoComplete="off"
              />
              <Textfield.Addon className="text-clay-semantic-textSub typo-clay-body-medium">
                {__('개 품목 이상')}
              </Textfield.Addon>
            </Textfield.Box>
          </Textfield.Set>
        </div>
      }
    />
  )
}
