import {
  type SetStateAction,
  atom,
  useAtom,
  useAtomValue,
  useSetAtom,
} from 'jotai'
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomType = boolean
const OmsDevtoolsOriginalState = atomWithReset<AtomType>(false)
OmsDevtoolsOriginalState.debugLabel = 'OmsDevtoolsOriginalState'

const OmsDevtoolsState = atom(
  (get) => get(OmsDevtoolsOriginalState),
  (_get, set, update: SetStateAction<AtomType> | typeof RESET) => {
    if (update === RESET) {
      set(OmsDevtoolsOriginalState, RESET)
      return
    }
    if (typeof update === 'function') {
      set(OmsDevtoolsOriginalState, (prev) => update(prev))
      return
    }
    set(OmsDevtoolsOriginalState, update)
  }
)
OmsDevtoolsState.debugLabel = 'OmsDevtoolsState'

export const useOmsDevtoolsAtom = {
  state: () => useAtom(OmsDevtoolsState),
  get: () => useAtomValue(OmsDevtoolsState),
  set: () => useSetAtom(OmsDevtoolsState),
  reset: () => useResetAtom(OmsDevtoolsState),
}
