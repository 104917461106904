import { atomFamily } from 'jotai/utils'
import { atom, useAtom } from 'jotai'
import React from 'react'
import type { TItem, T_VIEW_Item } from '../../product-search.type'
import { productSearchItemState } from '../../product-search.store'
import { Product } from '../../product-search-item-class'
import { TProductSearchItemBase } from '../../../product-search-item/product-search-item-base'

// 상품(id)옵션(id)의 상태를 관리하는 atom
export const SingleProductState = atomFamily(
  ({
    searchId,
    prodCode,
  }: {
    searchId: string
    prodCode: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      // ==================== get ====================
      (get) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        if (prodData === undefined) {
          return undefined
        }
        return prodData
      },
      // ==================== set ====================
      // 주문서전체 데이터에서 prodCode, optionCode, valueCode를 찾아서 해당 value를 업데이트한다.
      (get, set, arg: TItem & TProductSearchItemBase) => {
        const productSearchItemAtom = productSearchItemState({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        const prod = new Product(prodData, searchId)
        if (prod.childComponentType === '단일상품') {
          set(productSearchItemAtom, arg)
        }
      }
    )
    at.debugLabel = `search-product(${prodCode})-singleProduct`
    return at
  },
  (a, b) => a.searchId === b.searchId && a.prodCode === b.prodCode
)

export const useSingleProductAtom = {
  option: (
    searchId: string,
    prodCode: string
    // optionCode: string,
    // valueCode: string
  ) =>
    useAtom(
      React.useMemo(
        () =>
          SingleProductState({
            searchId,
            prodCode,
          }),
        [prodCode, searchId]
      )
    ),
}
