import { z } from 'zod'
import { zYn } from '~/entities/@x'

export const patchResClaimsSwitchDto = z.object({
  sourceOrderSectionCodes: z.array(z.string()),
})

export const patchPayloadClaimsSwitchDto = z.object({
  isExchange: zYn,
})
