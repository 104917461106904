import { z } from 'zod'

export const 상수_결제타입 = {
  결제완료: 'done', // 결제 완료
  미결제: 'nonPayment', // 미결제
  환불예정: 'refundPending', // 환불예정
  입금대기: 'waitDeposit', // 입금대기
} as const

const schema = z.nativeEnum(상수_결제타입)
export type T상수_결제타입 = z.infer<typeof schema>
