import {
  type SetStateAction,
  atom,
  useAtom,
  useAtomValue,
  useSetAtom,
} from 'jotai'
import { RESET, atomWithReset, useResetAtom } from 'jotai/utils'

type AtomType = boolean
const ExportExcelHistoryOriginalState = atomWithReset<AtomType>(false)
ExportExcelHistoryOriginalState.debugLabel = 'ExportExcelHistoryOriginalState'

const ExportExcelHistoryState = atom(
  (get) => get(ExportExcelHistoryOriginalState),
  (_get, set, update: SetStateAction<AtomType> | typeof RESET) => {
    if (update === RESET) {
      set(ExportExcelHistoryOriginalState, RESET)
      return
    }
    if (typeof update === 'function') {
      set(ExportExcelHistoryOriginalState, (prev) => update(prev))
      return
    }
    set(ExportExcelHistoryOriginalState, update)
  }
)
ExportExcelHistoryState.debugLabel = 'ExportExcelHistoryState'

export const useExportExcelHistoryAtom = {
  state: () => useAtom(ExportExcelHistoryState),
  get: () => useAtomValue(ExportExcelHistoryState),
  set: () => useSetAtom(ExportExcelHistoryState),
  /**
   * 엑셀모달 내보내기 이력 Collaps 닫힘으로 초기화
   */
  reset: () => useResetAtom(ExportExcelHistoryState),
}
