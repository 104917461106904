import { OrderFormData } from '~/entities/order-detail'

/**
 * 주문 추가정보 카드를 노출해야하는 지 여부
 */
export function showFormDataInfo(orderFormData?: OrderFormData[]): boolean {
  if (!orderFormData || orderFormData.length === 0) {
    return false
  }
  return orderFormData.some((form) => form.value.length)
}
