import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import GrimIconGrayDocument from '~/shared/components/icon/grim-icon-gray-document.svg?react'
import { __ } from '~/shared/i18n'

/**
 * 필터보기 메모로 설정 및 메모가 없을 때
 */
export function OrderTimelineEmptyMemo() {
  return (
    <Flex.Center
      width="100%"
      height="100%"
      flexDirection="column"
      rowGap={vars.spacing[2]}
    >
      <GrimIconGrayDocument />
      <Typography variant="body-medium">{__('메모가 없어요')}</Typography>
    </Flex.Center>
  )
}
