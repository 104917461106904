import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import {
  deliveryDataToAddress,
  memberDeliveryQueryOptions,
  TGetResMemberDeliveryDto,
} from '~/entities/member/member-delivery'
import {
  useDeliverySetupAtom,
  useOrderInfoAtom,
} from '~/container/order-create/order-create.store'
import { __ } from '~/shared/i18n'
import { delivDataToJotai } from '../delivery-setup.fn'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { Flex, OptionItem, OptionList, Select } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { YN, 상수_주소형식 } from '~/entities/@x'
import { 배송지_직접입력 } from '../delivery-setup.constants'
import { DirectInputInternational } from './direct-input-international'
import { match, P } from 'ts-pattern'
import { RESET } from 'jotai/utils'
import { DirectInputKorean } from './direct-input-korean'
import { useDropdownHeight } from '~/container/order-create/use-dropdown-height'

export function DelivAddressField({ memberCode }: { memberCode: string }) {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const [orderInfo] = useOrderInfoAtom()
  const { fieldLayoutRef, dropdownHeight } = useDropdownHeight({
    maxHeight: 380,
  })

  const { data: memberDelivList } = useSuspenseQuery({
    ...memberDeliveryQueryOptions(memberCode),
    select: React.useCallback(
      (data: TGetResMemberDeliveryDto) =>
        data.filter((el) => el.shippingCountry === deliverySetup.country),
      [deliverySetup.country]
    ),
  })

  const fullAddress: string = React.useMemo(() => {
    if (deliverySetup.shippingAddressCode === 배송지_직접입력.code) {
      return 배송지_직접입력.name
    }

    const found = memberDelivList.find(
      (el) => el.code === deliverySetup.shippingAddressCode
    )
    if (found) {
      return deliveryDataToAddress(found.addressData, found.shippingCountry)
    }
    return ''
  }, [deliverySetup, memberDelivList])

  const handleDelivCodeChange = React.useCallback(
    (code: string) => {
      const prevCountry = deliverySetup.country
      const prevCountryName = deliverySetup.countryName
      const prevDelivTypeCd = deliverySetup._deliveryTypeCd
      const prevDelivPayTypeCd = deliverySetup._deliveryPayTypeCd

      const found = memberDelivList.find((el) => el.code === code)

      setDeliverySetup(RESET)
      setDeliverySetup({
        shippingAddressCode: code,
        country: prevCountry,
        countryName: prevCountryName,
        _deliveryTypeCd: prevDelivTypeCd,
        _deliveryPayTypeCd: prevDelivPayTypeCd,
        addressFormat: found?.addressType
          ? found.addressType
          : prevCountry === 'KR'
            ? 상수_주소형식.KR
            : 상수_주소형식.GLOBAL,
        ...(code === 배송지_직접입력.code
          ? {
              receiverName: orderInfo.ordererName ?? '',
              receiverCall: orderInfo.ordererCall ?? '',
            }
          : {}),
      })
    },
    [deliverySetup, memberDelivList]
  )

  // 초기값 설정
  React.useEffect(() => {
    if (
      memberDelivList.length &&
      !deliverySetup.shippingAddressCode &&
      !deliverySetup.zipcode
    ) {
      const mainDeliv =
        memberDelivList.find((el) => el.isDefault === YN.Y) ??
        memberDelivList[0]
      setDeliverySetup(delivDataToJotai(mainDeliv))
    }
  }, [memberDelivList, deliverySetup])

  return (
    <FieldLayout title={__('배송지')} ref={fieldLayoutRef}>
      <Flex.Column rowGap={vars.spacing[5]}>
        {match({
          emptyDelivList: memberDelivList.length === 0,
          country: deliverySetup.country,
        })
          .with({ emptyDelivList: true, country: 'KR' }, () => (
            <DirectInputKorean />
          ))
          .with({ emptyDelivList: true, country: P.not('KR') }, () => (
            <DirectInputInternational />
          ))
          .with({ emptyDelivList: false }, () => (
            <Select>
              <Select.Trigger>
                <Select.Input
                  value={fullAddress}
                  placeholder={__('선택해 주세요')}
                  isDisabled={!deliverySetup.country}
                />
              </Select.Trigger>
              <Select.Portal>
                <OptionList
                  popOver
                  value={deliverySetup?.shippingAddressCode}
                  setValue={handleDelivCodeChange}
                  sx={{
                    maxHeight: `${dropdownHeight}px`,
                    overflow: 'auto',
                  }}
                >
                  <OptionItem
                    value={배송지_직접입력.code}
                    text={배송지_직접입력.name}
                  />
                  {memberDelivList.map((el) => (
                    <OptionItem
                      key={el.code}
                      value={el.code}
                      text={deliveryDataToAddress(
                        el.addressData,
                        el.shippingCountry
                      )}
                      sx={{ whiteSpace: 'pre-line' }}
                    />
                  ))}
                </OptionList>
              </Select.Portal>
            </Select>
          ))
          .otherwise(() => null)}

        {/* 배송지 목록에서 직접입력 선택한 경우  */}
        <>
          {match({
            directInput:
              deliverySetup.shippingAddressCode === 배송지_직접입력.code,
            country: deliverySetup.country,
          })
            .with({ directInput: true, country: 'KR' }, () => (
              <DirectInputKorean />
            ))
            .with({ directInput: true, country: P.not('KR') }, () => (
              <DirectInputInternational />
            ))
            .otherwise(() => null)}
        </>
      </Flex.Column>
    </FieldLayout>
  )
}
