import { z } from 'zod'

export const 상수_수거결제타입 = {
  신용: 'RPT01', // 신용
  선불: 'RPT02', // 선불
  착불: 'RPT03', // 착불
  기타: 'RPT04', // 기타, 택배 아닌 경우 등
} as const

const type = z.nativeEnum(상수_수거결제타입)
export type T상수_수거결제타입 = z.infer<typeof type>
