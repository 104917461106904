import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import { orderSearchTabQueryOptions } from '~/entities/order-search-tab'
import { useParams } from '~/router'
import { 믹스패널 } from '~/shared/mixpanel'

export default function Layout() {
  const { tabCode } = useParams('/orders/tab/:tabCode')
  const { data: tabData } = useSuspenseQuery(orderSearchTabQueryOptions())

  const tab_name = pipe(
    tabData.data.list,
    A.findFirst((tab) => tab.adminOrderSearchTabCode === tabCode),
    O.getOrElseW(() => '')
  )

  React.useEffect(() => {
    dataLayer.push({
      /**
       * @description
       * event는 view이지만 event이름은 click인 이유
       * 해당 버튼에 click 이벤트로 넣는 것보다 페이지 전환시 해당 페이지 로드가 되었다는 것을 인지하는 것이다.
       * click 버튼과 보기를 동시에 이벤트를 잡은다.
       * 해당 tabCode의 버튼을 클릭한다는 것은 해당 뷰를 보는 것과 동일하다.
       */
      event: 믹스패널.click_bo_oms_order_list_tap_select_tap,
      params: {
        tab_code: tabCode,
        tab_name,
      },
    })
  }, [tabCode])

  return <Outlet />
}
