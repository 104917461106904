import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query'
import React from 'react'
import { useOmsDevtoolsAtom } from '../oms-devtools/oms-devtools.store'
import { match } from 'ts-pattern'
import { __e } from '~/shared/i18n'
import { toast } from 'react-toastify'
import { TanstackQueryLabel } from './tanstack-query-label'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => {
      match(error.response?.data.statusCode)
        .with(403, () => {
          toast.error(__e('10006'))
        })
        .otherwise(() => undefined)
    },
  }),
})

export function QueryStateManagementProvider({
  children,
}: React.PropsWithChildren) {
  const devtools = useOmsDevtoolsAtom.get()
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {match<boolean>(true)
        .with(import.meta.env.VITE_NODE_ENV === 'local' || devtools, () => (
          <TanstackQueryLabel />
        ))
        .otherwise(() => null)}
    </QueryClientProvider>
  )
}
