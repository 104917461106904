import React from 'react'
import { TExternalPaymentInfoCardBase } from '.'
import { Card } from '~/shared/components/ui/card'
import { Separator } from '~/shared/components/ui/separator'

const PaymentInfoCardContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExternalPaymentInfoCard),
  }))
)

interface TExternalPaymentInfoCardPack extends TExternalPaymentInfoCardBase {}
export function ExternalPaymentInfoCardPack(_: TExternalPaymentInfoCardPack) {
  return (
    <React.Suspense
      fallback={
        <Card.Root className="h-auto w-[auto] rounded-[10px] items-center bg-clay-semantic-surface py-[24px]">
          <Card.Header className="p-0 px-[24px] flex flex-col gap-[28px]">
            <div className="skeleton w-[120px]" />
            <div className="skeleton w-[80px]" />
          </Card.Header>
          <Separator
            orientation="horizontal"
            className="mx-[24px] my-[14px] w-auto"
          />
          <Card.Footer className="p-0 px-[24px] flex justify-between">
            <div className="skeleton w-[120px]" />
            <div className="skeleton w-[80px]" />
          </Card.Footer>
        </Card.Root>
      }
    >
      <PaymentInfoCardContainer />
    </React.Suspense>
  )
}
