import { z } from 'zod'
import {
  상수_주문상태,
  상수_결제상태,
  상수_결제상태대표,
  상수_섹션상태,
  상수_섹션상태통합,
} from '~/entities/@x'

export const orderBadgeResponseDto = z.object({
  orderStatus: z.nativeEnum(상수_주문상태),
  paymentStatus: z.union([
    z.nativeEnum(상수_결제상태대표),
    z.nativeEnum(상수_결제상태),
  ]),
  deliveryStatus: z.union([
    z.nativeEnum(상수_섹션상태통합),
    z.nativeEnum(상수_섹션상태),
  ]),
})

export type TOrderBadgeResponseDto = z.infer<typeof orderBadgeResponseDto>
