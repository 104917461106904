import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { pipe, flow } from 'fp-ts/function'
import {
  모델_주문섹션,
  스펙_주문섹션,
} from '~/entities/order-detail/model/order-section'
import { match } from 'ts-pattern'
import { CancelAllProductPreparationPack } from './전체취소_상품준비'

export function Sections() {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(orderDetailOptions({ orderCode }))

  return (
    <>
      {pipe(
        data.orderSectionList,
        O.fromNullable,
        O.fold(
          () => null,
          flow(
            A.map((section) => {
              const model = new 모델_주문섹션(section)
              return (
                <React.Fragment
                  key={`${section.orderCode}-${section.orderSectionCode}`}
                >
                  {match<boolean>(true)
                    .with(스펙_주문섹션.상품준비.isSatisfiedBy(model), () => (
                      <CancelAllProductPreparationPack
                        sectionCode={section.orderSectionCode}
                      />
                    ))
                    .otherwise(() => null)}
                </React.Fragment>
              )
            })
          )
        )
      )}
    </>
  )
}
