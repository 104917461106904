import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useNonRequiredOptionAtom } from './non-required-option.store'
import { TItem } from '../../product-search.type'
import { Product } from '../../product-search-item-class'
import { useProductSearchAtom } from '../../product-search.store'

export const NonRequiredOptionContainer = ({
  searchId,
  prodCode,
  optionCode,
  valueCode,
  children,
  productData,
}: {
  prodCode: string
  optionCode: string
  valueCode: string
  searchId: string
  productData: TItem
  children: (props: {
    checked: boolean
    setChecked: () => void
    disabled?: boolean
  }) => React.ReactElement
}) => {
  // jotai가
  const [option, setOption] = useNonRequiredOptionAtom.option(
    searchId,
    prodCode,
    optionCode,
    valueCode
  )
  const value = useProductSearchAtom.getItem(searchId, prodCode)
  // 타입가드
  if (option === undefined) {
    return null
  }
  if (Array.isArray(option)) {
    return null
  }
  if ('options' in option) {
    return null
  }
  // =================================================================
  // 상품검색 - 필수상품을 선택하지 않으면 선택상품은 비활성화 추가
  // 각 상품타입마다 상품데이터에서 선택된 상품이있는지 확인한다음
  // 선택된 상품이없다면 disabled를 true로 설정한다.
  let disabled = true
  const prod = new Product(productData, searchId)
  if (prod.childComponentType === '단일상품') {
    disabled = value.checked !== true
  }
  if (prod.childComponentType === '단일상품_입력형옵션') {
    disabled = !(value.siOptions !== undefined && value.siOptions.length > 0)
  }
  if (
    prod.childComponentType === '필수비조합옵션' ||
    prod.childComponentType === '필수비조합옵션_입력형옵션'
  ) {
    disabled = !(
      value.rncoOptions !== undefined && value.rncoOptions.length > 0
    )
  }
  if (prod.childComponentType === '필수조합옵션') {
    disabled = pipe(
      value.optionDetails,
      O.fromNullable,
      O.fold(
        () => true,
        flow(
          A.map((e) => e.checked),
          A.map(
            flow(
              O.fromNullable,
              O.getOrElse(() => false)
            )
          ),
          A.reduce(false, (acc, cur) => acc || cur),
          (e) => !e
        )
      )
    )
  }
  if (prod.childComponentType === '필수조합옵션_입력형옵션') {
    disabled = !(value.rcoOptions !== undefined && value.rcoOptions.length > 0)
  }
  // =================================================================
  return (
    <div>
      {children({
        checked: !!option.checked,
        setChecked: () => {
          setOption({ ...option, checked: !option.checked })
        },
        disabled,
      })}
    </div>
  )
}
