import React from 'react'
import { TExportModalContainer } from '.'
import { useExportModalAtom } from './export-modal.store'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { Download01 } from '@imwebme/clay-icons'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { __, useLL } from '~/shared/i18n'
import { cn } from '~/shared/utils'
import { Button } from '@imwebme/clay-components'
import { 믹스패널 } from '~/shared/mixpanel'

const ExportModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExportModalContainer),
  }))
)

interface TExportModalPack extends TExportModalContainer {}
export function ExportModalPack(props: TExportModalPack) {
  const tabCode = useTabCode()
  const [open, setOpen] = useExportModalAtom.state({ tabCode })
  const step = useExportModalAtom.step.get({ tabCode })
  const location = useLL()

  const 탭생성페이지인가 =
    location.pathname.includes('/orders/tab/new') ||
    location.pathname.includes('/orders/tab/edit')

  return (
    <AlertDialog.Root
      open={open.value}
      onOpenChange={(e) => {
        if (e) {
          dataLayer.push({
            event: 믹스패널.click_bo_oms_order_list_export,
          })
        }
        if (step.value === '양식관리' && !e) {
          dataLayer.push({
            event: 믹스패널.click_bo_oms_order_list_export_format_cancel,
          })
        }
        setOpen((prev) => ({ ...prev, value: e }))
      }}
    >
      <AlertDialog.Trigger asChild>
        <Button
          native={{
            type: 'button',
          }}
          variant="secondary"
          text={__('내보내기')}
          trailingIcon={<Download01 />}
          isDisabled={탭생성페이지인가}
        />
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content
          className={cn(
            'sm:max-w-none transition-all duration-300 gap-y-0 p-0 overflow-hidden focus-visible:ring-0 focus-visible:ring-offset-0 border-0 focus-visible:border-0 focus-visible:outline-none',
            step.value !== '양식관리'
              ? 'xs:w-[280px] sm:w-[540px]'
              : '!w-[calc(100%-2*32px)] overflow-hidden max-h-none h-[calc(100dvh-100px)]'
          )}
        >
          <AlertDialog.Title />
          <React.Suspense fallback={<div>{__('로딩중...')}</div>}>
            <ExportModalContainer {...props} />
          </React.Suspense>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
