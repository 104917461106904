import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionDeliveryHoldApplyAllSchema,
  type TPostPayloadBulkActionSectionDeliveryHoldApplyAllSchema,
  postResBulkActionSectionDeliveryHoldApplyAllSchema,
} from '.'

const axios = ApiClient()

export async function postBulkActionSectionDeliveryHoldApplyAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionDeliveryHoldApplyAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionDeliveryHoldApplyAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/delivery/hold/all', body)
    .then((res) => res.data)

  postResBulkActionSectionDeliveryHoldApplyAllSchema.parse(result.data)

  return result.data
}
