import { Clay, Flex } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

export function StepProdSelectSkeleton() {
  return (
    <Clay
      paddingTop={vars.spacing[6]}
      borderRadius={vars.rounded.large}
      background={vars.semantic.color.surface}
    >
      {Array.from({ length: 3 }).map((_, idx) => (
        <Flex
          key={idx}
          paddingInline={vars.spacing[6]}
          paddingBottom={vars.spacing[6]}
          columnGap={vars.spacing[6]}
          alignItems="center"
        >
          <Clay
            width="40px"
            height="40px"
            borderRadius={vars.rounded.medium}
            background={vars.semantic.color.surfaceSub}
          />
          <Clay
            flex={1}
            height="20px"
            borderRadius={vars.rounded.medium}
            background={vars.semantic.color.surfaceSub}
          />
          <Clay flex={1}>
            <Clay
              width="85px"
              height="20px"
              marginLeft="auto"
              borderRadius={vars.rounded.medium}
              background={vars.semantic.color.surfaceSub}
            />
          </Clay>
        </Flex>
      ))}
    </Clay>
  )
}
