import React from 'react'
import { ExternalCancelDone, TExternalCancelDoneBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalCancelDoneContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalCancelDone }))
)

interface TExternalCancelDonePack extends TExternalCancelDoneBase {}
export function ExternalCancelDonePack(props: TExternalCancelDonePack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalCancelDoneContainer {...props} />
    </React.Suspense>
  )
}
