import { ApiClient, ApiResponse } from '~/shared/api'
import { type OrderDetailData, orderDetailResponseSchema } from '.'

const axios = ApiClient()

export function getOrderDetailData(orderCode: string) {
  return async function () {
    const response = await axios
      .get<ApiResponse<OrderDetailData>>(`/order/${orderCode}`)
      .then((res) => res.data)

    return response
  }
}
