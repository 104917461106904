/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import {
  patchPayloadCancelRequestDto,
  targetItemInformationDto,
  targetSectionInformationDto,
} from '.'

export const patchPayloadCancelRequestRdo = patchPayloadCancelRequestDto.extend(
  {
    targetSectionInformation: z.array(
      targetSectionInformationDto.extend({
        targetItemInformation: z.array(
          targetItemInformationDto.extend({
            _check: z.boolean(),
          })
        ),
      })
    ),
  }
)
