/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */

import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'
import { 상수_섹션상태 } from '~/entities/@x'
import { targetItemInformationDto } from '../_shared/target-item-information'

/**
 * patch status-next
 */
export const patchResStatusNextDto = ApiResponseSchema
export const patchPayloadStatusNextDto = z.object({
  targetItemInformation: z.array(targetItemInformationDto),
  expectedStatusCd: z.nativeEnum(상수_섹션상태).nullish(),
})
