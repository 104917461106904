import React from 'react'
import { TExternalDeliveryRetrieveBase } from '.'

const ExternalDeliveryRetrieveContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: module.ExternalDeliveryRetrieve,
  }))
)

interface TExternalDeliveryRetrievePack extends TExternalDeliveryRetrieveBase {}
export function ExternalDeliveryRetrievePack(
  props: TExternalDeliveryRetrievePack
) {
  return (
    <React.Suspense>
      <ExternalDeliveryRetrieveContainer {...props} />
    </React.Suspense>
  )
}
