import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type { TPostPayloadBulkActionCashReceiptApplyDto } from './bulk-action-cash-receipt-apply.type'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/6XWoOd
 * @description 현금영수증 발급 대량 처리 등록
 */
export async function postBulkActionCashReceiptApply<
  T = ApiVoidResponse,
  R = TPostPayloadBulkActionCashReceiptApplyDto,
>({ body }: { body: R }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/cash-receipt', body)
    .then((res) => res.data)

  return result.data
}
