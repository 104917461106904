/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { zYn } from '../@x'

const adminShoppingOmsDto = z
  .object({
    view: zYn.describe('조회 권한 여부'),
    edit: zYn.describe('편집 권한 여부'),
  })
  .describe('OMS 권한')

const etcPermissionDataDto = z
  .object({
    isPersonalInformationAccessPermission:
      zYn.describe('개인정보 접근 권한 여부'),
  })
  .describe('기타 권한')

export const getResPermissionDto = z.object({
  adminShoppingOms: adminShoppingOmsDto,
  etcPermissionData: etcPermissionDataDto,
})

export const getPayloadPermissionDto = z.any()
