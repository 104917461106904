import React from 'react'
import { TExternalDeliveryHold2NullInoviceBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalDeliveryHold2NullInoviceContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalDeliveryHold2NullInovice }))
)

interface TExternalDeliveryHold2NullInovicePack
  extends TExternalDeliveryHold2NullInoviceBase {}
export function ExternalDeliveryHold2NullInovicePack(
  props: TExternalDeliveryHold2NullInovicePack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalDeliveryHold2NullInoviceContainer {...props} />
    </React.Suspense>
  )
}
