import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/shared/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border border-slate-200 px-2.5 py-0.5 transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 dark:border-slate-800 dark:focus:ring-slate-300',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-slate-900 text-slate-50 hover:bg-slate-900/80 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/80',
        primary:
          'border-transparent text-clay-semantic-textPrimary bg-clay-semantic-layerSelected hover:bg-clay-semantic-layerSelectedHover',
        success:
          'border-transparent text-clay-semantic-textSuccess bg-clay-semantic-surfaceSuccess',
        secondary:
          'border-transparent bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80',
        destructive:
          'border-transparent bg-red-500 text-slate-50 hover:bg-red-500/80 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/80',
        outline: 'text-slate-950 dark:text-slate-50',
        critical:
          'bg-clay-semantic-surfaceCritical text-clay-semantic-textCritical border-transparent',
        warning:
          'bg-clay-semantic-surfaceWarning text-clay-semantic-textWarning border-transparent',
      },
      size: {
        default: 'typo-clay-label-small',
        small: 'typo-clay-label-xsmall',
        large: 'typo-clay-label-medium',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
