import { ApiClient } from '~/shared/api'
import { type TGetResDeliveryCountryDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 배송 가능 국가 목록 조회
 */
export async function getDeliveryCountry(unitCode: string) {
  const result = await axios
    .get<
      ApiResponse<TGetResDeliveryCountryDto>
    >(`/site/units/${unitCode}/delivery-country`)
    .then((res) => res.data)

  return result.data
}
