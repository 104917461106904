/**
 * @owner @imwebme
 * @project feature
 * @description
 * ApiResponseSchema는 OMSA프로젝트 전용입니다.
 * 일반프로젝트는 ApiResponseSchema를 사용하지 않습니다.
 */
import { z } from 'zod'
import { ApiResponseSchema } from '~/shared/api'
import {
  discountTypeSchema,
  filterSearchDateRangeTypeSchema,
  findAllFilterSearchDatesTypeSchema,
  paymentMethodCodeSchema,
} from '../order-search-filter'
import {
  YN,
  상수_결제수단,
  상수_섹션상태,
  상수_결제상태,
  상수_주문상태,
  상수_섹션상태필터,
} from '~/entities/@x'
import { __ } from '~/shared/i18n'

export const columnCondition = z
  .object({
    saleChannelIdx: z.array(z.number()).nullish(),
    orderStatusCd: z.array(z.nativeEnum(상수_주문상태)).nullish(),
    unitCode: z.array(z.string()).nullish(),
    paymentStatusCd: z.array(z.nativeEnum(상수_결제상태)).nullish(),
    orderSectionStatusCd: z
      .array(z.nativeEnum({ ...상수_섹션상태, ...상수_섹션상태필터 }))
      .nullish(),
    pgMethod: z.array(z.nativeEnum(상수_결제수단)).nullish(),
    isMember: z.nativeEnum(YN).nullish(),
    isDomestic: z.nativeEnum(YN).nullish(),
  })
  .nullish()

export const visibleColumn = z.object({
  key: z.string(),
  sortNo: z.number(),
  isVisible: z.union([z.literal('Y'), z.literal('N')]),
})

export const filter = z.object({
  idx: z.number(),
  adminOrderSearchTabCode: z.string(),
  name: z.string(),
  sortNo: z.number(),
  columnCondition: z.object({
    searchDateType: findAllFilterSearchDatesTypeSchema.nullish(),
    dateRange: filterSearchDateRangeTypeSchema.nullish(),
    startDate: z.string().nullish(),
    endDate: z.string().nullish(),
    pgMethod: paymentMethodCodeSchema.array().nullish(),
    discountType: discountTypeSchema.array().nullish(),
    minPaymentPrice: z.number().nullish(),
    maxPaymentPrice: z.number().nullish(),
    orderItemCount: z.number().nullish(),
    isMember: z.nativeEnum(YN).nullish(),
    isFirstOrderUser: z.nativeEnum(YN).nullish(),
    deliveryDelayDay: z.number().nullish(),
  }),
})

export const searchTabItem = z.object({
  adminOrderSearchTabCode: z.string(),
  name: z.string(),
  sortNo: z.number(),
  columnCondition,
  visibleColumns: z.array(visibleColumn),
  filters: z.array(filter).nullish(),
  count: z.number(),
})

/**
 * get order-search-tab
 */
export const getResOrderSearchTabSchema = z.object({
  list: z.array(searchTabItem),
})

/**
 * post order-search-tab
 */
export const postResOrderSearchTabSchema = z.object({
  adminOrderSearchTabCode: z.string(),
})
export const postPayloadOrderSearchTabSchema = z.object({
  name: z.string(),
  columnCondition,
})

/**
 * patch order-search-tab
 */
export const patchResOrderSearchTabSchema = ApiResponseSchema
export const patchPayloadOrderSearchTabSchema = z.object({
  name: z.string().nullish(),
  sortNo: z.number().nullish(),
  columnCondition,
  visibleColumns: visibleColumn.array().nullish(),
})

/**
 * delete order-search-tab
 */
export const deleteResOrderSearchTabSchema = ApiResponseSchema
export const deletePayloadOrderSearchTabSchema = z.undefined()

/**
 * post order-search-tab copy
 */
export const postResOrderSearchTabCopySchema = z.object({
  adminOrderSearchTabCode: z.string(),
})
export const postPayloadOrderSearchTabCopySchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: __('필수로 입력해야 해요') }),
})

/**
 * patch order-search-tab sorting
 */
export const patchPayloadOrderSearchTabSortingSchema = z.object({
  sortNo: z.number(),
  tabCodesOrigin: z.array(z.string()),
})
