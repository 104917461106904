import { useFormContext, Controller } from 'react-hook-form'
import { Textfield } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import {
  TFieldProps,
  TOrderFormDataEditFormSchema,
} from '../order-form-data-edit.type'

export function TextField({ index }: TFieldProps) {
  const { control, register } = useFormContext<TOrderFormDataEditFormSchema>()
  return (
    <div>
      <input type="hidden" {...register(`form.${index}.idx` as const)} />
      <Controller
        name={`form.${index}.value`}
        control={control}
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error },
        }) => (
          <Textfield.Set>
            <Textfield.Box>
              <Textfield.Input
                value={value[0] ?? ''}
                onChange={(v) => onChange([v.target.value])}
                {...rest}
                autoComplete="off"
                isValid={error ? false : undefined}
              />
            </Textfield.Box>
            <Textfield.HelperText className="text-clay-semantic-textCritical">
              {error?.message}
            </Textfield.HelperText>
          </Textfield.Set>
        )}
      />
    </div>
  )
}
