import React from 'react'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { TProductCard001 } from '../../product'
import { OrderSection } from '../../order-section/order-section'
import { OrderEditReturnSectionRowContainer } from './order-edit-return-section-row'
import { OrderEditReturnSectionHeaderMemo } from './order-edit-return-section-header'

const OrderEditReturnSectionRowContainerMemo = React.memo(
  OrderEditReturnSectionRowContainer
)

export interface OrderEditReturnSectionProps {
  id: string
  label?: string
  title: string
  items: TProductCard001.props[]
  onDeleteAll?: () => void
  deliveryName?: string
  deliveryNumber?: string | React.ReactNode
  onDeleteItem?: (item: TProductCard001.props) => void
  onReset?: () => void
}
export const OrderEditReturnSection = ({
  id,
  label,
  title,
  items,
  onDeleteAll,
  deliveryName,
  deliveryNumber,
  onDeleteItem,
  onReset,
}: OrderEditReturnSectionProps) => {
  const count = items?.length ?? 0

  return (
    <OrderSection name={title} data-id={id}>
      <OrderSection.Slot name="title">
        <OrderEditReturnSectionHeaderMemo
          label={`#${label}` || ''}
          title={title}
          count={count}
          onDeleteAll={onDeleteAll}
          onReset={onReset}
          deliveryName={deliveryName}
          deliveryNumber={deliveryNumber}
        />
      </OrderSection.Slot>
      <OrderSection.Slot name="body" className="mx-0 [--mx:24px] mt-[24px]">
        {/* 받아온 아이템이있으면 먼저 렌더링한다. */}
        {pipe(
          items,
          // undefined이거나 length가 0일때를 가져와야한다
          (e) => e,
          (_items) => (
            <div>
              {pipe(
                _items,
                A.map((item) => (
                  <div
                    key={`index-${item.id}`}
                    className="odd:bg-clay-semantic-layerSub"
                  >
                    <OrderEditReturnSectionRowContainerMemo
                      id={item.id}
                      sectionId={id}
                      onDeleteItem={onDeleteItem}
                    />
                  </div>
                ))
              )}
            </div>
          )
        )}
      </OrderSection.Slot>
    </OrderSection>
  )
}
