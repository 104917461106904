import React from 'react'
import { useController } from 'react-hook-form'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { OptionItem, Textfield } from '@imwebme/clay-components'
import { type DateRange as DateRangeType } from 'react-day-picker'
import { FindAllFilterSearchDatesType } from '~/entities/order-search-filter'
import { Calendar } from '~/shared/components/calendar'
import { dateTypeOptions, dateRangeOptions } from './date-field.constants'
import { __ } from '~/shared/i18n'
import { IconHelpCircle } from '@imwebme/icon'
import * as Tooltip from '@radix-ui/react-tooltip'
import { formatDate } from '~/container/order-search-filter/order-search-filter.fn'
import { pipe } from 'fp-ts/function'
import { startOfDay, endOfDay } from 'date-fns'

export type DropdownContent =
  | 'dateType'
  | 'dateRange'
  | 'deliveryDelayDay'
  | 'datePicker'

export interface DropdownComponentProps extends FormControlProp {
  setDropdownContent: React.Dispatch<React.SetStateAction<DropdownContent>>
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DateType = ({
  control,
  setDropdownContent,
}: DropdownComponentProps) => {
  const { onChange: onSearchDateTypeChange } = useController({
    name: 'searchDateType',
    control,
  }).field
  const { onChange: onDateRangeChange } = useController({
    name: 'dateRange',
    control,
  }).field

  const handleDateTypeClick = React.useCallback(
    (dateType: FindAllFilterSearchDatesType) => {
      onSearchDateTypeChange(dateType)

      switch (dateType) {
        case 'deliveryDelayDate':
          setDropdownContent('deliveryDelayDay')
          onDateRangeChange('CUSTOM')
          break
        default:
          setDropdownContent('dateRange')
          break
      }
    },
    []
  )

  return (
    <div className="min-w-[152px]">
      {dateTypeOptions.map((option) => (
        <OptionItem
          key={option.value}
          text={option.label}
          onClick={() => handleDateTypeClick(option.value)}
        />
      ))}
    </div>
  )
}

export const DateRange = ({
  control,
  setDropdownContent,
  setDropdownOpen,
}: DropdownComponentProps) => {
  const { onChange, value } = useController({
    name: 'dateRange',
    control,
  }).field

  const handleDateRangeClick = React.useCallback(
    (dateRange: (typeof dateRangeOptions)[number]['value']) => {
      onChange(dateRange)

      if (dateRange === 'CUSTOM') {
        setDropdownContent('datePicker')
      } else {
        setDropdownOpen(false)
      }
    },
    []
  )

  return (
    <div className="min-w-[108px]">
      {dateRangeOptions.map((option) => (
        <OptionItem
          key={option.value}
          text={option.label}
          onClick={() => handleDateRangeClick(option.value)}
          isSelected={value === option.value}
        />
      ))}
    </div>
  )
}

export const DatePicker = ({ control }: DropdownComponentProps) => {
  const { onChange: onStartDateChange, value: startDate } = useController({
    name: 'startDate',
    control,
  }).field

  const { onChange: onEndDateChange, value: endDate } = useController({
    name: 'endDate',
    control,
  }).field

  const handleDateSelect = React.useCallback(
    (range: DateRangeType | undefined) => {
      if (range) {
        const { from, to } = range
        from && pipe(from, startOfDay, formatDate, onStartDateChange)
        to && pipe(to, endOfDay, formatDate, onEndDateChange)
      }
    },
    []
  )

  return (
    <div className="p-[8px]">
      <Calendar
        mode="range"
        selected={{
          from: startDate ? new Date(startDate) : undefined,
          to: endDate ? new Date(endDate) : undefined,
        }}
        onSelect={handleDateSelect}
      />
    </div>
  )
}

export const DeliveryDelayDay = ({ control }: DropdownComponentProps) => {
  const { onChange, value, ...inputProps } = useController({
    name: 'deliveryDelayDay',
    control,
  }).field

  const handleChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (evt.target.value === '') {
        onChange(null)
        return
      }
      const parsed = parseInt(evt.target.value)
      if (!isNaN(parsed) && parsed >= 1 && parsed <= 90) {
        onChange(parsed)
      }
    },
    []
  )

  return (
    <div className="space-y-clay-15 px-clay-5 py-clay-4">
      <div className="typo-clay-label-medium text-clay-semantic-text flex items-center gap-x-[4px]">
        {__('배송지연일')} <DeliveryDelayDayTooltip />
      </div>
      <Textfield.Set>
        <Textfield.Box variant="secondary">
          <Textfield.Input
            type="tel"
            value={value ?? ''}
            onChange={handleChange}
            placeholder="0"
            autoComplete="off"
            {...inputProps}
          ></Textfield.Input>
          <Textfield.Addon className="typo-clay-body-medium text-clay-semantic-textSub">
            {__('일')}
          </Textfield.Addon>
        </Textfield.Box>
        <Textfield.HelperText>
          {__('최대 90일까지 입력가능해요')}
        </Textfield.HelperText>
      </Textfield.Set>
    </div>
  )
}

const DeliveryDelayDayTooltip = () => (
  <Tooltip.Provider delayDuration={300}>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <button>
          <IconHelpCircle />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          side="right"
          sideOffset={4}
          className="w-[177px] typo-clay-label-small text-dark-clay-semantic-text bg-dark-clay-semantic-surface py-[6px] px-[8px] rounded-[8px]"
        >
          {__(
            '오늘 기준으로 입력한 일자 만큼의 결제완료 미배송 주문을 검색해요'
          )}
          <Tooltip.Arrow width={12} height={6} className="fill-[#15181E]" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
)
