import { Suspense, useCallback, useRef, useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'
import { MessageTextSquare } from '@imwebme/clay-icons'
import {
  TimelineHeaderSidePanelView,
  TimelineHeaderSmallViewExpanded,
  TimelineHeaderSmallViewCollapsed,
} from './order-timeline-header'
import { OrderTimelineFooterContainer } from './order-timeline-footer'
import {
  OrderTimelineBodyContainer,
  OrderTimeLineBodySkeleton,
} from './order-timeline-body'
import {
  useResetAllTimelineAtom,
  useTimelineViewMode,
} from './order-timeline.store'
import {
  useOrderHistory,
  useTimelineInitialOpen,
  TimelineModalOpenContext,
} from './order-timeline.hook'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __ } from '~/shared/i18n'
import { Clay, IconButton, Portal, Tooltip } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { CSSObject } from '@emotion/react'
import { ErrorBoundary } from '@sentry/react'
import { 믹스패널 } from '~/shared/mixpanel'

export function OrderTimelineContainer() {
  const [modalOpen, setModalOpen] = useState(false)
  const [viewMode] = useTimelineViewMode()
  const orderCode = useOrderCode()
  const { hasUnresolvedMemo } = useOrderHistory({
    orderCode,
  })
  const resetAllTimelineAtom = useResetAllTimelineAtom()
  const handleModalOpen = useCallback((open: boolean) => {
    setModalOpen(open)
    // 모달이 열릴 때 jotai 상태 초기화, 모달이 닫히는 시점에는 transition 애니메이션이 진행되므로 상태 초기화하지 않음
    if (open === true) {
      resetAllTimelineAtom()
    }
  }, [])
  useTimelineInitialOpen({ orderCode, setModalOpen: handleModalOpen })

  return (
    <TimelineModalOpenContext.Provider value={[modalOpen, handleModalOpen]}>
      <Tooltip>
        <Tooltip.Trigger as="div">
          <IconButton
            native={{ type: 'button' }}
            variant="secondary"
            aria-label={__('타임라인 보기')}
            onClick={() => {
              dataLayer.push({
                event: 믹스패널.view_bo_oms_order_detail_timeline,
              })
              handleModalOpen(true)
            }}
            icon={
              <MessageTextSquare
                color="inherit"
                hasDot={hasUnresolvedMemo}
                dotProps={{
                  background: vars.semantic.color.focusAccent,
                  borderColor: vars.semantic.color.bg,
                  width: '6px',
                  height: '6px',
                  borderWidth: '2px',
                  boxSizing: 'content-box',
                }}
              />
            }
            sx={{
              backgroundColor: modalOpen
                ? vars.semantic.color.actionSecondaryPressed
                : undefined,
            }}
          />
        </Tooltip.Trigger>
        <Tooltip.Content
          label={__('타임라인')}
          description={__('주문의 메모와 히스토리를 \n확인할 수 있어요')}
          hasArrow
          position="bottom"
          sx={{ zIndex: 10, whiteSpace: 'pre' }}
          offsetY="0px"
        />
      </Tooltip>

      <ErrorBoundary>
        <TimelineSmallView
          show={
            modalOpen &&
            (viewMode === '작게보기_펼침' || viewMode === '작게보기_접힘')
          }
        />
        <TimelineSidePanelView show={modalOpen && viewMode === '우측패널'} />
      </ErrorBoundary>
    </TimelineModalOpenContext.Provider>
  )
}

const DURATION = 80

const transitionStyles_small: Partial<Record<TransitionStatus, CSSObject>> = {
  entered: { opacity: 1, transform: 'translateY(0)' },
  exited: { opacity: 0, transform: 'translateY(20px)' },
}

function TimelineSmallView({ show }: { show: boolean }) {
  const nodeRef = useRef(null)
  const [viewMode] = useTimelineViewMode()

  return (
    <Transition
      nodeRef={nodeRef}
      in={show}
      timeout={DURATION}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <Portal>
          <Clay
            ref={nodeRef}
            zIndex={10}
            width="320px"
            height="480px"
            position="fixed"
            bottom={viewMode === '작게보기_펼침' ? '0px' : '-432px'}
            left={vars.spacing[3]}
            borderTopRightRadius={vars.rounded.large}
            borderTopLeftRadius={vars.rounded.large}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            background={vars.semantic.color.surface}
            boxShadow={vars.dropShadow.pageAside}
            transition={`all ${DURATION}ms ease-in-out`}
            opacity={0}
            transform="translateY(20px)"
            sx={{
              ...transitionStyles_small[state],
            }}
          >
            {viewMode === '작게보기_펼침' ? (
              <TimelineHeaderSmallViewExpanded />
            ) : (
              <TimelineHeaderSmallViewCollapsed />
            )}
            <Clay flexGrow={1} overflowY="auto" overscrollBehavior="contain">
              <Suspense fallback={<OrderTimeLineBodySkeleton />}>
                <OrderTimelineBodyContainer />
              </Suspense>
            </Clay>
            <OrderTimelineFooterContainer />
          </Clay>
        </Portal>
      )}
    </Transition>
  )
}

const transitionStyles_sidePanel: Partial<Record<TransitionStatus, CSSObject>> =
  {
    entered: { opacity: 1, transform: 'translateX(0)' },
    exited: { opacity: 0, transform: 'translateX(20px)' },
  }

function TimelineSidePanelView({ show }: { show: boolean }) {
  const nodeRef = useRef(null)

  return (
    <Transition
      nodeRef={nodeRef}
      in={show}
      timeout={DURATION}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <Portal>
          <Clay
            ref={nodeRef}
            zIndex={10}
            width="380px"
            position="fixed"
            top={vars.spacing[3]}
            right={vars.spacing[3]}
            bottom={vars.spacing[3]}
            borderRadius={vars.rounded.large}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            background={vars.semantic.color.surface}
            boxShadow={vars.dropShadow.pageAside}
            transition={`all ${DURATION}ms ease-in-out`}
            opacity={0}
            transform="translateX(20px)"
            sx={{
              ...transitionStyles_sidePanel[state],
            }}
          >
            <TimelineHeaderSidePanelView />
            <Clay flexGrow={1} overflowY="auto" overscrollBehavior="contain">
              <Suspense fallback={<OrderTimeLineBodySkeleton />}>
                <OrderTimelineBodyContainer />
              </Suspense>
            </Clay>
            <OrderTimelineFooterContainer />
          </Clay>
        </Portal>
      )}
    </Transition>
  )
}
