import React from 'react'
import { Selector } from '../components'
import { __, __g } from '~/shared/i18n'
import { useController } from 'react-hook-form'
import { pipe } from 'fp-ts/function'
import { FormControlProp } from '../order-search-filter-form-fields.type'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'
import { T상수_현금영수증_상태, 상수_현금영수증_상태 } from '~/entities/@x'
import { OptionItem } from '@imwebme/clay-components'

interface Props extends FormControlProp {
  handleRemoveOrderInfoField: (
    fieldKey: OrderSearchFilterOrderInfoFields
  ) => void
}

const fieldKey = 'cashReceiptStatus'

export const CashReceiptStatusField = ({
  control,
  handleRemoveOrderInfoField,
}: Props) => {
  const { onChange, value } = useController({
    name: fieldKey,
    control,
  }).field
  const [dropdownOpen, setDropdownOpen] = React.useState(
    () => value === undefined || value === null
  )

  const fieldLabel = React.useMemo(
    () =>
      pipe(
        value,
        (v) => (v ? __g(v) : __('선택해 주세요')),
        (label) => __('현금영수증 신청상태') + ': ' + label
      ),
    [value]
  )

  const handleResetField = React.useCallback(() => {
    onChange(null)
  }, [])

  const handleItemClick = React.useCallback(
    (selectedValue: T상수_현금영수증_상태) => {
      onChange(selectedValue)
      setDropdownOpen(false)
    },
    []
  )

  React.useEffect(() => {
    ;(function removeFieldOnExit() {
      if (dropdownOpen === false && !value) {
        handleRemoveOrderInfoField(fieldKey)
      }
    })()
  }, [value, dropdownOpen])

  return (
    <Selector
      label={fieldLabel}
      isActive={true}
      resetField={handleResetField}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {Object.values(상수_현금영수증_상태)
            .filter(
              (status) =>
                ![
                  상수_현금영수증_상태.발행취소.toString(),
                  상수_현금영수증_상태.발행전_취소.toString(),
                ].includes(status)
            )
            .map((option) => (
              <OptionItem
                key={option}
                text={__g(option)}
                onClick={() => handleItemClick(option)}
                isSelected={option === value}
              />
            ))}
        </div>
      }
    />
  )
}
