import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import { Select } from '~/shared/components/ui/select'
import { Controller, useFormContext } from 'react-hook-form'
import { IconChevronDown, IconWarningTriangle } from '@imwebme/icon'
import type { TReturnRequestUI } from '~/container/order/order-return-info/order-retrun-info.type'
import { useQuery } from '@tanstack/react-query'
import { __ } from '~/shared/i18n'
import { useGetOrderDetailContextState } from '~/container/order/order-detail/order-detail-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { cn } from '~/shared/utils'
import { siteClaimsQueryOptions } from '~/entities/site/claims'

/**
 * 사유
 */
export const ReturnReasonCdSelect = () => {
  const { control } = useFormContext<TReturnRequestUI>()
  const orderCode = useOrderCode()
  const { data: orderData, isSuccess } =
    useGetOrderDetailContextState(orderCode)
  const {
    data,
    isPending,
    error: fIsError,
  } = useQuery({
    ...siteClaimsQueryOptions({
      type: 'return',
      unit_code: orderData?.unitCode,
    }),
    enabled: isSuccess,
  })

  if (isPending) {
    return <div>{__('로딩중')}</div>
  }
  if (fIsError) {
    return <div>{__('에러')}</div>
  }

  return (
    <Controller
      name="returnReasonObj.returnReason"
      control={control}
      render={({
        field: { onChange, ref, value: formValue, ...rest },
        fieldState: { error },
      }) => (
        <div>
          <Select.Root
            onValueChange={onChange}
            value={formValue ?? ''}
            {...rest}
          >
            <Select.Trigger
              className={cn(
                'w-full relative border-solid',
                error && 'bg-clay-semantic-surfaceCritical border-none'
              )}
              ref={ref}
            >
              <Select.Value placeholder={__('사유를 선택해주세요')} />
              <IconChevronDown className="stroke-[2]" />
              {error && (
                <IconWarningTriangle className="stroke-[2] text-clay-semantic-iconCritical absolute right-[36px] top-1/2 -translate-y-1/2" />
              )}
            </Select.Trigger>
            {error && (
              <div className="typo-clay-body-small mt-[4px] text-clay-semantic-textCritical">
                {error.message}
              </div>
            )}
            <Select.Content>
              <Select.Group>
                {pipe(
                  data,
                  A.map(([key, value]) => (
                    <Select.Item value={value} key={key}>
                      {value}
                    </Select.Item>
                  ))
                )}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </div>
      )}
    />
  )
}

export const ReturnReturnReasonTextArea = () => {
  const { control } = useFormContext<TReturnRequestUI>()
  return (
    <Controller
      name="returnReasonDetail"
      control={control}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <div>
          <textarea
            onChange={onChange}
            ref={ref}
            value={value ?? ''}
            className={cn(
              error && 'focus:ring-red-500',
              'flex h-20 w-full rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900'
            )}
          />
        </div>
      )}
    />
  )
}
