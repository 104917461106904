import { __ } from '~/shared/i18n'
import { ExternalOrderDetail } from '~/container/external-order/external-order-detail'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

function Page() {
  return (
    <>
      <PageTopBar />
      <ExternalOrderDetail />
    </>
  )
}

export default Page
