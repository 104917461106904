import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const optionSchema = z.object({
  optionCode: z.string().min(1, '필수 입력사항입니다.'),
  optionName: z.string().min(1, '필수 입력사항입니다.'),
  optionValueCode: z.string().optional(),
  optionValueName: z.string().optional(),
  optionValue: z.string().optional(),
  price: z.number().optional(),
  type: z.string().optional(),
})
const schema = z.record(optionSchema)
export const productSearchItemSIResolver = zodResolver(schema)
