import React from 'react'
import { OrderCancelAll } from './order-cancel-all'
import { Loading } from '~/shared/components/loading'

export function OrderCancelAllPack() {
  return (
    <React.Suspense fallback={<Loading data-id="order-cancel-all.pack.tsx" />}>
      <OrderCancelAll />
    </React.Suspense>
  )
}
