import React from 'react'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'
import {
  ExternalPurchaseConfirmation,
  TExternalPurchaseConfirmationBase,
} from '.'

interface TExternalPurchaseConfirmationPack
  extends TExternalPurchaseConfirmationBase {}
export function ExternalPurchaseConfirmationPack(
  props: TExternalPurchaseConfirmationPack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalPurchaseConfirmation {...props} />
    </React.Suspense>
  )
}
