/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { IMWEB_BANK_CODE } from '~/entities/@x'

/**
 * get bank-transfer-accounts
 */
export const getResBankTransferAccountsDto = z.array(
  z.object({
    bankCode: z.nativeEnum(IMWEB_BANK_CODE).nullish(),
    bankName: z.string(),
    bankAccount: z.string(),
    accountHolderName: z.string(),
  })
)
