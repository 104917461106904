import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

export const isEmptyObject = (obj: object) =>
  pipe(obj, Object.keys, (keys) => {
    if (keys.length === 0) {
      return O.none
    }
    return O.some(obj)
  })
