import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Modal,
} from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { OrderMemoPreviewDeleteModalProps } from './order-memo-preview-delete-modal.container'
import { vars } from '@imwebme/clay-token'

export const OrderMemoPreviewDeleteModal = ({
  handleModalClose,
  handleDeleteOrderMemo,
}: OrderMemoPreviewDeleteModalProps) => (
  <Modal width="small" isOpen setClose={() => {}}>
    <Modal.Header text={__('메모를 삭제할까요?')} type="leftAlign" />
    <Clay marginBottom={vars.spacing[6]} />
    <Modal.Footer>
      <Flex.Column alignItems="flex-end">
        <ButtonGroup size="medium" isInline>
          <Button
            fullWidth
            onClick={handleModalClose}
            text={__('취소')}
            variant="secondary"
          />
          <Button
            fullWidth
            onClick={handleDeleteOrderMemo}
            text={__('삭제')}
            variant="critical"
          />
        </ButtonGroup>
      </Flex.Column>
    </Modal.Footer>
  </Modal>
)
