import React from 'react'
import { TExternalExchangeDoneBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalExchangeDoneContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalExchangeDone }))
)

interface TExternalExchangeDonePack extends TExternalExchangeDoneBase {}
export function ExternalExchangeDonePack(props: TExternalExchangeDonePack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalExchangeDoneContainer {...props} />
    </React.Suspense>
  )
}
