import React from 'react'
import type { T_SingleProduct } from './single-product'
import { SingleProduct } from './single-product'
import { useSingleProductAtom } from './single-product.store'

export const withSingleProductContainer =
  (WrappedComponent: React.ComponentType<T_SingleProduct>) =>
  // return (WrappedComponent: React.ComponentType<T_SingleProduct>) => {
  ({
    searchId,
    ...props
  }: T_SingleProduct & {
    searchId: string
  }) => {
    const [value, setValue] = useSingleProductAtom.option(searchId, props.id)
    if (value === undefined) {
      return null
    }
    return (
      <WrappedComponent
        {...props}
        checked={!!value.checked}
        setChecked={() =>
          setValue({
            ...value,
            checked: !value.checked,
          })
        }
      />
    )
  }

export const SingleProductContainer = withSingleProductContainer(SingleProduct)
