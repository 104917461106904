import { Trans } from 'react-i18next'
import {
  Clay,
  Flex,
  Typography,
  Textfield,
  NumberBadge,
  Checkbox,
  Tabs,
  Tab,
  TabList,
  IconButton,
  Button,
  SafeImageLoading,
  Tag,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { match, P } from 'ts-pattern'
import {
  Plus,
  SearchSM,
  Filter,
  Columns02,
  Settings,
  ChevronSelectorVertical,
  ChevronDown,
  Download01,
  First,
} from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { fDate } from '~/shared/utils'
import GrimIconGrayShop from '~/shared/components/icon/grim-icon-gray-shop.svg?react'
import productThumb1 from '~/container/onboarding/assets/prod-thumb-1.png'
import productThumb2 from '~/container/onboarding/assets/prod-thumb-2.png'
import { SkyRing } from '~/container/onboarding/partials/sky-ring'
import { useOnboardingStep } from '~/container/onboarding/onboarding.store'
import { getOrderNo, formatPrice } from '~/container/onboarding/onboarding.fn'
import { OrderSearchFilter } from './order-search-filter'
import { OrderItemsPreview } from './order-items-preview'
import { BulkAction } from './bulk-action'
import { OnboardingTooltip } from '~/container/onboarding/onboarding-tooltip'

export function OnboardingOrderListPage() {
  const [step] = useOnboardingStep()

  return (
    <Clay position="relative" height="100vh">
      <OnboardingTooltip />

      {step === 5 && <OrderItemsPreview />}

      {step === 6 && <BulkAction />}

      <Clay padding={`0 ${vars.spacing[10]}`}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          padding={`${vars.spacing[8]} 0`}
        >
          <Typography variant="heading-4xlarge-bold">{__('주문')}</Typography>

          <Button
            variant="secondary"
            text={__('내보내기')}
            trailingIcon={<Download01 />}
          />
        </Flex>

        {/* 탭목록 */}
        <Clay
          borderRadius={vars.rounded.large}
          backgroundColor={vars.semantic.color.surface}
          padding={`0 ${vars.spacing[4]}`}
          marginBottom={vars.spacing[25]}
          position={match(step)
            .with(P.union(1, 2), () => 'relative' as const)
            .otherwise(() => 'static' as const)}
          zIndex={1}
        >
          <Tabs size="large" variant="text" underline index={0}>
            <TabList>
              {[
                __('전체'),
                __('결제대기'),
                __('상품 준비중'),
                __('배송대기'),
                __('배송중'),
                __('취소접수'),
                __('반품접수'),
              ].map((tab, idx) => (
                <Tab key={idx} text={tab} />
              ))}
              <Tab
                icon={match(step)
                  .with(2, () => (
                    <Flex.Center position="relative">
                      <Plus />
                      <SkyRing />
                    </Flex.Center>
                  ))
                  .otherwise(() => (
                    <Plus />
                  ))}
              />
            </TabList>
          </Tabs>
        </Clay>

        <Clay
          padding={`${vars.spacing[5]} 0`}
          borderRadius={vars.rounded.large}
          backgroundColor={vars.semantic.color.surface}
          zIndex={1}
          position={match(step)
            .with(P.union(2, 7), () => 'static' as const)
            .otherwise(() => 'relative' as const)}
        >
          {/* 주문목록 헤더 */}
          <Flex
            justifyContent="space-between"
            columnGap={vars.spacing[5]}
            paddingInline={vars.spacing[6]}
            paddingBottom={vars.spacing[4]}
          >
            <Flex columnGap={vars.spacing[1]} alignItems="center">
              <Typography variant="heading-xlarge-bold">
                {__('전체')}
              </Typography>
              <NumberBadge variant="primary" number={1} />
            </Flex>
            <Flex gap={vars.spacing[3]}>
              <Textfield.Box variant="secondary" width="260px">
                <Textfield.Addon>
                  <SearchSM colorToken="icon-sub" />
                </Textfield.Addon>
                <Textfield.Input
                  placeholder={__('이름, 연락처, 주문번호, 송장번호')}
                  readOnly
                  width="100%"
                />
              </Textfield.Box>
              <Flex gap={vars.spacing[1]}>
                {match(step)
                  .with(3, () => (
                    <Clay position="relative">
                      <IconButton variant="secondary" icon={<Filter />} />
                      <SkyRing />
                    </Clay>
                  ))
                  .otherwise(() => (
                    <IconButton variant="secondary" icon={<Filter />} />
                  ))}
                <IconButton variant="secondary" icon={<Columns02 />} />
                <IconButton variant="secondary" icon={<Settings />} />
              </Flex>
            </Flex>
          </Flex>

          {step === 4 && <OrderSearchFilter />}

          {/* 주문목록 테이블 */}
          <Typography as="div" variant="body-medium">
            <Clay
              display="grid"
              gridTemplateColumns="16px 24px 232px 96px repeat(3, 108px) 20px repeat(5, 180px) 1fr 16px"
              overflow="hidden"
            >
              <Clay
                gridColumnStart={2}
                gridColumnEnd={15}
                display="grid"
                gridTemplateColumns="subgrid"
                alignItems="center"
                color={vars.semantic.color.textSub}
                height="44px"
              >
                {match(step)
                  .with(6, () => (
                    <>
                      <Flex.Center>
                        <Checkbox checked />
                      </Flex.Center>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        <Typography variant="label-medium">
                          <Trans
                            i18nKey="<t>{{count}}</t>개 선택됨"
                            values={{
                              count: 1,
                            }}
                            components={{
                              t: (
                                <Typography as="span" colorToken="textAccent" />
                              ),
                            }}
                          />
                        </Typography>
                      </Clay>
                    </>
                  ))
                  .otherwise(() => (
                    <>
                      <Flex.Center>
                        <Checkbox checked={false} />
                      </Flex.Center>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('주문번호')}
                      </Clay>
                      <Flex
                        gap={vars.spacing[1]}
                        alignItems="center"
                        padding={`0 ${vars.spacing[2]}`}
                      >
                        {__('품목')} <ChevronSelectorVertical color="inherit" />
                      </Flex>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('주문상태')}
                      </Clay>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('결제상태')}
                      </Clay>
                      <Clay textAlign="right" padding={`0 ${vars.spacing[2]}`}>
                        {__('최종 실결제금액')}
                      </Clay>
                      <Clay />
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('구매자')}
                      </Clay>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('주문일')}
                      </Clay>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('결제일')}
                      </Clay>
                      <Clay padding={`0 ${vars.spacing[2]}`}>
                        {__('결제수단')}
                      </Clay>
                    </>
                  ))}
              </Clay>

              <Clay
                as="hr"
                gridColumn="1/-1"
                borderColor={vars.semantic.color.divide}
              />

              <Clay
                gridColumnStart={1}
                gridColumnEnd={-1}
                display="grid"
                gridTemplateColumns="subgrid"
                alignItems="center"
                height="44px"
                backgroundColor={match(step)
                  .with(6, () => vars.semantic.color.layerSelected)
                  .otherwise(() => vars.semantic.color.surface)}
                position="relative"
                zIndex={step === 7 ? 1 : undefined}
              >
                <Clay />
                <Flex.Center position="relative">
                  {match(step)
                    .with(6, () => (
                      <>
                        <Checkbox checked />
                        <SkyRing />
                      </>
                    ))
                    .otherwise(() => (
                      <Checkbox checked={false} />
                    ))}
                </Flex.Center>
                <Flex
                  gap={vars.spacing[2]}
                  alignItems="center"
                  padding={`0 ${vars.spacing[2]}`}
                >
                  <GrimIconGrayShop />
                  {match(step)
                    .with(7, () => (
                      <Clay position="relative">
                        {getOrderNo()}
                        <SkyRing />
                      </Clay>
                    ))
                    .otherwise(() => (
                      <Clay>{getOrderNo()}</Clay>
                    ))}
                  <First />
                </Flex>
                <Flex
                  alignItems="center"
                  padding={`0 ${vars.spacing[2]}`}
                  height="100%"
                  backgroundColor={match(step)
                    .with(5, () => vars.semantic.color.layerSelected)
                    .otherwise(() => undefined)}
                >
                  <Flex flexGrow={1}>
                    <Clay
                      width={24}
                      height={24}
                      borderRadius="100%"
                      overflow="hidden"
                      border={`1px solid ${vars.semantic.color.divide}`}
                      marginRight={-16}
                    >
                      <SafeImageLoading
                        src={productThumb1}
                        width="100%"
                        height="100%"
                      />
                    </Clay>
                    <Clay
                      width={24}
                      height={24}
                      borderRadius="100%"
                      overflow="hidden"
                      border={`1px solid ${vars.semantic.color.divide}`}
                    >
                      <SafeImageLoading
                        src={productThumb2}
                        width="100%"
                        height="100%"
                      />
                    </Clay>
                  </Flex>
                  <Clay marginRight={vars.spacing[4]}>3</Clay>
                  <ChevronDown />
                </Flex>
                <Clay padding={`0 ${vars.spacing[2]}`}>
                  <Tag size="small" variant="warning" text={__('상품준비')} />
                </Clay>
                <Clay padding={`0 ${vars.spacing[2]}`}>
                  <Tag size="small" variant="critical" text={__('결제대기')} />
                </Clay>
                <Clay textAlign="right" padding={`0 ${vars.spacing[2]}`}>
                  {formatPrice(129900)}
                </Clay>
                <Clay />
                <Clay padding={`0 ${vars.spacing[2]}`}>{__('구매자')}</Clay>
                <Clay padding={`0 ${vars.spacing[2]}`}>
                  {fDate(new Date(), 'yyyy-MM-dd')}
                </Clay>
                <Clay padding={`0 ${vars.spacing[2]}`}>-</Clay>
                <Clay padding={`0 ${vars.spacing[2]}`}>{__('무통장입금')}</Clay>
                <Clay />
              </Clay>
            </Clay>
          </Typography>
        </Clay>
      </Clay>
    </Clay>
  )
}
