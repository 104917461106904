import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResBulkActionSectionStatusValidateSchema,
  type TPostPayloadBulkActionSectionStatusValidateSchema,
  postResBulkActionSectionStatusValidateSchema,
} from '.'
import { TBulkActionSectionStatusApply } from '../bulk-action-section-status-apply'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/VfjBb6
 * @description 주문 입금 완료 업데이트 대량 검증
 */
export async function postBulkActionPaymentValidate<
  T = TPostResBulkActionSectionStatusValidateSchema,
  R = TPostPayloadBulkActionSectionStatusValidateSchema,
>({ body, status }: { body: R; status: TBulkActionSectionStatusApply }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >(`/order/bulk-action/section/${status}/validate`, body)
    .then((res) => res.data)

  postResBulkActionSectionStatusValidateSchema.parse(result.data)
  return result.data
}
