import React from 'react'
import { __ } from '~/shared/i18n'
import * as Tooltip from '@radix-ui/react-tooltip'
import { IconHelpCircle } from '@imwebme/icon'

export interface TProps {
  unipassNumber: string
}

type Nullish<T> = { [P in keyof T]?: T[P] | undefined | null }

export function UnipassNumberPack({ unipassNumber }: Nullish<TProps>) {
  if (!unipassNumber) {
    return null
  }
  return (
    <div className="flex text-[14px] text-clay-semantic-textSub">
      {unipassNumber}
      <Tooltip.Provider delayDuration={100}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <button className="flex items-center typo-clay-label-medium w-max">
              <IconHelpCircle className="ml-[4px] stroke-[2]" />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="rounded-[8px] bg-clay-semantic-text text-clay-semantic-surface typo-clay-label-small px-[8px] py-[6px] _drop-shadow animate-fade-right duration-300 animate-ease-out"
              sideOffset={8}
              side="right"
            >
              {__('개인통관고유부호')}
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  )
}
