import { queryOptions } from '@tanstack/react-query'
import { TPostPayloadReturnSettlementsDto } from './return-settlements.type'
import { postReturnSettlements } from './return-settlements.api'

export function returnSettlementsQueryOptions({
  orderCode,
  sectionCode,
  targetItemInformation,
}: {
  orderCode: string
  sectionCode: string
  targetItemInformation: TPostPayloadReturnSettlementsDto['targetItemInformation']
}) {
  return queryOptions({
    queryKey: ['return-settlements', orderCode, sectionCode],
    queryFn: () =>
      postReturnSettlements({
        orderCode,
        sectionCode,
        body: { targetItemInformation },
      }),
  })
}
