import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TDeliveryDeleteModalBase, useDeliveryDeleteModalAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const DeliveryEditModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DeliveryDeleteModal),
  }))
)

interface TDeliveryDeleteModalPack extends TDeliveryDeleteModalBase {}
export function DeliveryDeleteModalPack(props: TDeliveryDeleteModalPack) {
  const orderCode = useOrderCode()
  const [open, setState] = useDeliveryDeleteModalAtom.state({
    orderCode: orderCode,
    orderDeliveryCode: props.orderDeliveryCode,
    atomKey: props.atomKey,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] px-0 py-[24px] gap-0 max-w-[380px]">
          <React.Suspense fallback={<>{'...loading'}</>}>
            <DeliveryEditModalContainer {...props} />
          </React.Suspense>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
