import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import GrimIconGrayShopping from '~/shared/components/icon/grim-icon-gray-shopping.svg?react'
import { __ } from '~/shared/i18n'

export function EmptyProd() {
  return (
    <Flex.Center
      flexDirection="column"
      rowGap={vars.spacing[2]}
      padding={`${vars.spacing[4]} ${vars.spacing[6]} ${vars.spacing[10]}`}
    >
      <GrimIconGrayShopping width="48px" height="48px" />
      <Typography variant="body-medium">
        {__('상품을 추가해 보세요')}
      </Typography>
    </Flex.Center>
  )
}
