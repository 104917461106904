import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { useSuspenseQuery } from '@tanstack/react-query'
import { TExternalOrderCancelSelectSectionBase } from '.'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { findExternalOrderSection } from '~/shared/calc/find-external-order-section'
import { OrderSection } from '~t'
import { __ } from '~/shared/i18n'
import { ExternalCancelItemMutableReset } from '~/container/external-order/external-order-cancel-select/partials/external-cancel-item-mutable-reset'
import {
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '~/entities/external-order/model/external-order-section'

interface TExternalOrderCancelSelectSection
  extends TExternalOrderCancelSelectSectionBase {}
export function ExternalOrderCancelSelectSection({
  sectionCode,
  children,
  index,
}: TExternalOrderCancelSelectSection) {
  const orderCode = useOrderCode()
  const {
    data: { order, section },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      return {
        order: e.data,
        section: pipe(e.data, findExternalOrderSection(sectionCode)),
      }
    },
  })

  const $section = new 모델_외부채널_주문섹션(section)

  const title = match<boolean>(true)
    .with(스펙_외부채널_주문섹션.상품준비.isSatisfiedBy($section), () =>
      __('상품준비')
    )
    .with(
      스펙_외부채널_주문섹션.배송대기_송장등록전.isSatisfiedBy($section),
      스펙_외부채널_주문섹션.배송대기_송장등록후
        .and(스펙_외부채널_주문섹션.배송없는섹션)
        .isSatisfiedBy($section),
      () => __('배송대기')
    )
    .otherwise(() => null)

  return (
    <>
      <OrderSection name="외부채널_취소선택">
        <OrderSection.Slot name="title">
          <div className="grid grid-cols-[1fr,auto] gap-x-[8px]">
            <div>
              <div className="typo-clay-heading-xlarge-bold">
                {title}{' '}
                {pipe(
                  section.orderSectionItemList,
                  O.fromNullable,
                  O.fold(
                    () => null,
                    flow(
                      (e) => e.length,
                      (e) => (
                        <span className="text-clay-semantic-textPrimary">
                          {e}
                        </span>
                      )
                    )
                  )
                )}
              </div>
              <div className="mt-[8px] typo-clay-body-medium text-clay-semantic-textSub">
                #{section.orderSectionNo}
              </div>
            </div>
            <div>
              <ExternalCancelItemMutableReset
                sectionCode={sectionCode}
                index={index}
              />
            </div>
          </div>
        </OrderSection.Slot>
        <OrderSection.Slot
          name="body"
          className="mt-[24px] [--mx:24px] space-y-[24px]"
        >
          {children}
        </OrderSection.Slot>
      </OrderSection>
    </>
  )
}
