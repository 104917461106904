import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { useParams } from 'react-router-dom'
import { useOrderCodeAtom } from './use-order-code.store'
import { useModalParams } from '../use-modal-params'

/**
 * 현재 URL의 동적 매개변수에서 주문 코드(orderCode)를 가져옴
 * @returns orderCode
 * @throws orderCode가 undefined인 경우 에러 던짐
 * @allow
 * - 🟩 page
 * - 🟩 modal
 */
export const useOrderCode = (): string => {
  const params = useParams()
  const orderCodeState = useOrderCodeAtom.get()
  const orderCode = params.orderCode
  const modalOrderCode = useModalParams()

  if (orderCode) {
    return orderCode
  }

  if (orderCodeState) {
    return orderCodeState
  }

  // generouted에서 만든 모달에서 orderCode를 가져오기 위해 사용
  if (modalOrderCode) {
    const _orderCode = modalOrderCode?.orderCode
    if (_orderCode === undefined) {
      throw new Error('orderCode is not defined (modal.tsx)')
    }
    return _orderCode
  }
  throw new Error('orderCode is not defined')
}
