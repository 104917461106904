import React from 'react'
import { Clay, Textfield } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { SearchSM } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { debounce } from 'lodash'

interface Props {
  setKeyword: (keyword: string) => void
  initialKeyword: string
  disabled: boolean
}

export function SearchBar({ setKeyword, initialKeyword, disabled }: Props) {
  const [localQuery, setLocalQuery] = React.useState(initialKeyword)

  const onChangeKeyword = React.useCallback(
    debounce((text: string) => {
      setKeyword(text)
    }, 800),
    []
  )

  return (
    <Clay
      sx={{
        marginTop: vars.spacing[6],
        paddingInline: vars.spacing[6],
        marginBottom: vars.spacing[5],
      }}
    >
      <Textfield.Box variant="secondary" size="medium">
        <Textfield.Addon>
          <SearchSM colorToken="icon-sub" />
        </Textfield.Addon>
        <Textfield.Input
          value={localQuery}
          onChangeText={(v) => {
            setLocalQuery(v)
            onChangeKeyword(v)
          }}
          placeholder={__('주문번호, 구매자명')}
          disabled={disabled}
        />
      </Textfield.Box>
    </Clay>
  )
}
