import { useQuery } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import { PageBanner } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { menuBannerQueryOptions } from '~/entities/site/menu-banner'

export function Notification() {
  const { data: menuBanner } = useQuery(menuBannerQueryOptions())

  if (!menuBanner || !menuBanner.body) {
    return null
  }

  return (
    <PageBanner
      icon
      variant={menuBanner.bannerType ?? 'default'}
      text={
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(menuBanner.body, {
              ADD_ATTR: ['target'],
            }),
          }}
        />
      }
      sx={{
        marginBottom: vars.spacing[5],
      }}
    />
  )
}
