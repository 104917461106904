import { cn, findChild } from '~/shared/utils'

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'image' | 'name' | 'option' | 'price' | 'info'
}
const Slot: React.FC<SlotProps> = () => null

export const OrderSectionItem = Object.assign(
  ({ children }: React.PropsWithChildren) => {
    const ImageSlot = findChild(children, 'image')
    const NameSlot = findChild(children, 'name')
    const InfoSlot = findChild(children, 'info', true)
    const OptionSlot = findChild(children, 'option', true)
    const PriceSlot = findChild(children, 'price', true)

    return (
      <div className="grid grid-cols-[48px,1fr] gap-x-[12px]">
        <div
          className="rounded-clay-large w-[48px] h-[48px] relative overflow-hidden [&>*]:aspect-1 [&>*]:absolute [&>*]:w-full [&>*]:h-full [&>*]:object-cover"
          {...ImageSlot?.props}
        />
        <div className="typo-clay-body-medium">
          <div
            {...NameSlot?.props}
            className={cn('typo-clay-label-medium', NameSlot?.props.className)}
          />
          {InfoSlot && (
            <div
              {...InfoSlot?.props}
              className={cn(
                'text-clay-semantic-text mt-clay-1 empty:hidden',
                InfoSlot?.props.className
              )}
            />
          )}
          {OptionSlot && (
            <div
              {...OptionSlot?.props}
              className={cn(
                'text-clay-semantic-textSecondary mt-clay-1 empty:hidden break-all',
                OptionSlot?.props.className
              )}
            />
          )}
          {PriceSlot && (
            <div
              {...PriceSlot?.props}
              className={cn(
                'text-clay-semantic-text grid gap-y-clay-1 mt-clay-2',
                PriceSlot?.props.className
              )}
            />
          )}
        </div>
      </div>
    )
  },
  {
    Slot,
  }
)
