import { z } from 'zod'
import { YN } from '~/entities/@x'

export const getResShopConfigDto = z.object({
  nomemberOrderNoCk: z.nativeEnum(YN).describe('비회원 주문 불가'),
  useUnipassNumber: z
    .nativeEnum(YN)
    .describe('개인통관고유부호 필수로 입력받기'),
  shopUseNotRequireEmail: z
    .nativeEnum(YN)
    .describe('주문시 이메일 필수로 받지 않기 사용 여부'),
  useShoppingGroup: z.nativeEnum(YN).describe('쇼핑등급 사용여부'),
  usePoint: z.nativeEnum(YN).describe('적립금 사용여부'),
})
