import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TCancelRequest } from './order-cancel-info.type'
import { __ } from '~/shared/i18n'

const targetItemInformationSchema = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
})

const schema = z.object({
  cancelReason: z.string().min(1, __('필수로 선택해야 해요')),
  cancelReasonDetail: z.string().optional(),
  targetSectionInformation: z.array(
    z.object({
      orderSectionCode: z.string(),
      targetItemInformation: z.array(targetItemInformationSchema),
    })
  ),
})

export type TCancelRequestForm = z.infer<typeof schema>

/**
 * 리퀘스트 값을 다루는 클래스
 */
export class CCancelRequest {
  #data: TCancelRequest

  constructor(data: TCancelRequestForm) {
    this.#data = data
  }

  // zod.refined의 컨디셔널 조건들에 맞는 payload를 반환한다.
  get submitData() {
    return this.#data
  }
}

export const orderCancelnfoResolver = zodResolver(schema)
