import { z } from 'zod'

export const 상수_액셀배치상태 = {
  업로드중: 'FUS01',
  업로드완료: 'FUS02',
  업로드취소: 'FUS03',
  업로드실패: 'FUS04',
  배치처리중: 'FUS05',
  배치처리완료: 'FUS06',
  배치실패: 'FUS07',
  /**
   * @see https://5k.gg/6SfBEC
   */
  잘못된형식: 'FUS08',
} as const

const type = z.nativeEnum(상수_액셀배치상태)
export type T상수_액셀배치상태 = z.infer<typeof type>
