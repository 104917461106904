import { ChevronDown, HelpCircle } from '@imwebme/clay-icons'
import * as Tooltip from '@radix-ui/react-tooltip'
import { Collapse } from '~/shared/components/collapse'
import { __ } from '~/shared/i18n'
import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { InvoiceExcelHistoryListPack } from './invoice-excel-history-list/invoice-excel-history-list.pack'
import { useInvoiceExcelHistoryAtom } from './invoice-excel-history.store'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { ScrollArea } from '~/shared/components/ui/scroll-area'

export function InvoiceExcelHistory() {
  const tabCode = useTabCode()
  const [{ value: open }, setOpen] = useInvoiceExcelHistoryAtom.state({
    tabCode,
  })

  return (
    <div className="relative">
      <Flex
        alignItems="center"
        gap={vars.spacing[1]}
        cursor="pointer"
        color={vars.semantic.color.textSub}
        marginBottom={vars.spacing[3]}
        native={{
          onClick: () => {
            setOpen((prev) => ({ ...prev, value: !open }))
          },
        }}
      >
        <Typography variant="label-medium" colorToken="textSub">
          {__('이전 등록 내역')}
        </Typography>
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <HelpCircle color="inherit" />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="bg-dark-clay-semantic-surface text-dark-clay-semantic-text border-none py-[6px] px-[8px] rounded-[8px]">
                <div className="typo-clay-label-small">
                  {__('최근 7일 동안 등록된 5건까지 제공해요')}
                </div>
                <Tooltip.Arrow className="fill-dark-clay-semantic-surface" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <ChevronDown color="inherit" rotate={open ? 180 : 0} />
      </Flex>
      <Collapse in={!!open}>
        <ScrollArea className="h-[225px]">
          <InvoiceExcelHistoryListPack open={!!open} />
        </ScrollArea>
      </Collapse>
    </div>
  )
}
