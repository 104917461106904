import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResRequestDto,
  type TPostPayloadRequestDto,
  postResRequestDto,
  postPayloadRequestDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/NZPhFF
 * @description 주문 미결제 건 결제 요청/취소
 */
export function postRequest({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadRequestDto) {
    type R = typeof body
    type T = TPostResRequestDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/payment/request`, body)
      .then((res) => res.data)

    postResRequestDto.parse(result.data)
    return result
  }
}
