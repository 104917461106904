import React from 'react'
import { OptionItem, OptionList, TextButton } from '@imwebme/clay-components'
import { ChevronDown } from '@imwebme/clay-icons'
import * as Popover from '@radix-ui/react-popover'
import {
  MemoFilter,
  memoFilterOptions,
  TimelineFilter,
  TimelineFilterOptions,
  useTimelineFilter,
  useTimelineMemoFilter,
} from '~/container/order/order-timeline/order-timeline.store'
import { Tabs } from '~/shared/components/ui/tabs'

export function TimelineFilterController() {
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [timelineFilter, setTimelineFilter] = useTimelineFilter()
  const [memoFilter, setMemoFilter] = useTimelineMemoFilter()

  return (
    <>
      <Tabs.Root
        value={timelineFilter}
        onValueChange={(v) => setTimelineFilter(v as TimelineFilter)}
        variant="segment"
        className="w-[170px]"
      >
        <Tabs.List className="grid grid-cols-3 bg-clay-semantic-surfaceSub rounded-clay-medium">
          {TimelineFilterOptions.map(({ label, value }) => (
            <Tabs.Trigger
              value={value}
              key={value}
              className="h-clay-8 min-w-[54px] text-clay-semantic-textSub gap-clay-1 duration-0 m-clay-1 typo-clay-label-medium-bold"
            >
              {label}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs.Root>

      {timelineFilter === 'MEMO' && (
        <Popover.Root open={popoverOpen} onOpenChange={setPopoverOpen}>
          <Popover.Trigger>
            <TextButton
              as="div"
              text={
                memoFilterOptions.find((el) => el.value === memoFilter)
                  ?.label ?? ''
              }
              variant="secondary"
              size="small"
              trailingIcon={<ChevronDown />}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="end" sideOffset={8}>
              <OptionList
                popOver
                value={memoFilter}
                setValue={(v) => {
                  setMemoFilter(v as MemoFilter)
                  setPopoverOpen(false)
                }}
                sx={{ width: '168px' }}
              >
                {memoFilterOptions.map(({ value, label }) => (
                  <OptionItem key={value} text={label} value={value} />
                ))}
              </OptionList>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
    </>
  )
}
