import { __ } from '~/shared/i18n'
import type {
  FindAllFilterSearchDatesType,
  FilterSearchDateRangeType,
} from '~/entities/order-search-filter'

export const dateTypeOptions: ReadonlyArray<{
  label: string
  value: FindAllFilterSearchDatesType
}> = [
  {
    label: __('주문일'),
    value: 'orderDate',
  },
  {
    label: __('결제일'),
    value: 'paymentDate',
  },
  {
    label: __('송장번호 입력일'),
    value: 'invoiceNoCreateDate',
  },
  {
    label: __('발송처리일'),
    value: 'deliverySendRequestDate',
  },
  {
    label: __('메모작성일'),
    value: 'memoCreateDate',
  },
  {
    label: __('배송완료일'),
    value: 'deliveryCompleteDate',
  },
  {
    label: __('배송지연일'),
    value: 'deliveryDelayDate',
  },
]

export const dateRangeOptions: ReadonlyArray<{
  label: string
  value: FilterSearchDateRangeType
}> = [
  {
    label: __('오늘'),
    value: 'TODAY',
  },
  {
    label: __('어제'),
    value: 'YESTERDAY',
  },
  {
    label: __('1주일'),
    value: 'WEEK',
  },
  {
    label: __('1개월'),
    value: 'MONTH',
  },
  {
    label: __('3개월'),
    value: 'THREE_MONTHS',
  },
  {
    label: __('직접입력'),
    value: 'CUSTOM',
  },
]
