import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResBulkActionSectionInvoicePrintValidateAllSchema,
  TPostPayloadBulkActionSectionInvoicePrintValidateAllSchema,
} from './bulk-action-section-invoice-print-validate-all.type'
import { postResBulkActionSectionInvoicePrintValidateAllSchema } from './bulk-action-section-invoice-print-validate-all.schema'

const axios = ApiClient()

export async function postBulkActionSectionInvoicePrintValidateAll({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoicePrintValidateAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionSectionInvoicePrintValidateAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/section/invoice/print/validate/all', body)
    .then((res) => res.data)

  postResBulkActionSectionInvoicePrintValidateAllSchema.parse(result.data)

  return result.data
}
