import React from 'react'

type PropsAreEqual<P> = (
  prevProps: Readonly<P>,
  nextProps: Readonly<P>
) => boolean

// eslint-disable-next-line @typescript-eslint/ban-types
export const withIsLocal = <P extends {}>(
  component: {
    (props: P): Exclude<React.ReactNode, undefined>
    displayName?: string
  },
  propsAreEqual?: PropsAreEqual<P> | false,

  componentName = component.displayName ?? component.name
): {
  (props: P): React.JSX.Element
  displayName: string
} => {
  function WithIsLocal(props: P) {
    if (import.meta.env.VITE_NODE_ENV !== 'local') {
      return (<></>) as React.JSX.Element
    }
    //Do something special to justify the HoC.
    return component(props) as React.JSX.Element
  }

  WithIsLocal.displayName = `withIsLocal(${componentName})`

  const wrappedComponent =
    propsAreEqual === false
      ? WithIsLocal
      : React.memo(WithIsLocal, propsAreEqual)

  //copyStaticProperties(component, wrappedComponent);

  return wrappedComponent as typeof WithIsLocal
}
