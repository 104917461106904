import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { OrderSectionItemDto } from '~/entities/order-detail'
import { SectionItemImmutable } from '../item-immutable/item-immutable'
import React from 'react'

interface IItermImmutableList {
  sectionItems: OrderSectionItemDto[]
}
export function ItemImmutableList({ sectionItems }: IItermImmutableList) {
  return (
    <table className="w-full">
      <colgroup>
        <col className="min-w-[var(--mx)]" />
        <col className="w-full" />
        <col className="min-w-[132px]" />
        <col className="min-w-[100px]" />
        <col className="min-w-[var(--mx)]" />
      </colgroup>
      <tbody>
        {pipe(
          sectionItems,
          A.map((e) => (
            <React.Fragment key={e.orderSectionItemCode}>
              <SectionItemImmutable item={e} />
            </React.Fragment>
          ))
        )}
      </tbody>
    </table>
  )
}
