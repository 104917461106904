import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResExternalOrderReleaseClaimHoldbackDto,
  type TPostPayloadExternalOrderReleaseClaimHoldbackDto,
  postResExternalOrderReleaseClaimHoldbackDto,
  postPayloadExternalOrderReleaseClaimHoldbackDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description external-order-release-claim-holdback
 */
export function postExternalOrderReleaseClaimHoldback({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (
    body: TPostPayloadExternalOrderReleaseClaimHoldbackDto
  ) {
    type R = typeof body
    type T = TPostResExternalOrderReleaseClaimHoldbackDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/release-claim-holdback`, body)
      .then((res) => res.data)

    postResExternalOrderReleaseClaimHoldbackDto.parse(result.data)
    return result
  }
}
