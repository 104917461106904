import { ApiClient } from '~/shared/api'
import { type TGetResShopConfigDto, getResShopConfigDto } from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description 사이트의 상점설정 조회
 */
export async function getShopConfig() {
  const result = await axios
    .get<ApiResponse<TGetResShopConfigDto>>('/site/shop-config')
    .then((res) => res.data)

  return result.data
}
