import React, { useDeferredValue } from 'react'
import {
  Modal,
  Flex,
  Spinner,
  Typography,
  ContentsBanner,
  Clay,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { TGetPayloadProductListDto } from '~/entities/product/product-list'
import { useModals } from '~/router'
import { getProductList } from '~/entities/product/product-list'
import { useInfiniteQuery } from '@tanstack/react-query'
import { match } from 'ts-pattern'
import { Virtuoso, ListRange } from 'react-virtuoso'
import { useProductAddModalAtom } from './product-add-modal.store'
import { ProdSearchBar } from './상품검색바/prod-search-bar'
import { ProdItem } from './상품아이템/prod-item'
import { EmptyProd } from './상품없음/empty-prod'
import { ModalFooter } from './모달푸터/modal-footer'

export function ProductAddModalContainer() {
  const modals = useModals()
  const modalData = useProductAddModalAtom.get()
  const { currency, handleAddProduct, ...ApiParams } = modalData
  const [isOverflowing, setIsOverflowing] = React.useState(false)
  const [keyword, setKeyword] = React.useState<string>('')
  const deferredKeyword = useDeferredValue(keyword)

  const queryParams: TGetPayloadProductListDto = {
    searchingKeyword: deferredKeyword || undefined,
    ...ApiParams,
  }
  const { data, fetchNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['product-list', queryParams],
      queryFn: ({ pageParam }) =>
        getProductList({
          ...queryParams,
          cursor: pageParam || undefined,
        }),
      initialPageParam: '',
      getNextPageParam: (lastPage) =>
        lastPage.count < ApiParams.count ? undefined : lastPage.cursorForNext,
      select: (d) => d.pages.flatMap((page) => page.list),
    })

  const handleRangeChange = ({ endIndex }: ListRange) => {
    if (data) {
      setIsOverflowing(endIndex < data.length - 1)
    }
  }

  return (
    <Modal isOpen={true} setClose={modals.close} width="medium">
      <Modal.Header text={__('상품추가')} />

      <Clay padding={vars.spacing[6]} paddingBottom={0}>
        <ContentsBanner
          type="secondary"
          variant="information"
          text={__(
            '상품 유형(실물, 디지털, 그룹 이용권)과 배송 정보(방식, 국가, 비용 타입)가 동일한 상품만 추가할 수 있어요.'
          )}
        />
      </Clay>

      {/* 검색바 */}
      <ProdSearchBar setSearchQuery={setKeyword} />

      {/* 상품 목록 */}
      {match({ isLoading, isEmpty: data?.length === 0 })
        .with({ isLoading: true }, () => (
          <Flex.Center
            height="472px"
            flexDirection="column"
            rowGap={vars.spacing[5]}
          >
            <Spinner size="medium" />
            <Typography variant="body-medium">
              {__('상품을 불러오고 있어요')}
            </Typography>
          </Flex.Center>
        ))
        .with({ isEmpty: true }, () => <EmptyProd />)
        .otherwise(() => (
          <Virtuoso
            style={{
              height: '472px',
            }}
            data={data}
            endReached={() => fetchNextPage()}
            itemContent={(_index, prod) => (
              <ProdItem prod={prod} key={prod.prodCode} />
            )}
            components={{
              Footer: () => (
                <Flex.Center minHeight={vars.spacing[6]}>
                  {isFetchingNextPage ? <Spinner size="small" /> : null}
                </Flex.Center>
              ),
            }}
            rangeChanged={handleRangeChange}
          />
        ))}

      <ModalFooter isOverflowing={data?.length ? isOverflowing : false} />
    </Modal>
  )
}
