export const STEPS = [
  '시작',
  '탭',
  '필터1',
  '필터2',
  '주문처리1',
  '주문처리2',
  '수량별주문처리1',
  '수량별주문처리2',
  '추가결제1',
  '추가결제2',
  '취소반품',
  '숨겨진기능',
] as const
