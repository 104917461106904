import { I18nextProvider } from 'react-i18next'
import React, { PropsWithChildren } from 'react'
import { overrideI18n } from '~/shared/i18n'
import { LocaleContext } from '~/app/provider/locale'

interface WithI18nextProps extends PropsWithChildren {
  locale?: string
}
export const AppI18nextProvider = ({
  children,
  locale: _locale,
}: WithI18nextProps) => {
  const { locale } = React.useContext(LocaleContext)
  // When the locale global changes
  // Set the new locale in i18n
  React.useEffect(() => {
    if (_locale) {
      overrideI18n.changeLanguage(_locale)
    } else {
      overrideI18n.changeLanguage(locale)
    }
  }, [locale])

  return <I18nextProvider i18n={overrideI18n}>{children}</I18nextProvider>
}
