import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { deliveryCountryQueryOptions } from '~/entities/site/delivery-country'
import {
  useSelectedProdCommons,
  useDeliverySetupAtom,
  useOrderInfoAtom,
} from '~/container/order-create/order-create.store'
import { __ } from '~/shared/i18n'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { Clay, OptionItem, OptionList, Select } from '@imwebme/clay-components'
import { RESET } from 'jotai/utils'

export function DelivCountryField() {
  const [orderInfo] = useOrderInfoAtom()
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const selectedProdCommons = useSelectedProdCommons()

  const { data: siteDelivCountryList } = useSuspenseQuery(
    deliveryCountryQueryOptions(orderInfo.unitSite?.code as string)
  )

  const delivCountryList: { code: string; name: string }[] = React.useMemo(
    () =>
      selectedProdCommons.delivCountryList
        .sort((a, b) => {
          if (a == 'KR') {
            return -1
          }
          if (b == 'KR') {
            return 1
          }
          return 0
        })
        .reduce<{ name: string; code: string }[]>((acc, cur) => {
          const found = siteDelivCountryList.find((el) => el.code === cur)
          if (!found) {
            return acc
          }
          return [...acc, found]
        }, []),
    [siteDelivCountryList, selectedProdCommons]
  )

  const findNameByCode = React.useCallback(
    (code?: string) => {
      const found = delivCountryList.find((el) => el.code === code)
      return found?.name ?? ''
    },
    [delivCountryList]
  )

  // 초기값 설정
  React.useEffect(() => {
    if (!deliverySetup.country && delivCountryList.length > 0) {
      const mainCountry =
        delivCountryList.find((el) => el.code === 'KR') ?? delivCountryList[0]

      setDeliverySetup({
        country: mainCountry.code,
        countryName: mainCountry.name,
      })
    }
  }, [deliverySetup, delivCountryList])

  if (delivCountryList.length === 1) {
    return null
  }
  return (
    <FieldLayout title={__('배송 국가')}>
      <Clay width="220px">
        <Select>
          <Select.Trigger>
            <Select.Input value={findNameByCode(deliverySetup.country)} />
          </Select.Trigger>
          <Select.Portal>
            <OptionList
              popOver
              value={deliverySetup.country}
              setValue={(v) => {
                const prevDelivTypeCd = deliverySetup._deliveryTypeCd
                const prevPayTypeCd = deliverySetup._deliveryPayTypeCd
                const prevReceiverName = deliverySetup.receiverName
                const prevReceiverCall = deliverySetup.receiverCall

                setDeliverySetup(RESET)

                setDeliverySetup({
                  country: v,
                  countryName: findNameByCode(v),
                  receiverName: prevReceiverName,
                  receiverCall: prevReceiverCall,
                  _deliveryTypeCd: prevDelivTypeCd,
                  _deliveryPayTypeCd: prevPayTypeCd,
                })
              }}
            >
              {delivCountryList.map((el) => (
                <OptionItem key={el.code} value={el.code} text={el.name} />
              ))}
            </OptionList>
          </Select.Portal>
        </Select>
      </Clay>
    </FieldLayout>
  )
}
