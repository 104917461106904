import { useCallback, useRef, useState, useEffect } from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'
import { Button } from '@imwebme/clay-components'
import { IconFile, IconFileAddSlate } from '~t/components/icon-file'
import { fpDate, fileSizeToString } from '~/shared/utils'
import { useFormContext } from 'react-hook-form'
import { FormValue } from '..'
import { DragDropFileItem } from './drag-drop-file-item'
import {
  fileUploadDragValidation,
  fileUploadValidation,
} from './drag-drop-file.fn'
import { cn } from '~/shared/utils'

export function DragDropFile() {
  const { setValue, resetField } = useFormContext<FormValue>()
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [file, setFile] = useState<{
    date: string
    file: File
  }>()
  const [message, setMessage] = useState<string | null>(null)
  const dragRef = useRef<HTMLLabelElement | null>(null)

  function resetFile() {
    setFile(undefined)
    resetField('file')
    resetField('fileName')
  }

  const fileUploadValidHandler = useCallback(
    fileUploadValidation({
      onChangeMessage: (msg) => {
        setMessage(msg)
      },
      onChangeFile: flow((_file: File) => {
        setFile({
          date: pipe(new Date(), fpDate('HH:mm:ss')),
          file: _file,
        })
        setValue('file', _file)
        setValue('fileName', _file.name)
      }),
    }),
    [setMessage, setFile]
  )

  const fileUploadDragHandler = useCallback(
    fileUploadDragValidation({
      onChangeMessage: (msg) => {
        setMessage(msg)
      },
      onChangeFile: flow((_file: File) => {
        setFile({
          date: pipe(new Date(), fpDate('HH:mm:ss')),
          file: _file,
        })
        setValue('file', _file)
        setValue('fileName', _file.name)
      }),
    }),
    [setMessage, setFile]
  )

  const handleDragIn = useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)
  }, [])

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer!.files) {
      setIsDragging(true)
    }
  }, [])

  const handleDrop = useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    fileUploadDragHandler(e)
    setIsDragging(false)
  }, [])

  const initDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener('dragenter', handleDragIn)
      dragRef.current.addEventListener('dragleave', handleDragOut)
      dragRef.current.addEventListener('dragover', handleDragOver)
      dragRef.current.addEventListener('drop', handleDrop)
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

  const resetDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener('dragenter', handleDragIn)
      dragRef.current.removeEventListener('dragleave', handleDragOut)
      dragRef.current.removeEventListener('dragover', handleDragOver)
      dragRef.current.removeEventListener('drop', handleDrop)
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

  useEffect(() => {
    initDragEvents()

    return () => resetDragEvents()
  }, [initDragEvents, resetDragEvents])

  return pipe(
    file,
    O.fromNullable,
    O.fold(
      () => (
        <>
          <input
            type="file"
            id="fileUpload"
            style={{ display: 'none' }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={fileUploadValidHandler}
          />

          <label
            className={cn(
              isDragging ? 'DragDrop-File-Dragging' : 'DragDrop-File'
            )}
            htmlFor="fileUpload"
            ref={dragRef}
          >
            <div
              className={cn(
                'border border-dashed p-[24px] grid justify-center text-center rounded-[8px]',
                isDragging && 'border-clay-semantic-borderHover'
              )}
            >
              <div className="justify-self-center py-[9px] text-[54px]">
                {isDragging ? <IconFile /> : <IconFileAddSlate />}
              </div>
              <div className="typo-clay-body-medium mt-[16px]">
                <Trans>
                  파일을 선택하거나 끌어다놓기
                  <br />
                  <span className="text-clay-semantic-textSub typo-clay-body-small">
                    파일은 최대 5MB의 XLSX, CSV 파일을 추가할 수 있어요.
                  </span>
                </Trans>
              </div>
              <div className="mt-[16px] justify-self-center">
                <Button as="div" variant="outlined" text={__('파일 선택')} />
              </div>
            </div>
          </label>
          {message && <div className="mt-[10px]">{message}</div>}
        </>
      ),
      (_file) => (
        <DragDropFileItem
          name={_file.file.name}
          size={pipe(
            _file.file.size,
            fileSizeToString,
            O.fold(
              () => null,
              (size) => size
            )
          )}
          onDelete={() => resetFile()}
        />
      )
    )
  )
}
