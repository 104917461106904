import React from 'react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { TReturnCompleteConfirmBase } from './return-complete-confirm.type'
import { useReturnCompleteConfirmAtom } from './return-complete-confirm.store'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSectionCode } from '~/shared/hooks/use-section-code'
import { Loading } from '~/shared/components/loading'
import { ErrorBoundary } from '@sentry/react'
import { ErrorCard } from '~/shared/components/error'

const ReturnCompleteConfirm = React.lazy(() =>
  import('./return-complete-confirm').then((module) => ({
    default: React.memo(module.ReturnCompleteConfirm),
  }))
)

interface TReturnCompleteConfirmContainer extends TReturnCompleteConfirmBase {}
export function ReturnCompleteConfirmContainer(
  props: TReturnCompleteConfirmContainer
) {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode()
  if (!sectionCode) {
    throw new Error(
      '반품금액계산페이지라 sectionCode는 필수 에러가 일어나지 않음'
    )
  }
  const [state, setState] = useReturnCompleteConfirmAtom.state({
    orderCode,
    sectionCode,
  })

  function onOpenChange(o: boolean) {
    setState((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        open: o,
      },
    }))
  }

  return (
    <AlertDialog.Root open={state.value.open} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <ReturnCompleteConfirm {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
