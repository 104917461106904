import { pipe, flow } from 'fp-ts/function'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'
import { IconSearchSm, IconWarningTriangle } from '@imwebme/icon'
import { __ } from '~/shared/i18n'
import { matches } from 'kled'
import { parcelCompanyQueryOptions } from '~/entities/parcel-company'
import { cn } from '~/shared/utils'
import { TargetPortal } from '../target-portal'
import { useParams } from '~/router'
import { SearchSM } from '@imwebme/clay-icons'

export function SearchParcelCompany({
  value,
  onChange,
  error,
}: {
  value: number | null | undefined
  onChange: (value: number) => void
  error?: boolean
}) {
  const { saleChannel } = useParams('/order/:saleChannel')
  const [query, setQuery] = useState('')
  const { data: parcelCompanyList = [] } = useQuery({
    ...parcelCompanyQueryOptions({
      saleChannelIdx: Number(saleChannel) as 1 | 2 | 3,
    }),
    select: (d) => d.data.list.filter((e) => e.isUse === 'Y'),
  })

  const filteredParcelCompanyList = React.useMemo(
    () =>
      parcelCompanyList.filter((e) =>
        query
          ? query.length <= e.name.length
            ? matches(query, e.name) > 0.1
            : false
          : true
      ),
    [parcelCompanyList, query]
  )

  return (
    <Combobox as="div" value={value} onChange={(val) => onChange(Number(val))}>
      {(activeOption) => (
        <TargetPortal open={activeOption.open} className="fixed z-[99999]">
          <TargetPortal.Slot name="trigger">
            <ComboboxButton as="div" className="relative">
              <ComboboxInput
                className={cn(
                  '!pl-[36px] flex h-10 w-full rounded-clay-medium border-none bg-transparent py-2 px-3 text-sm placeholder:text-slate-400  disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50',
                  'outline outline-offset-[-1px] outline-1 outline-clay-semantic-border',
                  'focus:outline-clay-semantic-focus focus:outline-2 outline-offset-[-2px] px-clay',
                  error && 'bg-clay-semantic-surfaceCritical border-none'
                )}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(idx) =>
                  parcelCompanyList.find((e) => e.idx === Number(idx))?.name ??
                  ''
                }
                placeholder={__('택배사를 선택해주세요')}
              />
              <ComboboxLabel className="absolute left-0 top-0 pl-[12px] pr-[8px] w-[var(--icon-space,36px)] h-full flex items-center justify-center cursor-pointer">
                <SearchSM />
              </ComboboxLabel>

              {error && (
                <IconWarningTriangle className="stroke-[2] text-clay-semantic-iconCritical absolute right-[10px] top-1/2 -translate-y-1/2" />
              )}
            </ComboboxButton>
          </TargetPortal.Slot>
          <TargetPortal.Slot name="content">
            {({ height }) =>
              filteredParcelCompanyList.length > 0 ? (
                <div className="absolute z-10 p-[8px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOptions
                    style={{
                      maxHeight: `${height}px`,
                    }}
                  >
                    {filteredParcelCompanyList.map((parcelCompany) => (
                      <ComboboxOption
                        key={parcelCompany.idx}
                        value={parcelCompany.idx}
                      >
                        {({ active, selected }) => (
                          <span
                            className={cn(
                              'block truncate relative rounded-[8px] cursor-default select-none py-2 pl-3 pr-9 text-gray-900 transition-all',
                              (active || selected) &&
                                'bg-clay-semantic-actionSecondaryHover'
                            )}
                          >
                            {parcelCompany.name}
                          </span>
                        )}
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                </div>
              ) : null
            }
          </TargetPortal.Slot>
        </TargetPortal>
      )}
    </Combobox>
  )
}
