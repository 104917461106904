import i18n from 'i18next'
import * as S from 'fp-ts/string'
import { pipe, flow } from 'fp-ts/function'

function insertCommas(n: number) {
  // get stuff before the dot
  const s1 = n.toString()
  const d = s1.indexOf('.')
  let s2 = d === -1 ? s1 : s1.slice(0, d)

  // insert commas every 3 digits from the right
  for (let i = s2.length - 3; i > 0; i -= 3) {
    s2 = s2.slice(0, i) + ',' + s2.slice(i)
  }

  // append fractional part
  if (d !== -1) {
    s2 += s1.slice(d)
  }

  return s2
}

/**
 * 커스텀 포맷터를 추가한다.
 * currency, price
 */
export const extendsFormatter = (_i18n: typeof i18n) => {
  // 3comma
  _i18n.services.formatter?.add(
    '3comma',
    flow(insertCommas, (s) => {
      // -, 으로 시작하면 -으로 바꾼다
      if (s.startsWith('-,')) {
        return s.replace('-,', '-')
      }
      return s
    })
  )

  _i18n.services.formatter?.add('currency', (value = 0, lng, options) => {
    const overrideOptions = { ...options }
    overrideOptions.price = 10
    // 기본값을 currency로 설정한다.
    overrideOptions.style = 'currency'
    overrideOptions.currencyDisplay = 'name'
    overrideOptions.removeCountry = true
    if (!overrideOptions?.currency) {
      overrideOptions.currency = 'KRW'
    }

    const getCurrencyName = new Intl.NumberFormat(lng, overrideOptions).format(
      value
    )
    const result = getCurrencyName.split(' ')
    return result[result.length - 1]
  })

  _i18n.services.formatter?.add(
    'currency_symbol',
    (value = 0, lng, options) => {
      const overrideOptions = { ...options }
      overrideOptions.price = 10
      // 기본값을 currency로 설정한다.
      overrideOptions.style = 'narrowSymbol'
      overrideOptions.currencyDisplay = 'name'
      overrideOptions.removeCountry = true
      if (!overrideOptions?.currency) {
        overrideOptions.currency = 'KRW'
      }

      const getCurrencyName = new Intl.NumberFormat(
        lng,
        overrideOptions
      ).format(value)
      const result = getCurrencyName.replace('0', '')
      return pipe('{{currency}}', S.replace('{{currency}}', result))
    }
  )
}
