import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { Controller, useController } from 'react-hook-form'
import { IconX } from '@imwebme/icon'
import { __ } from '~/shared/i18n'
import { OrderSectionItem } from '~t/order-section/order-section-item'
import { useCurrency } from '~/shared/hooks/use-currency'
import { cn } from '~/shared/utils'
import { externalOrderQueryOptions } from '~/entities/external-order'
import {
  모델_외부채널_주문섹션_품목,
  모델_외부채널_주문섹션_품목_UI,
  스펙_외부채널_주문섹션_품목,
} from '~/entities/external-order/model/external-order-section-item'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useFormContextExternalOrderReturnSelect } from '../external-order-return-select'
import { Checkbox, Tag } from '@imwebme/clay-components'
import { ExternalChannelStatusTag } from '~/shared/domain-components'
import { externalOrderClaimInfoQueryOptions } from '~/entities/external-order-claim-info'
import { 모델_외부채널_주문섹션_품목_클레임정보 } from '~/entities/external-order-claim-info/model/external-order-item-claim-info'
import { ExternalItemTag } from '../../external-order-detail/partials/section/partials/extenral-item-tag'

interface TExternalItemMutable {
  index: number
  sectionCode: string
  sectionItemCode: string
  sectionIndex: number
}
export function ExternalReturnItemMutable({
  sectionIndex,
  index,
  sectionCode,
  sectionItemCode,
}: TExternalItemMutable) {
  const orderCode = useOrderCode()
  const {
    data: { order, item, saleChannelItem },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      const _item = pipe(
        e.data.orderSectionList!,
        A.findFirst((e2) => e2.orderSectionCode === sectionCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트가 없습니다.')
        }),
        (e2) => e2.orderSectionItemList!,
        A.findFirst((e2) => e2.orderSectionItemCode === sectionItemCode),
        O.getOrElseW(() => {
          throw new Error('섹션 리스트에 해당 아이템이 없습니다.')
        })
      )
      const _saleChannelItem = pipe(
        e.data.saleChannelItemList,
        A.findFirst((e2) => e2.orderSectionItemCode === sectionItemCode),
        O.getOrElseW(() => {
          throw new Error('섹션 아이템코드에 해당하는 채널 아이템이 없습니다.')
        })
      )
      return {
        order: e.data,
        item: _item,
        saleChannelItem: _saleChannelItem,
      }
    },
  })

  const { control, getValues, setValue, register } =
    useFormContextExternalOrderReturnSelect()
  const { field: _check } = useController({
    name: `targetSectionInformation.${sectionIndex}.targetItemInformation.${index}._check`,
    control,
  })
  const $prod = new 모델_외부채널_주문섹션_품목_UI(item, saleChannelItem)
  const $item = new 모델_외부채널_주문섹션_품목(item, saleChannelItem)
  const currency = useCurrency()

  const checkboxRef = React.useRef<HTMLInputElement>(null)

  const rowClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // checkboxRef.current의 형제노드가 e.target이면 return
      if (checkboxRef.current?.nextSibling?.contains(e.target as Node)) {
        return
      }
      setValue(
        `targetSectionInformation.${sectionIndex}.targetItemInformation.${index}`,
        {
          ...getValues(
            `targetSectionInformation.${sectionIndex}.targetItemInformation.${index}`
          ),
          _check: !_check.value,
        }
      )
      return
    },
    [_check]
  )

  const channelProductNoList = pipe(
    order.saleChannelItemList,
    A.filter((e) => !!e.channelOrderItemNo),
    A.map((e) => e.channelOrderItemNo as string)
  )
  const { data: $claimInfo } = useSuspenseQuery({
    ...externalOrderClaimInfoQueryOptions({ orderCode })({
      channelProductNoList,
    }),
    select({ list }) {
      const result = pipe(
        list,
        A.findFirst(
          (e) => e.channelOrderItemNo === saleChannelItem?.channelOrderItemNo
        ),
        O.getOrElseW(() => null)
      )

      return new 모델_외부채널_주문섹션_품목_클레임정보(result)
    },
  })

  return (
    <div
      className={cn(
        '@container px-[var(--mx)] py-[16px] w-full text-left block',
        _check.value && 'bg-clay-semantic-layerSelected'
      )}
      onClick={rowClick}
    >
      <div className="grid grid-cols-[28px,1fr,160px,minmax(120px,auto)] w-full">
        <div className="h-[48px] flex items-center">
          <input
            type="hidden"
            {...register(
              `targetSectionInformation.${sectionIndex}.targetItemInformation.${index}.orderSectionItemCode`
            )}
          />
          <input
            type="hidden"
            {...register(
              `targetSectionInformation.${sectionIndex}.targetItemInformation.${index}.qty`
            )}
          />
          <Controller
            key={`targetSectionInformation.${sectionIndex}.targetItemInformation.${index}._check`}
            name={`targetSectionInformation.${sectionIndex}.targetItemInformation.${index}._check`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="pr-[12px]">
                <Checkbox
                  className="bg-white"
                  checked={value}
                  ref={checkboxRef}
                  onChange={(e) => onChange(!e.target.checked)}
                />
              </div>
            )}
          />
        </div>
        <div>
          <OrderSectionItem>
            <OrderSectionItem.Slot name="image">
              {item.imageUrl ? (
                <img
                  src={item.imageUrl}
                  loading="lazy"
                  draggable="false"
                  alt=""
                />
              ) : (
                <div className="bg-clay-semantic-surfaceSlate" />
              )}
            </OrderSectionItem.Slot>
            <OrderSectionItem.Slot name="name">
              <div className="flex gap-x-[6px] gap-y-[4px] items-center flex-wrap">
                <ExternalChannelStatusTag
                  statusCd={saleChannelItem.channelStatus}
                />
                <ExternalItemTag $claimInfo={$claimInfo} />
                <div className="text-clay-semantic-textSub">
                  {saleChannelItem.channelOrderItemNo}
                </div>
              </div>
              <div className="mt-[6px]">{$prod.상품이름}</div>
            </OrderSectionItem.Slot>
            <OrderSectionItem.Slot name="option">
              <div className="flex gap-clay-1 flex-wrap">
                {pipe(
                  $prod.item.optionInfo,
                  O.fromNullable,
                  O.fold(
                    () => null,
                    flow(
                      A.map((e) => (
                        <Tag
                          variant="other"
                          background
                          size="small"
                          text={`${e.key} ${e.value}`}
                          className="bg-[#15181E1A]"
                        />
                      ))
                    )
                  )
                )}
              </div>
              {스펙_외부채널_주문섹션_품목.품목배송메모.model($item) && (
                <div className="mt-clay-1 break-all typo-clay-body-medium text-clay-semantic-textSecondary">
                  {$prod.배송메모}
                </div>
              )}
            </OrderSectionItem.Slot>
          </OrderSectionItem>
        </div>
        <div className="relative justify-self-end">
          <div>
            <div className="grid grid-cols-[auto,auto,auto] typo-clay-body-medium items-center gap-x-[4px]">
              <div className="justify-self-end">
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: $prod.상품가격,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              <IconX className="stroke-[2] text-clay-semantic-icon" />
              {item.qty}
              <div
                className={cn(
                  'text-clay-semantic-textDisabled',
                  스펙_외부채널_주문섹션_품목.할인유무.isSatisfiedBy($item) &&
                    'line-through'
                )}
              >
                {__('{{price, 3comma}} {{currency, currency}}', {
                  price: item.baseItemPrice,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="text-right typo-clay-body-medium">
          {__('{{price, 3comma}} {{currency, currency}}', {
            price: item.itemPrice * item.qty,
            formatParams: {
              currency: {
                currency,
              },
            },
          })}
        </div>
      </div>
    </div>
  )
}
