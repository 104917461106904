import { TStep } from '~/container/onboarding/onboarding.type'
import { TArrow } from '.'

interface Item {
  title: string
  desc: string
  arrow: TArrow
}

export const TOOLTIP_DATA: Record<TStep, Item> = {
  시작: {
    title: '새로워진 주문 관리를 살펴볼까요?',
    desc: '주문 목록에서 주문 정보를 확인하고 주문을 처리할 수 있어요.',
    arrow: 'top-right',
  },
  탭: {
    title: '탭 기능을 통해 보고 싶은 주문만 볼 수 있어요',
    desc: '자주 쓰는 조건들을 설정해놓고 원하는 조건별로 주문을 모아볼 수 있어요',
    arrow: 'top-right',
  },
  필터1: {
    title: '검색 필터 기능이 개선됐어요',
    desc: '기본검색 외에 더 다양한 조건으로 개선된 검색 경험을 할 수 있어요.',
    arrow: 'top-right',
  },
  필터2: {
    title: '검색 필터 기능이 개선됐어요',
    desc: '자주 쓰는 검색조건들은 필터를 추가해서 더 편리하고 빠르게 고도화된 검색을 할 수 있어요.',
    arrow: 'left-bottom',
  },
  주문처리1: {
    title: '주문의 상태를 변경할 수 있어요',
    desc: '품목 열에서 품목들의 주문 상태를 \n변경할 수 있어요.',
    arrow: 'left-bottom',
  },
  주문처리2: {
    title: '대량 처리도 가능해요',
    desc: '체크 박스를 클릭하여 목록에 있는 \n주문을 선택하고 처리할 수 있어요.',
    arrow: 'bottom-center',
  },
  수량별주문처리1: {
    title: '주문의 상세 정보를 확인해 보세요',
    desc: '주문의 상세 정보를 주문 상세 페이지에서 확인할 수 있어요.',
    arrow: 'top-left',
  },
  수량별주문처리2: {
    title: '수량별로 주문 상태 변경이 가능해요',
    desc: '부분 배송, 취소, 반품 등 CS가 발생했을 때 \n제약사항 없이 유연하게 주문처리가 가능해요.',
    arrow: 'left-bottom',
  },
  추가결제1: {
    title: '상품을 추가할 수 있어요',
    desc: '상품 추가 기능을 통해 수량·품목 추가가 가능해졌어요.',
    arrow: 'top-right',
  },
  추가결제2: {
    title: '구매자에게 추가 결제를 받을 수 있어요',
    desc: '상품 추가를 통한 추가 금액을 고객에게 \n직접 요청할 수 있어요.',
    arrow: 'top-right',
  },
  취소반품: {
    title: '취소·반품 처리가 더 자유로워 졌어요',
    desc: '취소·반품과 환불 과정에서 품목 처리와 금액 설정 자유도가 더 높아졌어요.',
    arrow: 'none',
  },
  숨겨진기능: {
    title: '그 밖에 많은 기능들이 있어요',
    desc: '주문과 결제 상태를 자유롭게 되돌릴 수 있고 주문 히스토리까지 제공해요.',
    arrow: 'none',
  },
} as const
