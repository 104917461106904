import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as Eq from 'fp-ts/Eq'
import * as E from 'fp-ts/lib/Either'
import {
  TPatchPayloadReturnCompleteRdo,
  patchPayloadReturnCompleteRdo,
} from '~/entities/order-section/return-complete'
import {
  CouponList,
  DeliveryExtraPriceType,
  OrderSectionReturnCalcHeader,
  OrderSectionitemList,
  RefundPoint,
  RefundPriceType,
  CancelPriceTable,
} from './partials'
import { __ } from '~/shared/i18n'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { orderDetailOptions } from '~/entities/order-detail'
import { useSectionCode } from '~/shared/hooks/use-section-code'
import { returnSettlementsQueryOptions } from '~/entities/order-section/return-settlements'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { 모델_반품금액계산 } from '~/entities/order-section/return-complete/model'
import { ReturnCompleteConfirmContainer } from './modal/return-complete-confirm/return-complete-confirm.container'
import { useOrderSectionReturnCalcAtom } from '.'
import { cn } from '~/shared/utils'
import { DevTool } from '~/app/provider/react-hook-form'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'

export function OrderSectionReturnCalc() {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode()
  if (!sectionCode) {
    throw new Error(
      '반품금액계산페이지라 sectionCode는 필수 에러가 일어나지 않음'
    )
  }
  const selectedItems = useOrderSectionReturnCalcAtom.get({
    orderCode,
    sectionCode,
  })
  // ************************************** 데이터
  const { data: _data } = useSuspenseQuery({
    ...orderDetailOptions({ orderCode }),
    select(e) {
      return pipe(
        e.data.orderSectionList,
        O.fromNullable,
        O.fold(
          () => {
            throw new Error('orderSectionList is null')
          },
          flow(
            A.findFirst((r) => r.orderSectionCode === sectionCode),
            O.fold(
              () => {
                throw new Error('orderSectionCode is null')
              },
              (r) => r
            )
          )
        ),
        (r) => ({
          data: r,
          orderData: e.data,
        })
      )
    },
  })
  const targetItemInformation = pipe(
    selectedItems,
    (e) => ((e || []).length > 0 ? selectedItems : undefined),
    O.fromNullable,
    O.fold(
      () =>
        pipe(
          _data.data.orderSectionItemList,
          O.fromNullable,
          O.fold(
            () => {
              throw new Error('orderSectionItemList is null')
            },
            flow(
              A.map((e) => ({
                orderSectionItemCode: e.orderSectionItemCode,
                qty: e.qty,
              }))
            )
          )
        ),
      (e) => e
    )
  )
  const { data: returnSettlements } = useSuspenseQuery({
    ...returnSettlementsQueryOptions({
      orderCode,
      sectionCode,
      targetItemInformation,
    }),
    select(e) {
      return e.data
    },
  })

  const { data, orderData } = _data

  // ************************************** 폼
  const methods = useForm<TPatchPayloadReturnCompleteRdo>({
    resolver: zodResolver(patchPayloadReturnCompleteRdo),
    defaultValues: React.useMemo(
      () => ({
        refundPriceTypeCd: 'ORT01',
        deliveryExtraPriceType: 'SUB',
        targetItemInformation,
        refundPoint: returnSettlements.recreditedPoint,
        returnedCoupons: pipe(
          returnSettlements.usedCouponInfo,
          O.fromNullable,
          O.fold(
            () => [],
            flow(
              A.map((e) => ({
                _code: e.couponIssueCode,
                _check: false,
              }))
            )
          )
        ),
      }),
      []
    ),
    mode: 'onChange',
  })
  const { control, getValues, handleSubmit, watch } = methods

  // ************************************** 계산

  const [$return, setReturnModal] = React.useState(
    new 모델_반품금액계산({
      form: getValues(),
      returnSettlements,
    })
  )

  // 최초 마운트시 먼저 실행되는 자식 컴포넌트의 useEffect에서 폼값을 업데이트하기 때문에 필요
  React.useEffect(() => {
    setReturnModal(
      new 모델_반품금액계산({
        form: getValues(),
        returnSettlements,
      })
    )
  }, [getValues])

  React.useEffect(() => {
    const subscription = watch((value) => {
      setReturnModal(
        new 모델_반품금액계산({
          form: value as TPatchPayloadReturnCompleteRdo,
          returnSettlements,
        })
      )
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const orderSectionItemList = pipe(
    targetItemInformation,
    (e) =>
      e.length === 0
        ? E.left(data.orderSectionItemList)
        : E.right(data.orderSectionItemList),
    E.fold(
      (e) => e,
      flow(
        O.fromNullable,
        O.fold(
          () => [],
          flow(
            A.filter((e) =>
              pipe(
                targetItemInformation,
                A.elem(
                  Eq.fromEquals<{
                    orderSectionItemCode: string
                    qty: number
                  }>(
                    (x, y) => x.orderSectionItemCode === y.orderSectionItemCode
                  )
                )({
                  orderSectionItemCode: e.orderSectionItemCode,
                  qty: 0,
                })
              )
            ),
            // QTY 업데이트
            A.map((e) => {
              const qty = pipe(
                targetItemInformation,
                A.findFirst(
                  (r) => r.orderSectionItemCode === e.orderSectionItemCode
                ),
                O.fold(
                  () => e.qty,
                  (r) => r.qty
                )
              )
              return {
                ...e,
                qty,
              }
            })
          )
        )
      )
    )
  )

  return (
    <>
      <PageTopBar />
      <div className="container min-h-[100dvh] min-w-[768px] overflow-auto">
        <div className="space-y-[32px] pt-[64px] desktop:pt-[32px]">
          <OrderSectionReturnCalcHeader
            orderNo={pipe(orderData.orderNo, String)}
          />
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <input
                type="hidden"
                {...control.register('targetItemInformation')}
              />
              <div className="grid grid-cols-[2fr,1fr] gap-x-[20px]">
                <div className="[--mx:24px] py-[24px] bg-white rounded-[12px]">
                  <div className="mx-[var(--mx)] typo-clay-heading-xlarge-bold">
                    {__('취소상품')}{' '}
                    <span className="text-clay-semantic-textPrimary">
                      {data.orderSectionItemList?.length}
                    </span>
                  </div>
                  <div className="space-y-[40px] mx-[var(--mx)] mt-[24px]">
                    <div className="grid grid-cols-[minmax(120px,auto),1fr]">
                      <div className="typo-clay-heading-medium-bold">
                        {__('품목')}
                      </div>
                      <div>
                        <OrderSectionitemList
                          orderSectionItemList={orderSectionItemList}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-[minmax(120px,auto),1fr]">
                      <div className="typo-clay-heading-medium-bold">
                        {__('금액 설정')}
                      </div>
                      <div>
                        <RefundPriceType $return={$return} />
                      </div>
                    </div>
                    <div
                      className={cn(
                        'grid grid-cols-[minmax(120px,auto),1fr]',
                        $return.form.refundPriceTypeCd === 'ORT05' && 'hidden'
                      )}
                    >
                      <div className="typo-clay-heading-medium-bold">
                        {__('배송비')}
                      </div>
                      <div className="typo-clay-label-medium">
                        <DeliveryExtraPriceType $return={$return} />
                      </div>
                    </div>
                    <div className="grid grid-cols-[minmax(120px,auto),1fr]">
                      <div className="typo-clay-heading-medium-bold">
                        {__('혜택')}
                      </div>
                      <div className="space-y-[20px]">
                        <CouponList data={returnSettlements} />
                        <RefundPoint $return={$return} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <CancelPriceTable
                    $return={$return}
                    orderCode={orderCode}
                    sectionCode={sectionCode}
                  />
                </div>
              </div>
              <DevTool control={control} />
            </form>
            <ReturnCompleteConfirmContainer $return={$return} />
          </FormProvider>
        </div>
      </div>
    </>
  )
}

export function useOrderSectionReturnComplete() {
  const form = useFormContext<TPatchPayloadReturnCompleteRdo>()
  return form
}
