import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { TExternalOrderSectionDto, TExternalSaleChannelItemDto } from '../..'

export class 모델_외부채널_주문섹션 {
  data: TExternalOrderSectionDto
  private _saleChannelItemList?: TExternalSaleChannelItemDto[]

  constructor(
    data: TExternalOrderSectionDto,
    _saleChannelItemList?: TExternalSaleChannelItemDto[]
  ) {
    this.data = data
    this._saleChannelItemList = _saleChannelItemList
  }

  /**
   * @description
   * 해당 섹션에대한 saleChannelItemList
   */
  get saleChannelItemList(): TExternalSaleChannelItemDto[] | undefined {
    return match(this._saleChannelItemList)
      .with(P.nonNullable, (e) => this.includeSaleChannelItemsFilter(e))
      .otherwise(() => undefined)
  }
  /**
   * @description
   * saleChannelItemList는 주문서 전체 품목을 가지고있다.
   * orderSectionItemList에 해당하는 saleChannelItemList만 필터링해서 가져온다.
   */
  includeSaleChannelItemsFilter(
    saleChannelItemList: TExternalSaleChannelItemDto[]
  ) {
    // 섹션 orderSectionItemList에서 sectionItemCode를 모두 가져온다.
    // 그리고 sectionItemCode와 동일한 saleChannelItemList를 가져온다.
    const _saleChannelItemList = pipe(
      saleChannelItemList,
      A.filter((e) => {
        const sectionItemCodes = pipe(
          this.data.orderSectionItemList!,
          A.map((ee) => ee.orderSectionItemCode)
        )
        return sectionItemCodes.includes(e.orderSectionItemCode)
      })
    )
    return _saleChannelItemList
  }
}
