import { ApiClient, ApiResponse } from '~/shared/api'
import { type TInvoiceExcelBatchSchema } from './invoice-excel-batch.type'
import { InvoiceExcelBatchSchema } from './invoice-excel-batch.schema'
import { AxiosResponse } from 'axios'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/YRjhjX
 * @description 송장 엑셀 배치작업 조회 단건
 */
export async function getInvoiceExcelBatch(batchInvoiceCode: string) {
  const res = await axios
    .get<
      ApiResponse<TInvoiceExcelBatchSchema>
    >(`/order/bulk-action/section/invoice/excel/status/${batchInvoiceCode}`)
    .then((r) => r.data)

  InvoiceExcelBatchSchema.parse(res.data)
  return res.data
}
