import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResNextShippingCompleteDto,
  type TPatchPayloadNextShippingCompleteDto,
  patchResNextShippingCompleteDto,
  patchPayloadNextShippingCompleteDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Dx5IAq
 * @description [next] 외부 주문서 섹션 배송완료로 변경
 */
export function patchNextShippingComplete({
  orderCode,
}: {
  orderCode: string
}) {
  return async function (body: TPatchPayloadNextShippingCompleteDto) {
    type T = TPatchResNextShippingCompleteDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/next/shipping-complete`, body)
      .then((res) => res.data)

    patchResNextShippingCompleteDto.parse(result.data)
    return result
  }
}
