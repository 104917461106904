import { queryOptions } from '@tanstack/react-query'
import { getMemberDiscountInfo, TGetPayloadMemberDiscountInfoDto } from '.'

export function memberDiscountInfoQueryOptions({
  memberCode,
  params,
}: {
  memberCode: string
  params: TGetPayloadMemberDiscountInfoDto
}) {
  return queryOptions({
    queryKey: ['member-discount-info', memberCode, params],
    queryFn: () => getMemberDiscountInfo({ memberCode, params }),
  })
}
