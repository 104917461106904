import React from 'react'
import {
  Clay,
  Flex,
  SafeImageLoading,
  Tag,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useOrderInfoAtom, useProdSelectAtom } from '../order-create.store'
import { __ } from '~/shared/i18n'
import { Trans } from 'react-i18next'
import ProdThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { X } from '@imwebme/clay-icons'

export function ProdListStatic() {
  const [orderInfo] = useOrderInfoAtom()
  const currency = orderInfo.unitSite?.currency || 'KRW'
  const [prodSelect] = useProdSelectAtom()

  return (
    <>
      {prodSelect.map((el, index) => (
        <React.Fragment key={el._id}>
          <Flex padding={`${vars.spacing[4]} ${vars.spacing[6]}`}>
            {/* 상품 정보 */}
            <Flex
              columnGap={vars.spacing[3]}
              paddingRight={vars.spacing[4]}
              flex={1}
            >
              <Clay
                width="40px"
                height="40px"
                overflow="hidden"
                borderRadius={vars.rounded.medium}
                flexShrink={0}
              >
                <SafeImageLoading
                  src={el._prodImg}
                  fallback={<ProdThumbFallback />}
                />
              </Clay>

              <Clay>
                <Typography
                  variant="label-medium"
                  sx={{
                    marginBottom: vars.spacing[15],
                    wordBreak: 'break-all',
                  }}
                >
                  {el._prodName}
                </Typography>

                {el._optionLabels && (
                  <Flex
                    flexWrap="wrap"
                    gap={vars.spacing[1]}
                    paddingBottom={vars.spacing[2]}
                  >
                    {el._optionLabels.map((label, idx) => (
                      <Tag
                        key={idx}
                        variant="other"
                        text={label}
                        sx={{
                          '--clay-tag-background-color':
                            'rgba(113, 118, 128, 0.10)',
                          '--clay-tag-color': vars.semantic.color.textSub,
                        }}
                      />
                    ))}
                  </Flex>
                )}

                <Typography variant="body-medium">
                  {__('개당 {{price, 3comma}}{{currency, currency}}', {
                    price: el.itemPrice,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </Typography>
              </Clay>
            </Flex>

            {/* 수량 */}
            <Flex alignItems="center" height="fit-content">
              <X />
              <Typography variant="body-medium-bold">{el.qty}</Typography>
            </Flex>

            {/* 총 금액 */}
            <Typography
              as="div"
              variant="body-medium"
              sx={{
                paddingLeft: vars.spacing[5],
                textAlign: 'right',
              }}
            >
              <Trans
                i18nKey="<t>{{price, 3comma}}</t> {{currency, currency}}"
                values={{
                  price: el.itemPrice * el.qty,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                }}
                components={{
                  t: (
                    <Typography
                      as="span"
                      variant="body-medium-bold"
                      sx={{ display: 'inline-block', minWidth: '70px' }}
                    />
                  ),
                }}
              />
            </Typography>
          </Flex>

          {index < prodSelect.length - 1 && (
            <Clay
              as="hr"
              background={vars.semantic.color.divide}
              height="1px"
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}
