import { SafeImageLoading } from '@imwebme/clay-components'
import { match } from 'ts-pattern'
import KR from './assets/kr.png'
import EN from './assets/en.png'
import TW from './assets/tw.png'
import CN from './assets/cn.png'
import JP from './assets/jp.png'
import ES from './assets/es.png'
import VI from './assets/vi.png'
import { T상수_언어코드, 상수_언어코드 } from '~/entities/@x'

export function ImgByLangCode({
  langCode,
  size = '32px',
}: {
  langCode?: T상수_언어코드 | null
  size?: `${number}px`
}) {
  return (
    <SafeImageLoading
      width={size}
      height={size}
      src={match(langCode)
        .with(상수_언어코드.KR, () => KR)
        .with(상수_언어코드.EN, () => EN)
        .with(상수_언어코드.TW, () => TW)
        .with(상수_언어코드.CN, () => CN)
        .with(상수_언어코드.JP, () => JP)
        .with(상수_언어코드.ES, () => ES)
        .with(상수_언어코드.VI, () => VI)
        .otherwise(() => undefined)}
    />
  )
}
