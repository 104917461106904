/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * patch next-shipping-ready
 */
export const patchResNextShippingReadyDto = z.object({
  updateResult: z.boolean(),
})

export const targetItemInformationDto = z.object({
  orderSectionItemCode: z.string(),
  qty: z.number(),
})

export const patchPayloadNextShippingReadyDto = z.object({
  orderSectionCode: z.string(),
  targetItemInformation: z.array(targetItemInformationDto),
})
