import React from 'react'
import {
  RegisterInvoiceExcel,
  useRegisterInvoiceExcelAtom,
  useRegisterInvoiceExcelClose,
} from '.'
import useFunnel from '~/shared/components/funnel'
import { BatchAlertContainer } from './batch-alert'
import { useBatchAlertAtom } from './batch-alert/batch-alert.store'
import { toast } from 'react-toastify'
import { __ } from '~/shared/i18n'
import { IconCheck } from '@imwebme/icon'
import { TInvoiceExcelBatchSchema } from '~/entities/invoice-excel-batch'
import {
  모델_송장배치,
  스펙_송장배치,
} from '~/entities/invoice-excel-batch/model'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { RESET } from 'jotai/utils'
import { useInvoiceExcelHistoryAtom } from './invoice-excel-history/invoice-excel-history.store'

export function RegisterInvoiceExcelContainer() {
  const [Funnel, currentStep, setStep] = useFunnel([
    '모달',
    '알림',
  ] as const).withState({
    initialStep: '모달',
  })
  const tabCode = useTabCode()
  const isOpen = useRegisterInvoiceExcelAtom.get({ tabCode })
  const setData = useBatchAlertAtom.set()
  const close = useRegisterInvoiceExcelClose()
  const setOpen = useInvoiceExcelHistoryAtom.set({
    tabCode,
  })

  React.useEffect(() => {
    setStep({
      step: '모달',
    })
  }, [isOpen])

  function onSuccessCheckBatch(args: TInvoiceExcelBatchSchema) {
    const $model = new 모델_송장배치(args)

    if (스펙_송장배치.모두성공.isSatisfiedBy($model)) {
      toast.success(__('송장이 등록되었어요'), {
        icon: (
          <IconCheck className="text-clay-semantic-iconSuccess stroke-[2]" />
        ),
      })
      close()
    }
    if (스펙_송장배치.부분실패.isSatisfiedBy($model)) {
      setData({
        totalCount: args.totalCnt,
        successCount: args.successCnt,
      })
      setStep({
        step: '알림',
      })
    }
  }

  function onBatchAlertClose() {
    close()
    setStep({
      step: '모달',
    })
    setData(RESET)
  }

  function onBatchCheckFails() {
    setStep({
      step: '모달',
    })
    setOpen((prev) => ({ ...prev, value: true }))
  }

  return (
    <div className="[--sx:8px] [--space:20px]">
      <Funnel>
        <Funnel.Step name="모달">
          <RegisterInvoiceExcel onSuccessCheckBatch={onSuccessCheckBatch} />
        </Funnel.Step>
        <Funnel.Step name="알림">
          <BatchAlertContainer
            onClose={onBatchAlertClose}
            onClickCheckFails={onBatchCheckFails}
          />
        </Funnel.Step>
      </Funnel>
    </div>
  )
}
