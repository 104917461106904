import React from 'react'
import { TExternalDeliveryHold2InoviceBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalDeliveryHold2InoviceContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalDeliveryHold2Inovice }))
)

interface TExternalDeliveryHold2InovicePack
  extends TExternalDeliveryHold2InoviceBase {}
export function ExternalDeliveryHold2InovicePack(
  props: TExternalDeliveryHold2InovicePack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalDeliveryHold2InoviceContainer {...props} />
    </React.Suspense>
  )
}
