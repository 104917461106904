import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import {
  useRegisterInvoiceExcelAtom,
  type TRegisterInvoiceExcelBase,
  RegisterInvoiceExcelContainer,
} from '.'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { __ } from '~/shared/i18n'
import { ErrorCard } from '~/shared/components/error'
import { DropdownMenu } from '~/shared/components/dropdown-menu'

interface TRegisterInvoiceExcelPack extends TRegisterInvoiceExcelBase {}
export function RegisterInvoiceExcelPack(_: TRegisterInvoiceExcelPack) {
  const tabCode = useTabCode()
  const [state, setState] = useRegisterInvoiceExcelAtom.state({ tabCode })
  const isPolling = useRegisterInvoiceExcelAtom.polling.get({ tabCode })
  const reset = useRegisterInvoiceExcelAtom.reset({ tabCode })
  const pollingReset = useRegisterInvoiceExcelAtom.polling.reset({ tabCode })

  function close() {
    reset()
    pollingReset()
  }
  function open() {
    setState((prev) => ({ ...prev, value: true }))
  }

  function onOpenChange(o: boolean) {
    if (!o) {
      close()
      return
    }
    open()
  }

  return (
    <AlertDialog.Root
      open={state.value}
      defaultOpen={state.value}
      onOpenChange={onOpenChange}
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content
          className="p-0 border-none bg-white rounded-[20px]"
          onEscapeKeyDown={(e) => {
            // 단일배치코드로 폴링중일때는 모달닫기를 막는다.
            if (isPolling) {
              e.preventDefault()
            }
          }}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense>
              <RegisterInvoiceExcelContainer />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

RegisterInvoiceExcelPack.Button = function () {
  const tabCode = useTabCode()
  const setModal = useRegisterInvoiceExcelAtom.set({ tabCode })
  return (
    <DropdownMenu.Item
      onSelect={() => setModal((prev) => ({ ...prev, value: true }))}
    >
      {__('송장등록 - 엑셀')}
    </DropdownMenu.Item>
  )
}
