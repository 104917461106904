/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN } from '~/entities/@x'

/**
 * post bulk-action-section-delivery-hold-validate
 */
export const postResBulkActionSectionDeliveryHoldValidateSchema = z.object({
  fulfilled: z.number().nullish(),
  rejected: z.number().nullish(),
})
export const postPayloadBulkActionSectionDeliveryHoldValidateSchema = z.object({
  targetOrderCodes: z.array(z.string()),
  deliveryHoldStatus: z.nativeEnum(YN),
})
