import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import GrimIconGrayDocument from '~/shared/components/icon/grim-icon-gray-document.svg?react'

export function EmptyProd() {
  return (
    <Flex.Center flexDirection="column" height="472px">
      <GrimIconGrayDocument />
      <Typography sx={{ marginTop: vars.spacing[5] }} variant="body-medium">
        {__('검색어와 일치하는 상품이 없어요')}
      </Typography>
      <Typography
        sx={{ marginTop: vars.spacing[2] }}
        variant="body-small"
        colorToken="textSub"
      >
        {__('검색어를 확인해 주세요')}
      </Typography>
    </Flex.Center>
  )
}
