import { z } from 'zod'
import { YN } from '~/entities/@x'

const digitalProductEntity = z.object({
  siteCode: z.string().describe('사이트코드'),
  digitalProductCode: z.string().describe('고객보유디지털상품코드'),
  memberCode: z.string().describe('회원코드'),
  prodCode: z.string().describe('상품코드'),
  prodName: z.string().describe('상품명'),
  downloadLimitTime: z
    .string()
    .describe(
      '다운로드 가능기한 (무제한 - 9999-12-31 15:00:00), example: 2023-12-31T15:00:00Z'
    ),
  downloadLimitCnt: z
    .number()
    .describe('최초다운로드 가능 횟수 - 무제한인경우 999_9999'),
  downloadAbleCnt: z.number().describe('잔여 다운로드 가능 횟수'),
  orderSectionItemCode: z
    .string()
    .nullish()
    .describe('주문섹션품목코드 / 코드가 있으면 수정 없으면 생성'),
  isDel: z.nativeEnum(YN).describe('삭제여부'),
  wtime: z.string().describe('등록일시, example: 2022-03-31T10:35:48.000Z'),
  mtime: z.string().describe('변경일시, example: 2022-03-31T10:35:48.000Z'),
})

export const orderSectionItemDto = z.object({
  siteCode: z.string(),
  orderSectionItemCode: z.string(),
  orderSectionCode: z.string(),
  orderSectionItemNo: z.string(),
  orderItemCode: z.string(),
  gradeDiscount: z.number(),
  shippingPlaceCode: z.string().nullish(),
  orderCode: z.string(),
  prodOptionDetailCode: z.string().nullish(),
  prodCode: z.string(),
  prodName: z.string(),
  baseItemPrice: z.number(),
  itemPrice: z.number(),
  isTaxFree: z.nativeEnum(YN),
  weight: z.number(),
  isRequireOption: z.union([z.nativeEnum(YN), z.literal('')]),
  reviewCode: z
    .string()
    .nullish()
    .describe('상품리뷰코드(doznut_site.site_review)'),
  optionInfo: z
    .object({
      key: z.string(),
      value: z.string(),
    })
    .array()
    .nullish(),
  prodSkuNo: z.string().nullish(),
  optionSkuNo: z.string().nullish(),
  qty: z.number(),
  itemCouponDiscount: z.number(),
  itemPointAmount: z.number(),
  isRestock: z.nativeEnum(YN),
  wtime: z.string().nullish(),
  mtime: z.string(),
  prodNo: z.number().describe('상품 상품고유번호(DB의 idx랑 다름)'),
  imageUrl: z.string().nullish(),
  digitalProductInfo: digitalProductEntity.nullish(),
})

export type TOrderSectionItemDto = z.infer<typeof orderSectionItemDto>
