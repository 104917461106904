import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import {
  TStatusBackCancelRejectModalBase,
  useStatusBackCancelRejectModalAtom,
} from '.'

const StatusBackCancelRejectModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.StatusBackCancelRejectModal),
  }))
)

interface TStatusBackCancelRejectModalPack
  extends TStatusBackCancelRejectModalBase {}
export function StatusBackCancelRejectModalPack(
  props: TStatusBackCancelRejectModalPack
) {
  const [open, setState] = useStatusBackCancelRejectModalAtom.state({
    atomKey: props.atomKey,
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <StatusBackCancelRejectModalContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
