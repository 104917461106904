import React, { Children, DOMAttributes, PropsWithChildren } from 'react'
import { IconX } from '@imwebme/icon'
import { type TProductCard001 } from './product-card-001.ns'
import { __ } from '~/shared/i18n'

/**
 * 상품정보를 표시하기위한 primitive 컴포넌트입니다.
 */
function ProductCard001({ children }: PropsWithChildren) {
  const childrenArray = Children.toArray(children) as React.ReactElement[]

  const findChild = (childName: string) => {
    const result = childrenArray.find(
      (child) => child?.props?.name === childName
    )
    if (result === undefined) {
      throw new Error(`${childName} is undefined`)
    }
    return result
  }

  const ImageSlot = findChild('image')
  const NameSlot = findChild('name')
  const OptionSlot = findChild('option')
  const PriceSlot = findChild('price')

  return (
    <div className="grid grid-cols-[48px,1fr] gap-x-[12px]">
      <div
        className="rounded-[8px] w-[48px] h-[48px] relative overflow-hidden [&>*]:aspect-1 [&>*]:absolute [&>*]:w-full [&>*]:h-full [&>*]:object-cover"
        {...ImageSlot?.props}
      />
      <div className="semantic-p14">
        <div className="semantic-l14" {...NameSlot?.props} />
        <div
          className="text-clay-semantic-textSecondary mt-[4px]"
          {...OptionSlot?.props}
        />
        <div
          className="text-clay-semantic-text grid gap-y-[4px] mt-[8px]"
          {...PriceSlot?.props}
        />
      </div>
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'image' | 'name' | 'option' | 'price'
}
const Slot: React.FC<SlotProps> = () => null
/**
 * Slot은 ProductCard001의 자식으로 사용되며, ProductCard001의 특정 영역에 컨텐츠를 표시해줍니다.
 */
ProductCard001.Slot = Slot

// ==============================
// ProductCard001Template
// ==============================

class CProductCard001Template {
  props: TProductCard001.props
  constructor(props: TProductCard001.props) {
    this.props = props
  }

  // render
  Qty = () => {
    const { qty } = this.props
    if (qty && qty > 0) {
      return (
        <>
          <IconX className="stroke-[2]" />
          <span>{qty}</span>
        </>
      )
    }
    return undefined
  }

  Thumbnail = () => {
    if (!this.props.thumbnail) {
      return undefined
    }
    if (typeof this.props.thumbnail === 'string') {
      return (
        <img
          loading="lazy"
          draggable="false"
          src={this.props.thumbnail as string}
          alt=""
        />
      )
    }
    return this.props.thumbnail
  }

  OriginalPrice = ({
    children,
    ...props
  }: Omit<DOMAttributes<HTMLElement>, 'children'> & {
    children: (price: number) => React.ReactNode | string
  }) => {
    const { originalPrice, price } = this.props
    if (originalPrice && price && originalPrice !== price) {
      return <s {...props}>{children(originalPrice)}</s>
    }
    return undefined
  }
  Price = ({
    children,
    ...props
  }: Omit<DOMAttributes<HTMLElement>, 'children'> & {
    children: (price: number) => React.ReactNode | string
  }) => {
    const { price, originalPrice } = this.props
    if (originalPrice && price && originalPrice !== price) {
      return <div {...props}>{children(price)}</div>
    }
    if (originalPrice) {
      return <div {...props}>{children(originalPrice)}</div>
    }
    return undefined
  }
}
/**
 * 상품 아이템을 표시해줍니다.
 *
 * @param thumbnail 아이템의 이미지를 표시해줍니다.
 * @param name 아이템의 이름을 표시해줍니다.
 * @param option 아이템의 옵션을 표시해줍니다.
 * @param count 아이템의 수량을 표시해줍니다.
 * @param originalPrice 아이템의 원가를 표시해줍니다. 원가는 항상 포함되어야합니다.
 * @param price 아이템의 결제해야하는 금액을 표시해줍니다.
 * @param currency 아이템이 어느 통화인가를 표시해줍니다.
 */
function ProductCard001Template({
  id,
  thumbnail,
  name,
  option,
  qty,
  originalPrice,
  price,
  currency = 'KRW',
}: TProductCard001.props) {
  const CPCT = new CProductCard001Template({
    id,
    thumbnail,
    name,
    option,
    qty,
    originalPrice,
    price,
    currency,
  })
  const Thumbnail = React.useMemo(() => <CPCT.Thumbnail />, [thumbnail])

  return (
    <ProductCard001 data-id={id}>
      <ProductCard001.Slot name="image">{Thumbnail}</ProductCard001.Slot>
      <ProductCard001.Slot name="name">{name}</ProductCard001.Slot>
      <ProductCard001.Slot name="option">{option}</ProductCard001.Slot>
      <ProductCard001.Slot name="price">
        {/* 할인이 되지 않는 상품이라면 다르게 보여줘야하기 때문에 if문이 사용되었습니다 */}
        <div className="flex items-center text-clay-semantic-textSub">
          <CPCT.Price>
            {(p) =>
              __('{{price, 3comma}}{{currency, currency}}', {
                price: p,
                formatParams: {
                  currency: {
                    currency,
                  },
                },
              })
            }
          </CPCT.Price>
          <CPCT.Qty />
        </div>
        <CPCT.OriginalPrice>
          {(p) =>
            __('{{price, 3comma}}{{currency, currency}}', {
              price: p,
              formatParams: {
                currency: {
                  currency,
                },
              },
            })
          }
        </CPCT.OriginalPrice>
      </ProductCard001.Slot>
    </ProductCard001>
  )
}
export { ProductCard001, ProductCard001Template }
