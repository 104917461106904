import React from 'react'
import type { TCancelReasonEditBase } from './cancel-reason-edit.type'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { useCancelReasonEditAtom } from './cancel-reason-edit.store'

const CancelReasonEdit = React.lazy(() =>
  import('./cancel-reason-edit').then((module) => ({
    default: React.memo(module.CancelReasonEdit),
  }))
)

interface TCancelReasonEditContainer extends TCancelReasonEditBase {}
export function CancelReasonEditContainer(props: TCancelReasonEditContainer) {
  const [open, setState] = useCancelReasonEditAtom.state({
    orderCode: props.orderCode,
    sectionCode: props.sectionCode,
  })

  function onOpenChange(o: boolean) {
    // close될때
    setState({
      ...open,
      value: o,
    })
  }

  return (
    <AlertDialog.Root open={open.value} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <CancelReasonEdit {...props} onSuccess={() => onOpenChange(false)} />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
