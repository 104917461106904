import { memo, Fragment } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { fDate } from '~/shared/utils'
import { ApiResponse } from '~/shared/api'
import { TIMELINE_ACTION_TRIGGER_CODE } from '~/entities/order-history'
import { TYN } from '~/entities/@x'
import {
  OrderTimelineItem,
  TimelineItemRenderError,
} from './order-timeline-item'
import { OrderTimelineMemo } from './order-timeline-memo'
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { TGetResOrderHistorySchema } from '~/entities/order-history'
import { Skeleton } from '~/shared/components/ui/skeleton'
import { Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'

type THistoryItem = TGetResOrderHistorySchema['orderHistoryList'][number]

interface Props {
  orderHistoryList: Record<string, NonEmptyArray<THistoryItem>>
  memoActions: MemoActions
  isDeletingOrderMemo: boolean
  isPatchingOrderMemo: boolean
}

export interface MemoActions {
  toggleIsDone: ({ idx, value }: { idx: number; value: TYN }) => void
  delete: ({ idx }: { idx: number }) => void
  update: ({
    idx,
    value,
  }: {
    idx: number
    value: string
  }) => Promise<ApiResponse<unknown>>
}

export function OrderTimelineBody({
  orderHistoryList,
  memoActions,
  isDeletingOrderMemo,
  isPatchingOrderMemo,
}: Props) {
  return (
    <div className="px-[24px]">
      {Object.keys(orderHistoryList).map((date) => (
        <div key={date}>
          <Typography
            variant="heading-medium-bold"
            sx={{ paddingBottom: vars.spacing[2] }}
          >
            {fDate(new Date(date), 'MMM do')}
          </Typography>

          <div className="relative">
            <div className="w-[2px] h-full absolute bg-clay-semantic-layerSub left-[11px] top-[0px]"></div>

            {orderHistoryList[date].map(
              ({ idx, wtime, profileImgUrl, historyType, data, name }) => (
                <Fragment key={idx}>
                  {historyType === 'timeline' ? (
                    <ErrorBoundary
                      beforeCapture={(scope, error) => {
                        if (error instanceof TimelineItemRenderError) {
                          scope.setExtras({
                            ...(error.issues && { issues: error.issues }),
                            errorType: error.errorType,
                            logTypeCd: error.timelineData.logTypeCd,
                          })
                        }
                      }}
                    >
                      <OrderTimelineItem
                        {...data}
                        wtime={wtime}
                        profileImgUrl={profileImgUrl}
                        name={match(data.actionTriggerCd)
                          .with(TIMELINE_ACTION_TRIGGER_CODE.SYSTEM, () =>
                            __('시스템')
                          )
                          .with(TIMELINE_ACTION_TRIGGER_CODE.OPEN_API, () =>
                            __('외부시스템')
                          )
                          .otherwise(() => name)}
                      />
                    </ErrorBoundary>
                  ) : (
                    <OrderTimelineMemo
                      name={name}
                      memo={data}
                      wtime={wtime}
                      idx={idx}
                      isDeletingOrderMemo={isDeletingOrderMemo}
                      isPatchingOrderMemo={isPatchingOrderMemo}
                      memoActions={memoActions}
                      profileImgUrl={profileImgUrl}
                    />
                  )}
                </Fragment>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export const OrderTimeLineBodySkeleton = memo(() => (
  <div className="px-[24px] w-full h-full space-y-[16px]">
    {new Array(3).fill(0).map((_, i) => (
      <div key={i} className="flex gap-x-[8px]">
        <Skeleton className="rounded-full w-[24px] h-[24px]" />
        <Skeleton className="w-full h-[120px]" />
      </div>
    ))}
  </div>
))
