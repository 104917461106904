import React, { useEffect } from 'react'
import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  LocalColorSchemeArea,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { match } from 'ts-pattern'
import { isAfter } from 'date-fns'
import { useOnboardingTooltip } from './onboarding-tooltip.store'
import { __ } from '~/shared/i18n'
import { useDevice } from '~/shared/hooks/use-device'
import Visual from './visual.svg?react'
import Pointer from './pointer.svg?react'
import { RESET } from 'jotai/utils'

const END_DATE = '2024-10-10T15:00:00Z' as const
const Z_INDEX = 20 as const

export function OnboardingTooltip({ step }: { step: 0 | 1 }) {
  const [onboardingTooltip, setOnboardingTooltip] = useOnboardingTooltip()
  const { desktop } = useDevice()
  const isExpired = isAfter(new Date(), new Date(END_DATE))

  const handlePrev = React.useCallback(() => {
    setOnboardingTooltip('prev')
  }, [])

  const handleNext = React.useCallback(() => {
    setOnboardingTooltip('next')
  }, [])

  const handleClose = React.useCallback(() => {
    setOnboardingTooltip('close')
  }, [])

  useEffect(() => {
    // 만료되면 스토리지 초기화
    if (isExpired) {
      setOnboardingTooltip(RESET)
    }
  }, [isExpired])

  if (
    step !== onboardingTooltip.step ||
    onboardingTooltip.close ||
    isExpired ||
    !desktop
  ) {
    return null
  }
  return (
    <LocalColorSchemeArea theme="dark">
      {match(onboardingTooltip.step)
        .with(0, () => <Step0 {...{ handleClose, handleNext }} />)
        .with(1, () => <Step1 {...{ handlePrev, handleClose }} />)
        .otherwise(() => null)}
    </LocalColorSchemeArea>
  )
}

function Step0({
  handleClose,
  handleNext,
}: {
  handleClose: () => void
  handleNext: () => void
}) {
  return (
    <Clay
      borderRadius={vars.rounded.large}
      background={vars.semantic.color.surface}
      padding={vars.spacing[4]}
      position="absolute"
      right={0}
      top={86}
      width="240px"
      zIndex={Z_INDEX}
    >
      <Clay
        position="absolute"
        right="14px"
        top="0px"
        transform="translateY(-100%)"
      >
        <Pointer />
      </Clay>

      <Flex.Column rowGap={vars.spacing[3]}>
        <Typography variant="heading-medium-bold">
          {__('직접 주문을 만들 수 있어요')}
        </Typography>
        <Typography variant="body-medium">
          {__('이제 관리자가 직접 주문을 만들고 주문을 관리할 수 있어요.')}
        </Typography>
      </Flex.Column>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginTop={vars.spacing[6]}
      >
        <Typography variant="label-medium">1/2</Typography>
        <LocalColorSchemeArea theme="light">
          <ButtonGroup size="small" isInline>
            <Button
              variant="primary"
              size="small"
              text={__('닫기')}
              onClick={handleClose}
            />
            <Button
              variant="outlined"
              size="small"
              text={__('다음')}
              onClick={handleNext}
            />
          </ButtonGroup>
        </LocalColorSchemeArea>
      </Flex>
    </Clay>
  )
}

function Step1({
  handlePrev,
  handleClose,
}: {
  handlePrev: () => void
  handleClose: () => void
}) {
  return (
    <Clay
      borderRadius={vars.rounded.large}
      overflow="hidden"
      position="absolute"
      left={0}
      top={54}
      width="240px"
      zIndex={Z_INDEX}
    >
      <Visual />
      <Clay background={vars.semantic.color.surface} padding={vars.spacing[4]}>
        <Flex.Column rowGap={vars.spacing[3]}>
          <Typography variant="heading-medium-bold">
            {__('탭 순서 변경 기능이 추가됐어요')}
          </Typography>
          <Typography variant="body-medium">
            {__('보고 싶은 탭을 끌어서 앞뒤로 자유롭게 순서를 바꿀 수 있어요.')}
          </Typography>
        </Flex.Column>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginTop={vars.spacing[6]}
        >
          <Typography variant="label-medium">2/2</Typography>
          <LocalColorSchemeArea theme="light">
            <ButtonGroup size="small" isInline>
              <Button
                variant="primary"
                size="small"
                text={__('이전')}
                onClick={handlePrev}
              />
              <Button
                variant="outlined"
                size="small"
                text={__('닫기')}
                onClick={handleClose}
              />
            </ButtonGroup>
          </LocalColorSchemeArea>
        </Flex>
      </Clay>
    </Clay>
  )
}
