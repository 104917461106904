import { ApiClient, ApiResponse } from '~/shared/api'
import type { TOrder } from './class-order.type'

const axios = ApiClient()

/**
 * @deprecated 'src/features/order-detail'로 대체
 */
export const SingleOrderCheck = async (orderCode: string) => {
  const result = await axios
    .get<ApiResponse<TOrder>>(`/order/${orderCode}`)
    .then((res) => res.data)
  return result
}

// ====================================== 벌크액션 START
export const validatePayment = async (params: {
  targetOrderCodes: string[]
}) => {
  const result = await axios
    .post('/order/bulk-action/payment/validate', params)
    .then((res) => res.data)
  return result
}
// ====================================== 벌크액션 END
