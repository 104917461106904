import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import { TDeliveryTrace } from './invoice.type'
import type {
  TGetResInvoiceSchema,
  TPostResInvoiceSchema,
  TPostPayloadInvoiceSchema,
  TPatchResInvoiceSchema,
  TPatchPayloadInvoiceSchema,
  TDeleteResInvoiceSchema,
  TDeletePayloadInvoiceSchema,
} from './invoice.type'
import {
  getResInvoiceSchema,
  postResInvoiceSchema,
  postPayloadInvoiceSchema,
  patchResInvoiceSchema,
  patchPayloadInvoiceSchema,
  deleteResInvoiceSchema,
  deletePayloadInvoiceSchema,
} from './invoice.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Ayved9
 * @description 송장을 통한 배송 상세조회
 */
export async function getDeliveryTraceByInvoice({
  invoiceCode,
}: {
  invoiceCode: string
}) {
  const { data } = await axios.get<ApiResponse<TDeliveryTrace>>(
    `/invoice/${invoiceCode}/get-trace-info`
  )

  return data.data
}

/**
 * @API문서 https://5k.gg/3U3SMl
 * @description 송장 등록
 */
export function postInvoice() {
  return async function (body: TPostPayloadInvoiceSchema) {
    type T = TPostResInvoiceSchema
    type R = typeof body

    const result = await axios.post<T, AxiosResponse<T>, R>('/invoice', body)

    postResInvoiceSchema.parse(result.data)
    return result
  }
}

/**
 * @API문서 https://5k.gg/18cJQz
 * @description 송장 수정
 */
export function patchInvoice({ invoiceCode }: { invoiceCode: string }) {
  return async function (body: TPatchPayloadInvoiceSchema) {
    type T = TPatchResInvoiceSchema
    type R = typeof body
    const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
      `/invoice/${invoiceCode}`,
      body
    )

    patchResInvoiceSchema.parse(result.data)
    return result
  }
}

/**
 * @API문서 https://5k.gg/pNVMej
 * @description 송장 삭제
 */
export function deleteInvoice({ invoiceCode }: { invoiceCode: string }) {
  return async function (body: TDeletePayloadInvoiceSchema) {
    type T = TDeleteResInvoiceSchema
    type R = typeof body
    const result = await axios.delete<T, AxiosResponse<ApiResponse<T>>, R>(
      `/invoice/${invoiceCode}`,
      {
        data: body,
      }
    )

    deleteResInvoiceSchema.parse(result.data)
    return result
  }
}
