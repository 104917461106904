import { useMediaQuery } from 'usehooks-ts'

/**
 * 아임웹 PHP의 BP에 해당하는 미디어쿼리 결과를 반환합니다.
 * @todo 클레이 BP가 전사 기준으로 잡히면 이를 따르도록 합니다.
 */
export function useDevice(): {
  mobile: boolean
  tablet: boolean
  desktop: boolean
} {
  const mobile = useMediaQuery('(width < 768px)')
  const tablet = useMediaQuery('(768px <= width < 992px)')
  const desktop = useMediaQuery('(992px <= width)')
  return { mobile, tablet, desktop }
}
