import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TPurchaserInfoEditBase, usePurchaserInfoEditAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'

const PurchaserInfoEditContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.PurchaserInfoEdit),
  }))
)

interface TPurchaserInfoEditPack extends TPurchaserInfoEditBase {}
export function PurchaserInfoEditPack(props: TPurchaserInfoEditPack) {
  const orderCode = useOrderCode()
  const [open, setState] = usePurchaserInfoEditAtom.state({
    orderCode: orderCode + (props.atomKey || ''),
  })
  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pb-[20px] gap-0 max-w-[380px] pt-0">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <PurchaserInfoEditContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
