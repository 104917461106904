import { Control, Controller } from 'react-hook-form'
import { Coupon } from '../order-edit/order-edit-type'
import { __ } from '~/shared/i18n'
import { Checkbox } from '@imwebme/clay-components'

export const CouponCheckbox = ({
  control,
  coupons,
  onChangeCouponSelect,
}: {
  control: Control<any>
  coupons: Coupon[]
  onChangeCouponSelect?: (couponIssueCode: string) => void
}) => {
  const getChecked = (couponIssueCode: string, fieldValue: Coupon[]) =>
    fieldValue.some(({ couponIssueCode: code }) => code === couponIssueCode)
  const toggleCouponSelection = (
    couponIssueCode: string,
    isUnlimitedDate: 'Y' | 'N',
    field: any
  ) => {
    const fieldValue = field.value as Coupon[]
    // 유효기간 체크해서 지낫으면 체크 안되게
    const date_object = coupons.find(
      (coupon) => coupon.couponIssueCode === couponIssueCode
    )?.expireDate
    const currentTime = new Date().getTime() // current time in milliseconds
    const expireTime = date_object ? new Date(date_object).getTime() : 0 // expiration time in milliseconds

    if (expireTime - currentTime < 0 && isUnlimitedDate === 'N') {
      // 만료되면
      return
    }

    if (
      fieldValue.some(({ couponIssueCode: code }) => code === couponIssueCode)
    ) {
      const newCoupons = fieldValue.filter(
        (v) => v.couponIssueCode !== couponIssueCode
      )
      field.onChange(newCoupons)
    } else {
      const newCoupon = coupons.find(
        (coupon) => coupon.couponIssueCode === couponIssueCode
      )
      const newCoupons = [...fieldValue, newCoupon]
      field.onChange(newCoupons)
    }
  }
  return (
    <div>
      {coupons.map(
        ({ couponName, couponIssueCode, expireDate, isUnlimitedDate }) => {
          const date_object = expireDate ? new Date(expireDate) : ''
          const currentTime = new Date().getTime() // current time in milliseconds
          const expireTime = date_object ? date_object.getTime() : 0 // expiration time in milliseconds
          const disabled =
            expireTime - currentTime < 0 && isUnlimitedDate === 'N'
          const transformedExpireDate = (() => {
            if (isUnlimitedDate === 'Y') {
              return __('무제한')
            }
            if (disabled) {
              return __('기간만료')
            }
            return expireDate
          })()

          return (
            <Controller
              key={couponIssueCode}
              name="coupons"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Checkbox.Set>
                  <Checkbox.Label
                    label={disabled ? __('반환 불가 쿠폰') : couponName}
                  >
                    <Checkbox
                      checked={getChecked(couponIssueCode, field.value)}
                      disabled={disabled}
                      onChangeChecked={() => {
                        toggleCouponSelection(
                          couponIssueCode,
                          isUnlimitedDate,
                          field
                        )
                        onChangeCouponSelect?.(couponIssueCode)
                      }}
                    />
                  </Checkbox.Label>
                  <Checkbox.HelperText>
                    {transformedExpireDate}
                  </Checkbox.HelperText>
                </Checkbox.Set>
              )}
            />
          )
        }
      )}
    </div>
  )
}
