import { atom, useAtom } from 'jotai'
import { RESET, atomWithReset } from 'jotai/utils'

type TSectionActionName =
  | '굿스플로송장등록'
  | '반품거절'
  | '반품승인'
  | '수거완료'
  | '배송대기처리'
  | '배송보류해제'
  | '배송완료처리'
  | '배송중처리'
  | '송장번호수동입력'
  | '취소승인'
  | '취소거절'

type TOrderActionModal =
  | {
      action: TSectionActionName
      orderCode: string
      sectionCode: string
      open: true
    }
  | {
      open: false
    }

//========================================= 일반주문 =========================================

const _orderActionModalAtom = atomWithReset<TOrderActionModal>({
  open: false,
})
if (import.meta.env.VITE_NODE_ENV !== 'production') {
  _orderActionModalAtom.debugLabel = '주문미리보기-액션모달(일반주문)'
}

const orderActionModalAtom = atom(
  (get) => get(_orderActionModalAtom),
  (
    _get,
    set,
    update:
      | { action: TSectionActionName; orderCode: string; sectionCode: string }
      | false
  ) => {
    if (update === false) {
      set(_orderActionModalAtom, RESET)
    } else {
      set(_orderActionModalAtom, { ...update, open: true })
    }
  }
)

export function useOrderActionModal() {
  return useAtom(orderActionModalAtom)
}

//========================================= 외부채널 주문 =========================================

const _externalOrderActionModalAtom = atomWithReset<TOrderActionModal>({
  open: false,
})
if (import.meta.env.VITE_NODE_ENV !== 'production') {
  _externalOrderActionModalAtom.debugLabel = '주문미리보기-액션모달(외부채널)'
}

const externalOrderActionModalAtom = atom(
  (get) => get(_externalOrderActionModalAtom),
  (
    _get,
    set,
    update:
      | { action: TSectionActionName; orderCode: string; sectionCode: string }
      | false
  ) => {
    if (update === false) {
      set(_externalOrderActionModalAtom, RESET)
    } else {
      set(_externalOrderActionModalAtom, { ...update, open: true })
    }
  }
)

export function useExternalOrderActionModal() {
  return useAtom(externalOrderActionModalAtom)
}
