import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResBulkActionInvoiceDeleteValidateAllSchema,
  TPostPayloadBulkActionInvoiceDeleteValidateAllSchema,
} from './bulk-action-invoice-delete-validate-all.type'
import { postResBulkActionInvoiceDeleteValidateAllSchema } from './bulk-action-invoice-delete-validate-all.schema'

const axios = ApiClient()

export async function postBulkActionInvoiceDeleteValidateAll({
  body,
}: {
  body: TPostPayloadBulkActionInvoiceDeleteValidateAllSchema
}) {
  type R = typeof body
  type T = TPostResBulkActionInvoiceDeleteValidateAllSchema
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/invoice/delete/validate/all', body)
    .then((res) => res.data)

  postResBulkActionInvoiceDeleteValidateAllSchema.parse(result.data)

  return result.data
}
