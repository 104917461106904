import { ErrorBoundary } from '@sentry/react'
import React from 'react'

const 환불예정금액Container = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.환불예정금액),
  }))
)

export function 환불예정금액Pack() {
  return (
    <ErrorBoundary>
      <React.Suspense>
        <환불예정금액Container />
      </React.Suspense>
    </ErrorBoundary>
  )
}
