export const 상수_상품상태 = {
  판매중: 'sale',
  판매중지: 'nosale',
  품절: 'soldout',
} as const
export type T상수_상품상태 = (typeof 상수_상품상태)[keyof typeof 상수_상품상태]

export const 상수_상품품절상태 = {
  판매중: 'sale',
  품절: 'soldout',
} as const
export type T상수_상품품절상태 =
  (typeof 상수_상품품절상태)[keyof typeof 상수_상품품절상태]

export const 상수_상품타입 = {
  일반: 'normal',
  다운로드: 'download',
  디지털: 'digital',
  구독형: 'subscribe',
} as const
export type T상수_상품타입 = (typeof 상수_상품타입)[keyof typeof 상수_상품타입]

export const 상수_상품옵션타입 = {
  기본: 'default',
  입력: 'input',
  색상: 'color',
} as const
export type T상수_상품옵션타입 =
  (typeof 상수_상품옵션타입)[keyof typeof 상수_상품옵션타입]
