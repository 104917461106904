export const 상수_통화코드 = {
  KRW: 'KRW',
  USD: 'USD',
  AED: 'AED', // 아랍에미리트 디르함
  AUD: 'AUD', // 호주 달러
  CAD: 'CAD', // 캐나다 달러
  CHF: 'CHF', // 스위스 프랑
  CNY: 'CNY', // 중국 위안
  CZK: 'CZK', // 체코 코루나
  DKK: 'DKK', // 덴마크 크로네
  EUR: 'EUR', // 유로
  GBP: 'GBP', // 영국 파운드
  HKD: 'HKD', // 홍콩 달러
  HUF: 'HUF', // 헝가리 포린트
  ILS: 'ILS', // 이스라엘 세켈
  JPY: 'JPY', // 일본 엔
  MXN: 'MXN', // 멕시코 페소
  MYR: 'MYR', // 말레이시아 링깃
  NOK: 'NOK', // 노르웨이 크로네
  NZD: 'NZD', // 뉴질랜드 달러
  PHP: 'PHP', // 필리핀 페소
  PLN: 'PLN', // 폴란드 즐로티(즈워티)
  RUB: 'RUB', // 러시아 루블
  SEK: 'SEK', // 스웨덴 크로나
  SGD: 'SGD', // 싱가포르 달러
  THB: 'THB', // 태국 바트
  TWD: 'TWD', // 대만 달러
  VND: 'VND', // 베트남 동
} as const

export type T상수_통화코드 = (typeof 상수_통화코드)[keyof typeof 상수_통화코드]
