import { OrderFormData } from '~/entities/order-detail'
import { Item } from './components'

export function Checkbox({ title, description, value }: OrderFormData) {
  if (value.length === 0) {
    return null
  }
  return (
    <Item>
      <Item.Title>{title}</Item.Title>
      <Item.Description>{description}</Item.Description>
      <Item.Content className="flex gap-[4px] flex-wrap">
        {value.map((v, i) => (
          <div
            className="rounded-full px-[8px] py-[4px] text-clay-semantic-textSub bg-clay-semantic-surfaceSub typo-clay-label-small"
            key={i}
          >
            {v}
          </div>
        ))}
      </Item.Content>
    </Item>
  )
}
