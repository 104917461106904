import React from 'react'
import {
  Clay,
  Flex,
  IconButton,
  SafeImageLoading,
  Tag,
  TextButton,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { AccordionDown } from '@imwebme/clay-icons'
import { vars } from '@imwebme/clay-token'
import { useOrderInfoAtom, useProdSelectAtom } from '../order-create.store'
import { __ } from '~/shared/i18n'
import { Trash03 } from '@imwebme/clay-icons'
import { Trans } from 'react-i18next'
import ProdThumbFallback from '~/shared/components/icon/product-thumb-fallback.svg?react'
import { useProductDiscountModalAtom } from '~/widgets/product-discount-modal/product-discount-modal.store'
import { useModals } from '~/router'

export function ProdListEditable() {
  const [orderInfo] = useOrderInfoAtom()
  const currency = orderInfo.unitSite?.currency || 'KRW'
  const [prodSelect, setProdSelect] = useProdSelectAtom()
  const [_prodDiscountModal, setProdDiscountModal] =
    useProductDiscountModalAtom()
  const modals = useModals()

  const handleRemove = React.useCallback(
    (id: string) => setProdSelect({ type: 'remove', id }),
    []
  )

  const handleQtyChange = React.useCallback(
    (id: string, dir: 'up' | 'down', curQty: number) => {
      const nextQty = curQty + (dir === 'up' ? 1 : -1)
      setProdSelect({ type: 'update', id, payload: { qty: nextQty } })
    },
    []
  )

  return (
    <>
      {prodSelect.map((el, index) => (
        <React.Fragment key={el._id}>
          <Flex padding={`${vars.spacing[4]} ${vars.spacing[6]}`}>
            {/* 상품 정보 */}
            <Flex
              columnGap={vars.spacing[3]}
              paddingRight={vars.spacing[4]}
              flex={1}
            >
              <Clay
                width="40px"
                height="40px"
                overflow="hidden"
                borderRadius={vars.rounded.medium}
                flexShrink={0}
              >
                <SafeImageLoading
                  src={el._prodImg}
                  fallback={<ProdThumbFallback />}
                />
              </Clay>

              <Clay>
                <Typography
                  variant="label-medium"
                  sx={{
                    marginBottom: vars.spacing[15],
                    wordBreak: 'break-all',
                  }}
                >
                  {el._prodName}
                </Typography>

                {el._optionLabels && (
                  <Flex
                    flexWrap="wrap"
                    gap={vars.spacing[1]}
                    paddingBottom={vars.spacing[2]}
                  >
                    {el._optionLabels.map((label, idx) => (
                      <Tag
                        key={idx}
                        variant="other"
                        text={label}
                        sx={{
                          '--clay-tag-background-color':
                            'rgba(113, 118, 128, 0.10)',
                          '--clay-tag-color': vars.semantic.color.textSub,
                        }}
                      />
                    ))}
                  </Flex>
                )}

                <Typography variant="body-medium">
                  {__('개당 {{price, 3comma}}{{currency, currency}}', {
                    price: el.itemPrice,
                    formatParams: {
                      currency: {
                        currency,
                      },
                    },
                  })}
                </Typography>

                <Flex
                  alignItems="center"
                  columnGap={vars.spacing[4]}
                  paddingTop={vars.spacing[25]}
                >
                  <TextButton
                    text={__('판매가 변경')}
                    variant="secondary"
                    onClick={() => {
                      setProdDiscountModal({
                        currency,
                        prodImg: el._prodImg ?? null,
                        prodName: el._prodName,
                        optionLabels: el._optionLabels ?? null,
                        itemPrice: el.itemPrice,
                        baseItemPrice: el._baseItemPrice,
                        handleSubmit: (itemPrice) => {
                          setProdSelect({
                            id: el._id,
                            type: 'update',
                            payload: { itemPrice },
                          })
                        },
                      })
                      modals.open('/product/discount')
                    }}
                  />
                  <IconButton
                    icon={<Trash03 />}
                    size="tiny"
                    variant="secondary"
                    onClick={() => handleRemove(el._id)}
                  />
                </Flex>
              </Clay>
            </Flex>

            {/* 수량 */}
            <Textfield.Box sx={{ width: '90px', height: 'max-content' }}>
              <Textfield.Input
                value={el.qty}
                onChangeText={(v) => {
                  const n = Number.parseInt(v)
                  if (!Number.isNaN(n) && n > 0) {
                    setProdSelect({
                      type: 'update',
                      id: el._id,
                      payload: { qty: n },
                    })
                  }
                }}
                className="text-right typo-clay-body-medium-bold"
              />
              <Textfield.Addon>
                <Flex.Column justifyContent="center">
                  <button
                    className="h-clay-4"
                    onClick={() => handleQtyChange(el._id, 'up', el.qty)}
                    aria-label="increase product quantity"
                  >
                    <AccordionDown rotate={180} colorToken="icon-sub" />
                  </button>
                  <button
                    className="h-clay-4"
                    onClick={() => handleQtyChange(el._id, 'down', el.qty)}
                    disabled={el.qty === 1}
                    aria-label="decrease product quantity"
                  >
                    <AccordionDown
                      colorToken={el.qty === 1 ? 'icon-disabled' : 'icon-sub'}
                    />
                  </button>
                </Flex.Column>
              </Textfield.Addon>
            </Textfield.Box>

            {/* 총 금액 */}
            <Typography
              as="div"
              variant="body-medium"
              sx={{
                paddingLeft: vars.spacing[5],
                textAlign: 'right',
              }}
            >
              <Trans
                i18nKey="<t>{{price, 3comma}}</t> {{currency, currency}}"
                values={{
                  price: el.itemPrice * el.qty,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                }}
                components={{
                  t: (
                    <Typography
                      as="span"
                      variant="body-medium-bold"
                      sx={{ display: 'inline-block', minWidth: '70px' }}
                    />
                  ),
                }}
              />
            </Typography>
          </Flex>

          {index < prodSelect.length - 1 && (
            <Clay
              as="hr"
              background={vars.semantic.color.divide}
              height="1px"
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}
