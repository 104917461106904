import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'
import {
  TExternalPaymentInfoModalBase,
  useExternalPaymentInfoModalAtom,
} from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'

const ExternalPaymentInfoModalContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.ExternalPaymentInfoModal),
  }))
)

interface TExternalPaymentInfoModalPack extends TExternalPaymentInfoModalBase {}
export function ExternalPaymentInfoModalPack(
  props: TExternalPaymentInfoModalPack
) {
  const orderCode = useOrderCode()
  const [modal, setState] = useExternalPaymentInfoModalAtom.state({
    atomKey: props.atomKey,
    orderCode,
  })
  return (
    <AlertDialog.Root
      open={modal?.open}
      onOpenChange={(e) => setState((prev) => ({ ...prev, open: e }))}
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="opacity-0" />
        <AlertDialog.Content
          className="right-0 left-auto top-0 translate-y-0 translate-x-0 data-[state=open]:animate-in-right data-[state=closed]:animate-out-right my-[12px] mx-[12px] w-[380px] rounded-[8px] max-h-[calc(100dvh-12px*2)] pt-0"
          animate={false}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <ExternalPaymentInfoModalContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
