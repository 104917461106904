/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'

/**
 * post bank-transfer-confirm
 */
export const postResBankTransferConfirmDto = z.any()
export const postPayloadBankTransferConfirmDto = z.object({
  bankName: z.string(),
  bankAccount: z.string(),
  accountHolderName: z.string(),
})

/**
 * post virtual-confirm
 */
export const postResVirtualConfirmDto = z.any()
export const postPayloadVirtualConfirmDto = z.object({})
