import { __ } from '~/shared/i18n'
import { OrderSearchFilterOrderInfoFields } from '~/entities/order-search-filter'

export const orderInfoOptions: ReadonlyArray<{
  label: string
  value: OrderSearchFilterOrderInfoFields
}> = [
  {
    label: __('주문번호'),
    value: 'orderNo',
  },
  {
    label: __('운송장번호'),
    value: 'invoiceNo',
  },
  {
    label: __('운송장번호 입력 유무'),
    value: 'invoiceIsInput',
  },
  {
    label: __('주문자명'),
    value: 'ordererName',
  },
  {
    label: __('주문자 이메일'),
    value: 'ordererEmail',
  },
  {
    label: __('주문자 연락처'),
    value: 'ordererCall',
  },
  {
    label: __('입금자명'),
    value: 'depositorName',
  },
  {
    label: __('수취인명'),
    value: 'receiverName',
  },
  {
    label: __('수취인 연락처'),
    value: 'receiverCall',
  },
  {
    label: __('수취인 주소'),
    value: 'receiverAddress',
  },
  {
    label: __('입금은행'),
    value: 'depositBankName',
  },
  {
    label: __('현금영수증 신청상태'),
    value: 'cashReceiptStatus',
  },
  {
    label: __('주문유형'),
    value: 'orderCategory',
  },
  {
    label: __('배송지 입력'),
    value: 'deliveryIsInput',
  },
  {
    label: __('사용쿠폰'),
    value: 'shopCouponCode',
  },
  {
    label: __('배송보류 주문'),
    value: 'isDeliveryHold',
  },
  {
    label: __('결제요청 주문'),
    value: 'isRequestPayment',
  },
  {
    label: __('메모 입력 유무'),
    value: 'memoIsInput',
  },
]

export const orderInfoFieldKeyAndLabelPairs: {
  [key in OrderSearchFilterOrderInfoFields]: string
} = orderInfoOptions.reduce(
  (acc, { label, value }) => ({ ...acc, [value]: label }),
  {} as {
    [key in OrderSearchFilterOrderInfoFields]: string
  }
)
