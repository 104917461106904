import React from 'react'
import { Checkbox, Clay, Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'
import { PriceItem } from './price-item'
import { match } from 'ts-pattern'
import { 상수_배송결제타입 } from '~/entities/@x'
import { CashOnDelivery } from './cash-on-delivery'
import { useDeliverySetupAtom } from '../order-create.store'
import { TPostResAmountDueDto } from '~/entities/order/amount-due'

interface Props {
  amountDue: TPostResAmountDueDto
  currency: string
}

export function Step3PriceDetails({ amountDue, currency }: Props) {
  const [deliverySetup] = useDeliverySetupAtom()

  return (
    <Clay paddingInline={vars.spacing[6]}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={vars.spacing[5]}
      >
        <Typography variant="label-medium-bold">{__('합계')}</Typography>
        <Flex alignItems="center" columnGap={vars.spacing[1]}>
          <Trans
            i18nKey="<t1>{{price, 3comma}}</t1> <t2>{{currency, currency}}</t2>"
            values={{
              price: amountDue.amountDue,
              formatParams: {
                currency: {
                  currency,
                },
              },
            }}
            components={{
              t1: <Typography variant="heading-xlarge-bold" as="span" />,
              t2: <Typography variant="body-medium" as="span" />,
            }}
          />
        </Flex>
      </Flex>
      <Flex.Column
        borderRadius={vars.rounded.medium}
        padding={vars.spacing[4]}
        background={vars.semantic.color.surfaceSub}
        rowGap={vars.spacing[4]}
        marginBottom={vars.spacing[3]}
      >
        <Flex.Column rowGap={vars.spacing[2]}>
          <PriceItem
            label={__('상품 금액')}
            price={
              amountDue.basePrice -
              amountDue.salePrice -
              amountDue.gradeDiscount -
              amountDue.couponDiscount +
              amountDue.deliveryCouponDiscount
            }
          />
          <Flex columnGap={vars.spacing[2]}>
            <Clay width="2px" background={vars.semantic.color.divide} />
            <Flex.Column rowGap={vars.spacing[15]} flex={1}>
              <PriceItem
                label={__('상품가')}
                price={amountDue.basePrice - amountDue.salePrice}
                color="textSub"
              />
              <PriceItem
                label={__('등급할인')}
                price={amountDue.gradeDiscount}
                color="textSub"
                minus
              />
              <PriceItem
                label={__('쿠폰할인')}
                price={
                  amountDue.couponDiscount - amountDue.deliveryCouponDiscount
                }
                color="textSub"
                minus
              />
            </Flex.Column>
          </Flex>
        </Flex.Column>

        {match(deliverySetup._deliveryPayTypeCd)
          .with(상수_배송결제타입.선결제, () => (
            <Flex.Column rowGap={vars.spacing[2]}>
              <PriceItem
                label={__('배송비')}
                price={
                  amountDue.deliveryPrice +
                  amountDue.deliveryIslandPrice -
                  amountDue.deliveryCouponDiscount
                }
              />
              <Flex columnGap={vars.spacing[2]}>
                <Clay width="2px" background={vars.semantic.color.divide} />
                <Flex.Column rowGap={vars.spacing[15]} flex={1}>
                  <PriceItem
                    label={__('기본')}
                    price={amountDue.deliveryPrice}
                    color="textSub"
                  />
                  <PriceItem
                    label={__('도서산간')}
                    price={amountDue.deliveryIslandPrice}
                    color="textSub"
                  />
                  <PriceItem
                    label={__('배송비 할인')}
                    price={amountDue.deliveryCouponDiscount}
                    color="textSub"
                    minus
                  />
                </Flex.Column>
              </Flex>
            </Flex.Column>
          ))
          .with(상수_배송결제타입.착불결제, () => (
            <Flex.Column rowGap={vars.spacing[2]}>
              <CashOnDelivery
                price={
                  amountDue.cashOnDeliveryPrice +
                  amountDue.cashOnDeliveryIslandPrice
                }
              />

              <Flex columnGap={vars.spacing[2]}>
                <Clay width="2px" background={vars.semantic.color.divide} />
                <Flex.Column rowGap={vars.spacing[15]} flex={1}>
                  <PriceItem
                    label={__('기본')}
                    price={amountDue.cashOnDeliveryPrice}
                    color="textSub"
                  />
                  <PriceItem
                    label={__('도서산간')}
                    price={amountDue.cashOnDeliveryIslandPrice}
                    color="textSub"
                  />
                </Flex.Column>
              </Flex>
            </Flex.Column>
          ))
          .otherwise(() => null)}

        <PriceItem label={__('적립금')} price={amountDue.pointAmount} minus />
      </Flex.Column>
    </Clay>
  )
}
