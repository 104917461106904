import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TResStatusBackDto,
  TPayloadStatusBackDto,
} from './status-back.type'
import { resStatusBackDto } from './status-back.dto'
import { AxiosResponse } from 'axios'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/vayiL2
 * @description 주문 섹션 상태 변경 (back)
 */
export function patchStatusBack({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPayloadStatusBackDto) {
    type T = TResStatusBackDto
    type R = typeof body
    const result = await axios.patch<T, AxiosResponse<ApiResponse<T>>, R>(
      `/order/${orderCode}/sections/${sectionCode}/back`,
      body
    )

    return result
  }
}
