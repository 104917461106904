import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type { TPostPayloadBulkActionSectionInvoiceExcelSchema } from './bulk-action-section-invoice-excel.type'
import { extractFilenameFromContentDisposition } from '~/shared/utils'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/1771jP
 * @description 선택된 주문들의 주문섹션들에 대한 대량 엑셀 양식 다운로드
 */
export async function postBulkActionSectionInvoiceExcel({
  body,
}: {
  body: TPostPayloadBulkActionSectionInvoiceExcelSchema
}) {
  type R = typeof body
  type T = ApiVoidResponse
  const res = await axios
    .post<T, AxiosResponse<BlobPart>, R>(
      '/order/bulk-action/section/invoice/excel',
      body,
      {
        responseType: 'blob',
      }
    )
    .catch(async (error) => new Error(error))

  // res가 error가 아니라면
  if (res instanceof Error) {
    // blob으로 요청했지만 error일떄는 blob이 아니다.
    await axios
      .post<
        T,
        AxiosResponse<BlobPart>,
        R
      >('/order/bulk-action/section/invoice/excel', body)
      .catch((error) => {
        throw error
      })

    return null
  } else {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers['content-type'] })
    )
    const contentDisposition = res.headers['content-disposition'] as
      | string
      | undefined
    let fileName = 'unknown'
    if (contentDisposition) {
      fileName = pipe(
        contentDisposition,
        extractFilenameFromContentDisposition,
        O.fold(
          () => 'unknown.xlsx',
          (str) => str
        )
      )
    }
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    link.target = '_blank'
    link.click()
    return null
  }
}
