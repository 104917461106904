import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { sitePgQueryOptions, TGetResSitePgSchema } from '~/entities/site/pg'
import {
  siteUnitQueryOptions,
  TGetResSiteUnitSchema,
} from '~/entities/site/unit'
import { __g, __ } from '~/shared/i18n'
import {
  상수_주문상태,
  상수_결제상태,
  상수_섹션상태,
  YN,
  상수_섹션상태필터,
} from '~/entities/@x'
import {
  TOrderSearchTabSelectRdo,
  TOrderSearchTabRdo,
} from './order-search-tab.rdo'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  TPostPayloadOrderSearchTabSchema,
  TPatchPayloadOrderSearchTabSchema,
} from '~/entities/order-search-tab'
import { 외부채널Idx } from './order-search-tab.constants'

export interface TSelctData {
  name: keyof TOrderSearchTabSelectRdo
  label: string
  options: {
    label: string
    value: string
  }[]
}

/**
 * 탭 Select 데이터를 반환
 */
export function useSelectData(): TSelctData[] {
  const { data: pgMethodOptions = [] } = useSuspenseQuery({
    ...sitePgQueryOptions(),
    select: React.useCallback(
      (data: ApiResponse<TGetResSitePgSchema>) =>
        data.data.map((item) => ({
          value: item.methodCd,
          label: __g(item.methodCd),
        })),
      []
    ),
    staleTime: 1000 * 60 * 5,
  })

  const { data: saleChannelOptions = [] } = useSuspenseQuery({
    ...siteUnitQueryOptions(),
    select: React.useCallback((data: TGetResSiteUnitSchema) => {
      const serverData = data.list.map((item) => ({
        label: item.name ?? '',
        value: item.code,
      }))
      return serverData.concat([
        {
          label: __('네이버페이 (주문형)'),
          value: '2',
        },
        {
          label: __('카카오페이 구매'),
          value: '3',
        },
      ])
    }, []),
    staleTime: 1000 * 60 * 5,
  })

  return [
    {
      name: '_saleChannelIdx',
      label: __('주문채널'),
      options: saleChannelOptions,
    },
    {
      name: 'orderStatusCd',
      label: __('거래상태'),
      options: [
        { label: __('거래중'), value: 상수_주문상태.거래개시 },
        { label: __('거래종료'), value: 상수_주문상태.거래종료 },
      ],
    },
    {
      name: 'paymentStatusCd',
      label: __('결제상태'),
      options: [
        {
          label: __('결제대기'),
          value: 상수_결제상태.결제대기,
        },
        {
          label: __('결제기한 초과'),
          value: 상수_결제상태.결제기한초과,
        },
        {
          label: __('결제완료'),
          value: 상수_결제상태.결제완료,
        },
        {
          label: __('부분환불완료'),
          value: 상수_결제상태.부분환불완료,
        },
        {
          label: __('전체환불완료'),
          value: 상수_결제상태.환불완료,
        },
        {
          label: __('입금전취소'),
          value: 상수_결제상태.입금전취소,
        },
      ],
    },
    {
      name: 'orderSectionStatusCd',
      label: __('섹션상태'),
      options: [
        {
          label: __('상품준비'),
          value: 상수_섹션상태.상품준비,
        },
        {
          label: __('배송대기'),
          value: 상수_섹션상태.배송대기,
        },
        {
          label: __('배송중'),
          value: 상수_섹션상태.배송중,
        },
        {
          label: __('배송보류'),
          value: 상수_섹션상태필터.배송보류,
        },
        {
          label: __('배송완료'),
          value: 상수_섹션상태.배송완료,
        },
        {
          label: __('구매확정'),
          value: 상수_섹션상태.구매확정,
        },
        {
          label: __('취소접수'),
          value: 상수_섹션상태.취소접수,
        },
        {
          label: __('취소완료'),
          value: 상수_섹션상태.취소완료,
        },
        {
          label: __('반품접수'),
          value: 상수_섹션상태.반품접수,
        },
        {
          label: __('회수지시'),
          value: 상수_섹션상태.회수지시,
        },
        {
          label: __('회수중'),
          value: 상수_섹션상태.회수중,
        },
        {
          label: __('반품완료'),
          value: 상수_섹션상태.반품완료,
        },
        {
          label: __('교환접수'),
          value: 상수_섹션상태필터.교환접수,
        },

        {
          label: __('교환완료'),
          value: 상수_섹션상태필터.교환완료,
        },
        {
          label: __('수거완료'),
          value: 상수_섹션상태필터.수거완료,
        },
      ],
    },
    {
      name: 'pgMethod',
      label: __('결제수단'),
      options: pgMethodOptions,
    },
    {
      name: 'isMember',
      label: __('회원구분'),
      options: [
        {
          label: __('비회원'),
          value: YN.N,
        },
        {
          label: __('회원'),
          value: YN.Y,
        },
      ],
    },
    {
      name: 'isDomestic',
      label: __('배송범위'),
      options: [
        {
          label: __('국내'),
          value: YN.Y,
        },
        {
          label: __('해외'),
          value: YN.N,
        },
      ],
    },
  ]
}

/**
 * 취소 버튼 동작
 */
export function useHandleCancel() {
  const [searchParams] = useSearchParams()
  const from = searchParams.get('from')
  const navigate = useNavigate()

  const handleDismiss = React.useCallback(() => {
    navigate(from ?? '/orders', { replace: true })
  }, [from])

  return handleDismiss
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

type TApiPayload = Pick<
  TPostPayloadOrderSearchTabSchema,
  keyof TPostPayloadOrderSearchTabSchema &
    keyof TPatchPayloadOrderSearchTabSchema
>

/**
 * 폼 제출값을 받아 API 페이로드를 변환하는 함수
 */
export function useApiPayload() {
  const selectData = useSelectData()

  return React.useCallback(
    (data: TOrderSearchTabRdo): TApiPayload => {
      const columnCondition = {} as NonNullable<TApiPayload['columnCondition']>
      const saleChannelIdx: number[] = []
      const unitCode: string[] = []

      for (const [key, value] of Object.entries(
        data
      ) as Entries<TOrderSearchTabRdo>) {
        if (key === 'name') {
          continue
        }

        const options = selectData.find((item) => item.name === key)?.options
        const isAll = value.length === options?.length

        // 전체 선택된 필드는 제외한다
        if (isAll) {
          continue
        }

        // 주문채널 관련: README 참조
        if (key === '_saleChannelIdx') {
          for (const idx of value) {
            if (외부채널Idx.includes(idx)) {
              saleChannelIdx.push(parseInt(idx))
            } else {
              !saleChannelIdx.includes(1) && saleChannelIdx.push(1)
              unitCode.push(idx)
            }
          }
          continue
        }

        if (key === 'isMember' || key === 'isDomestic') {
          Object.assign(columnCondition, { [key]: value[0] })
          continue
        }

        Object.assign(columnCondition, { [key]: value })
      }

      return {
        name: data.name,
        columnCondition: {
          ...columnCondition,
          ...(saleChannelIdx.length > 0 && { saleChannelIdx }),
          ...(unitCode.length > 0 && { unitCode }),
        },
      }
    },
    [selectData]
  )
}
