import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { cva } from 'class-variance-authority'
import { cn } from '~/shared/utils'
import clsx from 'clsx'

const stepVariants = cva(
  'bg-clay-semantic-layerSelected rounded-full px-[6px] py-[0px] min-w-[24px] flex justify-center',
  {
    variants: {
      variant: {
        on: 'text-clay-semantic-textPrimary bg-clay-semantic-layerSelected',
        off: 'bg-clay-semantic-surfaceSub text-clay-semantic-textDisabled',
      },
    },
  }
)

export function OrderHeaderStep({
  step,
}: {
  step: {
    id: string
    label: string
    active: boolean
  }[]
}) {
  return (
    <div className="flex gap-x-[20px]">
      {pipe(
        step,
        A.mapWithIndex((index, item) => (
          <div
            className="flex gap-x-[8px] items-center typo-clay-label-medium"
            key={`step-${item.id}`}
          >
            <div
              className={cn(
                stepVariants({ variant: item.active ? 'on' : 'off' })
              )}
            >
              {index + 1}
            </div>
            <div
              className={clsx({ 'text-clay-semantic-textSub': !item.active })}
            >
              {item.label}
            </div>
          </div>
        ))
      )}
    </div>
  )
}
