import { CancelAllProductPreparation, TCancelAllProductPreparation } from '.'
import React from 'react'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

interface TCancelAllProductPreparationPack
  extends TCancelAllProductPreparation {}
export function CancelAllProductPreparationPack(
  props: TCancelAllProductPreparationPack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <CancelAllProductPreparation {...props} />
    </React.Suspense>
  )
}
