import { type TProductCard001, OrderEditReturnSectionContainer } from '~t'
import { __g } from '~/shared/i18n'

const OrderSectionContainerOverlay = ({
  id,
  label,
  items: _items,
  statusCd,
}: {
  id: string
  label: string
  items?: TProductCard001.props[]
  statusCd: string
}) => (
  <OrderEditReturnSectionContainer
    id={id}
    label={label}
    items={_items || []}
    // OSS01, OSS02
    title={__g(statusCd)}
  />
)

export default OrderSectionContainerOverlay
