import React from 'react'
import { IconArrowLeft } from '@imwebme/icon'
import { findChild } from '~/shared/utils'
import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { OrderEditHeader } from '~t/order-edit/order-edit-header'
import { OrderHeaderStep } from '~t/order-edit/order-edit-header/partials/order-header-step'
import { OrderTimelinePack } from '~/container/order/order-timeline'
import { Button } from '@imwebme/clay-components'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { 상수_판매채널 } from '~/entities/@x'

function Header({ orderNo }: { orderNo: string }) {
  const orderCode = useOrderCode()

  return (
    <OrderEditHeader>
      <OrderEditHeader.Slot name="link">
        <Link
          className="flex gap-x-[4px] items-center"
          to={'/order/:saleChannel/:orderCode'}
          params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
        >
          <IconArrowLeft className="stroke-[2]" />
          <span>{orderNo}</span>
        </Link>
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="title">{__('추가')}</OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="step">
        <OrderHeaderStep
          step={[
            {
              id: '1',
              label: '품목 선택',
              active: false,
            },
            {
              id: '2',
              label: '금액계산',
              active: true,
            },
          ]}
        />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-top">
        <OrderTimelinePack />
      </OrderEditHeader.Slot>
      <OrderEditHeader.Slot name="right-bottom">
        <Link
          to="/order/:saleChannel/:orderCode/add"
          params={{ orderCode, saleChannel: String(상수_판매채널.아임웹) }}
        >
          <Button as="div" variant="secondary" text={__('이전')} />
        </Link>
      </OrderEditHeader.Slot>
    </OrderEditHeader>
  )
}

export interface OrderEditAddCalcProps
  extends React.HTMLAttributes<HTMLDivElement> {
  itemLength?: number
  orderNo: string
}

const OrderEditAddCalc = ({
  children,
  itemLength = 0,
  orderNo,
}: OrderEditAddCalcProps) => {
  const ItemAddedSlot = findChild(children, 'item-added', true)
  const CountAddedSlot = findChild(children, 'count-added', true)
  const DiscountSlot = findChild(children, 'discount', true)
  const DeliveryFeeSlot = findChild(children, 'delivery-fee', true)
  const CalcSlot = findChild(children, 'calc', true)

  return (
    <div className="w-full min-h-[100vh]">
      <Header orderNo={orderNo} />
      {/** 금액계산 설정 카드 */}
      <div className="mt-[32px] w-[970px] mx-auto flex gap-[20px] justify-center flex-row">
        <div className="bg-clay-semantic-bg basis-2/3">
          <div className="rounded-[12px] py-[24px] bg-clay-semantic-surface">
            <div className="mx-[24px]">
              <div className="flex gap-[4px] semantic-h4-bold">
                <div>{__('추가 상품')}</div>
                <div className="text-clay-semantic-textPrimary">
                  {itemLength}
                </div>
              </div>
            </div>
            <div className="h-[16px]" />
            <div className="mt-[24px] mx-[24px] grid grid-cols-[121px,1fr] grid-flow-row gap-y-[16px] gap-x-[20px]">
              {/** 품목추가된상품 */}
              {ItemAddedSlot ? (
                <>
                  <div className="flex items-center semantic-h6-bold self-start">
                    {__('품목 추가됨')}
                  </div>
                  <div data-slot-name="item-added" {...ItemAddedSlot?.props} />
                </>
              ) : null}
              {/** 수량추가된 상품 */}
              {CountAddedSlot ? (
                <>
                  <div className="flex items-center semantic-h6-bold self-start">
                    {__('수량 추가됨')}
                  </div>
                  <div
                    data-slot-name="count-added"
                    {...CountAddedSlot?.props}
                  />{' '}
                </>
              ) : null}
              {/** 할인설정 */}
              {DiscountSlot ? (
                <>
                  <div className="flex items-center semantic-h6-bold self-start">
                    {__('할인')}
                  </div>
                  <div
                    {...DiscountSlot?.props}
                    className="self-end w-full flex flex-col gap-[20px]"
                  />
                </>
              ) : null}
              {/** 배송비 */}
              {DeliveryFeeSlot ? (
                <>
                  <div className="flex items-center semantic-h6-bold self-start">
                    {__('배송비')}
                  </div>
                  <div {...DeliveryFeeSlot?.props} className="w-full" />
                </>
              ) : null}
            </div>
          </div>
        </div>
        {/** 금액계산 카드 */}
        <div className="basis-1/3 flex flex-col gap-[16px] justify-start">
          <div className="bg-clay-semantic-bg">
            <div {...CalcSlot?.props} />
          </div>
          <Button
            native={{ type: 'submit' }}
            text={__('주문서 반영')}
            variant="primary"
            fullWidth
            size="large"
          />
        </div>
      </div>
    </div>
  )
}

interface SlotProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  name: 'item-added' | 'count-added' | 'discount' | 'delivery-fee' | 'calc'
}
const Slot: React.FC<SlotProps> = () => null

OrderEditAddCalc.Slot = Slot
export { OrderEditAddCalc }
