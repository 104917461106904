import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import {
  Clay,
  OptionItemCheckbox,
  OptionList,
  Select,
} from '@imwebme/clay-components'
import { TOrderSearchTabSelectRdo } from '../order-search-tab.rdo'
import { TSelctData } from '../order-seasrch-tab.hook'
import { __ } from '~/shared/i18n'
import { useFloating, size, autoUpdate, offset } from '@floating-ui/react'

export function SelectField({ label, name, options }: TSelctData) {
  const {
    control,
    formState: { errors },
  } = useFormContext<TOrderSearchTabSelectRdo>()
  const {
    field: { value, onChange },
  } = useController({ name, control })

  const isAll = value.length === options.length

  const [displayLabels, setDisplayLabels] = React.useState<string[]>(() => {
    const labels: string[] = []
    for (const v of value) {
      const displayName = options.find((option) => option.value === v)?.label
      if (displayName) {
        labels.push(displayName)
      }
    }
    return labels
  })
  const [maxHeight, setMaxHeight] = React.useState<number>()

  const { refs } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 10 }), // 클레이 Select.Trigger와 Select.Portal 사이 간격
      size({
        apply({ availableHeight }) {
          setMaxHeight(availableHeight)
        },
        padding: 20,
      }),
    ],
  })

  function handleLabelChange(labels: string[]) {
    setDisplayLabels(labels)

    const newValues: string[] = []
    for (const l of labels) {
      const newValue = options.find((option) => option.label === l)?.value
      if (newValue) {
        newValues.push(newValue)
      }
    }
    onChange(newValues)
  }

  function handleValueChange(values: string[]) {
    if (values.includes('all')) {
      if (value.length === 0) {
        onChange(options.map((x) => x.value))
        setDisplayLabels(options.map((x) => x.label))
      } else {
        onChange([])
        setDisplayLabels([])
      }
      return
    }

    onChange(values)

    const newDisplayLabels: string[] = []
    for (const v of values) {
      const displayName = options.find((option) => option.value === v)?.label
      if (displayName) {
        newDisplayLabels.push(displayName)
      }
    }
    setDisplayLabels(newDisplayLabels)
  }

  return (
    <>
      <Select closeOnSelect={false}>
        <Select.Trigger>
          {isAll ? (
            <Select.Input
              label={label}
              value={__('전체')}
              sx={{
                width: name === '_saleChannelIdx' ? '240px' : '180px',
              }}
            />
          ) : (
            <Select.MultiInput
              label={label}
              values={displayLabels}
              setValues={handleLabelChange}
              placeholder={__('선택해 주세요')}
              sx={{
                width: name === '_saleChannelIdx' ? '240px' : '180px',
              }}
              isValid={errors[name] ? false : undefined}
              helperText={errors[name]?.message}
            />
          )}
          <Clay ref={refs.setReference}></Clay>
        </Select.Trigger>

        <Select.Portal>
          <Clay ref={refs.setFloating} sx={{ maxHeight }}>
            <OptionList
              popOver
              values={value}
              setValues={handleValueChange}
              sx={{ maxHeight: 'inherit', overflowY: 'auto' }}
            >
              <OptionItemCheckbox
                value="all"
                text={__('전체')}
                checked={value.length > 0}
                isSelected={value.length > 0}
                isIndeterminate={
                  value.length > 0 && value.length < options.length
                }
              />
              {options.map((option) => (
                <OptionItemCheckbox
                  key={option.value}
                  value={option.value}
                  text={option.label}
                />
              ))}
            </OptionList>
          </Clay>
        </Select.Portal>
      </Select>
    </>
  )
}
