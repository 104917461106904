/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { patchPayloadNextShippingDto, targetItemInformationDto } from '.'

export const patchPayloadNextShippingRdo = patchPayloadNextShippingDto.extend({
  targetItemInformation: z.array(
    targetItemInformationDto.extend({
      _check: z.boolean().optional(),
    })
  ),
})
