/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN } from '~/entities/@x'

/**
 * post request
 */
export const postResRequestDto = z.any()
export const postPayloadRequestDto = z.object({
  isRequestPayment: z.nativeEnum(YN),
})
