import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResCashReceiptProcessManualDto,
  type TPostPayloadCashReceiptProcessManualDto,
  postResCashReceiptProcessManualDto,
  postPayloadCashReceiptProcessManualDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/wtAq9G
 * @description 현금영수증 수동 발급 처리
 */
export function postCashReceiptProcessManual({
  paymentCode,
}: {
  paymentCode: string
}) {
  return async function (body?: TPostPayloadCashReceiptProcessManualDto) {
    type R = typeof body
    type T = TPostResCashReceiptProcessManualDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/payment/${paymentCode}/cash-receipt/process/manual`, body)
      .then((res) => res.data)

    postResCashReceiptProcessManualDto.parse(result.data)
    return result
  }
}
