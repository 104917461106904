import React from 'react'
import { Spinner } from '@imwebme/clay-components'

export function DeliveryTraceLoading() {
  return (
    <div className="h-[394px] flex items-center justify-center">
      <Spinner size="medium" />
    </div>
  )
}
