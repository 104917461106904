import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { SetStateAction, atom, useAtom } from 'jotai'
import { type TExportTemplate } from '../export-template/export-template.type'
import { orderExportFormTabState } from '../export-template-tab'

const orderExportFormsOriginalState = atom<TExportTemplate[] | null>(null)
orderExportFormsOriginalState.debugLabel = 'orderExportFormsOriginalState'

export const orderExportFormsState = atom(
  (get) => {
    const at = get(orderExportFormsOriginalState)
    return at
  },
  (_, set, update: SetStateAction<TExportTemplate[] | null>) => {
    if (typeof update === 'function') {
      set(orderExportFormsOriginalState, (prev) => {
        const updated = update(prev)
        set(orderExportFormTabState, (_prev) => {
          // 기존 요소를 제외해서 업데이트받은 항목만 남긴다.
          const updatedForms = pipe(
            updated,
            O.fromNullable,
            O.fold(
              () => [],
              flow(
                // filter _prev.forms에 같은 id == idx가 있는지 확인한다.
                A.filter((e) =>
                  pipe(
                    _prev.forms,
                    A.findFirst((item) => item.id === e.idx),
                    O.fold(
                      () => true,
                      () => false
                    )
                  )
                ),
                // map
                A.map((e) => ({
                  id: e.idx,
                  label: e.name,
                  active: e.idx === 'temp-before',
                }))
              )
            )
          )
          return {
            ..._prev,
            forms: pipe([
              ...pipe(
                _prev.forms,
                A.map((e) => {
                  if (updatedForms.some((item) => item.id === 'temp-before')) {
                    return {
                      ...e,
                      active: false,
                    }
                  }
                  return e
                })
              ),
              ...pipe(
                updated,
                O.fromNullable,
                O.fold(
                  () => [],
                  flow(
                    // filter _prev.forms에 같은 id == idx가 있는지 확인한다.
                    A.filter((e) =>
                      pipe(
                        _prev.forms,
                        A.findFirst((item) => item.id === e.idx),
                        O.fold(
                          () => true,
                          () => false
                        )
                      )
                    ),
                    // map
                    A.map((e) => ({
                      id: e.idx === 'temp-before' ? 'temp' : e.idx,
                      label: e.name,
                      active: e.idx === 'temp-before',
                    }))
                  )
                )
              ),
            ]),
          }
        })
        if (updated === null) {
          return null
        }
        return pipe(
          updated,
          A.map((e) => ({
            ...e,
            idx: e.idx === 'temp-before' ? 'temp' : e.idx,
          }))
        )
      })
    } else {
      set(orderExportFormsOriginalState, update)
    }
  }
)
orderExportFormsState.debugLabel = 'orderExportFormsState'

const orderExportFormsTriggerState = atom<boolean>(true)

export const useOrderExportFormsAtom = {
  state: () => useAtom(orderExportFormsState),
  trigger: () => useAtom(orderExportFormsTriggerState),
}
