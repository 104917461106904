import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import {
  ProductSearchItemRCO,
  type T_ProductSearchItemRCO,
} from '../../../product-search-item/product-search-item-rco/product-search-item-rco'
import { useRCOOptionAtom } from './rco-option.store'
import { ProductSearchItemRCOValues } from '../../../product-search-item/product-search-item-rco/product-search-item-rco-values'
import type { TItem } from '../../product-search.type'

export const withProductSearchItemContainer =
  (WrappedComponent: React.ComponentType<T_ProductSearchItemRCO>) =>
  ({
    searchId,
    optionKey,
    prodCode,
    productData,
    ...props
  }: Omit<
    T_ProductSearchItemRCO,
    'options' | 'values' | 'optionDetails' | 'setValues' | 'id' | 'productPrice'
  > & {
    productData: TItem
    searchId: string
    prodCode: string
    optionKey: string
    onValueChange?: (e: any) => void
  }) => {
    // jotai가
    const [options] = useRCOOptionAtom.option(searchId, prodCode, optionKey)
    const [details] = useRCOOptionAtom.details(searchId, prodCode, optionKey)
    const [values, setValues] = useRCOOptionAtom.values(
      searchId,
      prodCode,
      optionKey
    )

    // options가있다면 필수조합타입이다. 그러니 렌더링할 필요가 없어서 완전히 예외처리한다.
    if (options === undefined) {
      return null
    }
    if (details === undefined) {
      return null
    }
    return (
      <>
        <WrappedComponent
          {...props}
          id={optionKey}
          setValues={setValues}
          options={options}
          values={values}
          optionDetails={details || []}
          productPrice={productData.price}
        />
        {values && (
          <ProductSearchItemRCOValues
            values={values}
            setValues={setValues}
            productPrice={pipe(productData.price, Number)}
          />
        )}
      </>
    )
  }

export const RCOOptionContainer =
  withProductSearchItemContainer(ProductSearchItemRCO)
