import { useMutation } from '@tanstack/react-query'
import { ExcelUploadTemplate } from './excel-upload-template'
import { useOrderAtom } from '~/container/orders/orders-table/orders-table-body'
import { postBulkActionSectionInvoiceExcel } from '~/entities/bulk/bulk-action-section-invoice-excel'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { useRecoilState, useRecoilValue } from 'recoil'
import { orderTableTotalCountState } from '~/container/orders/orders-table/orders-table.store'
import { orderTableSelectAllOrdersState } from '~/container/orders/orders-table/orders-table-body'
import { postBulkActionSectionInvoiceExcelAll } from '~/entities/bulk/bulk-action-section-invoice-excel-all'
import { useCurrentTabData } from '~/shared/hooks/use-current-tab-data/use-current-tab-data'
import { useOrderSearchFilterQueryString } from '~/container/order-search-filter/order-search-filter.store'
import { getTabQueryString } from '~/container/orders/orders-fn'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'
import { toast } from 'react-toastify'
import { __e } from '~/shared/i18n'

export function ExcelUploadTemplateContainer() {
  const tabCode = useTabCode()
  const [selectedOrderCodes] = useOrderAtom.codeSelection.state({
    tabCode,
  })
  const [selectAllOrders] = useRecoilState(orderTableSelectAllOrdersState)
  const tableTotal = useRecoilValue(orderTableTotalCountState)
  const tabData = useCurrentTabData()
  const filterQueryString = useOrderSearchFilterQueryString()
  const [searchKeyword] = useOrderSearchQuery()
  const payload = {
    ...(!!searchKeyword && { keyword: searchKeyword }),
    searchTab: getTabQueryString(tabData.data?.columnCondition),
    searchFilter: filterQueryString,
  }

  const { mutate: mutateTargetTypeCodes, isPending: isPendingTargetTypeCodes } =
    useMutation({
      mutationFn: postBulkActionSectionInvoiceExcel,
      onError(error) {
        toast.error(
          __e(error.response?.data.code || error.response?.data.message || '')
        )
      },
    })

  const {
    mutate: mutateTargetTypeCondition,
    isPending: isPendingTargetTypeCondition,
  } = useMutation({
    mutationFn: postBulkActionSectionInvoiceExcelAll,
    onError(error) {
      toast.error(
        __e(error.response?.data.code || error.response?.data.message || '')
      )
    },
  })

  return (
    <ExcelUploadTemplate
      count={
        selectAllOrders
          ? tableTotal || 0
          : selectedOrderCodes.value?.length || 0
      }
      onClick={() =>
        selectAllOrders
          ? mutateTargetTypeCondition({
              body: payload,
            })
          : mutateTargetTypeCodes({
              body: { targetOrderCodes: selectedOrderCodes.value || [] },
            })
      }
      disabled={
        (!selectAllOrders && selectedOrderCodes.value?.length === 0) ||
        isPendingTargetTypeCodes ||
        isPendingTargetTypeCondition
      }
      isLoading={
        selectAllOrders
          ? isPendingTargetTypeCondition
          : isPendingTargetTypeCodes
      }
    />
  )
}
