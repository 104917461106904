import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResOrdererDto,
  type TPatchPayloadOrdererDto,
  patchResOrdererDto,
  patchPayloadOrdererDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/es2M7I
 * @description 구매자 정보 수정
 */
export function patchOrderer({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadOrdererDto) {
    type T = TPatchResOrdererDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/orderer`, body)
      .then((res) => res.data)

    patchResOrdererDto.parse(result.data)
    return result
  }
}
