import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import type { TPostPayloadBulkActionCashReceiptApplyAllDto } from './bulk-action-cash-receipt-apply-all.type'

const axios = ApiClient()

export async function postBulkActionCashReceiptApplyAll<
  T = ApiVoidResponse,
  R = TPostPayloadBulkActionCashReceiptApplyAllDto,
>({ body }: { body: R }) {
  const result = await axios
    .post<
      T,
      AxiosResponse<ApiResponse<T>>,
      R
    >('/order/bulk-action/cash-receipt/all', body)
    .then((res) => res.data)

  return result.data
}
