import { Controller, useFormContext } from 'react-hook-form'
import type { TReturnRequest } from '~/container/order/order-return-info/order-retrun-info.type'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

/**
 * 기타일때 나오는 것
 */
export const RRT03Component = () => {
  const { control } = useFormContext<TReturnRequest>()
  return (
    <Controller
      name="retrieveRequestDto.retrieveMemo"
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div>
          <div className="relative semantic-p14">
            <textarea
              onChange={onChange}
              value={value}
              maxLength={2000}
              placeholder={__('기타 사유를 입력해주세요') || undefined}
              className={cn(
                'flex h-20 w-full rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900',
                error && 'bg-clay-semantic-surfaceCritical border-none'
              )}
            />
            <div className="absolute bottom-[8px] right-[12px] text-clay-semantic-textSub">
              {value?.length || 0}/2000
            </div>
          </div>
          {error && (
            <div className="typo-clay-body-small mt-[4px] text-clay-semantic-textCritical">
              {error.message}
            </div>
          )}
        </div>
      )}
    />
  )
}
