import React from 'react'
import { OrderFormData } from '~/entities/order-detail'
import { Item } from './components'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

export function Textarea({ title, description, value }: OrderFormData) {
  const [textOverflow, setTextOverflow] = React.useState(false)
  const [showMore, setShowMore] = React.useState(false)
  const textRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (textRef.current) {
      setTextOverflow(
        textRef.current.scrollHeight > textRef.current.clientHeight
      )
    }
  }, [textRef.current])

  if (value[0] === undefined) {
    return null
  }
  return (
    <Item>
      <Item.Title>{title}</Item.Title>
      <Item.Description>{description}</Item.Description>
      <Item.Content className="typo-clay-body-medium">
        <div
          className={cn('text-clay-semantic-text', !showMore && 'line-clamp-6')}
          ref={textRef}
        >
          {value[0]}
        </div>
        {textOverflow && !showMore && (
          <button
            className="text-clay-semantic-textSub"
            type="button"
            onClick={() => setShowMore(true)}
          >
            {__('더보기')}
          </button>
        )}
      </Item.Content>
    </Item>
  )
}
