import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as N from 'fp-ts/number'
import * as Eq from 'fp-ts/Eq'
import * as R from 'fp-ts/Record'
import { ExportTemplateSelectionSearch } from './export-template-selection-search'
import type { TSheetItem as TSearchSheetItem } from './export-template-selection-search.type'
import type { TSheetItem } from '../export-template-selection.type'
import { SELECTION } from '~/entities/excel/excel-templates'
import { useExportTemplateSelectionAtom } from '../export-template-selection.store'
import { __c } from '~/shared/i18n'

const sheetItems: TSearchSheetItem[] = pipe(
  SELECTION,
  R.keys,
  A.map((key) => ({
    id: SELECTION[key],
    name: __c(key),
  }))
)

export function ExportTemplateSelectionSearchContainer({
  selectionId,
}: {
  selectionId: string
}) {
  const [selectedSheetItems, setSelectedSheetItems] =
    useExportTemplateSelectionAtom.state(selectionId)
  function onSelected(items: TSearchSheetItem[]) {
    // items의 갯수는 1개면 단일 1개가 아니면 전체 선택
    if (items.length === 1) {
      const setItems: TSheetItem[] = []
      // sheetItems에서 제일 높은 order의 값을 가져오기
      const findMaxOrder = pipe(
        selectedSheetItems,
        O.fromNullable,
        O.fold(
          () => 0,
          flow(
            A.map((item) => item.order),
            A.sort(N.Ord),
            A.last,
            O.map((findOrder) => findOrder + 1),
            O.getOrElse(() => 0)
          )
        )
      )
      setItems.push({
        ...items[0],
        order: findMaxOrder,
      })
      setSelectedSheetItems(setItems)
      return
    }

    // 전체선택이면 그냥 order를 순서대로 만든다.
    pipe(
      items,
      A.mapWithIndex((index, item) => ({
        ...item,
        order: index,
      })),
      setSelectedSheetItems
    )
  }

  function sheet() {
    // sheetItems에서 selectedSheetItems있는 id들의 순서를 찾아서 checked안된것들을 먼저 보여주고 checked된것들을 보여준다.
    // checked된 것들의 정렬을 후순위로
    const filterSheetItems = pipe(
      sheetItems,
      A.filter((item) =>
        pipe(
          selectedSheetItems,
          O.fromNullable,
          O.fold(
            () => true,
            flow(
              A.map((selectedItem) => selectedItem.id),
              A.elem(Eq.eqStrict)(item.id),
              (e) => !e
            )
          )
        )
      )
    )
    return [
      ...filterSheetItems,
      ...pipe(
        selectedSheetItems,
        O.fromNullable,
        O.fold(
          () => [],
          flow(
            A.map((e) => ({
              ...e,
              disabled: true,
            }))
          )
        )
      ),
    ]
  }
  return (
    <ExportTemplateSelectionSearch
      onSelected={onSelected}
      sheet={sheet() || []}
    />
  )
}
