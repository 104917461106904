import React from 'react'
import { Eye02, EyeOff02, ReorderLine } from '@imwebme/clay-icons'
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DropResult,
  type DraggableProvided,
  type DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import * as Portal from '@radix-ui/react-portal'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/lib/function'
import { cn, reorder } from '~/shared/utils'
import { match } from 'ts-pattern'

export type Item = {
  id: string
  title: string
  visible: boolean
  onClickVisible?: (e: boolean) => void
  isVisibleControl?: boolean
  lock?: boolean
}

type ItemProps = {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  item: Item
  onToggleVisible: () => void
}

const PortalAwareItem = ({ item, onToggleVisible, ...props }: ItemProps) => {
  const { provided, snapshot } = props
  const usePortal: boolean = snapshot.isDragging

  const child = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={cn(
        'group flex items-center gap-x-clay-2 rounded-clay-medium px-clay-3 py-clay-2 typo-clay-label-medium bg-clay-semantic-actionSecondary hover:bg-clay-semantic-actionSecondaryHover',
        snapshot.isDragging && 'shadow-clay-layer'
      )}
    >
      <div
        className={cn(
          'grow whitespace-nowrap truncate',
          !item.visible && 'text-clay-semantic-textDisabled'
        )}
      >
        {item.title}
      </div>
      {match({ control: !!item.isVisibleControl, visible: item.visible })
        .with({ control: false }, () => null)
        .with({ visible: true }, () => (
          <button
            type="button"
            onClick={() => {
              item.onClickVisible?.(!item.visible)
              onToggleVisible()
            }}
            className="leading-none opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-clay-semantic-iconSecondary hover:text-clay-semantic-iconSecondaryHover"
          >
            <Eye02 color="inherit" />
          </button>
        ))
        .with({ visible: false }, () => (
          <button
            type="button"
            onClick={() => {
              item.onClickVisible?.(!item.visible)
              onToggleVisible()
            }}
            className="leading-none text-clay-semantic-iconDisabled hover:text-clay-semantic-iconSecondaryHover"
          >
            <EyeOff02 color="inherit" />
          </button>
        ))
        .exhaustive()}
      {!item.lock && (
        <div {...provided.dragHandleProps} className="leading-none">
          <ReorderLine colorToken="icon-sub" />
        </div>
      )}
    </div>
  )

  if (!usePortal) {
    return child
  }

  return <Portal.Root>{child}</Portal.Root>
}

export interface OrderColumnSortProps {
  list: Item[]
  // 이름이 잘못되었다. setList는 여기서 사용하는 것이 맞을까?
  onChanged: (
    list: {
      id: string
      visible: boolean
    }[]
  ) => void
}
const OrderColumnSort = ({ list, onChanged }: OrderColumnSortProps) => {
  // 필요한 기본값을 추가한다.
  // order-table에서 저장된 컬럼들을가지고 isVisibleControl 키를 추가해준다.
  const list2insertList = pipe(
    list,
    A.map((item) => {
      const objAssign = { ...item }
      // isVisibleControl 키가 없으면 추가
      // log.debug(objAssign.isVisibleControl)
      if (objAssign.isVisibleControl === undefined) {
        objAssign.isVisibleControl = true
      }
      return objAssign
    })
  )

  // ==========================
  // Local state
  // ==========================
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let updatedItems = reorder(
      list2insertList,
      result.source.index,
      result.destination.index
    )
    // result의 onClickVisible 키를 제거
    updatedItems = pipe(
      updatedItems,
      A.map((item) => {
        const objAssign = { ...item }
        return objAssign
      })
    )

    // onchanged의 값은 [id, id, id] 형태로 넘겨준다.
    onChanged(
      pipe(
        updatedItems,
        A.map((e) => ({ id: e.id, visible: e.visible }))
      )
    )
  }

  // visible 토글
  const onToggleVisible = (index: number) => {
    const updatedItems = pipe(
      list2insertList,
      A.mapWithIndex((i, item) => {
        if (i === index) {
          const result = {
            ...item,
            visible: !item.visible,
          }
          return result
        }
        return item
      })
    )
    onChanged(
      pipe(
        updatedItems,
        A.map((e) => ({ id: e.id, visible: e.visible }))
      )
    )
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(wrapProvided, wrapSnapshot) => (
          <div
            {...wrapProvided.droppableProps}
            ref={wrapProvided.innerRef}
            className={cn(
              'p-clay-2 bg-clay-semantic-surface space-y-clay-1 w-[188px]',
              wrapSnapshot.isDraggingOver && 'pointer-events-none' // 드래그중이면 다른 요소는 hover되지 않도록
            )}
          >
            {list2insertList.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <PortalAwareItem
                    item={{
                      ...item,
                    }}
                    provided={provided}
                    snapshot={snapshot}
                    onToggleVisible={() => onToggleVisible(index)}
                  />
                )}
              </Draggable>
            ))}
            {wrapProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export { OrderColumnSort }
