import { useEffect, useMemo } from 'react'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as N from 'fp-ts/number'
import { contramap } from 'fp-ts/Ord'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import {
  transformC3OrderListToUiOrderTable,
  getTabQueryString,
} from '~/container/orders/orders-fn'
import { __, __e, useLL } from '~/shared/i18n'
import { useParams } from 'react-router-dom'
import { useExportExcelTargetRadioAtom } from '../../export/export-excel-target-radio'
import { orderSearchTabQueryOptions } from '~/entities/order-search-tab'
import {
  useCurOrderSearchFilter,
  useOrderSearchFilterQueryString,
} from '~/container/order-search-filter/order-search-filter.store'
import { OrderSearchFilter } from '~/container/order-search-filter/order-search-filter'
import { useOrderDetailHeaderGoBack } from '~/container/order/order-detail/partials/order-detail-header/order-detail-header.store'
import { TSearchTabItem } from '~/entities/order-search-tab'
import { Typography, NumberBadge, IconButton } from '@imwebme/clay-components'
import { OrderSearch } from './order-search/order-search'
import { OrderSettings } from './order-settings/order-settings'
import { OrderColumnSortContainer } from './order-column-sort'
import { Filter } from '@imwebme/clay-icons'
import { cn } from '~/shared/utils'
import { OrdersTableBody } from './orders-table-body'
import { OrdersTableBodyEmpty } from './orders-table-body-empty'
import { findCurrentTabData } from '~/entities/order-search-tab/order-search-tab.calc'
import * as C from '@hyeon/calc-ts'
import { Pagination } from '~/shared/components/pagination'
import { match, P } from 'ts-pattern'
import { useSetRecoilState } from 'recoil'
import { OrderTableHeaderState } from './orders-table.store'
import {
  orderListQueryOptions,
  TGetResOrderListDto,
} from '~/entities/order-list'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'
import { 믹스패널 } from '~/shared/mixpanel'

function numify(str?: string): number | null {
  try {
    if (Number.isNaN(Number(str))) {
      return null
    }
    return Number(str)
  } catch (e) {
    log.warn(e)
    return null
  }
}

function ordersQuerySelector(data: ApiResponse<TGetResOrderListDto>) {
  return transformC3OrderListToUiOrderTable(data.data)
}

const bySortNo = pipe(
  N.Ord,
  contramap((item: TSearchTabItem) => item.sortNo)
)

export const OrdersTable = () => {
  const location = useLL()
  const params = useParams()
  const tabIdParsed = params.tabCode
  const pageNum = params.pageNumber ? numify(params.pageNumber) || 1 : 1
  const [searchKeyword, setSearchKeyword] = useOrderSearchQuery()
  const [filter, setFilter] = useCurOrderSearchFilter()

  const { data: tabs } = useSuspenseQuery({
    ...orderSearchTabQueryOptions(),
    select: (d) => d.data,
  })

  const activeTab = pipe(
    tabs,
    (a) => a.list,
    A.sortBy([bySortNo]),
    A.findFirst((item) =>
      typeof tabIdParsed === 'undefined'
        ? true
        : item.adminOrderSearchTabCode === tabIdParsed
    ),
    O.fold(
      () => tabs.list[0],
      (item) => item
    )
  )

  const tabCode = tabIdParsed ?? activeTab.adminOrderSearchTabCode

  const filterQueryString = useOrderSearchFilterQueryString()
  const ordersQueryString = useMemo(
    () => ({
      page: pageNum || 1,
      sort: 'wtime',
      ...(!!searchKeyword && { keyword: searchKeyword }),
      ...getTabQueryString(activeTab.columnCondition),
      ...filterQueryString,
    }),
    [pageNum, filterQueryString, searchKeyword, activeTab]
  )

  const { data: orders } = useSuspenseQuery({
    ...orderListQueryOptions(ordersQueryString),
    select: ordersQuerySelector,
  })

  const { data: columnOrderSortList } = useQuery({
    ...orderSearchTabQueryOptions(),
    select(e) {
      return pipe(
        e,
        findCurrentTabData(tabCode),
        (r) => r.visibleColumns,
        A.filter((r) => r.isVisible === 'Y'),
        A.map((r) => C.camelToSnake(r.key))
      )
    },
  })

  const setOrderDetailHeaderGoBack = useOrderDetailHeaderGoBack.set()
  useEffect(() => {
    setOrderDetailHeaderGoBack(`${location.pathname}${location.search}`)
  }, [location])

  // ====================================== 엑셀내보내기
  const [, setExportTartget] = useExportExcelTargetRadioAtom.state()
  useEffect(() => {
    if (activeTab) {
      setExportTartget({
        currentTabName: activeTab.name,
      })
    }
  }, [activeTab])

  function handleClickFilter() {
    dataLayer.push({
      event: 믹스패널.click_bo_oms_order_list_filter,
    })
    setFilter({ ...filter, open: true })
    setSearchKeyword(null)
  }

  // ==========================
  // 테이블 정보
  // ==========================
  const set_orderTableHeader_title = useSetRecoilState(
    OrderTableHeaderState.title
  )
  useEffect(() => {
    set_orderTableHeader_title(() => activeTab.name)
  }, [activeTab, set_orderTableHeader_title])

  const set_orderTableHeader_totalCount = useSetRecoilState(
    OrderTableHeaderState.totalCount
  )
  useEffect(() => {
    set_orderTableHeader_totalCount(() => orders.total)
  }, [orders, set_orderTableHeader_totalCount])

  return (
    <div className="pt-clay-5 pb-clay-6 rounded-clay-large bg-clay-semantic-surface">
      {/* 테이블 헤더 */}
      <div className="px-clay-6 pb-clay-4">
        <div className="flex flex-col gap-y-clay-3 desktop:flex-row justify-between">
          <div className="flex gap-clay-1 items-center">
            <Typography variant="heading-xlarge-bold">
              {activeTab.name}
            </Typography>
            <NumberBadge
              number={__('{{number, 3comma}}', {
                number: orders.total,
              })}
              variant="secondary"
              size="large"
            />
          </div>
          <div className="flex flex-col desktop:flex-row gap-clay-3">
            <OrderSearch />
            <div className="flex gap-clay-2">
              <IconButton
                native={{ type: 'button' }}
                variant="secondary"
                className={cn(
                  filter.open && 'bg-clay-semantic-actionSecondaryPressed'
                )}
                onClick={handleClickFilter}
                aria-label={__('필터설정')}
                icon={<Filter />}
              />
              <OrderColumnSortContainer />
              <OrderSettings />
            </div>
          </div>
        </div>
      </div>

      {/* 필터 */}
      {filter.open && <OrderSearchFilter />}

      {/* 테이블 바디 */}
      {match({
        total: orders.total,
        totalPage: orders.totalPage,
      })
        .with({ total: 0 }, () => <OrdersTableBodyEmpty />)
        .with({ totalPage: P.number.gt(1) }, () => (
          <>
            <OrdersTableBody
              data={orders.list}
              columnOrderSortList={columnOrderSortList}
            />
            <div className="px-clay-5 pt-clay-6 pb-clay-3 flex justify-center">
              <Pagination.Link total={orders.totalPage} page={pageNum} />
            </div>
          </>
        ))
        .otherwise(() => (
          <OrdersTableBody
            data={orders.list}
            columnOrderSortList={columnOrderSortList}
          />
        ))}
    </div>
  )
}
