import { pipe, flow } from 'fp-ts/function'
import { ChevronLeft, ChevronRight, DotsHorizontal } from '@imwebme/clay-icons'
import { Button } from '@imwebme/clay-components'
import { __, useLL } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { createPages } from './pagination.fn'
import { match } from 'ts-pattern'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import { IconButton } from '@imwebme/clay-components'

interface TPaginationBase {
  page?: number
  pageSize?: number
  total?: number
  onChange?: (page: number) => void
}

interface StatePagination extends TPaginationBase {
  onChange?: (page: number) => void
}

export const DEFAULT_PAGE_SIZE = 7

export function StatePagination({
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  total = 9999,
  onChange,
}: StatePagination) {
  const pageSlots = createPages({ page, pageSize, total })

  if (total <= 1) {
    return null
  }
  return (
    <div className="flex gap-x-[2px] items-center" role="navigation">
      <IconButton
        native={{ type: 'button' }}
        variant="secondary"
        size="small"
        isDisabled={page === 1}
        onClick={() => onChange?.(page - 1)}
        aria-label="prev-page"
        icon={<ChevronLeft color="inherit" />}
        className="disabled:bg-transparent"
      />

      {pageSlots.map((_page, idx) => {
        const isEllipsis = typeof _page === 'string'

        if (isEllipsis) {
          return (
            <IconButton
              native={{ type: 'button' }}
              key={idx}
              variant="secondary"
              size="small"
              aria-label="page-slot"
              icon={<DotsHorizontal color="inherit" />}
              className="pointer-events-none"
            />
          )
        }

        return (
          <Button
            native={{ type: 'button' }}
            key={idx}
            variant={page === _page ? 'primary' : 'secondary'}
            size="small"
            onClick={() => onChange?.(_page)}
            aria-label="page-slot"
            text={String(_page)}
            sx={{ minWidth: 'auto' }}
          />
        )
      })}

      <IconButton
        native={{ type: 'button' }}
        variant="secondary"
        size="small"
        isDisabled={page === total}
        onClick={() => onChange?.(page + 1)}
        aria-label="next-page"
        icon={<ChevronRight color="inherit" />}
        className="disabled:bg-transparent"
      />
    </div>
  )
}

interface TLinkPagination extends TPaginationBase {}
export function LinkPagination({
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  total = 9999,
}: TLinkPagination) {
  const location = useLL()
  const tabCode = useTabCode()
  const pageSlots = createPages({ page, pageSize, total })

  return (
    <div className="flex gap-x-[2px]">
      {match(page === 1)
        .with(true, () => (
          <IconButton
            native={{ type: 'button' }}
            variant="secondary"
            size="small"
            className="disabled:bg-transparent"
            aria-label="prev-page"
            isDisabled
            icon={<ChevronLeft color="inherit" />}
          />
        ))
        .with(false, () => (
          <Link
            to="/orders/tab/:tabCode/:pageNumber"
            searchParams={location.search}
            params={{
              tabCode,
              pageNumber: pipe(page - 1, String),
            }}
            aria-label="prev-page"
          >
            <IconButton
              as="div"
              variant="secondary"
              size="small"
              icon={<ChevronLeft color="inherit" />}
            />
          </Link>
        ))
        .exhaustive()}

      {pageSlots.map((_page, idx) => {
        const isEllipsis = typeof _page === 'string'

        if (isEllipsis) {
          return (
            <IconButton
              native={{ type: 'button' }}
              key={idx}
              variant="secondary"
              size="small"
              aria-label="page-slot"
              icon={<DotsHorizontal color="inherit" />}
              className="pointer-events-none"
            />
          )
        }

        return (
          <Link
            key={idx}
            to="/orders/tab/:tabCode/:pageNumber"
            searchParams={location.search}
            params={{
              tabCode,
              pageNumber: pipe(_page, String),
            }}
            aria-label="page-slot"
          >
            <Button
              as="div"
              size="small"
              key={idx}
              variant={page === _page ? 'primary' : 'secondary'}
              text={String(_page)}
              className="min-w-[33px] leading-none min-h-[33px]"
            />
          </Link>
        )
      })}

      {match(page === total)
        .with(true, () => (
          <IconButton
            native={{ type: 'button' }}
            variant="secondary"
            size="small"
            className="disabled:bg-transparent"
            aria-label="next-page"
            isDisabled
            icon={<ChevronRight color="inherit" />}
          />
        ))
        .with(false, () => (
          <Link
            to="/orders/tab/:tabCode/:pageNumber"
            searchParams={location.search}
            params={{
              tabCode,
              pageNumber: pipe(page + 1, String),
            }}
            aria-label="next-page"
          >
            <IconButton
              as="div"
              variant="secondary"
              size="small"
              className="min-w-[33px] leading-none min-h-[33px]"
              icon={<ChevronRight color="inherit" />}
            />
          </Link>
        ))
        .exhaustive()}
    </div>
  )
}

export const Pagination = {
  State: StatePagination,
  Link: LinkPagination,
}
