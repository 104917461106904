import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResRetrievedDto,
  type TPatchPayloadRetrievedDto,
  patchResRetrievedDto,
  patchPayloadRetrievedDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/HS2WaN
 * @description [retrieved] 외부채널 주문서 하위 섹션 수거완료/해제 처리
 */
export function patchRetrieved({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadRetrievedDto) {
    type T = TPatchResRetrievedDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/retrieved`, body)
      .then((res) => res.data)

    return result
  }
}
