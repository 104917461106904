import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { TRefundProcessFailureBase, useRefundProcessFailureAtom } from '.'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { ErrorCard } from '~/shared/components/error'
import { Loading } from '~/shared/components/loading'

const RefundProcessFailureContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.RefundProcessFailure),
  }))
)

interface TRefundProcessFailurePack extends TRefundProcessFailureBase {}
export function RefundProcessFailurePack(props: TRefundProcessFailurePack) {
  const orderCode = useOrderCode()
  const [open, setState] = useRefundProcessFailureAtom.state({
    orderCode: orderCode + (props.atomKey || ''),
  })
  return (
    <AlertDialog.Root
      defaultOpen={false}
      open={open?.open && !!open?.data}
      onOpenChange={(e) =>
        setState((prev) => (e ? { ...prev, open: e } : { open: e }))
      }
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-0 gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<Loading />}>
              <RefundProcessFailureContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
