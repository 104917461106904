import { OrderSectionReturnCalcPack } from '~/container/order/order-section-return-calc'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

export default function Page() {
  return <OrderSectionReturnCalcPack />
}
