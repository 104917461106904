import { useModals } from '~/router'
import { OrderMemoPreviewDeleteModal } from './order-memo-preview-delete.modal'
import { deleteOrderMemo } from '~/entities/order-memo'
import { orderMemoQueryOptions } from '~/entities/order/order-memo'
// import { useOrderCode } from '~/shared/hooks/use-order-code'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
// import { ErrorCard } from '~/shared/components/error'
// import { Loading } from '~/shared/components/loading'
import { useQueryClient } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { useMemoCode } from '~/shared/hooks/use-memo-code'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { __ } from '~/shared/i18n'

// export const Catch = () => <ErrorCard isFull />
// export const Pending = () => <Loading />

export interface OrderMemoPreviewDeleteModalProps {
  handleModalClose: () => void
  handleDeleteOrderMemo: () => void
}

export const OrderMemoPreviewDeleteModalContainer = () => {
  const queryClient = useQueryClient()
  const modals = useModals()
  const handleModalClose = () => modals.close()
  const orderCode = useOrderCode()
  const memoCode = useMemoCode()
  const { mutate: deleteOrderMemoMutation } = useMutation({
    mutationFn: deleteOrderMemo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderMemoQueryOptions(orderCode).queryKey,
      })
      toast.success(__('메모를 삭제했어요.'))
      handleModalClose()
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message)
      }
    },
    mutationKey: ['deleteOrderMemo'],
  })

  const handleDeleteOrderMemo = () => {
    deleteOrderMemoMutation({
      orderCode,
      idx: Number(memoCode),
    })
  }

  const props: OrderMemoPreviewDeleteModalProps = {
    handleModalClose,
    handleDeleteOrderMemo,
  }

  return <OrderMemoPreviewDeleteModal {...props} />
}
