import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import {
  TRegisterInvoiceGoodsflowAllBase,
  useRegisterInvoiceGoodsflowAllAtom,
} from '.'
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { useOrderAtom } from '~/container/orders/orders-table/orders-table-body'
import { __ } from '~/shared/i18n'
import { goodsflowQueryOptions } from '~/entities/goodsflow'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { BulkBanner, BulkBannerButtons, BulkBannerHeader } from '../bulk-banner'
import { Select } from '~/shared/components/ui/select'
import { IconChevronDown } from '@imwebme/icon'
import { cn } from '~/shared/utils'
import { useCurrentTabData } from '~/shared/hooks/use-current-tab-data/use-current-tab-data'
import { useOrderSearchFilterQueryString } from '~/container/order-search-filter/order-search-filter.store'
import { getTabQueryString } from '../../orders-fn'
import { bulkActionSectionInvoicePrintValidateAllQueryOptions } from '~/entities/bulk/bulk-action-section-invoice-print-validate-all'
import {
  TPostPayloadBulkActionSectionInvoicePrintApplyAllSchema,
  postBulkActionSectionInvoicePrintApplyAll,
  postPayloadBulkActionSectionInvoicePrintApplyAllSchema,
} from '~/entities/bulk/bulk-action-section-invoice-print-apply-all'
import { useOrderSearchQuery } from '~/shared/hooks/use-order-search-query'

interface TRegisterInvoiceGoodsflowAllContainer
  extends TRegisterInvoiceGoodsflowAllBase {}
export function RegisterInvoiceGoodsflowAllContainer(
  _: TRegisterInvoiceGoodsflowAllContainer
) {
  const tabCode = useTabCode()
  const queryClient = useQueryClient()
  const [selectedOrderCodes] = useOrderAtom.codeSelection.state({
    tabCode,
  })
  const resetCodeSelection = useOrderAtom.codeSelection.reset({ tabCode })
  const resetRowSelection = useOrderAtom.rowSelection.reset({ tabCode })
  const setState = useRegisterInvoiceGoodsflowAllAtom.set({ tabCode })
  const tabData = useCurrentTabData()
  const filterQueryString = useOrderSearchFilterQueryString()
  const [searchKeyword] = useOrderSearchQuery()

  // ======================================
  const { data: goodsflow } = useSuspenseQuery(goodsflowQueryOptions())
  const applyPaymentMutation = useMutation({
    mutationFn: postBulkActionSectionInvoicePrintApplyAll,
  })
  const payload = {
    ...(!!searchKeyword && { keyword: searchKeyword }),
    searchTab: getTabQueryString(tabData.data?.columnCondition),
    searchFilter: filterQueryString,
    centerCode: goodsflow[0].centerCode,
  }
  const { data: validatePayment } = useSuspenseQuery(
    bulkActionSectionInvoicePrintValidateAllQueryOptions(payload)
  )
  const method = useForm({
    resolver: zodResolver(
      postPayloadBulkActionSectionInvoicePrintApplyAllSchema
    ),
    mode: 'onChange',
    defaultValues: payload,
  })

  const { handleSubmit } = method
  const onSubmit = handleSubmit((body) => {
    applyPaymentMutation.mutate(
      {
        body,
      },
      {
        onSuccess: (e) => {
          window.open(e.goodsflowPopUpUrl, '_blank', 'width=800,height=600')
          setState((prev) => ({ ...prev, value: false }))
          queryClient.invalidateQueries({
            queryKey: ['orderList'],
          })
          resetCodeSelection()
          resetRowSelection()
        },
      }
    )
  })

  const props = {
    nextStatus: __('송장등록'),
    prevStatus: __('송장등록'),
    fulfilled: validatePayment.fulfilled || 0,
    rejected: validatePayment.rejected || 0,
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={onSubmit}>
        <AlertDialog.Header className="px-[32px]">
          <AlertDialog.Title className="semantic-h4-bold dark:text-clay-semantic-text">
            <BulkBannerHeader {...props} />
          </AlertDialog.Title>
        </AlertDialog.Header>
        <div className="px-[32px] mt-[20px]">
          <BulkBanner {...props} />
        </div>
        {props.fulfilled > 0 && (
          <div className="px-[32px] mt-[20px]">
            <div className="mb-[4px]">{__('굿스플로 계정')}</div>
            <Controller
              name="centerCode"
              control={method.control}
              render={({
                field: { onChange, ref, value: formValue, ...rest },
                fieldState: { error },
              }) => (
                <Select.Root
                  onValueChange={onChange}
                  value={formValue ?? ''}
                  {...rest}
                >
                  <Select.Trigger
                    className={cn(
                      'w-full typo-clay-body-medium h-max text-left border-solid',
                      error && 'focus:ring-red-500'
                    )}
                    ref={ref}
                  >
                    <Select.Value placeholder={__('굿스플로 계정')} />
                    <IconChevronDown className="stroke-[2]" />
                  </Select.Trigger>
                  <Select.Content>
                    <div>
                      {goodsflow.map((item) => (
                        <Select.Item
                          value={item.centerCode}
                          key={item.centerCode}
                          className="text-left"
                        >
                          {item.deliverName}
                          <br />({item.centerZipCode}) {item.centerAddress}
                        </Select.Item>
                      ))}
                    </div>
                  </Select.Content>
                </Select.Root>
              )}
            />
          </div>
        )}
        <AlertDialog.Footer className="px-[32px] mt-[20px]">
          <BulkBannerButtons {...props} />
        </AlertDialog.Footer>
      </form>
    </FormProvider>
  )
}
