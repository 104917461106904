import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import type {
  TPostResOrderMemoSchema,
  TPostPayloadOrderMemoSchema,
  TPatchResOrderMemoSchema,
  TPatchPayloadOrderMemoSchema,
  TDeleteResOrderMemoSchema,
} from './order-memo.type'
import {
  postResOrderMemoSchema,
  patchResOrderMemoSchema,
  deleteResOrderMemoSchema,
} from './order-memo.schema'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/Ayved9
 * @description 주문 메모 생성
 */
export function postOrderMemo({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadOrderMemoSchema) {
    type T = TPostResOrderMemoSchema
    type R = typeof body
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<number>>,
        R
      >(`/order/${orderCode}/memo`, body)
      .then((res) => res.data)

    return result
  }
}

/**
 * @API문서 https://5k.gg/ZJQBPE
 * @description 주문 메모 수정
 */
export async function patchOrderMemo({
  body,
  orderCode,
  idx,
}: {
  body: TPatchPayloadOrderMemoSchema
  orderCode: string
  idx: number
}) {
  type T = TPatchResOrderMemoSchema
  type R = typeof body
  const result = await axios.patch<T, ApiResponse<number>, R>(
    `/order/${orderCode}/memo/${idx}`,
    body
  )

  patchResOrderMemoSchema.parse(result.data)
  return result
}

/**
 * @API문서 https://5k.gg/HcTFkE
 * @description 주문 메모 삭제
 */
export async function deleteOrderMemo({
  orderCode,
  idx,
}: {
  orderCode: string
  idx: number
}) {
  type T = TDeleteResOrderMemoSchema
  const result = await axios.delete<T, ApiResponse<void>>(
    `/order/${orderCode}/memo/${idx}`
  )

  deleteResOrderMemoSchema.parse(result.data)
  return result
}
