import { queryOptions } from '@tanstack/react-query'
import { getParcelCompany } from './parcel-company.api'
import { TGetParamsParcelCompanyDto } from './parcel-company.type'
import { T상수_판매채널, 상수_판매채널 } from '../@x'

export function parcelCompanyQueryOptions(
  props?: { saleChannelIdx: T상수_판매채널 },
  params?: TGetParamsParcelCompanyDto
) {
  return queryOptions({
    queryKey: [
      '택배사',
      {
        saleChannelIdx: props?.saleChannelIdx ?? 상수_판매채널.아임웹,
        orderSectionCode: params?.orderSectionCode,
      },
    ],
    queryFn: getParcelCompany({
      saleChannelIdx: props?.saleChannelIdx ?? 상수_판매채널.아임웹,
    })(params),
    staleTime: 1000 * 60 * 10, // 10분
    select: (d) => d.data.list,
  })
}
