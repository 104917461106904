/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { 상수_현금영수증_수단 } from '~/entities/@x'

/**
 * post cash-receipt-process-manual-revert
 */
export const postResCashReceiptProcessManualRevertDto = z.object({
  siteCode: z.string(),
  paymentCode: z.string(),
  statusCd: z.string(),
  methodTypeCd: z.nativeEnum(상수_현금영수증_수단),
})
export const postPayloadCashReceiptProcessManualRevertDto = z.any()
