import { queryOptions } from '@tanstack/react-query'
import { TPostPayloadCancelSettlementsSchema } from './cancel-settlements.type'
import { postCancelSettlements } from './cancel-settlements.api'

export function cancelSettlementsQueryOptions({
  orderCode,
  sectionCode,
  targetItemInformation,
}: {
  orderCode: string
  sectionCode: string
  targetItemInformation: TPostPayloadCancelSettlementsSchema['targetItemInformation']
}) {
  return queryOptions({
    queryKey: ['cancel-settlements', orderCode, sectionCode],
    queryFn: () =>
      postCancelSettlements({
        orderCode,
        sectionCode,
        body: { targetItemInformation },
      }),
  })
}
