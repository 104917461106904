import { useEffect, useState } from 'react'
import { DotsHorizontal, Plus } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { Link } from '~/shared/components/link'
import { DropdownMenu } from '~/shared/components/dropdown-menu'
import { match } from 'ts-pattern'
import {
  Clay,
  Flex,
  IconButton,
  Tab,
  TabList,
  Tabs,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useOrdersTabView } from './orders-tab.hook'
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DropResult,
} from 'react-beautiful-dnd'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/Array'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  orderSearchTabQueryOptions,
  patchOrderSearchTabSorting,
} from '~/entities/order-search-tab'
import { toast } from 'react-toastify'
import { reorder } from '~/shared/utils'

export interface TabItem {
  code: string
  name: string
  isAllTab: boolean
  count: number
  sortNo: number
}

export function OrdersTab() {
  const {
    tabList,
    orderedTabList,
    setOrderedTabList,
    activeTab,
    lastVisibleTabIdx,
    activeTabIdx: selectedTabIdx,
    containerRef,
    tabItemRef,
    handleAddTab,
    handleTabClick,
  } = useOrdersTabView()
  const isActiveHiddenTab = selectedTabIdx > lastVisibleTabIdx
  const [activeIndex, setActiveIndex] = useState(
    !isActiveHiddenTab ? selectedTabIdx : lastVisibleTabIdx + 2
  )
  const [activeHiddenTab, setActiveHiddenTab] = useState<TabItem | null>(null)

  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: patchOrderSearchTabSorting,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderSearchTabQueryOptions().queryKey,
      })
    },
    onMutate: ({ tabCode, body }) => {
      const prevQueryData = queryClient.getQueryData(
        orderSearchTabQueryOptions().queryKey
      )

      if (prevQueryData === undefined) {
        return
      }

      const startIndex = body.tabCodesOrigin.findIndex(
        (code) => code === tabCode
      )
      const endIndex = body.sortNo - 1
      let nextTabList = reorder(prevQueryData.data.list, startIndex, endIndex)

      nextTabList = pipe(
        nextTabList,
        A.mapWithIndex((index, item) => {
          item.sortNo = index + 1

          return item
        })
      )

      queryClient.setQueryData(orderSearchTabQueryOptions().queryKey, {
        ...prevQueryData,
        data: {
          list: nextTabList,
        },
      })
    },
    onError: (error) => {
      if (error.response?.data.code === '20016') {
        toast.error(
          __('다른 관리자가 이미 탭 순서를 변경했어요. 다시 시도해 주세요.')
        )
      }
      queryClient.invalidateQueries({
        queryKey: orderSearchTabQueryOptions().queryKey,
      })
    },
  })

  const onDragEnd = (result: DropResult) => {
    const { draggableId, source, destination } = result

    if (!destination) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    let sortNo: number
    let startIndex: number
    let endIndex: number

    // 이동하는 탭이 숨겨진 탭인 경우
    if (source.index === lastVisibleTabIdx + 2) {
      sortNo = destination.index + 1
      startIndex = activeHiddenTab!.sortNo - 1
      endIndex = destination.index
    } else {
      // 이동하는 탭이 마지막 보이는 탭보다 뒤로 이동하는 경우
      if (destination.index > lastVisibleTabIdx) {
        // 현재 숨겨진 탭이 활성화 상태가 아니라면 return
        if (!activeHiddenTab) {
          return
        }

        sortNo = activeHiddenTab.sortNo - 1
        startIndex = source.index
        endIndex = activeHiddenTab.sortNo - 2
      } else {
        sortNo = destination.index + 1
        startIndex = source.index
        endIndex = destination.index
      }
    }

    const tabCode = draggableId
    let updatedItems = reorder(orderedTabList, startIndex, endIndex)

    updatedItems = pipe(
      updatedItems,
      A.mapWithIndex((index, item) => {
        item.sortNo = index + 1

        return item
      })
    )

    setOrderedTabList(updatedItems)
    if (source.index === activeIndex) {
      setActiveIndex(destination.index)
    }

    mutate({
      tabCode,
      body: {
        sortNo,
        tabCodesOrigin: tabList.map((tab) => tab.code),
      },
    })
  }

  useEffect(() => {
    setActiveIndex(!isActiveHiddenTab ? selectedTabIdx : lastVisibleTabIdx + 2)
    setActiveHiddenTab(isActiveHiddenTab ? activeTab : null)
  }, [selectedTabIdx, lastVisibleTabIdx])

  return (
    <Flex
      ref={containerRef}
      background={vars.semantic.color.surface}
      borderRadius={vars.rounded.large}
      paddingInline={vars.spacing[4]}
      position="relative"
      alignItems="center"
      gap={vars.spacing[4]}
      overflow="hidden"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tab-droppable-id-0001" direction="horizontal">
          {(wrapProvided, wrapSnapshot) => (
            <div {...wrapProvided.droppableProps} ref={wrapProvided.innerRef}>
              <Tabs
                size="medium"
                variant="text"
                underline={wrapSnapshot.isDraggingOver !== true}
                index={activeIndex}
                onChange={handleTabClick}
              >
                <TabList>
                  {orderedTabList
                    .slice(0, lastVisibleTabIdx + 1)
                    .map((tab, index) => (
                      <Draggable
                        key={tab.code}
                        draggableId={tab.code}
                        index={index}
                        disableInteractiveElementBlocking={true}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Tab
                              text={tab.name}
                              badge={tabBadgeCount(tab.count)}
                              _index={index}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {wrapProvided.placeholder}
                  {/* 숨김탭 */}
                  <Draggable
                    draggableId="NONE"
                    index={lastVisibleTabIdx + 1}
                    disableInteractiveElementBlocking={true}
                    isDragDisabled={true}
                  >
                    {(provided) => (
                      <Clay
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display:
                            lastVisibleTabIdx < orderedTabList.length - 1
                              ? 'flex'
                              : 'none',
                          alignItems: 'center',
                        }}
                      >
                        <SeeMoreBtn
                          tabList={orderedTabList.slice(lastVisibleTabIdx + 1)}
                          activeTabId={activeTab.code}
                        />
                      </Clay>
                    )}
                  </Draggable>
                  {/* 현재탭인데 숨겨진 경우 표시 */}
                  {isActiveHiddenTab && (
                    <Draggable
                      key={activeTab.code}
                      draggableId={activeTab.code}
                      index={lastVisibleTabIdx + 2}
                      disableInteractiveElementBlocking={true}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Tab
                            text={activeTab.name}
                            badge={tabBadgeCount(activeTab.count)}
                            _index={lastVisibleTabIdx + 2}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}
                </TabList>
              </Tabs>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* 탭목록 너비 계산을 위한 dummy 요소*/}
      <Clay
        position="absolute"
        opacity={0}
        top={0}
        left={0}
        pointerEvents="none"
        aria-hidden
      >
        <Tabs size="medium" variant="text">
          <TabList>
            {orderedTabList.map((tab, idx) => (
              <Clay key={tab.code} ref={tabItemRef.current[idx]}>
                <Tab text={tab.name} badge={tabBadgeCount(tab.count)} />
              </Clay>
            ))}
          </TabList>
        </Tabs>
      </Clay>

      {/* 탭추가 */}
      <IconButton
        native={{ type: 'button' }}
        variant="secondary"
        size="tiny"
        onClick={handleAddTab}
        isDisabled={
          location.pathname.includes('/orders/tab/new') ||
          location.pathname.includes('/orders/tab/edit')
        }
        icon={<Plus />}
        aria-label="add tab"
      />
    </Flex>
  )
}

const SeeMoreBtn = ({
  tabList,
  activeTabId,
}: {
  tabList: TabItem[]
  activeTabId: string
}) => {
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <IconButton
          native={{ type: 'button' }}
          variant="secondary"
          icon={<DotsHorizontal />}
          size="tiny"
          aria-label="show more tabs"
          className="data-[state=open]:bg-clay-semantic-actionSecondaryPressed"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content align="end">
          {tabList.map((tab) => {
            if (tab.code === activeTabId) {
              return null
            }
            return (
              <DropdownMenu.Item
                key={tab.code}
                disabled={activeTabId === tab.code}
                asChild
              >
                {match(activeTabId === tab.code)
                  .with(true, () => <div>{tab.name}</div>)
                  .with(false, () => (
                    <Link
                      to="/orders/tab/:tabCode"
                      params={{ tabCode: encodeURIComponent(tab.code) }}
                      onClick={() => setOpen(false)}
                    >
                      {tab.name}
                    </Link>
                  ))
                  .exhaustive()}
              </DropdownMenu.Item>
            )
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  )
}

function tabBadgeCount(count: number): number | string | undefined {
  if (count === 0) {
    return undefined
  }
  return count < 1000 ? count : '999+'
}
