import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPostResCancelRejectRequestDto,
  type TPostPayloadCancelRejectRequestDto,
  postResCancelRejectRequestDto,
} from '.'
import * as Sentry from '@sentry/react'

const axios = ApiClient()

/**
 * @API문서
 * @description cancel-reject-request
 */
export function postCancelRejectRequest({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadCancelRejectRequestDto) {
    type R = typeof body
    type T = TPostResCancelRejectRequestDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/cancel/reject-request`, body)
      .then((res) => res.data)

    try {
      postResCancelRejectRequestDto.parse(result.data)
    } catch (e) {
      Sentry.captureException(e)
    }
    return result
  }
}
