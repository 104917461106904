import {
  Button,
  ButtonGroup,
  Clay,
  Flex,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { __ } from '~/shared/i18n'
import { useNavigate } from 'react-router-dom'
import DesktopAccess from '~/container/onboarding/assets/desktop-access.svg?react'

export function MobileAccessView() {
  const navigate = useNavigate()
  return (
    <>
      <PageTopBar pageTitle={__('튜토리얼')} />
      <Clay
        as="hr"
        border="none"
        borderBottom={`solid 1px ${vars.semantic.color.divide}`}
        position="fixed"
        left={0}
        right={0}
        top={44}
        zIndex={1}
      />
      <Flex.Center
        position="fixed"
        backgroundColor={vars.semantic.color.surface}
        inset={0}
      >
        <Flex.Column alignItems="center">
          <Flex.Center width={72} height={72}>
            <DesktopAccess />
          </Flex.Center>
          <Clay height={16} />
          <Typography variant="body-large">
            {__('PC에서 접속해 주세요')}
          </Typography>
          <Clay height={40} />
          <ButtonGroup direction="vertical" sx={{ width: '240px' }}>
            <Button
              variant="primary"
              text={__('관리자 홈')}
              fullWidth
              onClick={() => navigate('/orders', { replace: true })}
            />
          </ButtonGroup>
        </Flex.Column>
      </Flex.Center>
    </>
  )
}
