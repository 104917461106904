import React from 'react'
import {
  Button,
  Flex,
  IconButton,
  OptionItem,
  OptionList,
  Select,
  Textfield,
  Typography,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TGetResProducttListItemDto } from '~/entities/product/product-list'
import { __ } from '~/shared/i18n'
import { FormattedPrice } from '../../formatted-price'
import _ from 'lodash'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useUserDefinedOption,
  useSelectedProdAtom,
} from '~/widgets/product-add-modal/.'
import { 상수_상품옵션타입 } from '~/entities/@x'
import { Trash03 } from '@imwebme/clay-icons'

interface Props {
  prod: TGetResProducttListItemDto
}

const formSchema = z.object({
  field: z
    .object({
      value: z.string().min(1),
    })
    .array(),
})
type TForm = z.infer<typeof formSchema>

export function ProdWithOptions({ prod }: Props) {
  const optionListData = getSanitizedOptionList(prod.options)
  const [_selectedProd, setSelectedProd] = useSelectedProdAtom(prod.prodCode)
  const userDefinedOption = useUserDefinedOption(prod.prodCode)

  const { control, register, handleSubmit, formState, reset } = useForm<TForm>({
    resolver: zodResolver(formSchema),
    defaultValues: { field: optionListData.map(() => ({ value: '' })) },
  })

  const { fields } = useFieldArray({
    control,
    name: 'field',
  })

  function onRemoveUserDefinedOption(id: string) {
    setSelectedProd({
      action: 'remove',
      type: '사용자정의옵션',
      payload: id,
    })
  }

  function onSubmit(form: TForm) {
    function getLabel(
      optionData: (typeof optionListData)[number],
      fieldValue: string
    ): string {
      if ('values' in optionData) {
        return `${optionData.optionName} ${
          optionData.values?.find((el) => el.valueCode === fieldValue)
            ?.valueName ?? ''
        }`
      } else {
        return `${optionData.optionName} ${fieldValue}`
      }
    }

    function getPrice(
      optionData: (typeof optionListData)[number],
      fieldValue: string
    ): number {
      if ('values' in optionData) {
        return (
          optionData.values?.find((el) => el.valueCode === fieldValue)?.price ??
          0
        )
      }
      return 0
    }

    const options = form.field.map((field, idx) => {
      const option = optionListData[idx]

      return {
        optionType: option?.type,
        optionCode: option?.optionCode,
        valueCode: field.value,
        label: getLabel(option, field.value),
        price: getPrice(option, field.value),
      }
    })

    setSelectedProd({
      action: 'add',
      type: '사용자정의옵션',
      payload: {
        prod,
        options,
      },
    })

    reset()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex.Column rowGap={vars.spacing[3]} padding={`${vars.spacing[2]} 0`}>
          {fields.map((field, index) => {
            const optionData = optionListData[index]
            if (!optionData) {
              return null
            }

            // select 옵션
            if ('values' in optionData && optionData.isRequiredOption) {
              return (
                <Controller
                  key={index}
                  name={`field.${index}.value`}
                  control={control}
                  render={({ field: { onChange, value, ref, ...rest } }) => (
                    <Select>
                      <Select.Trigger>
                        <Select.Input
                          label={__(optionData.optionName)}
                          labelProps={{ bold: false }}
                          placeholder={__('선택해 주세요')}
                          value={
                            optionData.values?.find(
                              (el) => el.valueCode === value
                            )?.valueName ?? ''
                          }
                          {...rest}
                        />
                      </Select.Trigger>
                      <Select.Portal>
                        <OptionList
                          popOver
                          setValue={(v) => onChange(v)}
                          sx={{ maxHeight: '280px', overflow: 'auto' }}
                        >
                          {optionData.values?.map((opt, idx) => (
                            <OptionItem
                              key={idx}
                              text={opt.valueName}
                              value={opt.valueCode}
                            />
                          ))}
                        </OptionList>
                      </Select.Portal>
                    </Select>
                  )}
                />
              )
            }

            // input 옵션
            if (optionData.type === 상수_상품옵션타입.입력) {
              return (
                <React.Fragment key={index}>
                  <Textfield.Set>
                    <Textfield.Legend
                      text={optionData.optionName ?? ''}
                      bold={false}
                    />
                    <Textfield.Box>
                      <Textfield.Input
                        key={field.id}
                        {...register(`field.${index}.value` as const)}
                        autoComplete="off"
                        placeholder={__('입력해 주세요')}
                      />
                    </Textfield.Box>
                  </Textfield.Set>
                </React.Fragment>
              )
            }

            return null
          })}
          <Button
            text={__('목록에 추가')}
            variant="primary"
            fullWidth
            tonal
            isDisabled={!formState.isValid}
            native={{ type: 'submit' }}
          />
        </Flex.Column>
      </form>

      {/* 추가된 사용자정의 옵션 */}
      {userDefinedOption.map((opt) => (
        <Flex
          key={opt.id}
          gap={vars.spacing[2]}
          alignItems="center"
          sx={{ marginTop: vars.spacing[3] }}
        >
          <IconButton
            icon={<Trash03 />}
            size="tiny"
            variant="secondary"
            onClick={() => onRemoveUserDefinedOption(opt.id)}
          />
          <Typography
            variant="body-medium"
            sx={{ flex: 1, paddingRight: vars.spacing[2] }}
          >
            {opt.label}
          </Typography>
          <Typography variant="body-medium">
            <FormattedPrice price={opt.price} />
          </Typography>
        </Flex>
      ))}
    </>
  )
}

function getSanitizedOptionList(
  options: TGetResProducttListItemDto['options']
) {
  if (!options) {
    return []
  }
  return (
    options
      .filter((el) => el.isRequiredOption === true)
      .sort((a, b) => {
        if (
          a.type === 상수_상품옵션타입.입력 &&
          b.type !== 상수_상품옵션타입.입력
        ) {
          return 1
        }
        if (
          a.type !== 상수_상품옵션타입.입력 &&
          b.type === 상수_상품옵션타입.입력
        ) {
          return -1
        }
        return 0
      }) ?? []
  )
}
