import React from 'react'
import { useTabCode } from '~/shared/hooks/use-tab-code/use-tab-code'
import {
  useRegisterInvoiceGoodsflowAllAtom,
  RegisterInvoiceGoodsflowAllContainer,
} from '.'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { ErrorBoundary } from '@sentry/react'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'
import { Loading } from '~/shared/components/loading'
import SessionStorageManager from '~/shared/managers/session-storage-manger'
import { ErrorCard } from '~/shared/components/error'
import { DropdownMenu } from '~/shared/components/dropdown-menu'

const isB2b = SessionStorageManager.getItem('is_b2b')

export function RegisterInvoiceGoodsflowAllPack() {
  const tabCode = useTabCode()
  const [state, setState] = useRegisterInvoiceGoodsflowAllAtom.state({
    tabCode,
  })

  const onOpenChange = (o: boolean) => {
    setState((prev) => ({ ...prev, value: o }))
  }

  return (
    <AlertDialog.Root
      open={state.value}
      defaultOpen={state.value}
      onOpenChange={onOpenChange}
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content
          className={cn(
            'sm:max-w-[380px] bg-clay-semantic-surface px-0 rounded-[20px]',
            'dark:bg-clay-semantic-surface'
          )}
        >
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense
              fallback={
                <div className="max-h-[240px] overflow-hidden flex items-center justify-center rounded-xl">
                  <Loading />
                </div>
              }
            >
              <RegisterInvoiceGoodsflowAllContainer />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

RegisterInvoiceGoodsflowAllPack.Button = function () {
  const tabCode = useTabCode()
  const setModal = useRegisterInvoiceGoodsflowAllAtom.set({ tabCode })

  if (isB2b === 'true') {
    return null
  }
  return (
    <DropdownMenu.Item
      onSelect={() => setModal((prev) => ({ ...prev, value: true }))}
    >
      {__('송장등록 - 굿스플로')}
    </DropdownMenu.Item>
  )
}
