import React from 'react'
import { cn } from '~/shared/utils'

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => (
    <input
      className={cn(
        'flex h-10 w-full rounded-md border border-solid border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-0 focus:ring-slate-400 focus:ring-offset-0 disabled:cursor-not-allowed disabled:bg-clay-semantic-surfaceSub disabled:text-clay-semantic-textDisabled disabled:border-none dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900 read-only:bg-clay-semantic-fieldSecondary read-only:border-none no-spinner',
        className
      )}
      ref={ref}
      {...props}
    />
  )
)
Input.displayName = 'Input'

export { type InputProps }
export { Input }
