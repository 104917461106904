/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { YN } from '~/entities/@x'

/**
 * post bulk-action-section-delivery-hold-apply
 */
export const postResBulkActionSectionDeliveryHoldApplySchema = z.any()
export const postPayloadBulkActionSectionDeliveryHoldApplySchema = z.object({
  targetOrderCodes: z.array(z.string()),
  deliveryHoldStatus: z.nativeEnum(YN),
})
