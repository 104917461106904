import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TGetResOrderDeliveryDto,
  getResOrderDeliveryDto,
  type TPostResOrderDeliveryDto,
  type TPostPayloadOrderDeliveryDto,
  postResOrderDeliveryDto,
  postPayloadOrderDeliveryDto,
  type TPatchResOrderDeliveryDto,
  type TPatchPayloadOrderDeliveryDto,
  patchResOrderDeliveryDto,
  patchPayloadOrderDeliveryDto,
  type TDeleteResOrderDeliveryDto,
  type TDeletePayloadOrderDeliveryDto,
  deleteResOrderDeliveryDto,
  deletePayloadOrderDeliveryDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/DAeZH4
 * @description 배송지 단일 조회
 */
export function getOrderDelivery({
  orderCode,
  orderDeliveryCode,
}: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return async function () {
    const result = await axios
      .get<
        ApiResponse<TGetResOrderDeliveryDto>
      >(`/order/${orderCode}/delivery/${orderDeliveryCode}`)
      .then((res) => res.data)

    return result.data
  }
}

/**
 * @API문서 https://5k.gg/AG2L6j
 * @description 배송지 생성
 */
export function postOrderDelivery({ orderCode }: { orderCode: string }) {
  return async function (body: TPostPayloadOrderDeliveryDto) {
    type R = typeof body
    type T = TPostResOrderDeliveryDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/delivery`, body)
      .then((res) => res.data)

    postResOrderDeliveryDto.parse(result.data)
    return result
  }
}

/**
 * @API문서 https://5k.gg/72cQLM
 * @description 배송지 수정
 */
export function patchOrderDelivery({
  orderCode,
  orderDeliveryCode,
}: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return async function (body: TPatchPayloadOrderDeliveryDto) {
    type T = TPatchResOrderDeliveryDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/delivery/${orderDeliveryCode}`, body)
      .then((res) => res.data)

    patchResOrderDeliveryDto.parse(result.data)
    return result
  }
}

/**
 * @API문서 https://5k.gg/MY7LoO
 * @description 배송지 정보 삭제
 */
export function deleteOrderDelivery({
  orderCode,
  orderDeliveryCode,
}: {
  orderCode: string
  orderDeliveryCode: string
}) {
  return async function () {
    type T = TDeleteResOrderDeliveryDto
    const result = await axios
      .delete<
        T,
        AxiosResponse<ApiResponse<T>>
      >(`/order/${orderCode}/delivery/${orderDeliveryCode}`)
      .then((res) => res.data)

    deleteResOrderDeliveryDto.parse(result.data)
    return result
  }
}
