import { useQuery } from '@tanstack/react-query'
import { type ISpecification, Spec } from 'spec-pattern-ts'
import { OrderAddView } from './view'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useSetAtom } from 'jotai'
import { COrderSection } from '../class-order-section'
import { COrder } from '../class-order'
import { SingleOrderCheck } from '../order-api'
import { OrderAddAtom } from './order-add.store'
import { __ } from '~/shared/i18n'
import { useParams } from 'react-router-dom'
import { orderDetailOptions } from '~/entities/order-detail'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'

const OrderAddPage = () => {
  const params = useParams()
  const orderCode = params.orderCode
  if (orderCode === undefined) {
    // 오더코드가 없으면 안되는 페이지에서 오더코드가 없다.
    throw new Error('orderCode is undefined')
  }
  // server status
  const { data, isSuccess } = useQuery({
    queryKey: [orderDetailOptions({ orderCode }).queryKey[0]],
    queryFn: () => SingleOrderCheck(orderCode),
    select: flow((d) => d.data),
  })
  // client status
  const OrderAddAtomSet = useSetAtom(OrderAddAtom)

  if (isSuccess && data) {
    // 상품준비 섹션
    // OSS01 섹션인지 확인하는 스펙
    const sections = new COrder(data).orderSectionList
    const isStatusCdOSS01: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS01'
    )
    // OSS02 섹션이면서 인보이스가 없는 경우의 스펙
    // 인보이스가 없는 경우는 invoice가 없는 경우와 invocice가 있지만 invociceNo가 없는 경우가 있다.
    const isStatusCdOSS02: ISpecification<COrderSection> = new Spec(
      (candidate) =>
        candidate.statusCd === 'OSS02' &&
        (candidate.invoice === null || candidate.invoice.invoiceNo === '')
    )

    const result = pipe(
      sections,
      A.filter((section) =>
        isStatusCdOSS01.or(isStatusCdOSS02).isSatisfiedBy(section)
      )
    )

    // OSS02 섹션이 있는지 확인하기
    const havStatusCdOSS01: ISpecification<COrderSection> = new Spec(
      (candidate) => candidate.statusCd === 'OSS01'
    )

    const haveOSS01 = pipe(
      sections,
      A.filter((section) => havStatusCdOSS01.isSatisfiedBy(section)),
      (e) => e.length > 0
    )

    // init
    const readyItems = pipe(
      result,
      O.fromNullable,
      O.fold(
        () => [],
        flow(
          A.map((section) => ({
            id: section.id,
            label: section.orderSectionNo,
            statusCd: section.statusCd,
            value: section.templateItemList,
          })),
          // 주문서에 상품준비 섹션이 없다면 추가해준다.
          (e) => {
            if (!haveOSS01) {
              // e의 첫번째 index로 새로운 상품준비 섹션을 만들어줘야한다
              e.unshift({
                id: 'new',
                label: 'new',
                statusCd: 'OSS01',
                value: [],
              })
            }
            return e
          }
        )
      )
    )
    OrderAddAtomSet(data)

    // render
    return (
      <>
        <PageTopBar />
        <OrderAddView
          readyItems={readyItems}
          memberCode={data.memberCode}
          orderType={data.orderTypeCd}
          orderNo={data.orderNo}
        />
      </>
    )
  }
  return <div>{__('로딩중')}</div>
}

export default OrderAddPage
