import React from 'react'
import { TExternalExchangeRequestBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalReturnRequestContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalReturnRequest }))
)

interface TExternalExchangeRequestPack extends TExternalExchangeRequestBase {}
export function ExternalExchangeRequestPack(
  props: TExternalExchangeRequestPack
) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalReturnRequestContainer {...props} />
    </React.Suspense>
  )
}
