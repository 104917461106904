import * as _MenuBar from './menubar'

/**
 * radix-ui의 MenuBar와 클레이 디자인을 사용합니다.
 */
const Menubar = Object.assign(_MenuBar.Menubar, {
  Menu: _MenuBar.MenubarMenu,
  Trigger: _MenuBar.MenubarTrigger,
  Content: _MenuBar.MenubarContent,
  Item: _MenuBar.MenubarItem,
  Separator: _MenuBar.MenubarSeparator,
  Label: _MenuBar.MenubarLabel,
  CheckboxItem: _MenuBar.MenubarCheckboxItem,
  RadioGroup: _MenuBar.MenubarRadioGroup,
  RadioItem: _MenuBar.MenubarRadioItem,
  Portal: _MenuBar.MenubarPortal,
  SubContent: _MenuBar.MenubarSubContent,
  SubTrigger: _MenuBar.MenubarSubTrigger,
  Group: _MenuBar.MenubarGroup,
  Sub: _MenuBar.MenubarSub,
})

export { Menubar }
