import React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { LinkExternal02, Settings } from '@imwebme/clay-icons'
import { Button } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'
import { match } from 'ts-pattern'
import { IconButton } from '@imwebme/clay-components'
import { BlankLink } from '~/shared/components/link'

interface Props {
  prodNo: number
  children: React.ReactNode
}

export function SectionItemHover({ prodNo, children }: Props) {
  const [productDetailTooltip, setProductDetailTooltip] = React.useState(false)
  const [productSettingTooltip, setProductSettingTooltip] =
    React.useState(false)

  return (
    <Tooltip.Provider delayDuration={100}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            sideOffset={-8}
            align="end"
            alignOffset={24}
            className="p-clay-15 flex gap-x-clay-15 bg-clay-semantic-surface rounded-clay-medium shadow-clay-layer"
          >
            <div
              onMouseEnter={() => setProductDetailTooltip(true)}
              onMouseLeave={() => setProductDetailTooltip(false)}
              className="relative"
            >
              <BlankLink to={`/shop_view/?idx=${prodNo}`}>
                <IconButton
                  as="div"
                  variant="secondary"
                  icon={<LinkExternal02 />}
                  size="tiny"
                />
              </BlankLink>
              <TextTooltip text={__('상품 상세')} open={productDetailTooltip} />
            </div>
            <div
              onMouseEnter={() => setProductSettingTooltip(true)}
              onMouseLeave={() => setProductSettingTooltip(false)}
              className="relative"
            >
              <BlankLink to={`/admin/shopping/product/?mode=add&idx=${prodNo}`}>
                <IconButton
                  as="div"
                  variant="secondary"
                  icon={<Settings />}
                  size="tiny"
                />
              </BlankLink>
              <TextTooltip
                text={__('상품 관리')}
                open={productSettingTooltip}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

function TextTooltip({ text, open }: { text: string; open: boolean }) {
  if (!open) {
    return null
  }
  return (
    <div className="absolute px-clay-2 py-clay-15 bg-[#15181E] rounded-clay-medium text-clay-semantic-textOn w-max left-1/2 -translate-x-1/2 -top-clay-1 -translate-y-full typo-clay-label-small">
      {text}
    </div>
  )
}
