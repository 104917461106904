import * as Sentry from '@sentry/react'
import { pipe, flow } from 'fp-ts/function'
import React from 'react'
import { useLocation } from 'react-router-dom'

export function BrwoserRouterEventContainer({
  children,
}: React.PropsWithChildren) {
  const location = useLocation()
  React.useEffect(() => {
    const path = location.pathname + location.search
    ImwebOAuth2ClientAPI.changePath(path)
    window.imweb_url = pipe(
      path,
      btoa,
      (e) =>
        `${
          ImwebOAuth2ClientAPI.getInfo().siteUrl
        }/admin/shopping/order-v1?subPath=${e}`
    )
    Sentry.setTag('oms_app.imweb_url', window.imweb_url)
    // 여기서 modalParms를 초기화해준다.
    // TODO: @imwebme/generouted-react-router에서 해줄 작업
    if (history.state && history.state.usr && history.state.usr.modal === '') {
      delete history.state.usr.modalParams
    }
  }, [location])
  return <>{children}</>
}
