import { atom, useAtom, useAtomValue } from 'jotai'
import { atomFamily } from 'jotai/utils'
import type { TSheetItem } from '../../export-template-selection.type'
import { orderExportFormSheetState } from '../../export-template-selection.store'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import React from 'react'
/**
 * @description 액션을 받아서 처리할 수 있도록
 */
const orderExportFormSheetItemAction = {
  delete: 'delete',
  update: 'update',
} as const

const orderExportFormSheetItem = atomFamily(
  ({
    selectionId,
    id,
  }: {
    selectionId: string
    id: string
    values?: TSheetItem | null
  }) => {
    const at = atom(
      (get) => {
        const sheetItems = get(orderExportFormSheetState({ id: selectionId }))
        if (!sheetItems) {
          return null
        }
        const findSheetItem = pipe(
          sheetItems,
          O.fromNullable,
          O.fold(
            () => null,
            flow(
              A.findFirst((item) => item.id === id),
              O.fold(
                () => null,
                (item) => item
              )
            )
          )
        )

        return findSheetItem
        // return { id, values: findSheetItem }
      },
      (
        get,
        set,
        update: {
          type: (typeof orderExportFormSheetItemAction)[keyof typeof orderExportFormSheetItemAction]
          value: TSheetItem
        }
      ) => {
        const sheet = get(orderExportFormSheetState({ id: selectionId }))
        if (!sheet) {
          return
        }
        const otherSheet = sheet.filter((item) => item.id !== update.value.id)
        switch (update.type) {
          case orderExportFormSheetItemAction.delete:
            set(orderExportFormSheetState({ id: selectionId }), {
              action: 'delete',
              values: otherSheet,
            })
            return
          case orderExportFormSheetItemAction.update: {
            // 업데이트할때는 해당 아이디를 찾아서 제거한다.
            set(orderExportFormSheetState({ id: selectionId }), [
              ...otherSheet,
              update.value,
            ])
            return
          }
        }
      }
    )
    at.debugLabel = `orderExportFormSheetItem-${selectionId}-${id}`
    return at
  },
  (a, b) => a.id === b.id && a.selectionId === b.selectionId
)

export const useExportTemplateSelectionItemAtom = {
  item: ({ selectionId, id }: { selectionId: string; id: string }) =>
    useAtom(
      React.useMemo(
        () => orderExportFormSheetItem({ selectionId, id }),
        [selectionId, id]
      )
    ),
  getItem: ({ selectionId, id }: { selectionId: string; id: string }) =>
    useAtomValue(
      React.useMemo(
        () => orderExportFormSheetItem({ selectionId, id }),
        [selectionId, id]
      )
    ),
  action: orderExportFormSheetItemAction,
}
