import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import type { T_ProductSearchItem } from './product-search-item'
import { useProductSearchAtom } from './product-search.store'
import { ProductSearchItem } from './product-search-item'
import { Product } from './product-search-item-class'
import { CombineLabel } from './product-search-item-label/combine-label'
import { SelectedLabel } from './product-search-item-label/selected-label'
import { Tag } from '@imwebme/clay-components'
import { __ } from '~/shared/i18n'

export const withProductSearchItemContainer =
  (WrappedComponent: typeof ProductSearchItem) =>
  ({
    prodCode,
    prodName,
    prodStatus,
    prodSoldoutStatus,
    type,
    imageUrls,
    price,
    options,
    optionDetails,
    optionDetailCount,
    searchId,
  }: Omit<T_ProductSearchItem, 'open' | 'setOpen'>) => {
    const props = {
      prodCode,
      prodName,
      prodStatus,
      prodSoldoutStatus,
      type,
      imageUrls,
      price,
      options,
      optionDetails,
      optionDetailCount,
    }
    // jotai가
    // 여기 상태부분 해결해야한다.
    const [value, setValues] = useProductSearchAtom.item(searchId, prodCode)

    if (value === undefined) {
      log.error(
        '🚀 ~ file: product-search-item.container.tsx:17 ~ value:',
        value
      )
      return null
    }

    // 상품데이터
    const product = new Product(props, searchId)

    // 상품데이터에 따른 라벨
    let LabelComponent = null
    // isRequiredOption옵션이 false인 것들
    const nonRequiredOptionChecked = pipe(
      value.options,
      O.fromNullable,
      O.fold(
        () => 0,
        flow(
          A.filter((e) => e.isRequiredOption === false),
          A.map((e) => e.values),
          A.filter((e) => e !== undefined),
          A.map(
            flow(
              O.fromNullable,
              O.fold(
                () => 0,
                flow(
                  A.filter((e) => e.checked),
                  (e) => e.length
                )
              )
            )
          ),
          A.reduce(0, (acc, cur) => acc + cur)
        )
      )
    )
    if (product.childComponentType === '단일상품') {
      LabelComponent = (
        <Tag
          variant="plain"
          text={__('{{count}}개 품목', { count: product.optionCount })}
        />
      )
      if (value.checked || nonRequiredOptionChecked) {
        LabelComponent = <SelectedLabel count={1 + nonRequiredOptionChecked} />
      }
    }
    if (product.childComponentType === '단일상품_입력형옵션') {
      LabelComponent = <CombineLabel />
      if (value.siOptions) {
        LabelComponent = (
          <SelectedLabel
            count={(value?.siOptions?.length || 0) + nonRequiredOptionChecked}
          />
        )
      }
    }
    if (product.childComponentType === '필수조합옵션') {
      LabelComponent = (
        <Tag
          variant="plain"
          text={__('{{count}}개 품목', { count: product.optionCount })}
        />
      )
      // 선택한 상품이있는지 확인하기.
      const checkedCount = pipe(
        value.optionDetails,
        O.fromNullable,
        O.fold(
          () => 0,
          flow(
            A.filter((e) => e.checked),
            (e) => e.length
          )
        )
      )

      if (checkedCount || nonRequiredOptionChecked) {
        LabelComponent = (
          <SelectedLabel count={checkedCount + nonRequiredOptionChecked} />
        )
      }
    }
    if (product.childComponentType === '필수조합옵션_입력형옵션') {
      LabelComponent = <CombineLabel />
      // 폼으로 추가한 상품의 갯수 가져오기
      const checkedCount = pipe(
        value?.rcoOptions,
        O.fromNullable,
        O.fold(
          () => 0,
          (e) => e.length as number
        )
      )
      if (checkedCount || nonRequiredOptionChecked) {
        LabelComponent = (
          <SelectedLabel count={checkedCount + nonRequiredOptionChecked} />
        )
      }
    }
    if (
      product.childComponentType === '필수비조합옵션' ||
      product.childComponentType === '필수비조합옵션_입력형옵션'
    ) {
      LabelComponent = <CombineLabel />
      // 폼으로 추가한 상품의 갯수 가져오기
      const checkedCount = pipe(
        value?.rncoOptions,
        O.fromNullable,
        O.fold(
          () => 0,
          (e) => e.length as number
        )
      )
      if (checkedCount || nonRequiredOptionChecked) {
        LabelComponent = (
          <SelectedLabel count={checkedCount + nonRequiredOptionChecked} />
        )
      }
    }
    // 상태값에서 선택된 상품이있는지 확인해서 가져오기.
    return (
      <WrappedComponent
        {...props}
        searchId={searchId}
        open={value?.open}
        setOpen={(e) => setValues({ ...value, open: e })}
      >
        <WrappedComponent.Slot name="label">
          {LabelComponent}
        </WrappedComponent.Slot>
      </WrappedComponent>
    )
  }

export const ProductSearchItemContainer = pipe(
  ProductSearchItem,
  withProductSearchItemContainer,
  React.memo
)
