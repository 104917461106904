import { useSyncExternalStore } from 'react'
import { z } from 'zod'
import { STEPS } from '~/container/onboarding/onboarding.constants'

// ====================================== 온보딩 스텝 ======================================

const STEP_STORAGE_KEY = 'onboarding-step'

const stepDto = z.number().int().gte(1).lte(STEPS.length)

type TStepDto = z.infer<typeof stepDto>

function getStoredOnboardingStep(): TStepDto {
  const storedValue = sessionStorage.getItem(STEP_STORAGE_KEY)
  try {
    return stepDto.parse(JSON.parse(storedValue ?? ''))
  } catch {
    return 1
  }
}

function setOnboardingStep(step: 'prev' | 'next') {
  const prevStep = getStoredOnboardingStep()
  const newStep = prevStep + (step === 'prev' ? -1 : 1)
  const parsed = stepDto.safeParse(newStep)

  if (parsed.success) {
    sessionStorage.setItem(STEP_STORAGE_KEY, JSON.stringify(parsed.data))
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: STEP_STORAGE_KEY,
        newValue: JSON.stringify(parsed.data),
      })
    )
  }
}

const stepStore = {
  getSnapshot: getStoredOnboardingStep,
  subscribe: (listener: () => void) => {
    window.addEventListener('storage', listener)
    return () => void window.removeEventListener('storage', listener)
  },
}

export function useOnboardingStep() {
  const step = useSyncExternalStore(stepStore.subscribe, stepStore.getSnapshot)
  return [step, setOnboardingStep] as const
}

// ====================================== 온보딩 완료 여부 ======================================

const COMPLETE_STORAGE_KEY = 'onboarding-complete'

const completeDto = z.boolean()

type TCompleteDto = z.infer<typeof completeDto>

function getStoredOnboardingComplete(): TCompleteDto {
  const storedValue = localStorage.getItem(COMPLETE_STORAGE_KEY)
  try {
    return completeDto.parse(JSON.parse(storedValue ?? ''))
  } catch {
    return false
  }
}

function setOnboardingComplete(complete: boolean) {
  if (complete) {
    localStorage.setItem(COMPLETE_STORAGE_KEY, JSON.stringify(complete))

    window.dispatchEvent(
      new StorageEvent('storage', {
        key: COMPLETE_STORAGE_KEY,
        newValue: JSON.stringify(complete),
      })
    )
  }
}

const completeStore = {
  getSnapshot: getStoredOnboardingComplete,
  subscribe: (listener: () => void) => {
    window.addEventListener('storage', listener)
    return () => void window.removeEventListener('storage', listener)
  },
}

export function useOnboardingComplete() {
  const step = useSyncExternalStore(
    completeStore.subscribe,
    completeStore.getSnapshot
  )
  return [step, setOnboardingComplete] as const
}
