import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { YN, 상수_결제상태, 상수_결제수단 } from '~/entities/@x'
import { mainPayment, 모델_페이먼트 } from '.'
import { fpDate } from '~/shared/utils'
import { isAfter, parseISO } from 'date-fns'

/**
 * 페이먼트 데이터가 있어야한다
 * 페이먼트 수단이 있어야한다.
 */
export const 페이먼트: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.data, mainPayment, O.isSome)
)

export const 무료배송: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) => (e.paidPrice === 0 ? O.some(e) : O.none)),
    O.isSome
  )
)

export const 복합과세: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.data.complexTax === YN.Y)
)

export const 환불정보유무: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data.data,
    A.filter(
      (ex) => ex.statusCd !== 상수_결제상태.입금전취소 && ex.isCancel === YN.Y
    ),
    (e) => e.length > 0
  )
)

export const 무통장입금: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.무통장입금 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 신용카드: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.신용카드 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 가상계좌: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.가상계좌 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 실시간계좌이체: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.실시간계좌이체 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 휴대폰결제: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.휴대폰결제 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 무료결제: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.무료결제 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 카카오페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.카카오페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 네이버페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.네이버페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 토스페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.토스페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 정기결제: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.정기결제 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 페이코: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) => (e.methodCd === 상수_결제수단.페이코 ? O.some(e) : O.none)),
    O.isSome
  )
)

export const 삼성페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.삼성페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 삼성페이_체크: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.삼성페이_체크 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 쓱페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) => (e.methodCd === 상수_결제수단.쓱페이 ? O.some(e) : O.none)),
    O.isSome
  )
)

export const L페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) => (e.methodCd === 상수_결제수단.L페이 ? O.some(e) : O.none)),
    O.isSome
  )
)

export const KB앱페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.KB앱페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 차이페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.차이페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 티머니페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.티머니페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 핀페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) => (e.methodCd === 상수_결제수단.핀페이 ? O.some(e) : O.none)),
    O.isSome
  )
)

export const 애플페이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.애플페이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 네이버페이_주문형: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.네이버페이_주문형 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 페이팔: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) => (e.methodCd === 상수_결제수단.페이팔 ? O.some(e) : O.none)),
    O.isSome
  )
)

export const 엑심베이: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.엑심베이 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 톡체크아웃_주문형: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.톡체크아웃_주문형 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 편의점결제: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) => ex.data,
    mainPayment,
    O.chain((e) =>
      e.methodCd === 상수_결제수단.편의점_결제 ? O.some(e) : O.none
    ),
    O.isSome
  )
)

export const 결제대기: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.data.statusCd === 상수_결제상태.결제대기)
)

export const 결제완료: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.data.statusCd === 상수_결제상태.결제완료)
)

export const 입금전: ISpecification<모델_페이먼트> = 결제대기.and(무통장입금)

export const 입금완료: ISpecification<모델_페이먼트> = 결제완료.and(무통장입금)

export const 결제기한초과: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.data.statusCd === 상수_결제상태.결제기한초과)
)

export const 입금전취소: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.data.statusCd === 상수_결제상태.입금전취소)
)

export const 수동입금: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) =>
      ex.payment?.bankTransfer?.bankAccount !== null &&
      ex.payment?.bankTransfer?.bankName !== null &&
      ex.payment?.bankTransfer?.accountHolderName !== null &&
      ex.payment?.bankTransfer?.bankAccount !== '' &&
      ex.payment?.bankTransfer?.bankName !== '' &&
      ex.payment?.bankTransfer?.accountHolderName !== ''
  )
)

export const 입금기한연장: ISpecification<모델_페이먼트> = new Spec(
  flow(
    (ex) =>
      (ex.payment?.bankTransfer?.expireTime !== null &&
        ex.payment?.bankTransfer?.expireTime !== undefined &&
        pipe(ex.payment.bankTransfer.expireTime, parseISO, fpDate(), (e) =>
          isAfter(new Date(e), new Date())
        )) ||
      (ex.payment?.virtual?.expireTime !== null &&
        ex.payment?.virtual?.expireTime !== undefined &&
        pipe(ex.payment?.virtual.expireTime, parseISO, fpDate(), (e) =>
          isAfter(new Date(e), new Date())
        ))
  )
)

export const 카드정보: ISpecification<모델_페이먼트> = 신용카드.or(토스페이)

export const 현금영수증PG연동발급가능: ISpecification<모델_페이먼트> = new Spec(
  flow((ex) => ex.payment?.cashReceipt?.isSupportedPgCashReceipt ?? false)
)
