import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse } from '~/shared/api'
import {
  type TPatchPayloadOrderSectionSplitDto,
  type TPatchResOrderSectionSplitDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서
 * @description order-section-split
 */
export function patchOrderSectionSplit({
  orderCode,
  sectionCode,
}: {
  orderCode: string
  sectionCode: string
}) {
  return async function (body: TPatchPayloadOrderSectionSplitDto) {
    type R = typeof body
    type T = TPatchResOrderSectionSplitDto
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/order/${orderCode}/sections/${sectionCode}/split`, body)
      .then((res) => res.data)

    return result
  }
}
