import React from 'react'
import {
  useStatusNextDeliveryDoneModalAtom,
  type TStatusNextDeliveryDoneBase,
} from '.'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'

const StatusNextDeliveryDoneContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.StatusNextDeliveryDone),
  }))
)

interface TStatusNextDeliveryDonePack extends TStatusNextDeliveryDoneBase {}
export function StatusNextDeliveryDonePack({
  orderCode,
  sectionCode,
  ...props
}: TStatusNextDeliveryDonePack) {
  const [open, setState] = useStatusNextDeliveryDoneModalAtom.state({
    atomKey: props.atomKey,
    orderCode,
    sectionCode,
  })

  return (
    <AlertDialog.Root open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <StatusNextDeliveryDoneContainer
            orderCode={orderCode}
            sectionCode={sectionCode}
            {...props}
          />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
