import React from 'react'
import { ProductSearchItemProps } from '../product-search-item.type'
import { ProductSearchItemBase } from '../product-search-item-base'
import { __ } from '~/shared/i18n'
import { cn } from '~/shared/utils'

export const ProductSearchItemB = ({
  id,
  name,
  price,
  disabled,
  currency = 'KRW',
  children,
  labelId,
}: React.PropsWithChildren<
  Omit<ProductSearchItemProps, 'optionCount' | 'option' | 'thumbnail'>
>) => (
  <ProductSearchItemBase
    {...{
      id,
      name,
      thumbnail: undefined,
      optionCount: undefined,
      labelId,
    }}
  >
    {children}
    <ProductSearchItemBase.Slot name="RightArea">
      <div className="typo-clay-body-medium">
        <div className={cn(disabled && 'text-[#BCC0C6]')}>
          {__('{{price, 3comma}} {{currency, currency}}', {
            price,
            formatParams: {
              currency: {
                currency,
              },
            },
          })}
        </div>
      </div>
    </ProductSearchItemBase.Slot>
  </ProductSearchItemBase>
)
