import { atom, useAtomValue, useSetAtom, useAtom } from 'jotai'

// ====================================== 뒤로가기 버튼 타겟 경로

const orderDetailHeaderGoBackAtom = atom<string | null>(null)
orderDetailHeaderGoBackAtom.debugLabel = 'orderDetailHeaderGoBack'

export const useOrderDetailHeaderGoBack = {
  state: () => useAtom(orderDetailHeaderGoBackAtom),
  get: () => useAtomValue(orderDetailHeaderGoBackAtom),
  set: () => useSetAtom(orderDetailHeaderGoBackAtom),
}
