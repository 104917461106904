import './app/bootstrap'
import './app/index.css'
import '@imwebme/clay-stylesheet'

import { HelmetProvider } from 'react-helmet-async'
import ReactDOM from 'react-dom/client'
import { routes } from '@imwebme/generouted-react-router'
import { sentryCreateBrowserRouter } from './app/provider/sentry'
import { RouterProvider } from 'react-router-dom'

const router = sentryCreateBrowserRouter(routes)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
)
