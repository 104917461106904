import React from 'react'
import { TExternalShippingBase } from '.'
import { OrderSectionSkeleton } from '~/shared/components/order-section-skeleton'

const ExternalShippingContainer = React.lazy(() =>
  import('.').then((mod) => ({ default: mod.ExternalShipping }))
)

interface TExternalShippingPack extends TExternalShippingBase {}
export function ExternalShippingPack(props: TExternalShippingPack) {
  return (
    <React.Suspense fallback={<OrderSectionSkeleton />}>
      <ExternalShippingContainer {...props} />
    </React.Suspense>
  )
}
