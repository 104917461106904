import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import { Controller } from 'react-hook-form'
import { useOrderSectionReturnComplete } from '..'
import { Select } from '~/shared/components/ui/select'
import { IconChevronDown } from '@imwebme/icon'
import { __, __g } from '~/shared/i18n'
import { 모델_반품금액계산 } from '~/entities/order-section/return-complete/model'
import { match } from 'ts-pattern'
import { Trans } from 'react-i18next'
import { Input } from '~/shared/components/input'
import * as math from 'mathjs'
import { ContentsBanner } from '@imwebme/clay-components'
import { useCurrency } from '~/shared/hooks/use-currency'
import { cn } from '~/shared/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { orderDetailOptions } from '~/entities/order-detail'
import { 상수_환불금액_타입 } from '~/entities/@x'

export function RefundPriceType({ $return }: { $return: 모델_반품금액계산 }) {
  const orderCode = useOrderCode()
  const { data } = useSuspenseQuery(orderDetailOptions({ orderCode }))
  const currency = useCurrency()
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    resetField,
  } = useOrderSectionReturnComplete()

  /**
   * @description 최대값을 넘었을때 각 컨트롤러에 에러등록
   */
  React.useEffect(() => {
    if (Number($return.form.requestRefundPrice) > $return.최대_환불가능_금액) {
      setError('requestRefundPrice', {
        message: __('최대 환불 가능 금액을 초과하였습니다.'),
      })
    } else if (
      Number($return.form.requestRefundPrice) <= $return.최대_환불가능_금액 &&
      errors.requestRefundPrice
    ) {
      clearErrors('requestRefundPrice')
    }
  }, [$return])

  /**
   * @description
   * 초기값 설정
   */
  React.useEffect(() => {
    resetField('refundPoint', {
      defaultValue:
        $return.form.refundPriceTypeCd === 상수_환불금액_타입.직접입력
          ? math.min(
              $return.returnSettlements.recreditedPoint,
              $return.returnSettlements.refundableAmount
            )
          : $return.returnSettlements.recreditedPoint,
    })
    resetField('requestRefundPrice', {
      defaultValue:
        $return.form.refundPriceTypeCd === 상수_환불금액_타입.직접입력
          ? $return.returnSettlements.refundableAmount
          : null,
    })
    resetField('deliveryExtraPrice._sub', {
      defaultValue: match<boolean>(true)
        .with(
          ($return.form.refundPriceTypeCd === 상수_환불금액_타입.실결제가 ||
            $return.form.refundPriceTypeCd ===
              상수_환불금액_타입.평균실결제가) &&
            data.deliveryPrice !== 0,
          () =>
            math.min(
              $return.최대_환불가능_배송비,
              math
                .chain(data.deliveryPrice)
                .add(data.deliveryIslandPrice)
                .done()
            )
        )
        .otherwise(() => null),
    })
    resetField('deliveryExtraPrice._add', { defaultValue: null })
  }, [$return.form.refundPriceTypeCd])

  return (
    <>
      <Controller
        control={control}
        name="refundPriceTypeCd"
        render={({ field }) => (
          <Select.Root
            defaultValue={field.value}
            value={field.value}
            onValueChange={field.onChange}
          >
            <Select.Trigger className="border-solid">
              <Select.Value placeholder="Select a fruit" />
              <IconChevronDown className="stroke-[2]" />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value="ORT01">{__g('ORT01')}</Select.Item>
              <Select.Item value="ORT02">{__g('ORT02')}</Select.Item>
              <Select.Item value="ORT03">{__g('ORT03')}</Select.Item>
              <Select.Item value="ORT05">{__g('ORT05')}</Select.Item>
            </Select.Content>
          </Select.Root>
        )}
      />
      {match($return.form.refundPriceTypeCd)
        .with('ORT05', () => (
          <div className="space-y-[20px] mt-[20px]">
            <div>
              <ContentsBanner
                type="secondary"
                variant="warning"
                text={__(
                  '금액 설정을 직접하는 경우 배송비와 적립금 설정이 제한되고 적립금 사용 주문은 현금 우선으로 환불돼요'
                )}
              />
            </div>
            <div className="space-y-[6px]">
              <div className="typo-clay-label-medium">{__('환불 금액')}</div>
              <Controller
                control={control}
                name="requestRefundPrice"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <div className="relative w-[180px]">
                      <Trans
                        i18nKey="<input /> <span>{{ currency, currency }}</span>"
                        values={{
                          formatParams: {
                            currency: {
                              currency,
                            },
                          },
                        }}
                        components={{
                          input: (
                            <Input
                              type="number"
                              value={pipe(field.value, String)}
                              onChange={flow(
                                (e) => e.target.value,
                                Number,
                                field.onChange
                              )}
                              className={cn(
                                'pr-[calc(12px*2+12px)]',
                                error &&
                                  'bg-clay-semantic-surfaceCritical border-none'
                              )}
                            />
                          ),
                          span: (
                            <span className="text-clay-semantic-textSub typo-clay-body-medium absolute right-[12px] top-1/2 -translate-y-1/2" />
                          ),
                        }}
                      />
                    </div>
                    {error && (
                      <div className="typo-clay-body-small">
                        {__('배송비 추가금액은 20만원 이하로 입력해주세요.')}
                      </div>
                    )}
                  </>
                )}
              />
              <div className="mt-[8px] typo-clay-body-small text-clay-semantic-textSub">
                {__('최대 {{price, 3comma}} {{currency, currency}} 가능', {
                  price: $return.최대_환불가능_금액,
                  formatParams: {
                    currency: {
                      currency,
                    },
                  },
                })}
              </div>
            </div>
          </div>
        ))
        .otherwise(() => null)}
    </>
  )
}
