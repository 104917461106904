import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { TGetResExternalOrderDto } from '~/entities/external-order'

export function findExternalOrderSection(sectionCode: string) {
  return function (data: TGetResExternalOrderDto) {
    const result = pipe(
      data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => {
          throw new Error('orderSectionList is null')
        },
        flow(
          A.findFirst((r) => r.orderSectionCode === sectionCode),
          O.fold(
            () => {
              throw new Error('orderSectionCode is null')
            },
            (r) => r
          )
        )
      )
    )
    return result
  }
}
