/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { patchPayloadOrdererDto } from '.'
import { z } from 'zod'

export const patchPayloadOrdererRdo = patchPayloadOrdererDto.extend({
  ordererEmailId: z.string().describe('주문자 이메일 아이디'),
  ordererEmailDomain: z.string().describe('주문자 이메일 도메인'),
  ordererEmail: z.string().nullish().describe('주문자 이메일'),
})
