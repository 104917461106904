import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Clay,
  Flex,
  Typography,
  Checkbox,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { debounce } from 'lodash'
import { __, __e } from '~/shared/i18n'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  postAmountDue,
  TPostResAmountDueDto,
} from '~/entities/order/amount-due'
import { postOrder } from '~/entities/order/order'
import {
  useActiveStepIdxAtom,
  useApiPayload,
  useDiscountSetupAtom,
  useFreeShippingOrder,
  useOrderInfoAtom,
  useValidAll,
} from '~/container/order-create/order-create.store'
import { STEPS } from '~/container/order-create/order-create.constants'
import { toast } from 'react-toastify'
import { match, P } from 'ts-pattern'
import { YN, 상수_주문상태 } from '~/entities/@x'
import { isAfter, isValid } from 'date-fns'
import { useCreatedModalAtom } from '~/container/orders/orders-header/created-orders-modal/created-orders-modal.store'
import { getOrderList, orderListQueryOptions } from '~/entities/order-list'
import { getIsFreeShippingOrder } from './free-shipping.fn'
import { EmptyPrice } from './empty-price'
import { Step1PriceDetails } from './step1-price-details'
import { Step3PriceDetails } from './step3-price-details'
import { Step2PriceDetails } from './step2-price-details'

export function Price() {
  const [amountDue, setAmountDue] = React.useState<TPostResAmountDueDto | null>(
    null
  )
  const [loading, setLoading] = React.useState<boolean>(false)
  const [withPaymentRequest, setWithPaymentRequest] =
    React.useState<boolean>(true)
  const [activeStepIdx] = useActiveStepIdxAtom()
  const [discountSetup, setDiscountSetup] = useDiscountSetupAtom()
  const [orderInfo] = useOrderInfoAtom()
  const currency = orderInfo.unitSite?.currency || 'KRW'
  const payloadIsValid = useValidAll()
  const apiPayload = useApiPayload()
  const navigate = useNavigate()
  const [, setCreatedModalOpen] = useCreatedModalAtom()
  const queryClient = useQueryClient()
  const [, setFreeShippingOrder] = useFreeShippingOrder()
  const pointAmountRef = React.useRef<number>(0)
  const canSubmit = payloadIsValid && activeStepIdx === STEPS.length - 1

  const { mutate: mutateAmountDue } = useMutation({
    mutationFn: postAmountDue(),
    onMutate({ inputPointAmount }) {
      pointAmountRef.current = inputPointAmount ?? 0
    },
    onSuccess(data) {
      setAmountDue(data.data)

      setFreeShippingOrder(getIsFreeShippingOrder(data.data))

      // 사용자가 입력한 적립금과 서버에서 실제 적용된 적립금 동기화
      // 적립금 입력값이 그대로고(사용자가 추가입력 X), 입력값이 응답값과 다르면 응답값으로 입력값을 덮어써서
      if (
        discountSetup.inputPointAmount === pointAmountRef.current &&
        discountSetup.inputPointAmount !== data.data.pointAmount
      ) {
        setDiscountSetup({ inputPointAmount: data.data.pointAmount })
      }
    },
    onError(data) {
      toast.error(
        __e(
          data.response?.data?.code
            ? __e(data.response.data.code)
            : __('금액 계산에 실패했어요')
        )
      )
    },
  })

  const { mutate: mutatePostOrder, isPending } = useMutation({
    mutationFn: postOrder(),
    onSuccess: async (data) => {
      setLoading(true)

      toast.success(__('주문이 생성되었어요'))

      if (data.data.isCompletePayment === YN.Y) {
        queryClient.removeQueries({
          queryKey: [orderListQueryOptions({}).queryKey[0]],
        })

        navigate('/orders', {
          replace: true,
        })
      } else {
        await queryClient.prefetchInfiniteQuery({
          queryKey: [
            'admin-create-orders',
            { orderStatusCd: [상수_주문상태.거래대기] },
          ],
          queryFn: ({ pageParam }) =>
            getOrderList({
              orderStatusCd: [상수_주문상태.거래대기],
              page: pageParam,
            }),
          initialPageParam: 1,
        })

        navigate('/orders', {
          replace: true,
        })
        setTimeout(() => {
          setCreatedModalOpen(true)
        }, 0)
      }
    },
    onError(e) {
      toast.error(
        __e(
          e.response?.data?.code
            ? __e(e.response.data.code)
            : __('주문 생성에 실패했어요')
        )
      )
    },
  })

  const debouncedAmountDue = React.useMemo(
    () =>
      debounce(() => {
        if (payloadIsValid) {
          mutateAmountDue(apiPayload)
        }
      }, 800),
    [apiPayload, payloadIsValid]
  )

  const handleSubmit = React.useCallback(() => {
    if (!payloadIsValid) {
      return
    }

    const hasInvalidTimeCoupon = discountSetup._selectedCoupons?.some(
      (coupon) => {
        if (coupon.isUnlimitedDate == YN.Y) {
          return false
        }

        const now = new Date()
        const start = new Date(coupon.startDate || '')
        const end = new Date(coupon.endDate || '')

        return (
          (isValid(start) && isAfter(start, now)) ||
          (isValid(end) && isAfter(now, end))
        )
      }
    )
    if (hasInvalidTimeCoupon) {
      toast.error(__('사용기간이 유효하지 않은 쿠폰이 있어요'))
      return
    }

    mutatePostOrder({
      ...apiPayload,
      ...(withPaymentRequest ? { isRequestPayment: YN.Y } : {}),
    })
  }, [
    apiPayload,
    payloadIsValid,
    discountSetup._selectedCoupons,
    withPaymentRequest,
  ])

  React.useEffect(() => {
    debouncedAmountDue()
    return () => debouncedAmountDue.cancel()
  }, [debouncedAmountDue])

  return (
    <Flex.Column rowGap={vars.spacing[5]} paddingTop={vars.spacing[12]}>
      <Clay
        background={vars.semantic.color.surface}
        borderRadius={vars.rounded.large}
        padding={`${vars.spacing[6]} 0`}
        width="280px"
        height="fit-content"
      >
        <Clay padding={vars.spacing[6]} paddingTop={0}>
          <Typography variant="heading-xlarge-bold">{__('금액')}</Typography>
        </Clay>

        {match({ activeStepIdx, payloadIsValid, amountDue })
          .with({ activeStepIdx: 0 }, () => <EmptyPrice />)
          .with(
            {
              activeStepIdx: 2,
              payloadIsValid: true,
              amountDue: P.nonNullable,
            },
            ({ amountDue: due }) => (
              <Step2PriceDetails
                {...{
                  amountDue: due,
                  currency,
                }}
              />
            )
          )
          .with(
            {
              activeStepIdx: 3,
              payloadIsValid: true,
              amountDue: P.nonNullable,
            },
            ({ amountDue: due }) => (
              <Step3PriceDetails
                {...{
                  amountDue: due,
                  currency,
                }}
              />
            )
          )
          .otherwise(() => (
            <Step1PriceDetails {...{ currency }} />
          ))}

        {canSubmit && (
          <Clay paddingInline={vars.spacing[6]} marginTop={vars.spacing[3]}>
            <Checkbox.Label
              label={__('결제 요청도 함께하기')}
              checked={withPaymentRequest}
              onChangeChecked={setWithPaymentRequest}
            />
          </Clay>
        )}
      </Clay>
      <Button
        fullWidth
        size="large"
        text={__('주문 생성')}
        variant="primary"
        isDisabled={!canSubmit}
        isLoading={isPending || loading}
        onClick={handleSubmit}
      />
    </Flex.Column>
  )
}
