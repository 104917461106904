import React from 'react'
import {
  Button,
  ButtonGroup,
  Clay,
  IconButton,
  Modal,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { __ } from '~/shared/i18n'
import { Trash03 } from '@imwebme/clay-icons'

interface Props {
  onConfirm: () => void
  isDisabled: boolean
}

export function FilterDeleteModal({ onConfirm, isDisabled }: Props) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <IconButton
        native={{ type: 'button' }}
        variant="secondary"
        size="tiny"
        isDisabled={isDisabled}
        icon={<Trash03 />}
        onClick={() => setOpen(true)}
      />

      <Modal isOpen={open} setClose={() => setOpen(false)} width="small">
        <Modal.Header
          text={__('필터를 삭제할까요?')}
          subtle={__('삭제한 필터 정보는 되돌릴 수 없어요')}
        />
        <Clay height={vars.spacing[6]} />
        <Modal.Footer>
          <ButtonGroup sx={{ justifyContent: 'flex-end' }}>
            <Button
              text={__('취소')}
              variant="outlined"
              onClick={() => setOpen(false)}
            />
            <Button
              text={__('필터 삭제')}
              variant="critical"
              onClick={() => {
                setOpen(false)
                onConfirm()
              }}
            />
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  )
}
