import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPostResCashReceiptProcessManualRevertDto,
  type TPostPayloadCashReceiptProcessManualRevertDto,
  postResCashReceiptProcessManualRevertDto,
  postPayloadCashReceiptProcessManualRevertDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/fkmJ50
 * @description 현금영수증 수동 발급 처리 되돌리기
 */
export function postCashReceiptProcessManualRevert({
  paymentCode,
}: {
  paymentCode: string
}) {
  return async function (body: TPostPayloadCashReceiptProcessManualRevertDto) {
    type R = typeof body
    type T = TPostResCashReceiptProcessManualRevertDto
    const result = await axios
      .post<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/payment/${paymentCode}/cash-receipt/process/manual/revert`, body)
      .then((res) => res.data)

    postResCashReceiptProcessManualRevertDto.parse(result.data)
    return result
  }
}
