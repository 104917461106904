import React from 'react'

/**
 * 타겟 요소에 세로 스크롤이 있는지 여부
 */
export function useHasScroll<T extends HTMLElement>() {
  const ref = React.useRef<T>(null)
  const [hasScroll, setHasScroll] = React.useState(false)

  React.useEffect(() => {
    // 리사이즈 이벤트는 고려하지 않음
    if (ref.current) {
      const { scrollHeight, clientHeight } = ref.current
      setHasScroll(scrollHeight > clientHeight)
    }
  }, [ref.current])

  return { ref, hasScroll }
}
