import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTabCode } from '~/shared/hooks/use-tab-code'

export default function Page() {
  const router = useNavigate()
  const tabCode = useTabCode()

  log.debug('index', tabCode)

  React.useEffect(() => {
    router(`/orders/tab/${tabCode}`)
  }, [])
  return null
}
