import { pipe, flow } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import * as N from 'fp-ts/number'
import * as Ord from 'fp-ts/Ord'
import { queryOptions } from '@tanstack/react-query'
import { getSiteClaims } from '.'

// 1. '구매 의사 취소'
// 2. '색상 및 사이즈 변경'
// 3. '다른 상품 잘못 주문'
// 4. '서비스 및 상품 불만족'
// 5. '배송 지연'
// 6. '배송 누락'
// 7. '상품 품절'
// 8. '상품 파손'
// 9. '상품 정보 상이'
// 10. '오배송'
// 11. '색상 등이 다른 상품을 잘못 배송'
// 해당 순서로 정렬한다
export function customSortClaims(_e: [key: string, value: string][]) {
  return _e.sort(([, a], [, b]) => {
    // Define the order for the claims
    const predefinedOrder = [
      '구매 의사 취소',
      '색상 및 사이즈 변경',
      '다른 상품 잘못 주문',
      '서비스 및 상품 불만족',
      '배송 지연',
      '배송 누락',
      '상품 품절',
      '상품 파손',
      '상품 정보 상이',
      '오배송',
      '색상 등이 다른 상품을 잘못 배송',
    ]
    const result = predefinedOrder.indexOf(a) - predefinedOrder.indexOf(b)
    // 커스텀 클레임인 경우 맨 뒤로 보낸다
    if (
      predefinedOrder.indexOf(a) === -1 ||
      predefinedOrder.indexOf(b) === -1
    ) {
      return -9999
    }
    return result
  })
}

export function siteClaimsQueryOptions({
  unit_code,
  type,
}: {
  unit_code: string
  type: 'return' | 'cancel'
}) {
  return queryOptions({
    queryKey: ['site-claims', type],
    queryFn: ({ queryKey }) =>
      getSiteClaims({
        claimType: queryKey[1] as 'cancel' | 'return',
        unit_code,
      }),
    select(d) {
      return pipe(d.data.claims, R.toArray, customSortClaims)
    },
  })
}
