import { useSyncExternalStore } from 'react'
import { 상수_배송지보기모드, T상수_배송지보기모드 } from './delivery.constants'

export const STORE_KEY = 'oms-order-table-delivery-viewmode'

type TStore = Map<string, T상수_배송지보기모드>

/**
 * 배송지보기모드 상태를 로컬스토리지에 저장
 */
export function setDeliveryViewMode(
  tabCode: string,
  viewMode: T상수_배송지보기모드
) {
  const data = localStorage.getItem(STORE_KEY)
  const map = data
    ? (new Map(JSON.parse(data)) as TStore)
    : new Map<string, T상수_배송지보기모드>()

  map.set(tabCode, viewMode)
  const serialized = JSON.stringify(Array.from(map))

  localStorage.setItem(STORE_KEY, serialized)

  window.dispatchEvent(
    new StorageEvent('storage', {
      key: STORE_KEY,
      newValue: serialized,
    })
  )
}

const store = {
  getSnapshot: (tabCode: string): T상수_배송지보기모드 => {
    const data = localStorage.getItem(STORE_KEY)
    const map = data
      ? (new Map(JSON.parse(data)) as TStore)
      : new Map<string, T상수_배송지보기모드>()

    return map.get(tabCode) ?? 상수_배송지보기모드.요약보기
  },
  subscribe: (listener: () => void) => {
    window.addEventListener('storage', listener)
    return () => void window.removeEventListener('storage', listener)
  },
}

/**
 * 외부 스토어(로컬스토리지)와 동기화된 배송지보기모드 상태를 반환
 */
export function useDeliveryViewMode(tabCode: string) {
  return useSyncExternalStore(store.subscribe, () => store.getSnapshot(tabCode))
}
