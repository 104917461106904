import React from 'react'
import { Modal, TextButton } from '@imwebme/clay-components'
import { Button } from '@imwebme/clay-components'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { __ } from '~/shared/i18n'
import { DeliveryTraceLoading } from './배송조회로딩'
import { BasicModal } from '~/shared/components/basic-modal'
import { TDeliveryTraceBase } from '.'

const DeliveryTraceContainer = React.lazy(() =>
  import('./delivery-trace.container').then((module) => ({
    default: React.memo(module.DeliveryTraceContainer),
  }))
)

interface TDeliveryTracePack extends TDeliveryTraceBase {
  invoiceNo: string
}

export function DeliveryTracePack({
  parcelCompanyName,
  invoiceNo,
  ...props
}: TDeliveryTracePack) {
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>
        <button
          type="button"
          className="text-clay-semantic-textPrimary font-semibold font-imweb leading-clay-6"
        >
          {`${parcelCompanyName} ${invoiceNo}`}
        </button>
      </AlertDialog.Trigger>

      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="bg-clay-semantic-surface rounded-clay-large px-0 pb-0 w-full max-w-[540px]">
          <BasicModal>
            <BasicModal.Slot name="title">{__('배송조회')}</BasicModal.Slot>
            <BasicModal.Slot name="content" className="px-0 mt-clay-5">
              <React.Suspense fallback={<DeliveryTraceLoading />}>
                <DeliveryTraceContainer {...{ parcelCompanyName }} {...props} />
              </React.Suspense>
            </BasicModal.Slot>
            <BasicModal.Slot name="footer" className="flex-wrap px-0 mt-0">
              <hr className="w-full border-clay-semantic-border" />
              <div className="px-clay-8 py-clay-5 flex justify-end">
                <AlertDialog.Cancel asChild>
                  <button type="button">
                    <Button as="div" variant="outlined" text={__('닫기')} />
                  </button>
                </AlertDialog.Cancel>
              </div>
            </BasicModal.Slot>
          </BasicModal>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
