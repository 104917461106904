import GrimIConGrayDocument from '~/shared/components/icon/grim-icon-gray-document.svg?react'
import { Flex, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Trans } from 'react-i18next'
import { __ } from '~/shared/i18n'

export function OrdersTableBodyEmpty() {
  return (
    <Flex.Center padding={vars.spacing[8]}>
      <Flex.Column alignItems="center" gap={vars.spacing[2]}>
        <GrimIConGrayDocument />
        <Typography variant="body-medium">{__('주문이 없어요')}</Typography>
        <Typography variant="body-small" colorToken="textSub">
          <Trans
            i18nKey="주문 처리 전 <tutorialbutton />을 진행해 보세요."
            components={{
              tutorialbutton: (
                <span
                  className="text-clay-semantic-textAccent cursor-pointer"
                  onClick={() => {
                    window.open(
                      `${
                        ImwebOAuth2ClientAPI.getInfo().siteUrl
                      }/admin/shopping/order-v1?subPath=${btoa('/onboarding')}`
                    )
                  }}
                >
                  {__('튜토리얼')}
                </span>
              ),
            }}
          />
        </Typography>
      </Flex.Column>
    </Flex.Center>
  )
}
