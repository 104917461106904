import { OrderEditCalcPage } from '~/container/order/order-add-calc'
import { useSuspenseQuery } from '@tanstack/react-query'
import { orderDetailOptions } from '~/entities/order-detail'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import React from 'react'
import { PageTopBar } from '~/shared/components/page-top-bar/page-top-bar'
import { Loading } from '~/shared/components/loading'
import { ErrorCard } from '~/shared/components/error'

export const Catch = () => <ErrorCard isFull />
export const Pending = () => <Loading />

function Page() {
  const orderCode = useOrderCode()

  const { data } = useSuspenseQuery(orderDetailOptions({ orderCode }))

  return (
    <>
      <PageTopBar />
      <OrderEditCalcPage orderNo={String(data.orderNo)} />
    </>
  )
}

export default () => (
  <React.Suspense>
    <Page />
    <div className="pt-[200px]" />
  </React.Suspense>
)
