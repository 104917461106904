import { type ISpecification, Spec } from 'spec-pattern-ts'

export type TDialogBulkStatus = {
  fulfilled?: number
  rejected?: number
}
export class CDialogBulkStatus {
  #fulfilled?: number
  #rejected?: number
  constructor({ fulfilled, rejected }: TDialogBulkStatus) {
    this.#fulfilled = fulfilled
    this.#rejected = rejected
  }

  get fulfilled() {
    return this.#fulfilled
  }
  get rejected() {
    return this.#rejected
  }
}

export const isFailed: ISpecification<CDialogBulkStatus> = new Spec(
  (candidate) => candidate.fulfilled === undefined || candidate.fulfilled === 0
)
