import React from 'react'
import { Button, ButtonGroup, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import {
  useMasterProd,
  useProductAddModalAtom,
} from '~/widgets/product-add-modal/product-add-modal.store'
import { useModals } from '~/router'
import { __ } from '~/shared/i18n'

interface Props {
  isOverflowing: boolean
}

export function ModalFooter({ isOverflowing }: Props) {
  const modals = useModals()
  const modalData = useProductAddModalAtom.get()

  const masterProd = useMasterProd()
  const totalCounter = Object.keys(masterProd).length

  const onSubmit = React.useCallback(() => {
    modalData.handleAddProduct(Object.values(masterProd))
    modals.close()
  }, [masterProd])

  return (
    <Clay
      paddingInline={vars.spacing[6]}
      paddingBottom={vars.spacing[4]}
      paddingTop={isOverflowing ? vars.spacing[3] : undefined}
      marginTop={isOverflowing ? undefined : vars.spacing[3]}
      boxShadow={isOverflowing ? vars.dropShadow.layer : undefined}
    >
      <ButtonGroup>
        <Button
          text={__('취소')}
          variant="outlined"
          fullWidth
          onClick={() => modals.close()}
        />
        <Button
          isDisabled={totalCounter === 0}
          text={__('{{count}}개 상품추가', {
            count: totalCounter,
          })}
          variant="primary"
          fullWidth
          onClick={onSubmit}
        />
      </ButtonGroup>
    </Clay>
  )
}
