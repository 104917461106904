import React from 'react'
import { Label } from '~/shared/components/ui/label'
import { RadioGroup } from '~/shared/components/ui/radio-group'
import { Trans } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Textfield } from '@imwebme/clay-components'
import { retrievePayTypeCd } from '~/container/order/order-return-info/schema'
import type { TReturnRequest } from '~/container/order/order-return-info/order-retrun-info.type'
import { __, __g } from '~/shared/i18n'
import * as RA from 'fp-ts/ReadonlyArray'
import { pipe } from 'fp-ts/function'
import { SearchParcelCompany } from '../search-parcel-company'

const filteredRetrievePayTypeCd = pipe(
  retrievePayTypeCd,
  RA.filter((e) => e !== 'RPT01' && e !== 'RPT04')
)

const RetrievePayTypeCdRadio = () => {
  const { control, setValue } = useFormContext<TReturnRequest>()

  // 비용지불방법 초기값 부여
  React.useEffect(() => {
    setValue(
      'retrieveRequestDto.retrievePayTypeCd',
      filteredRetrievePayTypeCd[0]
    )
  }, [])

  return (
    <div className="semantic-p14">
      <div>
        <Trans>비용</Trans>
      </div>
      <Controller
        name="retrieveRequestDto.retrievePayTypeCd"
        control={control}
        render={({
          field: { onChange, ref, value },
          fieldState: { error },
        }) => (
          <div>
            <RadioGroup
              className="flex gap-x-[24px] mt-[8px]"
              ref={ref}
              value={value ?? ''}
              onValueChange={onChange}
            >
              {filteredRetrievePayTypeCd.map((e) => (
                <div className="flex items-center space-x-2" key={e}>
                  <RadioGroup.Item
                    value={e}
                    id={e}
                    className="text-clay-semantic-textPrimary"
                  />
                  <Label htmlFor={e}>{__g(e)}</Label>
                </div>
              ))}
            </RadioGroup>
            {error && (
              <div className="typo-clay-body-small mt-[4px] text-clay-semantic-textCritical">
                {error.message}
              </div>
            )}
          </div>
        )}
      />
    </div>
  )
}

const InvoiceNumber = () => {
  const { control } = useFormContext<TReturnRequest>()
  return (
    <Controller
      name="retrieveRequestDto.invoiceNo"
      control={control}
      render={({ field: { value, ...rest }, fieldState: { error } }) => (
        <Textfield.Set>
          <Textfield.Box>
            <Textfield.Input
              {...rest}
              placeholder={__('송장번호를 입력해주세요')}
              value={value ?? ''}
              isValid={error ? false : undefined}
              autoComplete="off"
            />
          </Textfield.Box>
          <Textfield.HelperText className="text-clay-semantic-textCritical">
            {error?.message}
          </Textfield.HelperText>
        </Textfield.Set>
      )}
    />
  )
}

const InvoiceInfo = () => (
  <div className="semantic-p14">
    <div>
      <Trans>송장정보</Trans>
    </div>
    <div className="mt-[8px]">
      <SearchParcelCompany />
    </div>
    <div className="mt-[8px]">
      <InvoiceNumber />
    </div>
  </div>
)

/**
 * 수거정보 - 택배 수동 등록
 */
export const RRT04Component = () => (
  <div className="grid gap-y-[20px]">
    <RetrievePayTypeCdRadio />
    <InvoiceInfo />
  </div>
)
