import { memo, useState, Dispatch, SetStateAction } from 'react'
import {
  CheckCircle,
  Trash03,
  Edit01,
  CheckCircleFilled,
} from '@imwebme/clay-icons'
import { __, __e } from '~/shared/i18n'
import { MemoActions } from '../order-timeline-body'
import { fDate } from '~/shared/utils'
import {
  Avatar,
  Button,
  ButtonGroup,
  Clay,
  Flex,
  IconButton,
  Typography,
  Textarea,
} from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { TOrderMemoDataDtoSchema } from '~/entities/order-history'
import { YN } from '~/entities/@x'
import { CustomTooltip } from '~/container/order/order-timeline/components'

interface Props {
  name: string
  memo: TOrderMemoDataDtoSchema
  idx: number
  isDeletingOrderMemo: boolean
  isPatchingOrderMemo: boolean
  memoActions: MemoActions
}

export function OrderTimelineMemo({
  profileImgUrl,
  wtime,
  ...props
}: Props & {
  profileImgUrl: string | null
  wtime: string
}) {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <Flex
      gap={vars.spacing[2]}
      paddingBottom={vars.spacing[5]}
      _hover={{
        '.visible-when-hover': {
          visibility: 'visible',
        },
      }}
    >
      <Avatar
        size="xsmall"
        src={profileImgUrl ?? ''}
        sx={{ marginTop: vars.spacing['05'] }}
      />

      <Clay flex={1}>
        {isEditing ? (
          <MemoForm {...props} setIsEditing={setIsEditing} />
        ) : (
          <MemoRenderer {...props} setIsEditing={setIsEditing} />
        )}

        <Flex gap={vars.spacing[1]} marginTop={vars.spacing[2]}>
          <CustomTooltip
            trigger={
              <Typography
                as="time"
                variant="body-small-bold"
                colorToken="textSub"
                sx={{ display: 'block' }}
              >
                {fDate(new Date(wtime), 'a h:mm')}
              </Typography>
            }
            label={fDate(new Date(wtime), 'yyyy년 M월 d일 a h:m')}
          />
          {wtime !== props.memo.mtime && (
            <CustomTooltip
              trigger={
                <Typography variant="body-small" colorToken="textSub">
                  {__('수정됨')}
                </Typography>
              }
              label={fDate(new Date(props.memo.mtime), 'yyyy년 M월 d일 a h:m')}
            />
          )}
        </Flex>
      </Clay>
    </Flex>
  )
}

const MemoForm = memo(
  ({
    name,
    memo: serverMemo,
    memoActions,
    idx,
    isPatchingOrderMemo,
    setIsEditing,
  }: Props & {
    setIsEditing: Dispatch<SetStateAction<boolean>>
  }) => {
    const [localMemo, setLocalMemo] = useState(serverMemo.memo ?? '')

    const handleSubmit = async () => {
      try {
        await memoActions.update({
          idx,
          value: localMemo,
        })
        setIsEditing(false)
      } catch (error) {
        log.debug(error)
      }
    }

    return (
      <Flex.Column gap={vars.spacing[1]}>
        <Flex height={vars.spacing[7]} alignItems="center">
          <Typography variant="label-medium-bold">{name}</Typography>
        </Flex>

        <Textarea
          value={localMemo}
          onChange={setLocalMemo}
          characterCount
          maxLength={1000}
          height="max"
        />

        <ButtonGroup size="small" sx={{ justifyContent: 'flex-end' }}>
          <Button
            text={__('취소')}
            onClick={() => setIsEditing(false)}
            variant="outlined"
            size="tiny"
          />
          <Button
            text={__('저장')}
            onClick={handleSubmit}
            variant="primary"
            size="tiny"
            isDisabled={isPatchingOrderMemo}
          />
        </ButtonGroup>
      </Flex.Column>
    )
  }
)

const MemoRenderer = memo(
  ({
    name,
    memo: serverMemo,
    idx,
    memoActions,
    isPatchingOrderMemo,
    isDeletingOrderMemo,
    setIsEditing,
  }: Props & {
    setIsEditing: Dispatch<SetStateAction<boolean>>
  }) => (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={vars.spacing[1]}
      >
        <Typography variant="label-medium-bold">{name}</Typography>
        <Flex gap={vars.spacing[15]}>
          <CustomTooltip
            trigger={
              <IconButton
                icon={<Trash03 />}
                variant="secondary"
                size="tiny"
                className="visible-when-hover"
                sx={{ visibility: 'hidden' }}
                onClick={() => memoActions.delete({ idx })}
                isDisabled={isDeletingOrderMemo}
              />
            }
            label={__('삭제')}
            asChild
          />
          <CustomTooltip
            trigger={
              <IconButton
                icon={<Edit01 />}
                variant="secondary"
                size="tiny"
                className="visible-when-hover"
                sx={{ visibility: 'hidden' }}
                onClick={() => setIsEditing(true)}
              />
            }
            label={__('수정')}
            asChild
          />
          <CustomTooltip
            trigger={
              <IconButton
                icon={
                  serverMemo.isDone === YN.Y ? (
                    <CheckCircleFilled />
                  ) : (
                    <CheckCircle />
                  )
                }
                variant="secondary"
                size="tiny"
                onClick={() =>
                  memoActions.toggleIsDone({
                    idx,
                    value: serverMemo.isDone === YN.Y ? YN.N : YN.Y,
                  })
                }
                isDisabled={isPatchingOrderMemo}
              />
            }
            label={serverMemo.isDone === YN.Y ? __('미해결') : __('해결')}
            asChild
          />
        </Flex>
      </Flex>
      <Typography
        variant="body-medium"
        as="div"
        whiteSpace="pre-line"
        colorToken={serverMemo.isDone === YN.Y ? 'textMinimal' : 'text'}
      >
        {serverMemo.memo}
      </Typography>
    </>
  )
)
