import React from 'react'
import {
  useSelectedProdCommons,
  useDeliverySetupAtom,
} from '~/container/order-create/order-create.store'
import { __, __g } from '~/shared/i18n'
import { FieldLayout } from '~/container/order-create/partials/field-layout'
import { Clay, OptionItem, OptionList, Select } from '@imwebme/clay-components'
import { T상수_배송타입, 상수_배송타입 } from '~/entities/@x'
import { RESET } from 'jotai/utils'
import { getDefaultDelivPayType } from '~/container/order-create/order-create.fn'

export function DelivTypeField() {
  const [deliverySetup, setDeliverySetup] = useDeliverySetupAtom()
  const intersection = useSelectedProdCommons()

  const handleChange = React.useCallback(
    (_deliveryTypeCd: T상수_배송타입) => {
      if (
        _deliveryTypeCd === 상수_배송타입.다운로드 ||
        _deliveryTypeCd === 상수_배송타입.방문수령 ||
        _deliveryTypeCd === 상수_배송타입.배송없음
      ) {
        const prevCountry = deliverySetup.country
        const prevCountryName = deliverySetup.countryName
        const prevReceiverName = deliverySetup.receiverName
        const prevReceiverCall = deliverySetup.receiverCall

        setDeliverySetup(RESET)
        setDeliverySetup({
          _deliveryTypeCd,
          _deliveryPayTypeCd: getDefaultDelivPayType(
            _deliveryTypeCd,
            intersection.delivPayTypeList
          ),
          country: prevCountry,
          countryName: prevCountryName,
          receiverName: prevReceiverName,
          receiverCall: prevReceiverCall,
        })
      } else {
        setDeliverySetup({
          _deliveryTypeCd,
          _deliveryPayTypeCd: getDefaultDelivPayType(
            _deliveryTypeCd,
            intersection.delivPayTypeList
          ),
        })
      }
    },
    [deliverySetup, intersection.delivPayTypeList]
  )

  return (
    <FieldLayout title={__('배송 방식')}>
      <Clay width="220px">
        <Select>
          <Select.Trigger>
            <Select.Input
              value={
                deliverySetup._deliveryTypeCd
                  ? __g(deliverySetup._deliveryTypeCd)
                  : ''
              }
            />
          </Select.Trigger>
          <Select.Portal>
            <OptionList
              popOver
              value={deliverySetup._deliveryTypeCd}
              setValue={(v) => handleChange(v as T상수_배송타입)}
            >
              {intersection.delivTypeList.map((el) => (
                <OptionItem key={el} value={el} text={__g(el)} />
              ))}
            </OptionList>
          </Select.Portal>
        </Select>
      </Clay>
    </FieldLayout>
  )
}
