import React from 'react'
import { Typography, ButtonGroup, Button, Clay } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { Download01 } from '@imwebme/clay-icons'
import { __ } from '~/shared/i18n'
import { ExportModalPack } from '~/container/export/export-modal'
import { OmsTutorial } from './oms-tutorial'
import { Link } from '~/shared/components/link'
import { usePermissionAtom } from '~/app/provider/permission'
import { useDevice } from '~/shared/hooks/use-device'
import { CreatedOrdersModal } from './created-orders-modal/created-orders-modal'
import { useResetAllAtom } from '~/container/order-create/order-create.store'

export function OrdersHeader() {
  const permission = usePermissionAtom()
  const { desktop } = useDevice()
  const resetAllOrderCreateAtom = useResetAllAtom()

  return (
    <Clay
      display={desktop ? 'grid' : 'none'}
      gridTemplateColumns="1fr auto"
      gap={`${vars.spacing[3]} ${vars.spacing[4]}`}
      paddingTop={vars.spacing[8]}
      paddingBottom={vars.spacing[6]}
    >
      <Typography variant="heading-3xlarge-bold">{__('주문')}</Typography>

      <ButtonGroup isInline>
        <React.Suspense
          fallback={
            <Button
              variant="secondary"
              text={__('내보내기')}
              trailingIcon={<Download01 />}
              isDisabled
            />
          }
        >
          <ExportModalPack />
        </React.Suspense>

        <CreatedOrdersModal />

        {permission.adminShoppingOms.view === 'Y' &&
          permission.adminShoppingOms.edit === 'Y' && (
            <Link to="/order-create" onClick={resetAllOrderCreateAtom}>
              <Button as="div" text={__('주문 생성')} variant="primary" />
            </Link>
          )}
      </ButtonGroup>

      <Clay gridColumn="1/-1">
        <OmsTutorial />
      </Clay>
    </Clay>
  )
}
