import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_외부채널_주문서 } from './external-order.model'
import {
  상수_결제상태,
  상수_섹션상태,
  상수_주문상태,
  상수_주문타입,
  상수_판매채널,
} from '~/entities/@x'
import {
  모델_외부채널_주문섹션,
  스펙_외부채널_주문섹션,
} from '../external-order-section'
import { 스펙_외부채널_주문섹션_품목 } from '../external-order-section-item'

// ====================================== 스펙 유틸리티
export const 외부채널주문섹션스펙some = (
  $sectionSpec:
    | ISpecification<모델_외부채널_주문섹션>
    | ISpecification<모델_외부채널_주문섹션>[]
): ISpecification<모델_외부채널_주문서> =>
  new Spec((candidate) =>
    pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.findFirst((e) => {
            const $section = new 모델_외부채널_주문섹션(
              e,
              candidate.data.saleChannelItemList
            )
            if ($sectionSpec instanceof Array) {
              return pipe(
                $sectionSpec,
                A.some((spec) => spec.isSatisfiedBy($section))
              )
            } else {
              return $sectionSpec.isSatisfiedBy($section)
            }
          }),
          O.isSome
        )
      )
    )
  )

export const 외부채널주문섹션스펙모두보유 = (
  $sectionSpec: ISpecification<모델_외부채널_주문섹션>[]
): ISpecification<모델_외부채널_주문서> =>
  new Spec((candidate) =>
    pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter((e) => {
            const $section = new 모델_외부채널_주문섹션(
              e,
              candidate.data.saleChannelItemList
            )
            // 한가지라도 유요해야한다
            const result = pipe(
              $sectionSpec,
              A.some((spec) => spec.isSatisfiedBy($section))
            )
            return result
          }),
          (e) => e.length === candidate.data.orderSectionList!.length
        )
      )
    )
  )

const 섹션스펙필터링 = (
  sectionSpec: ISpecification<모델_외부채널_주문섹션>
): ISpecification<모델_외부채널_주문서> =>
  new Spec((candidate) =>
    pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter((e) =>
            sectionSpec.isSatisfiedBy(
              new 모델_외부채널_주문섹션(e, candidate.data.saleChannelItemList)
            )
          ),
          (e) => e.length > 0
        )
      )
    )
  )

// ====================================== 스펙
export const 주문서: ISpecification<모델_외부채널_주문서> = new Spec(
  flow(
    (candidate) =>
      candidate.data.orderTypeCd === 상수_주문타입.쇼핑 &&
      pipe(candidate.data.orderCode, O.fromNullable, O.isSome)
  )
)

export const 네이버페이주문서: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.saleChannelIdx === 상수_판매채널.네이버페이
)

export const 톡체크아웃주문서: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.saleChannelIdx === 상수_판매채널.톡체크아웃
)

/**
 * @description 일반상품주문서
 */
export const 결제대기: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.paymentInfo.statusCd === 상수_결제상태.결제대기
)

export const 입금기한_초과: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) =>
    pipe(
      candidate.data.paymentInfo.paymentList,
      A.findFirst((r) => r.statusCd === 상수_결제상태.결제기한초과),
      O.isSome
    ) && candidate.data.paymentInfo.statusCd === 상수_결제상태.결제대기
)

export const 섹션변경가능여부 = 주문서.and(결제대기.not())

export const 거래종료여부 = 주문서.and(
  new Spec((candidate) => candidate.data.statusCd === 상수_주문상태.거래종료)
)

/**
 * 반품 가능한 섹션이 있는가
 * @see https://www.notion.so/imweb/07-27025a52850b46dc9d8dee3040370a44
 */
export const 반품가능여부 = 주문서.and(
  new Spec((candidate) => {
    const result = pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter(
            ({ statusCd }) =>
              statusCd === 상수_섹션상태.배송중 ||
              statusCd === 상수_섹션상태.배송완료 ||
              statusCd === 상수_섹션상태.구매확정
          ),
          (e) => e.length > 0
        )
      )
    )
    return result
  })
)

export const 취소가능섹션존재유무: ISpecification<모델_외부채널_주문서> =
  new Spec((candidate) => {
    const result = pipe(
      candidate.data.orderSectionList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter(({ statusCd, invoice }) => {
            if (statusCd === 상수_섹션상태.상품준비) {
              return true
            }
            return statusCd === 상수_섹션상태.배송대기 && !invoice?.invoiceNo
          }),
          (e) => e.length > 0
        )
      )
    )
    return result
  })

export const PC: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.deviceCd === 'DTA01'
)

export const MOBILE: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.deviceCd === 'DTA02'
)

export const 주문섹션전체취소: ISpecification<모델_외부채널_주문서> = new Spec(
  flow(
    (candidate) =>
      candidate.data.orderSectionList?.every(
        (section) => section.statusCd === 상수_섹션상태.취소완료
      ) ?? false
  )
)

export const 일반상품주문: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.쇼핑
)

export const 예약상품주문: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.예약
)

export const 디지털상품주문: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.디지털
)

export const 그룹패스상품주문: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => candidate.data.orderTypeCd === 상수_주문타입.그룹패스
)

export const 일반상품취소가능여부 = 일반상품주문.and(
  외부채널주문섹션스펙some([
    스펙_외부채널_주문섹션.상품준비.and(
      스펙_외부채널_주문섹션
        .외부채널주문섹션품목some([
          스펙_외부채널_주문섹션_품목.채널상태_교환재배송처리중,
          스펙_외부채널_주문섹션_품목.채널상태_교환재배송대기,
          스펙_외부채널_주문섹션_품목.채널상태_교환재배송중,
        ])
        .not()
    ),
    스펙_외부채널_주문섹션.배송대기_송장등록전.and(
      스펙_외부채널_주문섹션
        .외부채널주문섹션품목some([
          스펙_외부채널_주문섹션_품목.채널상태_교환재배송처리중,
          스펙_외부채널_주문섹션_품목.채널상태_교환재배송대기,
          스펙_외부채널_주문섹션_품목.채널상태_교환재배송중,
        ])
        .not()
    ),
    스펙_외부채널_주문섹션.배송대기_송장등록후
      .and(스펙_외부채널_주문섹션.배송없는섹션)
      .and(
        스펙_외부채널_주문섹션
          .외부채널주문섹션품목some([
            스펙_외부채널_주문섹션_품목.채널상태_교환재배송처리중,
            스펙_외부채널_주문섹션_품목.채널상태_교환재배송대기,
            스펙_외부채널_주문섹션_품목.채널상태_교환재배송중,
          ])
          .not()
      ),
  ])
)

export const 디지털그룹패스취소가능여부 = 디지털상품주문.and(
  외부채널주문섹션스펙some([
    스펙_외부채널_주문섹션.상품준비,
    스펙_외부채널_주문섹션.구매확정,
  ])
)

export const 일반상품반품가능여부 = 일반상품주문.and(
  섹션스펙필터링(
    스펙_외부채널_주문섹션.배송중
      .or(스펙_외부채널_주문섹션.배송완료)
      .or(스펙_외부채널_주문섹션.구매확정)
  )
)

export const 디지털그룹패스반품가능여부 = 디지털상품주문
  .or(그룹패스상품주문)
  .not()
  .and(
    섹션스펙필터링(
      스펙_외부채널_주문섹션.배송중
        .or(스펙_외부채널_주문섹션.배송완료)
        .or(스펙_외부채널_주문섹션.구매확정)
    )
  )

// ====================================== 배송
export const 배송지노출여부: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) =>
    match(candidate.data)
      .with(
        {
          orderDeliveryList: P.nonNullable,
        },
        (e) => e.orderDeliveryList.length > 1
      )
      .otherwise(() => false)
)

export const 품목별배송메모: ISpecification<모델_외부채널_주문서> = new Spec(
  (candidate) => {
    const result = pipe(
      candidate.data.saleChannelItemList,
      O.fromNullable,
      O.fold(
        () => false,
        flow(
          A.filter(
            flow((e) => e.apiProductInfo.memo, O.fromNullable, O.isSome)
          ),
          (e) => e.length > 0
        )
      )
    )
    return result
  }
)
