/**
 * 주문목록 상세보기 드랍다운 스켈레톤
 * @description 로딩 및 에러 상태에 fallback으로 렌더된다
 */
export function Skeleton() {
  return (
    <div className="w-full px-[20px] bg-clay-semantic-surface space-y-[8px]">
      <div className="skeleton rounded-[4px] h-[24px]" />
      <div className="skeleton rounded-[4px] h-[24px]" />
      <div className="skeleton rounded-[4px] h-[24px]" />
      <div className="skeleton rounded-[4px] h-[24px]" />
      <div className="skeleton rounded-[4px] h-[40px]" />
    </div>
  )
}
