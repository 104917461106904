import { ISpecification, Spec } from 'spec-pattern-ts'
import { 모델_외부채널_주문섹션_품목_클레임정보 } from '.'
import { 상수_외부채널주문_클레임_보류상태 } from '~/entities/@x/외부채널주문_클레임_보류상태'

// ====================================== 스펙

export const 보류중: ISpecification<모델_외부채널_주문섹션_품목_클레임정보> =
  new Spec(
    (candidate) =>
      candidate.data?.holdbackStatus ===
      상수_외부채널주문_클레임_보류상태.보류중
  )

export const 보류해제: ISpecification<모델_외부채널_주문섹션_품목_클레임정보> =
  new Spec(
    (candidate) =>
      candidate.data?.holdbackStatus ===
      상수_외부채널주문_클레임_보류상태.보류해제
  )

export const 미보류: ISpecification<모델_외부채널_주문섹션_품목_클레임정보> =
  new Spec(
    (candidate) =>
      candidate.data?.holdbackStatus ===
      상수_외부채널주문_클레임_보류상태.미보류
  )

export const 배송비동봉: ISpecification<모델_외부채널_주문섹션_품목_클레임정보> =
  new Spec((candidate) =>
    (candidate.data?.claimDeliveryFeePayMethod || '').includes('동봉')
  )

export const 배송비직접송금: ISpecification<모델_외부채널_주문섹션_품목_클레임정보> =
  new Spec((candidate) =>
    (candidate.data?.claimDeliveryFeePayMethod || '').includes('송금')
  )

export const 배송비환불금차감: ISpecification<모델_외부채널_주문섹션_품목_클레임정보> =
  new Spec((candidate) =>
    (candidate.data?.claimDeliveryFeePayMethod || '').includes('차감')
  )
