import { AxiosResponse } from 'axios'
import { ApiClient, ApiResponse, type ApiVoidResponse } from '~/shared/api'
import {
  type TPatchResReturnRequestDto,
  type TPatchPayloadReturnRequestDto,
  patchResReturnRequestDto,
  patchPayloadReturnRequestDto,
} from '.'

const axios = ApiClient()

/**
 * @API문서 https://5k.gg/yIvGeD
 * @description [return] 외부 주문서 섹션 반품접수 변경
 */
export function patchReturnRequest({ orderCode }: { orderCode: string }) {
  return async function (body: TPatchPayloadReturnRequestDto) {
    type T = TPatchResReturnRequestDto
    type R = typeof body
    const result = await axios
      .patch<
        T,
        AxiosResponse<ApiResponse<T>>,
        R
      >(`/external-order/${orderCode}/sections/return/request`, body)
      .then((res) => res.data)

    const r = patchResReturnRequestDto.safeParse(result.data)
    if (r.success) {
      log.error(r)
    }
    return result
  }
}
