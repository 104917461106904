/**
 * @owner @Hansanghyeon
 * @project feature
 */
import {
  postPayloadOrderDeliveryDto,
  patchPayloadOrderDeliveryDto,
  deletePayloadOrderDeliveryDto,
} from '.'
import { z } from 'zod'

export const postPayloadOrderDeliveryRdo = postPayloadOrderDeliveryDto.extend({
  receiverName: z.string().min(1).describe('수령자명'),
  receiverCall: z.string().min(1).describe('수령자전화번호'),
  zipcode: z.string().max(8).describe('우편번호'),
  addr1: z.string().max(200).describe('주소1'),
  addr2: z.string().max(200).nullish().describe('주소2'),
  memo: z.string().max(200).nullish().describe('메모 (배송 요청사항)'),
})
export const patchPayloadOrderDeliveryRdo = patchPayloadOrderDeliveryDto.extend(
  {
    receiverName: z.string().min(1).describe('수령자명'),
    receiverCall: z.string().min(1).describe('수령자전화번호'),
    zipcode: z.string().max(8).describe('우편번호'),
    addr1: z.string().max(200).describe('주소1'),
    addr2: z.string().max(200).nullish().describe('주소2'),
    memo: z.string().max(200).nullish().describe('메모 (배송 요청사항)'),
  }
)
export const deletePayloadOrderDeliveryRdo = deletePayloadOrderDeliveryDto
