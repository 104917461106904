export class CalcHiddenTabs {
  private 탭간격 = 16
  private 보이는마지막탭인덱스 = -1
  private 더보기버튼너비 = 32 + this.탭간격

  constructor(
    private 최대로가능한탭목록너비: number,
    private 탭너비목록: number[],
    private 현재탭인덱스: number
  ) {}

  get 숨김탭이있는가() {
    return this.보이는마지막탭인덱스 < this.탭너비목록.length - 1
  }

  get 현재탭이숨김상태인가() {
    return this.현재탭인덱스 > this.보이는마지막탭인덱스
  }

  updateLastVisibleTabIdx() {
    let 탭누적너비 = 0
    let i = 0

    while (
      i < this.탭너비목록.length &&
      탭누적너비 < this.최대로가능한탭목록너비
    ) {
      탭누적너비 += this.탭너비목록[i] + this.탭간격

      if (탭누적너비 <= this.최대로가능한탭목록너비) {
        i += 1
      }
    }

    this.보이는마지막탭인덱스 = i - 1
    return this.보이는마지막탭인덱스
  }

  getLastVisibleTabIdx(): number {
    this.updateLastVisibleTabIdx()

    if (this.숨김탭이있는가) {
      this.최대로가능한탭목록너비 -= this.더보기버튼너비

      this.updateLastVisibleTabIdx()

      if (this.현재탭이숨김상태인가) {
        this.최대로가능한탭목록너비 -= this.탭너비목록[this.현재탭인덱스] + 16
        this.updateLastVisibleTabIdx()
      }
    }

    return this.보이는마지막탭인덱스
  }
}
