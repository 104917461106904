import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { COrderSection } from './class-order-section'
import type { TOrder } from './class-order.type'
import { OrderDetailData } from '~/entities/order-detail'
// 주문서 클래스는
// 주문서 자체의 정보를 가져올 수 있다
// 주문서의 섹션들만 가져올수있다.

/**
 * @deprecated
 */
export class COrder {
  #data

  // c3api에서 단일 주문 정보를 가져온 데이터를 담는다.
  constructor(data: TOrder) {
    this.#data = data
  }

  get data() {
    return this.#data
  }

  get meta() {
    return pipe(
      this.#data
      // omit(['orderSectionList', 'orderMemoList', 'paymentList']),
    )
  }

  get orderSectionList(): COrderSection[] {
    return pipe(
      this.#data.orderSectionList,
      A.map((section) => new COrderSection(section, this.#data.currency))
    )
  }
}

export class 모델_주문서 {
  data: OrderDetailData

  constructor(data: OrderDetailData) {
    this.data = data
  }
}
