import { isThisYear, Locale } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { ko, enUS } from 'date-fns/locale'
import { match, P } from 'ts-pattern'

type DateType = number | Date
// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
// format에 들어오는 date 값은 모두 UTC+0 기준이다.
/**
 *
 * @param date number | Date
 * @param formatStr string
 * @example
 * fDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
 */
export const fDate = (date: DateType, formatStr = 'PP') => {
  // Error이 함수를 사용하는 곳은 순수한 함수에서 사용할 수도 있다.
  // 그럴때 useContext를 사용함으로 문제가 생긴다.
  // const { locale } = useContext(LocaleContext)
  const _empty = ''

  return formatInTimeZone(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    formatStr,
    {
      locale: ko,
    }
  )
}

export const fpDate = (formatStr = 'PP') =>
  function (date: DateType) {
    return formatInTimeZone(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      formatStr,
      {
        locale: match(window.locale)
          .with('en', () => enUS)
          .otherwise(() => ko),
      }
    )
  }
export const ISO8601Format = "yyyy-MM-dd'T'HH:mm:ss'Z'"

export function lastDayOfYear() {
  // 현재 날짜를 가져옴
  const today = new Date()

  // 올해의 마지막 날을 얻기 위해 다음 해의 1월 1일에서 하루 전으로 설정
  const _lastDayOfYear = new Date(today.getFullYear() + 1, 0, 1)
  _lastDayOfYear.setDate(_lastDayOfYear.getDate() - 1)

  return _lastDayOfYear
}

export function fpNewDate(e: string) {
  return new Date(e)
}

export function getLocalTimezoneDifferenceFromUTC() {
  // 현재 날짜와 시간을 나타내는 Date 객체 생성
  const now = new Date()

  // 로컬 시간대와 UTC 시간대 간의 차이를 분 단위로 반환
  // 반환값은 로컬 시간이 UTC보다 몇 분 빠른지를 나타내는데,
  // 이 값은 로컬 시간대가 UTC보다 앞선 경우 양수, 뒤쳐진 경우 음수입니다.
  const timezoneOffsetInMinutes = now.getTimezoneOffset()

  // 시간 차이를 시간 단위로 변환 (분을 시간으로 변환)
  const timezoneOffsetInHours = timezoneOffsetInMinutes / 60

  return timezoneOffsetInHours
}

export function formatUTC(date: DateType) {
  return formatInTimeZone(date, 'UTC', ISO8601Format)
}

export function fDateToKo(date: Date) {
  return formatInTimeZone(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    isThisYear(date) ? 'M월 dd일 a h:mm' : 'yyyy년 M월 d일',
    {
      locale: ko,
    }
  )
}
