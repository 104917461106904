import { z } from 'zod'

export const 상수_송장배송상태 = {
  배송대기: 'IDS01', // 배송대기. 27:집하예정
  집하예정: 'IDS01', // 배송대기. 27:집하예정
  배송중: 'IDS02', // 배송중. 30:집하, 50:배송중
  집하: 'IDS02', // 배송중. 30:집하, 50:배송중
  배송완료: 'IDS03', // 배송완료. 70:배달완료
  오류: 'IDS04', // 오류. 29:미집하, 99:오류
  미집하: 'IDS04', // 오류. 29:미집하, 99:오류
} as const

const type = z.nativeEnum(상수_송장배송상태)
export type T상수_송장배송상태 = z.infer<typeof type>
