import React from 'react'
import { RadioGroup } from '~/shared/components/ui/radio-group'
import { Control, Controller } from 'react-hook-form'
import { __ } from '~/shared/i18n'

const OrderEditAddCalcPurchaseLevelDiscount = ({
  onChangePurchaseLevelDiscount,
  control,
}: {
  onChangePurchaseLevelDiscount?: (value: string) => void
  control: Control<any>
}) => (
  <div className="flex flex-col gap-[8px] self-stretch ">
    <div className="leading-[24px] text-[14px]">{__('구매등급할인')}</div>
    <Controller
      name="purchaseDiscount"
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <RadioGroup
          className="flex gap-[24px] items-end self-stretch"
          value={field.value}
          onValueChange={(value: string) => {
            field.onChange(value)
            onChangePurchaseLevelDiscount?.(value)
          }}
        >
          <div className="flex items-center space-x-2">
            <RadioGroup.Item value="Y" id="discountOption1" />
            <RadioGroup.Label
              htmlFor="discountOption1"
              className="text-[14px] leading-[20px]"
            >
              {__('적용')}
            </RadioGroup.Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroup.Item value="N" id="discountOption2" />
            <RadioGroup.Label
              htmlFor="discountOption2"
              className="text-[14px] leading-[20px]"
            >
              {__('미적용')}
            </RadioGroup.Label>
          </div>
        </RadioGroup>
      )}
    />
  </div>
)

export { OrderEditAddCalcPurchaseLevelDiscount }
