import { z } from 'zod'
import { 상수_액셀배치상태 } from '../@x/엑셀배치상태'

export const InvoiceExcelBatchSchema = z.object({
  memberName: z.string().nullish(),
  batchInvoiceCode: z.string(),
  statusCd: z.nativeEnum(상수_액셀배치상태),
  totalCnt: z.number(),
  successCnt: z.number(),
  failureCnt: z.number(),
  wtime: z.string().datetime({ offset: true }),
  fileName: z.string(),
  fileSize: z.number(),
  mtime: z.string().datetime({ offset: true }),
})
