import React from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import { FormProvider, useForm } from 'react-hook-form'
import { DevTool } from '~/app/provider/react-hook-form'

import { Button } from '@imwebme/clay-components'
import { productSearchItemRNCOResolver } from './schema'
import { TOption } from '../../product-search/product-search.type'
import type { payload } from './product-search-item-rnco.type'
import { ProductSearchOptionItemRNCO } from './product-search-item-rnco-option'
import { __ } from '~/shared/i18n'

export interface T_ProductSearchItemRNCO {
  id: string
  productPrice: string
  options: TOption[]
  values?: payload[]
  setValues: (e: payload[]) => void
}
export function ProductSearchItemRNCO({
  id,
  options,
  values,
  setValues,
}: T_ProductSearchItemRNCO) {
  const method = useForm<payload>({
    resolver: productSearchItemRNCOResolver,
  })
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = method

  const onSubmit = (e: payload) => {
    pipe(
      e,
      R.mapWithIndex((k, v) =>
        setValue(k, {
          optionCode: v.optionCode,
          optionName: v.optionName,
          optionValueCode: '',
          optionValueName: v.optionValueName,
          optionValue: '',
          price: v.price,
          type: v.type,
        })
      )
    )
    setValues([...(values || []), e])
  }

  return (
    <FormProvider {...method}>
      <div className="flex flex-col mb-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inline-block w-full">
            <div className="flex flex-col gap-[12px]">
              {pipe(
                options,
                A.map((e) => (
                  <div key={e.optionCode}>
                    <div className="semantic-p14">{e.optionName}</div>
                    <div className="mt-[6px]">
                      <ProductSearchOptionItemRNCO {...e} />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="mt-[12px]">
            <Button
              native={{ type: 'submit' }}
              variant="primary"
              tonal
              fullWidth
              text={__('목록에 추가')}
            />
          </div>
        </form>
      </div>
      {id === '1646' && <DevTool control={method.control} />}
    </FormProvider>
  )
}
