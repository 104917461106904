import React from 'react'
import { ErrorBoundary } from '@sentry/react'
import { AlertDialog } from '~/shared/components/ui/alert-dialog'
import { useDepositWaitingChangeAtom } from './결제정보_입금대기변경.store'
import { TDepositWaitingChangeBase } from './결제정보_입금대기변경.type'
import { ErrorCard } from '~/shared/components/error'

const DepositWaitingChangeContainer = React.lazy(() =>
  import('.').then((module) => ({
    default: React.memo(module.DepositWaitingChange),
  }))
)

interface TDepositWaitingChangePack extends TDepositWaitingChangeBase {}
export function DepositWaitingChangePack(props: TDepositWaitingChangePack) {
  const [open, setState] = useDepositWaitingChangeAtom.state({
    paymentCode: props.paymentCode + (props.atomKey || ''),
  })
  return (
    <AlertDialog.Root defaultOpen={false} open={open} onOpenChange={setState}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content className="rounded-[20px] p-0 pt-[32px] pb-[20px] gap-0 max-w-[380px]">
          <ErrorBoundary fallback={ErrorCard}>
            <React.Suspense fallback={<>{'...loading'}</>}>
              <DepositWaitingChangeContainer {...props} />
            </React.Suspense>
          </ErrorBoundary>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
