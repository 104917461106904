import { atom } from 'jotai'

// 반품모달
export const OrderReturnInfoReturnRequestModalAtom = atom(false)
OrderReturnInfoReturnRequestModalAtom.debugLabel =
  'OrderReturnInfoReturnRequestModalAtom'

// 환불 같이하는 모달
export const OrderReturnInfoReturnRequestModalWithRefundAtom = atom(false)
OrderReturnInfoReturnRequestModalAtom.debugLabel =
  'OrderReturnInfoReturnRequestModalAtom'

// 환불도 함께 진행할지 여부
export const OrderReturnInfoReturnRequestWithRefundAtom = atom(false)
OrderReturnInfoReturnRequestWithRefundAtom.debugLabel =
  'OrderReturnInfoReturnRequestWithRefundAtom'
