import React from 'react'
import { __ } from '~/shared/i18n'
import { Selector } from '../components'
import * as O from 'fp-ts/lib/Option'
import { findFirst } from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/function'
import { find } from 'lodash'
import { orderInfoOptions } from './order-info.constants'
import { OrderInfoFieldItem } from '~/container/order-search-filter/order-search-filter-form'
import { OptionItem } from '@imwebme/clay-components'
interface Props {
  orderInfoFieldList: OrderInfoFieldItem[]
  addOrderInfoField: (item: OrderInfoFieldItem) => void
}

export const OrderInfoFieldCreator = ({
  orderInfoFieldList,
  addOrderInfoField,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const handleAddOrderInfoField = React.useCallback(
    (fieldItem: OrderInfoFieldItem) => {
      pipe(
        orderInfoFieldList,
        findFirst<OrderInfoFieldItem>((item) => item.value === fieldItem.value),
        O.fold(
          () => {
            addOrderInfoField(fieldItem)
          },
          () => undefined
        )
      )
      setDropdownOpen(false)
    },
    [orderInfoFieldList]
  )

  return (
    <Selector
      label={__('주문정보')}
      isActive={false}
      resetField={log.debug}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownContent={
        <div className="min-w-[152px]">
          {orderInfoOptions.map((item) => (
            <OptionItem
              key={item.value}
              text={item.label}
              isDisabled={!!find(orderInfoFieldList, { value: item.value })}
              onClick={() => handleAddOrderInfoField(item)}
            />
          ))}
        </div>
      }
    />
  )
}
