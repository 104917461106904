import React, { useEffect } from 'react'
import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import { ProductSearch, type ProductSearchProps } from './product-search'
import { useProductSearchAtom } from './product-search.store'
import type { TItem } from './product-search.type'

// ProductSearchProps['items']에서 isChecked를 제외한 모든 속성을 상속받는다.
export interface ProductSearchContainerProps
  extends Omit<ProductSearchProps, 'items'> {
  items: ProductSearchProps['items'][0][]
  onSelectedItemsChange?: (items: TItem[]) => void
  searchValue?: string
  isLoading: boolean
}

const ProductSearchContainer = ({
  searchId: id,
  items: _items,
  onSelectedItemsChange,
  searchValue,
  ...props
}: ProductSearchContainerProps) => {
  // jotai가
  const [{ values: items }, setItems] = useProductSearchAtom.items(id)
  const selectedItems = useProductSearchAtom.selectedItems(id)

  useEffect(() => {
    setItems((prev) => ({
      id,
      values: pipe(
        _items,
        A.map((e) => ({ ...e })),
        // 불러온 데이터가 list에 이미 있다면 `open`은 유지해야한다.
        A.map((e) => {
          const prevItem = prev.values.find(
            (item) => item.prodCode === e.prodCode
          )
          if (prevItem) {
            return { ...e, open: prevItem.open }
          }
          return { ...e }
        })
      ),
    }))
  }, [_items, setItems, id])

  useEffect(() => {
    // 검색어가변경되고 items가 변경되니까 다시 initialItems를 설정한다.
    if (searchValue !== '') {
      setItems({
        id,
        values: pipe(
          _items,
          A.map((e) => ({ ...e, open: false }))
        ),
      })
    }
  }, [searchValue])

  useEffect(() => {
    if (selectedItems) {
      onSelectedItemsChange?.(selectedItems.values)
    }
  }, [onSelectedItemsChange, selectedItems])
  return <ProductSearch {...props} items={items} searchId={id} />
}

export const ProductSearchContainerMemo = React.memo(ProductSearchContainer)
