import { match, P } from 'ts-pattern'
import { pipe, flow } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import { useSuspenseQuery } from '@tanstack/react-query'
import { TExternalProductPreparationBase } from '.'
import { externalOrderQueryOptions } from '~/entities/external-order'
import { useOrderCode } from '~/shared/hooks/use-order-code'
import { findExternalOrderSection } from '~/shared/calc/find-external-order-section'
import {
  모델_외부채널_주문서,
  스펙_외부채널_주문서,
} from '~/entities/external-order/model/external-order'
import { 모델_외부채널_주문섹션 } from '~/entities/external-order/model/external-order-section'
import { OrderSection } from '~t'
import { __ } from '~/shared/i18n'
import { fpDate } from '~/shared/utils'
import {
  ExternalItemMutableList,
  useFormContextExternalItemMutableList,
} from '../partials/external-item-mutable-list'
import { Button } from '@imwebme/clay-components'
import {
  NextShippingReadyModalPack,
  useNextShippingReadyModalAtom,
} from '~/entities/external-order-section/next-shipping-ready/modal'
import { Menubar } from '~/shared/components/menubar'
import { IconDotsVertical } from '@imwebme/icon'
import {
  ExternalDeliveryEditModalPack,
  useExternalDeliveryEditModalAtom,
} from '~/features/모달_외부채널_배송지수정'
import {
  DeliveryHoldModalPack,
  useDeliveryHoldModalAtom,
} from '~/entities/external-order-section/delivery-hold/modal'
import { YN } from '~/entities/@x'
import {
  DeliveryDelayModalPack,
  useDeliveryDelayModalAtom,
} from '~/entities/external-order-section/delivery-delay/modal'
import { ExternalDeliveryPack } from '../partials/external-delivery/external-delivery.pack'
import { IconButton } from '@imwebme/clay-components'
import { DotsVertical } from '@imwebme/clay-icons'
import { useSectionCode } from '~/shared/hooks/use-section-code'

interface TExternalProductPreparation extends TExternalProductPreparationBase {}
export function ExternalProductPreparation(_: TExternalProductPreparation) {
  const orderCode = useOrderCode()
  const sectionCode = useSectionCode(true)
  const {
    data: { order, section },
  } = useSuspenseQuery({
    ...externalOrderQueryOptions({ orderCode }),
    select(e) {
      return {
        order: e.data,
        section: pipe(e.data, findExternalOrderSection(sectionCode)),
      }
    },
  })
  const $exOrder = new 모델_외부채널_주문서(order)
  const $exSection = new 모델_외부채널_주문섹션(section)
  // ======================================
  const onOpenNextShippingReady = useNextShippingReadyModalAtom.set({
    orderCode,
    sectionCode,
  })

  return (
    <ExternalItemMutableList>
      {({ List }) => (
        <>
          <OrderSection name="외부채널_상품준비중">
            <OrderSection.Slot name="title">
              <div className="grid grid-cols-[1fr,auto] gap-x-[8px]">
                <div>
                  <div className="typo-clay-heading-xlarge-bold">
                    {__('상품준비')}{' '}
                    {pipe(
                      section.orderSectionItemList,
                      O.fromNullable,
                      O.fold(
                        () => null,
                        flow(
                          (e) => e.length,
                          (e) => (
                            <span className="text-clay-semantic-textPrimary">
                              {e}
                            </span>
                          )
                        )
                      )
                    )}
                  </div>
                  <div className="mt-[8px] typo-clay-body-medium text-clay-semantic-textSub">
                    {__('#{{섹션넘버}} · {{생성일}} 생성', {
                      생성일: pipe(
                        section.wtime,
                        O.fromNullable,
                        O.fold(
                          () => new Date(),
                          (e) => new Date(e)
                        ),
                        fpDate('yyyy년 MM월 dd일')
                      ),
                      섹션넘버: section.orderSectionNo,
                    })}
                  </div>
                </div>
                {/* 외부채널:상품준비중:액션메뉴 */}
                <div>
                  {match<boolean>(true)
                    .with(
                      // 디지털상품이 아니고 그룹패스상품이 아니고 예약상품이 아니고 입금확인여부가 true인 주문서
                      스펙_외부채널_주문서.섹션변경가능여부.isSatisfiedBy(
                        $exOrder
                      ),
                      () => (
                        <ExternalProductPreparationMenu
                          $exSection={$exSection}
                        />
                      )
                    )
                    .otherwise(() => (
                      <IconButton
                        native={{ type: 'button' }}
                        variant="secondary"
                        size="tiny"
                        aria-label={__('메뉴')}
                        icon={<DotsVertical color="inherit" />}
                        isDisabled
                      />
                    ))}
                </div>
              </div>
            </OrderSection.Slot>
            <OrderSection.Slot
              name="body"
              className="mt-[24px] [--mx:24px] space-y-[24px]"
            >
              <ExternalDeliveryPack
                sectionCode={sectionCode}
                deliveryCode={section.orderDeliveryCode}
              />
              <List />
            </OrderSection.Slot>
            <OrderSection.Slot name="footer" className="mt-[16px] mx-[24px]">
              <div className="flex justify-end">
                <Button
                  native={{ type: 'button' }}
                  variant="primary"
                  onClick={() => onOpenNextShippingReady(true)}
                  text={__('배송대기 처리')}
                  isDisabled={스펙_외부채널_주문서.섹션변경가능여부
                    .not()
                    .isSatisfiedBy($exOrder)}
                />
              </div>
            </OrderSection.Slot>
          </OrderSection>
          <NextShippingReadyModalPackForm />
        </>
      )}
    </ExternalItemMutableList>
  )
}

function NextShippingReadyModalPackForm() {
  const sectionCode = useSectionCode(true)
  const { handleSubmit, watch } = useFormContextExternalItemMutableList()
  const isAll = pipe(
    watch('targetItemInformation'),
    A.every((r) => !r._check)
  )

  const selectedItems = pipe(watch('targetItemInformation'), (e) =>
    pipe(
      e,
      A.findFirst((r) => r._check === true),
      O.fold(
        () =>
          pipe(
            e,
            A.map(({ orderSectionItemCode, qty }) => ({
              orderSectionItemCode,
              qty,
            }))
          ),
        () =>
          pipe(
            e,
            A.filter(flow((r) => !!r._check)),
            A.map(({ orderSectionItemCode, qty }) => ({
              orderSectionItemCode,
              qty,
            }))
          )
      )
    )
  )
  return (
    <NextShippingReadyModalPack
      onSubmit={(mutate) => {
        handleSubmit((body) => {
          pipe(
            body.targetItemInformation,
            A.filter(flow((r) => isAll || !!r._check)),
            A.map(({ _check, ...e }) => e),
            (e) => ({
              orderSectionCode: sectionCode,
              targetItemInformation: e,
            }),
            mutate
          )
        })()
      }}
      isAll={isAll}
      selectedItemCount={selectedItems.length}
    />
  )
}

function ExternalProductPreparationMenu({
  $exSection,
}: {
  $exSection: 모델_외부채널_주문섹션
}) {
  const orderCode = useOrderCode()
  const sectionCode = $exSection.data.orderSectionCode
  const deliveryCode = $exSection.data.orderDeliveryCode
  const onExternalDeliveryHoldModal = useDeliveryHoldModalAtom.set({
    orderCode,
    sectionCode,
  })
  const onExternalDeliveryEditModal = useExternalDeliveryEditModalAtom.set({
    orderCode,
    deliveryCode: deliveryCode || '',
  })
  const onDeliveryDelayModal = useDeliveryDelayModalAtom.set({
    orderCode,
    sectionCode,
  })
  const { handleSubmit, watch } = useFormContextExternalItemMutableList()

  return (
    <>
      <Menubar>
        <Menubar.Menu>
          <Menubar.Trigger asChild>
            <IconButton
              native={{ type: 'button' }}
              variant="secondary"
              size="tiny"
              icon={
                <IconDotsVertical
                  className="stroke-[2]"
                  width={16}
                  height={16}
                />
              }
            />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content>
              <Menubar.Item onSelect={() => onExternalDeliveryHoldModal(true)}>
                {__('배송보류 설정')}
              </Menubar.Item>
              {deliveryCode && (
                <Menubar.Item
                  onSelect={() => onExternalDeliveryEditModal(true)}
                >
                  {__('배송지 변경')}
                </Menubar.Item>
              )}
              <Menubar.Item onSelect={() => onDeliveryDelayModal(true)}>
                {__('발송지연 처리')}
              </Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
      </Menubar>
      <DeliveryHoldModalPack sectionCode={sectionCode} hold={YN.Y} />
      <DeliveryDelayModalPack
        sectionCode={sectionCode}
        onSubmit={(mutate, modalBody) => {
          handleSubmit((body) => {
            const isAll = pipe(
              watch('targetItemInformation'),
              A.every((r) => !r._check)
            )
            pipe(
              body.targetItemInformation,
              A.filter(flow((r) => isAll || !!r._check)),
              A.map((e) => e.orderSectionItemCode),
              (e) => ({
                orderSectionItemCodes: e,
                orderSectionCode: sectionCode,
                ...modalBody,
              }),
              pipeLog('발송지연처리 payload'),
              mutate
            )
          })()
        }}
      />
      {deliveryCode && (
        <ExternalDeliveryEditModalPack deliveryCode={deliveryCode} />
      )}
    </>
  )
}
