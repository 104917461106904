/**
 * @owner @Hansanghyeon
 * @project feature
 */
import { z } from 'zod'
import { patchPayloadReturnRequestDto, targetSectionInformationDto } from '.'

export const targetSectionInformationRdo = targetSectionInformationDto.extend({
  targetSectionInformation: z.array(
    z.object({
      orderSectionCode: z.string(),
      targetItemInformation: z.array(
        z.object({
          orderSectionItemCode: z.string(),
          qty: z.number(),
          _check: z.boolean(),
        })
      ),
    })
  ),
})

export const patchPayloadReturnRequestRdo = patchPayloadReturnRequestDto
