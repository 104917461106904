import { Textfield } from '@imwebme/clay-components'
import { Controller, useFormContext } from 'react-hook-form'
import { PostcodeSearchButton } from '../postcode-search-button'
import { TReturnRequest } from '~/container/order/order-return-info/order-retrun-info.type'
import { __ } from '~/shared/i18n'

export const AddressField = () => {
  const { control, setValue, setFocus, resetField, getValues, watch } =
    useFormContext<TReturnRequest>()

  return (
    <div className="flex flex-col gap-[20px] mt-[20px]">
      <div>
        {__('받는 분')}
        <div className="mt-[6px]">
          <Controller
            name="retrieveRequestDto.receiverName"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <Textfield.Set>
                <Textfield.Box>
                  <Textfield.Input
                    {...rest}
                    placeholder={__('받는 분')}
                    value={value ?? ''}
                    isValid={error ? false : undefined}
                  />
                </Textfield.Box>
                <Textfield.HelperText className="text-clay-semantic-textCritical">
                  {error?.message}
                </Textfield.HelperText>
              </Textfield.Set>
            )}
          />
        </div>
      </div>
      <div>
        {__('연락처')}
        <div className="mt-[6px]">
          <Controller
            name="retrieveRequestDto.receiverCall"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <Textfield.Set>
                <Textfield.Box>
                  <Textfield.Input
                    {...rest}
                    placeholder={__('연락처')}
                    value={value ?? ''}
                    isValid={error ? false : undefined}
                  />
                </Textfield.Box>
                <Textfield.HelperText className="text-clay-semantic-textCritical">
                  {error?.message}
                </Textfield.HelperText>
              </Textfield.Set>
            )}
          />
        </div>
      </div>
      <div>
        {__('주소정보')}
        <div
          className="mt-[6px] grid grid-cols-[1fr,auto] gap-x-[8px] gap-y-[12px]"
          style={{
            gridTemplateAreas: "'zipcode search' 'addr1 addr1' 'addr2 addr2'",
          }}
        >
          <div style={{ gridArea: 'zipcode' }}>
            <Controller
              name="retrieveRequestDto.zipcode"
              control={control}
              render={({
                field: { value, ...rest },
                fieldState: { error },
              }) => (
                <Textfield.Set>
                  <Textfield.Box>
                    <Textfield.Input
                      {...rest}
                      placeholder={__('우편번호')}
                      value={value ?? ''}
                      isValid={error ? false : undefined}
                      readOnly
                    />
                  </Textfield.Box>
                  <Textfield.HelperText className="text-clay-semantic-textCritical">
                    {error?.message}
                  </Textfield.HelperText>
                </Textfield.Set>
              )}
            />
          </div>
          <div style={{ gridArea: 'search' }}>
            <PostcodeSearchButton
              onComplete={(addr) => {
                setValue('retrieveRequestDto.addr1', addr.fullAddress)
                setValue('retrieveRequestDto.zipcode', addr.zonecode)
                setFocus('retrieveRequestDto.addr2')
              }}
            />
          </div>
          <div style={{ gridArea: 'addr1' }}>
            <Controller
              name="retrieveRequestDto.addr1"
              control={control}
              render={({
                field: { value, ...rest },
                fieldState: { error },
              }) => (
                <Textfield.Set>
                  <Textfield.Box>
                    <Textfield.Input
                      {...rest}
                      placeholder={__('도로명 혹은 지번 주소')}
                      value={value ?? ''}
                      isValid={error ? false : undefined}
                      readOnly
                    />
                  </Textfield.Box>
                  <Textfield.HelperText className="text-clay-semantic-textCritical">
                    {error?.message}
                  </Textfield.HelperText>
                </Textfield.Set>
              )}
            />
          </div>
          <div style={{ gridArea: 'addr2' }}>
            <Controller
              name="retrieveRequestDto.addr2"
              control={control}
              render={({
                field: { value, ...rest },
                fieldState: { error },
              }) => (
                <Textfield.Set>
                  <Textfield.Box>
                    <Textfield.Input
                      {...rest}
                      placeholder={__('상세주소')}
                      value={value ?? ''}
                      isValid={error ? false : undefined}
                    />
                  </Textfield.Box>
                  <Textfield.HelperText className="text-clay-semantic-textCritical">
                    {error?.message}
                  </Textfield.HelperText>
                </Textfield.Set>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
