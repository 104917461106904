import { z } from 'zod'

export const IMWEB_BANK_CODE = {
  CCB01: 'CCB01',
  CCB02: 'CCB02',
  CCB03: 'CCB03',
  CCB04: 'CCB04',
  CCB05: 'CCB05',
  CCB06: 'CCB06',
  CCB07: 'CCB07',
  CCB08: 'CCB08',
  CCB09: 'CCB09',
  CCB10: 'CCB10',
  CCB11: 'CCB11',
  CCB12: 'CCB12',
  CCB13: 'CCB13',
  CCB14: 'CCB14',
  CCB15: 'CCB15',
  CCB16: 'CCB16',
  CCB17: 'CCB17',
  CCB18: 'CCB18',
  CCB19: 'CCB19',
  CCB20: 'CCB20',
  CCB21: 'CCB21',
  CCB22: 'CCB22',
  CCB23: 'CCB23',
  CCB24: 'CCB24',
  CCB25: 'CCB25',
  CCB26: 'CCB26',
  CCB27: 'CCB27',
  CCB28: 'CCB28',
  CCB29: 'CCB29',
  CCB30: 'CCB30',
  CCB31: 'CCB31',
  CCB32: 'CCB32',
  CCB33: 'CCB33',
  CCB34: 'CCB34',
  CCB35: 'CCB35',
  CCB36: 'CCB36',
  CCB37: 'CCB37',
  CCB38: 'CCB38',
  CCB39: 'CCB39',
  CCB40: 'CCB40',
  CCB41: 'CCB41',
  CCB42: 'CCB42',
  CCB43: 'CCB43',
  CCB44: 'CCB44',
  CCB45: 'CCB45',
  CCB46: 'CCB46',
  CCB47: 'CCB47',
  CCB48: 'CCB48',
  CCB49: 'CCB49',
  CCB50: 'CCB50',
} as const

const type = z.nativeEnum(IMWEB_BANK_CODE)
export type TIMWEB_BANK_CODE = z.infer<typeof type>

export const IMWEB_BANK_NAME = {
  CCB01: '경남은행',
  CCB02: '광주은행',
  CCB03: '단위농협(지역농축협)',
  CCB04: '부산은행',
  CCB05: '새마을금고',
  CCB06: '산림조합',
  CCB07: '신한은행',
  CCB08: '신협',
  CCB09: '씨티은행',
  CCB10: '우리은행',
  CCB11: '우체국',
  CCB12: '저축은행',
  CCB13: '전북은행',
  CCB14: '제주은행',
  CCB15: '카카오뱅크',
  CCB16: '케이뱅크',
  CCB17: '토스뱅크',
  CCB18: '하나은행',
  CCB19: '홍콩상하이은행',
  CCB20: '기업은행',
  CCB21: '국민은행',
  CCB22: '대구은행',
  CCB23: '산업은행',
  CCB24: '농협',
  CCB25: 'SC 제일은행',
  CCB26: '수협',
  CCB27: '교보증권',
  CCB28: '대신증권',
  CCB29: '메리츠증권',
  CCB30: '미래에셋증권',
  CCB31: '부국증권',
  CCB32: '삼성증권',
  CCB33: '신영증권',
  CCB34: '신한금융투자',
  CCB35: '유안타증권',
  CCB36: '유진투자증권',
  CCB37: '키움증권',
  CCB38: '토스증권',
  CCB39: '한국포스증권',
  CCB40: '하나금융투자',
  CCB41: '하이투자증권',
  CCB42: '한국투자증권',
  CCB43: '한화투자증권',
  CCB44: '현대차투자증권',
  CCB45: 'DB금융투자',
  CCB46: 'KB증권',
  CCB47: '다올투자증권',
  CCB48: 'LIG투자증권',
  CCB49: 'NH투자증권',
  CCB50: 'SK증권',
} as const

const type1 = z.nativeEnum(IMWEB_BANK_NAME)
export type TIMWEB_BANK_NAME = z.infer<typeof type1>
