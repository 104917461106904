import { pipe, flow } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { atomFamily } from 'jotai/utils'
import { atom, useAtom } from 'jotai'
import React from 'react'
import type { TItem, TValue, T_VIEW_Item } from '../../product-search.type'
import { useProductSearchAtom } from '../../product-search.store'

// 상품(id)옵션(id)의 상태를 관리하는 atom
export const nonRequiredOptionState = atomFamily(
  ({
    searchId,
    prodCode,
    optionCode,
    valueCode,
  }: {
    searchId: string
    prodCode: string
    optionCode: string
    valueCode: string
    values?: (TItem & T_VIEW_Item)[]
  }) => {
    const at = atom(
      // ==================== get ====================
      (get) => {
        const productSearchItemAtom = useProductSearchAtom.itemAtom({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        if (prodData === undefined) {
          return undefined
        }
        const result = pipe(
          prodData,
          (e) => e.options,
          O.fromNullable,
          O.fold(
            () => undefined,
            flow(
              // optionCode가 동일하고 해당 옵션에 values가 있는데 거기에
              A.findFirst((e) => e.optionCode === optionCode),
              O.fold(
                () => undefined,
                flow(
                  (e) => e.values,
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      A.findFirst((e) => e.valueCode === valueCode),
                      O.fold(
                        () => undefined,
                        (e) => e
                      )
                    )
                  )
                )
              )
            )
          )
        )
        return result
      },
      // ==================== set ====================
      // 주문서전체 데이터에서 prodCode, optionCode, valueCode를 찾아서 해당 value를 업데이트한다.
      (get, set, arg: TValue) => {
        const productSearchItemAtom = useProductSearchAtom.itemAtom({
          searchId,
          prodCode,
        })
        const prodData = get(productSearchItemAtom)
        const options = pipe(prodData, (e) => e.options)
        // 선택상품은 항상 options가 있다.
        if (options === undefined) {
          return
        }
        // TODO: product데이터에서 optionCode에 맞고, valueCode에 맞는 값을 찾아서 업데이트한다.
        pipe(
          options,
          A.map((e) => {
            if (e.optionCode === optionCode) {
              return {
                ...e,
                values: pipe(
                  e.values,
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    flow(
                      A.map((r) => {
                        if (r.valueCode === valueCode) {
                          return {
                            ...r,
                            ...arg,
                          }
                        }
                        return r
                      })
                    )
                  )
                ),
              }
            }
            return e
          }),
          (e) => ({
            ...prodData,
            options: e,
          }),
          (e) => set(productSearchItemAtom, e)
        )
      }
    )
    at.debugLabel = `search-product(${prodCode})-option(${optionCode})-value(${valueCode})`
    return at
  },
  (a, b) =>
    a.searchId === b.searchId &&
    a.prodCode === b.prodCode &&
    a.optionCode === b.optionCode &&
    a.valueCode === b.valueCode
)

export const useNonRequiredOptionAtom = {
  option: (
    searchId: string,
    prodCode: string,
    optionCode: string,
    valueCode: string
  ) =>
    useAtom(
      React.useMemo(
        () =>
          nonRequiredOptionState({
            searchId,
            prodCode,
            optionCode,
            valueCode,
          }),
        [optionCode, prodCode, searchId, valueCode]
      )
    ),
}
