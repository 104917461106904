import { Flex, Tooltip, Typography } from '@imwebme/clay-components'
import { vars } from '@imwebme/clay-token'
import { useOrderInfoAtom } from '../order-create.store'
import { __ } from '~/shared/i18n'
import { HelpCircle } from '@imwebme/clay-icons'
import { Trans } from 'react-i18next'

export function CashOnDelivery({ price }: { price?: number }) {
  const [orderInfo] = useOrderInfoAtom()
  const currency = orderInfo.unitSite?.currency || 'KRW'
  if (price === undefined) {
    return null
  }
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      columnGap={vars.spacing[1]}
    >
      <Flex columnGap={vars.spacing[1]} alignItems="center">
        <Typography variant="label-medium">{__('착불 배송비')}</Typography>
        <Tooltip>
          <Tooltip.Trigger>
            <HelpCircle colorToken="icon-secondary" />
          </Tooltip.Trigger>
          <Tooltip.Content
            label={__(
              '구매자가 택배사에 부담하는 금액이며 \n결제 금액에 합산되지 않아요'
            )}
            position="top"
          />
        </Tooltip>
      </Flex>
      <Typography
        variant="body-medium"
        sx={{ display: 'flex', columnGap: vars.spacing[1] }}
      >
        <Trans
          i18nKey="<t>{{price, 3comma}}</t><t>{{currency, currency}}</t>"
          values={{
            price: price,
            formatParams: {
              currency: {
                currency,
              },
            },
          }}
          components={{ t: <Typography variant="body-medium" as="span" /> }}
        />
      </Typography>
    </Flex>
  )
}
